import React from "react";
import { useDispatch } from "react-redux";
import { BasicModal } from "./Modal.component";
import { ModalNames } from "./Modal.types";
import { modalWithWrapper } from "./ModalWrapper.component";
import { maximumBasketLimitHelpActionCreator, clearBasketErrorActionCreator } from "../../domain/basket/basket.actions";
import { BasketErrorKeys } from "../../domain/basket/basket.types";

const MaximumBasketLimitExceededWithWrapper = modalWithWrapper(
  BasicModal,
  ModalNames.MODAL_MAXIMUM_BASKET_LIMIT_EXCEEDED
);

type MaximumBasketLimitExceededModalProps = {
  title: string;
  description: string;
};

export const MaximumBasketLimitExceededModal = ({ title, description }: MaximumBasketLimitExceededModalProps) => {
  const dispatch = useDispatch();

  const clearMaximumBasketLimitExceededError = () => {
    dispatch(clearBasketErrorActionCreator(BasketErrorKeys.MAXIMUM_BASKET_LIMIT_EXCEEDED));
  };

  const maximumBasketLimitHelp = () => {
    dispatch(maximumBasketLimitHelpActionCreator());
  };

  return (
    <MaximumBasketLimitExceededWithWrapper
      title={title}
      description={description}
      primaryButtonText="OK"
      onPrimaryButtonClick={clearMaximumBasketLimitExceededError}
      secondaryButtonText="Find out more"
      onSecondaryButtonClick={maximumBasketLimitHelp}
      onClose={clearMaximumBasketLimitExceededError}
    />
  );
};
