import {
  FetchSlotReservationFailureAction,
  FetchSlotReservationRequestAction,
  FetchSlotReservationSuccessAction,
  FetchOriginalSlotReservationFailureAction,
  FetchOriginalSlotReservationRequestAction,
  FetchOriginalSlotReservationSuccessAction,
  SlotReservationActionTypes,
  SlotReservationState,
  SlotReservationType,
  SetReservationExpiredAction,
} from "./slotReservation.types";
import { DataState } from "../../common/dataState";
import { ResponseErrorActionType } from "../../common/types";
import { Action } from "redux";

export const slotReservationInitialState: SlotReservationState = {
  dataState: DataState.UNKNOWN,
  originalSlotReservationDataState: DataState.UNKNOWN,
  slotReservation: {
    reservationType: SlotReservationType.NONE,
    reservationExpired: false,
    reservationDate: "",
    reservationStartTime: "",
    reservationEndTime: "",
    reservationTimeSlot: "",
    reservationIsGreen: false,
    storeIdentifier: "",
    isAlcoholRestrictedStore: false,
    reservationUntil: "",
    reservationPostCode: "",
    orderId: "",
    isSameDay: false,
    region: "",
    reservationAddressNickname: "",
    slotPriceFormatted: "",
    isDeliveryPassApplicable: false,
    postcode: "",
    isXmasSlot: false,
  },
  originalSlotReservation: null,
};

type ActionTypes =
  | FetchSlotReservationRequestAction
  | FetchSlotReservationSuccessAction
  | FetchSlotReservationFailureAction
  | FetchOriginalSlotReservationRequestAction
  | FetchOriginalSlotReservationSuccessAction
  | FetchOriginalSlotReservationFailureAction
  | Action;

export const slotReservationReducer = (
  state: SlotReservationState = slotReservationInitialState,
  action: ActionTypes
): SlotReservationState => {
  switch (action.type) {
    case SlotReservationActionTypes.FETCH_SLOT_RESERVATION_REQUEST:
      return {
        ...state,
        dataState: DataState.PENDING,
      };

    case SlotReservationActionTypes.FETCH_SLOT_RESERVATION_SUCCESS: {
      const slotReservationSuccessAction = action as FetchSlotReservationSuccessAction;

      return {
        ...state,
        dataState: DataState.SUCCESS,
        slotReservation: slotReservationSuccessAction.slotReservation,
      };
    }

    case SlotReservationActionTypes.FETCH_SLOT_RESERVATION_FAILURE: {
      return {
        ...state,
        dataState: DataState.FAILED,
      };
    }

    case SlotReservationActionTypes.FETCH_ORIGINAL_SLOT_RESERVATION_REQUEST:
      return {
        ...state,
        originalSlotReservationDataState: DataState.PENDING,
      };

    case SlotReservationActionTypes.FETCH_ORIGINAL_SLOT_RESERVATION_SUCCESS:
      const originalSlotReservationSuccessAction = action as FetchOriginalSlotReservationSuccessAction;
      return {
        ...state,
        originalSlotReservationDataState: DataState.SUCCESS,
        originalSlotReservation: originalSlotReservationSuccessAction.slotReservation,
      };

    case SlotReservationActionTypes.FETCH_ORIGINAL_SLOT_RESERVATION_FAILURE:
      return {
        ...state,
        originalSlotReservationDataState: DataState.FAILED,
      };

    case SlotReservationActionTypes.SET_RESERVATION_EXPIRED:
      const setReservationExpiredAction = action as SetReservationExpiredAction;
      return {
        ...state,
        slotReservation: setReservationExpiredAction.slotReservation,
      };

    case ResponseErrorActionType.HTTP_UNAUTHORIZED_ERROR:
      return slotReservationInitialState;

    default:
      return state;
  }
};
