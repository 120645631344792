import { combineReducers } from "redux";
import { DataState } from "../../common/dataState";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import {
  EventsFeaturesActionType,
  EventsFeaturesState,
  CreateEventsFeaturesSearchTermActionType,
  CreateEventsFeaturesBuilderDataActionType,
  CreateEventsFeaturesBuilderCMSDataActionType,
} from "./EventsFeatures.types";

const dataState = createDataStateReducer({
  [EventsFeaturesActionType.PRODUCTS_REQUEST]: DataState.PENDING,
  [EventsFeaturesActionType.PRODUCTS_SUCCESS]: DataState.SUCCESS,
  [EventsFeaturesActionType.PRODUCTS_FAILURE]: DataState.FAILED,
  [EventsFeaturesActionType.SEARCH_TERM_SUCCESS]: DataState.SUCCESS,
  [EventsFeaturesActionType.CMS_DATA_SUCCESS]: DataState.SUCCESS,
  // pending and fail ?
});

export const initialState: EventsFeaturesState = {
  dataState: DataState.UNKNOWN,
  searchTerm: "",
  ads: [],
  cmsData: undefined,
};

const searchTermReducer = (
  state: EventsFeaturesState["searchTerm"] = initialState.dataState,
  action: CreateEventsFeaturesSearchTermActionType
) => {
  if (action.type === EventsFeaturesActionType.SEARCH_TERM_SUCCESS) {
    return action.searchTerm;
  }
  return state;
};

const eventsDataReducer = (
  state: EventsFeaturesState = initialState,
  action: CreateEventsFeaturesBuilderDataActionType
) => {
  if (action.type === EventsFeaturesActionType.CONTENT_SUCCESS && action.ads) {
    return action.ads;
  }
  return state;
};

const cmsDataReducer = (
  state: EventsFeaturesState = initialState,
  action: CreateEventsFeaturesBuilderCMSDataActionType
) => {
  if (action.type === EventsFeaturesActionType.CMS_DATA_SUCCESS && action.cmsData) {
    return action.cmsData;
  }
  return state;
};

export const eventsFeaturesReducer = combineReducers({
  dataState,
  searchTerm: searchTermReducer,
  ads: eventsDataReducer,
  cmsData: cmsDataReducer,
});
