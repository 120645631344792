import isEmpty from "lodash.isempty";
import { RequestFilters } from "../services/product.types";
import { PageVariants, searchFilterKeys } from "./SearchResultsFindability/SearchResultsFindability.types";
import { Filter } from "../domain/product/product.types";
import {
  FilterAdapterInput,
  FilterAdapterOutput,
  FilterVariations,
  initPathFilters,
  isBrandFilter,
  isOffersFilter,
  isPathFilters,
  isRequestFilters,
  PathFilters,
} from "./filters.types";
import { concatAllNonEmptyStrings, dehyphenate, encodeSpecialCharacters, hyphenate } from "../common/utils";
import { getBrowseSubstrings, stringToFiltersControls, resetPage } from "./Browse/Browse.utils";
import { getBrandedSubStrings } from "./BrandedPage/BrandedPage.utils";
import { getEventsAndFeaturesSubStrings } from "./EventsFeatures/EventsFeatures.utils";
import { getMealDealSubStrings } from "./MealDealBuilder/utils";

const needSorting = (s: any): boolean => typeof s == "string" && s.includes(",");
const sortFilterValue = (s: string): string =>
  s
    .split(",")
    .sort((a, b) => (a > b ? 1 : -1))
    .join(",");

function stringifyFilters(filters: PathFilters): string {
  if (isEmpty(filters)) return "";
  const sortedEntries = Object.entries(filters)
    .sort((a, b) => (a[0] > b[0] ? 1 : -1))
    .map(f => {
      const sortedValue = needSorting(f[1]) ? sortFilterValue(f[1]) : f[1];
      return [f[0], sortedValue];
    });

  return sortedEntries
    .reduce((entries, current) => {
      if (typeof current[1] === "string") {
        entries.push(current[0].concat(":", current[1]));
      } else {
        entries.push(current[0]);
      }
      return entries;
    }, [])
    .join("/");
}

export const createFilterPath = (currentFilters: PathFilters | undefined, payload: Filter[] | undefined): string => {
  if (currentFilters === undefined && payload === undefined) return "";
  if (currentFilters !== undefined && payload !== undefined) {
    const mappedPayload = payload.map(f => {
      return { ...f, key: requestToPathKeys[f.key], value: decodeURI(f.value) };
    });
    const combinedFilters: RequestFilters = combineFilters(currentFilters, mappedPayload);
    return encodeSpecialCharacters(stringifyFilters(combinedFilters));
  }

  const newFilters = FiltersAdapter(payload as Filter[], FilterVariations.PathFilters, true);
  return encodeSpecialCharacters(stringifyFilters(newFilters));
};

export const getUnfilteredBrowseURL = (pathname: string): string => {
  const { landingURL, options } = getBrowseSubstrings(pathname);
  return concatAllNonEmptyStrings([landingURL, options], "/");
};

export const getUnfilteredEventsURL = (pathname: string): string => {
  const { landingURL, facetSlug } = getEventsAndFeaturesSubStrings(pathname);
  return facetSlug ? `${landingURL}/${facetSlug}` : landingURL;
};

export const getUnfilteredMealDealsURL = (pathname: string): string => {
  const { landingURL, listIdSlug } = getMealDealSubStrings(pathname);
  return listIdSlug ? `${landingURL}/${listIdSlug}` : landingURL;
};

export const getURlSubstrings = (page: PageVariants, path: string) => {
  switch (page) {
    case PageVariants.BROWSE:
    default:
      return getBrowseSubstrings(path);
    case PageVariants.BRANDED:
      return getBrandedSubStrings(path);
    case PageVariants.EVENTS_BUILDER:
    case PageVariants.FEATURES:
      return getEventsAndFeaturesSubStrings(path);
    case PageVariants.MEAL_DEAL_BUILDER:
      return getMealDealSubStrings(path);
  }
};

export const createNewPathWithNewFilters = (
  currentPath: string,
  newFilters: Filter[] | undefined,
  page: PageVariants
): string => {
  const { landingURL, options, filters: existingFiltersPath } = getURlSubstrings(page, currentPath);
  let facetSlug;
  switch (page) {
    case PageVariants.BRANDED:
      facetSlug = getBrandedSubStrings(currentPath).facetSlug;
      break;
    case PageVariants.EVENTS_BUILDER:
    case PageVariants.FEATURES:
      facetSlug = getEventsAndFeaturesSubStrings(currentPath).facetSlug;
      break;
    case PageVariants.MEAL_DEAL_BUILDER:
      facetSlug = getMealDealSubStrings(currentPath).listIdSlug;
      break;
    default:
      facetSlug = "";
  }
  const existingFilters = stringToFiltersControls(existingFiltersPath);
  const newFiltersPath: string = createFilterPath(existingFilters, newFilters);
  return concatAllNonEmptyStrings([landingURL, facetSlug ?? "", newFiltersPath, resetPage(options)], "/");
};

const isActive = (filter: [string, boolean | string]) =>
  typeof filter[1] === "string" ? filter[1].length > 0 : filter[1];
const seoFormatted = (filterValue: string): string => hyphenate(filterValue).toLowerCase();

const deformat = (filterValueString: string): string =>
  decodeURIComponent(dehyphenate(filterValueString.split(",").join(",")));

export const combineFilters = (existingFilters: PathFilters, eventParams: Filter[]): PathFilters => {
  const newFilters: RequestFilters = eventParams.reduce((rf, current) => {
    rf[current.key] = { value: seoFormatted(current.value), enabled: current.enabled };
    return rf;
  }, {});

  const isNotDefault = (fe: [string, any]) => fe[0] in existingFilters || fe[0] in newFilters;
  const combined = Object.entries(initPathFilters)
    .filter(isNotDefault)
    .map(filter => {
      const currentKey = filter[0];
      const newValue = newFilters[currentKey];
      const currentValue = existingFilters[currentKey];

      if (newValue) {
        if (isOffersFilter(currentKey)) {
          filter[1] = newValue.enabled;
        } else if (newValue.enabled) {
          filter[1] = currentValue ? currentValue.concat(",", newValue.value) : newValue.value;
        } else {
          const values: string[] = currentValue.split(",");
          filter[1] = values.filter(v => v !== newValue.value).join(",");
        }
      } else {
        filter[1] = currentValue;
      }
      return filter;
    })
    .filter(isActive);

  return Object.fromEntries(combined);
};

const pathToRequestKeys = {
  dietary: searchFilterKeys.DIETARY,
  brand: searchFilterKeys.BRAND,
  other: searchFilterKeys.OTHER,
  offer: searchFilterKeys.OFFER,
};

const requestToPathKeys = Object.entries(pathToRequestKeys).reduce((m, current) => {
  const [key, value] = current;
  m[value] = key;
  return m;
}, {});

export const requestToPath = (requestFilters: RequestFilters): PathFilters => {
  return Object.entries(requestFilters).reduce((obj, current) => {
    const [key, value] = current;
    if (value) {
      if (isOffersFilter(key) && value) {
        obj[requestToPathKeys[key]] = true;
      } else {
        const formatted = seoFormatted(value);
        obj[requestToPathKeys[key]] = formatted;
      }
    }
    return obj;
  }, {} as PathFilters);
};

export const pathToRequest = (filters: PathFilters, hyphenSensitive: boolean): RequestFilters =>
  Object.entries(filters).reduce((obj, current) => {
    const [key, value] = current;
    if (value) {
      const filterKey = pathToRequestKeys[key];
      if (isOffersFilter(key) && value) {
        obj[filterKey] = "Offers";
      } else {
        const deformatted = deformat(value);
        // Send two versions of the brand filters because the deformatted value would be incorrect when the brand has hyphens in its name.
        if (isBrandFilter(key) && hyphenSensitive) {
          const withHyphen = decodeURIComponent(value);
          obj[filterKey] = deformatted.concat(",", withHyphen);
        } else {
          obj[filterKey] = deformatted;
        }
      }
    }
    return obj;
  }, {});

const eventParamsToRequest = (filters: Filter[]): RequestFilters =>
  filters
    .filter(f => f.enabled)
    .reduce((fs, current) => {
      fs[current.key] = decodeURI(current.value);
      return fs;
    }, {});

const eventParamsToPath = (filters: Filter[]): PathFilters => requestToPath(eventParamsToRequest(filters));

// FiltersAdapter facilitates the mapping of various filters data structures
export default function FiltersAdapter(
  input: FilterAdapterInput,
  outputType: FilterVariations,
  hyphenSensitive: boolean
): FilterAdapterOutput {
  if (isEmpty(input)) return {};
  if (isPathFilters(input)) return pathToRequest(input, hyphenSensitive);
  if (isRequestFilters(input)) return requestToPath(input);

  // if input is Filter[]
  return outputType === FilterVariations.RequestFilters ? eventParamsToRequest(input) : eventParamsToPath(input);
}
