import { YnpActions, YnpActionTypes, YnpState } from "./ynp.types";
import { DataState } from "../../common/dataState";

export const ynpInitialState: YnpState = {
  fetchAllOptIns: {
    optedIn: [],
    notOptedIn: [],
    dataState: DataState.UNKNOWN,
  },
  optInForAll: {
    dataState: DataState.UNKNOWN,
  },
  products: {
    dataState: DataState.UNKNOWN,
    products: [],
  },
};

export const ynpReducer = (state: YnpState = ynpInitialState, action: YnpActions): YnpState => {
  switch (action.type) {
    case YnpActionTypes.FETCH_SUCCESSFUL: {
      return {
        ...state,
        fetchAllOptIns: {
          optedIn: action.payload.optedIn,
          notOptedIn: action.payload.notOptedIn,
          dataState: DataState.SUCCESS,
        },
      };
    }

    case YnpActionTypes.FETCH_PENDING: {
      return {
        ...state,
        fetchAllOptIns: {
          ...state.fetchAllOptIns,
          dataState: DataState.PENDING,
        },
      };
    }

    case YnpActionTypes.FETCH_NOT_SUCCESSFUL: {
      return {
        ...state,
        fetchAllOptIns: {
          ...state.fetchAllOptIns,
          dataState: DataState.FAILED,
        },
      };
    }

    case YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_SUCCESSFUL: {
      return {
        ...state,
        optInForAll: {
          dataState: DataState.SUCCESS,
        },
      };
    }

    case YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_PENDING: {
      return {
        ...state,
        optInForAll: {
          dataState: DataState.PENDING,
        },
      };
    }

    case YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_NOT_SUCCESSFUL: {
      return {
        ...state,
        optInForAll: {
          dataState: DataState.FAILED,
        },
      };
    }

    case YnpActionTypes.SET_PRODUCTS: {
      return {
        ...state,
        products: {
          dataState: action.payload.dataState,
          products: action.payload.products,
        },
      };
    }

    default:
      return state;
  }
};
