import { AnyAction, combineReducers } from "redux";
import { DataState } from "../../common/dataState";
import { MealPlannerActions, MealPlannerState } from "./MealPlanner.types";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";

export const initialState: MealPlannerState = {
  plans: [],
  current: null,
  dataState: DataState.UNKNOWN,
};

const status = createDataStateReducer({
  [MealPlannerActions.MEAL_PLAN_SUCCESS]: DataState.SUCCESS,
  [MealPlannerActions.MEAL_PLAN_REQUEST]: DataState.PENDING,
  [MealPlannerActions.MEAL_PLAN_FAILED]: DataState.FAILED,
});

function mealPlanListReducer(
  state: MealPlannerState["plans"] = initialState.plans,
  action: AnyAction
): MealPlannerState["plans"] {
  switch (action.type) {
    case MealPlannerActions.MEAL_PLAN_SUCCESS:
      const plans = action.payload;
      return plans;

    case MealPlannerActions.MEAL_PLAN_FAILED:
      return initialState.plans;
    default:
      return state;
  }
}

function mealPlanCurrentReducer(
  state: MealPlannerState["current"] = initialState.current,
  action: AnyAction
): MealPlannerState["current"] {
  switch (action.type) {
    case MealPlannerActions.MEAL_PLAN_SET_CURRENT:
      return {
        id: action.id,
        name: action.name,
        featured: action.featured,
        recipes: action.recipes,
      };
    default:
      return state;
  }
}

export const mealPlannerReducer = combineReducers({
  dataState: status,
  plans: mealPlanListReducer,
  current: mealPlanCurrentReducer,
});
