import { State } from "../store";
import { DataState } from "../dataState";
import { GENERIC_USER_ID } from "../analytics/createDigitalData";

export const selectHasNectarAssociated = (state: State): boolean => {
  const { dataState, userDetails } = state.user;
  switch (dataState) {
    case DataState.SUCCESS:
      return userDetails!.hasNectarAssociated ? true : false;
    default:
      return false;
  }
};

export const selectUserDataStatus = (state: State): DataState => {
  const { dataState } = state.user;
  return dataState;
};

export const selectIsDigitalCustomer = (state: State): boolean => {
  const { dataState, userDetails } = state.user;
  switch (dataState) {
    case DataState.SUCCESS:
      return userDetails!.isDigitalCustomer ? true : false;
    default:
      return false;
  }
};

export const selectIsGuestCustomer = (state: State) => {
  const { dataState, userDetails } = state.user;
  switch (dataState) {
    case DataState.SUCCESS:
      return userDetails!.id === GENERIC_USER_ID;
    default:
      return true;
  }
};

export const selectIsDeliveryPassCustomer = (state: State) => {
  const { dataState, userDetails } = state.user;
  switch (dataState) {
    case DataState.SUCCESS:
      return userDetails!.hasDeliveryPass ? true : false;
    default:
      return false;
  }
};

export const selectIsVeryImportantCustomer = (state: State) => {
  const { dataState, userDetails } = state.user;
  switch (dataState) {
    case DataState.SUCCESS:
      return userDetails!.isVeryImportantCustomer ? true : false;
    default:
      return false;
  }
};

export const selectIsNewCustomer = (state: State): boolean => {
  const { dataState, userDetails } = state.user;
  switch (dataState) {
    case DataState.SUCCESS:
      return userDetails!.isNewCustomer ? true : false;
    default:
      return false;
  }
};

const initialUserData = {
  is_very_important_customer: false,
  has_nectar_associated: false,
  is_digital_customer: false,
  has_delivery_pass: false,
};

export const selectUserData = (state: State) => {
  const { dataState, userDetails } = state.user;
  return dataState === DataState.SUCCESS
    ? {
        is_very_important_customer: !!userDetails?.isVeryImportantCustomer,
        has_nectar_associated: !!userDetails?.hasNectarAssociated,
        is_digital_customer: !!userDetails?.isDigitalCustomer,
        has_delivery_pass: !!userDetails?.hasDeliveryPass,
      }
    : initialUserData;
};
