import {
  FetchProductDetailsSuccess,
  ProductDetails,
  ProductDetailsAction,
  ProductDetailsState,
} from "./productDetails.types";
import { DataState } from "../../common/dataState";
import { AnyAction, combineReducers } from "redux";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";
import { routes } from "../../routes";
import { ProductServiceError } from "../../services/product.types";

const dataState = createDataStateReducer({
  [ProductDetailsAction.FETCH_PRODUCT_DETAILS_PENDING]: DataState.PENDING,
  [ProductDetailsAction.FETCH_PRODUCT_DETAILS_SUCCESS]: DataState.SUCCESS,
  [ProductDetailsAction.FETCH_PRODUCT_DETAILS_ERROR]: DataState.FAILED,
});

export const productDetailsInitialState: ProductDetailsState = {
  dataState: DataState.UNKNOWN,
  productDetails: {
    isProductNotFound: false,
  },
  errors: [],
};

const productReducer = (
  state: ProductDetails = productDetailsInitialState.productDetails,
  action: AnyAction
): ProductDetails => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const match = matchPath(action.payload.location.pathname, {
        path: routes.PRODUCT_DETAILS,
        exact: true,
      });

      if (!match) {
        return state;
      }

      return productDetailsInitialState.productDetails;
    case ProductDetailsAction.FETCH_PRODUCT_DETAILS_NOT_FOUND_ERROR:
      return {
        ...state,
        isProductNotFound: true,
      };
    case ProductDetailsAction.FETCH_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};

const errorsReducer = (
  state: ProductServiceError[] = productDetailsInitialState.errors,
  action: AnyAction
): ProductServiceError[] => {
  switch (action.type) {
    case ProductDetailsAction.FETCH_PRODUCT_DETAILS_NOT_FOUND_ERROR:
    case ProductDetailsAction.FETCH_PRODUCT_DETAILS_ERROR:
    case ProductDetailsAction.FETCH_PRODUCT_DETAILS_PENDING:
      return [];
    case ProductDetailsAction.FETCH_PRODUCT_DETAILS_SUCCESS:
      const typedAction = action as FetchProductDetailsSuccess;
      return typedAction.errors || [];
    default:
      return state;
  }
};
export const productDetailsReducer = combineReducers<ProductDetailsState>({
  dataState,
  productDetails: productReducer,
  errors: errorsReducer,
});
