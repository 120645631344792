import { DietaryProfileStatus, User } from "../../domain/customer/customer";
import { invokeWithRetry } from "./invokeWithRetry";
import { PageViewProperties } from "./analytics";
import { NectarOffers } from "../../domain/nectar/nectar.types";
import {
  AdobeDigitalDataProperties,
  AnalyticsTrackEventAction,
  AnalyticsTrackLightboxViewEventAction,
  HIT_TYPES,
} from "./types";
import { createDigitalData } from "./createDigitalData";

/**
 * How to track an experiment?
 * Add your experiment to the @enum EXPERIMENTS  using the steps mentioned there
 * To track an experiment simple call @function trackExperiment method
 * Example Usage: trackExperiment(EXPERIMENTS.SAMPLE_TEST_GROUP_1, variant);
 */

/**
 * These are completed tests
 * [test id]: [test id]
 * FINDABILITY_TEST_GROUP_1: "FINDABILITY_TEST_GROUP_1"
 * FINDABILITY_TEST_GROUP_2: "FINDABILITY_TEST_GROUP_2"
 * GLOBAL_TEST_GROUP_1: "GLOBAL_TEST_GROUP_1"
 */

export enum EXPERIMENTS {
  Recipes_Test_Group_1 = "Recipes_Test_Group_1",
  Global_Recommendations_Test_Group_1 = "Global_Recommendations_Test_Group_1",
  Global_Test_Group_2 = "Global_Test_Group_2",
  Favourites_Test_Group_1 = "Favourites_Test_Group_1",
  Favourites_Test_Group_3 = "Favourites_Test_Group_3",
  PDP_Test_Group_1 = "PDP_Test_Group_1",
  PDP_Test_Group_2 = "PDP_Test_Group_2",
  PDP_Test_Group_4 = "PDP_Test_Group_4",
  Homepage_Test_Group_1 = "Homepage_Test_Group_1",
  Search_Test_Group_1 = "Search_Test_Group_1",
  Browse_Test_Group_1 = "Browse_Test_Group_1",
  Findability_Test_Group_8 = "Findability_Test_Group_8",
  Findability_Test_Group_6 = "Findability_Test_Group_6",
  Reorder_YNP_Test_Group_1 = "Reorder_YNP_Test_Group_1",
  BYG_Test_Group_3 = "BYG_Test_Group_3",
  Global_Header_Test_Group_1 = "Global_Header_Test_Group_1",
  Sponsored_Drawer_Test_Group_1 = "Sponsored_Drawer_Test_Group_1",
  Promo_Test_Group_1 = "Promo_Test_Group_1",
  PDP_FBT_Test_Group_3 = "PDP_FBT_Test_Group_3",
  Changes_to_trolley_OOS = "Changes_to_trolley_OOS",
  EAS_Tiles_Test_Group_2 = "EAS_Tiles_Test_Group_2",
}
/**
 * Add your experiments here
 * Syntax to add an experiment:
 * [test id]: [test id]
 * For instance:
 * SAMPLE_TEST_GROUP: [test id]
 */

const MAX_UTAG_RETRIES = 10;
const RETRY_TIMEOUT = 300;

const DEFAULT_USER: User = {
  id: "",
  customerId: "",
  customerNumber: "",
  websphereId: "",
  identityId: "",
  givenName: "",
  isRegistered: false,
  email: "",
  phoneNumber: "",
  isRecurringSlotRegistered: false,
  dietaryProfile: DietaryProfileStatus.NONE,
  hasDeliveryPass: false,
  isDeliveryPassAutoRenewalEnabled: false,
  postcode: "GU11BT",
  hasNectarLinked: false,
  nectar_saved_offers: 0,
  isSamAgent: false,
  defaultSubstitutionPreference: true,
};

export type PostEventParams = {
  page: PageViewProperties;
  referrer: string;
  user?: User;
  nectar: NectarOffers;
  region: string;
  shoppingMode: string;
  adobeProperties?: AdobeDigitalDataProperties;
};

export const postEvent = ({
  page,
  referrer,
  user = DEFAULT_USER,
  nectar,
  region,
  shoppingMode,
  adobeProperties,
}: PostEventParams) => {
  (window as any).digitalData = createDigitalData(page, referrer, user, nectar, region, shoppingMode, adobeProperties);
  postPageTrackEvent(window);
};

export const postErrorEvent = (errorType: string, errorMessage: string) => {
  (window as any).digitalData.page.events = [
    {
      pageEvent: "error",
      errorType,
      errorMessage,
    },
  ];

  postLinkTrackEvent(window);
};

export const isLoginUrl = (url: string) => !!(url && url.match(/Logon(\?|$|View|Form)/));

const isUtagLoaded = (w: any) => () => w.utag && w.utag.view && w.utag.link;

const utagView = (w: any) => () => w.utag.view();
const utagLink = (w: any) => () => w.utag.link();

export const postPageTrackEvent = (w: any) =>
  invokeWithRetry(utagView(w), isUtagLoaded(w), MAX_UTAG_RETRIES, RETRY_TIMEOUT);

export const postLinkTrackEvent = (w: any) =>
  invokeWithRetry(utagLink(w), isUtagLoaded(w), MAX_UTAG_RETRIES, RETRY_TIMEOUT);

export type ExperimentListObject = {
  name: EXPERIMENTS;
  variant: string;
};

export const trackExperiment = (experiment: EXPERIMENTS, variant: string) => {
  if (
    (window as any).digitalData &&
    (window as any).digitalData.experimentList &&
    (window as any).digitalData.experimentList.filter((item: ExperimentListObject) => item.name === experiment).length <
      1
  ) {
    (window as any).digitalData.experimentList.push({
      name: experiment,
      variant,
    });
  }
};

type TrackPageLoadParams = PostEventParams & {
  eventName: string;
};

export const trackPageLoad = ({
  page,
  referrer,
  user = DEFAULT_USER,
  nectar,
  region,
  shoppingMode,
  eventName,
  adobeProperties,
}: TrackPageLoadParams) => {
  (window as any).digitalData = createDigitalData(page, referrer, user, nectar, region, shoppingMode, adobeProperties);
  digitalDataTrackPageLoad(eventName);
};

const isDigitalDataTrackLoaded = (w: any) => () => isUtagLoaded(w) && w?.digitalData?.track;

export const digitalDataGenericTrackEvent = (eventName: string, properties: object) => {
  const w = window as any;

  invokeWithRetry(
    () => {
      /**
       * window.digitalData.track is a function provided by the Tealium tag.
       * @param {string} eventName (required) - Event name. Patterns are pl_{NAME} and ev_{NAME} for pageview events and non-pageview events respectively.
       * @param {object} eventData (optional) - Event data. If not specified, the function will try to get the event data from the last element of the window.digitalData.event array. If specified, hit_type property must be set to either 'view' or 'link' for pageview events and non-pageview events respectively.
       */

      w.digitalData?.track(eventName, {
        hit_type: HIT_TYPES.LINK,
        ...properties,
      });
    },
    isDigitalDataTrackLoaded(w),
    MAX_UTAG_RETRIES,
    RETRY_TIMEOUT
  );
};

export const digitalDataTrackEvent = ({
  eventName,
  data_event_category,
  data_event_action,
  data_event_label,
}: Pick<AnalyticsTrackEventAction, "eventName" | "data_event_category" | "data_event_action" | "data_event_label">) => {
  const w = window as any;

  invokeWithRetry(
    () => {
      /**
       * window.digitalData.track is a function provided by the Tealium tag.
       * @param {string} eventName (required) - Event name. Patterns are pl_{NAME} and ev_{NAME} for pageview events and non-pageview events respectively.
       * @param {object} eventData (optional) - Event data. If not specified, the function will try to get the event data from the last element of the window.digitalData.event array. If specified, hit_type property must be set to either 'view' or 'link' for pageview events and non-pageview events respectively.
       */

      w.digitalData?.track(eventName, {
        hit_type: HIT_TYPES.LINK,
        data_event_category,
        data_event_action,
        data_event_label,
      });
    },
    isDigitalDataTrackLoaded(w),
    MAX_UTAG_RETRIES,
    RETRY_TIMEOUT
  );
};

export const digitalDataTrackPageLoad = (eventName: string) => {
  const w = window as any;
  invokeWithRetry(
    () => {
      /**
       * window.digitalData.track is a function provided by the Tealium tag.
       * @param {string} eventName (required) - Event name. Patterns are pl_{NAME} and ev_{NAME} for pageview events and non-pageview events respectively.
       * @param {object} eventData (optional) - Event data. If not specified, the function will try to get the event data from the last element of the window.digitalData.event array. If specified, hit_type property must be set to either 'view' or 'link' for pageview events and non-pageview events respectively.
       */

      w.digitalData?.track(eventName, {
        hit_type: HIT_TYPES.VIEW,
      });
    },
    isDigitalDataTrackLoaded(w),
    MAX_UTAG_RETRIES,
    RETRY_TIMEOUT
  );
};

//used to send page view event when a modal is loaded
export const digitalDataTrackLightboxView = ({
  eventName,
  data_page_name,
}: Pick<AnalyticsTrackLightboxViewEventAction, "eventName" | "data_page_name">) => {
  const w = window as any;

  invokeWithRetry(
    () => {
      /**
       * window.digitalData.track is a function provided by the Tealium tag.
       * @param {string} eventName (required) - Event name. Patterns are pl_{NAME} and ev_{NAME} for pageview events and non-pageview events respectively.
       * @param {object} eventData (optional) - Event data. If not specified, the function will try to get the event data from the last element of the window.digitalData.event array. If specified, hit_type property must be set to either 'view' or 'link' for pageview events and non-pageview events respectively.
       */

      w.digitalData?.track(eventName, {
        hit_type: HIT_TYPES.VIEW,
        data_page_name,
      });
    },

    isDigitalDataTrackLoaded(w),
    MAX_UTAG_RETRIES,
    RETRY_TIMEOUT
  );
};
