import { ReactElement } from "react";
import { FeatureTourTooltipProps } from "./components/FeatureTour/FeatureTourTooltip.component";

export interface FeatureTourState {
  show: boolean;
  step: number;
}

export interface FeatureTourConfig {
  pages: Pages;
}

export enum TourPageTypes {
  SEARCH_RESULTS_FINDABILITY = "search_results_findability",
  SEARCH_RESULTS_FINDABILITY_WITH_CATEGORY = "search_results_findability_with_category",
  OFFERS = "offers",
  OFFERS_ZONE_PAGE = "offers_zone_page",
  FAVOURITES = "favourites",
  NECTAR_DESTINATION = "nectar_destination",
  PRODUCT_DETAILS = "product_details",
  BROWSE_FEATURED = "browse_featured",
  BROWSE = "browse",
  BROWSE_ZONE = "browse_zone",
  BROWSE_DEPARTMENT = "browse_department",
  BROWSE_AISLE = "browse_aisle",
  BROWSE_SHELF = "browse_shelf",
}

export enum DisabledTourPageTypes {
  BEFORE_YOU_GO = "before_you_go",
  FORGOTTEN_FAVOURITES = "forgotten_favourites",
  PREVIOUS_ORDERS = "previous_orders",
  BRAND_SHELF = "brand_shelf",
}

export enum TourJourneyTypes {
  NORMAL = "normal",
  ENHANCED = "enhanced",
}

type Pages = {
  [key in TourPageTypes]: Page;
};

export type Page = {
  [TourJourneyTypes.NORMAL]: {
    [key in Device]?: Step[];
  };
  [TourJourneyTypes.ENHANCED]?: {
    [key in Device]?: Step[];
  };
};

export enum Device {
  MOBILE = "mobile",
  DESKTOP = "desktop",
}

export interface Step {
  header?: string | ReactElement;
  text: string | ReactElement;
  nextCTA?: CtaTypes;
  position?: FeatureTourTooltipProps["position"];
  tileIndex?: number;
  isLargeTooltip?: boolean;
  scrollToElement?: string;
  scrollToElementIndex?: number;
}

export type CtaTypes = "Next" | "Got it";

export interface FeatureTourSteps {
  [TourPageTypes.SEARCH_RESULTS_FINDABILITY]: DefaultSteps;
  [TourPageTypes.SEARCH_RESULTS_FINDABILITY_WITH_CATEGORY]: DefaultSteps;
  [TourPageTypes.OFFERS]: DefaultSteps;
  [TourPageTypes.NECTAR_DESTINATION]: DefaultSteps;
  [TourPageTypes.PRODUCT_DETAILS]: DefaultSteps;
  [TourPageTypes.BROWSE]: DefaultSteps;
  [TourPageTypes.BROWSE_FEATURED]: DefaultSteps;
  [TourPageTypes.BROWSE_ZONE]: DefaultSteps;
  [TourPageTypes.BROWSE_DEPARTMENT]: DefaultSteps;
  [TourPageTypes.BROWSE_AISLE]: DefaultSteps;
  [TourPageTypes.BROWSE_SHELF]: DefaultSteps;
}

export enum DefaultSteps {
  PRODUCT_TILE = 1,
  DESKTOP_MENU = 2,
  PRODUCT_CONTROL = 2,
}
