import React, { useState } from "react";
import { Drawer } from "@sainsburys-tech/fable";
import { MenuItem } from "../../Header.types";
import { ToggleButton } from "./ToggleButton";
import { HeaderSection } from "./HeaderSection";
import { BrowseSection } from "./BrowseSection";
import { GroceriesSection } from "./GroceriesSection";
import { MyAccountSection } from "./MyAccountSection";
import { MegaNavProps } from "../MegaNav.types";
import { useMegaNavLogic } from "../useMegaNavLogic";
import SeasonalLinksComponent from "../SeasonalLinks.component";
import MegaNavContentComponent from "../MegaNavContent.component";
import { useTrackCMSComponentAnalytics } from "../../../../common/hooks/useTrackCMSComponentAnalytics";
import { featureFlags } from "../../../../common/firebase";
import { useMegaNavActions } from "../../headerState/header.actions";

function getActive(L0: MenuItem | undefined, L1: MenuItem | null, L2: MenuItem | null) {
  if (L2?.id) {
    return { level: 3, item: L2 };
  } else if (L1?.id) {
    return { level: 2, item: L1 };
  } else if (L0?.id) {
    return { level: 1, item: L0 };
  } else {
    return { level: 0, item: undefined };
  }
}

export const MobileMegaNav: React.FC<MegaNavProps> = ({
  ads,
  seasonal,
  isOpen,
  open,
  onClose,
  navItems,
  promotionalLink,
  isLoggedIn,
}) => {
  const [selectedLevel0, setSelectedLevel0] = useState<MenuItem>();
  const { handleCategorySelect, handleSubcategorySelect, selectedSubcategory, selectedCategory } =
    useMegaNavLogic(navItems);
  const { setSelectedMeganavCategory } = useMegaNavActions();

  const isSeasonal = selectedLevel0?.title === "Events & Inspiration";
  const isGroceries = selectedLevel0?.title === "Groceries";

  const { level: currentLevel, item: selected } = getActive(selectedLevel0, selectedCategory, selectedSubcategory);

  /** Moves the current level back from any view */
  function handleBack() {
    switch (currentLevel) {
      case 1:
        setSelectedLevel0(undefined);
        handleCategorySelect(null);
        setSelectedMeganavCategory(null);
        handleSubcategorySelect(null);
        break;
      case 2:
        handleSubcategorySelect(null);
        handleCategorySelect(null);
        setSelectedMeganavCategory(null);
        break;
      case 3:
        handleSubcategorySelect(null);
    }
  }

  /** Handles selection for any view under the mobile nav */
  function handleSelect(item?: MenuItem) {
    if (!item || !item.items?.length) {
      handleGroceriesClose();
    } else if (!selectedLevel0?.id) {
      setSelectedLevel0(item);
    } else if (!selectedCategory?.id) {
      handleCategorySelect(item);
      setSelectedMeganavCategory(item);
    } else if (!selectedSubcategory?.id) {
      handleSubcategorySelect(item);
    }
  }

  /** This logic sets the items which will be displayed under any view. We check through the
   * category selection hierarchy from top to bottom, and display the deepest nested elements,
   * until we reach the basic navigation items. */
  const toRender = React.useMemo(() => {
    if (selectedSubcategory?.items?.length) {
      return selectedSubcategory.items;
    } else if (selectedCategory?.items?.length) {
      return selectedCategory.items;
    } else if (selectedLevel0?.items?.length) {
      return selectedLevel0.items;
    } else {
      const items = [...navItems];
      if (seasonal) {
        const seasonalItem = {
          title: "Events & Inspiration",
          id: "seasonal-inspiration",
          url: "",
          items: [{} as MenuItem],
        };

        // Insert the item at index 1, right after "Groceries"
        items.splice(1, 0, seasonalItem);
      }
      return items;
    }
  }, [navItems, seasonal, selectedLevel0, selectedCategory, selectedSubcategory]);

  const handleGroceriesClose = () => {
    setSelectedLevel0(undefined);
    handleCategorySelect(null);
    setSelectedMeganavCategory(null);
    handleSubcategorySelect(null);
    onClose();
  };

  // Send load and view analytics for content on meganav
  useTrackCMSComponentAnalytics(ads.length > 0);

  const displayGroceries = Boolean(!isSeasonal && currentLevel > 0);
  const displayBrowse = Boolean(!isSeasonal && currentLevel === 0);
  const displaySeasonal = Boolean(seasonal && isSeasonal);
  const displayAds = Boolean(ads?.length && (currentLevel === 0 || (currentLevel < 3 && isGroceries)));
  const showHomeSuffix = Boolean(currentLevel === 1 || currentLevel === 2);
  const displayAccount = Boolean(currentLevel === 0 && isLoggedIn);

  const promotionalLink2 = featureFlags.get("promotional_link2");

  return (
    <>
      <ToggleButton onClick={open} aria-label="Open navigation menu" />
      <Drawer
        onClose={handleGroceriesClose}
        data-testid="mega-nav-drawer"
        isOpen={isOpen}
        className="megaNavDrawer"
        aria-label="Navigation menu"
        hasContentPaddingOverride={true}
      >
        <nav className="megaNavDrawer__menu" aria-label="Main navigation">
          <HeaderSection
            isLoggedIn={Boolean(isLoggedIn)}
            onClose={handleGroceriesClose}
            onBack={handleBack}
            isInSubmenu={currentLevel > 0}
            title={selectedLevel0?.title ?? "Browse"}
          />
          <ul className="megaNavDrawer__list" data-testid="mobile-nav-links">
            {displayGroceries && (
              <GroceriesSection
                items={toRender}
                title={selected?.title}
                titleUrl={selected?.url}
                onSelected={handleSelect}
                showHomeSuffix={showHomeSuffix}
              />
            )}
            {displayBrowse && (
              <BrowseSection
                items={toRender}
                onSelected={handleSelect}
                promotionalLink={promotionalLink ?? false}
                promotionalLink2={promotionalLink2}
              />
            )}
          </ul>
          {displaySeasonal && <SeasonalLinksComponent seasonal={seasonal!} />}
          {displayAccount && <MyAccountSection onClick={handleGroceriesClose} />}
          {displayAds && <MegaNavContentComponent ads={ads} />}
        </nav>
      </Drawer>
    </>
  );
};
