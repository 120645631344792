import React from "react";
import { useDispatch } from "react-redux";
import { BookDeliveryContainerProps } from "./BookDelivery.types";
import { BookDeliveryResponsiveWrapper } from "./BookDelivery.component";
import { bookSlotAction, chooseShoppingMethodNavigationAction } from "./bookDelivery.actions";
import { getBookSlotUrl, useSlotReservation } from "../../../domain/slot/slotReservation";
import { mapDeliveryTypeText } from "./BookDelivery";
import { DataState } from "../../../common/dataState";
import { SlotReservationType } from "../../../domain/slot/slotReservation.types";

export const BookDeliveryContainer = () => {
  const { slotReservation, dataState } = useSlotReservation();
  const dispatch = useDispatch();

  const props: BookDeliveryContainerProps = {
    deliveryDate: slotReservation.reservationDate,
    deliveryTime: slotReservation.reservationTimeSlot,
    deliveryIsExpired: slotReservation.reservationExpired,
    isPending: dataState === DataState.PENDING,
    isLoaded: dataState === DataState.SUCCESS && slotReservation.reservationType !== SlotReservationType.NONE,
    isFailed: dataState === DataState.FAILED,
    deliveryTypeText: mapDeliveryTypeText(slotReservation),
    isNoBooking: dataState === DataState.SUCCESS && slotReservation.reservationType === SlotReservationType.NONE,
    isGreen: slotReservation.reservationIsGreen,
    bookSlotUrl: getBookSlotUrl(slotReservation),
    isDelivery: slotReservation.reservationType === SlotReservationType.DELIVERY,
    bookSlot: () => {
      dispatch(bookSlotAction);
    },
    chooseDeliveryMethod: () => {
      dispatch(chooseShoppingMethodNavigationAction);
    },
  };

  return <BookDeliveryResponsiveWrapper {...props} />;
};
