export const sortAds = (ads: any) => {
  const order = [
    "top_1",
    "top_1a",
    "top_1b",
    "top_1c",
    "in_grid_1",
    "bottom_1a",
    "bottom_1b",
    "bottom_1c",
    "bottom_2",
    "bottom_3",
  ];
  if (!ads.length) return [];
  const sorted = ads.sort((a: any, b: any) => order.indexOf(a.placement) - order.indexOf(b.placement));
  return sorted;
};

export const ESPOT_IDS = {
  S_below_grid_1: [
    "NZ_Search_Responsive_Bottom_1a",
    "NZ_Search_Responsive_Bottom_1b",
    "NZ_Search_Responsive_Bottom_1c",
  ],
  Favourites_bottom_1: [
    "NZ_Favourites_Responsive_Bottom_1a",
    "NZ_Favourites_Responsive_Bottom_1b",
    "NZ_Favourites_Responsive_Bottom_1c",
  ],
  Offers_above_grid_1: [
    "NZ_Offers_Landing_Responsive_ESpot_Top1a",
    "NZ_Offers_Landing_Responsive_ESpot_Top1b",
    "NZ_Offers_Landing_Responsive_ESpot_Top1c",
  ],
  Offers_below_grid_1: [
    "NZ_Offers_Landing_Responsive_ESpot_Bottom1a",
    "NZ_Offers_Landing_Responsive_ESpot_Bottom1b",
    "NZ_Offers_Landing_Responsive_ESpot_Bottom1c",
  ],
  Shelf_in_grid_1: ["Shelf_in_grid_top", "Shelf_in_grid_bottom"],
  Event_in_grid_1: ["Event_in_grid_top", "Event_in_grid_bottom"],
  Search_in_grid_1: ["NZ_Search_Responsive_Grid_1_By_SearchTerm", "NZ_Search_Responsive_Grid_2_By_SearchTerm"],
  Favourites_in_grid_1: ["NZ_Favourites_Responsive_Grid_1", "NZ_Favourites_Responsive_Grid_2"],
};
