import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

/**
 * When introducing a new query string param to the app please add it to the QueryParams type.
 * This will build up a list of all of the known query string parameters used within the app which will be helpful for developers.
 */

export type QueryParams =
  | "currentPageUrl"
  | "postCode"
  | "postcode"
  | "success"
  | "err"
  | "isCheckout"
  | "isDeliveryPass"
  | "iframe"
  | "start_date"
  // Temporary query param to test pdp accordions
  | "accordions";

type DeleteQueryParam = () => void;

export type QueryParam = string | null;

export const useQueryParam = (
  queryStringParam: QueryParams,
  shouldParseValue = false
): { queryParam: QueryParam; deleteQueryParam: DeleteQueryParam } => {
  const { search } = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const queryParam = useMemo(() => {
    const parsedValue = query.get(queryStringParam);

    if (parsedValue) {
      return shouldParseValue ? parsedValue : encodeURIComponent(parsedValue);
    }

    return null;
  }, [query, queryStringParam, shouldParseValue]);

  const deleteQueryParam = useCallback(() => {
    if (queryParam) {
      query.delete(queryStringParam);
      history.replace({
        search: query.toString(),
      });
    }
  }, [history, query, queryParam, queryStringParam]);

  return { queryParam, deleteQueryParam };
};
