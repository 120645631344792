import { Action } from "redux";
import { ActionTypes, FetchMenuItemsSuccessAction } from "./MenuItem.actions";
import { CategoriesState } from "../../common/store";

const initialState: CategoriesState = [];

export function menuItemsReducer(
  state: CategoriesState | undefined = initialState,
  action: FetchMenuItemsSuccessAction | Action
): CategoriesState {
  switch (action.type) {
    case ActionTypes.FETCH_MENU_ITEMS_SUCCESS:
      const fetchMenuItemsAction = action as FetchMenuItemsSuccessAction;
      return [...(fetchMenuItemsAction.menuItems.items || [])];
    case ActionTypes.FETCH_MENU_ITEMS_FAILURE:
      return initialState;
    default:
      return state;
  }
}
