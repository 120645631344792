import { FormStateOptions, isNotEmpty } from "../../common/forms";

const POSTCODE_ERROR_MESSAGE = "Please enter a valid UK postcode.";
const MOBILE_NUMBER_ERROR_MESSAGE = "Please enter a mobile number between 10 and 20 characters.";

export const isValidMobileNumber = (value: string) => /^\d{10,20}$/.test(value);

export const progressiveRegistrationFormConfig: FormStateOptions = {
  mobileNumber: {
    value: "",
    error: MOBILE_NUMBER_ERROR_MESSAGE,
    warning: MOBILE_NUMBER_ERROR_MESSAGE,
    validator: isValidMobileNumber,
  },
  termsChecked: {
    value: false,
    error: "Please accept terms and conditions",
    validator: isNotEmpty,
  },
  password: {
    value: "",
    error: "Please enter your Nectar password.",
    validator: isNotEmpty,
  },
  postcode: {
    value: "",
    error: POSTCODE_ERROR_MESSAGE,
    warning: POSTCODE_ERROR_MESSAGE,
    validator: isNotEmpty,
  },
  // More options will be added dynamically for CPM
};
