import React from "react";
import { SainsburysLogo as Logo } from "../../components/Logo";
import { SearchBarContainer } from "../../components/Header/SearchBar/SearchBar.container";
import { MiniTrolleyContainer } from "../../components/Header/MiniTrolley/MiniTrolley.container";
import { Mobile, NonMobile } from "../../components/ResponsiveProvider";
import { GOLLink } from "../../components/GOLLink";
import { ContentSkipTo, CTASkipTo } from "../../components/Header/AccessibleSkipTo.component";
import { HeaderWrapper } from "../../components/Header/HeaderWrapper/HeaderWrapper";

export const CheckoutHeader = () => {
  return (
    <HeaderWrapper className={"checkout-header ln-c-card--soft header__card"}>
      <div className="checkout-header__skipto-links">
        <ContentSkipTo />
        <CTASkipTo />
      </div>
      <div className="checkout-header__content">
        <div className="checkout-header__item">
          <GOLLink to="/shop/gb/groceries" data-testid="checkout-header-home-link" external>
            <Logo />
          </GOLLink>
        </div>
        <Mobile>
          <div className="checkout-header__mini-trolley">
            <MiniTrolleyContainer />
          </div>
        </Mobile>
        <div className="checkout-header__search">
          <SearchBarContainer checkoutHeader={true} />
        </div>
        <NonMobile>
          <div className="checkout-header__item checkout-header__mini-trolley">
            <MiniTrolleyContainer />
          </div>
        </NonMobile>
      </div>
    </HeaderWrapper>
  );
};
