import { DataState } from "../../../../common/dataState";
import { RestingSearchV2 } from "../hooks/useTrendingSearch.hook";

export const restingSearchV2TestFeatureFlag: RestingSearchV2 = {
  suggested_enabled: true,
  magnolia_enabled: true,
  track_experiment: true,
  status: DataState.SUCCESS,
  terms: ["chocolate", "bread", "lamb"],
};

export interface TrendingSearch {
  START_DT: string;
  SEARCH_TERM: string;
}

export interface RestingSearch {
  suggested_enabled: boolean;
  magnolia_enabled: boolean;
  track_experiment: boolean;
  terms: TrendingSearch[];
}

export const trendingSearchWeek1 = [
  { START_DT: "30/09/2024", SEARCH_TERM: "apple" },
  { START_DT: "30/09/2024", SEARCH_TERM: "banana" },
];

export const trendingSearchWeek2 = [
  { START_DT: "07/10/2024", SEARCH_TERM: "curry" },
  { START_DT: "07/10/2024", SEARCH_TERM: "donut" },
];

export const trendingSearchWeek3 = [
  { START_DT: "14/10/2024", SEARCH_TERM: "energy" },
  { START_DT: "14/10/2024", SEARCH_TERM: "food" },
];

export const trendingSearchWeek4 = [
  { START_DT: "21/10/2024", SEARCH_TERM: "garlic" },
  { START_DT: "21/10/2024", SEARCH_TERM: "hoops" },
];

export const trendingSearchWeek5 = [
  { START_DT: "28/10/2024", SEARCH_TERM: "instant" },
  { START_DT: "28/10/2024", SEARCH_TERM: "jam" },
];

export const restingSearchTestFeatureFlag: RestingSearchV2 = {
  suggested_enabled: true,
  magnolia_enabled: true,
  track_experiment: true,
  status: DataState.SUCCESS,
  terms: ["apple", "banana", "milk"],
};

export const restingSearchTestFeatureFlagFilter: RestingSearch = {
  suggested_enabled: true,
  magnolia_enabled: true,
  track_experiment: true,
  terms: [
    ...trendingSearchWeek1,
    ...trendingSearchWeek2,
    ...trendingSearchWeek3,
    ...trendingSearchWeek4,
    ...trendingSearchWeek5,
  ],
};

const getLastMonday = () => {
  const date = new Date();
  const today = date.getDay();
  const previousMonday = new Date();
  if (date.getDay() === 0) {
    previousMonday.setDate(date.getDate() - 6);
  } else {
    previousMonday.setDate(date.getDate() - (today - 1));
  }

  return new Date(previousMonday.toDateString());
};

// util to grab last monday's trending searches
export const filterTrendingSearchestoCurrentWeek = (restingSearch: RestingSearch): RestingSearch => {
  if (!restingSearch.suggested_enabled) return restingSearch;
  const lastMonday = getLastMonday();
  return {
    ...restingSearch,
    terms: restingSearch.terms.filter(trendingSearch => {
      const trendingSearchStartDate = trendingSearch.START_DT;
      const splitStartDate = trendingSearchStartDate.split("/");
      const startDate = new Date(Number(splitStartDate[2]), Number(splitStartDate[1]) - 1, Number(splitStartDate[0]));
      return startDate.getTime() === lastMonday.getTime();
    }),
  };
};
