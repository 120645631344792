import React, { ErrorInfo, ReactNode } from "react";
import { NewRelicClient } from "../../utils/newRelicClient";

export enum AdErrorBoundaryBannerTypes {
  CITRUS = "CITRUS",
  ESPOT = "ESPOT",
}
interface AdErrorBoundaryProps {
  children: ReactNode;
  bannerType: AdErrorBoundaryBannerTypes;
}

interface State {
  hasError: boolean;
}

export class AdErrorBoundary extends React.Component<AdErrorBoundaryProps, State> {
  errorReporter: typeof NewRelicClient["noticeError"];

  constructor(props: AdErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.errorReporter = NewRelicClient.noticeError;
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info.componentStack);

    const { children, ...propsWithoutChildren } = this.props;

    const prefixProps = Object.keys(propsWithoutChildren).reduce(
      (acc, curr) => ({ ...acc, [`props_${curr}`]: propsWithoutChildren[curr] }),
      {}
    );

    this.errorReporter?.(error, { adError: true, ...prefixProps });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
