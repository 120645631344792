import { EspotState } from "../../common/store";
import { AnyAction } from "redux";
import { EspotActionTypes } from "./espot.actions";
import { InterleavedEspot } from "./Espot.types";

export const initialState: EspotState = {
  espots: [
    {
      espotParams: {
        name: "NZ_Search_Responsive_Grid_1_By_SearchTerm",
      },
      available: true,
      insertSequence: 3,
      categoriesPosition: { rowIndex: 0, insertSequence: 3 },
    },
  ],
};

const modifyAvailability = (espots: InterleavedEspot[], name: string, available: boolean) =>
  espots.map((spot: InterleavedEspot) =>
    spot.espotParams.name !== name
      ? spot
      : {
          ...spot,
          available,
        }
  );

export const espotReducer = (state: EspotState | undefined = initialState, action: AnyAction) => {
  switch (action.type) {
    case EspotActionTypes.UNAVAILABLE:
      return {
        ...state,
        espots: modifyAvailability(state.espots, action.payload, false),
      };
    case EspotActionTypes.AVAILABLE:
      return {
        ...state,
        espots: state.espots.map((spot: InterleavedEspot) => ({
          ...spot,
          available: true,
        })),
      };
    default:
      return state;
  }
};
