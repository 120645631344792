import { PageViewProperties } from "./analytics";

export type AdobeDigitalDataProperties = {
  data_search_term?: string;
  data_search_method?: string;
  data_page_number?: string | number;
  data_page_name?: string;
  data_productList_categories?: string;
  data_productList_filters?: string;
  data_productList_numberOfProducts?: string | number;
  data_productList_numberOfProductsShown?: string | number;
  data_siteSection?: string;
  data_sortBy?: string;
  data_page_type?: string;
  data_shoppingMode?: string;
  data_slot_minimumLeadTime?: string | number;
  data_search_typedTerm?: string;
  data_slot_price?: number;
  data_slot_type?: string;
  data_slot_booked?: boolean;
  data_user_loginStatus?: string;
  data_user_customerId?: string;
  data_customerMessage?: string;
  data_xmas_guide_version?: string;
};

export enum HIT_TYPES {
  LINK = "link",
  VIEW = "view",
}

export enum SHOPPING_MODES {
  NORMAL = "normal",
  AMEND = "amend",
}

export enum PAGE_LOAD_EVENT_NAMES {
  NECTAR_DESTINATION = "pl_nectarDestination",
  MEAL_DEALS_HUB = "pl_mealDealHub",
  RECIPE_SCRAPBOOK = "pl_recipes",
  PL_AUTH_REGISTER_CONFIRMATION = "pl_authRegisterConfirmation",
  PL_AUTH_REGISTER_SELECT_PREFERENCES = "pl_authRegisterSelectPreferences",
  PL_AUTH_LOGIN_REDIRECT = "pl_authLoginRedirect",
  PL_AUTH_LOGOUT = "pl_authLogout",
  PL_AUTH_LOGOUT_REDIRECT = "pl_authLogoutRedirect",
  PL_AUTH_LOGIN = "pl_authLogin",
  PL_SHOPPING_METHOD = "pl_shoppingMethod",
  PL_MEAL_PLANNER_HUB = "pl_mealPlannerHub",
  PL_HOMEPAGE = "pl_homepage",
  PL_SEARCH = "pl_search",
  PL_MULTI_SEARCH = "pl_multisearch",
  PL_PROMO_LISTER = "pl_promo",
  PL_XMAS_SLOT_GUIDE = "pl_xmasSlotGuide",
  PL_PAYMENT_AUTHORISATION = "pl_paymentAuthorisation",
}

export enum INTERACTION_EVENT_NAMES {
  NECTAR_DESTINATION_CTA = "ev_nectarDestinationCta",
  JOIN_NECTAR_WIDGET_CTA = "ev_joinNectarWidgetCta",
  PRODUCT_VIEW = "ev_productView",
  ADD_TO_CART = "ev_addToCart",
  ADD_FAVOURITE = "ev_addFavouriteProduct",
  REMOVE_FAVOURITE = "ev_removeFavouriteProduct",
  SORT_FAVOURITES = "ev_sortByInteraction",
  FEATURE_TOUR = "ev_addFavouriteFeatureTour",
  OFFER_LINK_CTA_CLICK = "ev_offerLinkCtaClick",
  MISSED_OFFER_LINK_CTA_CLICK = "ev_missedOfferLinkCtaClick",
  SEARCH_INTERACTION = "SearchInteraction",
  CHECKOUT_BUTTON = "checkout button",
  CAROUSEL_DISMISS_BUTTON = "carousel dismiss button",
  CAROUSEL_SWIPER_ARROW = "carousel swiper arrow",
  CMS_COMPONENT_LOAD = "ev_cmsComponentLoad",
  CMS_COMPONENT_VIEW = "ev_cmsComponentView",
  CMS_COMPONENT_CLICK = "ev_cmsComponentClick",
  ACCORDION_CLICK = "ev_accordion",
}

export enum AnalyticsActionTypes {
  PAGE_VIEW = "PAGE_VIEW",
  DIGITAL_DATA_TRACK_PAGE_LOAD = "DIGITAL_DATA_TRACK_PAGE_LOAD",
  DIGITAL_DATA_TRACK_EVENT = "DIGITAL_DATA_TRACK_EVENT",
  DIGITAL_DATA_LIGHTBOX_VIEW_TRACK_EVENT = "DIGITAL_DATA_LIGHTBOX_VIEW_TRACK_EVENT",
}

export type AnalyticsTrackPageLoadAction = {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: PageViewProperties;
  eventName: PAGE_LOAD_EVENT_NAMES;
  adobeProperties?: AdobeDigitalDataProperties;
};

export type AnalyticsTrackEventAction = {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_EVENT;
  eventName: INTERACTION_EVENT_NAMES;
  data_event_category: string;
  data_event_action: string;
  data_event_label: string;
};

export type AnalyticsTrackLightboxViewEventAction = {
  type: AnalyticsActionTypes.DIGITAL_DATA_LIGHTBOX_VIEW_TRACK_EVENT;
  eventName: string;
  data_page_name: string;
};

export type AnalyticsPageViewAction = {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties;
  adobeProperties?: AdobeDigitalDataProperties;
};

export enum SearchLocations {
  SEARCH_BAR_NEW = "SearchBarNew",
  MULTI_SEARCH = "MultiSearch",
}
