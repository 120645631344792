import { useCallback } from "react";
import { useSelector } from "react-redux";
import { State, useServiceRequestCallback } from "../../../services/hooks";
import { FetchMagnoliaTemplateParams, MagnoliaTemplate, fetchMagnoliaTemplate } from "../../../services/content";
import { selectHasNectarAssociated, selectIsDigitalCustomer, selectIsGuestCustomer } from "../../nectar";

type UseMagnoliaTemplateRequestParams = Partial<FetchMagnoliaTemplateParams> & {
  template: FetchMagnoliaTemplateParams["template"];
};

export const useMagnoliaTemplateRequest = (): [
  State<MagnoliaTemplate>,
  (params: UseMagnoliaTemplateRequestParams) => void
] => {
  const hasNectarAssociated = useSelector(selectHasNectarAssociated);
  const isDigitalCustomer = useSelector(selectIsDigitalCustomer);
  const isGuestCustomer = useSelector(selectIsGuestCustomer);

  const [request, doRequest] = useServiceRequestCallback(fetchMagnoliaTemplate);

  const makeRequest = useCallback(
    (params: UseMagnoliaTemplateRequestParams) => {
      doRequest({ isGuestCustomer, hasNectarAssociated, isDigitalCustomer, ...params });
    },
    [doRequest, hasNectarAssociated, isDigitalCustomer, isGuestCustomer]
  );

  return [request, makeRequest];
};
