import { useEffect, useMemo, useState } from "react";
import {
  BrowseHookResponse,
  BrowseHookResponseData,
  BrowseNodeType,
  BrowsePageType,
  BrowsePathSubstrings,
  BrowseTaxonomyType,
  isValidPath,
  ParsedBrowseNode,
  ParsedBrowseTaxonomyState,
  RequestFiltersWithMeta,
  ZoneCitrusResponse,
} from "./Browse.types";
import { FilterVariations, PathFilters } from "../filters.types";
import FiltersAdapter from "../filters.utils";
import {
  fetchMagnoliaContentForBrowse,
  getBrowseSubstrings,
  getSortOptions,
  isNewShelf,
  isSpecialOffersShelf,
  stringToFiltersControls,
} from "./Browse.utils";
import { useDispatch, useSelector } from "react-redux";
import { isSlotReservationPending, selectSlot } from "../slot.selectors";
import GolTime from "../../domain/time";
import { DataState } from "../../common/dataState";
import {
  createBrowseIngridAdFailureAction,
  createBrowseIngridAdRequestAction,
  createBrowseIngridAdResetAction,
  createBrowseIngridAdSuccessAction,
} from "./BrowseMagnoliaContent/BrowseAd.actions";
import {
  BrowseMagnoliaRequestProps,
  BrowseMagnoliaData,
  BrowseMagnoliaResponse,
} from "./BrowseMagnoliaContent/BrowseMagnolia.type";
import { browsePathRedirects } from "./BrowseRedirect";
import { fetchBrowseZoneCitrusData } from "../../services/product";
import { Result } from "../../common/http/request";
import { Banners } from "../../components/Citrus/BannerAd/BannerAd.types";
import { AdsResponseEnvelope } from "../../services/content";
import { getCachedSlotReservation } from "../../domain/slot/slotReservation";
import { CachedSlotReservation } from "../../domain/slot/slotReservation.types";
import {
  selectHasNectarAssociated,
  selectIsDigitalCustomer,
  selectIsDeliveryPassCustomer,
  selectIsGuestCustomer,
  selectIsVeryImportantCustomer,
  selectUserDataStatus,
} from "../../common/nectar";
import { getBrowseCategoryIds } from "./BrowseZone/BrowseZone.config";
import { JSSanitise } from "../../utils/JSSanitise";

/*
    Background:
      For the purpose of Search Engine Optimisation (SEO), the Browse/Shelf page URLs are constructed to be
      highly-readable by both humans and machine, and index-able by crawlers.
      This is achieved by using path params exclusively, instead of query params. Expected path params include:
        1. a directory tree where the current page is the leaf
        2. a page-specific category ID
        3. a list of controls for filtering and sorting the page
*/

const singleKeyHasMultipleValues = (val: string[][]) => val.filter(v => v.length >= 2).length > 0;
const hasMoreThanTwoKeys = (filters: PathFilters): boolean => Object.keys(filters).length > 2;

export const shouldDeterIndexing = (filters: PathFilters | undefined): boolean => {
  if (filters === undefined) return false;
  if (hasMoreThanTwoKeys(filters)) return true;
  const valueSets = Object.values(filters)
    .filter(fil => typeof fil === "string")
    .map(fil => fil.split(","));

  if (singleKeyHasMultipleValues(valueSets)) return true;

  const accumulator: number = filters.offers ? 1 : 0;
  return valueSets.reduce((a, b) => a + b.length, accumulator) > 2;
};

// useBrowsePath expects a valid Browse page URL and extracts from it the data required by the page in question.
export const useBrowsePath = (path: string): BrowseHookResponse => {
  const substrings: BrowsePathSubstrings = useMemo(() => getBrowseSubstrings(path), [path]);
  const { tree, browseID, filters: filterString, options: sortingOptions, landingURL } = substrings;
  const isSpecialOffersPage = isSpecialOffersShelf(tree);
  const isNewPage = isNewShelf(tree);
  const filters = useMemo(
    () => stringToFiltersControls(filterString, isSpecialOffersPage, isNewPage),
    [filterString, isSpecialOffersPage, isNewPage]
  );
  const noIndex: boolean = useMemo(() => shouldDeterIndexing(filters), [filters]);
  const { argsFilters, metaFilters }: RequestFiltersWithMeta = useMemo(() => {
    if (!filters) return { argsFilters: {}, metaFilters: {} };
    const argsFilters = FiltersAdapter(filters, FilterVariations.RequestFilters, true);
    const metaFilters = FiltersAdapter(filters, FilterVariations.RequestFilters, false);
    return { argsFilters, metaFilters };
  }, [filters]);
  const { page, sort } = useMemo(() => getSortOptions(sortingOptions), [sortingOptions]);

  if (!isValidPath(path) || JSSanitise.string(tree) !== tree) {
    return { data: {} as BrowseHookResponseData, err: "Invalid Path" };
  }

  return {
    data: {
      tree,
      browseID,
      argsFilters,
      metaFilters,
      page,
      sort,
      landingURL,
      noIndex,
    },
  };
};

export const useBrowseAdFetch = (pageType: BrowsePageType, categoryID: string, browseID: string) => {
  const [magnoliaData, setMagnoliaData] = useState<BrowseMagnoliaData | undefined>(undefined);
  const dispatch = useDispatch();
  // Using cached kslot reservation to check if userlogged in because useSelector asynchronously
  // provides slot data which causes ads cal to happen twice. This check prevents extra ads call
  // without store information just because useSelector provides slot data in successive renders
  const slot = useSelector(selectSlot);
  const region: string | undefined = slot ? slot.region : undefined;
  const storeId: string | undefined = slot ? slot.storeIdentifier : undefined;
  const deliveryDate: string | undefined =
    slot && slot.reservationStartTime ? new GolTime(slot.reservationStartTime).format("YYYY-MM-DD") : undefined;

  const isGuestCustomer = useSelector(selectIsGuestCustomer);
  const hasNectar = useSelector(selectHasNectarAssociated);
  const isDigitalCustomer = useSelector(selectIsDigitalCustomer);
  const hasDeliveryPass = useSelector(selectIsDeliveryPassCustomer);
  const isVeryImportantCustomer = useSelector(selectIsVeryImportantCustomer);
  const userDataStatus = useSelector(selectUserDataStatus);
  const isSlotPending = useSelector(isSlotReservationPending);

  useEffect(() => {
    const fetchBrowseAds = async () => {
      dispatch(createBrowseIngridAdRequestAction());
      const browseCategoryId = getBrowseCategoryIds(browseID);
      const browseAdPayload: BrowseMagnoliaRequestProps = {
        categoryID: browseCategoryId ? browseCategoryId : categoryID,
        browseID,
        storeId,
        deliveryDate,
        region,
        ...(!isGuestCustomer && {
          hasNectar,
          digitalCustomer: isDigitalCustomer,
          hasDeliveryPass,
          isVeryImportantCustomer,
        }),
        pageType,
      };
      const result: Result<BrowseMagnoliaResponse> = await fetchMagnoliaContentForBrowse(browseAdPayload);
      if (result.isSuccess()) {
        setMagnoliaData({
          meta: result.data.meta,
          aboveGrid: result.data.aboveGrid,
          belowGrid: result.data.belowGrid,
          aboveGridSecondary: result.data.aboveGridSecondary,
        });
        result.data.ads
          ? dispatch(createBrowseIngridAdSuccessAction(result.data.ads))
          : dispatch(createBrowseIngridAdFailureAction());
      } else {
        dispatch(createBrowseIngridAdFailureAction());
      }
    };
    if (browseID && userDataStatus !== DataState.PENDING && !isSlotPending) fetchBrowseAds();
    return () => {
      dispatch(createBrowseIngridAdResetAction());
    };
    // TO-DO: refactor when useEffectEvent is stable see https://react.dev/reference/react/experimental_useEffectEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browseID, storeId, deliveryDate, isSlotPending, userDataStatus]);
  return magnoliaData;
};

const defineNodeType = (lookups: number[], findabilityID: string): BrowseNodeType => {
  if (!findabilityID.includes(",")) return { taxonomyType: BrowseTaxonomyType.PLP, pageType: BrowsePageType.SHELF };
  switch (lookups.length) {
    case 1:
      return { taxonomyType: BrowseTaxonomyType.TLC, pageType: BrowsePageType.ZONE };
    case 2:
      return { taxonomyType: BrowseTaxonomyType.CLP, pageType: BrowsePageType.DEPARTMENT };
    case 3:
      return { taxonomyType: BrowseTaxonomyType.PLP, pageType: BrowsePageType.AISLE };
    default:
      return { taxonomyType: BrowseTaxonomyType.UNKNOWN, pageType: BrowsePageType.UNKNOWN };
  }
};

export const getBrowseNodeInfo = (
  key: string | null,
  nodeMap: ParsedBrowseTaxonomyState | undefined
): ParsedBrowseNode => {
  if (!key || !nodeMap) return {} as ParsedBrowseNode;
  const values = nodeMap[key];
  if (!values) return {} as ParsedBrowseNode;

  const { lookups, findabilityID } = values;
  const nodeType = defineNodeType(lookups, `${findabilityID}`);
  return { ...nodeType, ...values };
};

// There are several Browse URLS which has special characters
// But the URL isn't supposed to have special characters
// This hook utilizes the already defined redirect urls which basically takes
// the standard URL and replaces it with URL having special characters.

export const useBrowsePathRedirect = (pathname: string, getLandingUrl?: boolean) => {
  const categorySplit = pathname.split("/").pop();
  if (getLandingUrl) {
    const redirectObject = browsePathRedirects.find(redirectObj => pathname.includes(redirectObj.redirectSynapticaURL));
    if (redirectObject && categorySplit) return redirectObject.landingURL.concat("/", categorySplit);
    return pathname;
  }
  const redirectObject = browsePathRedirects.find(redirectObj => pathname.includes(redirectObj.landingURL));
  if (redirectObject && categorySplit) return redirectObject.redirectURL.concat("/", categorySplit);
  return pathname;
};

export const useBrowseZoneCitrusFetch = (pageType: BrowsePageType, findabilityID?: string) => {
  const [data, setData] = useState<AdsResponseEnvelope<Banners>[] | undefined>();
  const { storeIdentifier, slotDate, region } = useMemo(
    () => getCachedSlotReservation() || ({} as CachedSlotReservation),
    []
  );

  useEffect(() => {
    if (findabilityID && pageType === BrowsePageType.ZONE) {
      const fetchCitrusData = async () => {
        const result: Result<ZoneCitrusResponse> = await fetchBrowseZoneCitrusData({
          storeIdentifier,
          slotDate,
          region,
          findabilityID,
        });
        if (result.isSuccess()) {
          setData(result.data.ads);
        }
      };
      fetchCitrusData();
    }
  }, [storeIdentifier, slotDate, region, findabilityID, pageType]);
  if (pageType !== BrowsePageType.ZONE || !findabilityID) return;
  return data;
};
