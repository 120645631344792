import { DataState } from "../../common/dataState";
import { AnyAction } from "redux";
import {
  FetchRecurringSlotSuccess,
  RecurringSlotPreferenceActionTypes,
  RecurringSlotState,
} from "./RecurringSlot.types";

export const recurringSlotInitialState: RecurringSlotState = {
  optIn: {
    dataState: DataState.UNKNOWN,
  },
  optOut: {
    dataState: DataState.UNKNOWN,
  },
  current: {
    dataState: DataState.UNKNOWN,
    eligible: undefined,
    registered: undefined,
  },
};

export const recurringSlotReducer = (
  state: RecurringSlotState = recurringSlotInitialState,
  action: AnyAction
): RecurringSlotState => {
  switch (action.type) {
    case RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT: {
      return {
        ...state,
        optIn: { dataState: DataState.PENDING },
      };
    }

    case RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT_SUCCESS: {
      return {
        ...state,
        optIn: { dataState: DataState.SUCCESS },
      };
    }

    case RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT_FAILED: {
      return {
        ...state,
        optIn: { dataState: DataState.FAILED },
      };
    }

    case RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT: {
      return {
        ...state,
        optOut: { dataState: DataState.PENDING },
      };
    }

    case RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_SUCCESS: {
      return {
        ...state,
        optOut: { dataState: DataState.SUCCESS },
      };
    }

    case RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_FAILED: {
      return {
        ...state,
        optOut: { dataState: DataState.FAILED },
      };
    }

    case RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_PENDING: {
      return {
        ...state,
        optOut: { dataState: DataState.PENDING },
      };
    }

    case RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_PENDING: {
      return {
        ...state,
        current: {
          ...state.current,
          dataState: DataState.PENDING,
        },
      };
    }

    case RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_FAILED: {
      return {
        ...state,
        current: {
          ...state.current,
          dataState: DataState.FAILED,
        },
      };
    }

    case RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_SUCCESS: {
      const successAction = action as FetchRecurringSlotSuccess;

      return {
        ...state,
        current: {
          ...state.current,
          dataState: DataState.SUCCESS,
          eligible: successAction.recurringSlotPreferences.eligible,
          registered: successAction.recurringSlotPreferences.registered,
        },
      };
    }

    default:
      return state;
  }
};
