import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { FetchSlotReservationSuccessAction, SlotReservationActionTypes } from "../../domain/slot/slotReservation.types";
import { matchesPath, routes } from "../../routes";
import { clickAndCollectConfirmationPageViewAction } from "./clickAndCollect";
import GolTime from "../../domain/time";
import { formatSlotDate } from "./bookingConfirmation";
import { AdobeExtraPageData, AdobePageName, AdobePageType, generateAdobeAnalyticsPageProperties } from "../../utils";

export const cncBookingConfirmationMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) =>
  (next: Dispatch<any>) =>
  (action: FetchSlotReservationSuccessAction) => {
    switch (action.type) {
      case SlotReservationActionTypes.FETCH_SLOT_RESERVATION_SUCCESS:
        const location = api.getState().router.location;
        if (matchesPath(location.pathname, routes.CLICK_AND_COLLECT_CONFIRMATION)) {
          const basket = api.getState().basket.basketDetails;
          const { data_siteSection, data_page_name, data_shoppingMode } = generateAdobeAnalyticsPageProperties({
            basket,
            pageType: AdobePageType.GROCERIES,
            pageName: AdobePageName.SLOT_BOOKING,
            extraPageData: [AdobeExtraPageData.CLICK_AND_COLLECT, AdobeExtraPageData.RESERVATION_CONFIRMATION],
          });
          const chosenSlot = {
            start_time: new GolTime(action.slotReservation.reservationStartTime).format("HH:mm"),
            end_time: new GolTime(action.slotReservation.reservationEndTime).format("HH:mm"),
            price: action.slotReservation.slotQualifiedPrice!,
            status: 1,
            date: formatSlotDate(action.slotReservation),
          };
          api.dispatch(
            clickAndCollectConfirmationPageViewAction(chosenSlot, action.slotReservation.locationUid!, {
              data_siteSection,
              data_page_name,
              data_shoppingMode,
              data_slot_price: action.slotReservation.slotQualifiedPrice,
            })
          );
        }
        break;
      default:
        break;
    }
    next(action);
  };
