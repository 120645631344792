import { NectarActions, NectarActionTypes, NectarState } from "./nectar.types";
import { DataState } from "../../common/dataState";
import { hasDateExpired } from "../../common/utils";

export const nectarInitialState: NectarState = {
  dataState: DataState.UNKNOWN,
  data: {
    accountStatus: "none",
    offers: [],
  },
};

export const nectarReducer = (state: NectarState = nectarInitialState, action: NectarActionTypes): NectarState => {
  switch (action.type) {
    case NectarActions.FETCH_NECTAR_OFFERS_PENDING:
      return {
        ...state,
        dataState: DataState.PENDING,
      };

    case NectarActions.FETCH_NECTAR_OFFERS_SUCCESS:
      const { nectarOffers } = action;

      return {
        ...state,
        dataState: DataState.SUCCESS,
        data: {
          ...nectarOffers,
          offers: nectarOffers.offers.filter(({ expires }) => !hasDateExpired(expires)),
        },
      };

    case NectarActions.FETCH_NECTAR_OFFERS_ERROR:
      return {
        ...state,
        dataState: DataState.FAILED,
      };

    default:
      return state;
  }
};
