import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { DataState } from "../../common/dataState";
import { mapProducts } from "../../domain/product/product";
import { createProductBrandShelfSuccessAction } from "./BrandPage.actions";
import { ItemType } from "../../components/ProductTile/productTile.types";
import { fetchProductAds } from "../../services/product";
import { ProductAd } from "../../services/product.types";
import { ProductSource, ProductUid } from "../../domain/product/product.types";
import { getCachedSlotReservation } from "../../domain/slot/slotReservation";
import { CachedSlotReservation } from "../../domain/slot/slotReservation.types";

interface BrandShelfAdsState {
  products: Array<{
    item: ProductUid;
    type: ItemType;
  }>;
  status: DataState;
  error?: string;
}

export enum BrandShelfActionType {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  REQUEST = "PENDING",
}

interface BrandShelfRequestAction {
  type: BrandShelfActionType.REQUEST;
}

interface BrandShelfErrorAction {
  type: BrandShelfActionType.FAILED;
  error: string;
}

interface BrandShelfSuccessAction {
  type: BrandShelfActionType.SUCCESS;
  products: Array<{
    item: ProductUid;
    type: ItemType;
  }>;
}

type BrandShelfAction = BrandShelfRequestAction | BrandShelfErrorAction | BrandShelfSuccessAction;

export function brandShelfReducer(state: BrandShelfAdsState, action: BrandShelfAction) {
  switch (action.type) {
    case BrandShelfActionType.SUCCESS:
      return {
        status: DataState.SUCCESS,
        products: action.products,
      };
    case BrandShelfActionType.FAILED:
      return {
        ...state,
        status: DataState.FAILED,
        error: action.error,
      };
    case BrandShelfActionType.REQUEST:
      return {
        status: DataState.PENDING,
        products: [],
      };
    default:
      return state;
  }
}

const initialState = {
  products: [],
  status: DataState.PENDING,
};

export function useBrandShelfAds(brand: string): BrandShelfAdsState {
  const [state, dispatch] = React.useReducer(brandShelfReducer, initialState);
  const reduxDispatch = useDispatch();
  const { storeIdentifier, slotDate, slotDateTime, flexiStores } = useMemo(
    () => getCachedSlotReservation() || ({} as CachedSlotReservation),
    []
  );

  React.useEffect(() => {
    const fetchItems = async () => {
      const result = await fetchProductAds({
        pageType: "BrandPage",
        searchTerm: "",
        productFilters: [[`brandpage:${brand}`]],
        maxNumberOfAds: 15,
        storeIdentifier,
        slotDate,
        slotDateTime,
        flexiStores,
      });
      if (result.isSuccess() && result.data.ads.length > 0) {
        const products = mapProducts(
          ProductSource.BRANDSHELF,
          result.data.ads.map(ad => ad.product)
        );
        reduxDispatch(createProductBrandShelfSuccessAction(products));
        const items = result.data.ads.map(({ product }: ProductAd) => ({
          item: product.product_uid,
          type: ItemType.PRODUCT,
        }));
        dispatch({ type: BrandShelfActionType.SUCCESS, products: items });
      } else {
        dispatch({ type: BrandShelfActionType.FAILED, error: "Something went wrong" });
      }
    };
    fetchItems().catch(() => {
      dispatch({ type: BrandShelfActionType.FAILED, error: "Something went wrong" });
    });
  }, [brand, storeIdentifier, slotDate, slotDateTime, flexiStores, reduxDispatch]);

  return state;
}
