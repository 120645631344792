import React from "react";
import { MagnoliaRenderer } from "../../../components/Magnolia/MagnoliaRenderer.component";
import { Banners, CitrusBanner } from "../../Citrus/BannerAd/BannerAd.types";
import { AdsResponseEnvelope } from "../../../services/content";
import { sendCMSClickAnalytics } from "../../../views/Browse/BrowseMagnoliaContent/BrowseAnalytics.utils";

interface MegaNavContentComponentProps {
  ads: AdsResponseEnvelope<Banners>[];
}
interface MagnoliaIncluded {
  type?: string;
  id?: string;
  desc?: string;
  attributes?: any[];
  callouts?: string[];
  ctaStyle?: string;
  ctaText?: string;
  heading?: string;
  image?: {
    altText?: string;
    source?: string;
    url?: string;
  };
  legalCopy?: string;
  links?: { copy: string; style: string; target: string; title: string; url: string }[];
  link?: {
    target?: string;
    title?: string;
    url?: string;
  };
}
type IncludedType = CitrusBanner | MagnoliaIncluded;

const MegaNavContentComponent: React.FC<MegaNavContentComponentProps> = ({ ads }) => {
  const megaNavContent = transformAdsToStructuredData({ ads });

  return (
    <div className="mega-nav--cms-wrapper">
      <h6 className="ds-mt-1" data-testid="mega-nav-column-header">
        More from Sainsbury's
      </h6>
      <MagnoliaRenderer
        template={megaNavContent}
        className="ln-u-hard-bottom"
        onClick={(event: any) => sendCMSClickAnalytics(event, true)}
      />
    </div>
  );
};

const transformAdsToStructuredData = (props: MegaNavContentComponentProps) => {
  const attributes = {
    isVertical: true,
    mobileLayout: "stack",
    type: "UG002",
    desc: "Universal grid 2",
    imageStyle: "normal",
    meganav: true,
    included: [] as IncludedType[],
  };

  // Iterate through each ad and add banners/magnolia to a single included array
  props.ads?.forEach(ad => {
    if (ad.banner) {
      const banners = ad.banner.banners.map(banner => ({
        ...banner,
        type: "C004",
        citrus: true,
      }));
      attributes.included.push(...banners);
    }

    if (ad.magnolia) {
      attributes.included.push(ad.magnolia);
    }
  });

  return { data: [{ attributes: [attributes] }] };
};

export default MegaNavContentComponent;
