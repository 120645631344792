import { BrowsePageType } from "../views/Browse/Browse.types";
import { AverageWeight } from "../domain/product/product.types";
import { FavouriteType } from "@favourites/Favourites.types";

export interface SimilarProducts {
  product_uid: string;
  similar: Product[];
  also_bought: Product[];
  ads: ProductAd[];
}

//yet to be defined as krang work still ongoing
export interface FrequentlyBoughtTogether {
  product_uid: string;
  products: Product[];
}

export interface Products {
  products: Product[];
  controls?: Controls;
  errors?: ProductServiceError[];
}

export enum ProductErrorCode {
  DIETARY_PROFILE_ERROR = "DIETARY_PROFILE_ERROR",
  FAVOURITES_ERROR_FALLBACK = "FAVOURITES_ERROR_FALLBACK",
}

export type ProductServiceError = {
  code: ProductErrorCode;
};

export interface ProductMessage {
  id: string;
  text: string;
}

export interface SearchCategoriesRoot {
  id: string;
  name: string;
  value?: number;
  /** Will always be undefined for root, but this breaks a lot of shaky typing */
  selected?: boolean;
  children?: Array<SearchCategory>;
}

export interface SearchCategory {
  id: string;
  name: string;
  children?: Array<SearchCategory>;
  selected?: boolean;
  value: number;
}

export interface PaginatedProducts {
  products: Product[];
  controls: Controls;
  errors?: ProductServiceError[];
}

export interface Controls {
  searchterm_hfss_restricted?: boolean;
  category_match?: boolean;
  sort: Sort;
  total_record_count: number;
  returned_record_count: number;
  page: Page;
  filters: ResponseFilter[];
  cat_filters?: [SearchCategoriesRoot];
}

export interface SearchResponse extends PaginatedProducts {
  suggested_search_terms?: SuggestedSearchTerm[];
  redirect_to?: string;
  ads?: {
    sponsored_products: {
      spotlight_product_ads: ProductAd[];
      in_grid_product_ads: ProductAd[];
    };
  };
  spotlight_products?: Product[];
}

export type SuggestedSearchTerm = {
  type: string;
  term: string;
  count: string;
};

export type CitrusProductAds = {
  ads: ProductAd[];
};

export interface FavouritesResponse extends PaginatedProducts {
  ads?: FavouritesProductAds;
  alternatives?: AlternativeProduct[];
}

export interface AlternativeProduct {
  product: Product;
  type: string;
  parent_product_uid: string;
}

export interface ProductsBySeoUrlResponse {
  products: Product[];
  ads?: ProductAds;
  errors?: ProductServiceError[];
}

export interface FavouritesProductAds {
  sponsored_products: ProductAd[];
}

export interface ProductAds {
  sponsored_products: SponsoredProducts;
}

export interface SponsoredProducts {
  product_ads: ProductAd[];
}

export interface ProductAd {
  ad_id?: string;
  product: Product;
  type: string;
  parent_product_uid?: string;
}

export enum ProductAdType {
  CITRUS = "Citrus",
  XSELL = "X-SELL",
}

export enum PromoTypes {
  SIMPLE_HALF_PRICE = "SIMPLE_HALF_PRICE",
  SIMPLE_SAVE_X = "SIMPLE_SAVE_X",
  SIMPLE_OFF_X = "SIMPLE_OFF_X",
  SIMPLE_FIXED_PRICE = "SIMPLE_FIXED_PRICE",
  INTRO_HALF_PRICE = "INTRO_HALF_PRICE",
  INTRO_SAVE_X = "INTRO_SAVE_X",
  INTRO_OFF_X = "INTRO_OFF_X",
  INTRO_FIXED_PRICE = "INTRO_FIXED_PRICE",
  BUY_X_GET_Y_POINTS = "BUY_X_GET_Y_POINTS",
  SPEND_X_GET_Y_POINTS = "SPEND_X_GET_Y_POINTS",
  BOGOF = "BOGOF",
  BUY_3_FOR_2 = "BUY_3_FOR_2",
  MULTIBUY_BUY_X_OF_SAME_PRICE_FOR_Y = "MULTIBUY_BUY_X_OF_SAME_PRICE_FOR_Y",
  MULTIBUY_BUY_X_OF_VARIABLE_PRICE_FOR_Y = "MULTIBUY_BUY_X_OF_VARIABLE_PRICE_FOR_Y",
  MULTIBUY_BUY_X_ITEMS_SAVE_Y = "MULTIBUY_BUY_X_ITEMS_SAVE_Y",
  BUY_ITEM1_GET_ITEM_2_FREE = "BUY_ITEM1_GET_ITEM_2_FREE",
  BUY_ITEM_1_GET_ITEM_2_FOR_X = "BUY_ITEM_1_GET_ITEM_2_FOR_X",
  MULTIBUY_BUY_X_ITEMS_SAVE_Y_PERCENT = "MULTIBUY_BUY_X_ITEMS_SAVE_Y_PERCENT",
  MULTIBUY_BUY_X_ITEMS_SAVE_Y_PERCENT_ROLLING = "MULTIBUY_BUY_X_ITEMS_SAVE_Y_PERCENT_ROLLING",
  MULTIBUY_SPEND_X_SAVE_Y = "MULTIBUY_SPEND_X_SAVE_Y",
  MULTIBUY_SPEND_X_SAVE_Y_PERCENT = "MULTIBUY_SPEND_X_SAVE_Y_PERCENT",
  MULTIBUY_BUY_X_GET_Y_CHEAPEST_FREE = "MULTIBUY_BUY_X_GET_Y_CHEAPEST_FREE",
  MEAL_MULTI_DEAL_FOR_X = "MEAL_MULTI_DEAL_FOR_X",
  MEAL_MULTI_DEAL_SAVE_X = "MEAL_MULTI_DEAL_SAVE_X",
  MEAL_MULTI_DEAL_GET_X_POINTS = "MEAL_MULTI_DEAL_GET_X_POINTS",
  MEAL_MULTI_DEAL_SAVE_X_PERCENT = "MEAL_MULTI_DEAL_SAVE_X_PERCENT",
}

export interface PromotionResponse {
  promotion_uid: string;
  icon: string;
  link: string;
  strap_line: string;
  start_date: string;
  /*
    end_date is for frontend cosmetic reasons only where we want to display a date to the user.
    The backend (GOL Services) will not send promotions that do not apply to the product in the response.
  */
  end_date: string;
  original_price?: number;
  promo_mechanic_id?: string;
  promo_type?: `${PromoTypes}`;
  is_nectar?: boolean;
  promo_group?: PromoGroup;
  listId?: string;
  listOrder?: string;
  listName?: string;
}

export enum PromoGroup {
  SIMPLE = "simple",
  MULTI_BUY = "multi_buy",
  MEAL_DEAL = "meal_deal",
  UNKNOWN = "unknown",
}

export enum ProductType {
  CATCHWEIGHT = "CATCHWEIGHT",
  MULTIVARIANT = "MULTIVARIANT",
  LOOSE = "LOOSE",
  BASIC = "BASIC",
}

export interface NectarPrice {
  retail_price: number;
  measure: string;
  unit_price: number;
  url: string;
}

export interface YourNectarPrice {
  without_nectar_price: number;
  start_time: string;
  expiry_time: string;
}

export interface RetailPrice {
  price: number;
  measure: string;
  was_price?: number;
}

export interface UnitPrice {
  price: number;
  measure: string;
  measure_amount: number;
}

export interface ProductRecommendation {
  product_uid: string;
  score: number;
}

export interface ProductRecommendations {
  products: ProductRecommendation[];
}

export enum ProductRecommendationsPlacement {
  COMPLEMENTARY = "complementary",
  FAVOURITES = "favourites",
  BESTSELLERS = "bestsellers",
  FBT = "fbt",
}

export enum ProductRecommendationsChannel {
  PDP = "pdp",
}

export interface Product {
  product_uid: string;
  favourite_uid: string | null;
  favourite_type?: FavouriteType;
  zone: string | null;
  department: string | null;
  product_type: ProductType;
  is_supply_chain_orderable: boolean;
  name: string;
  eans: string[];
  retail_price?: RetailPrice;
  unit_price?: UnitPrice;
  average_weight?: AverageWeight;
  nectar_price?: NectarPrice;
  your_nectar_price?: YourNectarPrice;
  image: string | null;
  image_thumbnail: string | null;
  image_thumbnail_small: string | null;
  image_zoom?: string | null;
  full_url: string | null;
  pdp_deep_link: string;
  attributes: {
    brand: string[];
  };
  is_alcoholic?: boolean;
  is_available: boolean;
  is_favourite?: boolean;
  is_spotlight: boolean;
  is_intolerant: boolean;
  is_mhra: boolean;
  occasion?: Occasion;
  promise?: OrderPromise;
  multivariants?: MultiVariant[];
  catchweight?: Catchweight[];
  promotions?: PromotionResponse[];
  associations: Association[];
  display_icons: string[];
  badges: Badge[];
  labels: Label[];
  reviews: Reviews;
  breadcrumbs: Breadcrumb[];
  details_html?: string;
  assets: Assets;
  description: string[];
  important_information: string[];
  attachments: Attachment[];
  cta?: ProductHeader;
  header?: ProductHeader;
  categories: ProductCategory[];
  messages?: ProductMessage[];
  relevancyRank?: number;
  alternatives?: string[];
  not_for_eu?: boolean;
}

export interface Occasion {
  serves: string;
}

export interface OrderPromise {
  status: OrderPromiseStatus;
  earliest_promise_date: string;
  last_amendment_date: string;
}

export interface OrderPromiseStatus {
  label: string;
  type: OrderPromiseStatusType;
}

export enum OrderPromiseStatusType {
  AVAILABLE_TO_ADD = "AVAILABLE_TO_ADD",
  CHANGE_SLOT = "CHANGE_SLOT",
  NOT_FOR_THIS_ORDER = "NOT_FOR_THIS_ORDER",
  TIME_TO_CUTOFF = "TIME_TO_CUTOFF",
  TIME_TO_CUTOFF_CLOSE = "TIME_TO_CUTOFF_CLOSE",
  BEING_PREPARED = "BEING_PREPARED",
}

export interface ProductCategory {
  id: string;
  name?: string;
}

export interface ProductHeader {
  type: string;
  text: string;
}

export interface Badge {
  badge_uid: string;
  text: string;
  color: string;
  icon_url?: string;
  link?: string;
  link_opens_in_new_window: boolean;
  alt_text: string;
}

export interface Label {
  label_uid: string;
  text: string;
  color: string;
  link?: string;
  link_opens_in_new_window: boolean;
  alt_text: string;
}

export interface Assets {
  plp_image: string;
  images: Image[];
  video: Video[];
}

export interface Image {
  id: string;
  sizes: ImageSize[];
}

export interface ImageSize {
  height: number;
  width: number;
  url: string;
}

export interface Video {
  url: string;
}

export interface Breadcrumb {
  label: string;
  url: string;
}

// To be removed
export interface Association {
  expires?: string;
  association_type: string;
  product: Product;
}

export interface MultiVariant {
  product_uid: string;
  display_name: string;
  promotions: PromotionResponse[] | undefined;
  retail_price: RetailPrice;
  unit_price: UnitPrice;
}

export interface Reviews {
  is_enabled: boolean;
  product_uid: string;
  average_rating: number;
  total: number;
}

export interface Attachment {
  file_name: string;
  file_description: string;
  file_path: string;
  mime_type: string;
}

export interface Catchweight {
  range: string;
  retail_price: RetailPrice;
  unit_price: UnitPrice;
}

export interface Sort {
  active: string;
  options: SortOption[];
}

export interface SortOption {
  display: string;
  value: string;
}

export interface Page {
  active: number;
  first: number;
  last: number;
  size: number;
  size_options: number[];
}

export interface ResponseFilter {
  key: string;
  label: string;
  type: string;
  values: ResponseFilterValue[];
}

export interface ResponseFilterValue {
  id?: string;
  label: string;
  count?: number;
  value: string;
  selected: boolean;
  enabled: boolean;
  disabled?: boolean;
}

export interface RequestFilters {
  [key: string]: any;
}

export interface IncludeFilters {
  [key: string]: any;
}

export const initRequestFilters: RequestFilters = {};

export enum FilterTypes {
  FILTER_YOUR_RESULTS = "nav_Filter-Your-Results",
  AVAILABLE = "available",
}

export interface CategoryTreeResponse {
  catalog_id: string;
  category_hierarchy: Category;
}

export interface ShelfCategory {
  s: string;
  n: string;
}

export type Category = ShelfCategory & {
  c?: Category[];
};

export interface ProductQueryParameters {
  pageNumber?: number;
  pageSize?: number;
  page?: string;
  sort?: string;
  filters?: RequestFilters;
  includes?: string[];
  minimised?: boolean;
  storeIdentifier?: string;
  slotDate?: string;
  slotDateTime?: string;
  slotType?: string;
  isSlotLocked?: boolean;
  flexiStores?: string[];
  uid?: string;
  personalizationId?: string;
  dietaryProfile?: string;
  pattern?: string;
  region?: string;
  categoryId?: string;
  promotionId?: string;
  listId?: number;
  salesWindowVariant?: number;
  orderBySecondaryOffers?: boolean;
  compareSeasonal?: boolean;
}

export interface SearchProductParameters extends ProductQueryParameters {
  searchTerm: string;
  isSearchCategoryMatchEnabled?: boolean;
  hfss_restricted?: boolean;
  salesWindowVariant?: number;
}

export interface EventsSearchProductParameters extends ProductQueryParameters {
  searchTerm: string;
  sponsoredCategoryId?: string;
  sponsoredProductsCount?: string;
  isSearchCategoryMatchEnabled?: boolean;
}

export interface ProductsByOrderParameters extends ProductQueryParameters {
  orderUid: string;
}

export interface OffersResponse {
  products: Product[];
  controls: Controls;
}

export interface BrowseProductsControlsParams {
  page: string;
  sort: string;
  filters?: RequestFilters;
}

export type FetchBrowseProductsArgs = BrowseProductsControlsParams & {
  categoryID: string;
  slotDate?: string;
  slotDateTime?: string;
  storeIdentifier?: string;
  slotType?: string;
  isSlotLocked?: boolean;
  flexiStores?: string[];
  region?: string;
  pageType?: BrowsePageType;
  isEventPage?: boolean;
  sponsoredCategoryId?: string;
  enableFavouritesPriority?: boolean;
  salesWindowVariant?: number;
};

export type FetchEventProductsArgs = BrowseProductsControlsParams & {
  searchTerm: string;
  slotDate?: string;
  isSlotLocked?: boolean;
  storeIdentifier?: string;
  flexiStores?: string[];
  region?: string;
  sponsoredCategoryId?: string;
  sponsoredProductsCount?: string;
};
