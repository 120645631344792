import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { State } from "../../common/store";
import { selectCutOffModalOpen, selectSlotExpiryModalOpen } from "./AmendOrderBanner.selectors";
import { AmendOrderBanner } from "./AmendOrderBanner.component";
import { openDiscardChangesModalActionCreator, setCutOffExpired } from "./AmendOrderBanner.actions";
import { setReservationExpired } from "../../domain/slot/slotReservation.actions";
import { AmendOrderBannerLoadingIndicator } from "./AmendOrderBannerLoading/AmendOrderBannerLoading.component";
import { matchesPath, routes, urls } from "../../routes";
import { useCheckout } from "../../domain/checkout/checkout";
import { useServiceRequestCallback } from "../../services/hooks";
import { fetchOrderStatus } from "../../services/order";
import {
  getBookSlotUrl,
  getSlotExpiryDateAndTimeFormatted,
  useSlotReservation,
} from "../../domain/slot/slotReservation";
import { useBasket } from "../../domain/basket/basket";
import { getOrderBeingAmended } from "./amendOrderBanner";
import {
  AmendOrderBannerOrder,
  AmendOrderBannerPrimaryCta,
  ExpiryDetails,
  ExpiryDetailsReason,
  ExpiryStatus,
} from "./AmendOrderBanner.types";
import moment from "moment";
import GolTime from "../../domain/time";
import { isSlotNearExpiry } from "../../domain/slot/slotReservation.utils";
import { OrderStatus } from "../../services/order.types";
import { SlotReservation } from "../../domain/slot/slotReservation.types";
import { DataState } from "../../common/dataState";

export const AmendOrderBannerSwitch = () => (
  <Switch>
    <Route path={routes.MY_ACCOUNT} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.MY_ORDERS} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.ORDER_DETAILS} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.PREVIOUS_ORDERS} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.PRODUCT_DETAILS} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.FAVOURITES} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.YOUR_RECIPES_FAVOURITES} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.SEARCH_RESULTS} render={() => <AmendOrderBannerContainer />} />
    <Route
      path={[routes.SEARCH_RESULTS_FINDABILITY_WITH_CATEGORIES, routes.SEARCH_RESULTS_FINDABILITY]}
      render={() => <AmendOrderBannerContainer />}
    />
    <Route path={routes.BOOK_SLOT} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.CLICK_AND_COLLECT_BOOK} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.TROLLEY} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.CHECKOUT_FORGOTTEN_FAVOURITES} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.CHECKOUT_BEFORE_YOU_GO} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.PRODUCT_LISTER} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.CHANGES_TO_TROLLEY} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.BROWSE} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.EVENTS} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.FEATURES} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.MEAL_DEAL} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.PAYMENT_CARDS} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.ADD_PAYMENT_CARD} render={() => <AmendOrderBannerContainer />} />
    <Route path={routes.RECIPES_MEAL_PLANNER} render={() => <AmendOrderBannerContainer />} />
    <Route render={() => null} />
  </Switch>
);

export const AmendOrderBannerContainer = () => {
  const dispatch = useDispatch();
  const { startCheckout, error } = useCheckout();
  const [orderStatusState, getOrderStatus] = useServiceRequestCallback(fetchOrderStatus);
  const { slotReservation, dataState: slotDataState } = useSlotReservation();
  const {
    basket: { isInAmendMode, basketId, orderId, items },
  } = useBasket();
  const pathName = useSelector((state: State) => state.router.location.pathname);
  const usesFavouritesHeader =
    matchesPath(pathName, routes.FAVOURITES) || matchesPath(pathName, routes.PREVIOUS_ORDERS);
  const isCheckoutPages =
    matchesPath(pathName, routes.CHECKOUT_BEFORE_YOU_GO) ||
    matchesPath(pathName, routes.CHECKOUT_FORGOTTEN_FAVOURITES) ||
    matchesPath(pathName, routes.TROLLEY) ||
    matchesPath(pathName, routes.CHANGES_TO_TROLLEY);
  const isRecipePage =
    matchesPath(pathName, routes.RECIPES_MEAL_PLANNER_CREATE) ||
    matchesPath(pathName, routes.RECIPES_MEAL_PLANNER_PLAN);
  const expiryDetails = getExpiryDetails(orderStatusState.data, slotReservation, isInAmendMode);
  const order = getAmendOrderBannerOrder(orderStatusState.data, isInAmendMode, orderId);
  const isLoading = isDataLoading(slotDataState, orderStatusState.status);
  const showSlotExpiryModal = useSelector((state: State) => selectSlotExpiryModalOpen(state)) || false;
  const showCutOffModal = useSelector((state: State) => selectCutOffModalOpen(state)) || false;
  const hasOccasionItems = items.some(item => item.isSupplyChainOrderable);

  useEffect(() => {
    if (basketId) {
      getOrderStatus();
    }
    // TO-DO: refactor when useEffectEvent is stable see https://react.dev/reference/react/experimental_useEffectEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    basketId,
    slotReservation.reservationStartTime,
    slotReservation.reservationType,
    slotReservation.reservationPostCode,
  ]);

  if (!isInAmendMode || !order) {
    return null;
  }

  if (isLoading) {
    return <AmendOrderBannerLoadingIndicator usesFavouritesHeader={usesFavouritesHeader} />;
  }

  return (
    <AmendOrderBanner
      startCheckout={() => startCheckout(isInAmendMode, order!.orderId)}
      error={error}
      expiryDetails={expiryDetails}
      showSlotExpiryDetails={showSlotExpiryDetails(orderStatusState.data, slotReservation, isInAmendMode)}
      showCutOffDetails={showCutOffDetails(orderStatusState.data, slotReservation, isInAmendMode)}
      primaryCta={getAmendOrderBannerPrimaryCta(orderStatusState.data, slotReservation, isInAmendMode)}
      order={order}
      hasOccasionItems={hasOccasionItems}
      pathname={pathName}
      showCheckoutCTA={!isCheckoutPages}
      isRecipePage={isRecipePage}
      usesFavouritesHeader={usesFavouritesHeader}
      showSlotExpiryModal={showSlotExpiryModal}
      showCutOffModal={showCutOffModal}
      dispatchSetCutOffExpired={() => dispatch(setCutOffExpired(orderStatusState.data, isInAmendMode))}
      dispatchSetReservationExpired={() => dispatch(setReservationExpired())}
      dispatchOpenDiscardChangesModal={() => dispatch(openDiscardChangesModalActionCreator())}
    />
  );
};

export const getSlotExpiryStatus = (reservationUntil: string): ExpiryStatus => {
  const isValidDateTimeString = moment(reservationUntil, GolTime.servicesFormat, true).isValid();

  if (!isValidDateTimeString) {
    return {
      isNearExpiry: false,
      expiryDateAndTime: "",
      expiryDateAndTimeFormatted: "",
    };
  }

  const isNearExpiry = isSlotNearExpiry(reservationUntil);

  return {
    isNearExpiry,
    expiryDateAndTime: reservationUntil,
    expiryDateAndTimeFormatted: getSlotExpiryDateAndTimeFormatted(reservationUntil),
  };
};

export const getCutOffExpiryStatus = (cutOffTime: string): ExpiryStatus => {
  const isValidDateTimeString = moment(cutOffTime, GolTime.servicesFormat, true).isValid();

  if (!isValidDateTimeString) {
    return {
      isNearExpiry: false,
      expiryDateAndTime: "",
      expiryDateAndTimeFormatted: "",
    };
  }

  const cutOffMomentObject = moment(cutOffTime);
  const nowUK = moment.tz("Europe/London");
  const isSameDay = cutOffMomentObject.isSame(nowUK, "day");

  const isNearExpiry = isSlotNearExpiry(cutOffTime);

  return {
    isNearExpiry,
    expiryDateAndTime: cutOffTime,
    expiryDateAndTimeFormatted: getCutOffDateAndTimeFormatted(cutOffTime, isSameDay),
  };
};

export const getExpiryDetails = (
  orderStatus: OrderStatus | undefined,
  slotReservation: SlotReservation,
  isInAmendMode: boolean
): ExpiryDetails => {
  const orderBeingAmended = getOrderBeingAmended(orderStatus, isInAmendMode);

  if (!orderBeingAmended || !orderBeingAmended.cutoff_time) {
    return null;
  }

  const cutOffExpiryStatus = getCutOffExpiryStatus(orderBeingAmended.cutoff_time);
  const slotExpiryStatus = getSlotExpiryStatus(slotReservation.reservationUntil);

  if (!cutOffExpiryStatus.expiryDateAndTime || !slotExpiryStatus.expiryDateAndTime) {
    return null;
  }

  return cutOffExpiryStatus.expiryDateAndTime <= slotExpiryStatus.expiryDateAndTime
    ? { ...cutOffExpiryStatus, reason: ExpiryDetailsReason.CUT_OFF_TIME }
    : { ...slotExpiryStatus, reason: ExpiryDetailsReason.SLOT_EXPIRATION };
};

export const isDataLoading = (slotReservationDataState: DataState, ordersDataState: DataState) => {
  const slotReservationLoading =
    slotReservationDataState === DataState.UNKNOWN || slotReservationDataState === DataState.PENDING;
  const ordersLoading = ordersDataState === DataState.UNKNOWN || ordersDataState === DataState.PENDING;

  return slotReservationLoading || ordersLoading;
};

export const getCutOffDateAndTimeFormatted = (expiryDateAndTime: string, isSameDay: boolean): string => {
  if (expiryDateAndTime) {
    const cutOffDateAndTime = moment.tz(expiryDateAndTime, "Europe/London");
    const time = cutOffDateAndTime.format(cutOffDateAndTime.minutes() === 0 ? "ha" : "h:mma"); // (Luna guidelines) If the minutes are 0, we don't want to display them
    const date = isSameDay ? "today" : `on ${cutOffDateAndTime.format("dddd D MMMM")}`;
    return `${time} ${date}`;
  }

  return "";
};

export const hasSlotReservationChanged = (
  orderStatus: OrderStatus | undefined,
  slotReservation: SlotReservation,
  isInAmendMode: boolean
): boolean => {
  const orderBeingAmended = getOrderBeingAmended(orderStatus, isInAmendMode);

  if (!orderBeingAmended) {
    return false;
  }

  return slotReservation.reservationUntil !== orderBeingAmended.cutoff_time;
};

export const getAmendOrderBannerOrder = (
  orderStatus: OrderStatus | undefined,
  isInAmendMode: boolean,
  orderId: string
): AmendOrderBannerOrder | null => {
  if (!isInAmendMode || !orderId) {
    return null;
  }

  const viewOrderLink = `${urls.MY_ORDERS}/${orderId}`;
  const orderBeingAmended = getOrderBeingAmended(orderStatus, isInAmendMode);
  const isCutOff = orderBeingAmended ? orderBeingAmended.is_cutoff : false;

  return {
    orderId,
    viewOrderLink,
    isCutOff,
  };
};

export const getAmendOrderBannerPrimaryCta = (
  orderStatus: OrderStatus | undefined,
  slotReservation: SlotReservation,
  isInAmendMode: boolean
): AmendOrderBannerPrimaryCta => {
  const slotHasExpired = hasExpired(orderStatus, slotReservation, isInAmendMode);
  const bookSlotUrl = getBookSlotUrl(slotReservation);

  return {
    link: slotHasExpired ? bookSlotUrl : undefined,
    text: slotHasExpired ? "Book a slot" : "Checkout",
  };
};

export const hasExpired = (
  orderStatus: OrderStatus | undefined,
  slotReservation: SlotReservation,
  isInAmendMode: boolean
) => {
  const orderBeingAmended = getOrderBeingAmended(orderStatus, isInAmendMode);
  const isCutOff = orderBeingAmended ? orderBeingAmended.is_cutoff : false;
  const { reservationExpired } = slotReservation;

  return isCutOff || reservationExpired;
};

export const showCutOffDetails = (
  orderStatus: OrderStatus | undefined,
  slotReservation: SlotReservation,
  isInAmendMode: boolean
) => {
  const slotHasExpired = hasExpired(orderStatus, slotReservation, isInAmendMode);
  const slotReservationHasChanged = hasSlotReservationChanged(orderStatus, slotReservation, isInAmendMode);
  const expiryDetails = getExpiryDetails(orderStatus, slotReservation, isInAmendMode);
  const expiryReason = expiryDetails ? expiryDetails.reason : "";

  if (slotReservationHasChanged) {
    return expiryReason === ExpiryDetailsReason.CUT_OFF_TIME && !slotHasExpired;
  }

  return !slotReservationHasChanged && !slotHasExpired;
};

export const showSlotExpiryDetails = (
  orderStatus: OrderStatus | undefined,
  slotReservation: SlotReservation,
  isInAmendMode: boolean
) => {
  const slotHasExpired = hasExpired(orderStatus, slotReservation, isInAmendMode);
  const slotReservationHasChanged = hasSlotReservationChanged(orderStatus, slotReservation, isInAmendMode);

  const expiryDetails = getExpiryDetails(orderStatus, slotReservation, isInAmendMode);
  const expiryReason = expiryDetails ? expiryDetails.reason : "";

  return slotReservationHasChanged && expiryReason === ExpiryDetailsReason.SLOT_EXPIRATION && !slotHasExpired;
};
