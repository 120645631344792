import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../../common/store";
import { LOCATION_CHANGE } from "connected-react-router";
import { AppThunkDispatch, LocationChangeAction } from "../../../common/types";
import { resetTourStepAction } from "@favourites/state/Favourites.actions";
import { useFeatureFlagObject } from "../../../common/firebase/featureFlags.hooks";

export const featureTourMiddleware =
  (api: MiddlewareAPI<AppThunkDispatch, State>) => (next: Dispatch) => (action: LocationChangeAction) => {
    const isAddToFavouritesEnabled = useFeatureFlagObject("add_to_favourites.feature_tour");

    switch (action.type) {
      case LOCATION_CHANGE:
        if (isAddToFavouritesEnabled) api.dispatch(resetTourStepAction());

        break;

      default:
        break;
    }
    next(action);
  };
