import debounce from "lodash.debounce";
import React, { useReducer, useRef } from "react";
import { fetchSearchSuggestions } from "../../../services/product";

export interface Suggestion {
  label: string;
  value: string;
}

interface SearchBarState {
  loading: boolean;
  suggestions: Suggestion[] | null;
  error: string | null;
}

interface SearchBarAction {
  type: string;
  suggestions?: Suggestion[] | null;
}

export enum SearchBarActionTypes {
  SET_IDLE = "SET_IDLE",
  SET_LOADING = "SET_LOADING",
  SET_SUCCESS = "SET_SUCCESS",
  SET_ERROR = "SET_ ERROR",
}

const searchBarReducer = (state: SearchBarState, action: SearchBarAction) => {
  switch (action.type) {
    case SearchBarActionTypes.SET_IDLE:
      return { ...state, suggestions: null };
    case SearchBarActionTypes.SET_LOADING:
      return { ...state, loading: true };
    case SearchBarActionTypes.SET_SUCCESS:
      const { suggestions } = action;
      return { ...state, suggestions, loading: false };
    case SearchBarActionTypes.SET_ERROR:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
};

export default function useSuggestions() {
  const [{ loading, suggestions, error }, dispatch] = useReducer(searchBarReducer, {
    loading: false,
    suggestions: null,
    error: null,
  });
  const cachedRef = useRef({});

  const shouldSetLoading = (isCached: boolean) => {
    if (!isCached) {
      dispatch({ type: SearchBarActionTypes.SET_LOADING });
    }
  };

  const getSuggestedTerms = debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length > 2) {
      shouldSetLoading(cachedRef.current[`${value}`]);
      try {
        const result = await fetchSearchSuggestions(value);
        cachedRef.current[`${value}`] = true;
        // this line will be removed once the length of chars(2 or 3) supported has been decided
        dispatch({
          type: SearchBarActionTypes.SET_SUCCESS,
          suggestions: result.length < 1 && value.length < 3 ? null : result,
        });
      } catch (e) {
        dispatch({ type: SearchBarActionTypes.SET_ERROR });
      }
    } else {
      dispatch({ type: SearchBarActionTypes.SET_IDLE });
    }
  }, 300);

  return { loading, suggestions, error, getSuggestedTerms, dispatch };
}
