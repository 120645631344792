import React from "react";
import { useLocation } from "react-router-dom";
import { routes, matchesPath } from "../../routes";

export const FavouritesSkipTo = () => {
  const handleSkipToSeasonalFavourites = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      const header = document.getElementsByClassName("header-background")[0] as HTMLElement;
      const seasonalFavourites = document.querySelectorAll(".skipto-seasonal-favourites")[0] as HTMLElement;
      const firstElement = seasonalFavourites.querySelectorAll(".pt__swiper--grid-item")[0] as HTMLElement;
      const button = firstElement.querySelectorAll("button")[0] as HTMLElement;
      if (seasonalFavourites) {
        button.focus();
        window.scrollTo(0, seasonalFavourites.offsetTop - header.clientHeight);
      }
    }
    if (e.key !== "Tab") {
      e.preventDefault();
    }
  };

  const handleSkipToRegularFavourites = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      const header = document.getElementsByClassName("header-background")[0] as HTMLElement;
      const regularFavouritesHeader = document.querySelectorAll(".skipto-regular-favourites-header")[0] as HTMLElement;
      const regularFavourites = document.querySelectorAll(".skipto-regular-favourites")[0] as HTMLElement;
      const firstElement = regularFavourites.querySelectorAll(".pt-grid-item")[0] as HTMLElement;
      const link = firstElement.querySelectorAll("a")[0] as HTMLElement;
      if (regularFavourites) {
        link.focus();
        window.scrollTo(0, regularFavouritesHeader.offsetTop - header.clientHeight);
      }
    }
    if (e.key !== "Tab") {
      e.preventDefault();
    }
  };

  const hasSections = (): { hasSeasonalFavourites: boolean; hasRegularFavourites: boolean } => {
    const seasonalFavourites = document.querySelectorAll(".skipto-seasonal-favourites")[0];
    const regularFavourites = document.querySelectorAll(".skipto-regular-favourites")[0];
    return { hasSeasonalFavourites: !!seasonalFavourites, hasRegularFavourites: !!regularFavourites };
  };

  const { hasSeasonalFavourites, hasRegularFavourites } = hasSections();

  return (
    <>
      {hasSeasonalFavourites && (
        <button
          className="header__accessible-skipto"
          data-testid="skip-to-seasonal-favourites"
          tabIndex={0}
          onKeyDown={handleSkipToSeasonalFavourites}
        >
          Skip to seasonal favourites
        </button>
      )}
      {hasRegularFavourites && (
        <button
          className="header__accessible-skipto"
          data-testid="skip-to-regular-favourites"
          tabIndex={0}
          onKeyDown={handleSkipToRegularFavourites}
        >
          Skip to regular favourites
        </button>
      )}
    </>
  );
};

export const CTASkipTo = () => {
  const handleSkipToCTA = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      const ctaButton = document.querySelectorAll(".trolley__cta-button")[0];
      if (ctaButton) {
        (ctaButton as HTMLElement).focus();
      }
    }
    if (e.key !== "Tab") {
      e.preventDefault();
    }
  };

  return (
    <button
      className="header__accessible-skipto"
      data-testid="skip-to-cta-link"
      tabIndex={0}
      onKeyDown={handleSkipToCTA}
    >
      Skip to place order
    </button>
  );
};

export const ContentSkipTo = () => {
  const handleSkipToContent = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      const header = document.getElementsByClassName("header-background")[0] as HTMLElement;
      const content = document.querySelector("#main") as HTMLElement;
      const heading = document.querySelector("h1") as HTMLElement;

      if (heading) {
        heading.tabIndex = 0;
        (heading as HTMLElement).focus();

        if (content && header) {
          window.scrollTo(0, content.offsetTop - header.clientHeight);
        }
      }
    }
    if (e.key !== "Tab") {
      e.preventDefault();
    }
  };

  return (
    <button
      className="header__accessible-skipto"
      data-testid="skip-to-content"
      tabIndex={0}
      onKeyDown={handleSkipToContent}
    >
      Skip to content
    </button>
  );
};

export const AccessibleSkipTo = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const showSkipToCTA =
    matchesPath(pathName, routes.TROLLEY) ||
    matchesPath(pathName, routes.CHECKOUT_BEFORE_YOU_GO) ||
    matchesPath(pathName, routes.CHECKOUT_FORGOTTEN_FAVOURITES);

  const showSkipToTrolley = !(
    matchesPath(pathName, routes.TROLLEY) ||
    matchesPath(pathName, routes.CHECKOUT_BEFORE_YOU_GO) ||
    matchesPath(pathName, routes.CHECKOUT_FORGOTTEN_FAVOURITES)
  );

  const showSkipToFavourites = matchesPath(location.pathname, routes.FAVOURITES);

  const handleSkipToTrolley = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const minitrolleyButton = document.querySelectorAll(".skipto-trolley")[0];
      if (minitrolleyButton) {
        (minitrolleyButton as HTMLElement).focus();
      }
    }
    if (e.key !== "Tab") {
      e.preventDefault();
    }
  };

  return (
    <>
      <ContentSkipTo />

      {showSkipToCTA && <CTASkipTo />}

      {showSkipToTrolley && (
        <button
          className="header__accessible-skipto"
          data-testid="skip-to-trolley"
          tabIndex={0}
          onKeyDown={handleSkipToTrolley}
        >
          Skip to trolley
        </button>
      )}

      {showSkipToFavourites && <FavouritesSkipTo />}
    </>
  );
};
