import { Method, Result } from "../common/http/request";
import { serviceRequest } from "./request";
import { GOL_RESOURCES_PREFIX } from "../routes";

export interface Redirects {
  [key: string]: string;
}

export const getProductsRedirect = (): Promise<Result<Redirects>> => {
  // const baseUrl = "/search/redirects"; local testing
  const baseUrl = `${GOL_RESOURCES_PREFIX}/search_redirects.json`;

  return serviceRequest<Redirects>({ method: Method.GET, url: baseUrl });
};
