import { AnyAction, Dispatch, MiddlewareAPI } from "redux";
import { AppActionTypes, AppMountAction } from "../../App.actions";
import { LOCATION_CHANGE } from "connected-react-router";
import { matchesPath, routes, urls } from "../../routes";
import { fetchUserAction } from "../../components/Authorized";
import { createReplaceHistoryAction } from "./navigationMiddleware";
import { fetchBasket } from "../../domain/basket/basket.actions";
import { State } from "../store";
import { fetchMenuItemsAction } from "../../components/Header/MenuItem.actions";
import { LocationChangeAction } from "../types";
import { queryParamValue } from "../http/query";
import { fetchTaxonomy } from "../../views/Browse/Browse.actions";
type UserJourneyActions = AppMountAction | LocationChangeAction;

export const userJourney =
  (api: MiddlewareAPI<Dispatch<any>, State>) => (next: Dispatch<any>) => async (action: AnyAction) => {
    next(action);
    const state = api.getState();
    switch ((action as UserJourneyActions).type) {
      case LOCATION_CHANGE:
        // Workaround since WCS redirects users to the incorrect check postcode URL when user is coming from
        //  book a slot. We look at `currentPageUrl` and hijack the route change to push the user to the correct
        //  location. We should remove this statement when the issue is fixed in WCS
        const { payload } = action as LocationChangeAction;
        if (matchesPath(payload.location.pathname, routes.CHECK_POSTCODE)) {
          const params = queryParamValue(payload.location.search, "currentPageUrl");
          if (params && params.indexOf("BookingDeliverySlotDisplayView") >= 0) {
            api.dispatch(createReplaceHistoryAction(urls.CHECK_POSTCODE_BOOK_SLOT));
          }
        }
        break;

      case AppActionTypes.APP_MOUNT: {
        api.dispatch(fetchUserAction());
        const shouldFetchBasket = ![
          urls.TROLLEY,
          urls.CHECKOUT_SUMMARY,
          urls.OAUTH_LOGOUT,
          urls.OAUTH_LOGOUT_REDIRECT,
          urls.CHECKOUT_PAYMENT_REDIRECT,
        ].some(url => state.location.current.includes(url));
        if (shouldFetchBasket) {
          api.dispatch(fetchBasket());
        }
        api.dispatch(fetchMenuItemsAction());
        api.dispatch(fetchTaxonomy());
        break;
      }

      default:
        break;
    }
  };
