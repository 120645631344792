import { State } from "../store";

export const selectVerifiedEmail = (state: State): string => state.login.verifiedEmail;

export const selectIsEmailVerified = (state: State): boolean => {
  const verifiedEmail = selectVerifiedEmail(state);
  return Boolean(verifiedEmail) && verifiedEmail.length > 0;
};

export function isLoggedInSelector({ user }: State): boolean {
  return !!user.userDetails?.isRegistered;
}
