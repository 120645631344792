import { Dispatch } from "redux";
import { State } from "../../common/store";
import { fetchBrowseTree } from "../../services/product";
import {
  BrowseAnalyticsPageName,
  BrowseAnalyticsPageTemplate,
  BrowsePageType,
  BrowseTaxonomyState,
  BrowseTreeNode,
  ParsedBrowseTaxonomyState,
  SynapticaBreadcrumbs,
} from "./Browse.types";
import { capitalise, concatAllNonEmptyStrings, createFormattedPath } from "../../common/utils";
import { AdobeDigitalDataProperties, AnalyticsActionTypes } from "../../common/analytics/types";
import { BrowsePageViewAction } from "../../common/analytics/analytics";
import { featureFlags } from "../../common/firebase/featureFlags";

export enum ActionTypes {
  FETCH_TAXONOMY_SUCCESS = "FETCH_TAXONOMY_SUCCESS",
  FETCH_TAXONOMY_FAILURE = "FETCH_TAXONOMY_FAILURE",
  PARSE_TAXONOMY_SUCCESS = "PARSE_TAXONOMY_SUCCESS",
  CREATE_BREADCRUMBS_SUCCESS = "CREATE_BREADCRUMBS_SUCCESS",
  FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS",
}

export interface FetchTaxonomySuccessAction {
  type: ActionTypes.FETCH_TAXONOMY_SUCCESS;
  data: BrowseTreeNode[];
}

export interface FetchTaxonomyFailureAction {
  type: ActionTypes.FETCH_TAXONOMY_FAILURE;
}

export interface ParseTaxonomySuccessAction {
  type: ActionTypes.PARSE_TAXONOMY_SUCCESS;
  data: ParsedBrowseTaxonomyState;
}

export interface CreatedSynapticaBreadcrumbsAction {
  type: ActionTypes.CREATE_BREADCRUMBS_SUCCESS;
  data: SynapticaBreadcrumbs;
}

// Event zones and all the children should be treated as event pages
// These pages are supposed to contain more sponsored products
export interface BrowseEventsSuccessAction {
  type: ActionTypes.FETCH_EVENTS_SUCCESS;
  data: string[];
}

function createSynapticaBreadcrumbs(
  nodes: BrowseTreeNode[],
  m?: SynapticaBreadcrumbs,
  trail?: string
): SynapticaBreadcrumbs {
  const breadcrumbs: SynapticaBreadcrumbs = m || {};
  nodes?.forEach((node, index) => {
    const currentPath = createFormattedPath(node.name);
    const aggregatedTrail = trail ? trail.concat("/", currentPath) : currentPath;
    breadcrumbs[String(aggregatedTrail)] = {
      url: concatAllNonEmptyStrings(["/gol-ui/groceries", aggregatedTrail, "c:".concat(node.id.toString())], "/"),
      label: node.name,
    };
    if (node.children.length > 0) {
      createSynapticaBreadcrumbs(node.children, breadcrumbs, aggregatedTrail);
    }
  });
  return breadcrumbs;
}

const appendPimids = (node: BrowseTaxonomyState) => {
  const pimids: string[] = [];
  node.forEach(n => {
    if (n.children.length) {
      pimids.push(appendPimids(n.children));
    } else {
      // Filter nodes with zero pimid and zero children
      n.pimid && pimids.push(n.pimid);
    }
  });
  return pimids.join();
};

export function parseTaxonomy(
  nodes: BrowseTreeNode[],
  m?: ParsedBrowseTaxonomyState,
  lookups?: number[]
): ParsedBrowseTaxonomyState {
  const parsedMap: ParsedBrowseTaxonomyState = m || {};
  nodes?.forEach((node, index) => {
    const nodeKey = node.id.toString();
    const updatedLookups = lookups ? [...lookups, index] : [index];
    parsedMap[parseInt(nodeKey)] = {
      lookups: updatedLookups,
      findabilityID: node.pimid ? node.pimid : appendPimids(node.children),
    };
    if (node.children.length > 0) {
      parseTaxonomy(node.children, parsedMap, updatedLookups);
    }
  });
  return parsedMap;
}

export const fetchAllEventBrowseIds = (data: BrowseTreeNode[]): string[] => {
  const eventZones = featureFlags.get("event_zone_list");
  const eventBrowseIds: string[] = [...eventZones];
  const getChildrenIds = (node: BrowseTreeNode) => {
    if (node.children) {
      node.children.forEach((childNode: BrowseTreeNode) => {
        eventBrowseIds.push(childNode.id.toString());
        if (childNode.children) {
          getChildrenIds(childNode);
        }
      });
    }
  };

  data
    .filter(zone => eventZones.includes(zone.id.toString()))
    .forEach(eventZoneNode => {
      getChildrenIds(eventZoneNode);
    });

  return eventBrowseIds;
};

export function fetchTaxonomy() {
  return async (dispatch: Dispatch<any>, getState: () => State) => {
    if (getState().categories.length === 0) {
      const result = await fetchBrowseTree();
      if (result.isSuccess()) {
        dispatch({
          type: ActionTypes.FETCH_TAXONOMY_SUCCESS,
          data: result.data.data,
        });

        const parsedData = parseTaxonomy(result.data.data);
        dispatch({
          type: ActionTypes.PARSE_TAXONOMY_SUCCESS,
          data: parsedData,
        });
        const eventBrowseIds = fetchAllEventBrowseIds(result.data.data);
        dispatch({
          type: ActionTypes.FETCH_EVENTS_SUCCESS,
          data: eventBrowseIds,
        });
        const createdBreadcrumbs = createSynapticaBreadcrumbs(result.data.data);
        dispatch({
          type: ActionTypes.CREATE_BREADCRUMBS_SUCCESS,
          data: createdBreadcrumbs,
        });
      } else {
        dispatch({
          type: ActionTypes.FETCH_TAXONOMY_FAILURE,
        });
      }
    }
  };
}

export const browsePageViewAction = (
  tree: string,
  pageType: BrowsePageType,
  adobeProperties: AdobeDigitalDataProperties
): BrowsePageViewAction => {
  const breadCrumbItems = tree.split("/");
  const breadCrumbs = `Groceries::${breadCrumbItems.map(bci => capitalise(bci.replace(/-/g, " "))).join("::")}`;
  return {
    type: AnalyticsActionTypes.PAGE_VIEW,
    page: {
      name: BrowseAnalyticsPageName[pageType],
      template: BrowseAnalyticsPageTemplate[pageType],
      section: capitalise(breadCrumbItems[0].replace(/-/g, " ")),
      breadCrumbs,
      newTemplate: pageType === BrowsePageType.SHELF ? "plp" : BrowseAnalyticsPageTemplate[pageType],
    },
    adobeProperties,
  };
};
