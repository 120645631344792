import moment from "moment";
import "moment-timezone";

const MINUTES_TO_EXPIRY_THRESHOLD = 30;

export const isSlotNearExpiry = (reservationUntil: string) => {
  const slotExpiryMomentObject = moment(reservationUntil);
  const nowUK = moment.tz("Europe/London");
  const secondsUntilSlotExpiry = Math.round(slotExpiryMomentObject.diff(nowUK, "seconds", true));
  const minsUntilSlotExpiry = secondsUntilSlotExpiry / 60;
  const isNearExpiry = secondsUntilSlotExpiry > 0 && minsUntilSlotExpiry <= MINUTES_TO_EXPIRY_THRESHOLD;

  return isNearExpiry;
};
