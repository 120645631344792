import { Product as ServiceProduct } from "../../services/product.types";
import { Product as DomainProduct } from "../../domain/product";
import { featureFlags } from "../../common/firebase";

// Due to the unreliable nature of the breadcrumbs, we need to check the product categories instead
// It is also a tactical solution to store the allowed parent category names
// When we launch fully, we will need a better solution to get more meaningful category names
const TAGGSTAR_ENABLED_CATEGORY_IDS = {
  // Baby & toddler
  "12155": "Baby & toddler",
  "12156": "Baby & toddler",
  "12158": "Baby & toddler",
  "12159": "Baby & toddler",
  "12169": "Baby & toddler",
  "12171": "Baby & toddler",
  "12176": "Baby & toddler",
  "12179": "Baby & toddler",
  "12183": "Baby & toddler",
  "12224": "Baby & toddler",
  "12228": "Baby & toddler",
  "12232": "Baby & toddler",
  "12237": "Baby & toddler",
  "12241": "Baby & toddler",
  "44151": "Baby & toddler",
  "208289": "Baby & toddler",
  "208321": "Baby & toddler",
  "232287": "Baby & toddler",
  "270785": "Baby & toddler",
  "272253": "Baby & toddler",
  "275865": "Baby & toddler",
  "283855": "Baby & toddler",
  "299918": "Baby & toddler",
  "299919": "Baby & toddler",
  "299920": "Baby & toddler",
  "299921": "Baby & toddler",
  "299922": "Baby & toddler",
  "299923": "Baby & toddler",
  "322353": "Baby & toddler",
  "322354": "Baby & toddler",
  "322355": "Baby & toddler",
  "322357": "Baby & toddler",
  "323353": "Baby & toddler",
  "323354": "Baby & toddler",
  "323355": "Baby & toddler",
  "323356": "Baby & toddler",
  "323357": "Baby & toddler",
  "323358": "Baby & toddler",
  "323362": "Baby & toddler",
  "323364": "Baby & toddler",
  "323365": "Baby & toddler",
  "323367": "Baby & toddler",
  "323369": "Baby & toddler",
  "323370": "Baby & toddler",
  "323371": "Baby & toddler",
  "323372": "Baby & toddler",
  "323373": "Baby & toddler",
  "323375": "Baby & toddler",
  "323376": "Baby & toddler",
  "323377": "Baby & toddler",
  "323378": "Baby & toddler",
  "323379": "Baby & toddler",
  "323380": "Baby & toddler",
  "323381": "Baby & toddler",
  "323382": "Baby & toddler",
  "323383": "Baby & toddler",
  "323384": "Baby & toddler",
  "323386": "Baby & toddler",
  "323387": "Baby & toddler",
  "323388": "Baby & toddler",
  "323389": "Baby & toddler",
  "410375": "Baby & toddler",
  "419433": "Baby & toddler",
  "419745": "Baby & toddler",
  "431870": "Baby & toddler",
  "431871": "Baby & toddler",
  "458365": "Baby & toddler",
  "458366": "Baby & toddler",
  "458368": "Baby & toddler",
  "458369": "Baby & toddler",
  "458370": "Baby & toddler",
  "458372": "Baby & toddler",
  "458373": "Baby & toddler",
  "458374": "Baby & toddler",
  "458378": "Baby & toddler",
  "458381": "Baby & toddler",
  "458386": "Baby & toddler",
  "489873": "Baby & toddler",
  "510370": "Baby & toddler",
  "521855": "Baby & toddler",
  "548352": "Baby & toddler",
  "557382": "Baby & toddler",
  // Beauty & cosmetics
  "448393": "Beauty & cosmetics",
  "448395": "Beauty & cosmetics",
  "448403": "Beauty & cosmetics",
  "448404": "Beauty & cosmetics",
  "448405": "Beauty & cosmetics",
  "448406": "Beauty & cosmetics",
  "448408": "Beauty & cosmetics",
  "448409": "Beauty & cosmetics",
  "448410": "Beauty & cosmetics",
  "448412": "Beauty & cosmetics",
  "448413": "Beauty & cosmetics",
  "448414": "Beauty & cosmetics",
  "448415": "Beauty & cosmetics",
  "448416": "Beauty & cosmetics",
  "448417": "Beauty & cosmetics",
  "448418": "Beauty & cosmetics",
  "448422": "Beauty & cosmetics",
  "448423": "Beauty & cosmetics",
  "448424": "Beauty & cosmetics",
  "448425": "Beauty & cosmetics",
  "448426": "Beauty & cosmetics",
  "448435": "Beauty & cosmetics",
  "448436": "Beauty & cosmetics",
  "448437": "Beauty & cosmetics",
  "448440": "Beauty & cosmetics",
  "448441": "Beauty & cosmetics",
  "448443": "Beauty & cosmetics",
  "448869": "Beauty & cosmetics",
  "448870": "Beauty & cosmetics",
  "448879": "Beauty & cosmetics",
  "448881": "Beauty & cosmetics",
  "448882": "Beauty & cosmetics",
  "450366": "Beauty & cosmetics",
  "450367": "Beauty & cosmetics",
  "450368": "Beauty & cosmetics",
  "450369": "Beauty & cosmetics",
  "450371": "Beauty & cosmetics",
  "450372": "Beauty & cosmetics",
  "450373": "Beauty & cosmetics",
  "450374": "Beauty & cosmetics",
  "450375": "Beauty & cosmetics",
  "450376": "Beauty & cosmetics",
  "450377": "Beauty & cosmetics",
  "450378": "Beauty & cosmetics",
  "450379": "Beauty & cosmetics",
  "450380": "Beauty & cosmetics",
  "450381": "Beauty & cosmetics",
  "450382": "Beauty & cosmetics",
  "450383": "Beauty & cosmetics",
  "450384": "Beauty & cosmetics",
  "450388": "Beauty & cosmetics",
  "450389": "Beauty & cosmetics",
  "450390": "Beauty & cosmetics",
  "450391": "Beauty & cosmetics",
  "450392": "Beauty & cosmetics",
  "450393": "Beauty & cosmetics",
  "450394": "Beauty & cosmetics",
  "450396": "Beauty & cosmetics",
  "450397": "Beauty & cosmetics",
  "450398": "Beauty & cosmetics",
  "450399": "Beauty & cosmetics",
  "450400": "Beauty & cosmetics",
  "450402": "Beauty & cosmetics",
  "450403": "Beauty & cosmetics",
  "450404": "Beauty & cosmetics",
  "450405": "Beauty & cosmetics",
  "450406": "Beauty & cosmetics",
  "450407": "Beauty & cosmetics",
  "450408": "Beauty & cosmetics",
  "450409": "Beauty & cosmetics",
  "450410": "Beauty & cosmetics",
  "450411": "Beauty & cosmetics",
  "450412": "Beauty & cosmetics",
  "450413": "Beauty & cosmetics",
  "450414": "Beauty & cosmetics",
  "450416": "Beauty & cosmetics",
  "450417": "Beauty & cosmetics",
  "450418": "Beauty & cosmetics",
  "450419": "Beauty & cosmetics",
  "450420": "Beauty & cosmetics",
  "450421": "Beauty & cosmetics",
  "450422": "Beauty & cosmetics",
  "450423": "Beauty & cosmetics",
  "450424": "Beauty & cosmetics",
  "450434": "Beauty & cosmetics",
  "450435": "Beauty & cosmetics",
  "450436": "Beauty & cosmetics",
  "450437": "Beauty & cosmetics",
  "450438": "Beauty & cosmetics",
  "450439": "Beauty & cosmetics",
  "450440": "Beauty & cosmetics",
  "450441": "Beauty & cosmetics",
  "450442": "Beauty & cosmetics",
  "450443": "Beauty & cosmetics",
  "450444": "Beauty & cosmetics",
  "450451": "Beauty & cosmetics",
  "450456": "Beauty & cosmetics",
  "450457": "Beauty & cosmetics",
  "450458": "Beauty & cosmetics",
  "450460": "Beauty & cosmetics",
  "450461": "Beauty & cosmetics",
  "450462": "Beauty & cosmetics",
  "450463": "Beauty & cosmetics",
  "450476": "Beauty & cosmetics",
  "557366": "Beauty & cosmetics",
  "557380": "Beauty & cosmetics",
  "557381": "Beauty & cosmetics",
  "557385": "Beauty & cosmetics",
  // Toiletries & health
  "12465": "Toiletries & health",
  "12466": "Toiletries & health",
  "12468": "Toiletries & health",
  "12469": "Toiletries & health",
  "12605": "Toiletries & health",
  "12606": "Toiletries & health",
  "12607": "Toiletries & health",
  "12608": "Toiletries & health",
  "12610": "Toiletries & health",
  "12613": "Toiletries & health",
  "12619": "Toiletries & health",
  "12632": "Toiletries & health",
  "12633": "Toiletries & health",
  "12636": "Toiletries & health",
  "12638": "Toiletries & health",
  "12640": "Toiletries & health",
  "12643": "Toiletries & health",
  "12645": "Toiletries & health",
  "18666": "Toiletries & health",
  "18687": "Toiletries & health",
  "18697": "Toiletries & health",
  "188208": "Toiletries & health",
  "246807": "Toiletries & health",
  "247784": "Toiletries & health",
  "247787": "Toiletries & health",
  "247793": "Toiletries & health",
  "247794": "Toiletries & health",
  "247795": "Toiletries & health",
  "247796": "Toiletries & health",
  "247797": "Toiletries & health",
  "247813": "Toiletries & health",
  "247814": "Toiletries & health",
  "247815": "Toiletries & health",
  "247817": "Toiletries & health",
  "247822": "Toiletries & health",
  "247823": "Toiletries & health",
  "247824": "Toiletries & health",
  "247825": "Toiletries & health",
  "247826": "Toiletries & health",
  "247827": "Toiletries & health",
  "247828": "Toiletries & health",
  "247834": "Toiletries & health",
  "247835": "Toiletries & health",
  "247836": "Toiletries & health",
  "247837": "Toiletries & health",
  "247838": "Toiletries & health",
  "247839": "Toiletries & health",
  "247844": "Toiletries & health",
  "247846": "Toiletries & health",
  "247935": "Toiletries & health",
  "247936": "Toiletries & health",
  "247937": "Toiletries & health",
  "247938": "Toiletries & health",
  "247939": "Toiletries & health",
  "247940": "Toiletries & health",
  "247945": "Toiletries & health",
  "247946": "Toiletries & health",
  "247947": "Toiletries & health",
  "247953": "Toiletries & health",
  "247954": "Toiletries & health",
  "247955": "Toiletries & health",
  "247956": "Toiletries & health",
  "247957": "Toiletries & health",
  "247963": "Toiletries & health",
  "247964": "Toiletries & health",
  "247965": "Toiletries & health",
  "247966": "Toiletries & health",
  "247967": "Toiletries & health",
  "247971": "Toiletries & health",
  "247972": "Toiletries & health",
  "247973": "Toiletries & health",
  "247974": "Toiletries & health",
  "247978": "Toiletries & health",
  "247979": "Toiletries & health",
  "247982": "Toiletries & health",
  "274797": "Toiletries & health",
  "275371": "Toiletries & health",
  "275372": "Toiletries & health",
  "275373": "Toiletries & health",
  "275374": "Toiletries & health",
  "275375": "Toiletries & health",
  "275379": "Toiletries & health",
  "275380": "Toiletries & health",
  "275383": "Toiletries & health",
  "275387": "Toiletries & health",
  "275388": "Toiletries & health",
  "275389": "Toiletries & health",
  "275390": "Toiletries & health",
  "275391": "Toiletries & health",
  "275395": "Toiletries & health",
  "275396": "Toiletries & health",
  "275397": "Toiletries & health",
  "275398": "Toiletries & health",
  "275401": "Toiletries & health",
  "275402": "Toiletries & health",
  "275403": "Toiletries & health",
  "275404": "Toiletries & health",
  "275405": "Toiletries & health",
  "275410": "Toiletries & health",
  "275918": "Toiletries & health",
  "275919": "Toiletries & health",
  "275920": "Toiletries & health",
  "275921": "Toiletries & health",
  "275922": "Toiletries & health",
  "275923": "Toiletries & health",
  "275924": "Toiletries & health",
  "275925": "Toiletries & health",
  "275926": "Toiletries & health",
  "275934": "Toiletries & health",
  "275935": "Toiletries & health",
  "275936": "Toiletries & health",
  "275937": "Toiletries & health",
  "275938": "Toiletries & health",
  "275940": "Toiletries & health",
  "275941": "Toiletries & health",
  "275942": "Toiletries & health",
  "275943": "Toiletries & health",
  "275944": "Toiletries & health",
  "275945": "Toiletries & health",
  "275946": "Toiletries & health",
  "275948": "Toiletries & health",
  "275949": "Toiletries & health",
  "275950": "Toiletries & health",
  "275951": "Toiletries & health",
  "275955": "Toiletries & health",
  "275956": "Toiletries & health",
  "275957": "Toiletries & health",
  "275958": "Toiletries & health",
  "275959": "Toiletries & health",
  "279826": "Toiletries & health",
  "279840": "Toiletries & health",
  "279846": "Toiletries & health",
  "283810": "Toiletries & health",
  "283812": "Toiletries & health",
  "283856": "Toiletries & health",
  "283857": "Toiletries & health",
  "283858": "Toiletries & health",
  "283859": "Toiletries & health",
  "283861": "Toiletries & health",
  "283862": "Toiletries & health",
  "289497": "Toiletries & health",
  "293828": "Toiletries & health",
  "293830": "Toiletries & health",
  "293831": "Toiletries & health",
  "294347": "Toiletries & health",
  "294348": "Toiletries & health",
  "294350": "Toiletries & health",
  "294351": "Toiletries & health",
  "294352": "Toiletries & health",
  "294368": "Toiletries & health",
  "294369": "Toiletries & health",
  "294371": "Toiletries & health",
  "294373": "Toiletries & health",
  "294374": "Toiletries & health",
  "294375": "Toiletries & health",
  "294376": "Toiletries & health",
  "294377": "Toiletries & health",
  "294378": "Toiletries & health",
  "294381": "Toiletries & health",
  "294383": "Toiletries & health",
  "294385": "Toiletries & health",
  "294386": "Toiletries & health",
  "294387": "Toiletries & health",
  "294389": "Toiletries & health",
  "294390": "Toiletries & health",
  "294391": "Toiletries & health",
  "294392": "Toiletries & health",
  "294393": "Toiletries & health",
  "294395": "Toiletries & health",
  "294396": "Toiletries & health",
  "294397": "Toiletries & health",
  "294398": "Toiletries & health",
  "294400": "Toiletries & health",
  "294401": "Toiletries & health",
  "294402": "Toiletries & health",
  "294404": "Toiletries & health",
  "294405": "Toiletries & health",
  "294407": "Toiletries & health",
  "294408": "Toiletries & health",
  "294409": "Toiletries & health",
  "294411": "Toiletries & health",
  "294412": "Toiletries & health",
  "294413": "Toiletries & health",
  "294414": "Toiletries & health",
  "294415": "Toiletries & health",
  "294416": "Toiletries & health",
  "294417": "Toiletries & health",
  "294418": "Toiletries & health",
  "294419": "Toiletries & health",
  "294420": "Toiletries & health",
  "294421": "Toiletries & health",
  "294424": "Toiletries & health",
  "294425": "Toiletries & health",
  "294426": "Toiletries & health",
  "294429": "Toiletries & health",
  "294431": "Toiletries & health",
  "294432": "Toiletries & health",
  "294433": "Toiletries & health",
  "294434": "Toiletries & health",
  "294435": "Toiletries & health",
  "294436": "Toiletries & health",
  "294441": "Toiletries & health",
  "294442": "Toiletries & health",
  "294443": "Toiletries & health",
  "294444": "Toiletries & health",
  "294445": "Toiletries & health",
  "294446": "Toiletries & health",
  "294448": "Toiletries & health",
  "294449": "Toiletries & health",
  "294450": "Toiletries & health",
  "294451": "Toiletries & health",
  "295324": "Toiletries & health",
  "295325": "Toiletries & health",
  "295326": "Toiletries & health",
  "295327": "Toiletries & health",
  "295328": "Toiletries & health",
  "296329": "Toiletries & health",
  "296331": "Toiletries & health",
  "296332": "Toiletries & health",
  "296333": "Toiletries & health",
  "296334": "Toiletries & health",
  "297802": "Toiletries & health",
  "297803": "Toiletries & health",
  "297804": "Toiletries & health",
  "297805": "Toiletries & health",
  "297806": "Toiletries & health",
  "299909": "Toiletries & health",
  "304854": "Toiletries & health",
  "312378": "Toiletries & health",
  "367876": "Toiletries & health",
  "367877": "Toiletries & health",
  "367878": "Toiletries & health",
  "379860": "Toiletries & health",
  "379861": "Toiletries & health",
  "400864": "Toiletries & health",
  "404876": "Toiletries & health",
  "404878": "Toiletries & health",
  "404879": "Toiletries & health",
  "404880": "Toiletries & health",
  "404881": "Toiletries & health",
  "404882": "Toiletries & health",
  "404883": "Toiletries & health",
  "404884": "Toiletries & health",
  "404886": "Toiletries & health",
  "404887": "Toiletries & health",
  "404888": "Toiletries & health",
  "404889": "Toiletries & health",
  "404890": "Toiletries & health",
  "404891": "Toiletries & health",
  "404893": "Toiletries & health",
  "404894": "Toiletries & health",
  "404895": "Toiletries & health",
  "404896": "Toiletries & health",
  "404897": "Toiletries & health",
  "404898": "Toiletries & health",
  "404899": "Toiletries & health",
  "404900": "Toiletries & health",
  "404901": "Toiletries & health",
  "404902": "Toiletries & health",
  "405363": "Toiletries & health",
  "410355": "Toiletries & health",
  "411852": "Toiletries & health",
  "419369": "Toiletries & health",
  "419736": "Toiletries & health",
  "428854": "Toiletries & health",
  "428855": "Toiletries & health",
  "428856": "Toiletries & health",
  "428857": "Toiletries & health",
  "428858": "Toiletries & health",
  "428859": "Toiletries & health",
  "428860": "Toiletries & health",
  "428861": "Toiletries & health",
  "428862": "Toiletries & health",
  "428863": "Toiletries & health",
  "428864": "Toiletries & health",
  "428865": "Toiletries & health",
  "474590": "Toiletries & health",
  "514861": "Toiletries & health",
  "534867": "Toiletries & health",
  "608356": "Toiletries & health",
  "608357": "Toiletries & health",
  // Homeware & outdoor
  "281816": "Homeware & outdoor",
  "281818": "Homeware & outdoor",
  "281819": "Homeware & outdoor",
  "281821": "Homeware & outdoor",
  "281823": "Homeware & outdoor",
  "281824": "Homeware & outdoor",
  "281825": "Homeware & outdoor",
  "281826": "Homeware & outdoor",
  "281827": "Homeware & outdoor",
  "281828": "Homeware & outdoor",
  "281829": "Homeware & outdoor",
  "281831": "Homeware & outdoor",
  "281833": "Homeware & outdoor",
  "281834": "Homeware & outdoor",
  "281835": "Homeware & outdoor",
  "281836": "Homeware & outdoor",
  "281837": "Homeware & outdoor",
  "281838": "Homeware & outdoor",
  "281839": "Homeware & outdoor",
  "281840": "Homeware & outdoor",
  "281842": "Homeware & outdoor",
  "281843": "Homeware & outdoor",
  "281844": "Homeware & outdoor",
  "281847": "Homeware & outdoor",
  "281848": "Homeware & outdoor",
  "281849": "Homeware & outdoor",
  "281850": "Homeware & outdoor",
  "281851": "Homeware & outdoor",
  "281852": "Homeware & outdoor",
  "281854": "Homeware & outdoor",
  "281855": "Homeware & outdoor",
  "281856": "Homeware & outdoor",
  "281857": "Homeware & outdoor",
  "281860": "Homeware & outdoor",
  "281862": "Homeware & outdoor",
  "281863": "Homeware & outdoor",
  "281864": "Homeware & outdoor",
  "281865": "Homeware & outdoor",
  "281866": "Homeware & outdoor",
  "281867": "Homeware & outdoor",
  "281868": "Homeware & outdoor",
  "281869": "Homeware & outdoor",
  "281871": "Homeware & outdoor",
  "281872": "Homeware & outdoor",
  "281873": "Homeware & outdoor",
  "281874": "Homeware & outdoor",
  "281875": "Homeware & outdoor",
  "281876": "Homeware & outdoor",
  "281878": "Homeware & outdoor",
  "281879": "Homeware & outdoor",
  "281880": "Homeware & outdoor",
  "281881": "Homeware & outdoor",
  "281882": "Homeware & outdoor",
  "281883": "Homeware & outdoor",
  "281884": "Homeware & outdoor",
  "281885": "Homeware & outdoor",
  "281907": "Homeware & outdoor",
  "281908": "Homeware & outdoor",
  "281909": "Homeware & outdoor",
  "281910": "Homeware & outdoor",
  "281911": "Homeware & outdoor",
  "281912": "Homeware & outdoor",
  "281913": "Homeware & outdoor",
  "281914": "Homeware & outdoor",
  "281916": "Homeware & outdoor",
  "281917": "Homeware & outdoor",
  "281918": "Homeware & outdoor",
  "281919": "Homeware & outdoor",
  "281920": "Homeware & outdoor",
  "281921": "Homeware & outdoor",
  "281930": "Homeware & outdoor",
  "281946": "Homeware & outdoor",
  "281949": "Homeware & outdoor",
  "281950": "Homeware & outdoor",
  "281958": "Homeware & outdoor",
  "281960": "Homeware & outdoor",
  "281962": "Homeware & outdoor",
  "281963": "Homeware & outdoor",
  "281965": "Homeware & outdoor",
  "281966": "Homeware & outdoor",
  "281967": "Homeware & outdoor",
  "281968": "Homeware & outdoor",
  "281969": "Homeware & outdoor",
  "282304": "Homeware & outdoor",
  "282305": "Homeware & outdoor",
  "282308": "Homeware & outdoor",
  "282309": "Homeware & outdoor",
  "282311": "Homeware & outdoor",
  "282312": "Homeware & outdoor",
  "283821": "Homeware & outdoor",
  "283822": "Homeware & outdoor",
  "283837": "Homeware & outdoor",
  "283868": "Homeware & outdoor",
  "283869": "Homeware & outdoor",
  "283870": "Homeware & outdoor",
  "283871": "Homeware & outdoor",
  "283872": "Homeware & outdoor",
  "288306": "Homeware & outdoor",
  "296323": "Homeware & outdoor",
  "296324": "Homeware & outdoor",
  "296325": "Homeware & outdoor",
  "299949": "Homeware & outdoor",
  "314864": "Homeware & outdoor",
  "333383": "Homeware & outdoor",
  "337433": "Homeware & outdoor",
  "346892": "Homeware & outdoor",
  "349927": "Homeware & outdoor",
  "350445": "Homeware & outdoor",
  "350446": "Homeware & outdoor",
  "350448": "Homeware & outdoor",
  "350449": "Homeware & outdoor",
  "350450": "Homeware & outdoor",
  "350451": "Homeware & outdoor",
  "410366": "Homeware & outdoor",
  "419381": "Homeware & outdoor",
  "419501": "Homeware & outdoor",
  "419679": "Homeware & outdoor",
  "419733": "Homeware & outdoor",
  "460380": "Homeware & outdoor",
  "484859": "Homeware & outdoor",
  "487852": "Homeware & outdoor",
  "494381": "Homeware & outdoor",
  "506366": "Homeware & outdoor",
  "506367": "Homeware & outdoor",
  "527858": "Homeware & outdoor",
  "557356": "Homeware & outdoor",
  // Beer & cider
  "12260": "Beer & cider",
  "12267": "Beer & cider",
  "12269": "Beer & cider",
  "242801": "Beer & cider",
  "275894": "Beer & cider",
  "275896": "Beer & cider",
  "275897": "Beer & cider",
  "278253": "Beer & cider",
  "318428": "Beer & cider",
  "478380": "Beer & cider",
  "506355": "Beer & cider",
  "506356": "Beer & cider",
  "506357": "Beer & cider",
  // Wine
  "12211": "Wine",
  "12225": "Wine",
  "12238": "Wine",
  "18653": "Wine",
  "209298": "Wine",
  "258772": "Wine",
  "299875": "Wine",
  "299876": "Wine",
  "299877": "Wine",
  "299878": "Wine",
  "299879": "Wine",
  "299880": "Wine",
  "299883": "Wine",
  "299886": "Wine",
  "299887": "Wine",
  "299888": "Wine",
  "458352": "Wine",
  "458353": "Wine",
  "458362": "Wine",
  "545855": "Wine",
  // Champagne & sparkling wine
  "458355": "Champagne & sparkling wine",
  "458356": "Champagne & sparkling wine",
  "458357": "Champagne & sparkling wine",
  "458358": "Champagne & sparkling wine",
  "458852": "Champagne & sparkling wine",
  // Spirits & liqueurs
  "12286": "Spirits & liqueurs",
  "12287": "Spirits & liqueurs",
  "12291": "Spirits & liqueurs",
  "12293": "Spirits & liqueurs",
  "12294": "Spirits & liqueurs",
  "12296": "Spirits & liqueurs",
  "12306": "Spirits & liqueurs",
  "41153": "Spirits & liqueurs",
  "199702": "Spirits & liqueurs",
  "458359": "Spirits & liqueurs",
  "458360": "Spirits & liqueurs",
  "458395": "Spirits & liqueurs",
  "458854": "Spirits & liqueurs",
  "458855": "Spirits & liqueurs",
  "510355": "Spirits & liqueurs",
  "513352": "Spirits & liqueurs",
  // Low & no alcohol
  "458391": "Low & no alcohol",
  "458392": "Low & no alcohol",
  "458860": "Low & no alcohol",
  "500353": "Low & no alcohol",
};

export type TaggstarConfig = {
  overrideEnabled: boolean;
  overrides: Record<string, string>;
};

function getCategoryMap() {
  const taggstarConfig = featureFlags.get("taggstar_config");

  return taggstarConfig?.overrideEnabled ? taggstarConfig.overrides : TAGGSTAR_ENABLED_CATEGORY_IDS;
}

export function extractCategory(product?: DomainProduct | ServiceProduct) {
  if (!product) {
    return "Unknown";
  }

  const TAGGSTAR_CATEGORY_IDS = getCategoryMap();

  for (const category of product.categories) {
    if (category.id in TAGGSTAR_CATEGORY_IDS) {
      return TAGGSTAR_CATEGORY_IDS[category.id];
    }
  }
  return "Unknown";
}

export function isTaggstarEnabledProduct(product?: DomainProduct) {
  if (!product || !window.location.pathname.toLowerCase().startsWith("/gol-ui/product/")) {
    return false;
  }

  const TAGGSTAR_CATEGORY_IDS = getCategoryMap();

  return product.categories.some(category => category.id in TAGGSTAR_CATEGORY_IDS);
}
