import { GOLUI_PREFIX } from "../../../../routes";
import { stripAndExtractListValue } from "../stripAndExtractListValue/stripAndExtractListValue";
import { trimSlashes } from "../../../../utils/trimSlashes/trimSlashes";

export const getMealDealSubStrings = (path: string) => {
  const OPTION_DELIMITER = "opt/";
  const { pathname, listIdSlug } = stripAndExtractListValue(path);
  const segments: string[] = pathname.split(OPTION_DELIMITER);
  const options = segments.length > 1 ? OPTION_DELIMITER.concat(segments[1]) : "";
  const landingURL = `${GOLUI_PREFIX}/meal-deal/${pathname.split("/")[3]}`;
  const filters = trimSlashes(segments[0].replace(landingURL, ""));
  return {
    landingURL,
    options,
    filters,
    listIdSlug,
  };
};
