import { useDispatch, useSelector } from "react-redux";
import { State } from "../../common/store";
import { DataState } from "../../common/dataState";
import { fetchSlotReservation } from "./slotReservation.actions";
import {
  CachedSlotReservation,
  DeliveryAddress,
  ClickAndCollectLocation,
  SlotReservation,
  SlotReservationType,
  SlotReservationTypeFormatted,
} from "./slotReservation.types";
import {
  SlotReservationType as SlotReservationTypeResponse,
  SlotReservationResponse,
  SlotReservationType as ServicesSlotReservationType,
  DeliveryAddress as ServicesDeliveryAddress,
  CollectionAddress as ServicesCollectionAddress,
} from "../../services/slot.types";
import { urls } from "../../routes";
import moment from "moment";
import * as momentTimeZone from "moment-timezone";
import GolTime from "../time";
import { formatCurrency } from "../../common/format";
import { useReportError } from "../../components/Error/reportErrorHook";

export interface UseSlotReservation {
  slotReservation: SlotReservation;
  originalSlotReservation: SlotReservation | null;
  dataState: DataState;
  fetchSlotReservation: () => void;
}

export const useSlotReservation = (): UseSlotReservation => {
  const slotReservationState = useSelector((state: State) => state.slotReservation);
  const dispatch = useDispatch();

  return {
    slotReservation: slotReservationState.slotReservation,
    originalSlotReservation: slotReservationState.originalSlotReservation,
    dataState: slotReservationState.dataState,
    fetchSlotReservation: () => dispatch(fetchSlotReservation()),
  };
};

export const getBookSlotUrl = (slotReservation: SlotReservation): string => {
  const refererParam = `&currentPageUrl=${encodeURIComponent(window.location.href)}`;
  if (slotReservation.reservationType === SlotReservationType.CLICK_AND_COLLECT) {
    return `${urls.COLLECTION_LOCATION_URL}${refererParam}`;
  }
  return `${urls.BOOK_DELIVERY_SLOT}${refererParam}`;
};

const mapSlotReservationType = (slotType: string): SlotReservationType => {
  if (slotType === ServicesSlotReservationType.DELIVERY) {
    return SlotReservationType.DELIVERY;
  }

  if (slotType === ServicesSlotReservationType.COLLECTION) {
    return SlotReservationType.CLICK_AND_COLLECT;
  }

  return SlotReservationType.NONE;
};

const mapSlotReservationDate = (slot: string): string => {
  const dt = moment(slot, moment.ISO_8601).locale("en");
  return dt.format("ddd Do");
};

const mapSlotReservationTimeSlot = (reservationStartTime: string, reservationEndTime: string): string => {
  const dtStart = moment(reservationStartTime, moment.ISO_8601).locale("en");
  const dtEnd = moment(reservationEndTime, moment.ISO_8601).locale("en");
  return `${dtStart.format("h:mma")}-${dtEnd.format("h:mma")}`;
};

export const mapSlotReservationPostCode = (slot: SlotReservationResponse): string => {
  if (slot && slot.reservation_type === ServicesSlotReservationType.DELIVERY) {
    return slot.delivery_address && slot.delivery_address.postcode ? slot.delivery_address.postcode : slot.postcode;
  }
  if (slot && slot.reservation_type === ServicesSlotReservationType.COLLECTION) {
    return slot.click_and_collect_location.postcode;
  }
  return "";
};

export const mapSlotReservationAddressNickname = (slot: SlotReservationResponse): string => {
  const { reservation_type: reservationType } = slot;

  if (reservationType === ServicesSlotReservationType.DELIVERY) {
    return slot.delivery_address ? slot.delivery_address.nickname : "";
  }

  if (reservationType === ServicesSlotReservationType.COLLECTION) {
    return slot.click_and_collect_location ? slot.click_and_collect_location.name : "";
  }

  return "";
};

export const mapSlotReservation = (slot: SlotReservationResponse): SlotReservation => ({
  reservationType: mapSlotReservationType(slot.reservation_type),
  reservationExpired: Boolean(slot.is_expired),
  reservationDate: slot.slot ? mapSlotReservationDate(slot.slot.start_time) : "",
  reservationStartTime:
    slot.slot && slot.slot.start_time
      ? momentTimeZone.tz(slot.slot.start_time, "Europe/London").format(GolTime.servicesFormat)
      : "",
  reservationEndTime:
    slot.slot && slot.slot.end_time
      ? momentTimeZone.tz(slot.slot.end_time, "Europe/London").format(GolTime.servicesFormat)
      : "",
  reservationTimeSlot: slot.slot ? mapSlotReservationTimeSlot(slot.slot.start_time, slot.slot.end_time) : "",
  reservationIsGreen: slot.slot ? slot.slot.is_green : false,
  storeIdentifier: slot.store_identifier || "",
  isAlcoholRestrictedStore: Boolean(slot.is_alcohol_restricted_store),
  reservationUntil: slot.reserved_until
    ? momentTimeZone.tz(slot.reserved_until, "Europe/London").format(GolTime.servicesFormat)
    : "",
  reservationPostCode: mapSlotReservationPostCode(slot),
  reservationAddressNickname: slot.slot ? mapSlotReservationAddressNickname(slot) : "",
  isSameDay: slot.slot ? slot.slot.is_same_day : false,
  slotPriceFormatted: slot.slot ? formatCurrency(slot.slot.price) : "",
  slotPrice: slot.slot ? slot.slot.price : undefined,
  slotQualifiedPrice: slot.slot ? slot.slot.qualified_price : undefined,
  isDeliveryPassApplicable: slot.slot ? slot.slot.is_delivery_pass_applicable : false,
  deliveryAddress: slot.delivery_address ? mapDeliveryAddress(slot.delivery_address) : undefined,
  region: slot.region || "",
  slotType: slot.slot ? slot.slot.slot_type : "",
  postcode: slot.postcode,
  locationUid: slot.click_and_collect_location ? slot.click_and_collect_location.location_uid : "",
  clickAndCollectLocation: slot.click_and_collect_location
    ? mapClickAndCollectLocation(slot.click_and_collect_location)
    : undefined,
  isExpired: slot.is_expired,
  reservationFlexiStores: slot.flexi_stores || undefined,
  isXmasSlot: slot.slot ? slot.slot.is_xmas : false,
});

export function mapDeliveryAddress(deliveryAddress: ServicesDeliveryAddress): DeliveryAddress {
  return {
    addressUid: deliveryAddress.address_uid,
    nickname: deliveryAddress.nickname,
    street: deliveryAddress.street,
    buildingName: deliveryAddress.building_name,
    buildingNumber: deliveryAddress.building_number,
    flatNumber: deliveryAddress.flat_number,
    town: deliveryAddress.town,
    county: deliveryAddress.county,
    postcode: deliveryAddress.postcode,
  };
}

export function mapClickAndCollectLocation(
  clickAndCollectLocation: ServicesCollectionAddress
): ClickAndCollectLocation | undefined {
  if (!clickAndCollectLocation.name && !clickAndCollectLocation.postcode) {
    return;
  }

  return {
    name: clickAndCollectLocation.name,
    postcode: clickAndCollectLocation.postcode,
  };
}

const slotReservationCacheKey = "slot-reservation-cached";

/**
 * Gets the cached slot reservation from session storage.
 *
 * This should only be used for passing to product services, to avoid fetching the slot before we initiate the product
 * request.
 */
export const getCachedSlotReservation = (): CachedSlotReservation | undefined => {
  const reservation = localStorage.getItem(slotReservationCacheKey);
  try {
    return reservation ? JSON.parse(reservation) : undefined;
  } catch (e) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReportError()(e);
    return undefined;
  }
};

/**
 * Stores the slot reservation info in session storage
 *
 * We store this to avoid blocking fetching products until the slot reservation data is available.
 * Product services need this info to do ranging checks, and we don't want to prevent initiating the product request
 * until slot reservation is available.
 */
export const cacheSlotReservation = (rangingInfo: {
  storeIdentifier: string;
  region: string;
  slotStartTime?: string;
  slotDateTime?: string;
  flexiStores?: string[];
  postCode?: string;
  reservationType?: string;
  storeName?: string;
  slotEndTime?: string;
  qualifiedPrice?: string;
}) => {
  localStorage.setItem(
    slotReservationCacheKey,
    JSON.stringify({
      storeIdentifier: rangingInfo.storeIdentifier,
      region: rangingInfo.region,
      slotDate: getDateFromSlotStartTime(rangingInfo.slotStartTime),
      slotDateTime: getSlotTimeFromString(rangingInfo.slotStartTime),
      slotEndTime: getSlotEndTimeFromString(rangingInfo.slotEndTime),
      flexiStores: rangingInfo.flexiStores,
      postCode: rangingInfo.postCode,
      reservationType: rangingInfo.reservationType,
      storeName: rangingInfo.storeName,
      qualifiedPrice: rangingInfo.qualifiedPrice,
    })
  );
};

export const deleteCachedSlotReservation = () => localStorage.removeItem(slotReservationCacheKey);

function getSlotEndTimeFromString(endTime?: string): string | undefined {
  if (!endTime) {
    return undefined;
  }
  return new GolTime(endTime).format("YYYY-MM-DDTHH:mm:ss");
}

export const getSlotTimeFromString = (time?: string): string | undefined => {
  if (!time) {
    return undefined;
  }
  return new GolTime(time).format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export function getDateFromSlotStartTime(startTime?: string): string | undefined {
  if (!startTime) {
    return undefined;
  }
  return new GolTime(startTime).formatAsDate();
}

export const formatReservationTypeString = (reservationType: SlotReservationType): SlotReservationTypeFormatted =>
  reservationType === SlotReservationType.DELIVERY
    ? SlotReservationTypeFormatted.DELIVERY
    : reservationType === SlotReservationType.CLICK_AND_COLLECT
    ? SlotReservationTypeFormatted.CLICK_AND_COLLECT
    : SlotReservationTypeFormatted.NONE;

export const getSlotExpiryDateAndTimeFormatted = (reservationUntil: string): string => {
  if (reservationUntil) {
    const reservationUntilDateAndTime = moment.tz(reservationUntil, "Europe/London");
    const time = reservationUntilDateAndTime.format("h:mma");
    const reservationUntilFormatted = moment(reservationUntil).tz("Europe/London").format("dddd DD MMMM");
    const isSlotExpiryToday = reservationUntilDateAndTime.isSame(moment.tz("Europe/London"), "day");
    const isSlotExpiryTomorrow = reservationUntilDateAndTime.isSame(moment.tz("Europe/London").add(1, "days"));
    const day = isSlotExpiryToday ? "today" : isSlotExpiryTomorrow ? "tomorrow" : reservationUntilFormatted;

    return `${time} ${day}`;
  }

  return "";
};

/**
 * Checks if the slot response is valid. Slot is invalid if it has expired or if the slot type is "none"
 * WCS will return a slot response even when the slot has expired or the slot type is "none"
 * @param slotReservation: SlotReservationResponse
 * @returns boolean
 */
export const isValidSlotReservationResponse = (slotReservation: SlotReservationResponse): boolean => {
  if (!slotReservation) {
    return false;
  }

  return Boolean(
    slotReservation.is_expired === false &&
      slotReservation.reservation_type &&
      slotReservation.reservation_type !== SlotReservationTypeResponse.NONE &&
      slotReservation.store_identifier
  );
};
