import { ProductTilePrice, ProductTilePromotion } from "../components/ProductTile/productTile.types";
import { currencyFormatter } from "../components/ProductTile/ProductTile.selectors";
import { PromoMechanicIds } from "../common/types";

// Only display a promotion when the Retail Price is different from the Original Price
export function hasOfferPromotion(
  promotions: ProductTilePromotion[] | undefined,
  retailPrice: ProductTilePrice | undefined
) {
  if (!promotions || !retailPrice) {
    return false;
  }

  // first promotion can sometimes have an original price of 0 so 'find' searches for first promotion that contains original price
  const firstPromotion = promotions.find(({ originalPrice }) => originalPrice);

  if (!firstPromotion || firstPromotion.promoMechanicId === PromoMechanicIds.NECTAR_PROMOTION) {
    return false;
  }

  if (firstPromotion.originalPrice) {
    const formattedOriginalPrice = currencyFormatter(firstPromotion.originalPrice);
    return retailPrice.price !== formattedOriginalPrice;
  }
  return false;
}
