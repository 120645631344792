import { WithDataState } from "../../common/dataState";
import { NectarOffer } from "../../services/nectar.types";

export interface NectarOffers {
  accountStatus: "nectar_linked" | "none";
  offers: NectarOffer[];
}

export interface NectarState extends WithDataState {
  data: NectarOffers;
}

export enum NectarActions {
  FETCH_NECTAR_OFFERS_PENDING = "FETCH_NECTAR_OFFERS_PENDING",
  FETCH_NECTAR_OFFERS_SUCCESS = "FETCH_NECTAR_OFFERS_SUCCESS",
  FETCH_NECTAR_OFFERS_ERROR = "FETCH_NECTAR_OFFERS_ERROR",
}

export type NectarActionTypes =
  | FetchNectarOffersSuccessAction
  | FetchNectarOffersPendingAction
  | FetchNectarOffersErrorAction;

export type FetchNectarOffersSuccessAction = {
  type: NectarActions.FETCH_NECTAR_OFFERS_SUCCESS;
  nectarOffers: NectarOffers;
};

export type FetchNectarOffersPendingAction = {
  type: NectarActions.FETCH_NECTAR_OFFERS_PENDING;
};

export type FetchNectarOffersErrorAction = {
  type: NectarActions.FETCH_NECTAR_OFFERS_ERROR;
};
