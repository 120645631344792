import clsx from "clsx";
import { Nullable } from "../../common/types";
import React from "react";

type Theme = { themeColour: string; backgroundColour: string };
type ThemeContext = { theme: Nullable<Theme>; setTheme: React.Dispatch<React.SetStateAction<Nullable<Theme>>> };

const initialThemeContext = { theme: null, setTheme: () => {} };
export const pageThemeContext = React.createContext<ThemeContext>(initialThemeContext);

export function PageThemeContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [themeData, setThemeData] = React.useState<Nullable<Theme>>(null);
  return (
    <pageThemeContext.Provider
      value={{
        theme: themeData,
        setTheme: setThemeData,
      }}
    >
      {children}
    </pageThemeContext.Provider>
  );
}

export function PageTheme({ children, enabled = false }: React.PropsWithChildren<{ enabled: boolean }>) {
  const ctx = React.useContext(pageThemeContext);
  return (
    <div
      data-testid="theme-container"
      className={clsx(enabled && ctx?.theme && `gol-theme gol-theme-${ctx.theme.themeColour}`)}
      style={enabled && ctx?.theme ? { backgroundColor: ctx.theme.backgroundColour } : undefined}
    >
      {children}
    </div>
  );
}
