import { Action, AnyAction } from "redux";
import { CloseCurrentlyVisibleModalAction, ModalActionTypes, ModalCurrentlyVisibleAction } from "./Modal.types";

export const createOpenModalAction = (meta?: object): AnyAction => ({
  type: ModalActionTypes.OPEN_MODAL,
  meta,
});

export const createCloseModalAction = (): Action => ({
  type: ModalActionTypes.CLOSE_MODAL,
});

export const createCurrentlyVisibleModalAction = (name: string): ModalCurrentlyVisibleAction => ({
  type: ModalActionTypes.CURRENTLY_VISIBLE_MODAL,
  name,
});

export const createCloseCurrentlyVisibleModalAction = (name: string): CloseCurrentlyVisibleModalAction => ({
  type: ModalActionTypes.CLOSE_CURRENTLY_VISIBLE_MODAL,
  name,
});
