import React from "react";
import { ProductTileImageProps } from "./productTile.types";
import noImage from "../../static/no-image.svg";
import { FeatureEnabled } from "../../common/firebase";

interface ConditionalLinkProps {
  condition: string | undefined;
  wrapper: (children: React.ReactNode) => {};
  children: React.ReactNode;
}

interface ConditionalImageLabelProps {
  children: React.ReactNode;
  url?: string;
  productName: string;
  showOccasionBanner: boolean;
  isAvailable: boolean;
}

const ConditionalLink = ({ condition, wrapper, children }: ConditionalLinkProps) => {
  return <>{condition ? wrapper(children) : children}</>;
};

const ConditionalImageLabel = ({
  children,
  url,
  productName,
  showOccasionBanner,
  isAvailable,
}: ConditionalImageLabelProps) => (
  <div className="pt-image__ribbon-wrapper">
    <a href={url} className="pt-image pt-image__link" tabIndex={-1} aria-hidden={true}>
      {children}
      {!isAvailable && (
        <div
          tabIndex={-1}
          aria-hidden={false}
          className="pt-image__unavailable-sticker"
          aria-label={`${productName} Currently out of stock`}
        >
          Currently out of stock
        </div>
      )}
    </a>
    <FeatureEnabled flag="occasions">
      {showOccasionBanner && <div className="pt-image__banner pt-image__occasions-sticker">Occasions</div>}
    </FeatureEnabled>
  </div>
);

export const ProductTileImage = ({
  url,
  imageSrc,
  productName = "",
  isAvailable = true,
  showOccasionBanner = false,
}: ProductTileImageProps) => {
  const webpVersion = imageSrc ? imageSrc.replace("jpg", "webp") : noImage;

  return (
    <ConditionalLink
      condition={url}
      wrapper={children => (
        <ConditionalImageLabel
          url={url}
          productName={productName}
          showOccasionBanner={showOccasionBanner}
          isAvailable={isAvailable}
        >
          {children}
        </ConditionalImageLabel>
      )}
    >
      <picture>
        <source srcSet={webpVersion} type="image/webp" />
        <source srcSet={imageSrc ? imageSrc : noImage} type="image/jpeg" />
        <img
          title={productName}
          src={imageSrc ? imageSrc : noImage}
          alt={productName}
          aria-hidden={true}
          data-testid="pt-image"
        />
      </picture>
    </ConditionalLink>
  );
};

ProductTileImage.displayName = "ProductTileImage";
