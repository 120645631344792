import React from "react";
import { urls } from "../../../../routes";
import { MegaNavColumn } from "./MegaNavColumn";
import MegaNavContentComponent from "../MegaNavContent.component";
import SeasonalLinksComponent from "../SeasonalLinks.component";
import { MegaNavProps } from "../MegaNav.types";
import { useMegaNavLogic } from "../useMegaNavLogic";
import { useMegaNavActions } from "../../headerState/header.actions";

export const DesktopMegaNav: React.FC<MegaNavProps> = ({ ads, seasonal, onClose, navItems }) => {
  const { categories, selectedCategory, selectedSubcategory, handleCategorySelect, handleSubcategorySelect } =
    useMegaNavLogic(navItems);
  const { setSelectedMeganavCategory } = useMegaNavActions();

  function handleClickAway(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    if (event.target === event.currentTarget || (event as React.KeyboardEvent).key === "Escape") {
      onClose();
    }
  }

  return (
    <div
      className="mega-nav--backdrop"
      data-testid="mega-nav-container"
      onClick={handleClickAway}
      onKeyDown={handleClickAway}
      tabIndex={-1}
    >
      <div className="mega-nav" data-testid="mega-nav">
        <div className="mega-nav--content">
          <div className="mega-nav--column-wrapper" id="mega-nav--column-1">
            <MegaNavColumn
              title="Groceries Home"
              items={categories}
              onSelected={item => {
                handleCategorySelect(item);
                setSelectedMeganavCategory(item);
              }}
              selectedItem={selectedCategory}
              titleUrl={urls.BROWSE}
              data-testid="mega-nav-main-column"
            />
          </div>
          <div className="mega-nav--column-wrapper" id="mega-nav--column-2">
            {selectedCategory?.items?.length ? (
              <MegaNavColumn
                title={selectedCategory?.title}
                items={selectedCategory?.items}
                titleUrl={selectedCategory?.url}
                onSelected={handleSubcategorySelect}
                selectedItem={selectedSubcategory}
                data-testid="mega-nav-category-column"
                showHomeSuffix={true}
              />
            ) : (
              seasonal && <SeasonalLinksComponent seasonal={seasonal} />
            )}
          </div>
          <div className="mega-nav--column-wrapper" id="mega-nav--column-3">
            {selectedSubcategory?.items ? (
              <MegaNavColumn
                title={selectedSubcategory.title}
                items={selectedSubcategory?.items}
                onSelected={onClose}
                titleUrl={selectedSubcategory.url}
                data-testid="mega-nav-subcategory-column"
              />
            ) : (
              !!ads?.length && <MegaNavContentComponent ads={ads} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
