import React from "react";
import { miniTrolleyEmptyTrolleyAnalyticsEvent, sendAnalyticsEvent } from "../../../common/dataLayer/analyticsEvent";
import { BasicModal } from "./Modal.component";
import { useDispatch } from "react-redux";
import { deleteBasket } from "../../../domain/basket/basket.actions";
import { usePageView } from "../../../common/hooks/usePageViewEvent";

export interface EmptyTrolleyModalProps {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
}

export const EmptyTrolleyModal = ({ isOpen, setIsOpen }: EmptyTrolleyModalProps) => {
  const dispatch = useDispatch();
  const { sendPageEvent } = usePageView("groceries", "trolley", undefined, true, "emptyTrolleyLightbox");

  if (isOpen) {
    sendPageEvent(["empty trolley lightbox"]);
  }

  return (
    <BasicModal
      title="Are you sure you want to empty your trolley?"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      primaryButtonText="Empty trolley"
      onPrimaryButtonClick={() => {
        dispatch(deleteBasket());
        sendAnalyticsEvent(miniTrolleyEmptyTrolleyAnalyticsEvent);
        setIsOpen(false);
      }}
      secondaryButtonText="Go Back"
      className="modal-width-500"
    >
      <p>If you empty your trolley, all items will be removed. This cannot be undone.</p>
    </BasicModal>
  );
};
