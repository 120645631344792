import { DataState } from "../../common/dataState";
import { addItemsToBasket } from "../../services/basket";
import { capitalise } from "../../common/utils";
import { RecipeSwapItem } from "../RecipesSwapModal/RecipesSwapModal.types";
import {
  RecipeProductCommon,
  RecipeRetailPrice,
  RecipesAddIngredientsPriority,
  RecipeUnitPrice,
} from "./ATBModal.type";
import { PromoMechanicIds } from "../../common/types";
import { getPickTimeAndStoreNum, getSlotBooked } from "../../domain/basket/basket.helpers";
import {
  basketBulkOrderLimitExceededErrorActionCreator,
  basketItemQuantityExceededErrorActionCreator,
  basketMaximumLimitExceededErrorActionCreator,
  basketScottishLegislationErrorActionCreator,
} from "../../domain/basket/basket.actions";
import {
  BasketItemAdd,
  BasketItemQuantityExceededError,
  BulkOrderLimitExceededError,
  MaximumBasketLimitExceededError,
  ScottishLegislationRestrictionError,
  ScottishLegislationRestrictionRebookDeliveryError,
} from "../../services/basket.types";
import { ProductType } from "../../services/product.types";
import { ServiceError } from "../../common/http/request";
import { createInternalNavigationAction } from "../../common/middleware/navigationMiddleware";
import { urls } from "../../routes";
import { OfferType } from "../../common/dataLayer/types";
import { RecipeProductSet } from "./RecipeProductSet";
import { Recipe } from "../../views/Recipes/Recipe.types";

// Interfaces
interface analyticsDataType {
  sku: string;
  name: string;
  addToCartQuantity: number;
  addToCartRecipeUnitPrice: string | null;
  addToCartPrice: string | null;
  offerType: string | null;
  originalRecipeUnitPrice: string | null;
  originalPrice: string | null;
  unitPriceSaving: string | null;
  priceSaving: string | null;
}

// Utils
export const getTopLabel = (amount_string: string, unit: string, recipe_line: string): string => {
  let unit_label;
  const amount = amount_string.includes(".") ? parseFloat(amount_string) : parseInt(amount_string);
  if (unit === "pcs") {
    unit_label = "";
  } else if (unit.length >= 3) {
    if (unit.endsWith("s")) {
      unit = unit.slice(0, -1);
    }
    unit_label = " " + unit + (amount !== 1 ? "s" : "");
  } else {
    unit_label = unit;
  }
  const recipe_detail = recipe_line.split(",")[0];
  return `${amount.toString()}${unit_label} ${capitalise(recipe_detail)}`;
};

// Const
export const DEFAULT_RECIPE_PRIORITY = RecipesAddIngredientsPriority.PRICE;

// Convert an object returned from the swap endpoint to
// a recipe product common instance (inherit missing attributes from
// the product it is being swapped for)
export const convertSwapToCommon = (e: RecipeSwapItem, common: RecipeProductCommon): RecipeProductCommon => {
  return {
    ...common,
    ...e,
  };
};

export function isAvailable(ingredient: RecipeProductCommon): boolean {
  if (ingredient.product_type === ProductType.CATCHWEIGHT) {
    const weight = ingredient.catchweight;
    const hasValidCatchweight = !!(
      weight?.length && weight.every(w => !!w.unit_price?.price && !!w.retail_price?.price)
    );
    return !!(hasValidCatchweight && ingredient.product_id);
  } else {
    return !!(ingredient.unit_price?.price && ingredient.retail_price?.price && ingredient.product_id);
  }
}
// Logic for getting an item, including possibly catchweighted ones
export function getRecipeProductPrice(product: RecipeProductCommon): {
  retail_price: RecipeRetailPrice;
  unit_price: RecipeUnitPrice;
  range?: string | undefined;
} {
  if (product.product_type === ProductType.CATCHWEIGHT) {
    const selection = (product.catchweight || [])[0];
    return {
      retail_price: selection?.retail_price,
      unit_price: selection?.unit_price,
      range: selection.range,
    };
  } else {
    return {
      retail_price: product.retail_price as RecipeRetailPrice,
      unit_price: product.unit_price as RecipeUnitPrice,
    };
  }
}

function getUom(
  retail_price: RecipeProductCommon["retail_price"],
  product_type: RecipeProductCommon["product_type"]
): string {
  switch (product_type) {
    case ProductType.LOOSE:
      return "C62";
    case ProductType.BASIC:
    default:
      return retail_price?.measure || "ea";
  }
}

function handleClientError(error: ServiceError | undefined) {
  switch (error?.code) {
    case ScottishLegislationRestrictionError:
    case ScottishLegislationRestrictionRebookDeliveryError:
      return basketScottishLegislationErrorActionCreator(error.title, error.detail);
    case BulkOrderLimitExceededError:
      return basketBulkOrderLimitExceededErrorActionCreator(error.title, error.detail);
    case BasketItemQuantityExceededError:
      return basketItemQuantityExceededErrorActionCreator(error.title, error.detail);
    case MaximumBasketLimitExceededError:
      return basketMaximumLimitExceededErrorActionCreator({
        title: error.title,
        detail: error.detail,
        url: error.meta.information_url,
      });
    default:
      return;
  }
}
// Handler for setting the flow of making a basket add request
export async function addBasketRequest(
  productSet: RecipeProductSet,
  setState: React.Dispatch<React.SetStateAction<DataState>>
): Promise<void> {
  setState(DataState.PENDING);
  const items: BasketItemAdd[] = productSet
    // Remove any unavailable ingredients, then look up if they are selected and in what quantity
    .getAvailable()
    .filter(item => item.selected)
    .map(item => {
      return {
        product_uid: item.product_id,
        quantity: item.quantity,
        uom: getUom(item.retail_price, item.product_type),
        selected_catchweight: getRecipeProductPrice(item).range,
      } as BasketItemAdd;
    });

  const { pickTime, storeNum } = getPickTimeAndStoreNum();
  const slotBooked = getSlotBooked();

  const result = await addItemsToBasket(items, false, pickTime, storeNum, slotBooked);

  if (result.isSuccess()) {
    setState(DataState.SUCCESS);
    return Promise.resolve();
  } else if (result.isUnauthorised()) {
    return Promise.reject(createInternalNavigationAction(urls.LOGON_VIEW_IDENTIFIER));
  }

  const action = handleClientError(result.errors.first());
  if (action) {
    setState(DataState.UNKNOWN);
  } else {
    setState(DataState.FAILED);
  }

  return Promise.reject(action);
}

// Filter out malformed ingredients

// Parse serving sizes
function isValidServing(serving: number): boolean {
  return !!serving && serving > 0;
}

export function parseServings(serving: string | undefined): { val: string; ok: boolean } {
  // Declare a default value in case serving == null
  const def = "4";
  const servingOrDefault = serving ?? def;

  // Handle ranges
  if (servingOrDefault.includes("-")) {
    // If servings is a range like 5-6, return the higher
    const [lower, upper] = servingOrDefault.split("-").map(e => parseInt(e));
    if (isValidServing(upper) && isValidServing(lower)) {
      return { val: upper.toString(), ok: true };
    } else {
      // Otherwise return the default (ie if a negative number is given)
      return { val: def, ok: false };
    }
  }

  // Handle normal numbers
  const parsed = parseInt(servingOrDefault);
  if (isValidServing(parsed)) {
    return { val: parsed.toString(), ok: !!serving };
  } else {
    return { val: def, ok: false };
  }
}

// Get accurate prices for users without nectar
export function getAccuratePrice(
  productData: RecipeProductCommon,
  userHasNectar: boolean
): { price: number; original_price: number } {
  const { retail_price } = getRecipeProductPrice(productData);
  const isCatchweight = productData.product_type === ProductType.CATCHWEIGHT;

  // No promos, same for catchweight and non-catchweight
  if (productData.your_nectar_price?.without_nectar_price) {
    return {
      price: retail_price.price,
      original_price: productData.your_nectar_price.without_nectar_price,
    };
  } else if (!productData.promotions?.length) {
    return {
      price: retail_price.price,
      original_price: retail_price.was_price || retail_price.price,
    };
    // Nectar promo without nectar card
  } else if (productData.promotions[0].promo_mechanic_id === PromoMechanicIds.NECTAR_PROMOTION && !userHasNectar) {
    // catchweight products have original promo price in retail price
    if (isCatchweight) {
      return {
        price: retail_price.was_price as number,
        original_price: retail_price.was_price as number,
      };
      // non-catchweight products can use the promo original price
    } else {
      return {
        price: productData.promotions[0].original_price,
        original_price: productData.promotions[0].original_price,
      };
    }
    // any other promos follow a similar pattern
  } else {
    return {
      price: retail_price.price,
      original_price: (productData.promotions || [])[0]?.original_price || retail_price?.price || 0,
    };
  }
}

// Tracking function for adding to basket
export const addToBasketTracking = (productSet: RecipeProductSet, userHasNectar: boolean) => {
  const analyticsData: analyticsDataType[] = productSet
    .getAvailable()
    .filter(e => e.selected)
    .map(e => {
      const quantity = e.quantity;
      const promos = e.promotions || [];
      const isNectarPrice = promos[0]?.promo_mechanic_id === PromoMechanicIds.NECTAR_PROMOTION;
      const { price, original_price } = getAccuratePrice(e, userHasNectar);

      let offer;

      if (!(promos || []).length || (isNectarPrice && !userHasNectar)) {
        // There are no promotions, or there are nectar promotions without nectar card
        offer = OfferType.ORIGINAL_PRICE;
      } else if (isNectarPrice) {
        // There is a nectar promotion which will be applied
        offer = OfferType.NECTAR_PRICE;
      } else {
        // There is a TPR promotion
        offer = OfferType.TYPICAL_PRICE_REDUCTION;
      }

      return {
        sku: e.product_id,
        name: e.product_name,
        addToCartQuantity: quantity,
        addToCartRecipeUnitPrice: price.toFixed(2),
        addToCartPrice: (price * quantity).toFixed(2),
        offerType: offer,
        originalRecipeUnitPrice: original_price.toFixed(2),
        originalPrice: (original_price * quantity).toFixed(2),
        unitPriceSaving: (original_price - price).toFixed(2),
        priceSaving: (original_price * quantity - price * quantity).toFixed(2),
      };
    });

  (window as any).digitalData?.track &&
    (window as any).digitalData.track("ev_addToCart", {
      hit_type: "link",
      data_product_id: analyticsData.map(e => e.sku),
      data_product_addToCartQuantity: analyticsData.map(e => e.addToCartQuantity),
      data_product_addToCartRecipeUnitPrice: analyticsData.map(e => e.addToCartRecipeUnitPrice),
      data_product_addToCartPrice: analyticsData.map(e => e.addToCartPrice),
      data_product_offerType: analyticsData.map(e => e.offerType),
      data_product_originalRecipeUnitPrice: analyticsData.map(e => e.originalRecipeUnitPrice),
      data_product_originalPrice: analyticsData.map(e => e.originalPrice),
      data_product_unitPriceSaving: analyticsData.map(e => e.unitPriceSaving),
      data_product_priceSaving: analyticsData.map(e => e.priceSaving),
      data_product_quantityType: analyticsData.map(() => "unit"),
      data_shoppingMode: "primary",
      product_name: analyticsData.map(e => e.name),
      product_id: analyticsData.map(e => e.sku),
      product_quantity: analyticsData.map(e => e.addToCartQuantity),
      product_price: analyticsData.map(e => e.addToCartPrice),
    });
};

function merge<T, U>(mainLeft: T[], mainRight: T[], otherLeft: U[], otherRight: U[]): [T[], U[]] {
  // merges two sets of two sorted arrays, using the first set as the comparators
  let [lRef, rRef] = [0, 0];
  let mainArr: T[] = [];
  let otherArr: U[] = [];
  while (lRef < mainLeft.length && rRef < mainRight.length) {
    if (mainLeft[lRef][0] < mainRight[rRef][0]) {
      mainArr.push(mainLeft[lRef]);
      otherArr.push(otherLeft[lRef]);
      lRef++;
    } else {
      mainArr.push(mainRight[rRef]);
      otherArr.push(otherRight[rRef]);
      rRef++;
    }
  }
  if (lRef < mainLeft.length) {
    mainArr = mainArr.concat(mainLeft.slice(lRef));
    otherArr = otherArr.concat(otherLeft.slice(lRef));
  } else {
    mainArr = mainArr.concat(mainRight.slice(rRef));
    otherArr = otherArr.concat(otherRight.slice(rRef));
  }
  return [mainArr, otherArr];
}

export function mergeSortZipped<T, U>(mainArr: T[], otherArr: U[]): [T[], U[]] {
  if (mainArr.length > 1) {
    const div = Math.floor(mainArr.length / 2);
    const [left, otherLeft] = mergeSortZipped(mainArr.slice(0, div), otherArr.slice(0, div));
    const [right, otherRight] = mergeSortZipped(mainArr.slice(div), otherArr.slice(div));
    return merge(left, right, otherLeft, otherRight);
  }
  return [mainArr, otherArr];
}

export function getMealPlannerSharedProducts(sharedProducts: RecipeProductSet, recipes: Recipe[]) {
  const sharedIngredients = sharedProducts?.items.map(product => {
    const sharedRecipes = product.recipe_ids.map(id => {
      const found = recipes.find((recipe: any) => recipe.id === id)?.name;
      return found;
    });
    return { ...product, sharedRecipes: sharedRecipes.length > 1 ? sharedRecipes.join(", ") : "" };
  });
  return new RecipeProductSet(sharedIngredients);
}
