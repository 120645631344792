import { sendAnalyticsEvent } from "../../../common/dataLayer/analyticsEvent";
import { Controls } from "../../../services/product.types";
import { FilterProduct, FilterProductType } from "../../../domain/product/product.types";
import { replacementLabels } from "../../SearchResultsFindability/SearchResultsFindability.utils";
import { BrowsePageType } from "../Browse.types";
import { sendOnClick } from "../../../services/content";

export const sendCMSClickAnalytics = (event: any, isZoneCitrusGroup: boolean) => {
  // Sets a flag to whether or not send default magnolia tracking based on success of citrus tracking
  let shouldTriggerMagnoliaTracking = true;
  const document: Document = event.currentTarget;
  try {
    const cmsClickedComponent: HTMLElement | null = document.querySelector(
      "[data-name='ev_cmsComponentClick'], [data-action='Click']"
    );

    if (cmsClickedComponent) {
      const { label, destination, parent, action, test } = cmsClickedComponent.dataset;
      if (destination && isZoneCitrusGroup) {
        const url = new URL(destination, "https://sainsburys.co.uk");
        const adId = url?.searchParams.get("adId") ?? null;
        const name = url?.searchParams.get("name") ?? undefined;

        if (adId && destination) {
          event.preventDefault();
          shouldTriggerMagnoliaTracking = false;
          sendOnClick(adId, "Zone_1", name).finally(() => {
            window.top?.location.assign(destination);
          });
        }
        /**
         * For the above scenario the slot ID has been harcoded.
         * This is because currently only zone pages show citrus
         * ads in above grid section. Feel free to modify this as
         * we progress further with different approach for Browse
         */
      }
      const isAccordion = test && test.includes("m054");
      (window as any).digitalData.event = [];
      shouldTriggerMagnoliaTracking &&
        sendAnalyticsEvent({
          eventName: "ev_cmsComponentClick",
          eventCategory: "CMS Component",
          eventAction: isAccordion ? action : "Click",
          eventLabel: label,
          ...(!isAccordion && { linkDestination: destination }),
          linkParentHeader: parent,
        });
      // Mark the data-name as tracked after tracking event sent
      (window as any).document
        .querySelector("[data-name='ev_cmsComponentClick']")
        .setAttribute("data-name", "ev_cmsComponentTracked");
      (window as any).document.querySelector("[data-action='Click']").setAttribute("data-action", "clickTracked");
    }

    const eventAction: HTMLElement | null = document.querySelector('[data-event-action="click"]');
    if (eventAction) {
      sendGenericEventTracking(eventAction);
    }
  } catch {
    // Click event could not be tracked
  }
};

const sendGenericEventTracking = (eventAction: HTMLElement) => {
  const componentClassification = eventAction.getAttribute("data-component-classification") ?? undefined;
  const eventLabel = eventAction.getAttribute("data-event-label") ?? undefined;
  const eventCategory = eventAction.getAttribute("data-event-category") ?? undefined;
  const eventActionType = eventAction.getAttribute("data-event-action") ?? undefined;

  (window as any).digitalData?.track("ev_cmsComponentClick", {
    hit_type: "link",
    ...(eventLabel ? { data_event_label: eventLabel } : {}),
    ...(eventCategory ? { data_event_category: eventCategory } : {}),
    ...(eventActionType ? { data_event_action: eventActionType } : {}),
    ...(componentClassification ? { data_component_classification: componentClassification } : {}),
  });

  // Mark as tracked
  eventAction.setAttribute("data-event-action", "clickTracked");
};

export const retrieveBrowseDigitalDataProperties = (pageType: BrowsePageType | string, controls?: Controls) => {
  let filtersData = "";
  let sortData = "";

  if (controls && controls.filters) {
    const isMultiSelectOption = (filter: FilterProduct) => filter.values.find(value => value.selected);
    const selectedOptions = (filter: FilterProduct) => filter.type === FilterProductType.MULTI_SELECT;
    const selectedMultiSelectOptions = controls.filters.filter(selectedOptions).filter(isMultiSelectOption);

    selectedMultiSelectOptions.forEach(filterProduct => {
      filterProduct.values.forEach(filterItem => {
        if (filterItem.selected) {
          if (filtersData.includes(replacementLabels[filterProduct.key])) {
            filtersData += "|" + filterItem.label;
          } else {
            if (filtersData && filtersData !== "") {
              filtersData += ",";
            }

            filtersData += replacementLabels[filterProduct.key] + ":" + filterItem.label;
          }
        }
      });
    });

    const selectedSortOption = controls.sort.options.find(option => option.value === controls.sort.active);

    if (selectedSortOption) {
      sortData = selectedSortOption.display;
      if (sortData.includes(" - ")) {
        sortData = sortData.replace(" - ", ":");
      }
    }
  }

  const obj: any = {
    data_page_number: controls?.page.active,
    data_page_type: pageType.toLowerCase(),
    data_productList_filters: filtersData.toLowerCase(),
    data_productList_numberOfProducts: controls?.total_record_count,
    data_productList_numberOfProductsShown: controls?.returned_record_count,
    data_sortBy: sortData?.toLowerCase(),
  };

  return obj;
};
