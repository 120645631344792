import { Dispatch } from "redux";
import { mapProduct, mapProducts } from "../../domain/product/product";
import {
  FetchProductDetailsError,
  FetchProductDetailsNotFoundError,
  FetchProductDetailsPending,
  FetchProductDetailsSuccess,
  ProductDetailsAction,
  SetSelectedVariant,
} from "./productDetails.types";
import { ProductDetailsPageViewAction } from "../../common/analytics/analytics";
import { AdobeDigitalDataProperties, AnalyticsActionTypes } from "../../common/analytics/types";
import { Result } from "../../common/http/request";
import { Search } from "history";
import { AdMetaData, createAdImpressionAction, createAdsTrackingDataAction } from "../../common/ads";
import { AppThunkAction } from "../../common/types";
import { Products, ProductsBySeoUrlResponse, ProductServiceError } from "../../services/product.types";
import { fetchProduct, fetchProductBySeoUrl, fetchProductsByWCSCatEntryIds } from "../../services/product";
import { AssociationType, ProductAd, ProductSource, ProductsWithAds } from "../../domain/product/product.types";
import { Basket } from "../../domain/basket/basket.types";
import { AdobePageName, AdobePageType, generateAdobeAnalyticsPageProperties } from "../../utils";
import { getCachedSlotReservation } from "../../domain/slot/slotReservation";

export const setSelectedVariant = (variantUid: string): SetSelectedVariant => ({
  type: ProductDetailsAction.SET_SELECTED_VARIANT,
  variantUid,
});

export const fetchProductDetailsPending = (): FetchProductDetailsPending => ({
  type: ProductDetailsAction.FETCH_PRODUCT_DETAILS_PENDING,
});

export const fetchProductDetailsSuccess = (
  productsWithAds: ProductsWithAds,
  errors?: ProductServiceError[]
): FetchProductDetailsSuccess => ({
  type: ProductDetailsAction.FETCH_PRODUCT_DETAILS_SUCCESS,
  productsWithAds,
  errors,
});

export const fetchProductDetailsError = (): FetchProductDetailsError => ({
  type: ProductDetailsAction.FETCH_PRODUCT_DETAILS_ERROR,
});

export const fetchProductDetailsNotFoundError = (): FetchProductDetailsNotFoundError => ({
  type: ProductDetailsAction.FETCH_PRODUCT_DETAILS_NOT_FOUND_ERROR,
});

export const productDetailsPageViewEvent = (
  seoUrl: string,
  topLevelCategoryLabel: string,
  brand: string,
  productId: string,
  productImageCount: number,
  productVideoCount: number,
  available: boolean,
  reviewScore: number,
  reviewsNumber: number,
  productPrice: number,
  productName: string,
  adobeProperties: AdobeDigitalDataProperties
): ProductDetailsPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: `groceries:product:${seoUrl}`,
    template: "productdetails",
    newTemplate: "pdp",
    section: topLevelCategoryLabel,
    brand,
    productId,
    productImageCount,
    productVideoCount,
    available,
    reviewScore,
    reviewsNumber,
    productPrice,
    productName,
  },
  adobeProperties,
});

export const fetchProductDetailsThunkBySeoURL =
  (seoUrl: string, searchParams: Search, basket: Basket): AppThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    const { storeIdentifier, slotDate, slotDateTime, reservationType } = getCachedSlotReservation() || {};
    const result = await fetchProductBySeoUrl({
      seoUrl,
      store_identifier: storeIdentifier,
      slot_date: slotDate,
      slot_datetime: slotDateTime,
      slot_type: reservationType,
      is_slot_locked: basket.isSlotLocked,
    });

    if (!result.isSuccess()) {
      if (result.errors.hasCode("INVALID_PRODUCT_SEO_URL") || result.errors.hasCode("PRODUCT_NOT_FOUND")) {
        dispatch(fetchProductDetailsNotFoundError());
      } else {
        dispatch(fetchProductDetailsError());
      }
      return;
    }

    fetchProductSuccess(result, searchParams, dispatch, basket);
  };

export const fetchProductDetailsThunkByWCSCatEntryId =
  (wcsCatEntryId: string, searchParams: Search, basket: Basket): AppThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    const { storeIdentifier, slotDate, slotDateTime, reservationType } = getCachedSlotReservation() || {};
    const result = await fetchProductsByWCSCatEntryIds(
      [wcsCatEntryId],
      ["ASSOCIATIONS", "DIETARY_PROFILE"],
      true,
      false,
      storeIdentifier,
      slotDate,
      slotDateTime,
      reservationType,
      basket.isSlotLocked
    );

    if (!result.isSuccess()) {
      dispatch(fetchProductDetailsError());
      return;
    }
    fetchProductSuccess(result, searchParams, dispatch, basket);
  };

export const fetchProductDetailsThunkByProductId =
  (productUid: string, searchParams: Search, basket: Basket): AppThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    const { storeIdentifier, slotDate, slotDateTime, reservationType } = getCachedSlotReservation() || {};
    const result = await fetchProduct(productUid, {
      store_identifier: storeIdentifier,
      slot_date: slotDate,
      slot_datetime: slotDateTime,
      slot_type: reservationType,
      is_slot_locked: basket.isSlotLocked,
    });
    const productResponse = new Result(result.errors, result.response, { products: [result.data] });

    if (!result.isSuccess()) {
      dispatch(fetchProductDetailsError());
      return;
    }

    fetchProductSuccess(productResponse, searchParams, dispatch, basket);
  };

function fetchProductSuccess(result: Result<Products>, searchParams: Search, dispatch: Dispatch, basket: Basket) {
  if (result.data.products.length === 0) {
    dispatch(fetchProductDetailsNotFoundError());
    return;
  }

  const products = mapProductsResponse(ProductSource.PRODUCT_DETAILS, result.data);

  dispatch(fetchProductDetailsSuccess(products, result.data.errors));

  if (products.ads && products.ads.sponsoredProducts.productAds.length > 0) {
    const productAd = products.ads.sponsoredProducts.productAds[0];
    dispatch(createAdImpressionAction(productAd.id));

    const productUid = products.ads.sponsoredProducts.productAds[0].product.productUid;
    const AdData: AdMetaData[] = [{ id: productAd.id, type: productAd.type, productUid }];
    dispatch(createAdsTrackingDataAction(AdData));
  }

  const product = products.products[0];
  const breadcrumbs = product.breadcrumbs;
  const productId = product.productUid;
  const categoryLabel = breadcrumbs.length > 0 ? breadcrumbs[0].label : "";
  const productImageCount = product.assets.images.length;
  const productVideoCount = product.assets.video.length;
  const available = product.available;
  const productName = product.name;
  const reviewScore = product.reviews.averageRating;
  const reviewsNumber = product.reviews.numberOfReviews;
  const productPrice = product.retailPrice?.price ?? 0;

  const adobeProperties = generateAdobeAnalyticsPageProperties({
    basket,
    pageName: AdobePageName.PRODUCT,
    pageType: AdobePageType.GROCERIES,
    siteSection: "product detail",
    extraPageData: [productId],
  });

  dispatch(
    productDetailsPageViewEvent(
      productName,
      categoryLabel,
      product.attributes && product.attributes.brand ? product.attributes.brand[0] : "",
      productId,
      productImageCount,
      productVideoCount,
      available,
      reviewScore,
      reviewsNumber,
      productPrice,
      productName,
      adobeProperties
    )
  );
}

export const mapProductsResponse = (source: ProductSource, products: ProductsBySeoUrlResponse): ProductsWithAds => {
  const withAds: ProductsWithAds = {
    products: mapProducts(source, products.products),
  };

  if (products.ads) {
    withAds.ads = {
      sponsoredProducts: {
        productAds: products.ads.sponsored_products.product_ads
          .filter(ad => ad.ad_id)
          .map(
            ad =>
              ({
                id: ad.ad_id,
                type: ad.type,
                product: mapProduct(ad.product, 0, ProductSource.CITRUS_XSELL_AD_PDP, AssociationType.CITRUS_CROSSELL),
              } as ProductAd)
          ),
      },
    };
  }

  return withAds;
};
