import { AnyAction } from "redux";
import { HeaderState } from "../../../common/store";
import { HeaderActionTypes } from "./header.actions";
import { LOCATION_CHANGE } from "connected-react-router";

export const initialState: HeaderState = {
  visible: true,
  isMegaNavOpen: false,
  showMinimizedHeader: false,
  selectedMeganavCategory: null,
};

export function headerReducer(state: HeaderState | undefined = initialState, action: AnyAction): HeaderState {
  switch (action.type) {
    case HeaderActionTypes.SHOW_HEADER:
      return { ...state, visible: true };
    case HeaderActionTypes.HIDE_HEADER:
      if (state.isMegaNavOpen) return { ...state };
      return { ...state, visible: false };
    case HeaderActionTypes.SHOW_MINIMIZED_HEADER:
      return { ...state, showMinimizedHeader: true };
    case HeaderActionTypes.HIDE_MINIMIZED_HEADER:
      return { ...state, showMinimizedHeader: false };
    case HeaderActionTypes.SHOW_MEGANAV:
      return { ...state, isMegaNavOpen: true };
    case HeaderActionTypes.SET_SELECTED_CATEGORY:
      return { ...state, selectedMeganavCategory: action.selectedMeganavCategory };
    case LOCATION_CHANGE:
    case HeaderActionTypes.HIDE_MEGANAV:
      return { ...state, isMegaNavOpen: false };
    default:
      return state;
  }
}
