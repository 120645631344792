import React from "react";
import { Sainsburys } from "@jsluna/images";
import { Logo } from "./Logo";

export const SainsburysLogo = ({ className }: { className?: string }) => {
  const classNames = ["sainsburys-logo"];
  if (className) {
    classNames.push(className);
  }
  return (
    <Logo className={classNames.join(" ")}>
      <Sainsburys className="logo-image" />
    </Logo>
  );
};

SainsburysLogo.displayName = "SainsburysLogo";
