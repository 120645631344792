import { matchPath } from "react-router";
import { routes } from "../../../routes";
import { DisabledTourPageTypes, TourPageTypes } from "../FeatureTour.types";

type TourRoutes<T> = {
  [key in T as `${Uppercase<string & key>}`]: string;
};

type EnabledTourRoutes = TourRoutes<TourPageTypes>;
type DisabledTourRoutes = TourRoutes<DisabledTourPageTypes>;
type FavouritesRoutes = EnabledTourRoutes & DisabledTourRoutes;

// Only lists routes that display a feature tour tooltip on the desktop navigation link 'Favourites'
export const tourDesktopRoutes: Omit<EnabledTourRoutes, "FAVOURITES" | "BEFORE_YOU_GO" | "FORGOTTEN_FAVOURITES"> = {
  OFFERS: routes.OFFERS,
  OFFERS_ZONE_PAGE: routes.OFFERS_ZONE_PAGE,
  SEARCH_RESULTS_FINDABILITY: routes.SEARCH_RESULTS_FINDABILITY,
  SEARCH_RESULTS_FINDABILITY_WITH_CATEGORY: routes.SEARCH_RESULTS_FINDABILITY_WITH_CATEGORIES,
  NECTAR_DESTINATION: routes.NECTAR_DESTINATION,
  PRODUCT_DETAILS: routes.PRODUCT_DETAILS,
  BROWSE: routes.BROWSE,
  BROWSE_FEATURED: routes.BROWSE_FEATURED,
  BROWSE_ZONE: "/(shop|gol-ui|webapp/wcs/stores/servlet)/groceries/:zoneSlug/:browseIdSlug",
  BROWSE_DEPARTMENT: "/(shop|gol-ui|webapp/wcs/stores/servlet)/groceries/:zoneSlug/:departmentSlug/:browseIdSlug",
  BROWSE_AISLE: "/(shop|gol-ui|webapp/wcs/stores/servlet)/groceries/:zoneSlug/:departmentSlug/:aisleSlug/:browseIdSlug",
  BROWSE_SHELF:
    "/(shop|gol-ui|webapp/wcs/stores/servlet)/groceries/:zoneSlug/:departmentSlug/:aisleSlug/:shelfSlug/:browseIdSlug",
};

// Routes that are disabled for the feature tour
export const disabledTourRoutes: DisabledTourRoutes = {
  BEFORE_YOU_GO: routes.CHECKOUT_BEFORE_YOU_GO,
  FORGOTTEN_FAVOURITES: routes.CHECKOUT_FORGOTTEN_FAVOURITES,
  PREVIOUS_ORDERS: routes.PREVIOUS_ORDERS,
  BRAND_SHELF: routes.BRAND_SHELF,
};

const patterns = [/^\/gol-ui\/meal-deal/, /^\/gol-ui\/events/, /^\/gol-ui\/features/, /^\/gol-ui\/groceries/];

// All routes enabled for add to favourites
export const favouritesRoutes: FavouritesRoutes = {
  ...disabledTourRoutes,
  ...tourDesktopRoutes,
  FAVOURITES: routes.FAVOURITES,
};

// Routes used in unit testing
export const testRoutes: FavouritesRoutes = {
  SEARCH_RESULTS_FINDABILITY_WITH_CATEGORY: "/gol-ui/SearchResults/grapes/category:812510/sort:",
  SEARCH_RESULTS_FINDABILITY: "/gol-ui/SearchResults/grapes",
  OFFERS: "/gol-ui/offers",
  OFFERS_ZONE_PAGE: "/gol-ui/offers/meat-fish",
  FAVOURITES: "/gol-ui/favourites-as-list",
  NECTAR_DESTINATION: "/gol-ui/nectar",
  BEFORE_YOU_GO: "/gol-ui/checkout/before-you-go",
  FORGOTTEN_FAVOURITES: "/gol-ui/checkout/forgotten-favourites",
  PREVIOUS_ORDERS: "/gol-ui/PreviousOrders",
  PRODUCT_DETAILS: "/gol-ui/Product/sainsburys-somerset-goats-cheese-100g",
  BROWSE: "/gol-ui/groceries",
  BROWSE_FEATURED: "/gol-ui/featured/groceries/food-cupboard/c:1019883",
  BROWSE_ZONE: "/gol-ui/groceries/dairy-eggs-and-chilled/c:858119",
  BROWSE_DEPARTMENT: "/gol-ui/groceries/dairy-eggs-and-chilled/dairy-and-eggs/c:858010",
  BROWSE_AISLE: "/gol-ui/groceries/dairy-eggs-and-chilled/dairy-and-eggs/milk/c:857994",
  BROWSE_SHELF: "/gol-ui/groceries/dairy-eggs-and-chilled/dairy-and-eggs/milk/fresh-milk/c:857991",
  BRAND_SHELF: "/gol-ui/brands/gold",
};

export const isBYGPage = (path: string): boolean => {
  return path?.match(routes.CHECKOUT_BEFORE_YOU_GO) ? true : false;
};

export const isForgottenFavouritesPage = (path: string): boolean => {
  return path?.match(routes.CHECKOUT_FORGOTTEN_FAVOURITES) ? true : false;
};

export const isFavouritesPage = (path: string): boolean => {
  return path?.match(routes.FAVOURITES) ? true : false;
};

export const isPreviousOrders = (path: string): boolean => {
  return path?.match(routes.PREVIOUS_ORDERS) ? true : false;
};

export const isFavouriteIconRoute = (path: string): boolean => {
  // Check if the path matches any of the specified patterns
  if (patterns.some(pattern => pattern.test(path))) {
    return true;
  }

  return (
    matchPath(path, {
      path: Object.values(favouritesRoutes),
      exact: true,
    })?.isExact || false
  );
};

export const isDesktopTourRoute = (path: string): boolean => {
  return (
    matchPath(path, {
      path: Object.values(tourDesktopRoutes),
      exact: true,
    })?.isExact || false
  );
};
