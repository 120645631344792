import qs from "qs";
import { ProductQueryParameters } from "../../services/product.types";
import { Filter, ProductListControlsQueryParams } from "../../domain/product/product.types";

type QueryParams = { [key: string]: any };

const clearCategoriesIfAll = (filters: { [key: string]: string }): Object => {
  if (filters.cat_Category === "all") {
    return {
      ...filters,
      cat_Category: undefined,
      subcat_SubCategory: undefined,
    };
  }
  return filters;
};

export const mergeQueryParams = (
  queryString: string,
  incomingQueryParams: ProductListControlsQueryParams,
  extraParams?: { [key: string]: string }
): string => {
  const currentQueryParams = qs.parse(queryString, { ignoreQueryPrefix: true, decoder: c => c });

  const futureQueryParams: QueryParams = {
    ...currentQueryParams,
    ...incomingQueryParams,
    ...extraParams,
  };

  if (incomingQueryParams.filters) {
    futureQueryParams.pageNumber = 1;
    futureQueryParams.filters = clearCategoriesIfAll(
      incomingQueryParams.filters.reduce((acc, curr) => mergeFilters(acc, curr), currentQueryParams.filters)
    );
  }

  const alphabeticalSort = (a: string, b: string) => a.localeCompare(b);
  return qs.stringify(futureQueryParams, { sort: alphabeticalSort, encode: false });
};

export const extractQueryParams = (queryString: string): ProductQueryParameters => {
  if (!queryString) {
    return {};
  }

  const newQueryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
  const queryParams: ProductQueryParameters = {};
  const favouritesControlsQueryParams: string[] = [
    "pageNumber",
    "pageSize",
    "sort",
    "filters",
    "storeIdentifier",
    "uid",
  ];
  const favouritesControlsQueryParamsToNumber: string[] = ["pageNumber", "pageSize"];

  favouritesControlsQueryParams.forEach(k => {
    if (newQueryParams[`${k}`] && favouritesControlsQueryParamsToNumber.indexOf(k) !== -1) {
      const maybeNumber = Number(newQueryParams[`${k}`]);
      queryParams[`${k}`] = maybeNumber ? maybeNumber : 0;
    } else if (newQueryParams[`${k}`]) {
      queryParams[`${k}`] = newQueryParams[`${k}`];
    }
  });

  return queryParams;
};

export const destructureFilters = (filters: Filter): Filter[] => {
  const output: Filter[] = [];
  Object.keys(filters).forEach((key: any) => {
    const array = filters[`${key}`].split(",");
    array.forEach((value: string) => {
      output.push({
        key,
        value,
        enabled: true,
      });
    });
  });
  return output;
};

const mergeFilters = (filterExisting: Filter, filterNew: Filter): QueryParams => {
  const filterList: Filter[] = filterExisting ? destructureFilters(filterExisting) : [];

  const indexToReplace = filterList.findIndex(
    filter => filter.key === filterNew.key && filter.value === filterNew.value
  );

  if (indexToReplace > -1) {
    filterList[`${indexToReplace}`] = filterNew;
  } else {
    filterList.push(filterNew);
  }

  return filterList
    .filter(filter => filter.enabled)
    .reduce((acc: QueryParams, current: Filter): QueryParams => {
      acc[current.key] = acc[current.key] ? `${acc[current.key]},${current.value}` : current.value;
      return acc;
    }, {} as QueryParams);
};
