import { combineReducers } from "redux";
import { DataState } from "../../common/dataState";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import { ProductListerActionType, ProductListerState } from "./ProductLister.types";

const dataState = createDataStateReducer({
  [ProductListerActionType.PRODUCTS_REQUEST]: DataState.PENDING,
  [ProductListerActionType.PRODUCTS_SUCCESS]: DataState.SUCCESS,
  [ProductListerActionType.PRODUCTS_FAILURE]: DataState.FAILED,
});

export const productListerReducer = combineReducers({
  dataState,
});

export const initialState: ProductListerState = {
  dataState: dataState(),
};
