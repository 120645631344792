import { Method, Result } from "../common/http/request";
import { serviceRequest } from "./request";
import { Optional } from "../common/types";
import {
  DeliveryPassDays,
  RecurringSlotResponse,
  ReserveSlot,
  SlotDeliveryInformation,
  SlotList,
  SlotLocationContext,
  SlotReservationResponse,
} from "./slot.types";
import GolTime from "../domain/time";
import { Basket } from "./basket.types";
import { encodeQueryParams } from "../common/http/query";

const url = "/groceries-api/gol-services/slot";

// current reservation
export const fetchSlotReservation = (baseUrl: Optional<string> = url): Promise<Result<SlotReservationResponse>> =>
  serviceRequest<SlotReservationResponse>({ method: Method.GET, url: `${baseUrl}/v1/slot/reservation` });

export const fetchSlotLocationContext = (baseUrl: Optional<string> = url): Promise<Result<SlotLocationContext>> =>
  serviceRequest<SlotLocationContext>({ method: Method.GET, url: `${baseUrl}/v1/slot/reservation/location-context` });

export const fetchSlotDeliveryInformation = (
  baseUrl: Optional<string> = url
): Promise<Result<SlotDeliveryInformation>> =>
  serviceRequest<SlotDeliveryInformation>({ method: Method.GET, url: `${baseUrl}/v1/slot/delivery-information` });

export const fetchSlotList = (
  postcode: Optional<string> = "",
  addressUid: Optional<string> = "",
  locationUid: Optional<string> = "",
  weekNumber: Optional<string> = "",
  slotType: Optional<string | Array<string>> = "",
  baseUrl: Optional<string> = url
): Promise<Result<SlotList>> =>
  serviceRequest<SlotList>({
    method: Method.GET,
    url: `${baseUrl}/v1/slot${encodeQueryParams({
      postcode,
      address_uid: addressUid,
      location_uid: locationUid,
      week_number: weekNumber,
      slot_type: slotType,
    })}`,
  });

export const fetchHomeDeliverySlots = (
  weekNumber: number,
  slotType: Optional<string | Array<string>> = "",
  baseUrl: Optional<string> = url
): Promise<Result<SlotList>> =>
  serviceRequest<SlotList>({
    method: Method.GET,
    url: `${baseUrl}/v1/slot${encodeQueryParams({
      week_number: weekNumber,
      slot_type: slotType,
    })}`,
  });

export const fetchCollectionSlots = (
  locationUid: string,
  weekNumber?: number,
  baseUrl: Optional<string> = url
): Promise<Result<SlotList>> =>
  serviceRequest<SlotList>({
    method: Method.GET,
    url: `${baseUrl}/v1/slot${encodeQueryParams({
      week_number: weekNumber,
      location_uid: locationUid,
    })}`,
  });

export const fetchSlotsListV2 = (
  weekNumber: number,
  productUids: string[],
  isSlotLocked: boolean,
  storeIdentifier: string | undefined,
  slotType: string,
  deliveryType: Optional<string | Array<string>> = undefined,
  locationUid?: string,
  baseUrl: Optional<string> = url
): Promise<Result<SlotList>> =>
  serviceRequest<SlotList>({
    headers: {
      "X-Http-Method-Override": "GET",
    },
    method: Method.POST,
    url: `${baseUrl}/v2/slots`,
    body: {
      week_number: weekNumber,
      slot_type: slotType,
      product_uids: productUids,
      is_slot_locked: isSlotLocked,
      store_identifier: storeIdentifier,
      delivery_type: deliveryType,
      location_uid: locationUid,
    },
  });

export const reserveSlot = (
  bookingKey: Optional<string> = "",
  changesConfirmed: Optional<boolean> = false,
  slotDate: Optional<string> = "",
  storeNumber: Optional<string> = "",
  baseUrl: Optional<string> = url
): Promise<Result<ReserveSlot>> =>
  serviceRequest<ReserveSlot>({
    method: Method.POST,
    url: `${baseUrl}/v1/slot/reservation`,
    body: {
      booking_key: bookingKey,
      changes_confirmed: changesConfirmed,
      new_slot_date: slotDate,
      new_store_number: storeNumber,
    },
  });

export const confirmReserveSlot = (
  bookingKey: Optional<string> = "",
  changesConfirmed: Optional<boolean> = true,
  baseUrl: Optional<string> = url
): Promise<Result<ReserveSlot>> =>
  serviceRequest<ReserveSlot>({
    method: Method.POST,
    url: `${baseUrl}/v1/slot/reservation`,
    body: {
      booking_key: bookingKey,
      changes_confirmed: changesConfirmed,
    },
  });

// parent reservation (if no parent, returns current reservation)
export const fetchSlotReservationByOrderUid = (
  orderId: Optional<string>,
  baseUrl: Optional<string> = url
): Promise<Result<SlotReservationResponse>> =>
  serviceRequest<SlotReservationResponse>({
    method: Method.GET,
    url: `${baseUrl}/v1/slot/reservation?order_uid=${orderId}`,
  });

// gets the current basket
export const fetchRecentOrderId = (
  baseUrl: Optional<string> = "/groceries-api/gol-services/basket"
): Promise<Result<Basket>> => serviceRequest<Basket>({ method: Method.GET, url: `${baseUrl}/v1/basket` });

export const optInRecurringSlot = (
  slotStartTime: GolTime,
  slotEndTime: GolTime,
  frequency: number,
  storeIdentifier: string,
  addressId: string,
  baseUrl: Optional<string> = url
): Promise<Result<void>> =>
  serviceRequest({
    method: Method.POST,
    url: `${baseUrl}/v1/slot/recurring`,
    body: {
      slot_start_time: slotStartTime.formatToServices(),
      slot_end_time: slotEndTime.formatToServices(),
      frequency,
      store_identifier: storeIdentifier,
      address_uid: addressId,
    },
  });

export const skipRecurringSlot = (baseUrl: Optional<string> = url): Promise<Result<void>> =>
  serviceRequest({ method: Method.PUT, url: `${baseUrl}/v1/slot/recurring/_skip` });

export const optOutRecurringSlot = (baseUrl: Optional<string> = url): Promise<Result<void>> =>
  serviceRequest({ method: Method.DELETE, url: `${baseUrl}/v1/slot/recurring` });

export const fetchRecurringSlot = (baseUrl: Optional<string> = url): Promise<Result<RecurringSlotResponse>> =>
  serviceRequest<RecurringSlotResponse>({ method: Method.GET, url: `${baseUrl}/v1/slot/recurring` });

export const fetchDeliveryPassDays = (
  deliveryPassId: string,
  baseUrl: Optional<string> = url
): Promise<Result<DeliveryPassDays>> =>
  serviceRequest<DeliveryPassDays>({
    method: Method.GET,
    url: `${baseUrl}/v1/slot/delivery-pass/days${encodeQueryParams({
      delivery_pass_id: deliveryPassId,
    })}`,
  });
