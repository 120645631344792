import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectBrowseAdLength, selectFavouritesAdLength } from "../../IngridAd.selectors";
import { ContentPage } from "../../../../services/contentTypes";

interface UseInGridAdLength {
  page: ContentPage | undefined;
}

export const useInGridAdLength = ({ page }: UseInGridAdLength) => {
  const adLengthSelector = useMemo(() => {
    if (page === ContentPage.FAVOURITES) {
      return selectFavouritesAdLength();
    }

    return selectBrowseAdLength();
  }, [page]);

  const ingridAdsLength = useSelector(adLengthSelector);

  return { ingridAdsLength };
};
