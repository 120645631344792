import clsx from "clsx";
import React, { ReactNode } from "react";
import { useHeaderPortal } from "../HeaderPortal/hooks/useHeaderPortal";
import { useHeaderHeight } from "../hooks/useHeaderHeight";

type HeaderWrapperProps = {
  children: ReactNode;
  className?: string;
};

export const HeaderWrapper = ({ children, className }: HeaderWrapperProps) => {
  const { headingRef } = useHeaderPortal<HTMLDivElement>();
  const { headerRef } = useHeaderHeight<HTMLDivElement>();

  return (
    <header className={clsx("header-background", className)}>
      <div data-testid="header" className={"header-wrapper"} ref={headerRef}>
        {children}
      </div>
      <div data-testid="header-portal-root" className="ds-fixed ds-top-0" ref={headingRef} />
    </header>
  );
};
