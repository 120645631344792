import { InterleavedEspot } from "../Espots/Espot.types";
import { ProductCoord } from "../../common/scrollIntoView";
import { InterleavedBannerAd, InterleavedBannerAdV2 } from "../Citrus/BannerAd/BannerAd.types";
import { ProductMessage } from "../../services/product.types";
import { UnitOfMeasure } from "../../services/basket.types";
import { BasketOrderPromise, BasketProduct, ClickSource } from "../../domain/basket/basket.types";
import { Product, ProductHeader, ProductUid, Promotion } from "../../domain/product/product.types";
import { NectarOffer } from "../../services/nectar.types";
import { FulfilmentState } from "../../common/dataLayer/types";
import { Dispatch, SetStateAction } from "react";
import { UseProductsReturnType } from "../../common/hooks/useProducts";

export interface ProductTilePrice {
  price: string;
  measure: string;
  wasPrice?: string;
}

export interface ProductOption {
  value: string;
  label: string;
  retailPrice?: ProductTilePrice;
  unitPrice?: ProductTilePrice;
}

export interface ProductTileMiniProps {
  productUid: string;
  name: string;
  image: string;
  url: string;
  subTotal: number;
  unitPrice?: number;
  promotions: Promotion[];
  showPromotionPrice: boolean;
  unitOfMeasure: UnitOfMeasure;
  nectarPrice?: number;
  nectarOffer: NectarOffer | undefined;
  totalBasePrice?: string;
  isYourNectarPrice?: boolean;
  isSupplyChainOrderable?: boolean;
  orderPromise?: BasketOrderPromise;
  serves?: string;
}

export interface ProductTileProduct {
  product: Product;
  selectedUnitOfMeasure: UnitOfMeasure;
  retailPrice?: ProductTilePrice;
  unitPrice?: ProductTilePrice;
  productOptions?: ProductOption[];
  promotions?: ProductTilePromotion[];
  relevancyRank?: number;
  productPage: ProductPage;
  isScrolledIntoView: boolean;
  productCTA?: ProductHeader;
  productHeader?: ProductHeader;
  messages?: ProductMessage[];
  canonicalUrl: string;
}

export interface ProductPriceProps {
  hasPromotions: boolean;
  isNectarPrice?: boolean;
  hasNectarAssociated?: boolean;
  retailPrice?: ProductTilePrice;
  unitPrice?: ProductTilePrice;
  currencyOriginalPrice?: string;
  nectarPoints?: string;
  shouldShowCostPerUnit: boolean;
  productPage: ProductPage;
  children?: any;
  displayOfferPromotion?: boolean;
}

export enum ButtonStatus {
  PENDING = "PENDING",
  DISABLED = "DISABLED",
  AVAILABLE = "AVAILABLE",
}

export interface ProductTileMiniProduct {
  product: BasketProduct;
  showPromotionPrice: boolean;
  serves?: string;
}

export type ProductTilePromotion = Pick<
  Promotion,
  | "strapLine"
  | "url"
  | "promotionUid"
  | "promotionMissed"
  | "originalPrice"
  | "promoMechanicId"
  | "endDate"
  | "promoType"
  | "promoGroup"
  | "isNectar"
>;

export interface ProductTileCrossIconContainerProps {
  productUid: string;
}

export interface ProductNectarOffer {
  expiry: string;
  points: string;
}

export interface ProductTileContainerProps {
  isFeatureTourTile?: boolean;
  productUid: ProductUid;
  product?: ProductTileProduct;
  isDisabled?: boolean;
  hideFavouritesIcon?: boolean;
  hidePromotion?: boolean;
  hasNectarAssociated?: boolean;
  hideFirstFavourites?: boolean;
  removeFavourite?: (productId: string) => void;
  openModal?: boolean;
  addProductForViewportAnalytics?: any;
  carouselKey?: string;
  hideProductHeader?: boolean;
  isLastTileInCarousel?: boolean;
  clickSource?: ClickSource;
  fulfilmentState?: FulfilmentState;
  className?: string;
  setWeightedItemPrice?: Dispatch<SetStateAction<ProductOption | null>>;
  isFbt?: boolean;
  handleFbtOnSearch?: (index: number, productId: string) => void;
  rowIndex?: number;
  isArrowVisible?: boolean;
  forceHorizontalTile?: boolean;
  buttonVariant?: "primary" | "secondary" | "tertiary";
}

export interface ProductTileProps extends ProductTileContainerProps {
  className?: string;
  isFavourite: boolean;
  isFirstFavourites: boolean;
  product: ProductTileProduct;
  dietaryProfileActive: boolean;
  showDietaryWarning: boolean;
  useCustomMessaging?: boolean;
  nectarOffer?: { points: string; expiry: string };
  addProductForViewportAnalytics?: any;
  carouselKey?: string;
  isFbt?: boolean;
}

export enum ItemType {
  PRODUCT = "PRODUCT",
  PRODUCT_AD = "PRODUCT_AD",
  ESPOT = "ESPOT",
  IN_GRID_BANNER = "IN_GRID_BANNER",
  IN_GRID_BANNER_V2 = "IN_GRID_BANNER_V2",
}

export interface ProductTileListContainerProps {
  products: ProductUid[];
  espots?: InterleavedEspot[];
  bannerAds?: InterleavedBannerAd[];
  bannerAdsV2?: InterleavedBannerAdV2[];
}
export interface TileGridSize {
  tile: { [key: string]: string };
  espot: { [key: string]: string };
}
export interface ProductTileListProps {
  isCategorySort?: boolean;
  isDisabled?: boolean;
  hideFavouritesIcon?: boolean;
  hidePromotion?: boolean;
  productCoordMap?: Map<number, ProductCoord>;
  productTileGridSizes?: TileGridSize;
  windowSize?: {
    windowHeight: number;
    windowWidth: number;
  };
  lastSeenFavourite?: string;
  removeFavourite?: boolean;
  term?: string;
  isHFSSRestricted?: boolean;
  ariaLabel?: string;
  addProductForViewportAnalytics?: any;
  hideTourTile?: boolean;
  forceHorizontalTile?: boolean;
  clickSoruce?: ClickSource;
}

export interface ProductListItem {
  item: InterleavedEspot | InterleavedBannerAd | InterleavedBannerAdV2 | ProductUid | Product;
  type: ItemType;
}

export interface ProductTileListItems {
  items: ProductListItem[];
  shouldRenderFeedback?: boolean;
  clickSource?: ClickSource;
}

export interface ProductTileListSingleRowProps {
  items: Array<{
    item: ProductUid;
    type: ItemType;
  }>;
  isHFSSRestricted: boolean;
  lastSeenFavourite?: string;
  productTileGridSizes?: { tile: { [key: string]: string }; espot: { [key: string]: string } };
  addProductForViewportAnalytics?: UseProductsReturnType["addProductForViewportAnalytics"];
}

export interface ProductTileImageProps {
  url?: string;
  imageSrc: string | null;
  productName: string;
  isAvailable?: boolean;
  showOccasionBanner?: boolean;
}

export interface ProductTileButtonsContainerProps {
  productUid: string;
  selectedUnitOfMeasure?: string;
  selectedCatchweight?: string;
  reverseTabIndex?: boolean;
}

export enum ProductActionType {
  PRODUCT_CLICKED = "PRODUCT_CLICKED",
  PROMOTION_CLICKED = "PROMOTION_CLICKED",
  REMOVE_PRODUCT_PENDING = "REMOVE_PRODUCT_PENDING",
}

export type ProductClickedAction = {
  type: ProductActionType.PRODUCT_CLICKED;
  product: Product;
};

export type PromotionClickedAction = {
  type: ProductActionType.PROMOTION_CLICKED;
  promotion: Promotion;
};

export enum ProductPage {
  PRODUCT_TILE = "ProductTile",
  PRODUCT_DETAILS = "ProductDetails",
  RECIPE_INGREDIENT_TILE = "RecipeIngredientTile",
}

export interface ProductGridRow extends ProductListItem {
  tileIndex: number;
}
