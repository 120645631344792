import {
  AddOrderToBasketErrorsAction,
  AddOrderToBasketFailureAction,
  AddOrderToBasketRequestAction,
  FetchPreviousOrdersPending,
  FetchPreviousOrdersSuccess,
  PreviousOrdersAction,
  SelectPreviousOrderAction,
} from "./previousOrders.types";
import { Action } from "redux";
import { mapPaginatedProducts } from "../../domain/product/product";
import { handleUnauthorized } from "../../common/responseErrorHandler";
import { PreviousOrdersPageViewAction } from "../../common/analytics/analytics";
import { AdobeDigitalDataProperties, AnalyticsActionTypes } from "../../common/analytics/types";
import { AppThunkDispatch } from "../../common/types";
import { ProductQueryParameters } from "../../services/product.types";
import { fetchProductsByOrder as servicesFetchProductsByOrder } from "../../services/product";
import { PRICE_ASC, PaginatedProducts, ProductSource } from "../../domain/product/product.types";
import { Basket, BasketAddOrderError } from "../../domain/basket/basket.types";
import { useFeatureFlag } from "../../common/firebase/featureFlags.hooks";
import { AdobeExtraPageData, AdobePageName, AdobePageType, generateAdobeAnalyticsPageProperties } from "../../utils";

export const createSelectPreviousOrderAction = (orderUid: string): SelectPreviousOrderAction => ({
  type: PreviousOrdersAction.SELECT_PREVIOUS_ORDER,
  orderUid,
});

export const previousOrdersPendingAction = (): FetchPreviousOrdersPending => ({
  type: PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_PENDING,
});

export const previousOrdersSuccess = (products: PaginatedProducts): FetchPreviousOrdersSuccess => ({
  type: PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_SUCCESS,
  products,
});

export const previousOrdersErrorAction = (error: string) => {
  return {
    type: PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_ERROR,
    message: error,
  };
};

export const createAddOrderToBasketFailureAction = (): AddOrderToBasketFailureAction => ({
  type: PreviousOrdersAction.ADD_ORDER_TO_BASKET_FAILURE,
});

export const createAddOrderToBasketRequestAction = (orderID: string): AddOrderToBasketRequestAction => ({
  type: PreviousOrdersAction.ADD_ORDER_TO_BASKET_REQUEST,
  orderID,
});

export const createAddOrderToBasketSuccessAction = (): Action => ({
  type: PreviousOrdersAction.ADD_ORDER_TO_BASKET_SUCCESS,
});

export const createAddOrderToBasketErrorsAction = (errors: BasketAddOrderError[]): AddOrderToBasketErrorsAction => ({
  type: PreviousOrdersAction.ADD_ORDER_TO_BASKET_ERRORS,
  errors,
});

export const previousOrdersPageViewAction = (
  adobeProperties: AdobeDigitalDataProperties
): PreviousOrdersPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "favourites:previous orders",
    template: "favouritesPreviousOrder",
    newTemplate: "favouritesPreviousOrder",
    section: "previous orders",
  },
  adobeProperties,
});

export const fetchProductsByOrder =
  (query: ProductQueryParameters, orderUid: string, basket: Basket) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(previousOrdersPendingAction());

    const isModalEnable = useFeatureFlag("alternatives_modal");
    const result = await servicesFetchProductsByOrder({
      orderUid,
      sort: query.sort ? query.sort : PRICE_ASC,
      filters: query.filters,
      pageSize: query.pageSize ? query.pageSize : 60,
      pageNumber: query.pageNumber ? query.pageNumber : 1,
      includes: ["ASSOCIATIONS", "DIETARY_PROFILE"],
    });

    dispatch(handleUnauthorized(result));

    const { data_page_name, data_siteSection, data_shoppingMode } = generateAdobeAnalyticsPageProperties({
      basket,
      pageName: AdobePageName.FAVOURITES,
      pageType: AdobePageType.GROCERIES,
      extraPageData: [AdobeExtraPageData.PREVIOUSLY_ORDERED_PRODUCTS],
    });

    if (result.isSuccess()) {
      dispatch(previousOrdersPageViewAction({ data_page_name, data_siteSection, data_shoppingMode }));
      dispatch(previousOrdersSuccess(mapPaginatedProducts(ProductSource.PREVIOUS_ORDERS, result.data, isModalEnable)));
    } else {
      dispatch(previousOrdersErrorAction(result.errors.first().detail));
    }
  };
