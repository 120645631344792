import qs from "qs";
import { queryParamValue } from "../http/query";
import { urls } from "../../routes";
import { blockedRedirectRefererUrlPaths, isUrlPathInList } from "../location/locationSelectors";

export const parseUrl = (url: string) => {
  if (!url) {
    return {
      url: "",
      query: {},
    };
  }
  if (url.startsWith("?")) {
    return {
      url: "",
      query: qs.parse(url, { ignoreQueryPrefix: true }),
    };
  }

  const parsedUrl = new URL(url);
  const parsedQuery = qs.parse(parsedUrl.search, { ignoreQueryPrefix: true });
  return {
    url: `${parsedUrl.origin}${parsedUrl.pathname}`,
    query: parsedQuery,
  };
};

export const setQueryParameter = (url: string, key: string, parameter: boolean | string): string => {
  const { url: parsedUrl, query } = parseUrl(url);
  query[key] = parameter;
  return `${parsedUrl}?${qs.stringify(query)}`;
};

export const getQueryParameter = (url: string, key: string): string | string[] | undefined => {
  return parseUrl(url).query[key] || "";
};

export const getContinueShoppingUrl = (search: string): string => {
  const url = queryParamValue(search, "currentPageUrl") || urls.GROCERIES_URL;
  const blockedList: string[] = blockedRedirectRefererUrlPaths.concat(
    "/webapp/wcs/stores/servlet/ShoppingMethodsView",
    "/shop/ShoppingMethodsView"
  );
  if (isUrlPathInList(url, blockedList)) {
    return urls.GROCERIES_URL;
  }
  const parsedURL = parseURL(url);
  return parsedURL.protocol === "https:" ? parsedURL.toString() : urls.GROCERIES_URL;
};

export const parseURL = (u: string): URL => {
  const re = new RegExp("^(?:[a-z]+):?//", "i");
  if (re.test(u)) {
    // if a full URL parse and return
    return new URL(u);
  }
  // if a path, need to parse in the context of the current page domain
  return new URL(u, window.location.href);
};
