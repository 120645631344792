import { AnyAction } from "redux";

export enum ActionTypes {
  PERSIST_CONDITIONAL_ACTION = "PERSIST_CONDITIONAL_ACTION",
}

export interface DeferredConditionalAction {
  type: ActionTypes.PERSIST_CONDITIONAL_ACTION;
  persistedAction: DeferredAction;
}

export interface DeferredAction {
  // the condition that causes the action to be re-dispatched, e.g. LOGIN_SUCCESS or CHECK_POSTCODE_SUCCESS
  type: string;
  expiryTime: string;

  // the action that is re-dispatched if the condition is met
  redispatchedAction: AnyAction;
}
