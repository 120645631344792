import { useSelector } from "react-redux";
import { State } from "../../../common/store";
import { useFeatureFlag } from "../../../common/firebase/featureFlags.hooks";

export const useHeaderState = () => {
  const isMinimiseHeaderEnabled = useFeatureFlag("new_global_header");
  const state = useSelector((state: State) => ({
    isHeaderVisible: state.header.visible,
    showMinimised: isMinimiseHeaderEnabled && state.header.showMinimizedHeader,
  }));

  return state;
};

export const useMegaNavState = () => {
  const state = useSelector((state: State) => ({
    isMegaNavOpen: state.header.isMegaNavOpen,
    selectedMeganavCategory: state.header.selectedMeganavCategory,
  }));

  return state;
};
