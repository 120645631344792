import { pushEvent } from "../components/Espots/espot.digitaldata";
import { postLinkTrackEvent } from "../common/analytics/digitalData";
import debounce from "lodash.debounce";

export const sendCarouselScrollEvent = debounce((title: string) => {
  const description = `Carousel scroll: ${title} `;
  pushEvent({
    eventAction: "scroll",
    eventName: description,
    eventLabel: description,
    eventCategory: description,
  });
  postLinkTrackEvent(window);
}, 500);
