import React, { createContext, ReactNode, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { featureFlags } from "../../../common/firebase/featureFlags";
import { NectarDestinationsPageConfig } from "../types";
import { isLoggedInSelector } from "../../../common/login/login.selectors";

type NectarDestinationContextProps = {
  children: ReactNode;
};

type ContextValue = {
  isLoggedIn: boolean;
  bonusPointsCarouselEnabled: boolean;
  staticSkuListCarouselEnabled: boolean;
  config?: NectarDestinationsPageConfig;
  shouldDisplayMagnoliaContent: boolean;
  nectarFavouritesCarouselEnabled: boolean;
  nectarPopularCarouselEnabled: boolean;
  yourNectarPricesCarouselEnabled: boolean;
};

const NectarDestinationContext = createContext<ContextValue>(undefined as unknown as ContextValue);

export const NectarDestinationContextProvider = ({ children }: NectarDestinationContextProps) => {
  const config = useMemo(() => featureFlags.get("nectar_destination_page"), []);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const bonusPointsCarouselEnabled = useMemo(() => Boolean(config?.carousels?.bonus_points?.enabled), [config]);
  const yourNectarPricesCarouselEnabled = useMemo(
    () => Boolean(config?.carousels?.your_nectar_prices?.enabled),
    [config]
  );
  const nectarFavouritesCarouselEnabled = useMemo(() => Boolean(config?.carousels?.favourites?.enabled), [config]);
  const staticSkuListCarouselEnabled = useMemo(() => {
    const enabled = Boolean(config?.carousels?.static_sku_list?.enabled);
    const numberOfSkus = Number(config?.carousels?.static_sku_list?.skus?.length);

    return enabled && numberOfSkus > 0;
  }, [config]);

  const nectarPopularCarouselEnabled = useMemo(() => {
    const enabled = Boolean(config?.carousels?.nectar_popular_promotions?.enabled);

    return enabled;
  }, [config]);

  const shouldDisplayMagnoliaContent = useMemo(
    () => Boolean(config?.magnolia?.enabled && config?.magnolia?.templateName),
    [config]
  );

  const value = {
    config,
    isLoggedIn,
    bonusPointsCarouselEnabled,
    staticSkuListCarouselEnabled,
    nectarPopularCarouselEnabled,
    shouldDisplayMagnoliaContent,
    nectarFavouritesCarouselEnabled,
    yourNectarPricesCarouselEnabled,
  };

  return <NectarDestinationContext.Provider value={value}>{children}</NectarDestinationContext.Provider>;
};

export const useNectarDestinationContext = () => {
  const context = useContext(NectarDestinationContext);

  if (context === undefined) {
    throw new Error(
      "The NectarDestinationContext is undefined. useNectarDestinationContext must be used within a NectarDestinationContext.Provider"
    );
  }

  return context;
};
