import React from "react";
import { CountdownComponent } from "../../SlotCountdown/SlotCountdown.component";
import {
  AmendOrderBannerDetailsWithCountdownProps,
  AmendOrderBannerCutOffDetailsProps,
  AmendOrderBannerDetailsBaseProps,
  AmendOrderBannerSlotExpiryDetailsProps,
  AmendOrderBannerDetailsProps,
  ExpiryDetailsReason,
} from "../AmendOrderBanner.types";
import { GOLLink } from "../../GOLLink";

const OccasionsCopy =
  " For changes impacting occasions items, check your trolley for details on when to check out, as these items cannot be amended or cancelled once in progress.";

export const SlotExpiredDetails = ({ orderId, hasOccasionItems, viewOrderLink }: AmendOrderBannerDetailsBaseProps) => (
  <p className="amend-order-banner__details" id="dialogDesc">
    <strong>
      You're amending your order{" "}
      <GOLLink data-testid="amend-order-banner-order-details-link" to={viewOrderLink}>
        #{orderId}
      </GOLLink>
    </strong>
    . Please book a slot before you check out.
    {hasOccasionItems && OccasionsCopy}
  </p>
);

export const SlotExpiryDetailsWithCountdown = ({
  orderId,
  hasOccasionItems,
  viewOrderLink,
  expiryDateAndTime,
  onCountdownComplete,
}: AmendOrderBannerDetailsWithCountdownProps) => (
  <p className="amend-order-banner__details" id="dialogDesc">
    <strong>
      You're amending your order{" "}
      <GOLLink data-testid="amend-order-banner-order-details-link" to={viewOrderLink}>
        #{orderId}
      </GOLLink>
      . Check out before your new slot expires in
      <CountdownComponent expiryDateAndTime={expiryDateAndTime} onComplete={onCountdownComplete} />.
    </strong>
    {hasOccasionItems && OccasionsCopy}
  </p>
);

export const SlotExpiryDetails = ({
  orderId,
  hasOccasionItems,
  viewOrderLink,
  expiryDateAndTimeFormatted,
}: AmendOrderBannerSlotExpiryDetailsProps) => (
  <p className="amend-order-banner__details" id="dialogDesc">
    <strong>
      You're amending your order{" "}
      <GOLLink data-testid="amend-order-banner-order-details-link" to={viewOrderLink}>
        #{orderId}
      </GOLLink>
      .
    </strong>{" "}
    To confirm your new slot, check out by <span className="bold-text">{expiryDateAndTimeFormatted}</span>.
    {hasOccasionItems && OccasionsCopy}
  </p>
);

export const CutOffDetailsWithCountdown = ({
  orderId,
  hasOccasionItems,
  viewOrderLink,
  expiryDateAndTime,
  onCountdownComplete,
}: AmendOrderBannerDetailsWithCountdownProps) => (
  <p className="amend-order-banner__details" id="dialogDesc">
    <strong>
      You've got
      <CountdownComponent expiryDateAndTime={expiryDateAndTime} onComplete={onCountdownComplete} /> left to check out,
      or changes to your order{" "}
      <GOLLink data-testid="amend-order-banner-order-details-link" to={viewOrderLink}>
        #{orderId}
      </GOLLink>{" "}
      won't be saved.
    </strong>
    {hasOccasionItems && OccasionsCopy}
  </p>
);

export const CutOffDetails = ({
  orderId,
  hasOccasionItems,
  viewOrderLink,
  expiryDateAndTimeFormatted,
}: AmendOrderBannerCutOffDetailsProps) => (
  <p className="amend-order-banner__details" id="dialogDesc">
    <strong>
      You're amending your order{" "}
      <GOLLink data-testid="amend-order-banner-order-details-link" to={viewOrderLink}>
        #{orderId}
      </GOLLink>
      .
    </strong>{" "}
    To save changes, remember to check out by {expiryDateAndTimeFormatted}.{hasOccasionItems && OccasionsCopy}
  </p>
);

export const AmendOrderBannerDetails = ({
  order,
  hasOccasionItems,
  showSlotExpiryDetails,
  showCutOffDetails,
  expiryDetails,
  dispatchSetReservationExpired,
  dispatchSetCutOffExpired,
}: AmendOrderBannerDetailsProps) => {
  let details = (
    <SlotExpiredDetails
      orderId={order!.orderId}
      hasOccasionItems={hasOccasionItems}
      viewOrderLink={order!.viewOrderLink}
    />
  );
  if (expiryDetails) {
    const onCountdownComplete =
      expiryDetails.reason === ExpiryDetailsReason.SLOT_EXPIRATION
        ? dispatchSetReservationExpired
        : dispatchSetCutOffExpired;
    if (showSlotExpiryDetails) {
      details = expiryDetails.isNearExpiry ? (
        <SlotExpiryDetailsWithCountdown
          orderId={order!.orderId}
          hasOccasionItems={hasOccasionItems}
          viewOrderLink={order!.viewOrderLink}
          onCountdownComplete={onCountdownComplete}
          expiryDateAndTime={expiryDetails.expiryDateAndTime}
        />
      ) : (
        <SlotExpiryDetails
          orderId={order!.orderId}
          hasOccasionItems={hasOccasionItems}
          viewOrderLink={order!.viewOrderLink}
          expiryDateAndTimeFormatted={expiryDetails.expiryDateAndTimeFormatted}
        />
      );
    }

    if (showCutOffDetails) {
      details = expiryDetails.isNearExpiry ? (
        <CutOffDetailsWithCountdown
          orderId={order!.orderId}
          hasOccasionItems={hasOccasionItems}
          viewOrderLink={order!.viewOrderLink}
          onCountdownComplete={onCountdownComplete}
          expiryDateAndTime={expiryDetails.expiryDateAndTime}
        />
      ) : (
        <CutOffDetails
          orderId={order!.orderId}
          hasOccasionItems={hasOccasionItems}
          viewOrderLink={order!.viewOrderLink}
          expiryDateAndTimeFormatted={expiryDetails.expiryDateAndTimeFormatted}
        />
      );
    }
  }

  return details;
};

AmendOrderBannerDetails.displayName = "AmendOrderBannerDetails";
