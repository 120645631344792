import { urls } from "../../routes";
import { UserManager, WebStorageStateStore } from "oidc-client";
import { getGlobal } from "../../global";
import memoize from "lodash.memoize";
import { useReportError } from "../../components/Error/reportErrorHook";
import { getTime as servicesGetTime } from "../../services/login";
import { updateWcCookies } from "../../services/cookie.jwt";

// as we have one gol ui deployment in nonprod for all WCS environments, we need
// a way of configuring different ID environments for different WCS environments.
export const getIdentityURL = (): string => {
  const GOL = getGlobal("GOL");
  const location = getGlobal("location");

  if (location) {
    const urlOverrides = {
      "www.perf6.gol.js-devops.co.uk": "https://perf-test-identity-oauth-api.js-golazo.co.uk",
      "www.perf7.gol.js-devops.co.uk": "https://perf-test-identity-oauth-api.js-golazo.co.uk",
      "www.perf9.gol.js-devops.co.uk": "https://perf-test-identity-oauth-api.js-golazo.co.uk",
      "www.perf10.gol.js-devops.co.uk": "https://identity-int.pub.nonprod.phantom.js-devops.co.uk",
    };
    if (location.hostname in urlOverrides) {
      return urlOverrides[location.hostname];
    }
  }

  return GOL ? GOL.config.identityUrl : null;
};

// This is needed as the oidc-client library was throwing errors preventing people
// from logging in for having expiry values in the past and iats in the future due to
// their system clocks being incorrect. Now we provide the time ourselves from the backend.
const getTime = async () => {
  const result = await servicesGetTime();
  if (result.isSuccess()) {
    return result.data.time;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const reportError = useReportError();
  reportError(result.errors.first() as any);
  return Date.now() / 1000;
};

const clockService = {
  getEpochTime: getTime,
};

const globalLocation = getGlobal("location");
const baseUrl = globalLocation ? globalLocation.origin : "";
export const baseIdentityUrl = getIdentityURL();
const keys = [
  {
    use: "sig",
    kty: "RSA",
    kid: "public:5857b478-0280-4039-b3a1-f89a393dc99a",
    alg: "RS256",
    n: "zLrDP6bERLLNeoPRHD4kbBCbjchYJRw76LuyI7zNz21sj7VP3erObXM33ApJoH_R85xXAISzHuO_zJkbukphIzsiqs16TSnoQtMcM93mKdnK6PPLFymiEWT6LQQWrButIk8l6tGWZytyVSEICh0H0ctKk7ufLc4gwAhuGFBTOxtBEXHpbN63rgXBzuKc0cmoJe9Nb_RxMBW0z3v-RbDkoF8lFQpwfZaVGnhySNo85gQLPlFhUeneBZqaToSLYA8omcNfxPPiiX2p1g73J8CJtteJa8jXP1e5rRNdElszPnkU7LDgYgIE6UdeSaAZa_YLT6lMguwu0gqXFTQ7IM-9OgIdJg5cR91zToTKLM0nof_TWNXGVCnsK0K8SpXbJkgT9E8h9vHHuk5qlq7jvwReTAfX2r99HUrjGOU21h8YHbKeDHuNSIexog3qPGtvpwBDy-BUjid1Q_xoDjSlchNQILCmVCS8r4tBlgsgowVlUgFGxGoWatO1y3wjQn0D-JH_vZ4mhSJEvpTWCbUuhDpdOjeqkhvp7_eB1x1YeZMjBucq4NzqyAnYGQDLiZB5Pycc8Tk0UPR8TZizltrXPwaUd3KlvPMTBI42ZHbcOx_yTsS3uK1PL3rYWwLbmbNixog3lq2NQHf35UXjkQyIC31NTmz1X7JDRRrDqF7NsgecvZk",
    e: "AQAB",
  },
];

export const IDENTITY_CONFIG = {
  authority: baseIdentityUrl,
  client_id: "gol",
  redirect_uri: `${baseUrl}${urls.OAUTH_REDIRECT}`,
  login: `${baseUrl}${urls.OAUTH_LOGIN}`,
  automaticSilentRenew: true,
  loadUserInfo: false,
  silent_redirect_uri: `${baseUrl}${urls.OAUTH_REFRESH}`,
  post_logout_redirect_uri: `${baseUrl}${urls.OAUTH_LOGOUT_REDIRECT}`,
  audience: "gol.sainsburys.co.uk",
  response_type: "code",
  grantType: "authorization_code",
  scope: "openid offline gol-session",
  webAuthResponseType: "code",
  signingKeys: keys,
  clockService,
  // raise access token expiry event 5 minutes before expiry
  accessTokenExpiringNotificationTime: 300,
};

export const METADATA_OIDC = {
  // trailing slash for issuer added due to issue in hydra https://github.com/ory/hydra/issues/1041
  issuer: `${baseIdentityUrl}/`,
  jwks_uri: `${baseIdentityUrl}/.well-known/jwks.json`,
  authorization_endpoint: `${baseIdentityUrl}/oauth2/auth?missionId=gol&audience=gol.sainsburys.co.uk`,
  token_endpoint: `${baseIdentityUrl}/oauth2/token`,
  userinfo_endpoint: `/groceries-api/gol-services/customer/v1/customer/identity-profile`,
  end_session_endpoint: `${baseIdentityUrl}/oauth2/sessions/logout`,
};

export const USER_MANAGER_SETTINGS = {
  ...IDENTITY_CONFIG,
  userStore: new WebStorageStateStore({ store: typeof window !== "undefined" && window.localStorage }),
  metadata: {
    ...METADATA_OIDC,
  },
};

function updateWcCookiesCallback(userManager: UserManager) {
  // Remove callback after first call
  // This is done as we cannot differentiate between
  // a normal user load and a silent renew
  const userLoadedCallback = () => {
    updateWcCookies();
    userManager.events.removeUserLoaded(userLoadedCallback);
  };
  userManager.events.addUserLoaded(userLoadedCallback);
}

export const createUserManager = memoize(() => {
  const userManager = new UserManager(USER_MANAGER_SETTINGS);

  // set up silent renew callback
  // to update WC cookies after refresh
  userManager.events.addAccessTokenExpiring(() => updateWcCookiesCallback(userManager));

  return userManager;
});

export default createUserManager;
