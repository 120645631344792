// Modal types

import { InternalNavigationAction } from "../../../common/middleware/navigationMiddleware";
import { UserState } from "../../../common/store";

export interface DietaryModalProps {
  isLoggedIn: boolean;
  user: UserState;
  enabled: boolean;
  profile: string;
  navigateToLogin: () => InternalNavigationAction;
  refetchUser: () => void;
  refetchProducts: () => void;
  resetProfile: () => ResetConditionsAction;
  updateProfileStatus: () => void;
}

export interface DietaryModalContainerProps {
  user: UserState;
  isLoggedIn: boolean;
  profile: string;
  enabled: boolean;
}

export interface DietaryModalDispatchProps {
  navigateToLogin: () => InternalNavigationAction;
  refetchUser: () => void;
  refetchProducts: () => void;
  resetProfile: () => ResetConditionsAction;
  updateProfileStatus: () => void;
}

export interface ModalDietaryManagerProps {
  closeModal: () => void;
  getConditions: () => Promise<boolean>;
  manageConditionsOnSelect: (id: string) => void;
  createProfile: () => Promise<any>;
}

export const initialProfileState = {
  ProfileID: null,
  Conditions: [],
  loading: true,
};

export interface ModalLoginProps {
  closeModal: () => void;
  navigateToLogin: () => InternalNavigationAction;
}

export interface ProfileState {
  ProfileID: string | null;
  Conditions: Condition[];
  loading: boolean;
}

export interface ActionType {
  type: string;
  payload: any;
}

export interface Condition {
  ConditionGroupName: string;
  ConditionID: string;
  ConditionName: string;
  ConditionTypeName: string;
  ConditionSelected: number;
}

export interface ProfileAuth {
  FirstName: string;
  LastName: string;
  ProfileID: string;
  Conditions?: Condition[];
}

export interface UpdateProfileRes {
  Message: string;
  Success: boolean;
}

export interface AuthenticationRes {
  Profiles: ProfileAuth[];
}

export interface ProfileConditionsItem {
  ProfileID: string;
  Conditions: Condition[];
}

export interface CondtionsRes {
  Conditions: Condition[];
}

export enum FoodMaestroActionTypes {
  SET_PROFILEID = "SET_PROFILEID",
  SET_CONDITIONS = "SET_CONDITIONS",
  SET_PROFILE = "SET_PROFILE",
  ERROR = "ERROR",
  FETCH = "FETCH",
  RESET = "RESET",
}

export interface CreateDietaryProfileStateProps {
  conditions?: Condition[];
  error: boolean;
  loading: boolean;
}

export interface ResetConditionsAction {
  type: string;
}

export interface CreateDietaryProfileDispatchProps {
  getConditions: () => void;
  manageConditionsOnSelect: (id: string) => void;
  createProfile: () => Promise<boolean | void>;
}

export interface CreateDietaryProfileProps {
  closeModal: () => void;
}

export interface UpdateDietaryProfileStateProps {
  conditions?: Condition[];
  profileId?: string;
  error: boolean;
  loading: boolean;
}

export interface UpdateDietaryProfileDispatchProps {
  getProfileConditions: () => void;
  manageConditionsOnSelect: (id: string) => void;
  updateProfile: () => Promise<boolean | void>;
  deleteProfile: () => Promise<boolean | void>;
}

export interface UpdateDietaryProfileProps {
  closeModal: () => void;
}
