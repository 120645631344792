import isEmpty from "lodash.isempty";
import { cloneDeep } from "lodash";
import {
  CATEGORY_ID_IDENTIFIER,
  initBrowsePathSubstrings,
  isExpected,
  isValidPath,
  OPTION_DELIMITER,
  BrowseOptions,
  BrowsePathSubstrings,
  BrowseTaxonomyState,
  BrowsePageType,
} from "./Browse.types";
import {
  BrowseMagnoliaRequestPayload,
  BrowseMagnoliaRequestProps,
  BrowseMagnoliaResponse,
} from "./BrowseMagnoliaContent/BrowseMagnolia.type";
import { isOffersFilter, PathFilters } from "../filters.types";
import {
  capitalise,
  concatAllNonEmptyStrings,
  getCharsAfter,
  getCharsBetween,
  removeBookends,
  hyphenate,
  dehyphenate,
  replacePlaceholders,
} from "../../common/utils";
import { urls } from "../../routes";
import { BrowseProductsControlsParams, RequestFilters, ResponseFilter } from "../../services/product.types";
import { ContentBannerLocation, ContentPlacement, TemplateArea, TemplateComponent } from "../../services/contentTypes";
import { ESPOT_IDS } from "../../components/Citrus/MultiAd/MultiAd.utils";
import { serviceRequest } from "../../services/request";
import { Method } from "../../common/http/request";
import { CitrusBanner } from "../../components/Citrus/BannerAd/BannerAd.types";
import { makePillItems } from "./BrowseHeaderPills/BrowseHeaderPills.component";
import { PageVariants } from "../SearchResultsFindability/SearchResultsFindability.types";
import { getBrandedSubStrings } from "../BrandedPage/BrandedPage.utils";
import { getEventsAndFeaturesSubStrings } from "../EventsFeatures/EventsFeatures.utils";
import { getMealDealSubStrings } from "../MealDealBuilder/utils";
import { FilterValuesResponse } from "../EventsFeatures/EventsFeatures.hooks";

export enum PLACEHOLDERS {
  PARENT_CATEGORY = "{{parent category}}",
  CATEGORY = "{{cat}}",
  REFINEMENT = "{{refinement}}",
}

export const isSpecialOffersShelf = (tree: string) => tree.split("/").slice(-1)[0] === "special-offers";

export const isNewShelf = (tree: string) => tree.split("/").slice(-1)[0] === "new";

export function stringToFiltersControls(
  path: string,
  isSpecialOffersPage?: boolean,
  isNewPage?: boolean
): PathFilters | undefined {
  if (isEmpty(path)) {
    if (isSpecialOffersPage) return { offer: true };
    if (isNewPage) return { other: "new" };
    return undefined;
  }
  const filters: any = path
    .split("/")
    .filter(s => isExpected(s))
    .reduce((controls, current) => {
      const arr = current.split(":");
      isOffersFilter(arr[0]) ? (controls[arr[0]] = true) : (controls[arr[0]] = arr[1]);
      return controls;
    }, {});

  if (isEmpty(filters)) return undefined;
  if (isSpecialOffersPage && !filters.offer) filters.offer = true;
  if (isNewPage && filters.other !== "new") filters.other = "new";
  return filters;
}

export const getBrowseSubstrings = (path: string): BrowsePathSubstrings => {
  if (!isValidPath(path)) return initBrowsePathSubstrings;

  const segments: string[] = path.split(OPTION_DELIMITER);

  const options = segments.length > 1 ? OPTION_DELIMITER.concat(segments[1]) : "";
  const treeWithRoute = removeBookends(segments[0].split(CATEGORY_ID_IDENTIFIER)[0]);
  const tree: string = removeBookends(getCharsAfter(treeWithRoute, removeBookends(urls.BROWSE)));
  const categoryIDWithPrefix = segments[0].split("/").filter(s => s.includes(CATEGORY_ID_IDENTIFIER))[0];

  const landingURL = [urls.BROWSE, tree, categoryIDWithPrefix].filter(s => s.length).join("/");
  const categoryID = removeBookends(getCharsAfter(categoryIDWithPrefix, CATEGORY_ID_IDENTIFIER));
  const filters = removeBookends(getCharsBetween(path, landingURL, OPTION_DELIMITER));
  return { tree, browseID: categoryID, prefixedBrowseID: categoryIDWithPrefix, landingURL, filters, options };
};

export const getSortOptions = (optionString: string): BrowseOptions => {
  const init = { sort: "", page: "" };
  if (optionString.length === 0) return init;
  return optionString
    .split("/")
    .filter(s => isExpected(s))
    .map(s => s.split(":"))
    .reduce((controls, current) => {
      controls[current[0]] = current[1];
      return controls;
    }, init);
};

export const resetPage = (optionString: string): string => {
  const { sort } = getSortOptions(optionString);
  return sort.length ? OPTION_DELIMITER.concat("sort:", sort) : "";
};

// TODO: Since the logic is being reused should be moved to a more common location
export const createNewPaginatedURL = (path: string, position: number, pageVariant: PageVariants): string => {
  let landingURL, filters, options;
  switch (pageVariant) {
    case PageVariants.BROWSE:
      ({ landingURL, filters, options } = getBrowseSubstrings(path));
      break;
    case PageVariants.BRANDED:
      ({ landingURL, filters, options } = getBrandedSubStrings(path));
      break;
    case PageVariants.MEAL_DEAL_BUILDER:
      ({ landingURL, filters, options } = getMealDealSubStrings(path));
      break;
    default:
      ({ landingURL, filters, options } = getEventsAndFeaturesSubStrings(path));
  }

  let facetSlug: string | null;
  switch (pageVariant) {
    case PageVariants.BRANDED:
      facetSlug = getBrandedSubStrings(path).facetSlug;
      break;
    case PageVariants.EVENTS_BUILDER:
    case PageVariants.FEATURES:
      facetSlug = getEventsAndFeaturesSubStrings(path).facetSlug;
      break;
    case PageVariants.MEAL_DEAL_BUILDER:
      facetSlug = getMealDealSubStrings(path).listIdSlug;
      break;
    default:
      facetSlug = "";
      break;
  }

  const { sort: sortValue } = getSortOptions(options);
  const sort = sortValue ? "sort:".concat(sortValue) : "";
  const page = position === 1 ? "" : "page:".concat(position.toString());
  const newOptions = (): string => {
    if (!page && !sort && !facetSlug) return "";

    if (page || sort) {
      return concatAllNonEmptyStrings([facetSlug ?? "", removeBookends(OPTION_DELIMITER), page, sort], "/");
    } else {
      return concatAllNonEmptyStrings([facetSlug ?? ""], "/");
    }
  };
  return concatAllNonEmptyStrings([landingURL, filters, newOptions()], "/");
};

export const validateControls = (controls: BrowseProductsControlsParams): BrowseProductsControlsParams => {
  const validEntries = Object.entries(controls).filter(c => !isEmpty(c) && !isEmpty(c[0]));
  return Object.fromEntries(validEntries) as BrowseProductsControlsParams;
};

export const fetchMagnoliaContentForBrowse = async ({
  categoryID,
  browseID,
  storeId,
  deliveryDate,
  region,
  hasNectar,
  digitalCustomer,
  hasDeliveryPass,
  isVeryImportantCustomer,
  pageType,
}: BrowseMagnoliaRequestProps) => {
  const SHELF_AD_URL = "/groceries-api/gol-services/content/v2/withMagnoliaTemplate/ads";
  const categoryIdsArray = `${categoryID}`?.split(",");
  const requestBody: BrowseMagnoliaRequestPayload = {
    category_ids: categoryIdsArray,
    template_id: browseID,
    slotId: ContentBannerLocation.SHELF_IN_GRID_1,
    espotIds: ESPOT_IDS[ContentBannerLocation.SHELF_IN_GRID_1],
    ...(pageType !== BrowsePageType.ZONE && { placement: ContentPlacement.CATEGORY_ONLY }),
    store: storeId,
    delivery_date: deliveryDate,
    region,
    has_nectar_associated: hasNectar,
    is_digital_customer: digitalCustomer,
    has_delivery_pass: hasDeliveryPass,
    is_very_important_customer: isVeryImportantCustomer,
  };

  return serviceRequest<BrowseMagnoliaResponse>({ method: Method.POST, url: SHELF_AD_URL, body: requestBody });
};

export const getParentCategoryName = (tree: string): string => {
  const treeSplits = tree.split("/");
  const indexOfParentCategory = treeSplits.length > 1 ? treeSplits.length - 2 : 0;
  const parentCategoryName = treeSplits[indexOfParentCategory].replace(/-/g, " ");
  return capitalise(parentCategoryName);
};

export const getRefinedValue = (filters: RequestFilters): string => {
  const keys = Object.keys(filters);
  return keys.map(key => filters[key]).join(", ");
};

// MIGRATION_LEVEL determines whether the WCS URL or the GOL-UI URL is used for the breadcrumbs. When MIGRATION_LEVEL is 0, all breadcrumbs will use GOL-UI URLs. When MIGRATION_LEVEL is greater than 3, all breadcrumbs will use WCS URLs.
const MIGRATION_LEVEL = 2;
export const isMigrationReady = (pageLevel: number): boolean => pageLevel >= MIGRATION_LEVEL;

export const getDescriptionWithParentCategoryLink = (
  description: string,
  category: string,
  url: string | undefined
) => {
  // This function is used by SSR cannot contain Redux elements or reference browser objects e.g. window, location, etc
  let formattedDescription: string;
  if (url) {
    const anchorWrapper = `<a href="${url}" target="_self">${PLACEHOLDERS.PARENT_CATEGORY}</a>`;
    const pageDescriptionWithLink = description.replace(PLACEHOLDERS.PARENT_CATEGORY, anchorWrapper);
    formattedDescription = replacePlaceholders(pageDescriptionWithLink, PLACEHOLDERS.PARENT_CATEGORY, category);
  } else {
    formattedDescription = replacePlaceholders(description, PLACEHOLDERS.PARENT_CATEGORY, category);
  }

  return formattedDescription;
};

export const getParentTree = (tree: string) => {
  const treeList = tree.split("/").slice(0, -1);
  if (!treeList || treeList.length < 1) return tree;
  return treeList.join("/");
};

export interface PlaceholdersTags {
  seoTags: any;
  category: string;
  parentCategory: string;
  refinement: string;
  filters: string;
  selfLocation?: string;
}
export const replaceBrowsePlaceholderTags = ({
  seoTags,
  category,
  parentCategory,
  refinement,
  filters,
  selfLocation,
}: PlaceholdersTags): typeof seoTags => {
  const cleanCategory = category.replace(/-/g, " ");
  const cleanParentCategory = parentCategory.replace(/-/g, " ");
  const refinedPageIntroCopy = getDescriptionWithParentCategoryLink(
    seoTags.refinedPageIntroCopy,
    cleanParentCategory,
    selfLocation
  );
  seoTags.refinedPageIntroCopy = refinedPageIntroCopy;

  // replace key placeholders for refinement and category name
  for (const tag in seoTags) {
    const formattedRefinement = tag === "refinedDescription" ? refinement.replace(/,/g, "") : refinement;

    const contentTag = seoTags[tag];
    const refinedTag =
      typeof contentTag === "string"
        ? contentTag
            .replace("{{refinement}}", formattedRefinement)
            .replace("{{cat}}", capitalise(cleanCategory))
            .replace("{{parent category}}", cleanParentCategory)
            .replace(/\s-\s\s|\s\s/gm, " ")
        : seoTags[tag];

    seoTags[tag] = refinedTag;
  }
  // noIndex=true will be forced until deploy to Prod to avoid indexing pages before release
  if (filters && refinement === "") {
    seoTags.noIndex = true;
  }
  // end of block to remove on
  seoTags.canonicalTag = seoTags?.canonicalTag || selfLocation;

  return seoTags;
};

export const validateRefineRules = (pathFilters: PathFilters | undefined): string => {
  if (!pathFilters) return "";
  // unrefined output
  // { brand: "sainsbury's,so-organic", dietary: 'organic', other: 'british' }
  // { brand: "sainsbury's", dietary: 'organic', other: 'british' }
  let refinement = "";
  const filters = Object.assign({}, pathFilters);
  if (filters.nav_Brand) {
    // Fix double version of brand
    let brands = filters.nav_Brand.split(",");
    brands = brands.map((v: string) => dehyphenate(hyphenate(v)));
    filters.nav_Brand = Array.from(new Set(brands)).join(",");
  }
  if (filters && Object.keys(filters).length > 2) return "";
  for (const filter in filters) {
    if (filter !== "offer" && filters[filter] !== "Offers" && filters[filter] !== "new") {
      if (filters[filter].toString().split(",").length > 1) {
        return "";
      } else {
        refinement = refinement ? `${refinement} ${filters[filter]}` : filters[filter];
      }
    }
  }
  return refinement.replace(/-/g, " ").replace(/,/g, ", ");
};

export const emptyBrowseTags: SeoTags = {
  noIndex: true,
  refinedDescription:
    "View our collection of {{refinement}} {{cat}}. Shop online at Sainsbury's - same great quality you'd expect in-store, anytime delivery available.",
  refinedPageHeader: "{{refinement}} {{cat}}",
  refinedPageIntroCopy:
    "Pick up your favourites from our range of {{refinement}} {{cat}} products. Top up your trolley and check out the rest of our {{parent category}} range too. Book your collection or delivery slot today.",
  refinedTitle: "{{refinement}} {{cat}} | Sainsbury's",
  unrefinedTitle: "{{cat}} | Sainsbury's",
  seoFooterCopy: "",
};

export interface SeoTags {
  noIndex: boolean | undefined;
  refinedDescription: string;
  refinedPageHeader: string;
  refinedPageIntroCopy: string;
  refinedTitle: string;
  unrefinedTitle: string;
  seoFooterCopy: string;
  canonicalTag?: string;
}

export const getBrowseShelfCategoryName = (path: string, taxonomy: BrowseTaxonomyState, lookups: number[]): string => {
  const fallbackCategoryName = (path.split("/").pop() || path).replace(/-/g, " ");
  const selectedCategoryPillItem = makePillItems(taxonomy, BrowsePageType.SHELF, lookups).find(
    pillItem => pillItem.isSelected
  );
  if (!selectedCategoryPillItem || !selectedCategoryPillItem.label) return fallbackCategoryName;
  return selectedCategoryPillItem.label;
};

export const prepareCitrusDataForZone = (ads: CitrusBanner[]) => {
  if (ads.length < 3) return ads.map(ad => ({ ...ad, type: "C001", citrus: true, isSecondaryHeading: true }));
  return ads.map(ad => ({ ...ad, type: "C004", citrus: true }));
};

export const browseSpecialOffersAndNewFilterUpdate = (filters: ResponseFilter[], tree: string) => {
  if (isSpecialOffersShelf(tree)) {
    try {
      const updatedFilters = cloneDeep(filters);
      const multiSelectFilterIndex = updatedFilters.findIndex(e => e.key === "nav_Filter-Your-Results");
      const offersIndex = updatedFilters[multiSelectFilterIndex].values.findIndex(
        e => e.label === "Offers & Nectar Prices"
      );
      updatedFilters[multiSelectFilterIndex].values[offersIndex].disabled = true;
      return updatedFilters;
    } catch (e) {
      return filters;
    }
  }

  if (isNewShelf(tree)) {
    try {
      const updatedFilters = cloneDeep(filters);
      const multiSelectFilterIndex = updatedFilters.findIndex(e => e.key === "other");
      const offersIndex = updatedFilters[multiSelectFilterIndex].values.findIndex(e => e.label === "New");
      updatedFilters[multiSelectFilterIndex].values[offersIndex].disabled = true;
      updatedFilters[multiSelectFilterIndex].values[offersIndex].selected = true;
      return updatedFilters;
    } catch (e) {
      return filters;
    }
  }

  return filters;
};

export const isBrowseRoute = (path: string): boolean => {
  if (path) return path.includes("/gol-ui/groceries");
  return false;
};

export function manipulateCarouselComponents(
  aboveGridSecondary: TemplateArea | undefined,
  pageType: BrowsePageType,
  includeTypes: boolean
) {
  let aboveGridSecondaryRefined: (TemplateComponent | CitrusBanner)[] = [];
  let aboveGridSecondaryLocal = aboveGridSecondary;

  if (pageType === BrowsePageType.ZONE && aboveGridSecondary !== undefined) {
    aboveGridSecondaryRefined = aboveGridSecondary.attributes
      // Type assertion to TemplateComponent | CitrusBanner
      .map(attribute => attribute as TemplateComponent | CitrusBanner)
      // Filter based on the type property
      .filter(typedAttribute => {
        // Check if the type property exists
        if (typedAttribute.type) {
          // Check the type property and filter accordingly
          return includeTypes
            ? typedAttribute.type === "C006" || typedAttribute.type === "A001"
            : typedAttribute.type !== "C006" && typedAttribute.type !== "A001";
        }
        // Default case, return false to exclude the attribute
        return false;
      });

    aboveGridSecondaryLocal = { ...aboveGridSecondary, attributes: aboveGridSecondaryRefined };
  }
  return aboveGridSecondaryLocal;
}

export const parseFilters = (filtersInput: string[]): FilterValuesResponse => {
  const brands: string[] = [];
  const dietary: string[] = [];
  const other: string[] = [];
  let term = "",
    sort,
    categoryId,
    promotionId;

  filtersInput.forEach(filter => {
    const filterParts = filter.split("=");
    if (filterParts.length === 2) {
      const [filterKey, filterValue] = filterParts;
      if (filterKey === "q") {
        term = filterValue;
      }
      if (filterKey === "filter") {
        const [category, val] = filterValue.split(":");
        if (category === "category") {
          categoryId = val;
        } else if (category === "promotion") {
          promotionId = val;
        } else if (category === "brands") {
          brands.push(val);
        } else if (category === "dietaryandlifestyle") {
          dietary.push(val);
        } else if (category === "other") {
          other.push(val);
        }
      } else if (filterKey === "sort") {
        sort = filterValue;
      }
    }
  });
  const filterValues = filtersToPath(brands, dietary, other);
  return { term, filterValues, categoryId, promotionId, sort };
};

export const filtersToPath = (brand: string[], dietary: string[], other: string[]): string => {
  const filters = { brand, dietary, other };
  return Object.keys(filters)
    .map(key => (filters[key].length > 0 ? `/${key}:${filters[key].join(",")}` : ""))
    .join("");
};
