export const formatCurrency = (value: number, fractionDigits: number = 2): string =>
  `£${formatNumber(Math.abs(value), fractionDigits)}`;

/**
 * Formats a price for presentation.
 * @param {string | number} price - the price to format
 * @param {boolean=} includeZeroPence - whether the zero pence (.00) should be included in the output
 * @example
 * formatPrice("1.50")
 * // returns "£1.50"
 * @example
 * formatPrice(1.50)
 * // returns "£1.50"
 * @example
 * formatPrice("2.00", true)
 * // returns "£2.00"
 * @example
 * formatPrice("2.00")
 * // returns "£2"
 * @returns {string} Returns the formatted price
 */
export const formatPrice = (price: number | string, includeZeroPence: boolean = false): string => {
  if (price === undefined) {
    return "";
  }
  if (typeof price === "string" && price.toLowerCase() === "free") {
    return price;
  }
  const priceNumber = typeof price === "number" ? price : parseFloat(price);
  const formattedPrice = priceNumber >= 1 ? formatCurrency(priceNumber) : formatPence(priceNumber);
  return includeZeroPence ? formattedPrice : formattedPrice.replace(".00", "");
};

const formatPence = (value: number): string => `${Math.abs(value * Math.pow(10, 2)).toFixed()}p`;

export const formatNumber = (value: number, fractionDigits: number = 2): string => value.toFixed(fractionDigits);

export function formatBase64EncodedUnicode(str: string): string {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map((c: string) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );
}
