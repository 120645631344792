import React from "react";
import { CitrusBannerSoloProps } from "../Citrus/CitrusBannerSolo/CitrusBannerSolo";
import { EspotProps } from "../Espots/Espot.component";
import { AdErrorBoundary, AdErrorBoundaryBannerTypes } from "./AdErrorBoundary";
import { FeatureFlagControlledAd } from "../Citrus/FeatureFlagControlledAd/FeatureFlagControlledAd";

export function withAdErrorBoundary<T extends CitrusBannerSoloProps | EspotProps>(
  WrappedComponent: React.ComponentType<T>,
  bannerType: AdErrorBoundaryBannerTypes
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "AdComponent";

  const Component = (props: T) => (
    <FeatureFlagControlledAd>
      <AdErrorBoundary {...props} bannerType={bannerType}>
        <WrappedComponent {...props} />
      </AdErrorBoundary>
    </FeatureFlagControlledAd>
  );

  Component.displayName = `withAdErrorBoundary(${displayName})`;

  return Component;
}
