import React, { ReactElement, ReactNode } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { productClickedActionCreator, promotionClickedActionCreator } from "../ProductTile/productTile.actions";
import { Product, Promotion } from "../../domain/product/product.types";
import { digitalDataGenericTrackEvent, INTERACTION_EVENT_NAMES } from "../../common/analytics";

type TrackClickOwnProps = {
  event: "product" | "promotion" | "grouped_promotion_modal" | "missed_offer" | "grouped_missed_promotion_modal";
  product?: Product;
  promotion?: Promotion;
  productUid?: string;
};

interface TrackClickDispatchProps {
  handleClick: () => void;
  children: JSX.Element;
}

interface TrackClickProps extends TrackClickOwnProps, TrackClickDispatchProps {}

export class TrackClick extends React.PureComponent<TrackClickProps> {
  preventClickAndTriggerEvent = (event: React.MouseEvent<HTMLInputElement>) => {
    const { handleClick } = this.props;
    event.stopPropagation();
    event.preventDefault();
    handleClick();
  };

  render() {
    const { children } = this.props;
    return <div onClickCapture={this.preventClickAndTriggerEvent}>{children}</div>;
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: TrackClickOwnProps) => ({
  handleClick: (): void => {
    switch (ownProps.event) {
      case "product":
        if (ownProps.product) {
          dispatch(productClickedActionCreator(ownProps.product));
        }
        break;
      case "grouped_promotion_modal":
      case "promotion":
        if (ownProps.promotion && ownProps.productUid) {
          const isGroupedPromotionModal = ownProps.event === "grouped_promotion_modal";
          const category = isGroupedPromotionModal ? "Promo Strapline Modal" : "Promo Strapline";
          const header = isGroupedPromotionModal
            ? ownProps.promotion.isNectar
              ? "Meal deals available with Nectar | "
              : "Meal deals available | "
            : "";
          digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.OFFER_LINK_CTA_CLICK, {
            hit_type: "link",
            data_event_category: category,
            data_event_action: "click",
            data_event_label: `${header}${ownProps.promotion.strapLine} | ${ownProps.productUid}`,
          });
          dispatch(promotionClickedActionCreator(ownProps.promotion));
        }
        break;
      case "grouped_missed_promotion_modal":
      case "missed_offer":
        if (ownProps.promotion && ownProps.productUid) {
          const isGroupedMissedPromotionModal = ownProps.event === "grouped_missed_promotion_modal";
          const category = isGroupedMissedPromotionModal
            ? "Promo Strapline Modal | Missed Offer"
            : "Promo Strapline | Missed Offer";
          const setHeader = () => {
            if (isGroupedMissedPromotionModal) {
              if (ownProps.promotion?.strapLine.startsWith("Meal Deal")) {
                return ownProps.promotion?.isNectar ? "Meal deals available with Nectar | " : "Meal deals available | ";
              } else {
                return ownProps.promotion?.isNectar ? "Multi Buys available with Nectar | " : "Multi Buys available | ";
              }
            } else {
              return "";
            }
          };
          digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.MISSED_OFFER_LINK_CTA_CLICK, {
            hit_type: "link",
            data_event_category: category,
            data_event_action: "click",
            data_event_label: `${setHeader()}${ownProps.promotion.strapLine} | ${ownProps.productUid}`,
          });
          dispatch(promotionClickedActionCreator(ownProps.promotion));
        }
        break;
      default:
        return;
    }
  },
});

export const TrackClickConnected = connect(null, mapDispatchToProps)(TrackClick);

TrackClickConnected.displayName = "TrackClickConnected";

interface TrackClick2Props<D> {
  children: ReactNode;
  clickHandler: (trackedData?: D) => void;
  trackedData?: D;
}

type TrackClick2Type = <D>(props: TrackClick2Props<D>) => ReactElement<any>;

export const TrackClick2: TrackClick2Type = ({ children, clickHandler, trackedData }) => (
  <div onClickCapture={() => clickHandler(trackedData)} style={{ display: "contents" }}>
    {children}
  </div>
);
