import { Action, Dispatch, MiddlewareAPI } from "redux";
import { matchPath } from "react-router";
import { LOCATION_CHANGE, push } from "connected-react-router";
import { State } from "../../common/store";
import { AppActionTypes } from "../../App.actions";
import { routes, urls } from "../../routes";
import { ErrorDetailsType, getMissionsConsents } from "./Api";
import { createFormState, formCreateActionCreator } from "../../common/forms";
import { progressiveRegistrationFormConfig } from "./formConfig";
import { GolAppDataSuccess, GolAppDataSuccessAction } from "../../common/golapp";
import { ErrorActionTypes } from "../../components/Error/error.actions";
import { createMissionsDefault, createMissionsFromResponse } from "./cpmMapping";
import { MissionsConsentsApiResponse } from "./golServicesTypes";
import Cookies from "js-cookie";
import { errorType, ProgressiveRegistrationActionTypes } from "./types";
import { ProgressiveRegistrationErrorAction } from "./Actions";

export const progressiveRegistrationMiddleware =
  (api: MiddlewareAPI<Dispatch, State>) => (next: Dispatch<any>) => async (action: Action) => {
    next(action);

    const {
      router: { location },
      login: { verifiedEmail },
      forms: { progressiveRegistration },
    } = api.getState();

    switch (action.type) {
      // Fetch user missions consents to be able to create progressive registration form with CPM
      case AppActionTypes.APP_MOUNT:
      case LOCATION_CHANGE:
      case GolAppDataSuccess.GOL_APP_DATA_SUCCESS: // data was passed from golapp
        if (action.type === GolAppDataSuccess.GOL_APP_DATA_SUCCESS) {
          const data = (action as GolAppDataSuccessAction).data;
          // TODO: remove this 'if check' when apps always pass in JWT token
          if (data.humanVerificationToken) {
            Cookies.set("GOL_VALIDATION_JWT", data.humanVerificationToken);
          }
        }

        if (verifiedEmail && !progressiveRegistration) {
          const isProgressiveRegistration = matchPath(location.pathname, {
            path: routes.PROGRESSIVE_REGISTRATION,
            exact: true,
          });
          if (isProgressiveRegistration) {
            let missionsConsents, cpmFormConfig;
            try {
              missionsConsents = await getMissionsConsents(verifiedEmail);
              cpmFormConfig = createMissionsFromResponse(missionsConsents as MissionsConsentsApiResponse);
            } catch (err) {
              const unknownErrors: Array<string> = [
                ErrorActionTypes.UNHANDLED_API_ERROR,
                "FETCH_FAILURE",
                "JSON_DECODING_ERROR",
              ];

              const hasUnknownErrors = err.some((err: ErrorDetailsType) => unknownErrors.indexOf(err.code) > -1);

              if (!hasUnknownErrors) {
                cpmFormConfig = createMissionsDefault();
              }
            }

            const options = Object.assign({}, progressiveRegistrationFormConfig, cpmFormConfig);
            const cpmFormState = createFormState(options);
            api.dispatch(
              formCreateActionCreator({
                form: "progressiveRegistration",
                state: cpmFormState,
              })
            );
          }
        }
        break;

      case ProgressiveRegistrationActionTypes.PROGRESSIVE_REGISTRATION_ERROR: {
        const progRegErrorAction = action as ProgressiveRegistrationErrorAction;
        if (progRegErrorAction.errorType === errorType.HUMAN_VERIFICATION_FAILED) {
          api.dispatch(push(urls.LOGON_VIEW_IDENTIFIER));
        }
        break;
      }

      default:
        break;
    }
  };
