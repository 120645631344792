import { Dispatch, Action } from "redux";

export interface EspotControl {
  instructions: Instruction[];
}

export interface Instruction {
  name: string;
  insertSequence: number;
  categoriesPosition: CategoriesPosition;
  visible: boolean;
  region: string;
}

export interface CategoriesPosition {
  rowIndex: number;
  insertSequence: number;
}

const getEspotControlDataStore = (): EspotControl => (window as any).ESPOT_CONTROL;

const waitForDataFromEspotControl = (name: string, callback: (value: string) => void) => {
  const EspotControlDataStore = getEspotControlDataStore();
  Object.defineProperty(EspotControlDataStore, name, {
    set(value: string) {
      callback(value);
    },
  });
};

export const getItemFromEspotControl = (name: string) =>
  new Promise((resolve, reject) => {
    const EspotControlDataStore = getEspotControlDataStore();
    if (EspotControlDataStore[`${name}`]) {
      resolve(EspotControlDataStore[`${name}`]);
    } else {
      waitForDataFromEspotControl(name, resolve);
    }
  });

export enum EspotControlDataSuccess {
  ESPOT_CONTROL_DATA_SUCCESS = "ESPOT_CONTROL_DATA_SUCCESS",
}

export type EspotControlDataSuccessAction = {
  type: EspotControlDataSuccess.ESPOT_CONTROL_DATA_SUCCESS;
  espotControl: EspotControl;
};

export const createEspotControlDataSuccess = (espotControl: EspotControl): EspotControlDataSuccessAction => ({
  type: EspotControlDataSuccess.ESPOT_CONTROL_DATA_SUCCESS,
  espotControl,
});

export const createEspotControlData = () => async (dispatch: Dispatch<Action>) => {
  const instructions = await getItemFromEspotControl("instructions");

  if (validateInstructions(instructions)) {
    dispatch(createEspotControlDataSuccess({ instructions } as EspotControl));
  }
};

export const validateInstructions = (instructions: any) =>
  instructions.every(
    (instruction: any) =>
      typeof instruction.name === "string" &&
      typeof instruction.visible === "boolean" &&
      typeof instruction.insertSequence === "number" &&
      typeof instruction.region === "string" &&
      instruction.categoriesPosition &&
      typeof instruction.categoriesPosition.rowIndex === "number" &&
      typeof instruction.categoriesPosition.insertSequence === "number"
  );
