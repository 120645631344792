/* eslint-disable security/detect-object-injection */
import { PromoGroup } from "../services/product.types";
import { complexPromoMechanicIds, temporaryPriceReductionMechanicIds } from "../common/constants";
import { PromoMechanicIds } from "../common/types";
import { ProductTilePromotion } from "../components/ProductTile/productTile.types";
import { Promotion } from "../domain/product/product.types";
import { groupBy } from "lodash";

export interface IPromotionsCategorised<PromoType> {
  complexPromotions: PromoType[];
  tprPromotions: PromoType[];
  nectarPromotions: PromoType[];
  mealDealPromotions: PromoType[];
  nectarMealDealPromotions: PromoType[];
}

export interface IPromoParam<PromoType> {
  promotions: PromoType[] | undefined;
  groupedMealDealsEnabled?: boolean;
}

export const groupPromotionsByType = <PromoType extends ProductTilePromotion | Promotion>({
  promotions,
  groupedMealDealsEnabled,
}: IPromoParam<PromoType>): IPromotionsCategorised<PromoType> => {
  const noPromos = {
    complexPromotions: [],
    tprPromotions: [],
    nectarPromotions: [],
    mealDealPromotions: [],
    nectarMealDealPromotions: [],
  };

  if (!promotions) return noPromos;

  const groupedPromotions = groupBy(promotions, p => `${p.promoGroup}:${p.isNectar}`);
  const hasMultipleMealDealPromos = groupedPromotions[`${PromoGroup.MEAL_DEAL}:false`]?.length > 1;
  const hasMultipleNectarMealDealPromos = groupedPromotions[`${PromoGroup.MEAL_DEAL}:true`]?.length > 1;

  return promotions.reduce((accumulator: IPromotionsCategorised<PromoType>, current: PromoType) => {
    const isTprPromo = temporaryPriceReductionMechanicIds.some(id => id === current.promoMechanicId);
    const isComplexPromo = complexPromoMechanicIds.some(id => id === current.promoMechanicId);
    const isNectarPromo = current.promoMechanicId === PromoMechanicIds.NECTAR_PROMOTION;

    if (groupedMealDealsEnabled) {
      if (current.promoGroup === PromoGroup.MEAL_DEAL) {
        if (current.isNectar && hasMultipleNectarMealDealPromos) {
          return {
            ...accumulator,
            nectarMealDealPromotions: [...accumulator.nectarMealDealPromotions, current],
          };
        }

        if (hasMultipleMealDealPromos) {
          return {
            ...accumulator,
            mealDealPromotions: [...accumulator.mealDealPromotions, current],
          };
        }
      }
    }

    if (isTprPromo) {
      return {
        ...accumulator,
        tprPromotions: [...accumulator.tprPromotions, current],
      };
    }

    if (isComplexPromo) {
      return {
        ...accumulator,
        complexPromotions: [...accumulator.complexPromotions, current],
      };
    }

    if (isNectarPromo) {
      return {
        ...accumulator,
        nectarPromotions: [...accumulator.nectarPromotions, current],
      };
    }

    return accumulator;
  }, noPromos);
};
