import { Product } from "../../domain/product/product.types";
import { DataState } from "../../common/dataState";
import { refetchYnpProducts } from "../../views/NectarDestinationPage/state/actions/refetchYnpProducts/refetchYnpProducts";

export enum YnpActionTypes {
  FETCH_PENDING = "FETCH_PENDING",
  FETCH_SUCCESSFUL = "FETCH_SUCCESSFUL",
  FETCH_NOT_SUCCESSFUL = "FETCH_NOT_SUCCESSFUL",
  OPT_IN_FOR_ALL_PRODUCTS_SUCCESSFUL = "OPT_IN_FOR_ALL_PRODUCTS_SUCCESSFUL",
  OPT_IN_FOR_ALL_PRODUCTS_NOT_SUCCESSFUL = "OPT_IN_FOR_ALL_PRODUCTS_NOT_SUCCESSFUL",
  OPT_IN_FOR_ALL_PRODUCTS_PENDING = "OPT_IN_FOR_ALL_PRODUCTS_PENDING",
  SET_PRODUCTS = "SET_PRODUCTS",
}

export type YnpState = {
  fetchAllOptIns: { optedIn: YnpOptInProduct[]; notOptedIn: YnpOptInProduct[]; dataState: DataState };
  optInForAll: { dataState: DataState };
  products: { dataState: DataState; products: Product[] };
};

export type YnpActions =
  | SetProducts
  | FetchNectarOffersSuccessAction
  | FetchNectarOffersPendingAction
  | FetchNectarOffersErrorAction
  | OptInForAllTypesSuccessAction
  | OptInForAllTypesErrorAction
  | OptInForAllTypesPendingAction
  | ReturnType<typeof refetchYnpProducts>;

export type YnpOptInProduct = {
  offer_id: string;
  sku: string;
  start_date: string;
  expiry_date: string;
  image: string;
};

export type FetchNectarOffersSuccessAction = {
  type: YnpActionTypes.FETCH_SUCCESSFUL;
  payload: {
    optedIn: YnpOptInProduct[];
    notOptedIn: YnpOptInProduct[];
  };
};

export type FetchNectarOffersPendingAction = {
  type: YnpActionTypes.FETCH_PENDING;
};

export type FetchNectarOffersErrorAction = {
  type: YnpActionTypes.FETCH_NOT_SUCCESSFUL;
};

export type OptInForAllTypesSuccessAction = {
  type: YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_SUCCESSFUL;
};

export type OptInForAllTypesErrorAction = {
  type: YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_NOT_SUCCESSFUL;
};

export type OptInForAllTypesPendingAction = {
  type: YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_PENDING;
};

export type SetProducts = {
  type: YnpActionTypes.SET_PRODUCTS;
  payload: {
    dataState: DataState;
    products: Product[];
  };
};

export type FetchYnpOptInResults = {
  opted_in: YnpOptInProduct[];
  not_opted_in: YnpOptInProduct[];
};

export type AcceptYnpOptInResults = {
  updated_offer_ids: string[];
  offer_response_failures: {
    offer_id: string;
    error_message: string;
    error_code: number;
  }[];
};
