import React from "react";
import { Section } from "@jsluna/react";
import { ErrorBoundary, ThemeProvider, Themes } from "@sainsburys-tech/bolt";
import AllComponents, { components, GetComponent } from "@sainsburys-tech/bolt-cms-components";
import { MagnoliaTemplate } from "../../services/contentTypes";
import { SainsburysVocab, VocabProvider } from "@sainsburys-tech/bolt-vocab-provider";

export type MagnoliaRendererProps = {
  template: MagnoliaTemplate;
  className?: string;
  dataTestId?: string;
  onClick?: Function;
};

const Template = ({ template }: { template: MagnoliaTemplate }) => (
  <>
    {template.data.map(templateArea =>
      templateArea.attributes.map((templateComponent, index) => (
        <ErrorBoundary key={`cms-error-boundary__${templateComponent.type}-${index}`}>
          <GetComponent {...templateComponent} />
        </ErrorBoundary>
      ))
    )}
  </>
);

export const MagnoliaRenderer = ({ template, className, dataTestId, onClick }: MagnoliaRendererProps) => (
  <Section className={className} onClick={onClick} data-testid={dataTestId ?? undefined}>
    <ThemeProvider theme={Themes.InternalSainsburysTheme}>
      <VocabProvider vocab={SainsburysVocab}>
        <AllComponents components={components}>
          <ErrorBoundary>
            <Template template={template} />
          </ErrorBoundary>
        </AllComponents>
      </VocabProvider>
    </ThemeProvider>
  </Section>
);
