import { WithDataState } from "../../common/dataState";
import { Recipe } from "../Recipes/Recipe.types";

export interface MealPlannerProps {
  create?: boolean;
}

export interface MealPlansResponse {
  response: MealPlan[];
}
export interface MealPlan {
  id: string;
  name: string;
  featured: boolean;
  recipes: Recipe[];
}

export interface MealPlannerFooterProps {
  price: number;
  reviewCallback: VoidFunction;
}

export interface MealPlannerState extends WithDataState {
  plans: MealPlan[];
  current: MealPlan | null;
}

export enum MealPlannerActions {
  MEAL_PLAN_REQUEST = "MEAL_PLAN_REQUEST",
  MEAL_PLAN_SUCCESS = "MEAL_PLAN_SUCCESS",
  MEAL_PLAN_FAILED = "MEAL_PLAN_FAILED",
  MEAL_PLAN_SET_CURRENT = "MEAL_PLAN_SET_CURRENT",
}
