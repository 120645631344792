import { Method, Result } from "../common/http/request";
import { Optional } from "../common/types";
import { encodeQueryParams } from "../common/http/query";
import { serviceRequest } from "./request";
import { EspotParams } from "../components/Espots";

export type { EspotParams };

export interface Espot {
  data_type: string;
  espot_type: string;
  data: string;
}

const url = "/groceries-api/gol-services/content";

export const fetchEspot = (espotParams: EspotParams, baseUrl: Optional<string> = url): Promise<Result<Espot>> => {
  return serviceRequest<Espot>({ method: Method.GET, url: `${baseUrl}/v1/espot${encodeQueryParams(espotParams)}` });
};
