import { Dispatch, MiddlewareAPI } from "redux";
import { matchPath } from "react-router";
import { formErrorActionCreator, FormNames } from "../../common/forms";
import { LOCATION_CHANGE } from "connected-react-router";
import { createReplaceHistoryAction, NavigationActionTypes } from "../../common/middleware/navigationMiddleware";
import Cookies from "js-cookie";
import { matchesPath, registrationMoreDetails, routes, urls } from "../../routes";
import { State } from "../../common/store";
import {
  CheckPostcodeActions,
  CheckPostcodeActionTypes,
  CheckPostcodeFailureActionType,
  CheckPostcodeSuccessActionType,
} from "./checkPostcode.types";
import { selectIsUserRegistered } from "./checkPostcode.selectors";
import { selectIsEmailVerified } from "../../common/login/login.selectors";
import { LocationChangeAction } from "../../common/types";
import { queryParamValue } from "../../common/http/query";
import { createExternalNavigation } from "../../components/RedirectExternal";

export const checkPostcodeMiddleware =
  (api: MiddlewareAPI<Dispatch, State>) => (next: Dispatch<any>) => async (action: CheckPostcodeActions) => {
    switch (action.type) {
      case CheckPostcodeActionTypes.CHECK_POSTCODE_FAILURE:
        api.dispatch(
          formErrorActionCreator({
            form: FormNames.CHECK_POSTCODE,
            name: "postcode",
            error: (action as CheckPostcodeFailureActionType).error,
            pristine: false,
          })
        );
        break;
      case CheckPostcodeActionTypes.CHECK_POSTCODE_REGISTRATION_SUCCESS:
        // TODO remove
        // api.dispatch(createInternalNavigationAction(urls.REGISTRATION_EMAIL_VERIFICATION));
        break;
      case LOCATION_CHANGE:
        const { payload } = action as LocationChangeAction;
        if (matchesPath(payload.location.pathname, routes.CHECK_POSTCODE_REGISTRATION)) {
          const state = api.getState();
          if (selectIsUserRegistered(state)) {
            break;
          }

          if (!selectIsEmailVerified(state)) {
            api.dispatch(createReplaceHistoryAction(urls.LOGON_VIEW_IDENTIFIER));
            return;
          }
        }
        break;

      case CheckPostcodeActionTypes.CHECK_POSTCODE_SUCCESS:
        const successAction = action as CheckPostcodeSuccessActionType;
        Cookies.set("postcode", successAction.postcode.toUpperCase().replace(/\s+/g, ""));
        const location = api.getState().router.location;

        const isCheckPostcodePath = matchPath(location.pathname, {
          path: routes.CHECK_POSTCODE,
          exact: true,
        });

        if (isCheckPostcodePath) {
          const currentPageUrl = queryParamValue(location.search, "currentPageUrl");
          const isMobileRegistrationFlow =
            currentPageUrl && currentPageUrl.indexOf("RegistrationMoreDetailsFormView") >= 0;
          if (isMobileRegistrationFlow) {
            api.dispatch(createExternalNavigation(registrationMoreDetails(successAction.postcode.toUpperCase())));
            break;
          }
        }

        api.dispatch({
          type: NavigationActionTypes.INTERNAL_NAVIGATION,
          path: urls.CYSM,
        });
        break;
      default:
        break;
    }
    next(action);
  };
