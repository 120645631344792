import { FavouritesCarouselsKey } from "@favourites/Favourites.types";
import { ProductSource } from "../../../../domain/product/product.types";
import { AddToCartOrigin } from "../../../dataLayer/types";
import { NectarDestinationsPageCarouselsKey } from "../../../../views/NectarDestinationPage/types";
import { OfferPageCarouselsKey } from "../../../../views/Offers/types";
import { SimilarProductsCarouselsKey } from "../../../../views/ProductDetails/SimilarProducts/types";
import { HomepageSponsoredProductsCarouselsKey } from "../../../../views/Homepage/Homepage.types";
import { FrequentlyBoughtTogetherCarouselKey } from "../../../../views/ProductDetails/FrequentlyBoughtTogether/types";

export const getOriginFromCarouselKey = (
  productSource?: ProductSource | string,
  carouselKey?: string
): string | undefined => {
  switch (carouselKey) {
    case SimilarProductsCarouselsKey.SIMILAR_PRODUCTS:
      return AddToCartOrigin.SIMILAR_PRODUCTS;
    case HomepageSponsoredProductsCarouselsKey.HOME_PAGE_SPONSORED_PRODUCTS:
      return AddToCartOrigin.HOME_PAGE;
    case NectarDestinationsPageCarouselsKey.NECTAR_FAVOURITES_CAROUSEL:
      return AddToCartOrigin.NECTAR_FAVOURITES;
    case NectarDestinationsPageCarouselsKey.NECTAR_STATIC_SKU_LIST_CAROUSEL:
    case NectarDestinationsPageCarouselsKey.NECTAR_POPULAR_PRODUCTS_CAROUSEL:
      return AddToCartOrigin.NECTAR_TOP;
    case NectarDestinationsPageCarouselsKey.NECTAR_BONUS_POINTS_CAROUSEL:
      return AddToCartOrigin.NECTAR_BONUS;
    case OfferPageCarouselsKey.OFFERS_KRANG_CAROUSEL:
      return AddToCartOrigin.KRANG;
    case OfferPageCarouselsKey.OFFERS_CITRUS_CAROUSEL:
      return AddToCartOrigin.OFFERS_CITRUS;
    case OfferPageCarouselsKey.OFFERS_GREAT_OFFERS_CAROUSEL:
      return productSource?.startsWith("findability") ? AddToCartOrigin.OFFERS_FINDABILITY : AddToCartOrigin.OFFERS_TOP;
    case OfferPageCarouselsKey.OFFERS_FAVOURITES_CAROUSEL:
      return AddToCartOrigin.OFFERS_FAVOURITES;
    case FavouritesCarouselsKey.SEASONAL_FAVOURITE_CAROUSEL:
      return AddToCartOrigin.FAVOURITES_SEASONAL;
    case OfferPageCarouselsKey.OFFERS_YNP_CAROUSEL:
      return AddToCartOrigin.OFFERS_YNP_CAROUSEL;
    case OfferPageCarouselsKey.NECTAR_ZONAL_YNP_CAROUSEL:
      return AddToCartOrigin.NECTAR_ZONAL_YNP_CAROUSEL;
    case FrequentlyBoughtTogetherCarouselKey.FREQUENTLY_BOUGHT_TOGETHER_CAROUSEL:
      return AddToCartOrigin.FREQUENTLY_BOUGHT_TOGETHER_CAROUSEL;
    default:
      return undefined;
  }
};
