import { fetchProductsByUids } from "../../services/product";
import { createCategoryMapViaBasketProducts, createCategoryMapViaOrderItems } from "./createCategoryMap";
import { AmendedOrder } from "../../views/OrderConfirmation/OrderConfirmation.component";
import { fetchSlotReservationByOrderUid } from "../../services/slot";
import { pushConversionEventViaBasketProduct, pushConversionEventViaOrderItems } from "./client";
import { Order } from "../../services/order.types";
import { featureFlags } from "../../common/firebase";
import { getDateFromSlotStartTime } from "../../domain/slot/slotReservation";

async function fetchOrderedProducts(
  skus: string[],
  storeIdentifier?: string,
  slotDate?: string,
  slotDateTime?: string,
  slotType?: string,
  isSlotLocked?: boolean,
  flexiStores?: string[]
) {
  const res = await fetchProductsByUids(
    skus,
    ["DIETARY_PROFILE"],
    true,
    false,
    storeIdentifier,
    slotDate,
    slotDateTime,
    slotType,
    isSlotLocked,
    flexiStores
  );

  if (!res.isSuccess()) {
    return [];
  }

  return res.data.products;
}

export async function sendTaggstarConversionEvent(
  orderDetails: Order,
  amendedOrder?: AmendedOrder,
  isSlotLocked?: boolean
) {
  if (!featureFlags.get("taggstar")) {
    return;
  }

  const slot = await fetchSlotReservationByOrderUid(orderDetails?.order_uid);
  if (!slot.isSuccess()) {
    return;
  }

  const products = await fetchOrderedProducts(
    (amendedOrder?.amendedOrderId
      ? amendedOrder.amendedOrderDiffItems.map(item => item.sku)
      : orderDetails?.order_items?.map(item => item.product.product_uid!)) ?? [],
    orderDetails?.store_identifier,
    getDateFromSlotStartTime(slot.data.slot.start_time),
    slot.data.slot.start_time, // to be used for dateTime
    slot.data.reservation_type,
    isSlotLocked,
    slot.data.flexi_stores
  );

  if (amendedOrder?.amendedOrderId) {
    const categoryMap = createCategoryMapViaBasketProducts(amendedOrder.amendedOrderDiffItems, products);
    pushConversionEventViaBasketProduct(
      amendedOrder.amendedOrderId,
      amendedOrder.amendedOrderDifference,
      amendedOrder.amendedOrderDiffItems,
      categoryMap
    );
  } else if (orderDetails?.order_items) {
    const categoryMap = createCategoryMapViaOrderItems(orderDetails.order_items, products);
    pushConversionEventViaOrderItems(
      orderDetails?.order_uid,
      String(orderDetails?.total),
      orderDetails.order_items,
      categoryMap
    );
  }
}
