import { UTAG_GROUP_CALL_DELAY_FAVOURITES } from "../../common/analytics/analytics";
import isEmpty from "lodash.isempty";
import { DigitalDataEvent, pushEvent } from "./espot.digitaldata";
import { debounce } from "../../common/debounce";
import { postLinkTrackEvent } from "../../common/analytics/digitalData";

enum AnalyticsFunctions {
  trackContentEspotImpression = "trackContentEspotImpression",
  trackContentEspotClick = "trackContentEspotClick",
}

// Wait 1s for more data before calling utag.link

interface DataBuffer {
  add: (data: DigitalDataEvent) => void;
  flush: () => DigitalDataEvent[];
}

export const createBuffer = (): DataBuffer => {
  let data: Array<DigitalDataEvent> = [];

  const add = (newData: DigitalDataEvent) => {
    const isPromotionAlreadyLogged = data.some(
      (item: { [key: string]: string }) => item.promo_spot_id === newData.promo_spot_id
    );
    if (!isPromotionAlreadyLogged) data.push(newData);
  };

  const flush = (): DigitalDataEvent[] => {
    const copy = data.slice();
    data = [];
    return copy;
  };

  return {
    add,
    flush,
  };
};

export const saveImpressionDataToDataLayer = (data: { [key: string]: string }) => {
  const { digitalData } = window as any;
  if (data && digitalData) {
    if (!Array.isArray(digitalData.promoEspotImpression)) {
      digitalData.promoEspotImpression = [];
    }

    const isPromotionAlreadyLogged = digitalData.promoEspotImpression.some(
      (item: { [key: string]: string }) => item.promo_spot_id === data.promo_spot_id
    );
    if (!isPromotionAlreadyLogged) {
      digitalData.promoEspotImpression.push(data);
    }
  }
};

export const readAndSync = (buf: DataBuffer) => {
  const data = buf.flush();
  if (!isEmpty(data)) {
    pushEvent({
      eventAction: "view",
      eventName: "promoEvent",
      promo: data,
    });
  }
  postLinkTrackEvent(window);
};

const buffer = createBuffer();

export const handleTrackingMessage = (
  message: { [key: string]: any },
  delay: number = UTAG_GROUP_CALL_DELAY_FAVOURITES
) => {
  switch (message.type) {
    case AnalyticsFunctions.trackContentEspotImpression:
      buffer.add(message.otherData);
      debounce(readAndSync, delay)(buffer);
      saveImpressionDataToDataLayer(message.otherData);

      break;
    case AnalyticsFunctions.trackContentEspotClick:
      if (!isEmpty(message.otherData)) {
        pushEvent({
          eventAction: "click",
          eventName: "promoEvent",
          promo: message.otherData,
        });
      }
      postLinkTrackEvent(window);
      break;

    default:
      break;
  }
};
