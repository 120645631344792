export enum AppActionTypes {
  APP_MOUNT = "APP_MOUNT",
}

export interface AppMountAction {
  type: AppActionTypes.APP_MOUNT;
}

export const createAppMountAction = (): AppMountAction => ({
  type: AppActionTypes.APP_MOUNT,
});
