import { Dispatch, AnyAction } from "redux";

export enum EspotActionTypes {
  UNAVAILABLE = "UNAVAILABLE",
  AVAILABLE = "AVAILABLE",
}

export const createSetEspotUnavailable = (espotName: string) => (dispatch: Dispatch<AnyAction>) =>
  dispatch({
    type: EspotActionTypes.UNAVAILABLE,
    payload: espotName,
  });

export const createResetEspotAvailability = () => (dispatch: Dispatch<AnyAction>) =>
  dispatch({
    type: EspotActionTypes.AVAILABLE,
  });
