import { AnalyticsEvent, AnalyticsEventType, ExperimentVariantEvent, Product } from "./types";
import { postLinkTrackEvent } from "../../common/analytics/digitalData";

export const createAnalyticsEvent = (eventName: string, product: Product): AnalyticsEvent => ({
  eventName,
  product,
});

export const sendAnalyticsEvent = (event: AnalyticsEvent) => {
  const { digitalData } = window as any;
  if (digitalData && Array.isArray(digitalData.event)) {
    digitalData.event.push(event);
    postLinkTrackEvent(window);
  }
};

export const addExperimentEvent = (event: ExperimentVariantEvent) => {
  const { digitalData } = window as any;
  if (digitalData && !Array.isArray(digitalData.experimentList)) {
    digitalData.experimentList = [];
  }

  const hasExperiment = digitalData.experimentList.some((e: ExperimentVariantEvent) => e.name === event.name);
  if (hasExperiment) {
    return;
  }

  digitalData.experimentList.push(event);
};

// order summary
export const bookASlotAnalyticsEvent = {
  eventName: "book a slot button",
  eventCategory: "order summary",
  eventAction: "book a slot button clicked",
};

export const checkoutButtonAnalyticsEvent = {
  eventName: "checkout button",
  eventCategory: "order summary",
  eventAction: "checkout button clicked",
};

export const continueToCheckoutAnalyticsEvent = {
  eventName: "continue to checkout button",
  eventCategory: "order summary",
  eventAction: "continue to checkout button clicked",
};

// order details
export const productImageClickedAnalyticsEvent = {
  eventName: "product image",
  eventCategory: "order details",
  eventAction: "product image clicked",
};
export const productNameClickedAnalyticsEvent = {
  eventName: "product name",
  eventCategory: "order details",
  eventAction: "product name clicked",
};

// trolley
export const undoRemovedItemAnalyticsEvent = {
  eventName: "undo removed item",
  eventCategory: "trolley",
  eventAction: "undo removed item clicked",
};

export const emptyTrolleyAnalyticsEvent = {
  eventName: "empty trolley confirmation",
  eventCategory: "trolley",
  eventAction: "empty trolley confirmation clicked",
};

export const productNameAnalyticsEvent = {
  eventName: "product name",
  eventCategory: "trolley",
  eventAction: "product name clicked",
};

export const productImageAnalyticsEvent = {
  eventName: "product image",
  eventCategory: "trolley",
  eventAction: "product image clicked",
};

// order confirmation
export const viewOrderAnalyticsEvent = {
  eventName: "view order button",
  eventCategory: "order confirmation",
  eventAction: "view order button clicked",
};
export const goHomeAnalyticsEvent = {
  eventName: "go to groceries home button",
  eventCategory: "order confirmation",
  eventAction: "go to groceries home button clicked",
};

// amend order
export const amendOrderAnalyticsEvent = {
  eventName: "amend order button",
  eventCategory: "amend order",
  eventAction: "amend order button clicked",
};

// mini trolley
export const miniTrolleyCheckoutButtonAnalyticsEvent = {
  eventName: "checkout button",
  eventCategory: "mini trolley",
  eventAction: "checkout button clicked",
};

export const homepageCheckoutButtonAnalyticsEvent = {
  eventName: "checkout button",
  eventCategory: "mini trolley",
  eventAction: "checkout button clicked",
};

export const miniTrolleyBookASlotAnalyticsEvent = {
  eventName: "book a slot button",
  eventCategory: "mini trolley",
  eventAction: "book a slot button clicked",
};

export const miniTrolleyEmptyTrolleyAnalyticsEvent = {
  eventName: "empty trolley confirmation",
  eventCategory: "mini trolley",
  eventAction: "empty trolley confirmation clicked",
};

// checkout summary
export const colleagueDiscountCheckboxAnalyticsEvent = {
  eventName: "colleague discount checkbox",
  eventCategory: "checkout summary",
  eventAction: "colleague discount checkbox clicked",
};

export const removeNectarAnalyticsEvent = {
  eventLabel: "",
  eventName: "remove nectar coupon",
  eventCategory: "checkout summary",
  eventAction: "remove nectar coupon button clicked",
};

export const spendNectarPointsAnalyticsEvent = {
  eventName: "spend nectar points button",
  eventCategory: "checkout summary",
  eventAction: "spend nectar points button clicked",
};

export const verifyNectarCardCheckoutAnalyticsEvent = {
  eventName: "link nectar card button",
  eventCategory: "checkout summary",
  eventAction: "link nectar card button clicked",
};

export const verifyNectarCardAnalyticsEvent = {
  data_event_name: "ev_nectarCardCtaClick",
  data_event_action: "click",
  data_event_category: "My Account",
  data_event_label: "Verify account",
};

export const getNectarAppAnalyticsEvent = {
  data_event_name: "ev_nectarCardCtaClick",
  data_event_action: "click",
  data_event_category: "My Account",
  data_event_label: "Get nectar app",
};

export const joinNectarAnalyticsEvent = {
  data_event_name: "ev_nectarCardCtaClick",
  data_event_action: "click",
  data_event_category: "My Account",
  data_event_label: "Join nectar",
};

export const registerNectarAnalyticsEvent = {
  data_event_name: "ev_nectarCardCtaClick",
  data_event_action: "click",
  data_event_category: "My Account",
  data_event_label: "Register nectar",
};

export const cancelVerifyNectarAnalyticsEvent = {
  data_event_name: "ev_nectarCardCtaClick",
  data_event_action: "click",
  data_event_category: "My Account",
  data_event_label: "Verify account | Cancel",
};

export const editNectarAnalyticsEvent = {
  data_event_name: "ev_nectarCardCtaClick",
  data_event_action: "click",
  data_event_category: "My Account",
  data_event_label: "Edit nectar details",
};

export const cancelEditNectarAnalyticsEvent = {
  data_event_name: "ev_nectarCardCtaClick",
  data_event_action: "click",
  data_event_category: "My Account",
  data_event_label: "Edit nectar details | Cancel",
};

export const removeCouponAnalyticsEvent = {
  eventLabel: "",
  eventName: "remove coupon",
  eventCategory: "checkout summary",
  eventAction: "remove coupon button clicked",
};

export const useCouponAnalyticsEvent = {
  eventName: "use coupon button",
  eventCategory: "checkout summary",
  eventAction: "use coupon button clicked",
};

export const useEGiftCardAnalyticsEvent = {
  eventName: "use e-gift card",
  eventCategory: "checkout summary",
  eventAction: "use e-gift card button clicked",
};

export const addDeliveryInstructionsAnalyticsEvent = {
  eventName: "add delivery instructions confirmation",
  eventCategory: "checkout summary",
  eventAction: "add delivery instructions confirmation clicked",
};

export const addPaymentCardAnalyticsEvent = {
  eventName: "add new payment card button",
  eventCategory: "checkout summary",
  eventAction: "add new payment card button clicked",
};

export const bookSlotAnalyticsEvent = {
  eventName: "book a slot button",
  eventCategory: "checkout summary",
  eventAction: "book a slot button clicked",
};

export const placeOrderAnalyticsEvent = {
  eventName: "place order button",
  eventCategory: "checkout summary",
  eventAction: "place order button clicked",
};

// errors
export const removeCouponAnalyticsError = {
  eventName: "remove coupon error",
  eventCategory: "Error",
  eventLabel: "remove coupon error",
  eventAction: "remove coupon error",
};

export const invalidCouponAnalyticsError = {
  eventName: "invalid coupon error",
  eventCategory: "Error",
  eventLabel: "invalid coupon error",
  eventAction: "invalid coupon error",
};

export const fetchCouponsAnalyticsError = {
  eventName: "fetch coupon error",
  eventCategory: "Error",
  eventLabel: "fetch coupon error",
  eventAction: "fetch coupon error",
};

export const noPaymentCardAnalyticsError = {
  eventName: "no payment card error",
  eventCategory: "Error",
  eventLabel: "no payment card error",
  eventAction: "no payment card error",
};

export const fetchPaymentCardAnalyticsError = {
  eventName: "fetch payment card error",
  eventCategory: "Error",
  eventLabel: "fetch payment card error",
  eventAction: "fetch payment card error",
};

export const paymentAuthorizationAnalyticsError = {
  eventName: "payment authorization error",
  eventCategory: "Error",
  eventLabel: "payment authorization error",
  eventAction: "payment authorization error",
};

export const removeNectarCouponAnalyticsError = {
  eventName: "remove nectar coupon error",
  eventCategory: "Error",
  eventLabel: "remove nectar coupon error",
  eventAction: "remove nectar coupon error",
};

export const fetchNectarCardAnalyticsError = {
  eventName: "fetch nectar coupon error",
  eventCategory: "Error",
  eventLabel: "fetch nectar coupon error",
  eventAction: "fetch nectar coupon error",
};

export const setDeliveryInstructionsAnalyticsError = {
  eventName: "set delivery instructions error",
  eventCategory: "Error",
  eventLabel: "set delivery instructions error",
  eventAction: "set delivery instructions error",
};

export const fetchDeliveryInstructionsAnalyticsError = {
  eventName: "fetch delivery instructions error",
  eventCategory: "Error",
  eventLabel: "fetch delivery instructions error",
  eventAction: "fetch delivery instructions error",
};

export const invalidDeliveryInstructionsCharacterAnalyticsError = {
  eventName: "invalid character delivery instructions error",
  eventCategory: "Error",
  eventLabel: "invalid character delivery instructions error",
  eventAction: "invalid character delivery instructions error",
};

export const fetchSlotAnalyticsError = {
  eventName: "fetch slot error",
  eventCategory: "Error",
  eventLabel: "fetch slot error",
  eventAction: "fetch slot error",
};

export const generalAnalyticsError = {
  eventName: "general error",
  eventCategory: "Error",
  eventLabel: "general error",
  eventAction: "general error",
};

export const fetchBasketAnalyticsError = {
  eventName: "fetch basket error",
  eventCategory: "Error",
  eventLabel: "fetch basket error",
  eventAction: "fetch basket error",
};

export const cancelOrderAnalyticsEvent = {
  eventName: "cancel order button",
  eventCategory: "order details",
  eventAction: "cancel order button clicked",
};

export const readReviewsClickAnalyticsEvent = {
  eventName: "reviews",
  eventCategory: "fetch review action",
  eventLabel: "Users that have interacted with read reviews link",
  eventAction: "pdp read reviews",
};

export const readReviewsTabClickAnalyticsEvent = {
  eventName: "reviews",
  eventCategory: "fetch review action",
  eventLabel: "Users that have interacted with reviews tab",
  eventAction: "pdp read reviews",
};

export const reportAsInappropriateAnalyticsEvent = {
  eventName: "reviews",
  eventCategory: "fetch review action",
  eventLabel: "Users that have interacted with report review",
  eventAction: "pdp report review",
};

export const reviewsPaginationClickAnalyticsEvent = {
  eventName: "reviews",
  eventCategory: "fetch review action",
  eventLabel: "Users that have interacted with reviews pagination",
  eventAction: "pdp read more reviews",
};

export const writeReviewLinkPDPAnalyticsEvent = {
  eventName: "reviews",
  eventCategory: "fetch review action",
  eventLabel: "Users that have interacted with write review link",
  eventAction: "pdp write review",
};

export const writeReviewButtonPDPAnalyticsEvent = {
  eventName: "reviews",
  eventLabel: "Users that have interacted with write review button",
  eventCategory: "fetch review action",
  eventAction: "pdp write review",
};

export const userClickOnPLPProductCardAnalyticsEvent = (product: Product): AnalyticsEvent => ({
  eventName: AnalyticsEventType.PRODUCT_CLICKED,
  product,
});

export const userClickOnReviewsPaginationAnalyticsEvent = (pagination: number): AnalyticsEvent => ({
  eventName: "reviews",
  eventLabel: "Users that have interacted with reviews pagination",
  eventCategory: "fetch review action",
  eventAction: `pdp read more reviews:${pagination}`,
});

export const reviewImpressionsAnalyticsEvent = (reviewImpressions: number): AnalyticsEvent => ({
  eventName: "reviews",
  eventLabel: "Users have seen these reviews",
  eventCategory: "fetch review action",
  eventAction: `pdp review impressions:${reviewImpressions}`,
});

export const readRecipeReviewsClickAnalyticsEvent = {
  eventName: "reviews",
  eventCategory: "fetch review action",
  eventLabel: "Users that have interacted with recipe read reviews link",
  eventAction: "recipe read reviews",
};

export const writeRecipeReviewLinkAnalyticsEvent = {
  eventName: "reviews",
  eventCategory: "fetch review action",
  eventLabel: "Users that have interacted with write recipe review link",
  eventAction: "recipe write review",
};

export const writerecipeReviewButtonAnalyticsEvent = {
  eventName: "reviews",
  eventLabel: "Users that have interacted with write recipe review button",
  eventCategory: "fetch review action",
  eventAction: "recipe write review",
};
