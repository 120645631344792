import React from "react";
import { BasicModalProps } from "../Modal.types";
import { BasicModal } from "../Modal.component";
import { useDispatch } from "react-redux";
import { createCloseModalAction } from "../modal.actions";

type BasicModalWithCloseProps = Omit<BasicModalProps, "onClose">;

export const BasicModalWithClose = (props: BasicModalWithCloseProps) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(createCloseModalAction());
  };

  return <BasicModal {...props} onClose={closeModal} onPrimaryButtonClick={closeModal} />;
};
