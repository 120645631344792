import { combineReducers, AnyAction } from "redux";
import { DataState } from "../../common/dataState";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import {
  RecipeSearchResultsType,
  RecipeSearchState,
  RecipeSearchTabSelectedAction,
  RecipeSearchTabSelectedActionType,
  ResultsTabs,
  SearchPageType,
  SearchResults,
} from "./RecipesSearchResults.types";

const dataState = createDataStateReducer({
  [RecipeSearchResultsType.RECIPE_SEARCH_REQUEST]: DataState.PENDING,
  [RecipeSearchResultsType.RECIPE_SEARCH_SUCCESS]: DataState.SUCCESS,
  [RecipeSearchResultsType.ARTICLE_SEARCH_SUCCESS]: DataState.SUCCESS,
  [RecipeSearchResultsType.SCRAPBOOK_SEARCH_SUCCESS]: DataState.SUCCESS,
  [RecipeSearchResultsType.RECIPE_SEARCH_FAILURE]: DataState.FAILED,
});

const recipeSearchTabReducer = (
  state = ResultsTabs.RESULTS_RECIPES,
  action: RecipeSearchTabSelectedAction
): ResultsTabs => {
  switch (action.type) {
    case RecipeSearchTabSelectedActionType.RECIPE_SEARCH_ARTICLE_TAB:
      return ResultsTabs.RESULTS_ARTICLES;
    case RecipeSearchTabSelectedActionType.RECIPE_SEARCH_SCRAPBOOK_TAB:
      return ResultsTabs.RESULTS_SCRAPBOOKS;
    case RecipeSearchTabSelectedActionType.RECIPE_SEARCH_RECIPE_TAB:
      return ResultsTabs.RESULTS_RECIPES;
    default:
      return state;
  }
};

export const initialState: RecipeSearchState = {
  dataState: DataState.UNKNOWN,
  recipeSearchTabReducer: ResultsTabs.RESULTS_RECIPES,
};

export interface SearchReducer {
  recipeSearch?: SearchResults;
  articleSearch?: SearchResults;
  scrapbookSearch?: SearchResults;
}

const recipeSearchReducer = (state: SearchReducer = {}, action: AnyAction): SearchReducer => {
  switch (action.type) {
    case RecipeSearchResultsType.RECIPE_SEARCH_SUCCESS:
      if (state.recipeSearch) {
        return {
          ...state,
          recipeSearch: {
            ...action.searchResultItems,
            searchResults: [...state.recipeSearch.searchResults, ...action.searchResultItems.searchResults],
          },
        };
      }
      return {
        ...state,
        recipeSearch: { ...action.searchResultItems },
      };

    case RecipeSearchResultsType.ARTICLE_SEARCH_SUCCESS:
      if (state.articleSearch) {
        return {
          ...state,
          articleSearch: {
            ...action.searchResultItems,
            searchResults: [...state.articleSearch.searchResults, ...action.searchResultItems.searchResults],
          },
        };
      }
      return {
        ...state,
        articleSearch: { ...action.searchResultItems },
      };

    case RecipeSearchResultsType.SCRAPBOOK_SEARCH_SUCCESS:
      if (state.scrapbookSearch) {
        return {
          ...state,
          scrapbookSearch: {
            ...action.searchResultItems,
            searchResults: [...state.scrapbookSearch.searchResults, ...action.searchResultItems.searchResults],
          },
        };
      }
      return {
        ...state,
        scrapbookSearch: { ...action.searchResultItems },
      };

    case RecipeSearchResultsType.RECIPE_FILTER_UPDATE:
      if (state.recipeSearch) {
        return {
          ...state,
          recipeSearch: {
            ...state.recipeSearch,
            filters: action.filters,
          },
        };
      }
      return state;

    case RecipeSearchResultsType.RESET_SEARCH_RESULTS:
      return {};

    default:
      return state;
  }
};

export interface PageReducer {
  currentPage?: number;
}

const searchPageReducer = (state: PageReducer = {}, action: AnyAction): PageReducer => {
  switch (action.type) {
    case SearchPageType.SEARCH_PAGE_UPDATE:
      if (state.currentPage) {
        return {
          ...state,
          currentPage: state.currentPage + 1,
        };
      }
      return {
        ...state,
        currentPage: 1,
      };

    case SearchPageType.SEARCH_PAGE_RESET:
      return {};

    default:
      return state;
  }
};

export const recipeSearchResultsReducer = combineReducers({
  dataState,
  recipeSearchReducer,
  searchPageReducer,
  recipeSearchTabReducer,
});
