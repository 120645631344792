import { useEffect, useState } from "react";
import { DataState } from "../../dataState";

type useRenderNothingParams = {
  dataState: DataState;
};

export const useRenderNothing = ({ dataState }: useRenderNothingParams) => {
  const [renderNothing, setRenderNothing] = useState(true);

  useEffect(() => {
    if (dataState === DataState.PENDING || dataState === DataState.SUCCESS) {
      setRenderNothing(false);
    }

    if (dataState === DataState.FAILED || dataState === DataState.UNKNOWN) {
      setRenderNothing(true);
    }
  }, [dataState]);

  return { renderNothing, setRenderNothing };
};
