export enum AddPageActionTypes {
  CARD_CLICKED_CTA = "clicked-card-CTA",
  SLOT_BOOKING = "SlotBooking",
  SLOT_BOOKING_HOME_DELIVERY = "SlotBookingHomeDelivery",
  ORDER_CONFIRM = "OrderConfirm",
  ORDER_HISTORY = "OrderHistory",
  MY_ACCOUNT = "MyAccount",
  COUPON_WALLET = "CouponWallet",
  BASKET = "Basket",
  PAYMENT_CARD = "PaymentCard",
  ADD_PAYMENT_CARD = "AddPaymentCard",
  MY_FAVOURITES = "Favourites",
  PROMOTION_INFORMATION = "PromotionInformation",
  PRODUCT_DETAILS_PAGE = "PDP",
  HOMEPAGE = "Home",
  NECTAR_CARD = "NectarCard",
  MY_PREFERENCES = "MyPreferences",
  PRODUCT_SEARCH_PAGE = "SRP",
  PAYMENT_AUTHORISATION = "PaymentAuthorisation",
}

export type NewRelicClientType = {
  /**
   * Reports a Browser PageAction event to Insights along with a name and attributes.
   *
   * @param name Name or category of the action. Reports to Insights as the actionName attribute.
   * @param attributes JSON object with one or more key/value pairs.
   *   The key will report to Insights as its own PageAction attribute with the specified values.
   * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-page-action
   */
  addPageAction: (name: AddPageActionTypes, attributes?: Record<string, string | number>) => void;
  /**
   * Identifies a browser error without disrupting your app's operations.
   *
   * @param error Provide a meaningful error message that you can use when analyzing data on
   *   New Relic Browser's JavaScript errors page.
   * @param customAttributes An object containing name/value pairs representing custom attributes.
   * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/notice-error
   */
  noticeError: (error: Error | string, customAttributes?: Record<string, string | number | boolean>) => void;
};

class NewRelicClientClass {
  public addPageAction?: NewRelicClientType["addPageAction"];
  public noticeError?: NewRelicClientType["noticeError"];

  constructor() {
    const newRelicClient = window.newrelic;
    this.addPageAction = newRelicClient?.addPageAction;
    this.noticeError = newRelicClient?.noticeError;
  }
}

export const NewRelicClient = new NewRelicClientClass();
