import React from "react";
import { useDispatch } from "react-redux";
import { BasicModal } from "./Modal.component";
import { ModalNames } from "./Modal.types";
import { modalWithWrapper } from "./ModalWrapper.component";
import { changeDeliverySlotActionCreator } from "@favourites/state/Favourites.actions";
import { clearBasketErrorActionCreator } from "../../domain/basket/basket.actions";
import { BasketErrorKeys } from "../../domain/basket/basket.types";

const ScottishLegislationWithWrapper = modalWithWrapper(BasicModal, ModalNames.MODAL_SCOTTISH_LEGISLATION);

type ScottishLegislationModalProps = {
  title: string;
  description: string;
};

export const ScottishLegislationModal = ({ title, description }: ScottishLegislationModalProps) => {
  const dispatch = useDispatch();

  const changeDeliverySlot = () => changeDeliverySlotActionCreator(dispatch);

  const clearScottishLegislationError = () => {
    dispatch(clearBasketErrorActionCreator(BasketErrorKeys.SCOTTISH_LEGISLATION));
  };

  return (
    <ScottishLegislationWithWrapper
      title={title}
      description={description}
      primaryButtonText="Change slot"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={changeDeliverySlot}
      onSecondaryButtonClick={clearScottishLegislationError}
      onClose={clearScottishLegislationError}
    />
  );
};
