export enum OfferFilterTypes {
  CATEGORY = "category",
  CATEGORY_OPERATOR = "category:operator",
  PRICE = "price",
  PROMO_MECHANIC_ID = "mechanicid",
}

export enum OffersDataSource {
  FINDABILITY = "findability",
  FAVOURITES = "favourites",
  KRANG = "krang",
  GREAT_OFFERS = "great-offers",
  CITRUS = "citrus",
  YNP = "your-nectar-prices",
}

export type OffersFilter = { type: OfferFilterTypes; value: string };

export type OfferSectionConfig = {
  title: string;
  dataSource: OffersDataSource;
  zonePath?: string;
  filters?: OffersFilter[];
  metaDescription?: string;
  disableAds?: boolean;
};

export type SectionName = string;

export type OffersConfig = {
  pills: { primary: SectionName[]; secondary: SectionName[] };
  carousels: SectionName[];
  sections: {
    [sectionName: SectionName]: OfferSectionConfig;
  };
};

// This is the default config, actual config will obtained via firebase
export const offersConfig: OffersConfig = {
  pills: {
    primary: ["one-pound", "two-pound", "fruit-veg", "chilled-frozen", "nectar-prices"],
    secondary: ["snacks-treats", "beers-wines-spirits", "health-beauty", "household", "food-cupboard", "meat-fish"],
  },
  carousels: [
    "favourites",
    "top-picks",
    "personalised",
    "your-nectar-prices",
    "sponsored",
    "frozen-event",
    "beers-wines-spirits",
    "fruit-veg",
  ],
  sections: {
    "one-pound": {
      title: "£1 & under",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "one-pound",
      filters: [{ type: OfferFilterTypes.PRICE, value: "£0 - £1" }],
      metaDescription:
        "Shop our latest deals on food for £1 and under at Sainsbury's Groceries and stock up on essentials for less. Anytime Sainsbury’s delivery available.",
    },
    "two-pound": {
      title: "£2 & under",
      dataSource: OffersDataSource.FINDABILITY,
      filters: [{ type: OfferFilterTypes.PRICE, value: "£0 - £2" }],
      zonePath: "two-pound",
      metaDescription:
        "Shop our latest deals on food for £2 and under at Sainsbury's Groceries and stock up on essentials for less. Anytime Sainsbury’s delivery available.",
    },
    "top-picks": {
      title: "Top offers of the week",
      dataSource: OffersDataSource.GREAT_OFFERS,
      zonePath: "top-picks",
      metaDescription:
        "Browse this week's latest online grocery offers; the same great Sainsbury's quality, freshness and choice you'd find in store. Anytime delivery available.",
    },
    "half-price": {
      dataSource: OffersDataSource.FINDABILITY,
      title: "Half price",
      zonePath: "half-price",
      filters: [{ type: OfferFilterTypes.PROMO_MECHANIC_ID, value: "1" }],
    },
    "nectar-prices": {
      dataSource: OffersDataSource.FINDABILITY,
      title: "Nectar prices",
      zonePath: "nectar-prices",
      filters: [{ type: OfferFilterTypes.PROMO_MECHANIC_ID, value: "99" }],
    },
    toprated: {
      title: "Top rated",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "toprated",
      filters: [{ type: OfferFilterTypes.CATEGORY, value: "428991" }],
    },
    "fruit-veg": {
      title: "Fruit & veg",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "fruit-veg",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "12553,12551,12563,210894,13205,210893,13231,12648,13217,199708,185749,440356,13331,13249,13319,13298,185756,13246,13242,13240,13251,13321,13253,13247,13243,13317,13244,13238,146651,13337,210806,13256,13299,199711,210807",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Explore our latest fruit and veg deals at Sainsbury's Groceries. Browse the freshest greens and fruit for less. Anytime Sainsbury’s delivery available.",
    },
    "chilled-frozen": {
      title: "Chilled & frozen",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "chilled-frozen",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "434377,428954,428956,428955,428957,428976,428962,428964,428961,428960,428969,428971,428975,428972,428968,428974,428973,428873,428936,428885,428877,428876,428900,428943,428907,428901,428909,428902,428905,12438,12433,12431,12437,12434,428879,428882,428880,428881,428920,428942,428928,428930,428932,428926,428921,428919,428922,428924,12413,428984,314380,428985,428982,428983,428986,314374,310880,310872,314373,428981,314372,12482,314375,428892,428891,428890,428889,314364,13346,13351,314365,314366,314368,314369,314363,314367,494375,314362,269790,269826,218853,218857,218855,251751,218856,218854,271268,218859,218860,271274,271269,218862,218863,218858,218864,271271,271272,218861,218865,271273,271270,269770,273765,269773,269769,269777,269763,269771,218872,243752,243753,218871,218927,269807,218925,218926,269811,269805,269806,269809,269810,269808,269803,269802,222253,269804,294307,269796,269786,269797,269793,269795,299953,218891,218893,218894,218892,421852,269823,419879,269817,269824,269816,13363,431865,314370,312365,275324,276041,369361,276045,276047,276044,276048,310879,310869,310865,339356,310868,310866,312355,369360,312356,312359,312354,222293,222294,222295,222296,276036,222298,369358,222297,333366,428947,428938,269812,428931,428913,428912,272770,428927,428910,428911,428929,428915,428916,428923,428914,310901,428933,428988,428987,13265",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Shop the latest chilled and frozen food deals across meat, dairy and desserts at Sainsbury's Groceries. Anytime Sainsbury’s delivery available.",
    },
    "snacks-treats": {
      title: "Snacks & treats",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "snacks-treats",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "12332,340935,340928,304968,304979,304966,304964,304965,340932,304960,340934,304962,340933,340931,304963,210849,210842,210851,210841,210843,210845,210850,304959,210848,210844,210846,210847,260760,260758,192705,260759,260771,192701,12501,403383,260761,192706,260772,294360,294356,294365,294363,294364,294355,294362,294358,294366,294357,294361,12472,300020,288325,210902,288322,210899,442366,442368,210898,288324,288321,210901,288328,288323,210903,442367,272769",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Discover new snacks and treats on offer at Sainsbury's Groceries. Stock up on crisps, sweets and savoury snacks with Anytime Sainsbury’s delivery available.",
    },
    "beers-wines-spirits": {
      title: "Beers, wines & spirits",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "beers-wines-spirits",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "12269,12257,318428,12264,278253,275894,275897,275908,12260,275896,458391,12267,273770,12238,458392,458359,12293,458360,12287,12294,458395,12291,12306,41153,12296,12286,199702,458356,458357,299875,12211,458352,458358,458362,299883,209298",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Shop the latest deals on beers, wines and spirits at Sainsbury's Groceries. Stock up on leading brands for less. Anytime Sainsbury’s delivery available.",
    },
    "health-beauty": {
      title: "Toiletries, health & beauty",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "health-beauty",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "448412,404901,404899,458374,12645,12610,450458,450460,275958,275957,275959,295326,275956,275938,275950,275924,275952,367876,367877,367878,295324,275944,275945,295325,275941,275942,275946,275943,275937,275936,275935,275926,275922,275925,275919,275920,275923,275921,247955,247954,12466,247956,247957,12465,404884,404878,450404,404883,404881,247972,450406,428864,428862,428865,428863,247837,448408,448410,247967,289497,448405,247835,283861,247839,247836,275398,275396,275400,275399,275397,12638,12636,247825,247824,247793,247826,247828,247964,247823,283863,379859,247936,275410,275391,247939,275390,275375,275380,247938,275382,450408,450410,450413,12643,12633,448424,12640,12639,448426,12632,448436,450422,450421,448414,448413,404900,448440,404880,448418,247974,450400,450407,404894,404896,404895,404887,404889,404898,404902,404888,450399,404891,448437,450423,247817,247966,304854,450392,450393,400863,450394,450451,450396,247815,283859,328853,247813,247814,400864,450366,450403,450367,448395,403359,450368,450369,450370,450371,450372,450373,450374,450439,450375,450376,450440,450442,450443,450444,310861,450441,450462,450382,450378,450377,450379,450463,403372,403373",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Explore our latest deals on toiletries at Sainsbury's Groceries. Shop leading brands of haircare, bodycare and beauty. Anytime Sainsbury’s delivery available.",
    },
    household: {
      title: "Household",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "household",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "13279,13170,37652,281827,13296,13264,13281,13283,13294,13293,37653,13267,281882,238881,238882,238877,238879,238883,247781,238878,238880,12644,12637,13180,13165,355434,13155,12642,13161,13175,210809,13159,12649,13151,210812,210811,12627,12629,209272,209267,12625,209259,209258,209257,209256,209273,355358,281963,355359,281968,281965,283837,281969,397863,397864,397862,397861,281828,288306,281824,281825,281826,13185,210813",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Discover our latest deals on household essentials at Sainsbury's Groceries. Shop your favourite brands for less. Anytime Sainsbury’s delivery available.",
    },
    "food-cupboard": {
      title: "Food Cupboard",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "food-cupboard",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "13250,13167,13162,13163,12558,333382,12595,283851,208346,13168,12554,208345,13263,283853,243767,12560,13172,13158,13259,318452,318464,318469,318467,318474,318475,13278,12580,318470,318473,172271,450495,318472,235756,318471,172272,318468,318476,448362,281322,281312,448367,383856,448365,281323,281802,383852,281324,447852,281325,281326,281327,448371,448360,281804,448368,448361,281314,448366,448363,231277,231278,231279,13224,13218,13216,13213,13221,231276,208344,13230,267557,267554,267555,267552,267551,283854,267556,267550,267553,267548,267547,267549,267546,235753,235755,283847,235757,235751,235752,235754,13156,218758,208252,13153,208254,208253,12555,12557,12552,283852,12546,12550,281318,281319,281321,383869,400861,281320,243756,299955,243762,243757,243764,269788,243755,243770,244251,243759,318459,318456,318455,318458,318460,318462,320358,318461,318465,314920,13255,314924,314921,314922,314923,314919,13254,208326,55664,208334,208332,55662,12622,55663,12647,208330,185750,208329,12628,55665,12626,208328,208335,12624,208331,305022,305018,305020,305019,276033,272757,276027,276031,276028,276030,276026,276025,276032,230255,230254,283803,230256,230257,283802,275973,275963,275965,275971,275969,275962,275972,275975,275974,275970,275980",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Shop our latest food cupboard deals at Sainsbury's Groceries - with cereal, condiment and sauces on offer now. Anytime Sainsbury’s delivery available.",
    },
    "meat-fish": {
      title: "Meat & fish",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "meat-fish",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "314374,310880,310872,314373,428981,314372,12482,314375,13363,431865,314370,312365,275324,276041,369361,276045,276047,276044,276048,310879,310869,310865,339356,310868,310866,312355,369360,312356,312359,312354,222293,222294,222295,222296,276036,222298,369358,222297,333366,269770,273765,269773,269769,269777,269763,269771,314364,13346,13351,314365,314366,314368,314369,314363,314367,494375,314362,271268,218859,218860,271274,271269,218862,218863,218858,218864,271271,271272,218861,218865,271273,271270",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Explore our meat and fish offers at Sainsbury's Groceries. Browse for chicken, pork and salmon for less. Anytime Sainsbury’s delivery available.",
    },
    "seasonal-treats": {
      title: "Seasonal Treats",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "seasonal-treats",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value: "260758,210849,294364",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Explore our seasonal treat offers at Sainsbury's Groceries. Anytime Sainsbury’s delivery available.",
    },
    "baby-event": {
      title: "Baby Event",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "baby-event",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "323373,323370,323371,323372,323384,323383,323381,323380,323386,323382,323389,208321,458370,323367,323366,458386,323364,458368,323365,323354,323355,323356,323357,323358,275865,12155,12158,62151,12156,458372",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Explore our Baby Event offers at Sainsbury's Groceries. Anytime Sainsbury’s delivery available.",
    },
    "frozen-event": {
      title: "Frozen Event",
      dataSource: OffersDataSource.FINDABILITY,
      zonePath: "frozen-event",
      filters: [
        {
          type: OfferFilterTypes.CATEGORY,
          value:
            "269790,269826,218853,218857,218855,251751,218856,218854,271268,218859,218860,271274,271269,218862,218863,218858,218864,271271,271272,218861,218865,271273,271270,269770,273765,269773,269769,269777,269763,269771,218872,243752,243753,218871,218927,269807,218925,218926,269811,271271,269805,269806,269809,269810,269808,269803,269802,222253,269804,294307,269796,269786,269797,269793,269795,299953,218891,218893,218894,218892,421852,269823,419879,269822,13286,269825,269817,269824,269816",
        },
        { type: OfferFilterTypes.CATEGORY_OPERATOR, value: "OR" },
      ],
      metaDescription:
        "Explore our Frozen Event offers at Sainsbury's Groceries. Anytime Sainsbury’s delivery available.",
    },
    personalised: {
      title: "Offers you may like",
      dataSource: OffersDataSource.KRANG,
      zonePath: "personalised",
      metaDescription:
        "Discover the latest offers tailored to you at Sainsbury's Groceries. Explore deals across food and household with Anytime Sainsbury’s delivery available.",
    },
    favourites: {
      title: "Save on your favourites",
      dataSource: OffersDataSource.FAVOURITES,
    },
    sponsored: {
      title: "Featured Offers",
      dataSource: OffersDataSource.CITRUS,
      zonePath: "featured",
    },
    "your-nectar-prices": {
      title: "Your Nectar Prices,\n personalised to you",
      dataSource: OffersDataSource.YNP,
    },
  },
};
