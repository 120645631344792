import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { fetchHierarchyFailure, fetchHierarchySuccess, mapResponseToState } from "./RecipesBrowse.actions";
import { ContentWrap } from "../ContentWrap";
import { NonMobile } from "../ResponsiveProvider";
import { CollectionItem, DropdownItem, DropdownViewProps, RecipeHierarchy } from "./RecipesBrowse.types";
import { Nullable } from "../../common/types";
import { DataState } from "../../common/dataState";
import { TrackAndNavigate } from "../TrackAndNavigate/TrackAndNavigate.component";
import { useServiceRequest } from "../../services/hooks";
import fetchRecipeHierarchy from "../../services/recipe-hierarchy";
import { useHeaderState } from "../Header/headerState/useHeaderState";

/*
  Background:
    /* Hierarchy is the data structure GOL-UI retrieve from GOL-SERVICES, who in turn sources from Yext.
    /* The "hierarchy" object is a 2 dimensional array of navigation items, each of which might or might not have other navigation items as children.
    /* Those who have children are LevelOne/L1, and those do NOT are LevelTwo/L2
    /* This component introduces the concept of Collection. Collection is essentially a Front-end abstraction that groups navigation items, whether L1 or L2.
    /* Each Collection must have a Dropdown that are a list of L1 and/or L2.
    /* This allows the business to realise short-term features on GOL-UI, such as seasonal recipes' collection, or festive recipes collection.
 */

const DropdownView = ({ items }: DropdownViewProps) => {
  // only display the first 25 if collection has more L2 items.
  const maxAmount = 25;
  const data = items.length > maxAmount ? items.slice(0, maxAmount - 1) : items;

  return (
    <div className="recipes_browse_dropdown_view_container">
      <ContentWrap>
        <div className="recipes_browse__dropdown_view" id="recipes_browse__dropdown_view">
          {data.length > 0 ? (
            <ol className="recipes_browse__dropdown_view__list" data-testid="recipes_browse__dropdown_view__list">
              {data.map(i => {
                return (
                  <li key={`dropdown_item_${i.id}`}>
                    <TrackAndNavigate
                      eventData={{
                        eventName: `RecipesNavLinkClick`,
                        eventCategory: "Recipes",
                        eventAction: "Click navigation link",
                        eventLabel: String(i.tag),
                      }}
                      url={i.slug}
                      className="analytics"
                    >
                      <a href={i.slug}> {i.title} </a>
                    </TrackAndNavigate>
                  </li>
                );
              })}
            </ol>
          ) : null}
        </div>
      </ContentWrap>
    </div>
  );
};

const initDropDownItem: DropdownItem = {
  id: "",
  title: "",
  description: "",
  slug: "",
  tag: "",
};

const initCollectionItem: CollectionItem = {
  id: "",
  title: "",
  description: "",
  tag: "",
  items: [],
  hasDropdown: false,
};

const initDropDownView: DropdownViewProps & { showDropdown: boolean } = {
  showDropdown: false,
  items: [initDropDownItem],
};

export const RecipesBrowse = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [dropdownView, updateDropdownView] = useState(initDropDownView);
  const [selected, updateSelected] = useState<CollectionItem>(initCollectionItem);
  const [state, updateState] = useState<Nullable<RecipeHierarchy>>(null);
  const { showMinimised } = useHeaderState();

  const { data, status } = useServiceRequest(() => fetchRecipeHierarchy(), []);

  // close dropdown before going to a new page
  useEffect(() => {
    updateDropdownView(dropdownView => {
      return { ...dropdownView, showDropdown: false };
    });
  }, [location]);

  useEffect(() => {
    const setFromClickEvent = (e: Event) => {
      // close dropdown if user clicks on other elements than the L1 items
      if (e.target instanceof Element && e.target.className !== "recipes_browse__item-link") {
        updateDropdownView(dropdownView => {
          return { ...dropdownView, showDropdown: false };
        });
      }
    };

    window.addEventListener("click", setFromClickEvent);
    if (data && status === DataState.SUCCESS) {
      data.Hierarchy.sort((a, b) => parseInt(a.position) - parseInt(b.position));
      const recipesBrowse: Nullable<RecipeHierarchy> = mapResponseToState(data);
      if (!recipesBrowse) {
        dispatch(fetchHierarchyFailure([]));
        return;
      }
      dispatch(fetchHierarchySuccess(recipesBrowse));
      updateState(recipesBrowse);
    }

    if (status === DataState.FAILED) {
      dispatch(fetchHierarchyFailure([]));
    }
    return () => {
      window.removeEventListener("click", setFromClickEvent);
    };
  }, [dispatch, status, data]);

  if (state === null || status !== DataState.SUCCESS) {
    return null;
  }

  return (
    <NonMobile>
      <div className={`recipes_browse_container${showMinimised ? " minimized" : ""}`}>
        <ContentWrap soft>
          <div className="recipes_browse" id="recipe_browse">
            {state && (
              <ul className="recipes_browse__items_list">
                {state
                  .filter(d => d.hasDropdown)
                  .map(d => (
                    <li
                      className="recipes_browse__items_list__item"
                      id={`recipes_browse_option_${d.id}`}
                      key={`recipes_browse_option_${d.id}`}
                    >
                      {d.slug ? (
                        <TrackAndNavigate
                          eventData={{
                            eventName: `RecipesNavLinkClick`,
                            eventCategory: "Recipes",
                            eventAction: "Click navigation link",
                            eventLabel: String(d.tag),
                          }}
                          url={d.slug}
                          className="analytics"
                        >
                          <a href={d.slug}> {d.title} </a>
                        </TrackAndNavigate>
                      ) : (
                        <button
                          onClick={() => {
                            updateSelected(d);
                            updateDropdownView({
                              items: d.items,
                              showDropdown: !dropdownView.showDropdown || d.id !== selected.id,
                            });
                          }}
                          className="recipes_browse__item-link"
                        >
                          {d.title}
                        </button>
                      )}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </ContentWrap>
        {dropdownView.showDropdown ? <DropdownView items={dropdownView.items} /> : null}
      </div>
    </NonMobile>
  );
};

export default RecipesBrowse;
