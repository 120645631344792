import { DataState, WithDataState } from "../../common/dataState";

export interface SlotReservation {
  reservationType: SlotReservationType;
  reservationExpired: boolean;
  reservationDate: string;
  reservationStartTime: string;
  reservationEndTime: string;
  reservationTimeSlot: string;
  reservationIsGreen: boolean;
  storeIdentifier: string;
  isAlcoholRestrictedStore: boolean;
  reservationUntil: string;
  reservationPostCode: string;
  orderId?: string;
  isSameDay: boolean;
  reservationAddressNickname: string;
  slotPriceFormatted: string;
  slotPrice?: number;
  slotQualifiedPrice?: number;
  isDeliveryPassApplicable: boolean;
  deliveryAddress?: DeliveryAddress;
  region: string;
  slotType?: string;
  postcode: string;
  locationUid?: string;
  clickAndCollectLocation?: ClickAndCollectLocation;
  isExpired?: boolean;
  reservationFlexiStores?: string[];
  isXmasSlot: boolean;
}

export interface DeliveryAddress {
  addressUid: string;
  nickname: string;
  street: string;
  buildingName: string;
  buildingNumber: string;
  flatNumber: string;
  town: string;
  county: string;
  postcode: string;
}

export interface ClickAndCollectLocation {
  name: string;
  postcode: string;
}

export interface SlotReservationState extends WithDataState {
  slotReservation: SlotReservation;
  originalSlotReservation: SlotReservation | null;
  originalSlotReservationDataState: DataState;
}

export enum SlotReservationType {
  NONE = "NONE",
  DELIVERY = "DELIVERY",
  CLICK_AND_COLLECT = "CLICK_AND_COLLECT",
}

export enum SlotReservationTypeFormatted {
  DELIVERY = "Delivery",
  CLICK_AND_COLLECT = "Collection",
  NONE = "",
}

export enum UnqualifiedSlotPrice {
  DELIVERY = 7,
  COLLECTION = 4,
}

export const QualifiedCollectionPrice = 0.5;

export const QualifiedCollectionPriceInPennies = 50;

export enum QualifyingBasketAmount {
  DELIVERY = 40,
  COLLECTION = 25,
  SAME_DAY_COLLECTION = 40,
}

export enum SameDaySlotPrice {
  DELIVERY = 9,
  COLLECTION = 6,
  QUALIFIED_COLLECTION = 2,
}

export enum SlotReservationActionTypes {
  FETCH_SLOT_RESERVATION_REQUEST = "FETCH_SLOT_RESERVATION_REQUEST",
  FETCH_SLOT_RESERVATION_SUCCESS = "FETCH_SLOT_RESERVATION_SUCCESS",
  FETCH_SLOT_RESERVATION_FAILURE = "FETCH_SLOT_RESERVATION_FAILURE",
  FETCH_ORIGINAL_SLOT_RESERVATION_REQUEST = "FETCH_ORIGINAL_SLOT_RESERVATION_REQUEST",
  FETCH_ORIGINAL_SLOT_RESERVATION_SUCCESS = "FETCH_ORIGINAL_SLOT_RESERVATION_SUCCESS",
  FETCH_ORIGINAL_SLOT_RESERVATION_FAILURE = "FETCH_ORIGINAL_SLOT_RESERVATION_FAILURE",
  SET_RESERVATION_EXPIRED = "SET_RESERVATION_EXPIRED",
}

export type SetReservationExpiredAction = {
  type: SlotReservationActionTypes;
  slotReservation: SlotReservation;
};

export type FetchSlotReservationRequestAction = {
  type: SlotReservationActionTypes;
};

export type FetchSlotReservationFailureAction = {
  type: SlotReservationActionTypes;
  error: string;
};

export type FetchSlotReservationSuccessAction = {
  type: SlotReservationActionTypes;
  slotReservation: SlotReservation;
};

export type FetchOriginalSlotReservationRequestAction = {
  type: SlotReservationActionTypes;
};

export type FetchOriginalSlotReservationFailureAction = {
  type: SlotReservationActionTypes;
  error: string;
};

export type FetchOriginalSlotReservationSuccessAction = {
  type: SlotReservationActionTypes;
  slotReservation: SlotReservation | null;
};

export type CachedSlotReservation = {
  region: string;
  slotDate?: string;
  slotDateTime?: string;
  slotEndTime?: string;
  storeIdentifier: string;
  flexiStores?: string[];
  postCode?: string;
  reservationType?: string;
  storeName?: string;
  qualifiedPrice?: string;
};

export type SlotPriceData = {
  basketSubTotal: number;
  reservationType: SlotReservationType;
  qualifiedSlotPrice: number | undefined;
  isDeliveryPassApplicable: boolean;
  isSameDay: boolean;
};
