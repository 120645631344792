export enum FormActionTypes {
  FORM_CREATE = "FORM_CREATE",
  FORM_CHANGE = "FORM_CHANGE",
  FORM_ERROR = "FORM_ERROR",
  FORM_WARNING = "FORM_WARNING",
  FORM_RESET = "FORM_RESET",
  FORM_RESET_ERRORS = "FORM_RESET_ERRORS",
  FORM_RESET_WARNINGS = "FORM_RESET_WARNINGS",
  FIELD_TOUCH = "FIELD_TOUCH",
}

export type FormCreateActionType = {
  type: FormActionTypes.FORM_CREATE;
  form: string;
  state: {
    values: {};
    pristine: {};
    fieldErrors: {};
    fieldWarnings: {};
    formErrors: string[];
  };
};

export enum FormNames {
  PROGRESSIVE_REGISTRATION = "progressiveRegistration",
  NEW_PROGRESSIVE_REGISTRATION = "newProgressiveRegistration",
  CHECK_POSTCODE = "checkPostcode",
}

export type FormChangeActionType = {
  type: FormActionTypes.FORM_CHANGE;
  form: FormNames;
  name: string;
  value: string | boolean;
};

export type FormErrorActionType = {
  type: FormActionTypes.FORM_ERROR;
  form: FormNames;
  name: string;
  error: string;
  pristine?: boolean;
};

export type FormWarningActionType = {
  type: FormActionTypes.FORM_ERROR;
  form: FormNames;
  name: string;
  warning: string;
};

export type FormResetActionType = {
  type: FormActionTypes.FORM_RESET;
  form: string;
};

export type FormResetErrorOrWarningssActionType = {
  type: FormActionTypes.FORM_RESET_ERRORS;
  form: string;
};

export type FormFieldTouchActionType = {
  type: FormActionTypes.FIELD_TOUCH;
  form: FormNames;
  names: string[];
};

export const formCreateActionCreator = ({ form, state }: Partial<FormCreateActionType>) => ({
  type: FormActionTypes.FORM_CREATE,
  form,
  state,
});

export const formChangeActionCreator = ({ form, name, value }: Partial<FormChangeActionType>) => ({
  type: FormActionTypes.FORM_CHANGE,
  form,
  name,
  value,
});

export const formErrorActionCreator = ({ form, name, error, pristine }: Partial<FormErrorActionType>) => ({
  type: FormActionTypes.FORM_ERROR,
  form,
  name,
  error,
  pristine,
});

export const formWarningActionCreator = ({ form, name, warning }: Partial<FormWarningActionType>) => ({
  type: FormActionTypes.FORM_WARNING,
  form,
  name,
  warning,
});

export const formResetActionCreator = ({ form }: Partial<FormResetActionType>) => ({
  type: FormActionTypes.FORM_RESET,
  form,
});

export const formResetErrorsActionCreator = ({ form }: Partial<FormResetErrorOrWarningssActionType>) => ({
  type: FormActionTypes.FORM_RESET_ERRORS,
  form,
});

export const formResetWarningsActionCreator = ({ form }: Partial<FormResetErrorOrWarningssActionType>) => ({
  type: FormActionTypes.FORM_RESET_WARNINGS,
  form,
});

export const formFieldTouchActionCreator = ({ form, names }: Partial<FormFieldTouchActionType>) => ({
  type: FormActionTypes.FIELD_TOUCH,
  form,
  names,
});

type FormStateOption = {
  value: string | boolean;
  error?: string;
  warning?: string;
  validator?: (value: FormValue) => boolean;
};

export type FormStateOptions = { [key: string]: FormStateOption };

export const createFormState = (options: FormStateOptions) => ({
  values: Object.keys(options).reduce((acc, key) => {
    acc[`${key}`] = options[`${key}`].value;
    return acc;
  }, {} as any),
  pristine: Object.keys(options).reduce((acc, key) => {
    acc[`${key}`] = true;
    return acc;
  }, {} as any),
  fieldErrors: Object.keys(options).reduce((acc, key) => {
    acc[`${key}`] = options[`${key}`].error || "";
    return acc;
  }, {} as any),
  fieldWarnings: Object.keys(options).reduce((acc, key) => {
    acc[`${key}`] = options[`${key}`].warning || "";
    return acc;
  }, {} as any),
  formErrors: [],
});

export type FormValue = string | boolean;

export type validator = (value: FormValue) => boolean;

export const isNotEmpty = (value: FormValue) => Boolean(value);

export const defaultValidator: validator = (value: FormValue) => isNotEmpty(value);

export const selectValidator = (formConfig: FormStateOptions, name: string): validator => {
  const fieldConfig = formConfig[`${name}`];
  const hasValidator = fieldConfig && typeof fieldConfig.validator === "function";
  return hasValidator ? fieldConfig.validator! : defaultValidator;
};
