import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { CreateSearchProductSuccessActionType, SearchActionType } from "./search.types";
import { fetchBelowGridProductAdsAction } from "./search.actions";
import { isBrowseRoute } from "../Browse/Browse.utils";
import { isBrandedPage } from "../BrandedPage/BrandedPage.utils";
import { isEventsRoute, isFeaturesRoute } from "../EventsFeatures/EventsFeatures.utils";
import { isMealDealRoute } from "../MealDealBuilder/utils";

export const searchResultsMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) =>
  (next: Dispatch<any>) =>
  async (action: CreateSearchProductSuccessActionType) => {
    switch (action.type) {
      case SearchActionType.SEARCH_PRODUCTS_SUCCESS:
        // We aren't showing bottom grid sponsored products on Browse or Branded Pages
        const { pathname } = window.location;
        if (
          isBrowseRoute(pathname) ||
          isBrandedPage(pathname) ||
          isEventsRoute(pathname) ||
          isFeaturesRoute(pathname) ||
          isMealDealRoute(pathname)
        )
          break;
        api.dispatch(fetchBelowGridProductAdsAction(action.products.products));
        break;
      default:
        break;
    }
    next(action);
  };
