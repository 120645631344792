import { Method, Result } from "../common/http/request";
import { Optional } from "../common/types";
import { encodeQueryParams } from "../common/http/query";
import { serviceRequest } from "./request";
import { Order, OrdersSummary, OrderStatus, PaginatedOrders } from "./order.types";

const url = "/groceries-api/gol-services/order";

export const fetchOrders = (
  pageNumber: number,
  pageSize: number,
  baseUrl: Optional<string> = url
): Promise<Result<PaginatedOrders>> => {
  const params = {
    page_size: pageSize,
    page_number: pageNumber,
  };

  return serviceRequest<PaginatedOrders>({
    method: Method.GET,
    url: `${baseUrl}/v1/order${encodeQueryParams(params)}`,
  });
};

export const fetchOrdersSummary = (
  pageNumber: number,
  pageSize: number,
  baseUrl: Optional<string> = url
): Promise<Result<OrdersSummary>> => {
  const params = {
    page_size: pageSize,
    page_number: pageNumber,
  };
  return serviceRequest<OrdersSummary>({
    method: Method.GET,
    url: `${baseUrl}/v1/order/summary${encodeQueryParams(params)}`,
  });
};

export const fetchOrderById = (
  orderId: string,
  placed: Optional<boolean> = false,
  baseUrl: Optional<string> = url
): Promise<Result<Order>> => {
  return serviceRequest<Order>({ method: Method.GET, url: `${baseUrl}/v1/order/${orderId}?placed=${placed}` });
};

export const stopAmendingOrder = (baseUrl: Optional<string> = url): Promise<Result<void>> => {
  return serviceRequest<void>({ method: Method.DELETE, url: `${baseUrl}/v1/order/amend` });
};

export const startAmendingOrder = (orderUid: string, baseUrl: Optional<string> = url): Promise<Result<void>> => {
  return serviceRequest<void>({ method: Method.POST, url: `${baseUrl}/v1/order/${orderUid}/amend` });
};

export const fetchOrderStatus = (baseUrl: Optional<string> = url): Promise<Result<OrderStatus>> => {
  return serviceRequest<OrderStatus>({ method: Method.GET, url: `${baseUrl}/v1/order/status` });
};

export const cancelOrder = (orderId: string, baseUrl: Optional<string> = url): Promise<Result<void>> => {
  return serviceRequest<void>({ method: Method.DELETE, url: `${baseUrl}/v1/order/${orderId}` });
};
