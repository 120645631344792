import { AnyAction, combineReducers } from "redux";
import { DataState } from "../../common/dataState";
import { YourRecipesFavouritesActions, YourRecipesFavouritesState } from "./YourRecipesFavourites.types";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";

export const initialState: YourRecipesFavouritesState = {
  dataState: DataState.UNKNOWN,
  favourites: {
    recipes: [],
    totalCount: 0,
  },
};

const status = createDataStateReducer({
  [YourRecipesFavouritesActions.YOUR_RECIPES_FAVOURITES_SUCCESS]: DataState.SUCCESS,
  [YourRecipesFavouritesActions.YOUR_RECIPES_FAVOURITES_REQUEST]: DataState.PENDING,
  [YourRecipesFavouritesActions.YOUR_RECIPES_FAVOURITES_FAILED]: DataState.FAILED,
  [YourRecipesFavouritesActions.YOUR_RECIPES_FAVOURITE_DELETED_SUCCESS]: DataState.SUCCESS,
  [YourRecipesFavouritesActions.YOUR_RECIPES_FAVOURITE_DELETED_REQUEST]: DataState.PENDING,
  [YourRecipesFavouritesActions.YOUR_RECIPES_FAVOURITE_DELETED_FAILED]: DataState.FAILED,
});

function yourRecipesFavouritesReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case YourRecipesFavouritesActions.YOUR_RECIPES_FAVOURITES_SUCCESS:
      return action.payload;
    case YourRecipesFavouritesActions.REMOVE_DELETED_RECIPE_FROM_STATE:
      const removedRecipeId = action.payload;
      return state.favourites.recipes.filter(recipe => recipe.id !== removedRecipeId);
    default:
      return state;
  }
}

export const recipeFavouritesReducer = combineReducers({
  dataState: status,
  favourites: yourRecipesFavouritesReducer,
});
