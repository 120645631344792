import { State } from "../../../common/store";
import { selectErrorCode } from "../../../components/Error/error.selectors";
import { DataState } from "../../../common/dataState";
import { selectProduct } from "../../../components/ProductTile/ProductTile.selectors";
import { createProductsSelector } from "../../product.selectors";
import { SortOptionSelectField } from "@favourites/Favourites.types";
import { createSelector, createSelectorCreator, defaultMemoize } from "reselect";
import { ProductCoordMap } from "../../../common/scrollIntoView";
import { ProductErrorCode, SortOption } from "../../../services/product.types";
import { FilterProduct, ProductsViews, ProductUid } from "../../../domain/product/product.types";
import { SlotReservationState } from "../../../domain/slot/slotReservation.types";
import { InterleavedEspot } from "../../../components/Espots/Espot.types";
import isEqual from "lodash.isequal";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectIsAlcoholRestrictedStore = (state: State): boolean => {
  if (state.slotReservation) {
    const slot = state.slotReservation as SlotReservationState;
    return slot.slotReservation && slot.slotReservation.isAlcoholRestrictedStore;
  }
  return false;
};

export const selectIsOffersAvailable = (state: State): boolean => {
  if (state.favourites) {
    return state.favourites.isOffersOnly;
  }
  return false;
};

export const isScrolledIntoView = (state: State, productUid: string): boolean => {
  return state.router.location.hash.replace("#", "") === productUid;
};

export const createRecommendedProductsUidsSelector = createDeepEqualSelector(
  [(state: State) => state.products.views[ProductsViews.RECOMMENDATIONS]],
  (recommendations: ProductUid[]) => recommendations
);

export const isFavouritePending = (state: State): boolean => state.favourites.dataState === DataState.PENDING;

export const selectIsRecommendationPending = (state: State): boolean =>
  state.recommendations.dataState === DataState.PENDING;

export const selectAreFavouritesReady = (state: State): boolean => state.favourites.dataState === DataState.SUCCESS;

export const selectAreSeasonalFavouritesReady = (state: State): boolean =>
  state.favourites.seasonalDataState === DataState.SUCCESS;

export const selectIsFavouritesError = (state: State): boolean => {
  return (
    state.favourites.dataState === DataState.FAILED ||
    (state.favourites.dataState === DataState.SUCCESS && state.favourites.errors.length > 0)
  );
};

export const selectIsDietaryProfileError = (state: State): boolean =>
  !!(
    state.favourites.errors &&
    state.favourites.errors.filter(error => error.code === ProductErrorCode.DIETARY_PROFILE_ERROR).length > 0
  );

export const selectErrCode = (state: State): string => {
  const errorCode = selectErrorCode(state);
  return errorCode !== null ? errorCode.toString() : "";
};

export const selectIsFavourite = (state: State, productUid: string): boolean => {
  const product = selectProduct(state, productUid);
  return product ? Boolean(product.favouriteUid) : false;
};

export const selectIsSeasonalFavourite = (state: State, productUid: string): boolean => {
  if (state.products?.views[ProductsViews.SEASONAL_FAVOURITES] !== undefined) {
    const seasonal = createProductsSelector(state, ProductsViews.SEASONAL_FAVOURITES);

    return seasonal.indexOf(productUid) < 0 ? false : true;
  }
  return false;
};

export const selectSeasonalFavouritePosition = (state: State, productUid: string): number => {
  if (state.products.views[ProductsViews.SEASONAL_FAVOURITES] !== undefined) {
    const seasonal = createProductsSelector(state, ProductsViews.SEASONAL_FAVOURITES);
    return seasonal.indexOf(productUid) < 0 ? 0 : seasonal.indexOf(productUid) + 1;
  }
  return 0;
};

export const createProductCoordMapSelector = createDeepEqualSelector(
  [(state: State) => state.favourites.productCoordMap],
  (productCoordMap: ProductCoordMap) => {
    return productCoordMap;
  }
);

export const selectIsFavouritesModalDisabled = (state: State): boolean => state.favourites.isFavouritesModalDisabled;

export const isFavouritesModalFirstInteraction = (state: State): boolean =>
  state.favourites.isFavouritesModalFirstInteraction;

export const selectActiveSort = (state: State): string | undefined =>
  state.products.controls && state.products.controls.activeSort;

export const selectSortOptions = (state: State): SortOption[] =>
  state.products.controls ? state.products.controls.sortOptions : [];

export const selectSortOptionsCached = createSelector(selectSortOptions, (sortOptions): SortOptionSelectField[] =>
  sortOptions.map(({ value, display }) => ({ value, label: display }))
);

export const selectFilters = (state: State): FilterProduct[] | undefined =>
  state.products.controls && state.products.controls.filters;

export const selectFilterOffers = (state: State): FilterProduct | undefined => {
  const filters = selectFilters(state);

  if (!filters) {
    return undefined;
  }

  const offers = filters.filter(item => {
    item.values = item.values.filter(y => y.value === "Offers");
    return item.values.length > 0;
  });

  return offers ? offers.pop() : undefined;
};

export const selectPageActive = (state: State): number | undefined =>
  state.products.controls && state.products.controls.pageNumber;

export const selectPageActiveCached = createSelector(selectPageActive, (pageActive): number =>
  pageActive ? pageActive : 1
);

export const selectPageLast = (state: State): number | undefined =>
  state.products.controls && state.products.controls.pageLast;

export const selectPageLastCached = createSelector(selectPageLast, (pageLast): number => (pageLast ? pageLast : 1));

export const selectPageSize = (state: State): number | undefined =>
  state.products.controls && state.products.controls.pageSizeActive;

export const selectPageSizeCached = createSelector(selectPageSize, (pageSize): number => (pageSize ? pageSize : 1));

export const selectPageSizeOptions = (state: State): number[] | undefined =>
  state.products.controls && state.products.controls.pageSizeOptions;

export const selectPageSizeOptionsCached = createSelector(selectPageSizeOptions, (pageSizeOptions): number[] =>
  pageSizeOptions ? pageSizeOptions : []
);

// Ensure in-grid espots receive slotReservation
export const createEspotsSelector = createDeepEqualSelector(
  [
    (state: State) => state.favourites.gridEspots,
    (state: State) =>
      state.slotReservation.dataState === DataState.SUCCESS || state.slotReservation.dataState === DataState.FAILED,
  ],
  (gridEspots: InterleavedEspot[], isDataStateReady: boolean) => (isDataStateReady ? gridEspots : undefined)
);
