import { WithDataState } from "../../common/dataState";

export interface ReserveSlotRequestAction {
  type: SlotReservationActions.RESERVE_SLOT_REQUEST;
}

export interface ReserveSlotSuccessAction {
  type: SlotReservationActions.RESERVE_SLOT_SUCCESS;
}

export interface ReserveSlotFailureAction {
  type: SlotReservationActions.RESERVE_SLOT_FAILURE;
  message: string;
}

export interface SetSlotReferrerAction {
  type: SlotReservationActions.SET_SLOT_REFERRER;
  data: {
    referrer: string;
  };
}

export enum SlotReservationActions {
  RESERVE_SLOT_REQUEST = "RESERVE_SLOT_REQUEST",
  RESERVE_SLOT_SUCCESS = "RESERVE_SLOT_SUCCESS",
  RESERVE_SLOT_FAILURE = "RESERVE_SLOT_FAILURE",
  SET_SLOT_REFERRER = "SET_SLOT_REFERRER",
}

export interface ReserveSlotState extends WithDataState {
  failureMessage?: string;
}
