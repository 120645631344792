import React from "react";
import clsx from "clsx";
import { ArrowRight } from "@jsluna/icons";
import { MissedPromotion } from "./MultibuyPromotionList.component";
import { TrackClickConnected } from "@components/TrackClick/index";
import { Link } from "@sainsburys-tech/fable";

type MissedSinglePromotionProps = {
  missedPromotion: MissedPromotion;
  productUid: string;
};

export const MissedSinglePromotion = ({ missedPromotion, productUid }: MissedSinglePromotionProps) => {
  return (
    <TrackClickConnected event="missed_offer" promotion={missedPromotion.items[0]} productUid={productUid}>
      <Link
        href={missedPromotion.items[0].url}
        className={clsx("missed-multibuy", {
          "missed-multibuy--nectar": missedPromotion.isNectar,
        })}
        data-testid="pt-mini-promotion-missed-flag"
        isStandalone
      >
        <div>
          <strong>Missed Offer:&nbsp;</strong>
          {missedPromotion.items[0].strapLine}
        </div>
        <ArrowRight className="missed-multibuy__arrow" />
      </Link>
    </TrackClickConnected>
  );
};
