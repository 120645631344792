import React from "react";
import { Button } from "@sainsburys-tech/fable";
import { ArrowLeft, Cancel } from "@sainsburys-tech/icons";
import { GOLLink } from "../../../GOLLink";
import { urls } from "../../../../routes";
import { HeaderSectionProps } from "./types";

export const HeaderSection: React.FC<HeaderSectionProps> = ({ isLoggedIn, onClose, isInSubmenu, title, onBack }) => {
  function handleBack(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    (e.target as HTMLButtonElement)?.blur?.();
    onBack();
  }

  const leftContent = () => {
    if (isInSubmenu) {
      return (
        <Button
          icon={<ArrowLeft />}
          onClick={handleBack}
          variant="tertiary"
          size="md"
          testid="mobile-meganav-back-btn"
          id="headerSection__back"
          data-scheme="ds-sainsburys-cta--meganav-back"
          isFullWidth
        >
          Back
        </Button>
      );
    } else if (isLoggedIn) {
      return (
        <span id="headerSection__item" data-testid="mobile-meganav-browse">
          {title ?? "Browse"}
        </span>
      );
    } else {
      return (
        <GOLLink
          to={urls.LOGON_VIEW_IDENTIFIER}
          external
          data-testid="mobile-meganav-login-register"
          id="headerSection__item"
        >
          Log in / Register
        </GOLLink>
      );
    }
  };

  return (
    <div className="headerSection">
      {leftContent()}
      <Button
        hasHiddenText
        icon={<Cancel />}
        onClick={onClose}
        variant="tertiary"
        size="md"
        testid="mobile-meganav-close-btn"
        className="headerSection__close"
        id="headerSection__close"
        data-scheme="ds-sainsburys-cta--meganav-close"
      >
        Close
      </Button>
    </div>
  );
};
