import { AppThunkAction, AppThunkDispatch } from "src/common/types";
import {
  removeUnwantedRecommendationProduct,
  updateFirstFavouritesRecommendationsSuccess,
} from "@favourites/state/Favourites.actions";
import { ProductActionType, ProductClickedAction, PromotionClickedAction } from "./productTile.types";
import { Product, Promotion } from "../../domain/product/product.types";

export const productClickedActionCreator = (product: Product): ProductClickedAction => ({
  type: ProductActionType.PRODUCT_CLICKED,
  product,
});

export const promotionClickedActionCreator = (promotion: Promotion): PromotionClickedAction => ({
  type: ProductActionType.PROMOTION_CLICKED,
  promotion,
});

export const removeProductActionPending = () => ({
  type: ProductActionType.REMOVE_PRODUCT_PENDING,
});

export const appendToStorage = (name: string, data: string) => {
  var old = localStorage.getItem(name);
  if (old === null) {
    old = "";
  }
  localStorage.setItem(name, `${old},${data}`);
};

export const removeProduct =
  (productUid: string): AppThunkAction =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(removeProductActionPending());

    appendToStorage("firstFavouritesIgnoredProducts", productUid);

    dispatch(removeUnwantedRecommendationProduct());

    dispatch(updateFirstFavouritesRecommendationsSuccess());
  };
