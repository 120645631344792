import { DataState } from "../../../../common/dataState";
import { Product } from "../../../../domain/product/product.types";

export enum NectarDestinationPageRequestIds {
  YNP_PRODUCTS = "YNP_PRODUCTS",
  POPULAR_PRODUCTS = "POPULAR_PRODUCTS",
  FAVOURITES_PRODUCTS = "FAVOURITES_PRODUCTS",
  BONUS_POINTS_PRODUCTS = "BONUS_POINTS_PRODUCTS",
  STATIC_SKU_LIST_PRODUCTS = "STATIC_SKU_LIST_PRODUCTS",
  SPONSORED_POPULAR_PRODUCTS = "SPONSORED_POPULAR_PRODUCTS",
}

export enum NectarDestinationPageActions {
  UPDATE_ADD_ALL_BONUS_OFFERS_TO_BASKET_DATA_STATE = "UPDATE_ADD_ALL_BONUS_OFFERS_TO_BASKET_DATA_STATE",
  UPDATE_ADD_ALL_YOUR_NECTAR_PRICES_TO_BASKET_DATA_STATE = "UPDATE_ADD_ALL_YOUR_NECTAR_PRICES_TO_BASKET_DATA_STATE",
  UPDATE_REQ_DATA_STATE = "UPDATE_REQ_DATA_STATE",
  CACHE_RESPONSE = "CACHE_RESPONSE",
}

export type CacheResponse = {
  type: NectarDestinationPageActions.CACHE_RESPONSE;
  products: Product[];
  requestId: NectarDestinationPageRequestIds;
};

export type UpdateReqDataState = {
  type: NectarDestinationPageActions.UPDATE_REQ_DATA_STATE;
  dataState: DataState;
  requestId: NectarDestinationPageRequestIds;
};

export type UpdateAddAllToTrolleyDataState = {
  type:
    | NectarDestinationPageActions.UPDATE_ADD_ALL_BONUS_OFFERS_TO_BASKET_DATA_STATE
    | NectarDestinationPageActions.UPDATE_ADD_ALL_YOUR_NECTAR_PRICES_TO_BASKET_DATA_STATE;
  dataState: DataState;
};
