import { featureFlags, Flags, NestedFlags } from "./featureFlags";

/**
 * useFeatureFlag provides access to feature flags in React components.
 * @param key the feature flag name
 *
 * @example
 * import { useFeatureFlag } from "firebase/featureFlags";
 * const isNewFeature = useFeatureFlag("new_feature");
 */
export const useFeatureFlag = <K extends keyof Flags>(key: K): Flags[`${K}`] => {
  return featureFlags.get(key);
};
/**
 * useFeatureFlagObject provides access to feature flags in React components.
 * @param key the feature flag name using dot notation
 *
 * @example
 * import { useFeatureFlag } from "firebase/featureFlags";
 * const isFeatureFlagObject = useFeatureFlag("new_feature.banner.mobile")
 */
export const useFeatureFlagObject = <K extends keyof NestedFlags<K>>(key: K): boolean => {
  return featureFlags.find(key as string);
};
