import { AnyAction } from "redux";
import { ReviewsState } from "../../../common/store";
import { ProductReviews, ProductReviewsSuccessActionType } from "./reviews.types";
import { DataState } from "../../../common/dataState";

export const reviewsReducer = (
  state: ReviewsState = {
    dataState: DataState.UNKNOWN,
    reportReviewsDataState: DataState.UNKNOWN,
    totalReviews: 0,
    reviews: [],
  },
  action: AnyAction
): ReviewsState => {
  switch (action.type) {
    case ProductReviews.REPORT_REVIEWS_PENDING:
      return { ...state, reportReviewsDataState: DataState.PENDING };
    case ProductReviews.REPORT_REVIEWS_FAILED:
      return { ...state, reportReviewsDataState: DataState.FAILED };
    case ProductReviews.REPORT_REVIEWS_SUCCESS:
      return { ...state, reportReviewsDataState: DataState.SUCCESS };
    case ProductReviews.REPORT_REVIEWS_RESET:
      return { ...state, reportReviewsDataState: DataState.UNKNOWN };
    case ProductReviews.FETCH_PRODUCT_REVIEWS_PENDING:
      return { ...state, dataState: DataState.PENDING };

    case ProductReviews.FETCH_PRODUCT_REVIEWS_FAILED:
      return { ...state, dataState: DataState.FAILED };

    case ProductReviews.FETCH_PRODUCT_REVIEWS_SUCCESS:
      const reviewsAction = action as ProductReviewsSuccessActionType;

      return {
        ...state,
        dataState: DataState.SUCCESS,
        totalReviews: reviewsAction.totalReviews,
        reviews: reviewsAction.reviews,
      };

    default:
      return state;
  }
};
