import { sendAnalyticsEvent, userClickOnPLPProductCardAnalyticsEvent } from "./analyticsEvent";
import { AnalyticsEventType, PageDescription, PFMCategory, Price, Product, OfferType } from "./types";
import { mapProductToPFMCategory } from "../middleware/analyticsMiddleware/analyticsMiddleware";
import { AdMetaData } from "../ads";
import { ProductSource, ProductType, Product as DomainProduct } from "../../domain/product/product.types";
import { PromoMechanicIds } from "../types";
import { FavouriteSourceForAnalyticsTypes } from "@favourites/Favourites.types";
import { mapFavouriteSource } from "./basket";

export const setProducts = (list: PageDescription, pageNumber: number, pageSize: number, products: DomainProduct[]) => {
  const productImpression: Product[] = products.map(product => {
    const productPosition = getProductPosition(pageNumber, pageSize, product);
    const pfmCategory = mapProductToPFMCategory(product);

    return mapDomainProductToDataLayerProduct(productPosition, product, pfmCategory, list);
  });

  if (!(window as any).digitalData.productImpression) {
    (window as any).digitalData.productImpression = [];
  }

  (window as any).digitalData.productImpression = (window as any).digitalData.productImpression.concat(
    productImpression
  );

  if (productImpression.length) {
    sendAnalyticsEvent({ eventName: AnalyticsEventType.PRODUCT_IMPRESSION, eventAction: productImpression[0].list });
  }
};

const getPrice = (product: DomainProduct, catchWeight?: string, multiVariant?: string): Price => {
  const price: Price = { finalPrice: 0, priceType: "" };

  if (product.retailPrice) {
    price.finalPrice = product.retailPrice.price;
    price.priceType = product.retailPrice.measure;
  }

  switch (product.productType) {
    case ProductType.CATCHWEIGHT:
      if (catchWeight && product.catchweight) {
        const cw = product.catchweight.filter(v => v.range === catchWeight).shift();
        if (cw) {
          price.finalPrice = cw.retailPrice.price;
          price.priceType = cw.retailPrice.measure;
        }
      }
      break;
    case ProductType.MULTIVARIANT:
      if (multiVariant && product.multivariants) {
        const mv = product.multivariants.filter(v => v.productUid === multiVariant).shift();
        if (mv) {
          price.finalPrice = mv.retailPrice.price;
          price.priceType = mv.retailPrice.measure;
        }
      }
      break;
    default:
      break;
  }

  return price;
};

export const mapDomainProductToDataLayerProduct = (
  position: number,
  product: DomainProduct,
  pfmCategory: PFMCategory | string,
  list?: PageDescription | string,
  catchWeight?: string,
  multiVariant?: string,
  adMetaData?: AdMetaData,
  isNectarOffer?: boolean
): Product => {
  const price = getPrice(product, catchWeight, multiVariant);
  const hasPromotions = !!(product.promotions && product.promotions.length > 0);
  const isFavouritesPage =
    product.source === ProductSource.FAVOURITE || product.source === ProductSource.PREVIOUS_ORDERS;

  const favouriteSource =
    (product.favouriteSource && mapFavouriteSource[product.favouriteSource]) || FavouriteSourceForAnalyticsTypes.NONE;

  return {
    sku: multiVariant ? multiVariant : product.productUid,
    parentSku: product.associationParentProductUid ? product.associationParentProductUid : null,
    name: product.name,
    finalPrice: price.finalPrice,
    priceType: price.priceType,
    isSpotlight: product.isSpotlight,
    isOnOffer: hasPromotions,
    offerPromoId: hasPromotions ? product.promotions![0].promotionUid : null,
    offerDateStartTimeStamp: hasPromotions ? product.promotions![0].startDate! : null,
    offerDateEndTimeStamp: hasPromotions ? product.promotions![0].endDate! : null,
    offerType: setOfferType(product),
    isUserFavorite: !!product.favouriteUid,
    favouriteSource,
    isUnitAvailable: true,
    isWeightAvailable: product.productType === ProductType.LOOSE,
    isWeightRangeAvailable: product.productType === ProductType.CATCHWEIGHT,
    isMultiVariantAvailable: product.productType === ProductType.MULTIVARIANT,
    imageURL: product.image,
    list,
    position,
    pfmCategory,
    adId: adMetaData ? adMetaData.id : null,
    adType: adMetaData ? adMetaData.type : null,
    nectar_offer: isNectarOffer !== undefined ? isNectarOffer : null,
    parent_sku: product.associationParentProductUid || undefined,
    isInStock: isFavouritesPage ? product.available : undefined,
    reviewScore: product.reviews.averageRating,
    reviewsNumber: product.reviews.numberOfReviews,
  };
};

export const setOfferType = (product: DomainProduct): string => {
  if (product.yourNectarPrice) {
    return OfferType.YOUR_NECTAR_PRICE;
  } else if (product.promotions && product.promotions.length > 0) {
    const promotion = product.promotions[0];
    if (promotion.promoMechanicId === PromoMechanicIds.NECTAR_PROMOTION) {
      return OfferType.NECTAR_PRICE;
    } else {
      return OfferType.TYPICAL_PRICE_REDUCTION;
    }
  }
  return OfferType.ORIGINAL_PRICE;
};

export const clearProducts = () => {
  (window as any).digitalData.productImpression = [];
};

export const sendProductClickedEvent = (
  pageNumber: number,
  pageSize: number,
  product: DomainProduct,
  pfmCategory: PFMCategory | string,
  adMetaData?: AdMetaData
) => {
  const productPosition = getProductPosition(pageNumber, pageSize, product);
  const mappedProduct = mapDomainProductToDataLayerProduct(
    productPosition,
    product,
    pfmCategory,
    undefined,
    undefined,
    undefined,
    adMetaData
  );

  const event = userClickOnPLPProductCardAnalyticsEvent(mappedProduct);

  sendAnalyticsEvent(event);
};

export const getProductPosition = (pageNumber: number, pageSize: number, product: DomainProduct): number => {
  const basePostion = (pageNumber - 1) * pageSize;
  if (product.source === ProductSource.RECOMMENDATION || product.source === ProductSource.KRANG) {
    return product.position;
  }
  return basePostion + product.position;
};
