import React from "react";
import { useHistory } from "react-router-dom";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { GOLLink } from "../../../components/GOLLink";
import { Link } from "@sainsburys-tech/fable";
import { MenuItem } from "../Header.types";
import { getDefaultMenuItems } from "../menuItemsSelectors";
import { DesktopGroceriesMenuLink } from "./DesktopGroceriesMenuLink";
import { TabletGroceriesMenuLink } from "./TabletGroceriesMenuLink";
import { withAuthorizedState, WithIsAuthorized } from "../../Authorized";
import { featureFlags } from "../../../common/firebase";
import { FeatureTourTooltip } from "@favourites/components/FeatureTour/FeatureTourTooltip.component";
import useFeatureTour from "@favourites/hooks/useFeatureTour";
import { DefaultSteps } from "@favourites/FeatureTour.types";
import { useMenuPromotionalItem } from "../useMenuPromotionalItem";
import { isDesktopTourRoute } from "@favourites/utils/routes";
import { urls } from "../../../routes";

type MenuLinkProps = {
  title: string;
  url: string;
  newTab?: boolean;
  isSelected: boolean;
  external: boolean;
};

export const isMenuItemSelected = (currentUrl: string): boolean => {
  const urlSplit = currentUrl.replace(/\/$/, "").split("/");

  if (location.pathname.includes("YourRecipes")) {
    return urlSplit[2] === "favourites-as-list";
  }
  return location.pathname.toLowerCase().indexOf(urlSplit[urlSplit.length - 1]) > -1;
};

export const getUrl = (isAuthorized: boolean, item: MenuItem): string => {
  return isAuthorized && item.authorizedUrl ? item.authorizedUrl : item.url;
};

export const MenuLink = React.memo(({ title, url, newTab, isSelected, external }: MenuLinkProps) => {
  const classes = isSelected ? "nav__menu-link nav__menu-link--selected" : "nav__menu-link";
  const isOffer = title === "Offers" ? "offer" : "";
  const { show, step, totalSteps, page, handleCloseTourOnClick, handleNextTourOnClick } = useFeatureTour();
  const { pathname } = useLocation();
  const history = useHistory();

  const isCorrectStep = step === DefaultSteps.DESKTOP_MENU;
  const showTourTooltip = show && title === "Favourites" && isCorrectStep && isDesktopTourRoute(pathname);

  const trackEvent = (label: string) => {
    (window as any).digitalData.track("ev_headernavClick", {
      hit_type: "link",
      data_event_action: "click",
      data_event_category: "header nav",
      data_event_label: label,
    });
  };

  if (showTourTooltip) {
    return (
      <li className={`ln-o-inline-list__item nav__menu-link-wrapper ${isOffer}`}>
        <FeatureTourTooltip
          currentStep={step}
          totalStep={totalSteps}
          showNextCTA={Boolean(page.nextCTA)}
          nextCTAText={page.nextCTA}
          handleNextOnClick={handleNextTourOnClick}
          handleCloseOnClick={handleCloseTourOnClick}
          isOpen={true}
          message={page.text}
          position={page.position}
          isLarge={page.isLargeTooltip}
        >
          <div onClick={handleCloseTourOnClick}>
            <GOLLink className={classes} to={url} external={external} newTab={newTab}>
              {title}
            </GOLLink>
          </div>
        </FeatureTourTooltip>
      </li>
    );
  }
  return (
    <li className={`ln-o-inline-list__item nav__menu-link-wrapper ${isOffer}`}>
      <Link
        className={classes}
        href={url}
        onClick={(e: React.MouseEvent) => {
          trackEvent(title);
          if (!external) {
            e.preventDefault();
            e.stopPropagation();
            history.push(url);
          }
        }}
        data-testid="desktop-menu-link"
        external={external}
        target={newTab ? "_blank" : undefined}
      >
        {title}
      </Link>
    </li>
  );
});

export interface DesktopMenuContainerProps {
  isFoodToOrderEnabled: boolean;
}
export interface DesktopMenuProps {
  onGroceriesActive?: () => void;
  onGroceriesNoLongerActive?: () => void;
  isNavOpen?: boolean;
  isTablet?: boolean;
  menuItems?: MenuItem[];
  megaNavFetchFailed?: boolean;
}

export interface DesktopMenuWithRouterProps extends DesktopMenuProps, RouteComponentProps {}

export const DesktopMenuContainer = (routerProps: any): React.ReactElement => {
  const isFoodToOrderEnabled = featureFlags.get("fto_header_flag");

  const props = {
    ...routerProps,
    isFoodToOrderEnabled,
  };

  return <DesktopMenu {...props} />;
};

export const DesktopMenu = ({
  onGroceriesActive,
  onGroceriesNoLongerActive,
  isNavOpen,
  isTablet,
  isAuthorized,
  menuItems = getDefaultMenuItems(),
  isFoodToOrderEnabled,
  megaNavFetchFailed = false,
}: DesktopMenuProps & DesktopMenuContainerProps & WithIsAuthorized) => {
  const { promotionalLink } = useMenuPromotionalItem();
  const promotionalLink2 = featureFlags.get("promotional_link2");

  const handleGroceriesClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!megaNavFetchFailed) {
      event.preventDefault();
      if (isNavOpen) {
        onGroceriesNoLongerActive?.();
      } else {
        onGroceriesActive?.();
      }
    }
  };

  return (
    <div className="nav__menu-wrapper">
      <ul className="ln-o-inline-list ln-o-inline-list--spaced">
        <li className="ln-o-inline-list__item">
          {isTablet ? (
            <TabletGroceriesMenuLink onClick={onGroceriesActive} selected={isNavOpen} />
          ) : (
            <DesktopGroceriesMenuLink
              onClick={handleGroceriesClick}
              selected={isNavOpen}
              megaNavFetchFailed={megaNavFetchFailed}
            />
          )}
        </li>
        {menuItems.slice(1).map((item: MenuItem) => {
          const url = getUrl(isAuthorized, item);
          const isSelectedItem = isMenuItemSelected(url);
          const external = item.external === undefined ? true : item.external;

          return (
            <MenuLink
              title={item.title}
              url={url}
              newTab={item.newTab}
              key={item.id}
              isSelected={isSelectedItem}
              external={external}
            />
          );
        })}
        {isFoodToOrderEnabled && (
          <MenuLink
            title="Occasions"
            url={urls.OCCASIONS}
            newTab={false}
            external={false}
            isSelected={isMenuItemSelected(`${urls.OCCASIONS}`)}
          />
        )}
        {promotionalLink && (
          <MenuLink
            title={promotionalLink.title}
            url={promotionalLink.url}
            newTab={false}
            external={true}
            isSelected={isMenuItemSelected(promotionalLink.url)}
          />
        )}
        {promotionalLink2 && Object.keys(promotionalLink2).length > 0 && (
          <MenuLink
            title={promotionalLink2.title}
            url={promotionalLink2.url}
            newTab={false}
            external={true}
            isSelected={isMenuItemSelected(promotionalLink2.url)}
          />
        )}
      </ul>
    </div>
  );
};

DesktopMenu.displayName = "DesktopMenu";

export const DesktopMenuWithRouter: React.FC<DesktopMenuWithRouterProps & WithIsAuthorized> = (
  props: DesktopMenuWithRouterProps & WithIsAuthorized
) => <DesktopMenuContainer {...props} />;

DesktopMenuWithRouter.displayName = "DesktopMenuWithRouter";

export const DesktopMenuWithAuthorizedState = withRouter<DesktopMenuWithRouterProps, any>(
  withAuthorizedState(DesktopMenuWithRouter)
);
