import { useCallback } from "react";
import { useDigitalDataEventTracking } from "../useDigitalDataEventTracking/useDigitalDataEventTracking";
import { useBasket } from "../../../domain/basket/basket";
import { digitalDataTrackLightboxView } from "../../analytics";

export const usePageView = (
  pageType: string,
  pageName: string,
  siteSection?: string,
  isLightbox?: boolean,
  trackName?: string
): { sendPageEvent: (extraPageData?: string[] | undefined, extraProperties?: object | undefined) => void } => {
  const { addToDigitalData } = useDigitalDataEventTracking();
  const { basket } = useBasket();
  const shoppingMode = basket.isInAmendMode ? "amend" : "primary";

  const pageViewName = `web:${pageType}:${pageName}`; // abstract this even more
  if (!siteSection) {
    siteSection = pageName;
  }

  const sendPageEvent: (extraPageData?: string[] | undefined, extraProperties?: object | undefined) => void =
    useCallback(
      (extraPageData?: string[], extraProperties?: object) => {
        let appendData = "";
        if (extraPageData) {
          appendData = ":" + extraPageData.join(":");
        }

        if (!isLightbox) {
          addToDigitalData({
            data_siteSection: siteSection,
            data_page_name: `${pageViewName}${appendData}`,
            data_shoppingMode: shoppingMode,
            ...extraProperties,
          });
        } else {
          digitalDataTrackLightboxView({
            eventName: `pl_${trackName}`,
            data_page_name: `${pageViewName}${appendData}`,
          });
        }
      },
      [addToDigitalData, isLightbox, pageViewName, shoppingMode, siteSection, trackName]
    );

  return {
    sendPageEvent,
  };
};
