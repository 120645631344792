import { IngridAd } from "../../../components/IngridAd/IngridAd.type";

export interface SearchIngridAdState {
  ads: IngridAd[];
}

export enum SearchIngridAdActionType {
  SEARCH_AD_REQUEST = "SEARCH_AD_REQUEST",
  SEARCH_AD_SUCCESS = "SEARCH_AD_SUCCESS",
  SEARCH_AD_FAILURE = "SEARCH_AD_FAILURE",
  SEARCH_AD_RESET = "SEARCH_AD_RESET",
}
