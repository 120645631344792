import {
  FavouriteDisableModalFirstInteractionAction,
  FavouritesActionTypes,
  FavouritesCheckOffersOnlyAction,
  FavouritesDataPendingAction,
  FavouriteDisableModalSuccessAction,
  favouritesDeleteSelectedItemType,
  FavouritesErrorAction,
  SeasonalFavouritesErrorAction,
  FavouritesState,
  FetchFavouritesSuccessWithErrorsAction,
  FetchSeasonalFavouritesSuccessWithErrorsAction,
  FavouritesLastSeenProductAction,
  fetchFirstFavouritesSuccessAction,
  fetchFavouritesSuccessWithErrorsFallbackAction,
  FavouritesErrorActionTypes,
  FavouritesMagnoliaSuccessfulAction,
} from "@favourites/Favourites.types";
import { DataState } from "../../../common/dataState";
import { Action } from "redux";
import { ProductCoord } from "../../../common/scrollIntoView";
import { favouritePlacement } from "../../../components/Espots";
import { EspotControlDataSuccess, EspotControlDataSuccessAction } from "../../../common/espotControl/espotControl";
import { EspotParams, InterleavedEspot } from "../../../components/Espots/Espot.types";
import { ProductActionType } from "../../../components/ProductTile/productTile.types";
import { ProductErrorCode } from "../../../services/product.types";

export const initialState: FavouritesState = {
  tour: {
    show: true,
    step: 1,
  },
  dataState: DataState.UNKNOWN,
  seasonalDataState: DataState.UNKNOWN,
  errors: [],
  productCoordMap: new Map<number, ProductCoord>(),
  gridEspots: favouritePlacement,
  isFavouritesModalDisabled: false,
  isFavouritesModalFirstInteraction: true,
  favouritesDeleteSelectedItem: "",
  favouriteProductUid: "",
  isOffersOnly: false,
  serviceProducts: [],
  lastSeenFavourite: "",
  errorUids: {},
  ads: [],
};

type ActionTypes =
  | FetchFavouritesSuccessWithErrorsAction
  | FavouritesErrorAction
  | SeasonalFavouritesErrorAction
  | FavouritesDataPendingAction
  | Action;

export const favouritesReducer = (state = initialState, action: ActionTypes): FavouritesState => {
  switch (action.type) {
    case FavouritesActionTypes.CLEAR_PRODUCT_COORD_MAP:
      return {
        ...state,
        productCoordMap: new Map<number, ProductCoord>(),
      };
    case FavouritesActionTypes.SET_FAVOURITES_IDLE:
      return {
        ...state,
        dataState: DataState.UNKNOWN,
      };
    case FavouritesActionTypes.RESET_TOUR_STEP:
      return {
        ...state,
        tour: {
          ...state.tour,
          step: 1,
        },
      };
    case FavouritesActionTypes.INCREASE_TOUR_STEP:
      const { step } = state.tour;

      return {
        ...state,
        tour: {
          ...state.tour,
          step: step + 1,
        },
      };
    case FavouritesActionTypes.HIDE_FEATURE_TOUR:
      return {
        ...state,
        tour: {
          ...state.tour,
          show: false,
          step: 1,
        },
      };
    case FavouritesActionTypes.SHOW_FEATURE_TOUR:
      return {
        ...state,
        tour: {
          ...state.tour,
          show: true,
        },
      };
    case FavouritesActionTypes.SET_LAST_SEEN_FAVOURITE: {
      const { product } = action as FavouritesLastSeenProductAction;
      return {
        ...state,
        lastSeenFavourite: product,
      };
    }
    case FavouritesActionTypes.REMOVE_LAST_SEEN_FAVOURITE: {
      return {
        ...state,
        lastSeenFavourite: "",
      };
    }
    case FavouritesActionTypes.CHECK_OFFERS_ONLY: {
      const { isOffersOnly } = action as FavouritesCheckOffersOnlyAction;
      return {
        ...state,
        isOffersOnly,
      };
    }

    case FavouritesActionTypes.FETCH_FIRST_FAVOURITES_PENDING: {
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    }

    /** @deprecated to be removed after add to favourites release */
    case FavouritesActionTypes.PENDING_SAVE_FAVOURITE: {
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    }

    case FavouritesActionTypes.SUCCESSFUL_SAVE_FAVOURITE: {
      return {
        ...state,
        dataState: DataState.SUCCESS,
      };
    }

    case FavouritesActionTypes.ERROR_SAVE_FAVOURITE: {
      return {
        ...state,
        dataState: DataState.FAILED,
      };
    }

    case ProductActionType.REMOVE_PRODUCT_PENDING: {
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    }

    case FavouritesActionTypes.FAVOURITE_PRODUCT_ERROR: {
      const { favouriteProductUid, status } = action as favouritesDeleteSelectedItemType;

      let isHeartIconFull;

      switch (status) {
        case FavouritesErrorActionTypes.DELETE:
          isHeartIconFull = true;
          break;
        case FavouritesErrorActionTypes.SAVE:
          isHeartIconFull = false;
          break;
        default:
          isHeartIconFull = false;
          break;
      }

      return {
        ...state,
        errorUids: {
          ...state.errorUids,
          [favouriteProductUid]: isHeartIconFull,
        },
      };
    }

    case FavouritesActionTypes.CLEAR_FAVOURITE_PRODUCT_ERROR: {
      const { favouriteProductUid } = action as favouritesDeleteSelectedItemType;

      const updatedList = { ...state.errorUids };
      delete updatedList[favouriteProductUid];

      return {
        ...state,
        errorUids: {
          ...updatedList,
        },
      };
    }

    case FavouritesActionTypes.FETCH_FIRST_FAVOURITES_SUCCESS: {
      const { serviceProducts } = action as fetchFirstFavouritesSuccessAction;
      return {
        ...state,
        dataState: DataState.SUCCESS,
        serviceProducts,
      };
    }

    case FavouritesActionTypes.UPDATE_RECOMMENDATIONS_SUCCESS: {
      return {
        ...state,
        dataState: DataState.SUCCESS,
      };
    }

    case FavouritesActionTypes.FETCH_FAVOURITES_PENDING: {
      return {
        ...state,
        dataState: DataState.PENDING,
        errors: [],
      };
    }

    case FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS: {
      return {
        ...state,
        dataState: DataState.SUCCESS,
      };
    }

    case FavouritesActionTypes.FETCH_FAVOURITES_ERROR: {
      return {
        ...state,
        dataState: DataState.FAILED,
      };
    }

    case FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_PENDING: {
      return {
        ...state,
        seasonalDataState: DataState.PENDING,
        errors: [],
      };
    }

    case FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_SUCCESS: {
      return {
        ...state,
        seasonalDataState: DataState.SUCCESS,
      };
    }

    case FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_SUCCESS_WITH_ERRORS: {
      const { errors } = action as FetchSeasonalFavouritesSuccessWithErrorsAction;
      return {
        ...state,
        seasonalDataState: DataState.SUCCESS,
        errors,
      };
    }

    case FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_ERROR: {
      return {
        ...state,
        seasonalDataState: DataState.FAILED,
      };
    }

    case FavouritesActionTypes.FETCH_FIRST_FAVOURITES_FAILURE: {
      return {
        ...state,
        dataState: DataState.FAILED,
      };
    }

    case FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS_WITH_ERRORS: {
      const { errors } = action as FetchFavouritesSuccessWithErrorsAction;

      return {
        ...state,
        dataState: DataState.SUCCESS,
        errors,
      };
    }

    case FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS_WITH_ERRORS_FALLBACK: {
      const { serviceProducts } = action as fetchFavouritesSuccessWithErrorsFallbackAction;
      const errors = [{ code: ProductErrorCode.FAVOURITES_ERROR_FALLBACK }];
      return {
        ...state,
        dataState: DataState.SUCCESS,
        serviceProducts,
        errors,
      };
    }

    case FavouritesActionTypes.DISABLE_FAVOURITES_FIRST_INTERACTION:
    case FavouritesActionTypes.DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_ENABLED:
    case FavouritesActionTypes.DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_DISABLED:
      const { isFavouritesModalFirstInteraction } = action as FavouriteDisableModalFirstInteractionAction;
      return {
        ...state,
        isFavouritesModalFirstInteraction,
      };
    /** @deprecated to be removed after add to favourites release */
    case FavouritesActionTypes.DELETE_FAVOURITES_PENDING:
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    case FavouritesActionTypes.DELETE_FAVOURITES_SUCCESS:
      return {
        ...state,
        dataState: DataState.SUCCESS,
      };
    case FavouritesActionTypes.DELETE_FAVOURITES_ERROR:
      return {
        ...state,
        dataState: DataState.FAILED,
      };

    case FavouritesActionTypes.DISABLE_FAVOURITES_MODAL: {
      const { isFavouritesModalDisabled } = action as FavouriteDisableModalSuccessAction;
      return {
        ...state,
        isFavouritesModalDisabled,
      };
    }

    /** @deprecated to be removed after add to favourites release */
    case FavouritesActionTypes.UPDATE_DELETE_FAVOURITES_SELECTED_ITEM: {
      const { favouritesDeleteSelectedItem, favouriteProductUid } = action as favouritesDeleteSelectedItemType;
      return {
        ...state,
        favouritesDeleteSelectedItem,
        favouriteProductUid,
      };
    }

    case EspotControlDataSuccess.ESPOT_CONTROL_DATA_SUCCESS:
      const espotControlAction = action as EspotControlDataSuccessAction;
      let gridEspots = [...state.gridEspots];

      for (const instruction of espotControlAction.espotControl.instructions) {
        if (instruction.region === "fav_grid" && instruction.visible) {
          const espot: InterleavedEspot = {
            espotParams: {
              name: instruction.name as EspotParams["name"],
            },
            insertSequence: instruction.insertSequence,
            categoriesPosition: instruction.categoriesPosition,
          };
          gridEspots.push(espot);
        }

        if (instruction.region === "fav_grid" && !instruction.visible) {
          gridEspots = gridEspots.filter(
            espot => espot.insertSequence !== instruction.insertSequence || espot.espotParams.name !== instruction.name
          );
        }
      }

      return {
        ...state,
        gridEspots,
      };

    case FavouritesActionTypes.FETCH_MAGNOLIA_SUCCESS:
      const { ads } = action as FavouritesMagnoliaSuccessfulAction;

      return {
        ...state,
        ads,
      };

    default:
      return state;
  }
};
