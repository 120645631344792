import React, { createContext, FC, useContext, useEffect, useState } from "react";
import { innerWidth } from "../../common/innerWidth";

export const extraLargeDesktopBreakpoint = 1400;
export const largeDesktopBreakpoint = 1200;
export const desktopBreakpoint = 960;
export const tabletBreakpoint = 720;
export const smallMobileBreakpoint = 480;

// UseResponsive is a hook that provides utilities to write responsive code. The state is managed globally using a
// React context.
export type UseResponsive = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  isExtraLargeDesktop: boolean;
  isSmallMobile: boolean;
  width: number;
  height: number;
};

export const useResponsive = (): UseResponsive => {
  return useContext(ResponsiveContext);
};

export const LargeDesktop: FC = ({ children }) => {
  const { isLargeDesktop } = useResponsive();
  return isLargeDesktop ? <>{children}</> : null;
};

export const Desktop: FC = ({ children }) => {
  const { isDesktop } = useResponsive();
  return isDesktop ? <>{children}</> : null;
};

export const Mobile: FC = ({ children }) => {
  const { isMobile } = useResponsive();
  return isMobile ? <>{children}</> : null;
};

export const Tablet: FC = ({ children }) => {
  const { isTablet } = useResponsive();
  return isTablet ? <>{children}</> : null;
};

export const NonMobile: FC = ({ children }) => {
  const { isMobile } = useResponsive();
  return !isMobile ? <>{children}</> : null;
};

export const NonDesktop: FC = ({ children }) => {
  const { isDesktop } = useResponsive();
  return !isDesktop ? <>{children}</> : null;
};

export const NonLargeDesktop: FC = ({ children }) => {
  const { isLargeDesktop } = useResponsive();
  return !isLargeDesktop ? <>{children}</> : null;
};

export const SmallMobile: FC = ({ children }) => {
  const { isSmallMobile } = useResponsive();
  return isSmallMobile ? <>{children}</> : null;
};

export const NonSmallMobile: FC = ({ children }) => {
  const { isSmallMobile } = useResponsive();
  return !isSmallMobile ? <>{children}</> : null;
};

export const ExtraLargeDesktop: FC = ({ children }) => {
  const { isExtraLargeDesktop } = useResponsive();
  return isExtraLargeDesktop ? <>{children}</> : null;
};

export const Responsive: FC<{
  minWidth?: number;
  maxWidth?: number;
  children?: React.ReactNode;
}> = ({ children, minWidth, maxWidth }) => {
  const { width } = useResponsive();
  if (minWidth && width < minWidth) {
    return null;
  }
  if (maxWidth && width > maxWidth) {
    return null;
  }
  return <>{children}</>;
};

export const ResponsiveContext = createContext<UseResponsive>(getState());

export const ResponsiveProvider: FC = ({ children }) => {
  const [state, setState] = useState(getState());

  const handleWindowResize = () => {
    setState(getState());
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return <ResponsiveContext.Provider value={state}>{children}</ResponsiveContext.Provider>;
};

export function getState(): UseResponsive {
  const width = typeof window !== "undefined" ? innerWidth() : desktopBreakpoint;
  const height = typeof window !== "undefined" ? window.innerHeight : desktopBreakpoint;
  return {
    width,
    height,
    isTablet: width >= tabletBreakpoint && width < desktopBreakpoint,
    isDesktop: width >= desktopBreakpoint,
    isMobile: width < tabletBreakpoint,
    isLargeDesktop: width >= largeDesktopBreakpoint,
    isExtraLargeDesktop: width >= extraLargeDesktopBreakpoint,
    isSmallMobile: width <= smallMobileBreakpoint,
  };
}
