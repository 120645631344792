import React from "react";
import { Alert } from "@jsluna/alert";
import { MissedSinglePromotion } from "./MissedSinglePromotion.component";
import { MissedMultiplePromotions } from "./MissedMultiplePromotion.component";
import { BasketPromotion } from "../../domain/basket/basket.types";

interface MultibuyPromotionListProps {
  missedMultibuyPromotions: BasketPromotion[];
  appliedMultibuyPromotions: BasketPromotion[];
  showMissedPromotions: boolean;
  productUid: string;
}

export interface MissedPromotion {
  items: BasketPromotion[];
  isNectar: boolean;
  isMealDeal: boolean;
}

export const MultibuyPromotionList = ({
  missedMultibuyPromotions,
  appliedMultibuyPromotions,
  showMissedPromotions,
  productUid,
}: MultibuyPromotionListProps) => {
  if (appliedMultibuyPromotions.length > 0) {
    return (
      <Alert className="alert-promo-applied">
        <strong>{appliedMultibuyPromotions.length > 1 ? "Offers" : "Offer"} complete:&nbsp;</strong>
        {appliedMultibuyPromotions.map(promo => promo.strapLine).join(" PLUS ")}
      </Alert>
    );
  }

  // Unmodifiable trolley item will not show missed promotions
  if (showMissedPromotions) {
    const missedPromotions: MissedPromotion[] = [
      {
        items: missedMultibuyPromotions.filter(
          promo => !promo.isNectarPrice && !promo.strapLine.startsWith("Meal Deal")
        ),
        isNectar: false,
        isMealDeal: false,
      },
      {
        items: missedMultibuyPromotions.filter(
          promo => !promo.isNectarPrice && promo.strapLine.startsWith("Meal Deal")
        ),
        isNectar: false,
        isMealDeal: true,
      },
      {
        items: missedMultibuyPromotions.filter(
          promo => promo.isNectarPrice && !promo.strapLine.startsWith("Meal Deal")
        ),
        isNectar: true,
        isMealDeal: false,
      },
      {
        items: missedMultibuyPromotions.filter(promo => promo.isNectarPrice && promo.strapLine.startsWith("Meal Deal")),
        isNectar: true,
        isMealDeal: true,
      },
    ];

    return (
      <ul className="ln-u-margin-left*0 ln-u-margin-bottom*0" data-testid="pt-mini-promotion-list">
        {missedPromotions.map(
          missedPromotion =>
            missedPromotion.items.length > 0 && (
              <li key={missedPromotion.items[0].promotionUid} className="pt-mini__promotion">
                {missedPromotion.items.length > 1 ? (
                  <MissedMultiplePromotions missedPromotion={missedPromotion} productUid={productUid} />
                ) : (
                  <MissedSinglePromotion missedPromotion={missedPromotion} productUid={productUid} />
                )}
              </li>
            )
        )}
      </ul>
    );
  }

  return null;
};
