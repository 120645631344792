import { OrderPromiseStatus, ProductType } from "./product.types";
import { BasketCouponError } from "../domain/basket/basket.types";

export const ScottishLegislationRestrictionError = "SCOTTISH_LEGISLATION_RESTRICTION";
export const ScottishLegislationRestrictionRebookDeliveryError = "SCOTTISH_LEGISLATION_RESTRICTION_REBOOK_DELIVERY";
export const BulkOrderLimitExceededError = "BULK_ORDER_LIMIT_EXCEEDED";
export const BasketItemQuantityExceededError = "BASKET_ITEM_QUANTITY_EXCEEDED";
export const MaximumBasketLimitExceededError = "MAXIMUM_BASKET_LIMIT_EXCEEDED";

export type Basket = {
  basket_id: string;
  order_id: string;
  subtotal_price: number;
  total_price: number;
  savings: number;
  nectar_savings: number;
  vouchers_savings: number;
  slot_price?: number;
  minimum_spend: number;
  has_exceeded_minimum_spend: boolean;
  longest_promise_date?: string;
  is_slot_locked?: boolean;
  item_count: number;
  items: BasketItem[];
  is_in_amend_mode: boolean;
  delivery_instructions: string;
  error?: BasketCouponError[];
};

export type BasketAddOrder = {
  basket: Basket;
  errors: BasketAddOrderError[];
};

export type BasketAddOrderError = {
  code: string;
  title: string;
  detail: string;
  meta: {
    sku: string;
    name: string;
    information_url: string;
  };
};

export interface BasketItem {
  item_uid: string;
  product: BasketItemProduct;
  uom: string;
  quantity: number;
  subtotal_price: number;
  promotions: BasketPromotion[];
  allow_substitutions?: boolean;
  selected_catchweight?: string;
  display_icons?: string[] | undefined;
  total_base_price?: string;
  is_your_nectar_price?: boolean;
  max_quantity_limit?: number;
  promise?: OrderPromise;
  is_supply_chain_orderable?: boolean;
  savings?: number;
  price_type?: string;
}

export type OrderPromise = {
  earliest_promise_date: string;
  last_amendment_date: string;
  status: OrderPromiseStatus;
};

export type BasketItemProduct = {
  name: string;
  sku: string;
  product_type: ProductType;
  image: string;
  full_url: string;
};

export type UnitOfMeasure = string;
export const KG: UnitOfMeasure = "kg";
export const EACH: UnitOfMeasure = "ea";
export const C62: UnitOfMeasure = "C62";

export interface BasketPromotion {
  promotion_uid: string;
  strap_line: string;
  promotion_missed: boolean;
  is_nectar_price?: boolean;
  type?: string;
}

export interface BasketItemUpdate {
  product_uid: string;
  item_uid: string;
  uom: string;
  quantity: number;
  selected_catchweight?: string;
  decreasing_quantity?: boolean;
}

export interface BasketItemAdd {
  product_uid: string;
  uom: string;
  quantity: number;
  selected_catchweight?: string;
  decreasing_quantity?: boolean;
}

export interface BasketItemSubstitutionPreference {
  allow_substitutions: boolean;
  item_uid: string;
  product_uid?: string; // sku is optional for updating the basket until we have fully migrated to fbs
}
