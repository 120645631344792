import { ProductCoordMap } from "../../common/scrollIntoView";
import { InterleavedEspot } from "../../components/Espots/Espot.types";
import { InterleavedBannerAd } from "../../components/Citrus/BannerAd/BannerAd.types";
import { DataState, WithDataState } from "../../common/dataState";
import {
  Product as ProductService,
  ProductQueryParameters,
  ProductServiceError,
  SortOption,
} from "../../services/product.types";
import {
  FilterProduct,
  PaginatedProducts,
  ProductListControlsQueryParams,
  ProductUid,
} from "../../domain/product/product.types";
import { CtaTypes, FeatureTourState, TourJourneyTypes } from "./FeatureTour.types";
import { TemplateAreas } from "../../services/contentTypes";
import { IngridAd } from "../../components/IngridAd/IngridAd.type";
import { UseProductsReturnType } from "../../common/hooks/useProducts";

export interface FavouritesState extends WithDataState {
  tour: FeatureTourState;
  seasonalDataState: DataState;
  productCoordMap: ProductCoordMap;
  gridEspots: InterleavedEspot[];
  errors: ProductServiceError[];
  isFavouritesModalDisabled: boolean;
  isFavouritesModalFirstInteraction: boolean;
  favouritesDeleteSelectedItem: string;
  favouriteProductUid: string;
  isOffersOnly: boolean;
  recommendationToken?: string;
  serviceProducts: ProductService[];
  lastSeenFavourite: string;
  errorUids: FavouriteErrorUids;
  ads: IngridAd[];
}

export type FavouriteErrorUids = Record<string, boolean>;

export interface ProductsByCategory {
  category: string;
  products: ProductUid[];
}

export enum FavouritesActionTypes {
  CLEAR_PRODUCT_COORD_MAP = "CLEAR_PRODUCT_COORD_MAP",
  SORT_FAVOURITES_ADOBE_ANALYTICS_REQUEST = "SORT_FAVOURITES_ADOBE_ANALYTICS_REQUEST",
  ADD_FAVOURITE_ADOBE_ANALYTICS_REQUEST = "ADD_FAVOURITE_ADOBE_ANALYTICS_REQUEST",
  REMOVE_FAVOURITE_ADOBE_ANALYTICS_REQUEST = "REMOVE_FAVOURITE_ADOBE_ANALYTICS_REQUEST",
  REMOVE_LAST_SEEN_FAVOURITE = "REMOVE_LAST_SEEN_FAVOURITE",
  SET_LAST_SEEN_FAVOURITE = "SET_LAST_SEEN_FAVOURITE",
  DISABLE_FAVOURITES_FIRST_INTERACTION = "DISABLE_FAVOURITES_FIRST_INTERACTION",
  DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_ENABLED = "DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_ENABLED",
  DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_DISABLED = "DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_DISABLED",
  SEND_FAVOURITES_ANALYTIC_EVENT = "SEND_FAVOURITES_ANALYTIC_EVENT",
  START_SHOPPING_ANALYTICS_EVENT = "START_SHOPPING_ANALYTICS_EVENT",
  FETCH_FAVOURITES_PENDING = "FETCH_FAVOURITES_PENDING",
  FETCH_FAVOURITES_SUCCESS = "FETCH_FAVOURITES_SUCCESS",
  FETCH_FAVOURITES_SUCCESS_WITH_ERRORS = "FETCH_FAVOURITES_SUCCESS_WITH_ERRORS",
  FETCH_FAVOURITES_SUCCESS_WITH_ERRORS_FALLBACK = "FETCH_FAVOURITES_SUCCESS_WITH_ERRORS_FALLBACK",
  FETCH_FAVOURITES_ERROR = "FETCH_FAVOURITES_ERROR",
  FETCH_SEASONAL_FAVOURITES_PENDING = "FETCH_SEASONAL_FAVOURITES_PENDING",
  FETCH_SEASONAL_FAVOURITES_SUCCESS = "FETCH_SEASONAL_FAVOURITES_SUCCESS",
  FETCH_SEASONAL_FAVOURITES_SUCCESS_WITH_ERRORS = "FETCH_SEASONAL_FAVOURITES_SUCCESS_WITH_ERRORS",
  FETCH_SEASONAL_FAVOURITES_ERROR = "FETCH_SEASONAL_FAVOURITES_ERROR",
  DELETE_FAVOURITES_SUCCESS = "DELETE_FAVOURITES_SUCCESS",
  DELETE_FAVOURITES_PENDING = "DELETE_FAVOURITES_PENDING",
  DELETE_FAVOURITES_ERROR = "DELETE_FAVOURITES_ERROR",
  DELETE_FAVOURITES_FROM_PRODUCTS = "DELETE_FAVOURITES_FROM_PRODUCTS",
  DISABLE_FAVOURITES_MODAL = "DISABLE_FAVOURITES_MODAL",
  UPDATE_DELETE_FAVOURITES_SELECTED_ITEM = "UPDATE_DELETE_FAVOURITES_SELECTED_ITEM",
  CHECK_OFFERS_ONLY = "CHECK_OFFERS_ONLY",
  FETCH_FIRST_FAVOURITES_PENDING = "FETCH_FIRST_FAVOURITES_PENDING",
  FETCH_FIRST_FAVOURITES_SUCCESS = "FETCH_FIRST_FAVOURITES_SUCCESS",
  FETCH_FIRST_FAVOURITES_FAILURE = "FETCH_FIRST_FAVOURITES_FAILURE",
  UPDATE_RECOMMENDATIONS = "UPDATE_RECOMMENDATIONS",
  UPDATE_RECOMMENDATIONS_SUCCESS = "UPDATE_RECOMMENDATIONS_SUCCESS",
  SET_FAVOURITES_IDLE = "SET_FAVOURITES_IDLE",
  SUCCESSFUL_SAVE_FAVOURITE = "SUCCESSFUL_SAVE_FAVOURITE",
  PENDING_SAVE_FAVOURITE = "PENDING_SAVE_FAVOURITE",
  ERROR_SAVE_FAVOURITE = "ERROR_SAVE_FAVOURITE",
  FAVOURITE_PRODUCT_ERROR = "FAVOURITE_PRODUCT_ERROR",
  CLEAR_FAVOURITE_PRODUCT_ERROR = "CLEAR_FAVOURITE_PRODUCT_ERROR",
  SHOW_FEATURE_TOUR = "SHOW_FEATURE_TOUR",
  HIDE_FEATURE_TOUR = "HIDE_FEATURE_TOUR",
  INCREASE_TOUR_STEP = "INCREASE_TOUR_STEP",
  START_FEATURE_TOUR = "START_FEATURE_TOUR",
  CLOSE_FEATURE_TOUR = "CLOSE_FEATURE_TOUR",
  NEXT_STEP_FEATURE_TOUR = "NEXT_STEP_FEATURE_TOUR",
  RESET_TOUR_STEP = "RESET_TOUR_STEP",
  FETCH_MAGNOLIA_SUCCESS = "FETCH_MAGNOLIA_SUCCESS",
}

export type FavouritesAction = {
  type: FavouritesActionTypes;
};

export enum FavouritesErrorActionTypes {
  DELETE = "DELETE",
  SAVE = "SAVE",
}

export type FavouritesErrorAction = {
  type: FavouritesActionTypes.FETCH_FAVOURITES_ERROR;
  message: string;
};

export type FetchFavouritesSuccessAction = {
  type: FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS;
  products: PaginatedProducts;
};

export type FavouritesSeasonalDataPendingAction = {
  type: FavouritesActionTypes;
};

export type FetchFavouritesSuccessWithErrorsAction = {
  type: FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS_WITH_ERRORS;
  errors: ProductServiceError[];
};

export type FetchSeasonalFavouritesSuccessWithErrorsAction = {
  type: FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_SUCCESS_WITH_ERRORS;
  errors: ProductServiceError[];
};

export type FetchSeasonalFavouritesSuccessAction = {
  type: FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_SUCCESS;
  products: PaginatedProducts;
};

export type SeasonalFavouritesErrorAction = {
  type: FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_ERROR;
  message: string;
};

/** @deprecated to be removed after add to favourites release */
export interface DeleteFavouritesSuccessAction {
  type: FavouritesActionTypes.DELETE_FAVOURITES_SUCCESS;
}

export interface FavouritesCheckOffersOnlyAction {
  type: FavouritesActionTypes.CHECK_OFFERS_ONLY;
  isOffersOnly: boolean;
}

/** @deprecated to be removed after add to favourites release */
export interface FavouritesDeleteFromProductsAction {
  type: FavouritesActionTypes.DELETE_FAVOURITES_FROM_PRODUCTS;
  productUid: string;
}

export interface FavouritesControls {
  sort?: string;
  filters?: FilterProduct[];
  pageSize?: number;
  pageNumber?: number;
}

/** @deprecated to be removed after add to favourites release */
export interface FavouritesDeleteAction {
  type: FavouritesActionTypes;
  favouriteProductUid: string;
}

/** @deprecated to be removed after add to favourites release */
export interface PendingSaveFavouriteAction {
  type: FavouritesActionTypes.PENDING_SAVE_FAVOURITE;
  productUid: string;
}

export interface SuccessfulSaveFavouriteAction {
  type: FavouritesActionTypes.SUCCESSFUL_SAVE_FAVOURITE;
}

export interface ErrorSaveFavouriteAction {
  type: FavouritesActionTypes.ERROR_SAVE_FAVOURITE;
  message: string;
}

export interface DeleteFavouriteProductErrorAction {
  type: FavouritesActionTypes.FAVOURITE_PRODUCT_ERROR;
  favouriteProductUid: string;
  status: FavouritesErrorActionTypes.DELETE;
}
export interface SaveFavouriteProductErrorAction {
  type: FavouritesActionTypes.FAVOURITE_PRODUCT_ERROR;
  favouriteProductUid: string;
  status: FavouritesErrorActionTypes.SAVE;
}
export interface ClearFavouritesErrorsAction {
  type: FavouritesActionTypes.CLEAR_FAVOURITE_PRODUCT_ERROR;
  favouriteProductUid: string;
}

export interface TrackAddFavouriteClickAction {
  type: FavouritesActionTypes.ADD_FAVOURITE_ADOBE_ANALYTICS_REQUEST;
  data_product_id: string[];
  data_product_favouriteUnitPrice: (number | undefined)[];
  data_product_name: string[];
  data_event_category: "Favourites";
  data_event_action: "click";
  data_event_label: "Add favourite";
}

export interface TrackRemoveFavouriteClickAction {
  type: FavouritesActionTypes.REMOVE_FAVOURITE_ADOBE_ANALYTICS_REQUEST;
  data_product_id: string[];
  data_product_favouriteUnitPrice: (number | undefined)[];
  data_product_name: string[];
  data_product_favouriteSource: FavouriteSourceAnalyticsType[];
  data_event_category: "Favourites";
  data_event_action: "click";
  data_event_label: "Remove favourite";
}

export interface TrackSortFavouritesAction {
  type: FavouritesActionTypes.SORT_FAVOURITES_ADOBE_ANALYTICS_REQUEST;
  hit_type: "view";
  data_sortBy: string;
  data_event_category: "Sort by selection";
  data_event_action: "Sort by interaction";
  data_event_label: string;
}
export interface TrackFeatureTourStartAction {
  type: FavouritesActionTypes.START_FEATURE_TOUR;
  data_event_category: "Favourites - Feature tour";
  data_event_action: "click";
  data_event_label: "Find out how";
  data_journey_type: TourJourneyTypes;
}

export interface TrackFeatureTourCloseAction {
  type: FavouritesActionTypes.CLOSE_FEATURE_TOUR;
  data_event_category: "Favourites - Feature tour";
  data_event_action: "click";
  data_event_label: "Close";
  data_tooltip_step: string;
  data_journey_type: TourJourneyTypes;
}

export interface TrackFeatureTourNextAction {
  type: FavouritesActionTypes.NEXT_STEP_FEATURE_TOUR;
  data_event_category: "Favourites - Feature tour";
  data_event_action: "click";
  data_event_label?: CtaTypes;
  data_tooltip_step: string;
  data_journey_type: TourJourneyTypes;
}

export interface FavouriteDisableModalFirstInteractionAction {
  type: FavouritesActionTypes;
  isFavouritesModalFirstInteraction: boolean;
}

export type FavouritesDataPendingAction = {
  type: FavouritesActionTypes;
  controls: ProductQueryParameters;
};

export type fetchFirstFavouritesSuccessAction = {
  type: FavouritesActionTypes;
  products: Object;
  serviceProducts: ProductService[];
};

export type fetchFavouritesSuccessWithErrorsFallbackAction = {
  type: FavouritesActionTypes;
  products: Object;
  serviceProducts: ProductService[];
  message: string;
};

export type fetchRecommendationsPendingAction = {
  type: FavouritesActionTypes;
};

export type fetchRecommendationsFailureAction = {
  type: FavouritesActionTypes;
};

export type fetchRecommendationsSuccessAction = {
  type: FavouritesActionTypes;
  recommendationToken: string;
  products: Object;
  serviceProducts: ProductService[];
};

export type FavouritesOffersOnlyActionType = {
  type: FavouritesActionTypes;
  isOffersOnly: boolean;
};

export type FavouritesStartShoppingActionType = {
  type: FavouritesActionTypes;
};

export type FavouritesFeatureTourActionType = {
  type: FavouritesActionTypes;
};

export type FavouritesLastSeenProductAction = {
  type: FavouritesActionTypes;
  product: string;
};

export type FavouriteDisableModalSuccessAction = {
  type: FavouritesActionTypes;
  isFavouritesModalDisabled: boolean;
};

export type favouritesDeleteSelectedItemType = {
  type: FavouritesActionTypes;
  favouritesDeleteSelectedItem: string;
  favouriteProductUid: string;
  status: string;
};

export type FavouriteDisableModalActionCreatorType = () => FavouriteDisableModalSuccessAction;

export type FavouriteDisableFirstInteractionType = () => FavouriteDisableFirstInteractionAction;

export type FavouriteDisableFirstInteractionAction = {
  type: FavouritesActionTypes.DISABLE_FAVOURITES_FIRST_INTERACTION;
  isFavouritesModalFirstInteraction: boolean;
};

export type FavouritesMagnoliaSuccessfulAction = {
  type: FavouritesActionTypes.FETCH_MAGNOLIA_SUCCESS;
  ads: IngridAd[];
};

export interface FavouritesSort {
  activeSort: string;
  sortOptions: SortOption[];
}

export interface FavouritesProps extends FavouritesStateProps {
  isInAmendMode: boolean;
  allFavouritesState: DataState;
  allProductsLength: number;
}

export interface FavouritesStateProps {
  products: ProductUid[];
  firstFavouriteProducts: ProductUid[];
  seasonalProducts: ProductUid[];
  favouritesByCategory: ProductsByCategory[];
  espots?: InterleavedEspot[];
  bannerAds?: InterleavedBannerAd[];
  recommendations: ProductUid[];
  favouritesDataState: DataState;
  seasonalDataState: DataState;
  isFavouritesError: boolean;
  isRecommendationPending: boolean;
  areFavouritesReady: boolean;
  areSeasonalFavouritesReady: boolean;
  showDietaryProfileWarning: boolean;
  alcoholWarning: boolean;
  isOffersOnly: boolean;
  isNIPostcode: boolean;
  lastSeenFavourite: string;
  currentPage?: number;
}

export interface NoFavouritesProps {
  noFavouritesStartShoppingTrack: () => void;
}

export interface FirstFavouritesContainerProps {
  firstFavouriteProducts: ProductUid[];
  isFavouritesError: boolean;
  addProductToViewportAnalytics?: UseProductsReturnType["addProductForViewportAnalytics"];
  template?: TemplateAreas;
}

export interface FirstFavouritesProps extends FirstFavouritesContainerProps {
  areFirstFavouritesReady: boolean;
  noFavouritesStartShoppingTrack: () => void;
}

export interface FavouritesControlsContainerStateToProps {
  activeSort?: string;
  filters?: FilterProduct;
  pageActive: number;
  pageLast: number;
  pageSize: number;
  pageSizeOptions: number[];
  sortOptions: SortOptionSelectField[];
}

export interface SortOptionSelectField {
  value: string;
  label: string;
}

export interface BrandsBackgrounds {
  brands?: BrandsBackground[];
}

export interface BrandsBackground {
  brand: string;
  theme: string;
  background: string;
}

export interface FavouritesControlsContainerDispatchToProps {
  changeView: (obj: ProductListControlsQueryParams) => void;
}

export interface FavouritesControlsProps
  extends FavouritesControlsContainerDispatchToProps,
    FavouritesControlsContainerStateToProps {
  isOffersOnly: boolean;
  isPending: boolean;
  hideFilters: boolean;
}

export interface SeasonalFavouritesProps {
  productUids: ProductUid[];
  addProductForViewportAnalytics?: any;
  scrollCallback?: () => void;
  carouselKey?: string;
}

export enum FavouritesCarouselsKey {
  SEASONAL_FAVOURITE_CAROUSEL = "seasonalFavouriteCarousel",
}

export enum FavouriteType {
  MANUALLY_ADDED = "MANUALLY_ADDED",
  FREQUENTLY_BOUGHT = "FREQUENTLY_BOUGHT",
}

export type FavouriteSourceAnalyticsType =
  | FavouriteSourceForAnalyticsTypes.MANUALLY_ADDED
  | FavouriteSourceForAnalyticsTypes.FREQUENTLY_BOUGHT
  | FavouriteSourceForAnalyticsTypes.NONE;

export enum FavouriteSourceForAnalyticsTypes {
  MANUALLY_ADDED = "manually added favourite",
  FREQUENTLY_BOUGHT = "frequently bought favourite",
  NONE = "non-favourite",
}

export enum FavouritesTooltipMessage {
  ADD = "Add to Favourites",
  REMOVE = "Remove from Favourites",
  ADDED = "Added to Favourites",
  REMOVED = "Removed from Favourites",
}

export type AddFavouritesFlag =
  | {
      add_to_favourites: {
        feature_tour: boolean;
        banner: boolean;
      };
    }
  | false;
