import React from "react";
import fruitImage from "../../static/empty-trolley.svg";

export const NotFoundFilterPage = () => (
  <div className="error-page ln-u-text-align-center ln-o-section padding-top-default">
    <div className="error-page__header">Sorry, we can't find what you're looking for.</div>
    <div className="error-page__description">Try removing some filters or check another previous order.</div>
    <div className="error-page__image">
      <img alt="Fruit" src={fruitImage} />
    </div>
  </div>
);

NotFoundFilterPage.displayName = "NotFoundFilterPage";
