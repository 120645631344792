import { Coupon as ServicesCoupon } from "../../services/coupon.types";
import groupBy from "lodash.groupby";
import { Card } from "../../services/checkout.types";
import React from "react";
import {
  AdobeDigitalDataProperties,
  AnalyticsActionTypes,
  AnalyticsPageViewAction,
  AnalyticsTrackPageLoadAction,
  PAGE_LOAD_EVENT_NAMES,
} from "../../common/analytics/types";
import { OrderItem } from "../../services/order.types";
import { QuantityType, ExistingOrder } from "../../common/dataLayer/types";

export interface GroupedCoupons {
  general: CouponsByStatus;
  nectar: CouponsByStatus;
}

export interface CouponsByStatus {
  applied: ServicesCoupon[];
  unapplied: ServicesCoupon[];
  previouslyApplied?: ServicesCoupon[];
}

export interface FetchServiceState {
  CARD: boolean;
  SLOT: boolean;
  BASKET: boolean;
  COUPON: boolean;
}

export interface RefProps {
  ref?: React.Ref<HTMLDivElement>;
}

enum CouponTypeGroup {
  GENERAL = "general",
  NECTAR = "nectar",
}

enum CouponStatusGroup {
  APPLIED = "applied",
  UNAPPLIED = "unapplied",
}

export interface CheckoutPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: string;
    template: string;
    newTemplate: string;
    section: string;
    shoppingMode: string;
    existingOrder?: ExistingOrder;
  };
}

export interface DigitalDataProduct {
  quantityType: QuantityType;
  quantity: number;
  productId: string | undefined;
  price: number;
}

export const getGroupedCoupons = (coupons: ServicesCoupon[]): GroupedCoupons => {
  const couponsGroupedByType = groupBy(coupons, (coupon: ServicesCoupon) => getCouponTypeGroup(coupon));

  const general = groupCouponsByStatus(couponsGroupedByType[CouponTypeGroup.GENERAL] || []);
  const nectar = groupCouponsByStatus(couponsGroupedByType[CouponTypeGroup.NECTAR] || []);

  return {
    general,
    nectar,
  };
};

const groupCouponsByStatus = (coupons: ServicesCoupon[]): CouponsByStatus => {
  const groups = groupBy(coupons, (coupon: ServicesCoupon) => getCouponStatusGroup(coupon));

  const applied = groups[CouponStatusGroup.APPLIED] || [];
  const unapplied = groups[CouponStatusGroup.UNAPPLIED] || [];

  return {
    applied,
    unapplied,
  };
};

const getCouponStatusGroup = (coupon: ServicesCoupon): CouponStatusGroup => {
  if (coupon.promotion.is_already_applied_to_cart) {
    return CouponStatusGroup.APPLIED;
  }
  return CouponStatusGroup.UNAPPLIED;
};

const getCouponTypeGroup = (coupon: ServicesCoupon): CouponTypeGroup => {
  if (coupon.promotion.nectar_voucher) {
    return CouponTypeGroup.NECTAR;
  }
  return CouponTypeGroup.GENERAL;
};

export const makePlural = (couponCount: number) => (couponCount > 1 ? "s" : "");

export const updateCoupon = (
  couponCode: string,
  isNectar: boolean,
  couponsList: GroupedCoupons,
  isRemoveCoupon: boolean
) => {
  const couponType = isNectar ? CouponTypeGroup.NECTAR : CouponTypeGroup.GENERAL;
  const couponStatusGroupToRemoveFrom = isRemoveCoupon ? CouponStatusGroup.APPLIED : CouponStatusGroup.UNAPPLIED;
  const couponStatusGroupToAddTo = isRemoveCoupon ? CouponStatusGroup.UNAPPLIED : CouponStatusGroup.APPLIED;
  const coupon = couponsList[`${couponType}`][`${couponStatusGroupToRemoveFrom}`].find(c => c.code === couponCode);

  if (coupon) {
    return {
      ...couponsList,
      [couponType]: {
        [couponStatusGroupToRemoveFrom]: couponsList[`${couponType}`][`${couponStatusGroupToRemoveFrom}`].filter(
          c => c.code !== couponCode
        ),
        [couponStatusGroupToAddTo]: [...couponsList[`${couponType}`][`${couponStatusGroupToAddTo}`], coupon],
      },
    };
  }
  return couponsList;
};

export const priortisePreferredCard = (cardList: Card[]) => {
  const preferredCardIndex = cardList.findIndex(card => card.is_preferred);
  if (preferredCardIndex > -1) {
    const preferredCard = cardList[`${preferredCardIndex}`];
    cardList.splice(preferredCardIndex, 1);
    cardList.unshift(preferredCard);
  }
  return cardList;
};

export const formatFetchError = (fetchErrorState: FetchServiceState) => {
  return `Sorry, we can’t show your ${
    getNumberOfFailedFetch(fetchErrorState) > 1 ? "checkout" : getFailedComponentName(fetchErrorState)
  } information at the moment.`;
};

const getFailedComponentName = (fetchErrorState: FetchServiceState) => {
  enum ComponentName {
    CARD = "card",
    SLOT = "slot",
    BASKET = "basket",
    COUPON = "coupon",
  }

  const firstFailedServiceIndex = Object.values(fetchErrorState).findIndex(value => value);
  const firstFailedServiceName = Object.keys(fetchErrorState)[`${firstFailedServiceIndex}`];
  return ComponentName[`${firstFailedServiceName}`];
};

export const getNumberOfFailedFetch = (fetchErrorState: FetchServiceState) => {
  return Object.values(fetchErrorState).filter(item => item).length;
};

export const hasFailedFetch = (fetchErrorState: FetchServiceState) => {
  return getNumberOfFailedFetch(fetchErrorState) >= 1;
};

export const isComponentOutlined = (fetchErrorState: FetchServiceState, componentState: boolean) => {
  return getNumberOfFailedFetch(fetchErrorState) === 1 && componentState;
};

export const formatErrorMessage = (errorList: Set<string>, fetchErrors: FetchServiceState) => {
  return hasFailedFetch(fetchErrors) ? [...errorList, formatFetchError(fetchErrors)] : [...errorList];
};

export const scrollTo = (
  ref: React.RefObject<HTMLDivElement>,
  blockPosition: ScrollLogicalPosition,
  isReducedMotion?: boolean
) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: isReducedMotion ? "auto" : "smooth", block: blockPosition });
    return;
  }
};

export const checkoutPageView = (adobeProperties: AdobeDigitalDataProperties): CheckoutPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "groceries:checkout:order review",
    template: "checkout",
    newTemplate: "checkoutorderreview",
    section: "checkout",
    shoppingMode: "normal",
  },
  adobeProperties,
});
export const mapQuantityType = (productType?: string, uom?: string) => {
  if (productType === "LOOSE" && uom === "kg") {
    return QuantityType.WEIGHT;
  }
  return QuantityType.UNITS;
};
export const mapOrderItemToDigitalDataProduct = (orderItems: OrderItem[]): DigitalDataProduct[] => {
  const items = orderItems.map(item => {
    const quantityType = mapQuantityType(item.product.product_type, item.uom);
    return {
      productId: item.product.product_uid,
      quantity: quantityType === QuantityType.WEIGHT ? 1 : item.quantity,
      quantityType,
      price: item.sub_total,
    };
  });

  return items;
};

export const checkoutPageViewAmendMode = (
  orderTotal: number,
  items: OrderItem[],
  adobeProperties: AdobeDigitalDataProperties
): CheckoutPageViewAction => {
  const totalQuantity =
    items &&
    items.reduce((accumulator, item) => {
      return accumulator + item.quantity;
    }, 0);
  return {
    type: AnalyticsActionTypes.PAGE_VIEW,
    page: {
      name: "groceries:checkout:order review",
      template: "checkout",
      newTemplate: "orderamendreview",
      section: "checkout",
      shoppingMode: "amend",
      existingOrder: {
        item: mapOrderItemToDigitalDataProduct(items),
        total: {
          totalQuantity,
          transactionCost: orderTotal,
        },
      },
    },
    adobeProperties,
  };
};

export const paymentAuthPageViewAction = (
  adobeProperties: AdobeDigitalDataProperties
): AnalyticsTrackPageLoadAction => ({
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD,
  page: {
    name: "web:groceries:checkout:payment authorisation",
    template: "payment authorisation",
    newTemplate: "payment authorisation",
    section: "checkout",
  },
  eventName: PAGE_LOAD_EVENT_NAMES.PL_PAYMENT_AUTHORISATION,
  adobeProperties,
});
