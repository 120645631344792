import React, { useEffect, useState } from "react";
import { useFeatureFlag } from "../../common/firebase/featureFlags.hooks";
import orangeLogo from "../../static/logo-sainsburys.svg";
import prideLogo from "../../static/logo-sainsburys-pride.svg";
import bhmLogo from "../../static/logo-sainsburys-bhm.svg";
import blackLogo from "../../static/logo-sainsburys-black.svg";
import { useHistory } from "react-router-dom";

//-- Assets MUST be SVG format
enum Logos {
  PRIDE = "PRIDE",
  BHM = "BHM",
  BLACK = "BLACK",
  ORANGE = "ORANGE",
}

export const Logo: () => React.ReactElement = () => {
  const activeLogo = useFeatureFlag("special_logo");
  const [logo, setLogo] = useState<string>(orangeLogo);

  const trackEvent = () => {
    (window as any).digitalData.track("ev_headernavClick", {
      hit_type: "link",
      data_event_action: "click",
      data_event_category: "header nav",
      data_event_label: "GOL Homepage",
    });
  };

  const history = useHistory();

  useEffect(() => {
    switch (activeLogo) {
      case Logos.PRIDE:
        setLogo(prideLogo);
        break;
      case Logos.BHM:
        setLogo(bhmLogo);
        break;
      case Logos.BLACK:
        setLogo(blackLogo);
        break;
      default:
        setLogo(orangeLogo);
    }
  }, [activeLogo]);

  return (
    <a
      data-testid="logo"
      href="/gol-ui/groceries"
      className="logo-image logo-image-header"
      onClick={(e: React.MouseEvent) => {
        trackEvent();
        e.stopPropagation();
        history.push("/gol-ui/groceries");
      }}
    >
      <img src={logo} className="logo" alt="Sainsbury's logo - go to Groceries home" />
    </a>
  );
};
