import { Action } from "redux";

export enum ErrorActionTypes {
  UNHANDLED_API_ERROR = "UNHANDLED_API_ERROR",
}

export interface UnhandledApiErrorAction extends Action {
  errCode: number | null;
  errTitle: string | null;
  errDetail: string | null;
}

export const unhandledApiError = (
  errCode: number | null = null,
  errTitle: string | null = null,
  errDetail: string | null = null
): UnhandledApiErrorAction => {
  return {
    type: ErrorActionTypes.UNHANDLED_API_ERROR,
    errCode,
    errTitle,
    errDetail,
  };
};
