import { Action, Dispatch, MiddlewareAPI } from "redux";
import moment from "moment";
import { isBrandedPage, updatedCitrusEvent } from "../../../views/BrandedPage/BrandedPage.utils";
import { CitrusAnalyticsType } from "../../citrus";
import {
  digitalDataGenericTrackEvent,
  digitalDataTrackEvent,
  digitalDataTrackLightboxView,
  postErrorEvent,
  postEvent,
  trackPageLoad,
} from "../../analytics/digitalData";
import { State } from "../../store";
import { FormActionTypes, FormErrorActionType, FormNames } from "../../forms";
import { RecommendationsActionTypes } from "../../recommendations/actions";
import { ProductActionType, ProductClickedAction } from "../../../components/ProductTile/productTile.types";
import {
  FavouritesActionTypes,
  FavouritesDeleteAction,
  FetchFavouritesSuccessAction,
  FetchSeasonalFavouritesSuccessAction,
  TrackAddFavouriteClickAction,
  TrackRemoveFavouriteClickAction,
  FavouriteSourceAnalyticsType,
  FavouriteSourceForAnalyticsTypes,
  TrackSortFavouritesAction,
  TrackFeatureTourStartAction,
  TrackFeatureTourCloseAction,
  TrackFeatureTourNextAction,
} from "@favourites/Favourites.types";
import { selectIsSeasonalFavourite, selectSeasonalFavouritePosition } from "@favourites/state/Favourites.selectors";
import { extractQueryParams } from "../../../components/ProductControls/productControls";
import {
  CheckPostcodeActionTypes,
  CheckPostcodeFailureActionType,
} from "../../../views/CheckPostcode/checkPostcode.types";
import { matchesPath, routes } from "../../../routes";
import { FetchPreviousOrdersSuccess, PreviousOrdersAction } from "../../../views/PreviousOrders/previousOrders.types";
import { FetchUserAction, UserActionTypes, selectIsRegistered } from "../../../components/Authorized";
import { AdImpressionAction, ProductDetailsAction } from "../../../views/ProductDetails/productDetails.types";
import { getGlobal } from "../../../global";
import {
  BasketActionTypes,
  BasketPromotion,
  BasketUpdateType,
  ClickSource,
  CreateUpdateBasketAdobeAnalyticsRequestActionType,
  CreateUpdateBasketRequestActionType,
  FetchBasketSuccessAction,
} from "../../../domain/basket/basket.types";
import { AssociationType, Product, ProductSource, ProductsViews } from "../../../domain/product/product.types";
import {
  calculateUnitPrices,
  clearBasket,
  mapBasketProduct,
  mapDomainBasketProductToDataLayerBasketProduct,
  sendBasketUpdateEventFromBasket,
  sendBasketUpdateEventFromProduct,
  setBasket,
} from "../../../common/dataLayer/basket";
import {
  addToFavouritesExperimentVariantEvent,
  fetchSeasonalFavouritesEvent,
  sendFavouriteDeleteModalEvent,
  sendFavouriteDeleteOneClickEvent,
  sendFavouritesDeleteEvent,
  sendFavouritesProductAPICallEvent,
  sendFavouritesSeasonalDeleteEvent,
  startShoppingAnalyticsEvent,
} from "../../dataLayer/favourites";
import {
  FavouritesPageDescription,
  listerPagePageDescription,
  NotApplicablePageDescription,
  PageDescription,
  PFMCategory,
  PreviousOrdersPageDescription,
  SearchPageDescription,
  BrandPageDescription,
  SeasonalFavouritesDescription,
  TrolleyPageDescription,
  OffersPageDescription,
  CheckoutSummaryPageDescription,
  QuantityType,
  BasketProduct,
  UnitPriceAndSaving,
  FulfilmentState,
  OfferType,
  EventsPageDescription,
  FeaturesPageDescription,
  HomePageDescription,
  MealDealPageDescription,
} from "../../dataLayer/types";
import { clearProducts, sendProductClickedEvent, setProducts } from "../../dataLayer/product";
import { FetchNectarOffersSuccessAction, NectarActions, NectarOffers } from "../../../domain/nectar/nectar.types";
import { DataState } from "../../dataState";
import { nectarInitialState } from "../../../domain/nectar/nectar.reducer";
import { featureFlags } from "../../firebase/featureFlags";
import { matchPath } from "react-router";
import { OffersDataSource } from "../../../views/Offers/config/offers.config";
import { getBrowseSubstrings } from "../../../views/Browse/Browse.utils";
import {
  AnalyticsActionTypes,
  AnalyticsTrackPageLoadAction,
  AnalyticsPageViewAction,
  AnalyticsTrackEventAction,
  AnalyticsTrackLightboxViewEventAction,
  SHOPPING_MODES,
  INTERACTION_EVENT_NAMES,
} from "../../analytics/types";
import { mapQuantityType } from "../../../views/Checkout/checkout";
import { AdMetaData, selectAdMetaData } from "../../ads";
import { NECTAR_CUSTOMER_TYPES } from "../../analytics/createDigitalData";
import { BasketItem } from "../../../services/basket.types";
import { mapProductSourceToAddToCartOrigin } from "./mapProductSourceToAddToCartOrigin/mapProductSourceToAddToCartOrigin";
import { SearchProductsSuccessAction, SearchActionType } from "../../../views/SearchResults/search.types";

const basketEvents = [ClickSource.TROLLEY, ClickSource.MINI_TROLLEY, ClickSource.CHANGES_TO_TROLLEY];

const formErrorTypeMap = {
  [FormNames.PROGRESSIVE_REGISTRATION]: "Progressive registration error",
};

let deferredUserAction = {
  resolve: (_: FetchUserAction) => {},
  reject: (reason?: any) => {},
};

let deferredNectarOfferAction = {
  resolve: (_: NectarOffers) => {},
  reject: (reason?: any) => {},
};

export const resetUserData = () => (userRequestPromise = getUserRequestPromise());
let userRequestPromise = getUserRequestPromise();
const nectarOfferRequestPromise = getNectarOfferRequestPromise();

const handleUpdateBasketRequest = (
  action: CreateUpdateBasketRequestActionType,
  state: State,
  qp: { pageNumber: number; pageSize: number }
): void => {
  const {
    sku,
    uom,
    quantityChange,
    selectedCatchweight,
    clickSource,
    product,
    basketProduct,
    carouselKey,
    fulfilmentState,
  } = action;
  const updateType: BasketUpdateType = quantityChange > 0 ? BasketUpdateType.ADD : BasketUpdateType.SUBTRACT;
  const quantityIncrement = Math.abs(quantityChange);
  const isEventFromBasket = basketEvents.includes(clickSource);
  const isNectarOffer = state.nectar.data.offers.some(offer => offer.skus.find(offerSku => offerSku === sku));
  const nectarOffer = state.nectar.data.offers.find(offer => offer.skus.find(offerSku => offerSku === sku));
  const nectarPoints = nectarOffer ? nectarOffer.points : 0;
  const isSeasonalFavourite = selectIsSeasonalFavourite(state, sku);
  const basketId = state.basket.basketDetails.basketId;

  if (basketProduct && isEventFromBasket) {
    const pageDescription = getPageDescription(state);
    sendBasketUpdateEventFromBasket(
      basketProduct,
      uom,
      quantityIncrement,
      updateType,
      pageDescription,
      isNectarOffer,
      basketId,
      nectarPoints,
      fulfilmentState
    );
    return;
  }

  if (product) {
    const { pageNumber, pageSize, list, adMetaData, pfmCategory, seasonalPosition } = prepareProductEventData(
      state,
      product,
      sku,
      qp,
      carouselKey,
      clickSource,
      isSeasonalFavourite
    );
    const isAuthenticatedUser = selectIsRegistered(state);
    if (isAuthenticatedUser) {
      // Currently only branded pages implement new citrus atb analytics
      const shouldSendNewCitrusATBEvent = isBrandedPage(state.router.location.pathname);
      if (shouldSendNewCitrusATBEvent) updatedCitrusEvent(state, product.productUid, CitrusAnalyticsType.ATB);

      sendBasketUpdateEventFromProduct(
        pageNumber,
        pageSize,
        product,
        uom,
        quantityIncrement,
        updateType,
        sku,
        list,
        pfmCategory,
        isNectarOffer,
        basketId,
        selectedCatchweight,
        adMetaData,
        seasonalPosition,
        fulfilmentState
      );
    }
  }
};

const prepareProductEventData = (
  state: State,
  product: Product,
  sku: string,
  qp: { pageNumber: number; pageSize: number },
  carouselKey: string | undefined,
  clickSource: ClickSource,
  isSeasonalFavourite: boolean
) => {
  const fromGrid = clickSource === ClickSource.PRODUCT_GRID;
  const pageNumber = fromGrid ? qp.pageNumber : 0;
  const pageSize = fromGrid ? qp.pageSize : 0;
  const disableTealiumProductAdTracking = featureFlags.get("disable_tealium_product_adtracking");
  let adMetaData;
  if (!disableTealiumProductAdTracking) {
    adMetaData = selectAdMetaData(state, sku);
  }

  //if carouselKey exist, set the list as a carouselKey value
  const pfmCategory = mapProductToPFMCategory(product, isSeasonalFavourite);
  const list =
    (pfmCategory === PFMCategory.HIERARCHY ||
      pfmCategory === PFMCategory.BROWSE_ZONE ||
      pfmCategory === PFMCategory.HOME_PAGE) &&
    carouselKey
      ? carouselKey + " Carousel"
      : product.list ?? getPageDescription(state, isSeasonalFavourite);
  const seasonalPosition = selectSeasonalFavouritePosition(state, sku);

  return { pageNumber, pageSize, list, adMetaData, pfmCategory, seasonalPosition };
};

export const analyticsMiddleware =
  (api: MiddlewareAPI<Dispatch, State>) => (next: Dispatch<any>) => async (action: Action) => {
    next(action);
    const state = api.getState();
    const qp = getQueryParams(api.getState().router.location.search);
    /** @deprecated to be removed after add to favourites release */
    const { favouriteProductUid } = action as FavouritesDeleteAction;

    switch (action.type) {
      case CheckPostcodeActionTypes.CHECK_POSTCODE_FAILURE:
        postErrorEvent("loginError", (action as CheckPostcodeFailureActionType).error);
        break;

      case FormActionTypes.FORM_ERROR:
        const formAction = action as FormErrorActionType;
        postErrorEvent(formErrorTypeMap[formAction.form] || formAction.form, formAction.error);
        break;

      case AnalyticsActionTypes.PAGE_VIEW:
        {
          const createDigitalDataParams = await getCreateDigitalDataParams({
            api,
            action: action as AnalyticsPageViewAction,
          });

          postEvent(createDigitalDataParams);
        }
        break;

      case AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD: {
        const analyticsTrackPageLoadAction = action as AnalyticsTrackPageLoadAction;
        const { eventName } = analyticsTrackPageLoadAction;

        const createDigitalDataParams = await getCreateDigitalDataParams({
          api,
          action: analyticsTrackPageLoadAction,
        });

        trackPageLoad({ ...createDigitalDataParams, eventName });
        break;
      }

      case AnalyticsActionTypes.DIGITAL_DATA_TRACK_EVENT: {
        const { eventName, data_event_category, data_event_action, data_event_label } =
          action as AnalyticsTrackEventAction;

        digitalDataTrackEvent({
          eventName,
          data_event_category,
          data_event_action,
          data_event_label,
        });
        break;
      }

      case AnalyticsActionTypes.DIGITAL_DATA_LIGHTBOX_VIEW_TRACK_EVENT: {
        const { eventName, data_page_name } = action as AnalyticsTrackLightboxViewEventAction;
        digitalDataTrackLightboxView({ eventName, data_page_name });
        break;
      }

      case UserActionTypes.FETCH_USER_SUCCESS:
        fetchUserSuccess(api, action as FetchUserAction);
        break;

      case UserActionTypes.FETCH_USER_FAILURE:
        deferredUserAction.reject("Fetch Failed");
        break;

      case NectarActions.FETCH_NECTAR_OFFERS_SUCCESS:
        fetchNectarOffersSuccess(api, (action as FetchNectarOffersSuccessAction).nectarOffers);
        break;

      case NectarActions.FETCH_NECTAR_OFFERS_ERROR:
        deferredNectarOfferAction.reject("Fetch Failed");
        break;

      case FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_SUCCESS: {
        // AB testing experiment events needs to be trigger after the season favourites call is success
        // else the experiment data won't be included as part of the page view data
        addToFavouritesExperimentVariantEvent(featureFlags.get("add_to_favourites"));
        const { products } = action as FetchSeasonalFavouritesSuccessAction;
        if (products.products.length > 0) {
          fetchSeasonalFavouritesEvent(products.products);
        }
        break;
      }

      case FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS: {
        const { products } = action as FetchFavouritesSuccessAction;
        sendFavouritesProductAPICallEvent(products.products);
        setProducts(FavouritesPageDescription, qp.pageNumber, qp.pageSize, products.products);
        break;
      }

      case SearchActionType.SEARCH_PRODUCTS_SUCCESS: {
        const { products } = action as SearchProductsSuccessAction;
        setProducts(SearchPageDescription, qp.pageNumber, qp.pageSize, products.products);
        break;
      }

      case SearchActionType.SEARCH_PRODUCTS_REQUEST: {
        clearProducts();
        break;
      }

      case PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_ERROR:
      case PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_PENDING:
      case FavouritesActionTypes.FETCH_FAVOURITES_ERROR:
      case FavouritesActionTypes.FETCH_FAVOURITES_PENDING:
        clearProducts();
        break;
      /** @deprecated to be removed after add to favourites release */
      case FavouritesActionTypes.DELETE_FAVOURITES_PENDING:
        if (selectIsSeasonalFavourite(state, favouriteProductUid)) {
          sendFavouritesSeasonalDeleteEvent();
        }
        sendFavouritesDeleteEvent();
        break;
      /** @deprecated to be removed after add to favourites release */
      case FavouritesActionTypes.DELETE_FAVOURITES_SUCCESS:
        const isAddToFavouritesEnabled = featureFlags.get("add_to_favourites");

        if (isAddToFavouritesEnabled) {
          if (selectIsSeasonalFavourite(state, favouriteProductUid)) {
            sendFavouritesSeasonalDeleteEvent();
          }
          sendFavouritesDeleteEvent();
        }
        break;
      case FavouritesActionTypes.DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_DISABLED:
        sendFavouriteDeleteOneClickEvent();
        break;
      case FavouritesActionTypes.START_SHOPPING_ANALYTICS_EVENT:
        startShoppingAnalyticsEvent();
        break;
      case FavouritesActionTypes.DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_ENABLED:
        const disabledModal = localStorage.hasOwnProperty("disable_favourites_modal");
        const isFavouritesModalFirstInteraction = !localStorage.hasOwnProperty("is_favourites_modal_first_interaction");

        sendFavouriteDeleteModalEvent(isFavouritesModalFirstInteraction, disabledModal);
        break;
      case PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_SUCCESS: {
        const { products } = action as FetchPreviousOrdersSuccess;
        setProducts(PreviousOrdersPageDescription, qp.pageNumber, qp.pageSize, products.products);
        break;
      }

      case BasketActionTypes.FETCH_BASKET_SUCCESS:
      case BasketActionTypes.UPDATE_BASKET_SUCCESS:
        const { nectar } = api.getState();
        const { basket } = action as FetchBasketSuccessAction;
        const list = getPageDescription(state);
        const isOrderConfirmationPage = matchesPath(state.router.location.pathname, routes.ORDER_CONFIRMATION);
        if (!isOrderConfirmationPage) {
          setBasket(basket, nectar.data.offers, list);
        }

        break;

      case BasketActionTypes.FETCH_BASKET_ERROR:
        clearBasket();
        break;

      case RecommendationsActionTypes.FETCH_RECOMMENDATIONS_SUCCESS:
        // TODO disable sending recommendations to analytics until we can come up with a
        //  solution that will allow them to process favourites & recommendations productImpression
        //  data at once
        // const { recommendations } = (action as FetchRecommendationsSuccessAction)
        // setProducts(ProductSource.RECOMMENDATION, recommendations)
        break;

      case BasketActionTypes.UPDATE_BASKET_REQUEST: {
        const updateBasketAction = action as CreateUpdateBasketRequestActionType;
        if (updateBasketAction.quantityChange > 0) {
          // Only report basket adds
          reportCitrusAdClick(state, updateBasketAction.sku);
        }
        handleUpdateBasketRequest(updateBasketAction, state, qp);
        break;
      }

      case BasketActionTypes.UPDATE_BASKET_ADOBE_ANALYTICS_REQUEST: {
        const updateBasketAdobeAnalyticsAction = action as CreateUpdateBasketAdobeAnalyticsRequestActionType;
        if (!selectIsRegistered(state)) break;
        const adobeProperties = handleUpdateBasketAdobeAnalyticsRequest(updateBasketAdobeAnalyticsAction, state, qp);
        if (adobeProperties) digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.ADD_TO_CART, adobeProperties);

        break;
      }

      case FavouritesActionTypes.ADD_FAVOURITE_ADOBE_ANALYTICS_REQUEST: {
        const {
          data_product_id,
          data_product_favouriteUnitPrice,
          data_product_name,
          data_event_action,
          data_event_category,
          data_event_label,
        } = action as TrackAddFavouriteClickAction;

        digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.ADD_FAVOURITE, {
          data_product_id,
          data_product_favouriteUnitPrice,
          data_product_name,
          data_event_action,
          data_event_category,
          data_event_label,
        });

        break;
      }

      case FavouritesActionTypes.REMOVE_FAVOURITE_ADOBE_ANALYTICS_REQUEST: {
        const {
          data_product_id,
          data_product_favouriteUnitPrice,
          data_product_name,
          data_product_favouriteSource,
          data_event_action,
          data_event_category,
          data_event_label,
        } = action as TrackRemoveFavouriteClickAction;

        digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.REMOVE_FAVOURITE, {
          data_product_id,
          data_product_favouriteUnitPrice,
          data_product_name,
          data_product_favouriteSource,
          data_event_action,
          data_event_category,
          data_event_label,
        });

        break;
      }

      case FavouritesActionTypes.SORT_FAVOURITES_ADOBE_ANALYTICS_REQUEST: {
        const { data_sortBy, data_event_action, data_event_category, data_event_label, hit_type } =
          action as TrackSortFavouritesAction;

        digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.SORT_FAVOURITES, {
          hit_type,
          data_sortBy,
          data_event_action,
          data_event_category,
          data_event_label,
        });

        break;
      }

      case FavouritesActionTypes.START_FEATURE_TOUR: {
        const { data_event_action, data_event_category, data_event_label, data_journey_type } =
          action as TrackFeatureTourStartAction;

        digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.FEATURE_TOUR, {
          data_event_action,
          data_event_category,
          data_event_label,
          data_journey_type,
        });

        break;
      }

      case FavouritesActionTypes.CLOSE_FEATURE_TOUR: {
        const { data_event_action, data_event_category, data_event_label, data_tooltip_step, data_journey_type } =
          action as TrackFeatureTourCloseAction;

        digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.FEATURE_TOUR, {
          data_event_action,
          data_event_category,
          data_event_label,
          data_tooltip_step,
          data_journey_type,
        });

        break;
      }

      case FavouritesActionTypes.NEXT_STEP_FEATURE_TOUR: {
        const { data_event_action, data_event_category, data_event_label, data_tooltip_step, data_journey_type } =
          action as TrackFeatureTourNextAction;

        digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.FEATURE_TOUR, {
          data_event_action,
          data_event_category,
          data_event_label,
          data_tooltip_step,
          data_journey_type,
        });

        break;
      }

      case ProductActionType.PRODUCT_CLICKED: {
        const { product } = action as ProductClickedAction;

        const pfmCategory = mapProductToPFMCategory(product);

        if (product) {
          reportCitrusAdClick(state, product.productUid);
          const disableTealiumProductAdTracking = featureFlags.get("disable_tealium_product_adtracking");
          let adMetaData;
          if (!disableTealiumProductAdTracking) {
            adMetaData = selectAdMetaData(state, product.productUid);
          }
          // Currently only branded pages implement new citrus click analytics
          const shouldSendNewCitrusClickEvent = product.source === ProductSource.BRANDSHELF;
          if (shouldSendNewCitrusClickEvent) updatedCitrusEvent(state, product.productUid, CitrusAnalyticsType.CLICK);
          else sendProductClickedEvent(qp.pageNumber, qp.pageSize, product, pfmCategory, adMetaData);
        }
        break;
      }

      case ProductDetailsAction.AD_IMPRESSION: {
        const { adId } = action as AdImpressionAction;
        // Fire and forget
        createAdImpression(adId);
        break;
      }

      default:
        break;
    }
  };

export const createAdImpression = (adId: string) =>
  fetch(`${getGlobal("GOL").config.citrusUrl}/v1/resource/first-i/${adId}`);

export const reportCitrusAdClick = (state: State, productUid: string) => {
  const adMetaData = selectAdMetaData(state, productUid);
  if (adMetaData && adMetaData.type === "Citrus") {
    fetch(`${getGlobal("GOL").config.citrusUrl}/v1/resource/second-c/${adMetaData.id}`);
  }
};

async function getCreateDigitalDataParams({
  api,
  action: { page, adobeProperties },
}: {
  api: MiddlewareAPI<Dispatch, State>;
  action: AnalyticsPageViewAction | AnalyticsTrackPageLoadAction;
}) {
  const state = api.getState();
  let user = state.user.userDetails;
  let nectar = state.nectar.data;
  const region = state.slotReservation.slotReservation.region;
  const referrer = state.location.referrer;
  const shoppingMode = state.basket.basketDetails.isInAmendMode ? SHOPPING_MODES.AMEND : SHOPPING_MODES.NORMAL;

  if (
    state.slotReservation &&
    state.slotReservation.slotReservation &&
    state.slotReservation.slotReservation.reservationStartTime &&
    state.slotReservation.slotReservation.reservationTimeSlot
  ) {
    const deliverySlot = getSlotDetailsSlotTime(
      state.slotReservation.slotReservation.reservationStartTime,
      state.slotReservation.slotReservation.reservationTimeSlot
    );
    page.deliverySlot = deliverySlot;
  }

  const userRequestSettled = state.user.dataState !== DataState.PENDING;
  const nectarRequestSettled = state.nectar.dataState !== DataState.PENDING;

  if (userRequestSettled && nectarRequestSettled) {
    return {
      page,
      referrer,
      user,
      nectar,
      region,
      shoppingMode,
      adobeProperties,
    };
  }

  if (!user) {
    const userInfo = await userRequestPromise;
    if (userInfo) {
      user = userInfo.user;
    }
  }
  if (!nectar || nectar === nectarInitialState.data) {
    const nectarOffers = await nectarOfferRequestPromise;

    if (nectarOffers) {
      nectar = nectarOffers;
    }
  }

  return {
    page,
    referrer,
    user,
    nectar,
    region,
    shoppingMode,
    adobeProperties,
  };
}

function fetchUserSuccess(api: MiddlewareAPI<Dispatch, State>, user: FetchUserAction) {
  if (user) {
    deferredUserAction.resolve(user);
  } else {
    deferredUserAction.reject("NO User");
  }
}

function fetchNectarOffersSuccess(api: MiddlewareAPI<Dispatch, State>, nectarOffers: NectarOffers) {
  if (nectarOffers) {
    deferredNectarOfferAction.resolve(nectarOffers);
  } else {
    deferredNectarOfferAction.reject("NO nectar offers");
  }
}

const getQueryParams = (queryString: string): { pageNumber: number; pageSize: number } => {
  const qp = extractQueryParams(queryString);
  return {
    pageNumber: Number(qp.pageNumber || 1),
    pageSize: Number(qp.pageSize || 60),
  };
};

export const mapProductToPFMCategory = (product: Product, isSeasonalFavourites?: boolean): PFMCategory | string => {
  const { source, associationParentProductUid, associationType, isSpotlight, favouriteUid } = product;

  if (source?.indexOf("findability") === 0) {
    return source;
  }

  switch (source) {
    case ProductSource.KRANG:
      return PFMCategory.KRANG;
    case ProductSource.FREQUENTLY_BOUGHT_TOGETHER:
      return PFMCategory.FREQUENTLY_BOUGHT_TOGETHER;
    case ProductSource.PRODUCT_LISTER:
      return PFMCategory.PRODUCT_LISTER;
    case ProductSource.CITRUS_SEARCH_ADS_BELOW_GRID:
      return PFMCategory.CITRUS_SEARCH_ADS_BELOW_GRID;
    case ProductSource.CITRUS_SEARCH_ADS_IN_GRID:
      return PFMCategory.CITRUS_SEARCH_ADS_IN_GRID;
    case ProductSource.CITRUS_XSELL_AD_PDP:
      return PFMCategory.CITRUS_XSELL_AD_PDP;
    case ProductSource.CITRUS_XSELL_AD_FAVOURITES:
      return PFMCategory.CITRUS_XSELL_AD_FAVOURITES;
    case ProductSource.CITRUS_CHECKOUT_UPSELL:
      return PFMCategory.CITRUS_CHECKOUT_UPSELL;
    case ProductSource.CITRUS_OFFERS:
      return PFMCategory.CITRUS_OFFERS;
    case ProductSource.CHECKOUT_UPSELL:
      return PFMCategory.CHECKOUT_UPSELL;
    case ProductSource.CHECKOUT:
      return PFMCategory.CHECKOUT;
    case ProductSource.CHECKOUT_FAVOURITES:
      return PFMCategory.CHECKOUT_FAVOURITES;
    case ProductSource.PREVIOUS_ORDERS:
      return PFMCategory.PREVIOUS_ORDERS;
    case ProductSource.PRODUCT_DETAILS:
      return PFMCategory.PRODUCT_DETAILS;
    case ProductSource.TROLLEY:
      return PFMCategory.TROLLEY;
    case ProductSource.CROSS_SELL:
      return PFMCategory.CROSS_SELL;
    case ProductSource.ZONE_OFFERS:
      return PFMCategory.ZONE_OFFERS;
    case OffersDataSource.GREAT_OFFERS:
      return PFMCategory.GREAT_OFFERS;
    case ProductSource.HIERARCHY:
      return PFMCategory.HIERARCHY;
    case ProductSource.BRANDSHELF:
      return PFMCategory.BRANDEDSHELF;
    case ProductSource.NECTAR_DESTINATION:
      return PFMCategory.NECTAR_DESTINATION;
    case ProductSource.EVENTS:
      return PFMCategory.EVENTS;
    case ProductSource.FEATURES:
      return PFMCategory.FEATURES;
    case ProductSource.HOME_PAGE:
      return PFMCategory.HOME_PAGE;
    case ProductSource.MEAL_DEAL_BUILDER:
      return PFMCategory.MEAL_DEAL_BUILDER;
    case ProductSource.BROWSE_ZONE:
      return PFMCategory.BROWSE_ZONE;
  }

  if (associationParentProductUid) {
    switch (associationType) {
      case AssociationType.APE:
        return PFMCategory.APE;
      case AssociationType.APE_OVERRIDE:
        return PFMCategory.APE_OVERRIDE;
      case AssociationType.KRANG:
        return PFMCategory.KRANG;
      case AssociationType.CITRUS_CROSSELL:
        break;
      default:
        return PFMCategory.CROSS_SELL;
    }
  }

  if (source === ProductSource.SEARCH_RESULTS) {
    if (isSpotlight) {
      return PFMCategory.SPOTLIGHT;
    }
    if (favouriteUid !== null) {
      return PFMCategory.FAVOURITES;
    }
    return PFMCategory.SEARCH_RESULTS;
  }

  if (isSeasonalFavourites) {
    return PFMCategory.SEASONAL_FAVOURITES;
  }

  return PFMCategory.FAVOURITES;
};

export const getPageDescription = (state: State, isSeasonalFavourites?: boolean): PageDescription | string => {
  const route = state.router.location.pathname;
  const getDescriptionForRoute = (route: string): PageDescription | undefined => {
    switch (true) {
      case matchesPath(route, routes.PREVIOUS_ORDERS):
        return PreviousOrdersPageDescription;
      case matchesPath(route, routes.FAVOURITES):
        return isSeasonalFavourites ? SeasonalFavouritesDescription : FavouritesPageDescription;
      case matchesPath(route, routes.SEARCH_RESULTS_FINDABILITY):
        return SearchPageDescription;
      case matchesPath(route, routes.BRAND_SHELF):
        return BrandPageDescription;
      case matchesPath(route, routes.EVENTS):
        return EventsPageDescription;
      case matchesPath(route, routes.FEATURES):
        return FeaturesPageDescription;
      case matchesPath(route, routes.MEAL_DEAL_BUILDER):
        return MealDealPageDescription;
      case matchesPath(route, routes.PRODUCT_LISTER):
        return listerPagePageDescription;
      case matchesPath(route, routes.PRODUCT_DETAILS):
        const product = state.products.data.get(state.products.views[ProductsViews.PRODUCT_DETAILS][0]);
        if (product && product.breadcrumbs) {
          return product.breadcrumbs.map(breadcrumb => breadcrumb.label.toLowerCase()).join(":");
        }
        break;
      case matchesPath(route, routes.TROLLEY):
        return TrolleyPageDescription;
      case matchesPath(route, routes.CHECKOUT_SUMMARY):
        return CheckoutSummaryPageDescription;
      case matchesPath(route, routes.OFFERS):
        return OffersPageDescription;
      case matchesPath(route, routes.OFFERS_ZONE_PAGE):
        const match = matchPath(route, routes.OFFERS_ZONE_PAGE);
        const zonePath = match?.params["zonePath"];
        let pfmSegment = zonePath?.replace(/-/g, " ");

        if (zonePath === "top-picks") {
          pfmSegment = PFMCategory.GREAT_OFFERS.replace("_", " ");
        }

        if (zonePath?.indexOf("personalised") === 0) {
          pfmSegment = PFMCategory.KRANG;
        }

        return `zone:${pfmSegment}`;
      case Boolean(matchPath(route, { path: routes.BROWSE, exact: true, strict: true })):
        return HomePageDescription;
      case Boolean(matchPath(route, { path: routes.BROWSE, exact: false, strict: false })):
        return getBrowseSubstrings(route).tree;
      default:
        return NotApplicablePageDescription;
    }
  };

  return getDescriptionForRoute(route) ?? NotApplicablePageDescription;
};

function getUserRequestPromise() {
  return new Promise<FetchUserAction>(
    (resolve, reject) =>
      (deferredUserAction = {
        resolve,
        reject,
      })
  ).catch(() => {});
}

function getNectarOfferRequestPromise() {
  return new Promise<NectarOffers>(
    (resolve, reject) =>
      (deferredNectarOfferAction = {
        resolve,
        reject,
      })
  ).catch(() => {});
}

export const getSlotDetailsSlotTime = (reservationStartTime: string, reservationTimeSlot: string): string => {
  const date = moment.tz(reservationStartTime, "Europe/London").format("ddd DD MMM");
  return `${date} - ${reservationTimeSlot}`;
};

const handleUpdateBasketAdobeAnalyticsRequest = (
  action: CreateUpdateBasketAdobeAnalyticsRequestActionType,
  state: State,
  qp: { pageNumber: number; pageSize: number }
) => {
  const {
    sku,
    uom,
    quantityChange,
    selectedCatchweight,
    clickSource,
    product,
    basketProduct,
    basketProductQuantity,
    basketProductSubtotalPrice,
    basketProductTotalBasePrice,
    basketPriceIncrement,
    nectarPromotion,
    carouselKey,
    fulfilmentState,
    basketItem,
  } = action;
  let unitPriceAndSaving = undefined;

  if (basketProductSubtotalPrice && basketProductTotalBasePrice && basketProductQuantity) {
    unitPriceAndSaving = calculateUnitPrices(
      basketProductSubtotalPrice,
      basketProductTotalBasePrice,
      basketProductQuantity
    );
  }

  const updateType: BasketUpdateType = quantityChange > 0 ? BasketUpdateType.ADD : BasketUpdateType.SUBTRACT;
  const quantityIncrement = Math.abs(quantityChange);
  const isEventFromBasket = basketEvents.includes(clickSource);
  const isNectarOffer = nectarPromotion ? true : false;
  const nectarOffer = state.nectar.data.offers.find(offer => offer.skus.find(offerSku => offerSku === sku));
  const nectarPoints = nectarOffer ? nectarOffer.points : 0;
  const isSeasonalFavourite = selectIsSeasonalFavourite(state, sku);

  let properties = initializeAddToCartAdobePropertiesObject();

  const priceIncrement = basketPriceIncrement && basketPriceIncrement >= 0 ? basketPriceIncrement : 0;
  if (updateType === BasketUpdateType.ADD) {
    if (basketProduct && isEventFromBasket) {
      const pageDescription = getPageDescription(state);
      const quantityType = mapQuantityType(basketProduct.productType, uom);
      const quantityValue = quantityType === QuantityType.WEIGHT ? 1 : basketProduct.quantity;
      const price = calculateUnitPrices(basketProduct.subTotal, Number(basketProduct.totalBasePrice), quantityValue);

      const mappedBasketProduct = mapDomainBasketProductToDataLayerBasketProduct(
        basketProduct,
        pageDescription,
        isNectarOffer,
        nectarPoints
      );
      const dataLayerBasketProduct: BasketProduct = {
        ...mappedBasketProduct,
        quantityType,
        quantityValue: quantityIncrement,
        finalPrice: price.unitPrice,
      };
      const reviewProduct = product === undefined ? ({} as Product) : product;
      properties = createAddToCartAdobeDigitalDataProperties({
        productReview: reviewProduct,
        product: dataLayerBasketProduct,
        priceIncrement,
        unitPriceAndSaving,
        clickSource,
        nectarPromotion,
        productSource: undefined,
        adMetaData: undefined,
        carouselKey: undefined,
        fulfilmentState,
        basketItem,
      });
    } else {
      if (product) {
        const fromGrid = clickSource === ClickSource.PRODUCT_GRID;
        const pageNumber = fromGrid ? qp.pageNumber : 0;
        const pageSize = fromGrid ? qp.pageSize : 0;
        const adMetaData = selectAdMetaData(state, sku);

        const list = product.list ?? getPageDescription(state, isSeasonalFavourite);
        const seasonalPosition = selectSeasonalFavouritePosition(state, sku);

        const pfmCategory = mapProductToPFMCategory(product, isSeasonalFavourite);
        const dataLayerBasketProduct = mapBasketProduct(
          pageNumber,
          pageSize,
          product,
          uom,
          quantityIncrement,
          sku,
          list,
          pfmCategory,
          isNectarOffer,
          selectedCatchweight,
          adMetaData,
          seasonalPosition
        );
        properties = createAddToCartAdobeDigitalDataProperties({
          productReview: product,
          product: dataLayerBasketProduct,
          priceIncrement,
          unitPriceAndSaving,
          clickSource,
          nectarPromotion,
          productSource: product.source,
          adMetaData,
          carouselKey,
          fulfilmentState,
          basketItem,
        });
      }
    }

    return properties;
  }
};

interface CreateAddToCartAdobeDigitalDataPropertiesParams {
  productReview: Product;
  product: BasketProduct;
  priceIncrement: number;
  unitPriceAndSaving?: UnitPriceAndSaving;
  clickSource?: ClickSource;
  nectarPromotion?: BasketPromotion;
  productSource?: ProductSource | string;
  adMetaData?: AdMetaData;
  carouselKey?: string | undefined;
  fulfilmentState?: FulfilmentState;
  basketItem?: BasketItem;
}

export const createAddToCartAdobeDigitalDataProperties = ({
  productReview,
  product,
  priceIncrement,
  unitPriceAndSaving,
  clickSource,
  nectarPromotion,
  productSource,
  adMetaData,
  carouselKey,
  fulfilmentState,
  basketItem,
}: CreateAddToCartAdobeDigitalDataPropertiesParams): AddToCartAdobeProperties => {
  const nectarCustomerType = (window as any).digitalData?.user?.profile?.profileInfo?.nectar_customer_type
    ? (window as any).digitalData?.user?.profile?.profileInfo?.nectar_customer_type
    : undefined;
  const isNectarUser = nectarCustomerType && nectarCustomerType !== NECTAR_CUSTOMER_TYPES.NON_NECTAR ? true : false;
  const shoppingMode = (window as any).digitalData.data_shoppingMode === "amend" ? "amend" : "primary";

  const addToCartOrigin = mapProductSourceToAddToCartOrigin(productSource, clickSource, carouselKey);
  const fulfilment = fulfilmentState ? [fulfilmentState] : [""];
  const addToCartPrice = getAddToCartPrice(priceIncrement);
  const addToCartUnitPrice = getAddToCartUnitPrice(
    product,
    addToCartPrice,
    unitPriceAndSaving,
    nectarPromotion?.[0],
    isNectarUser
  );
  const addToCartOriginalPrice = getAddToCartOriginalPrice(product, addToCartPrice, unitPriceAndSaving);
  const addToCartOriginalUnitPrice = getAddToCartOriginalUnitPrice(product, addToCartUnitPrice, unitPriceAndSaving);

  const weight = [getAddToCartWeight(product)];
  const unitPrice = [addToCartUnitPrice];
  const quantity = [getAddToCartQuantity(product)];
  const reviewCount = [getAddToCartReviewCount(productReview)];
  const reviewScore = [getAddToCartReviewScore(productReview)];
  const itemType = getAddToCartItemType(product, clickSource, adMetaData)
    ? [getAddToCartItemType(product, clickSource, adMetaData)]
    : [];
  const originalUnitPrice = [addToCartOriginalUnitPrice];
  const price = [addToCartPrice];
  const originalPrice = [addToCartOriginalPrice];
  const priceSaving = [getAddToCartPriceSaving(addToCartPrice, addToCartOriginalPrice)];
  const unitPriceSaving = [getAddToCartUnitPriceSaving(addToCartUnitPrice, addToCartOriginalUnitPrice)];
  const offerType = [getAddToCartOfferType(product, isNectarUser)];
  const quantityType = [getAddToCartQuantityType(product) || ""];
  const favouriteSource = [getAddToCartFavouriteSource(product)];
  const [offerNames, offersQualified] = getAddToCartOfferNamesAndQualified(basketItem, product);

  const properties: AddToCartAdobeProperties = {
    data_product_id: [product?.sku!],
    data_product_addToCartWeight: weight,
    data_product_addToCartQuantity: quantity,
    data_product_addToCartUnitPrice: unitPrice,
    data_product_reviewCount: reviewCount,
    data_product_reviewScore: reviewScore,
    data_shoppingMode: shoppingMode,
    data_product_addToCartItemType: itemType,
    data_product_addToCartOriginList: addToCartOrigin,
    data_product_fulfilmentState: fulfilment,
    data_product_quantityType: quantityType,
    data_product_originalUnitPrice: originalUnitPrice,
    data_product_addToCartPrice: price,
    data_product_originalPrice: originalPrice,
    data_product_priceSaving: priceSaving,
    data_product_unitPriceSaving: unitPriceSaving,
    data_product_offerType: offerType,
    data_product_favouriteSource: favouriteSource,
    data_product_offerNames: offerNames,
    data_product_offersQualified: offersQualified,
  };

  return properties;
};

export interface AddToCartAdobeProperties {
  data_product_id: Array<string>;
  data_product_addToCartWeight: Array<number>;
  data_product_addToCartQuantity: Array<number>;
  data_product_addToCartUnitPrice: Array<number>;
  data_product_reviewCount: Array<number>;
  data_product_reviewScore: Array<number>;
  data_shoppingMode: string;
  data_product_addToCartItemType: Array<string | undefined>;
  data_product_addToCartOriginList: string;
  data_product_fulfilmentState: Array<string>;
  data_product_quantityType: Array<string>;
  data_product_originalUnitPrice: Array<number>;
  data_product_addToCartPrice: Array<number>;
  data_product_originalPrice: Array<number>;
  data_product_priceSaving: Array<number>;
  data_product_unitPriceSaving: Array<number>;
  data_product_offerType: Array<string>;
  data_product_favouriteSource: Array<FavouriteSourceAnalyticsType>;
  data_product_offerNames: Array<string>;
  data_product_offersQualified: Array<boolean>;
}
export const initializeAddToCartAdobePropertiesObject = () => {
  const addToCartAdobeProperties: AddToCartAdobeProperties = {
    data_product_id: [],
    data_product_addToCartWeight: [],
    data_product_addToCartQuantity: [],
    data_product_addToCartUnitPrice: [],
    data_product_reviewCount: [],
    data_product_reviewScore: [],
    data_shoppingMode: "",
    data_product_addToCartItemType: [],
    data_product_addToCartOriginList: "",
    data_product_fulfilmentState: [""],
    data_product_quantityType: [],
    data_product_originalUnitPrice: [],
    data_product_addToCartPrice: [],
    data_product_originalPrice: [],
    data_product_priceSaving: [],
    data_product_unitPriceSaving: [],
    data_product_offerType: [],
    data_product_favouriteSource: [],
    data_product_offerNames: [],
    data_product_offersQualified: [],
  };

  return addToCartAdobeProperties;
};

function getAddToCartUnitPrice(
  product: BasketProduct,
  addToCartPrice: number,
  unitPriceAndSaving?: UnitPriceAndSaving,
  nectarPromotion?: BasketPromotion,
  isNectarUser?: boolean
) {
  let addToCartUnitPrice = 0;

  if (unitPriceAndSaving && unitPriceAndSaving.unitPrice) {
    if (product.quantityType && product.quantityType === QuantityType.UNITS) {
      if ((!product.priceType || product.priceType === "ea" || product.priceType === "unit") && product.finalPrice) {
        if (
          (nectarPromotion && nectarPromotion.promotionMissed) ||
          (product.offerType && product.offerType === OfferType.NECTAR_PRICE && !isNectarUser)
        ) {
          addToCartUnitPrice = unitPriceAndSaving.unitPrice;
        } else {
          addToCartUnitPrice = product.finalPrice;
        }
      } else if (product.priceType !== "ea") {
        addToCartUnitPrice = unitPriceAndSaving.unitPrice;
      }
    } else if (
      product.quantityType &&
      product.quantityType === QuantityType.WEIGHT &&
      product.quantityValue &&
      unitPriceAndSaving.unitPrice
    ) {
      addToCartUnitPrice = Math.round(unitPriceAndSaving.unitPrice * product.quantityValue * 100) / 100;
    }
  }

  //Adding additional logic to handle typical price reduction scenario
  if (
    product.offerType === OfferType.TYPICAL_PRICE_REDUCTION &&
    product.quantityValue &&
    product.quantityType !== QuantityType.WEIGHT
  ) {
    addToCartUnitPrice = Math.round((addToCartPrice / product.quantityValue) * 100) / 100;
  }

  return addToCartUnitPrice;
}

function getAddToCartPrice(basketPriceIncrement: number) {
  return basketPriceIncrement >= 0 ? basketPriceIncrement : 0;
}

function getAddToCartOriginalPrice(
  product: BasketProduct,
  addToCartPrice: number,
  unitPriceAndSaving?: UnitPriceAndSaving
) {
  let addToCartOriginalPrice = 0;

  if (unitPriceAndSaving && unitPriceAndSaving.originalUnitPrice && product.quantityValue) {
    addToCartOriginalPrice = Math.round(unitPriceAndSaving.originalUnitPrice * product.quantityValue * 100) / 100;
  }
  if (product.offerType === OfferType.ORIGINAL_PRICE) {
    addToCartOriginalPrice = addToCartPrice;
  }

  return addToCartOriginalPrice;
}

function getAddToCartOriginalUnitPrice(
  product: BasketProduct,
  addToCartUnitPrice: number,
  unitPriceAndSaving?: UnitPriceAndSaving
) {
  let addToCartOriginalUnitPrice = 0;

  if (unitPriceAndSaving && unitPriceAndSaving.originalUnitPrice) {
    if (product.quantityType && product.quantityType === QuantityType.WEIGHT && product.quantityValue) {
      addToCartOriginalUnitPrice = Math.round(unitPriceAndSaving.originalUnitPrice * product.quantityValue * 100) / 100;
    } else {
      addToCartOriginalUnitPrice = unitPriceAndSaving.originalUnitPrice;
    }
  }
  if (product.offerType === OfferType.ORIGINAL_PRICE) {
    addToCartOriginalUnitPrice = addToCartUnitPrice;
  }

  return addToCartOriginalUnitPrice;
}

function getAddToCartWeight(product: BasketProduct) {
  let addToCartWeight = 0;

  if (product.quantityType === QuantityType.WEIGHT && product.quantityValue) {
    addToCartWeight = product.quantityValue;
  }

  return addToCartWeight;
}

function getAddToCartQuantity(product: BasketProduct) {
  let addToCartQuantity = 0;

  if (product.quantityType) {
    if (product.quantityType === QuantityType.WEIGHT) {
      addToCartQuantity = 1;
    } else {
      if (product.quantityValue) {
        addToCartQuantity = product.quantityValue;
      }
    }
  }

  return addToCartQuantity;
}

function getAddToCartReviewCount(product: Product) {
  const reviewsNumber = product?.reviews?.numberOfReviews;

  return reviewsNumber && reviewsNumber >= 0 ? reviewsNumber : 0;
}

function getAddToCartReviewScore(product: Product) {
  const reviewScore = product?.reviews?.averageRating;

  return reviewScore && reviewScore >= 0 ? reviewScore : 0;
}

const getAddToCartItemType = (
  product: BasketProduct,
  clickSource: ClickSource = ClickSource.PRODUCT_GRID,
  adMetaData?: AdMetaData
): string | undefined => {
  let addToCartItemType;
  if (product.isUserFavorite || product.favourite) {
    addToCartItemType = "Favourite";
  }

  if (product.isSpotlight) {
    addToCartItemType = "Spotlight";
  }

  if (
    product.pfmCategory === PFMCategory.KRANG ||
    product.pfmCategory === PFMCategory.FREQUENTLY_BOUGHT_TOGETHER ||
    clickSource === ClickSource.FREQUENTLY_BOUGHT_TOGETHER
  ) {
    addToCartItemType = "Recommended";
  }

  if (adMetaData?.type === "Citrus") {
    addToCartItemType = adMetaData?.type;
  }

  return addToCartItemType;
};

function getAddToCartPriceSaving(addToCartPrice: number, originalPrice: number) {
  let addToCartPriceSaving = 0;

  if (originalPrice !== undefined && addToCartPrice !== undefined) {
    addToCartPriceSaving = Math.round((originalPrice - addToCartPrice) * 100) / 100;
  }

  //avoid send saving when there is a rounding on the price in the trolley section
  if (Math.abs(addToCartPriceSaving) === 0.01) {
    addToCartPriceSaving = 0;
  }

  return addToCartPriceSaving;
}

function getAddToCartUnitPriceSaving(addToCartUnitPrice: number, originalUnitPrice: number) {
  let addToCartUnitPriceSaving = 0;

  if (originalUnitPrice) {
    addToCartUnitPriceSaving = Math.round((originalUnitPrice - addToCartUnitPrice) * 100) / 100;
  }

  return addToCartUnitPriceSaving;
}

function getAddToCartOfferType(product: BasketProduct, isNectarUser?: boolean) {
  let addToCartOfferType = OfferType.ORIGINAL_PRICE;

  if (isNectarUser) {
    if (
      product.offerType === OfferType.YOUR_NECTAR_PRICE ||
      product.offerType === OfferType.TYPICAL_PRICE_REDUCTION ||
      product.offerType === OfferType.ORIGINAL_PRICE
    ) {
      addToCartOfferType = product.offerType;
    } else {
      addToCartOfferType = OfferType.NECTAR_PRICE;
    }
  } else if (product.offerType === OfferType.TYPICAL_PRICE_REDUCTION) {
    addToCartOfferType = product.offerType;
  }

  return addToCartOfferType;
}

function getAddToCartQuantityType(product: BasketProduct) {
  const { quantityType, isWeightRangeAvailable } = product;

  return isWeightRangeAvailable ? QuantityType.WEIGHTRANGE : quantityType;
}

const getAddToCartFavouriteSource = (product: BasketProduct): FavouriteSourceAnalyticsType => {
  return product.favouriteSource ?? FavouriteSourceForAnalyticsTypes.NONE;
};

function getAddToCartOfferNamesAndQualified(
  basketItem?: BasketItem,
  basketProduct?: BasketProduct
): [string[], boolean[]] {
  const qualifiedOffers: string[] = [];
  const offersQualified: boolean[] = [];

  if (!basketItem || !basketProduct || !basketItem?.promotions || !basketProduct?.promotions) {
    return [qualifiedOffers, offersQualified];
  }

  for (const promotion of basketProduct.promotions) {
    qualifiedOffers.push(promotion.strapLine);
    // This is done due to the fact that the promotions are not always present in the basketItem
    // when returned from the basket endpoint
    // If the promotion is not found in the basketItem, it is assumed that the offer is not qualified
    offersQualified.push(
      !Boolean(
        basketItem.promotions.find(itemPromotion => itemPromotion.promotion_uid === promotion.promotionUid)
          ?.promotion_missed ?? true
      )
    );
  }

  return [qualifiedOffers, offersQualified];
}
