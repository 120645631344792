/**
 * This is the config files that regulates the shelves
 * we need to feature in the Zone pages. At present
 * we plan to nominate three shelves/categories per zone.
 * Please update the file only after proper confirmation
 * as this might affect the content displayed in zone pages.
 */

import { ParsedBrowseTaxonomyState } from "../Browse.types";
import { getBrowseSubstrings } from "../Browse.utils";
import { ZoneConfig } from "./BrowseZone.types";

const config: ZoneConfig = {
  //non-prod
  857706: [
    "/gol-ui/groceries/baby-and-toddler/little-ones/c:857648",
    "/gol-ui/groceries/baby-and-toddler/bottles-and-feeding-accessories/c:857647",
    "/gol-ui/groceries/baby-and-toddler/toys/c:857696",
  ], // Baby
  857794: [
    "/gol-ui/groceries/bakery/bread/white-bread/c:857718",
    "/gol-ui/groceries/bakery/bread/all-bread/c:857709/offer",
    "/gol-ui/groceries/bakery/bread/baguettes/c:857710",
    "/gol-ui/groceries/bakery/bread/small-loaves/c:857715",
  ], // Bakery
  857947: [
    "/gol-ui/groceries/beauty-and-cosmetics/hair-care/hair-colour/blonde/c:857855",
    "/gol-ui/groceries/beauty-and-cosmetics/hair-care/hair-colour/brunette/c:857856",
    "/gol-ui/groceries/beauty-and-cosmetics/hair-care/hair-colour/expressive/c:857859",
  ], // Beauty & cosmetics
  858119: [
    "/gol-ui/groceries/dairy-eggs-and-chilled/dairy-and-eggs/milk/fresh-milk/c:857991",
    "/gol-ui/groceries/dairy-eggs-and-chilled/dairy-and-eggs/milk/iced-coffee/c:857992",
    "/gol-ui/groceries/dairy-eggs-and-chilled/dairy-and-eggs/milk/flavoured-milk/c:857990",
  ], // Dairy, eggs & chilled
  858398: [
    "/gol-ui/groceries/drinks/wine/rose-wine/c:858384",
    "/gol-ui/groceries/drinks/wine/sparkling-wine/c:858387",
    "/gol-ui/groceries/drinks/wine/all-wine/c:858373",
  ], // Drinks
  858818: [
    "/gol-ui/groceries/food-cupboard/cereals/everyday-cereals/bran/c:858483",
    "/gol-ui/groceries/food-cupboard/cereals/everyday-cereals/flakes/c:858484",
    "/gol-ui/groceries/food-cupboard/cereals/everyday-cereals/wheats/c:858488",
    "/gol-ui/groceries/food-cupboard/rice-pasta-and-noodles/special-offers/c:858728",
  ], // Food cupboard
  858936: [
    "/gol-ui/groceries/frozen/meal-ideas/breakfast/c:858879",
    "/gol-ui/groceries/frozen/meal-ideas/frozen-takeaway/c:858883",
    "/gol-ui/groceries/frozen/meal-ideas/roast-dinners/c:858886",
  ], // Frozen
  859017: [
    "/gol-ui/groceries/fruit-and-vegetables/fresh-vegetables/avocados-and-salad-vegetables/c:858977",
    "/gol-ui/groceries/fruit-and-vegetables/prepared-fruit-veg-and-salad/ready-to-eat-salad/c:859011",
    "/gol-ui/groceries/fruit-and-vegetables/prepared-fruit-veg-and-salad/carb-alternatives/c:859008",
  ], // Fruit & Veg
  859323: [
    "/gol-ui/groceries/meat-and-fish/fish-and-seafood/cod-and-white-fish/c:859289",
    "/gol-ui/groceries/meat-and-fish/fish-and-seafood/just-cook-and-slow-cooked/c:859291",
  ], // Meat & fish
  859399: [
    "/gol-ui/groceries/pet/dog-and-puppy/puppy-0-2-years/c:859371",
    "/gol-ui/groceries/pet/cat-and-kitten/kitten-0-1-years/c:859339",
    "/gol-ui/groceries/pet/cat-and-kitten/cat-litter/c:859335",
  ], // Pet
  859259: [
    "/gol-ui/groceries/household/air-fresheners/gels/c:859167",
    "/gol-ui/groceries/household/air-fresheners/all-aircare/c:859162",
    "/gol-ui/groceries/household/air-fresheners/plug-ins/c:859168",
  ], // Household
  859160: [
    "/gol-ui/groceries/home/home-and-office/bathroom-accessories/c:859031/brand:sainsbury's",
    "/gol-ui/groceries/home/home-and-office/diy-and-car-care/c:859039",
    "/gol-ui/groceries/homeware-and-outdoor/home-and-office/bedroom/c:859032/brand:sainsbury's-/other:new",
    "/gol-ui/groceries/homeware-and-outdoor/party-tableware/kids-party-essentials/c:859131",
  ], // Homeware & outdoors
  859762: [
    "/gol-ui/groceries/toiletries-and-health/sports-nutrition-diet/c:859687",
    "/gol-ui/groceries/toiletries-and-health/toothpaste-and-mouthwash/c:859747",
    "/gol-ui/groceries/toiletries-and-health/vitamins/c:859761",
    "/gol-ui/groceries/toiletries-and-health/medicines-and-healthcare/pain-relief/all-pain-relief/c:859559",
  ], // Toiletries & health

  //prod
  1020225: [
    "/gol-ui/groceries/homeware-and-outdoor/home-and-office/stationery-and-home-office/all-stationery-and-home-office/c:1020111",
    "/gol-ui/groceries/homeware-and-outdoor/batteries/all-batteries/c:1020086",
    "/gol-ui/groceries/homeware-and-outdoor/garden-and-outdoor/c:1020094",
    "/gol-ui/groceries/homeware-and-outdoor/kitchen-and-dining/cookware/all-cookware/c:1020135",
  ], // Home and outdoor
  1020388: [
    "/gol-ui/groceries/meat-and-fish/just-cook-and-slow-cooked/c:1020371",
    "/gol-ui/groceries/meat-and-fish/fish-and-seafood/all-fish-and-seafood/c:1020353",
    "/gol-ui/groceries/meat-and-fish/bacon-and-sausages/c:1020327",
  ], // Meat & fish
  1020082: [
    "/gol-ui/groceries/fruit-and-vegetables/fresh-salad/c:1020040",
    "/gol-ui/groceries/fruit-and-vegetables/organic-fruit-and-vegetables/c:1020071",
    "/gol-ui/groceries/fruit-and-vegetables/fresh-fruit/all-fruit/c:1020006",
    "/gol-ui/groceries/fruit-and-vegetables/flowers-and-plants/c:1020005",
  ], // Fruit & Veg
  1018859: ["/gol-ui/groceries/bakery/special-offers/c:1018851", "/gol-ui/groceries/bakery/new/c:1018842"], // Bakery
  1019184: [
    "/gol-ui/groceries/dairy-eggs-and-chilled/special-offers/c:1019168",
    "/gol-ui/groceries/dairy-eggs-and-chilled/desserts/special-offers/c:1019083",
    "/gol-ui/groceries/dairy-eggs-and-chilled/ready-meals/all-ready-meals/c:1019124",
    "/gol-ui/groceries/dairy-eggs-and-chilled/pies-pasties-and-quiche/pies/c:1019111",
  ], // Dairy, eggs & chilled
  1020001: [
    "/gol-ui/groceries/frozen/fruit-vegetables-and-herbs/all-vegetables/c:1019925",
    "/gol-ui/groceries/frozen/ready-meals-pies-and-party-food/all-ready-meals/c:1019975",
    "/gol-ui/groceries/dairy-eggs-and-chilled/pies-pasties-and-quiche/pies/c:1019111",
  ], // Frozen
  1019883: [
    "/gol-ui/groceries/food-cupboard/cereals/all-cereals/c:1019541",
    "/gol-ui/groceries/food-cupboard/cooking-sauces-and-meal-kits/all/c:1019631",
    "/gol-ui/groceries/food-cupboard/sugar-and-home-baking/baking-essentials/c:1019805",
  ], // Food cupboard
  1019265: [
    "/gol-ui/groceries/dietary-and-world-foods/vegan/new/c:1019246",
    "/gol-ui/groceries/dietary-and-world-foods/special-offers/c:1019234",
  ], // Dietary & world foods
  1019463: [
    "/gol-ui/groceries/drinks/champagne-and-sparkling-wine/special-offers/c:1019291",
    "/gol-ui/groceries/drinks/spirits-and-liqueurs/special-offers/c:1019372",
  ], // Drinks
  1019012: [
    "/gol-ui/groceries/beauty-and-cosmetics/new/c:1018991",
    "/gol-ui/groceries/beauty-and-cosmetics/special-offers/c:1019007",
  ], // Beauty & cosmetics
  1020827: [
    "/gol-ui/groceries/toiletries-and-health/new/c:1020700",
    "/gol-ui/groceries/toiletries-and-health/special-offers/c:1020743",
    "/gol-ui/groceries/toiletries-and-health/toothpaste-and-mouthwash/c:1020812",
  ], // Toiletries & health
  1020464: [
    "/gol-ui/groceries/pet/new/c:1020450",
    "/gol-ui/groceries/pet/special-offers/c:1020463",
    "/gol-ui/groceries/pet/pet-toys/c:1020462",
  ], // Pet
  1020324: [
    "/gol-ui/groceries/household/new/c:1020290",
    "/gol-ui/groceries/household/special-offers/c:1020312",
    "/gol-ui/groceries/household/eco-friendly/c:1020253",
    "/gol-ui/groceries/household/laundry/washing-powder-and-liquids/capsules/c:1020283",
  ], // Household
  1018771: [
    "/gol-ui/groceries/baby-and-toddler/special-offers/c:1018744",
    "/gol-ui/groceries/baby-and-toddler/big-packs/all-big-packs/c:1018705",
    "/gol-ui/groceries/baby-and-toddler/nappies-and-pants/all-nappies/c:1018722",
  ], // Baby & toddler
  1043310: [
    "/gol-ui/groceries/christmas/christmas-day/christmas-dinner/mains/c:1043326",
    "/gol-ui/groceries/christmas/christmas-day/christmas-dinner/vegetables/c:1043327",
    "/gol-ui/groceries/christmas/christmas-day/desserts-and-fresh-cream/c:1043330",
    "/gol-ui/groceries/christmas/drinks/wine-and-sparkling/c:1043358",
  ], // Christmas
  1043379: [
    "/gol-ui/groceries/food-cupboard/confectionery/sweets/multipacks-and-party-sweets/c:1019593",
    "/gol-ui/groceries/food-cupboard/confectionery/multipack-chocolate/c:1019581",
    "/gol-ui/groceries/drinks/spirits-and-liqueurs/premixed-drinks-and-cocktails/c:1019362",
    "/gol-ui/groceries/frozen/ready-meals-pies-and-party-food/party-food/c:1019983",
  ], // Halloween
  1043776: [
    "/gol-ui/groceries/new-years-eve/drinks/champange-and-prosecco/c:1043836",
    "/gol-ui/groceries/new-years-eve/party-food/c:1043842",
    "/gol-ui/groceries/new-years-eve/meal-deals/c:1043855",
  ], // New years eve
  1044114: [
    "/gol-ui/groceries/beauty-and-cosmetics/beauty-gifts/for-her/c:1018886",
    "/gol-ui/groceries/frozen/ready-meals-pies-and-party-food/c:1019986",
    "/gol-ui/groceries/beauty-and-cosmetics/beauty-gifts/for-him/c:1018887",
    "/gol-ui/groceries/food-cupboard/confectionery/boxed-chocolate/c:1019575",
  ], // Valentines Day
  1044170: [
    "/gol-ui/groceries/easter/easter-eggs-and-chocolate/luxury/c:1044284",
    "/gol-ui/groceries/easter/gifts-and-decorations/c:1044297",
    "/gol-ui/groceries/easter/drinks/c:1044274",
    "/gol-ui/groceries/easter/hot-cross-buns-and-cakes/c:1044298",
  ], // Easter
  1045274: [
    "/gol-ui/groceries/mothers-day/gifts/beauty-and-pampering/c:1045277",
    "/gol-ui/groceries/mothers-day/gifts/drink-gifts/c:1045280",
    "/gol-ui/groceries/mothers-day/cards-and-wrap/c:1045298",
    "/gol-ui/groceries/mothers-day/mothers-day-afternoon-tea/scones-cakes-and-biscuits/c:1045289",
  ], // Mothers Day
  1045745: [
    "/gol-ui/groceries/summer/ice-cream-and-desserts/ice-creams-and-lollies/c:1045765",
    "/gol-ui/groceries/summer/suncare-and-beauty/suncare/c:1045780",
    "/gol-ui/groceries/summer/taste-the-difference/c:1045936",
    "/gol-ui/groceries/summer/bbq/vegetarian-and-vegan/c:1045748",
  ],
};

const zoneCategoryIds = {
  1019883: [12422], // Food cupboard
  1043310: [65656], // christmas
  1043379: [337356], // halloween
  1043776: [350369], // New years eve
};

const browseCategoryIds = {
  1043314: [407853], // christmas -- chocolate-and-sweets
  1043315: [494353], // christmas -- Party food and snacks
  1043336: [337427], // christmas -- christmas and Festive bakery and biscuits
  1043340: [407854], // christmas -- gifts
  1043356: [494354], // christmas -- drinks
  1043316: [346857], // christmas -- christmas day
  1043350: [340936], // christmas -- crackers and decorations
  1043381: [337358], // Halloween -- Trick or treat
  1043384: [337378], // Halloween -- Halloween party
  1043388: [537363], // Halloween -- Halloween Cakes and Baking
  1043391: [337371], // Halloween -- Costumes, accessories and makeup
  1043835: [352860], // New years eve -- Drinks
  1043844: [350379], // New years eve -- Dinner party
};

// Avoid mutation to these objects
Object.freeze(config);
Object.freeze(zoneCategoryIds);
Object.freeze(browseCategoryIds);

// getter method for zoneCategoryIds
// For zones having greater number of children catIds citrus returns no result for
// sponsored. This is an alternative approach to tackle that situation.
export const getZoneCategoryIds = (browseId: string): string | null =>
  zoneCategoryIds[browseId] ? zoneCategoryIds[browseId].join(",") : null;

// Some browse pages need an extra categoryId for sponsored content
export const getBrowseCategoryIds = (browseId: string): string | undefined =>
  browseCategoryIds[browseId] ? browseCategoryIds[browseId] : undefined;

export interface CategoryUrlsForZone {
  url: string;
  parsedTaxonomyItem: { findabilityID: string; lookups: number[] };
}

// Getter method to get categories for a zone
export const getCategoryUrlsForZone = (
  browseId: string,
  parsedTaxonomy: ParsedBrowseTaxonomyState
): CategoryUrlsForZone[] => {
  if (!parsedTaxonomy || Object.keys(parsedTaxonomy).length === 0) return [];

  const categoryUrlsForZone = config[browseId] || [];
  const urlsWithTaxonomy = categoryUrlsForZone.map((item: string) => {
    const browseId = getBrowseSubstrings(item)?.browseID;
    return { url: item, parsedTaxonomyItem: parsedTaxonomy[browseId] || { findabilityID: "", lookups: [] } };
  });
  return urlsWithTaxonomy.filter((item: CategoryUrlsForZone) => item.parsedTaxonomyItem.findabilityID !== "");
};
