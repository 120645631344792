import React from "react";
import { ModalsGroupComponentProps } from "./Modal.types";
import { MaximumBasketLimitExceededModal } from "./MaximumBasketLimitExceededModal";
import { ScottishLegislationModal } from "./ScottishLegislationModal";
import { ItemQuantityExceededModal } from "./ItemQuantityExceededModal";
import { BulkQuantityExceededModal } from "./BulkQuantityExceededModal";
import { BasketMultiErrorModal } from "./ModalBasketMultiError.component";
import { FavouriteDeleteErrorModal } from "./FavouriteDeleteErrorModal";
import { FavouriteSaveErrorModal } from "./FavouriteSaveErrorModal";
import { GenericErrorModal } from "./GenericErrorModal";
import ModalLoading from "./ModalLoading";
import ModalBasketAddSuccess from "./ModalBasketAddSuccess";

export const ModalsGroupComponent = ({
  bulkOrderingHelp,
  showGenericErrorModal,
  addOrderToBasketErrors,
  scottishLegislationError,
  itemQuantityExceededError,
  bulkOrderLimitExceededError,
  showFavouriteSaveErrorModal,
  showFavouriteDeleteErrorModal,
  maximumBasketLimitExceededError,
  showLoadingModal,
  showBasketAddSuccess,
}: ModalsGroupComponentProps) => {
  if (scottishLegislationError) {
    return (
      <ScottishLegislationModal title={scottishLegislationError.title} description={scottishLegislationError.detail} />
    );
  }

  if (showLoadingModal) {
    return <ModalLoading />;
  }

  if (showBasketAddSuccess) {
    return <ModalBasketAddSuccess />;
  }

  if (itemQuantityExceededError) {
    return (
      <ItemQuantityExceededModal
        title={itemQuantityExceededError.title}
        description={itemQuantityExceededError.detail}
        bulkOrderingHelp={bulkOrderingHelp}
      />
    );
  }

  if (maximumBasketLimitExceededError) {
    return (
      <MaximumBasketLimitExceededModal
        title={maximumBasketLimitExceededError.title}
        description={maximumBasketLimitExceededError.detail}
      />
    );
  }

  if (bulkOrderLimitExceededError) {
    return (
      <BulkQuantityExceededModal
        title={bulkOrderLimitExceededError.title}
        description={bulkOrderLimitExceededError.detail}
        bulkOrderingHelp={bulkOrderingHelp}
      />
    );
  }

  if (addOrderToBasketErrors) {
    return <BasketMultiErrorModal errors={addOrderToBasketErrors} />;
  }

  if (showFavouriteDeleteErrorModal) {
    return <FavouriteDeleteErrorModal />;
  }

  if (showFavouriteSaveErrorModal) {
    return <FavouriteSaveErrorModal />;
  }

  if (showGenericErrorModal) {
    return <GenericErrorModal />;
  }

  return null;
};
