import React from "react";
import { GOLLink } from "../../../GOLLink";
import { useMegaNavActions } from "../../headerState/header.actions";
import { MegaNavColumnProps } from "../MegaNav.types";
import { MegaNavItem } from "./MegaNavItem";

export const MegaNavColumn: React.FC<MegaNavColumnProps> = ({
  title,
  items,
  onSelected,
  selectedItem,
  titleUrl,
  showHomeSuffix,
}) => {
  const { hideMegaNav } = useMegaNavActions();

  const isGroceriesHome = title === "Groceries Home";

  const getTitleUrl = (url: string | undefined): string => {
    if (!url) return "/";
    return url.startsWith("/") ? url : `/${url}`;
  };

  const renderTitle = () => {
    const titleContent = (
      <span className="mega-nav__item-text" data-testid="mega-nav-column-title">
        {!isGroceriesHome && <span>View </span>}
        {title}
        {showHomeSuffix && <span> Home</span>}
      </span>
    );
    if (titleUrl) {
      return (
        <GOLLink
          to={getTitleUrl(titleUrl)}
          className="mega-nav__item-content"
          onClick={hideMegaNav}
          data-testid="mega-nav-column-title-link"
        >
          {titleContent}
        </GOLLink>
      );
    }

    return <div className="mega-nav__item-content">{titleContent}</div>;
  };

  return (
    <div className="mega-nav__column" data-testid="mega-nav-column">
      <div className="mega-nav__title" data-testid="mega-nav-column-header">
        {renderTitle()}
      </div>
      <ul role="menu" className="mega-nav__list" data-testid="mega-nav-column-list">
        {items.map(item => (
          <MegaNavItem key={item.id} item={item} onSelected={onSelected} isSelected={selectedItem === item} />
        ))}
      </ul>
    </div>
  );
};
