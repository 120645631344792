import React, { Suspense } from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { Route } from "./components/Route/Route";
import { ContentWrap } from "./components/ContentWrap";
import { AuthorizedContainer } from "./components/Authorized";
import { RedirectExternal } from "./components/RedirectExternal";
import { routes, urls } from "./routes";
import { ModalsGroupContainer } from "./components/Modal/ModalsGroup.container";
import { staticPageAnalyticsValues } from "./common/analytics/analytics";
import { AnalyticsPageViewEvent } from "./components/AnalyticsPageViewEvent";
import { NotFoundErrorPage } from "./components/Error/NotFoundErrorPage";
import { UnrecoverableErrorPage } from "./components/Error/UnrecoverableErrorPage";
import { RedirectWithQueryString } from "./components/RedirectWithQueryString/RedirectWithQueryString";
import { NectarDestinationContextProvider } from "./views/NectarDestinationPage/NectarDestinationContext/NectarDestinationContext";
import { AnalyticsPageViewTrackEvent } from "./components/AnalyticsPageViewTrackEvent";
import { PAGE_LOAD_EVENT_NAMES } from "./common/analytics/types";
import { AdobeExtraPageData, AdobePageName, AdobePageType } from "./utils/generateAdobeAnalyticsPageProperties";
import { ToastContextProvider } from "@sainsburys-tech/fable";
import { useFeatureFlag } from "./common/firebase/featureFlags.hooks";

const AboutClickAndCollectContainer = React.lazy(
  () =>
    import(/* webpackChunkName: "aboutClickAndCollect" */ "./views/AboutClickAndCollect/AboutClickAndCollect.component")
);
const OrdersContainer = React.lazy(
  () => import(/* webpackChunkName: "orders" */ "./views/MyOrders/MyOrders.component")
);
const OrderDetailsContainer = React.lazy(
  () => import(/* webpackChunkName: "orderDetails" */ "./views/OrderDetails/OrderDetails.container")
);
const HomeDeliveryContainer = React.lazy(
  () => import(/* webpackChunkName: "homeDelivery" */ "./views/HomeDelivery/HomeDelivery.container")
);
const ClickAndCollectContainer = React.lazy(
  () => import(/* webpackChunkName: "clickAndCollect" */ "./views/ClickAndCollect/ClickAndCollect.container")
);
const ClickAndCollectConfirmationContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "clickAndCollectConfirmation" */ "./views/ClickAndCollect/BookingConfirmation.container"
    )
);
const CouponsContainer = React.lazy(
  () => import(/* webpackChunkName: "coupons" */ "./views/MyCoupons/MyCoupons.container")
);

const MyNectarCardContainer = React.lazy(
  () => import(/* webpackChunkName: "myNectarCard" */ "./views/MyNectarCard/MyNectarCard.container")
);

const EditNectarContainer = React.lazy(
  () => import(/* webpackChunkName: "editNectar" */ "./views/MyNectarCard/EditNectar.container")
);

const MyAddresses = React.lazy(
  () => import(/* webpackChunkName: "myAddresses" */ "./views/MyAddresses/MyAddresses.component")
);

const VerifyNectar = React.lazy(
  () => import(/* webpackChunkName: "linkNectar" */ "./views/MyNectarCard/LinkNectar.component")
);

const SignUpNectarContainer = React.lazy(
  () => import(/* webpackChunkName: "signUpNectar" */ "./views/MyNectarCard/SignUpNectar.container")
);

const ProgressiveRegistrationContainer = React.lazy(
  () => import(/* webpackChunkName: "progressiveRegistration" */ "./views/ProgressiveRegistration")
);
const FavouritesContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "favourites" */ "./views/Favourites/components/FavouritesContainer/Favourites.container"
    )
);
const YourRecipesFavouritesContainer = React.lazy(
  () => import(/* webpackChunkName: "yourRecipes" */ "./views/RecipesFavourites/YourRecipesFavourites.container")
);
const PreviousOrders = React.lazy(
  () => import(/* webpackChunkName: "previousOrders" */ "./views/PreviousOrders/PreviousOrders")
);
const SearchResultsContainer = React.lazy(
  () => import(/* webpackChunkName: "searchResults" */ "./views/SearchResults/SearchResults.container")
);
const ProductListerContainer = React.lazy(
  () => import(/* webpackChunkName: "productLister" */ "./views/ProductLister/ProductLister.container")
);
const ProductRangeComponent = React.lazy(
  () => import(/* webpackChunkName: "productLister" */ "./views/ProductLister/ProductRange.component")
);
const CheckPostcodeContainer = React.lazy(
  () => import(/* webpackChunkName: "checkPostcode" */ "./views/CheckPostcode")
);
const ProductDetailsContainer = React.lazy(
  () => import(/* webpackChunkName: "productDetails" */ "./views/ProductDetails/ProductDetails.container")
);
const NectarDestinationPageContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "nectarDestinationPage" */ "./views/NectarDestinationPage/NectarDestinationPage.container"
    )
);

const OffersContainer = React.lazy(
  () => import(/* webpackChunkName: "offers" */ "./views/Offers/pages/MainOffersPage/Offers.container")
);

const OffersZonePageContainer = React.lazy(
  () => import(/* webpackChunkName: "offersZonalPage" */ "./views/Offers/pages/OffersZonalPage/ZonalPage.container")
);

const RecurringSlotContainer = React.lazy(
  () => import(/* webpackChunkName: "recurringSlot" */ "./views/RecurringSlot/RecurringSlot.container")
);

const Homepage = React.lazy(() => import(/* webpackChunkName: "Homepage" */ "./views/Homepage/Homepage.container"));
const BrowsePage = React.lazy(() => import(/* webpackChunkName: "browse" */ "./views/Browse/Browse.component"));
const BrowseFeaturedPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "browseFeatured" */ "./views/Browse/BrowseFeaturedLister/BrowseFeaturedLister.container"
    )
);
const BrandedShelf = React.lazy(
  () => import(/* webpackChunkName: "brandedShelf" */ "./views/BrandedPage/BrandedPage.container")
);
const Trolley = React.lazy(() => import(/* webpackChunkName: "trolley" */ "./views/Trolley/Trolley.component"));
const PaymentCards = React.lazy(
  () => import(/* webpackChunkName: "paymentCards" */ "./views/PaymentCards/PaymentCards.component")
);
const AddPaymentCard = React.lazy(
  () => import(/* webpackChunkName: "addPaymentCard" */ "./views/PaymentCards/AddPaymentCard.component")
);
const PaymentCardsRedirect = React.lazy(
  () => import(/* webpackChunkName: "addPaymentCard" */ "./views/PaymentCards/PaymentCardsRedirect.component")
);
const ChangesToTrolley = React.lazy(
  () => import(/* webpackChunkName: "trolley" */ "./views/ChangesToTrolley/ChangesToTrolley.container")
);
const Checkout = React.lazy(() => import(/* webpackChunkName: "checkout" */ "./views/Checkout/Checkout.component"));
const CheckoutPaymentAuthorisation = React.lazy(
  () => import(/* webpackChunkName: "checkout" */ "./views/Checkout/CheckoutPaymentAuthorisation.component")
);
const CheckoutPaymentRedirect = React.lazy(
  () => import(/* webpackChunkName: "checkout" */ "./views/Checkout/CheckoutPaymentRedirect.component")
);
const OrderConfirmationContainer = React.lazy(
  () => import(/* webpackChunkName: "orderConfirmation" */ "./views/OrderConfirmation/OrderConfirmation.container")
);
const OAuthRefresh = React.lazy(
  () => import(/* webpackChunkName: "oauthRefresh" */ "./views/OAuthLogin/OAuthRefresh.component")
);
const OAuthRedirect = React.lazy(
  () => import(/* webpackChunkName: "oauthRedirect" */ "./views/OAuthLogin/OAuthRedirect.component")
);
const OAuthRedirectWithToken = React.lazy(
  () => import(/* webpackChunkName: "oauthRedirect" */ "./views/OAuthLogin/OAuthRedirectWithToken.component")
);
const OAuthLogin = React.lazy(
  () => import(/* webpackChunkName: "oauthLogin" */ "./views/OAuthLogin/OAuthLogin.component")
);
const OAuthLogout = React.lazy(
  () => import(/* webpackChunkName: "oauthLogout" */ "./views/OAuthLogout/OAuthLogout.component")
);
const OAuthLogoutRedirect = React.lazy(
  () => import(/* webpackChunkName: "oauthLogoutRedirect" */ "./views/OAuthLogout/OAuthLogoutRedirect.component")
);
const RedirectToOAuthLogin = React.lazy(
  () => import(/* webpackChunkName: "redirectToOauthLogin" */ "./views/OAuthLogin/RedirectToOAuthLogin.component")
);
const LoginError = React.lazy(
  () => import(/* webpackChunkName: "loginError" */ "./views/OAuthLogin/LoginError.component")
);
const OAuthRegistrationConfirmation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "oauthRegistration" */ "./views/OAuthRegistration/OAuthRegistrationConfirmation.component"
    )
);
const OAuthPartnerLogin = React.lazy(
  () => import(/* webpackChunkName: "oauthThirdPartyLogin" */ "./views/OAuthLogin/PartnerLogin.component")
);
const ProgressiveRegistrationV2 = React.lazy(
  () =>
    import(
      /* webpackChunkName: "progressiveRegistration2" */ "./views/ProgressiveRegistrationV2/ProgressiveRegistrationV2"
    )
);
const BookingConfirmationContainer = React.lazy(
  () =>
    import(/* webpackChunkName: "bookingConfirmation" */ "./views/BookingConfirmation/BookingConfirmation.container")
);
const BeforeYouGo = React.lazy(
  () => import(/* webpackChunkName: "beforeYouGo" */ "./views/CheckoutInterstitials/BeforeYouGo.component")
);
const ForgottenFavourites = React.lazy(
  () =>
    import(/* webpackChunkName: "forgottenFavourites" */ "./views/CheckoutInterstitials/ForgottenFavourites.component")
);
const CheckoutRedirect = React.lazy(
  () => import(/* webpackChunkName: "checkoutRedirect" */ "./views/CheckoutInterstitials/CheckoutRedirect.component")
);
const MyAccount = React.lazy(() => import(/* webpackChunkName: "myAccount" */ "./views/MyAccount/MyAccount.component"));
const SearchResultsFindability = React.lazy(
  () =>
    import(
      /* webpackChunkName: "searchResultsFindabilityContainer" */ "./views/SearchResultsFindability/SearchResultsFindability.container"
    )
);

const Bannerx = React.lazy(() => import(/* webpackChunkName: "bannerPreviewBannerX" */ "./views/BannerPreview"));
const DeliveryPassRenewalOptionsContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "deliveryPassRenewalOptions" */ "./views/DeliveryPassRenewalOptions/DeliveryPassRenewalOptions.container"
    )
);

const RecipeHomepage = React.lazy(
  () => import(/* webpackChunkName: "recipe home" */ "./views/RecipeHomepage/RecipeHomepage.container")
);

const RecipesBrowsePageView = React.lazy(
  () => import(/* webpackChunkName: "recipes browse page" */ "./views/RecipesBrowsePage/RecipesBrowsePage.component")
);

const Recipe = React.lazy(() => import(/* webpackChunkName: "recipe" */ "./views/Recipes/Recipe.container"));

const RecipesSearchResults = React.lazy(
  () =>
    import(/* webpackChunkName: "recipeSearchResults" */ "./views/RecipesSearchResults/RecipesSearchResults.container")
);

const WithRecipeSearch = React.lazy(
  () => import(/* webpackChunkName: "recipeSearch" */ "./components/RecipesSearch/WithRecipeSearch.component")
);

const MealPlanner = React.lazy(
  () => import(/* webpackChunkName: "mealPlanner" */ "./views/RecipeMealPlanner/MealPlannerPage")
);

const RecipeScrapbook = React.lazy(
  () => import(/* webpackChunkName: "recipescrapbook" */ "./views/RecipeScrapbooks/RecipeScrapbook.container")
);

const MultiSearchFormView = React.lazy(
  () => import(/* webpackChunkName: "multiSearchForm" */ "./views/MultiSearch/MultiSearchFormView")
);

const Article = React.lazy(() => import(/* webpackChunkName: "article" */ "./views/Articles/Article.container"));

const MealDeal = React.lazy(() => import(/* webpackChunkName: "mealDeal" */ "./views/MealDeal/MealDealPage"));

const EventsFeatures = React.lazy(
  () => import(/* webpackChunkName: "EventsFeatures" */ "./views/EventsFeatures/EventsFeatures.component")
);

const MealDealBuilderContainer = React.lazy(
  () => import(/* webpackChunkName: "mealDealBuilder" */ "./views/MealDealBuilder/MealDealBuilder")
);

const CYSMPage = React.lazy(() => import(/* webpackChunkName: "CYSM" */ "./views/CYSM/CYSMPage"));

const PostcodeRedirectPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostcodeRedirect" */ "./views/CYSM/PostcodeCheck/PostcodeRedirect/PostcodeRedirectPage"
    )
);

const PromoLister = React.lazy(
  () => import(/* webpackChunkName: "promoListerComponent" */ "./views/PromoLister/PromoLister.container")
);

const XmasSlotGuide = React.lazy(
  () => import(/* webpackChunkName: "XmasSlotGuideComponent" */ "./views/XmasSlotGuide/XmasSlotGuide")
);

const DeliveryPassCheckout = React.lazy(
  () => import(/* webpackChunkName: "DeliveryPassCheckout" */ "./views/DeliveryPass/Checkout.component")
);

const DeliveryPassCheckoutRedirect = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DeliveryPassCheckoutRedirect" */ "./views/DeliveryPass/CheckoutPaymentRedirect.component"
    )
);

export const Main = () => {
  const isBookSlot = useRouteMatch(routes.BOOK_SLOT);
  const isPciPhase3 = useFeatureFlag("pci_phase_3");

  return (
    <div className="page-body">
      <ContentWrap className={`padding-bottom-default${isBookSlot ? "__book-slot" : ""}`}>
        <Suspense fallback={null}>
          <div data-testid="content-wrap">
            <Switch>
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.MEAL_DEAL_BUILDER}
                render={() => <MealDealBuilderContainer />}
              />
              <Route showFallbackUIWhenDisabled={true} exact path={routes.MEAL_DEAL} render={() => <MealDeal />} />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CLICK_AND_COLLECT_ABOUT}
                render={() => (
                  <>
                    <AnalyticsPageViewEvent page={staticPageAnalyticsValues.AboutClickAndCollect} />
                    <AboutClickAndCollectContainer />
                  </>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.MY_ACCOUNT}
                render={() => (
                  <AuthorizedContainer>
                    <MyAccount />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.MY_ORDERS}
                render={() => (
                  <AuthorizedContainer>
                    <OrdersContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.EDIT_NECTAR_CARD}
                render={() => (
                  <AuthorizedContainer>
                    <EditNectarContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.VERIFY_NECTAR_CARD}
                render={() => (
                  <AuthorizedContainer>
                    <VerifyNectar />
                  </AuthorizedContainer>
                )}
              />

              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.SIGNUP_ON_NECTAR}
                render={() => (
                  <AuthorizedContainer>
                    <SignUpNectarContainer />
                  </AuthorizedContainer>
                )}
              />

              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.MY_NECTAR_CARD}
                render={() => (
                  <AuthorizedContainer>
                    <MyNectarCardContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.MY_COUPONS}
                render={() => (
                  <AuthorizedContainer>
                    <CouponsContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.PAYMENT_CARDS}
                render={() => (
                  <AuthorizedContainer>
                    <PaymentCards />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.ADD_PAYMENT_CARD}
                render={() => (
                  <AuthorizedContainer>
                    <AddPaymentCard />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.PAYMENT_CARDS_REDIRECT}
                render={() => (
                  <AuthorizedContainer>
                    <PaymentCardsRedirect />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.ORDER_DETAILS}
                render={() => (
                  <AuthorizedContainer>
                    <OrderDetailsContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.MY_ADDRESSES}
                render={() => (
                  <AuthorizedContainer>
                    <MyAddresses />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.FAVOURITES}
                render={() => (
                  <AuthorizedContainer>
                    <FavouritesContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.YOUR_RECIPES_FAVOURITES}
                render={() => (
                  <AuthorizedContainer>
                    <YourRecipesFavouritesContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.PREVIOUS_ORDERS}
                render={() => (
                  <AuthorizedContainer>
                    <PreviousOrders />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.PRODUCT_DETAILS}
                render={() => <ProductDetailsContainer />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.SEARCH_RESULTS}
                render={() => <SearchResultsContainer />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={[routes.SEARCH_RESULTS_FINDABILITY_WITH_CATEGORIES, routes.SEARCH_RESULTS_FINDABILITY]}
                render={() => <SearchResultsFindability />}
              />
              <Route showFallbackUIWhenDisabled={true} path={routes.BROWSE} exact render={() => <Homepage />} />
              <Route showFallbackUIWhenDisabled={true} path={routes.BROWSE} render={() => <BrowsePage />} />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.BROWSE_FEATURED}
                render={() => <BrowseFeaturedPage />}
              />
              <Route showFallbackUIWhenDisabled={true} path={routes.BRAND_SHELF} render={() => <BrandedShelf />} />
              <Route showFallbackUIWhenDisabled={true} path={routes.BANNERX_PREVIEW} component={Bannerx} />
              <Route
                showFallbackUIWhenDisabled={true}
                path={[routes.EVENTS, routes.FEATURES]}
                render={() => <EventsFeatures />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.ARTICLE}
                exact
                render={() => (
                  <WithRecipeSearch>
                    <Article />
                  </WithRecipeSearch>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.RECIPE_SCRAPBOOK}
                render={() => (
                  <WithRecipeSearch>
                    <RecipeScrapbook />
                  </WithRecipeSearch>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.RECIPES_SEARCH_RESULTS}
                exact
                render={() => (
                  <WithRecipeSearch>
                    <RecipesSearchResults />
                  </WithRecipeSearch>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={[
                  routes.RECIPES_BROWSE_RESULTS_LEVEL_TWO,
                  routes.RECIPES_BROWSE_RESULTS_LEVEL_ONE,
                  routes.RECIPES_BROWSE,
                ]}
                render={() => <RecipesBrowsePageView paginationLimit={48} />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.RECIPES_MEAL_PLANNER_CREATE}
                render={() => <MealPlanner create={true} />}
              />

              <Route
                showFallbackUIWhenDisabled={true}
                path={[routes.RECIPES_MEAL_PLANNER_PLAN, routes.RECIPES_MEAL_PLANNER]}
                render={() => (
                  <AuthorizedContainer>
                    <MealPlanner />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.RECIPE}
                exact
                render={() => (
                  <WithRecipeSearch>
                    <Recipe />
                  </WithRecipeSearch>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.RECIPES_HOMEPAGE}
                exact
                render={() => (
                  <WithRecipeSearch hasHomepageBanner>
                    <RecipeHomepage />
                  </WithRecipeSearch>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.MULTI_SEARCH_PAGE}
                render={() => <MultiSearchFormView />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.PRODUCT_LISTER}
                render={() => <ProductListerContainer />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.PRODUCT_LISTER_SEO}
                render={() => <ProductRangeComponent />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.NECTAR_DESTINATION}
                render={() => <NectarDestinationPageContainer />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={[routes.NECTAR_OFFERS_LEGACY, routes.NECTAR_PRICES_DESTINATION]}
                render={() => <RedirectWithQueryString to={urls.NECTAR_DESTINATION} />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.OFFERS_ZONE_PAGE}
                render={({ match }) => (
                  <NectarDestinationContextProvider>
                    <OffersZonePageContainer path={match.params.zonePath} />
                  </NectarDestinationContextProvider>
                )}
              />
              <Route showFallbackUIWhenDisabled={true} path={routes.OFFERS} render={() => <OffersContainer />} />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.CHECK_POSTCODE}
                render={() => (
                  <>
                    <CheckPostcodeContainer />
                    <AnalyticsPageViewEvent page={staticPageAnalyticsValues.CheckPostcode} />
                  </>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.LOGON_VIEW}
                render={() => <RedirectToOAuthLogin />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.LOGON_VIEW_IDENTIFIER}
                render={() => (
                  <AuthorizedContainer fallback={<RedirectToOAuthLogin />}>
                    <Redirect to={urls.MY_ACCOUNT} />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.LOGON_VIEW_PASSWORD}
                render={() => (
                  <AuthorizedContainer fallback={<RedirectToOAuthLogin />}>
                    <Redirect to={urls.MY_ACCOUNT} />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHECKOUT_LOGIN_IDENTIFIER}
                render={() => (
                  <AuthorizedContainer fallback={<RedirectToOAuthLogin />}>
                    <RedirectExternal to={urls.CHECKOUT_FLOW} />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHECKOUT_LOGIN_PASSWORD}
                render={() => (
                  <AuthorizedContainer fallback={<RedirectToOAuthLogin />}>
                    <RedirectExternal to={urls.CHECKOUT_FLOW} />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.PROGRESSIVE_REGISTRATION}
                render={() => (
                  <AuthorizedContainer fallback={<ProgressiveRegistrationContainer />}>
                    <Redirect to={urls.MY_ACCOUNT} />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.PROGRESSIVE_V2_REGISTRATION}
                render={() => (
                  <AuthorizedContainer fallback={<ProgressiveRegistrationV2 />}>
                    <Redirect to={urls.MY_ACCOUNT} />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.OAUTH_ERROR}
                render={() => (
                  <>
                    <LoginError />
                    <AnalyticsPageViewEvent page={staticPageAnalyticsValues.OAuthLoginError} />
                  </>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.OAUTH_REGISTRATION_CONFIRMATION}
                render={() => (
                  <AuthorizedContainer>
                    <OAuthRegistrationConfirmation />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.RECURRING_SLOT}
                render={() => (
                  <AuthorizedContainer>
                    <RecurringSlotContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.BOOK_SLOT}
                render={() => (
                  <AuthorizedContainer>
                    <ToastContextProvider variant="primary">
                      <HomeDeliveryContainer />
                    </ToastContextProvider>
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CLICK_AND_COLLECT_BOOK}
                render={() => (
                  <AuthorizedContainer>
                    <ToastContextProvider variant="primary">
                      <ClickAndCollectContainer />
                    </ToastContextProvider>
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CLICK_AND_COLLECT_CONFIRMATION}
                render={() => (
                  <AuthorizedContainer>
                    <ClickAndCollectConfirmationContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.BOOKING_CONFIRMATION}
                render={() => (
                  <AuthorizedContainer>
                    <BookingConfirmationContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.OAUTH_LOGIN}
                render={() => (
                  <>
                    <OAuthLogin />
                    <AnalyticsPageViewTrackEvent
                      {...{
                        page: staticPageAnalyticsValues.OAuthLogin,
                        eventName: PAGE_LOAD_EVENT_NAMES.PL_AUTH_LOGIN,
                        adobeProperties: {
                          pageType: AdobePageType.GROCERIES,
                          pageName: AdobePageName.AUTHENTICATION,
                          extraPageData: [AdobeExtraPageData.LOGIN],
                        },
                      }}
                    />
                  </>
                )}
              />
              <Route showFallbackUIWhenDisabled={true} path={routes.OAUTH_REDIRECT} render={() => <OAuthRedirect />} />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.OAUTH_LOGIN_WITH_TOKEN}
                render={() => (
                  <>
                    <OAuthRedirectWithToken />
                    <AnalyticsPageViewEvent page={staticPageAnalyticsValues.OAuthRedirect} />
                  </>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.OAUTH_REFRESH}
                render={() => <OAuthRefresh />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.OAUTH_LOGOUT}
                render={() => (
                  <>
                    <OAuthLogout />
                    <AnalyticsPageViewEvent page={staticPageAnalyticsValues.OAuthLogout} />
                  </>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.OAUTH_LOGOUT_REDIRECT}
                render={() => (
                  <>
                    <OAuthLogoutRedirect />
                    <AnalyticsPageViewEvent page={staticPageAnalyticsValues.OAuthLogoutRedirect} />
                  </>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.OAUTH_PARTNER_LOGIN}
                render={() => <OAuthPartnerLogin />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.TROLLEY}
                render={() => (
                  <AuthorizedContainer>
                    <Trolley />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHANGES_TO_TROLLEY}
                render={() => (
                  <AuthorizedContainer>
                    <ChangesToTrolley />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHECKOUT_SUMMARY}
                render={() => (
                  <AuthorizedContainer>
                    <Checkout />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHECKOUT_PAYMENT_AUTHORISATION}
                render={() => (
                  <AuthorizedContainer>
                    <CheckoutPaymentAuthorisation />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHECKOUT_PAYMENT_REDIRECT}
                render={() => (
                  <AuthorizedContainer>
                    <CheckoutPaymentRedirect />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.ORDER_CONFIRMATION}
                render={() => (
                  <AuthorizedContainer>
                    <OrderConfirmationContainer />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHECKOUT_BEFORE_YOU_GO}
                render={() => (
                  <AuthorizedContainer>
                    <BeforeYouGo />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHECKOUT_FORGOTTEN_FAVOURITES}
                render={() => (
                  <AuthorizedContainer>
                    <ForgottenFavourites />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.CHECKOUT_REDIRECT}
                render={() => (
                  <AuthorizedContainer>
                    <CheckoutRedirect />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.DELIVERY_PASS_RENEWAL_OPTIONS}
                render={() => <DeliveryPassRenewalOptionsContainer />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                path={routes.PROMO_LISTER}
                render={({ match }) => <PromoLister promoId={match.params.promoId} />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.ERROR}
                render={() => (
                  <>
                    <UnrecoverableErrorPage />
                    <AnalyticsPageViewEvent page={staticPageAnalyticsValues.Error} />
                  </>
                )}
              />
              <Route showFallbackUIWhenDisabled={true} exact path={routes.CYSM} render={() => <CYSMPage />} />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.POSTCODE_CHECK}
                render={() => <PostcodeRedirectPage />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.XMAS_SLOT_GUIDE}
                render={() => <XmasSlotGuide />}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={isPciPhase3 ? routes.DELIVERY_PASS_CHECKOUT_SUMMARY : routes.ERROR}
                render={() => (
                  <AuthorizedContainer>
                    <DeliveryPassCheckout />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                exact
                path={routes.DELIVERY_PASS_CHECKOUT_REDIRECT}
                render={() => (
                  <AuthorizedContainer>
                    <DeliveryPassCheckoutRedirect />
                  </AuthorizedContainer>
                )}
              />
              <Route
                showFallbackUIWhenDisabled={true}
                render={() => (
                  <>
                    <NotFoundErrorPage />
                    <AnalyticsPageViewEvent page={staticPageAnalyticsValues.Error} />
                  </>
                )}
              />
            </Switch>
          </div>
        </Suspense>
      </ContentWrap>
      <ModalsGroupContainer />
    </div>
  );
};

Main.displayName = "Main";
