import { useEffect, useState } from "react";
import { useResponsive } from "../../../components/ResponsiveProvider";

export const mobileFooterOffset = 359;
export const tabletFooterOffset = 163;
export const desktopFooterOffset = 140;

export const useStickyElementScroll = () => {
  const [stickyElement, setStickyElement] = useState(true);
  const stickyScrollStyling = stickyElement ? "sticky-button" : "";
  const { isMobile, isTablet } = useResponsive();
  const offset = isMobile ? mobileFooterOffset : isTablet ? tabletFooterOffset : desktopFooterOffset;

  useEffect(() => {
    const element = () => {
      if (window.innerHeight + window.scrollY <= document.body.offsetHeight - offset) {
        setStickyElement(true);
      } else {
        setStickyElement(false);
      }
    };

    window.addEventListener("scroll", element);

    return () => {
      window.removeEventListener("scroll", element);
    };
  }, [offset]);

  return stickyScrollStyling;
};
