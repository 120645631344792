import moment from "moment";
import "moment-timezone";
import { State } from "../../common/store";
import { ModalTypes } from "./AmendOrderBanner.types";
import { SlotReservationState, SlotReservationType } from "../../domain/slot/slotReservation.types";

export const selectSlotExpiryModalOpen = (state: State) =>
  state.modal.meta && state.modal.meta.name === ModalTypes.SLOT_EXPIRED_MODAL;

export const selectCutOffModalOpen = (state: State) =>
  state.modal.meta && state.modal.meta.name === ModalTypes.SLOT_CUT_OFF_MODAL;

export const selectSlotCutOffModalDescription = (state: State): string => {
  const { originalSlotReservation, slotReservation } = state.slotReservation as SlotReservationState;

  if (originalSlotReservation) {
    const originalPostCode = originalSlotReservation.reservationPostCode;
    const originalSlotDate = moment(originalSlotReservation.reservationStartTime).format("DD/MM/YY");
    const originalStoreName = originalSlotReservation.reservationAddressNickname;
    const deliveryMessage = `This means your latest amends will not be made to your order. Your original order will be delivered to ${originalPostCode} on ${originalSlotDate}. To make another order, please book a new slot.`;

    const collectionMessage = `This means your latest amends will not be made to your order. Your original order will be ready for collection from ${originalStoreName} on ${originalSlotDate}. To make another order, please book a new slot.`;

    if (slotReservation.reservationType === SlotReservationType.DELIVERY) {
      return deliveryMessage;
    }
    if (slotReservation.reservationType === SlotReservationType.CLICK_AND_COLLECT) {
      return collectionMessage;
    }
  }

  return `Please book a new slot before you check out`;
};
