import { ProductOption } from "../productTile.types";

type MapProductDropdownOptions = {
  productOptions: ProductOption[] | undefined;
  hasNectarOrYNPPromo: boolean;
  hasNectarAssociated: boolean;
};

export const mapProductDropdownOptions = ({
  productOptions,
  hasNectarOrYNPPromo,
  hasNectarAssociated,
}: MapProductDropdownOptions) => {
  if (!productOptions) return [];

  return productOptions.reduce((acc, { value, label, retailPrice }) => {
    if (retailPrice) {
      const useWasPrice = Boolean(retailPrice.wasPrice) && hasNectarOrYNPPromo && !hasNectarAssociated;
      const price = useWasPrice ? retailPrice.wasPrice! : retailPrice.price;

      const dropdownOption = {
        value,
        label: `${label} - ${price}`,
      };

      return [...acc, dropdownOption];
    }

    return acc;
  }, []);
};
