export interface PendingTextProps {
  width?: number | string;
  hasTrailingWhitespace?: boolean;
}

export enum SlotTypeValue {
  Saver = "saver_slot",
  Standard = "1hour",
}

export enum SlotTypeLabel {
  Saver = "Saver Slot",
  Standard = "Standard Slot",
}

export enum SlotTypeAnalytics {
  Saver = "saver",
  Standard = "standard",
  StandardGreen = "standard:green",
}

export interface SlotType {
  label: SlotTypeLabel;
  type: SlotTypeValue;
}

export enum Sources {
  CLICK_AND_COLLECT = "click and collect",
  HOME_DELIVERY = "home delivery",
}
