import { Method, Result } from "../common/http/request";
import { Optional } from "../common/types";
import {
  CategoryTreeResponse,
  CitrusProductAds,
  EventsSearchProductParameters,
  FavouritesResponse,
  FetchBrowseProductsArgs,
  FrequentlyBoughtTogether,
  IncludeFilters,
  PaginatedProducts,
  Product,
  ProductQueryParameters,
  ProductRecommendations,
  Products,
  ProductsByOrderParameters,
  ProductsBySeoUrlResponse,
  RequestFilters,
  SearchProductParameters,
  SearchResponse,
  SimilarProducts,
  SuggestedSearchTerm,
} from "./product.types";
import { encodeQueryParams } from "../common/http/query";
import { serviceRequest } from "./request";
import { FAVOURITES_FIRST } from "../domain/product/product.types";
import { BrowsePageType, BrowseServiceResponse, ZoneCitrusResponse } from "../views/Browse/Browse.types";
import { getCachedSlotReservation } from "../domain/slot/slotReservation";
import { MegaNavData } from "../components/Header/Header.types";
import { featureFlags } from "../common/firebase/featureFlags";
import { EXPERIMENTS, trackExperiment } from "../common/analytics";

const url = "/groceries-api/gol-services/product";

const favouritePatterns = { wcs: "default", seasonal: "seasonal" };

export const fetchProductsByWCSCatEntryIds = (
  wcsCatEntryIDs: string[],
  includes: IncludeFilters,
  filterAvailable?: boolean,
  minimised?: boolean,
  storeIdentifier?: string,
  slotDate?: string,
  slotDateTime?: string,
  slotType?: string,
  isSlotLocked?: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<Products>> => {
  const params = Object.assign(
    {},
    { cat_entry_id: wcsCatEntryIDs },
    { "filter[available]": filterAvailable },
    mapIncludes(includes),
    { minimised },
    storeIdentifier ? { store_identifier: storeIdentifier } : null,
    slotDate ? { slot_date: slotDate } : null,
    slotDateTime ? { slot_date_time: slotDateTime } : null,
    slotType ? { slot_type: slotType } : null,
    isSlotLocked ? { is_slot_locked: isSlotLocked } : false
  );
  return serviceRequest<Products>({ method: Method.GET, url: `${baseUrl}/v1/product${encodeQueryParams(params)}` });
};

export interface FetchProductParams {
  store_identifier?: string;
  slot_date?: string;
  slot_datetime?: string;
  slot_type?: string;
  is_slot_locked?: boolean;
}

export const fetchProduct = (
  productUid: string,
  params: Optional<FetchProductParams> = {},
  baseUrl: Optional<string> = url
): Promise<Result<Product>> => {
  return serviceRequest<Product>({
    method: Method.GET,
    url: `${baseUrl}/v1/product/${productUid}${encodeQueryParams(params)}`,
  });
};

export const fetchSimilarProducts = (
  productId: string,
  storeIdentifier?: string,
  slotDate?: string,
  slotDateTime?: string,
  slotType?: string,
  isSlotLocked?: boolean,
  maxNumberOfAds: Optional<number> = 6,
  baseUrl: Optional<string> = url
) => {
  return serviceRequest<SimilarProducts>({
    method: Method.POST,
    url: `${baseUrl}/v1/product/similar`,
    body: {
      product_uid: productId,
      max_number_of_ads: maxNumberOfAds,
      store_identifier: storeIdentifier,
      slot_date: slotDate,
      slot_datetime: slotDateTime,
      slot_type: slotType,
      is_slot_locked: isSlotLocked,
    },
  });
};

export const fetchFrequentlyBoughtTogether = (productId: string, baseUrl: Optional<string> = url) => {
  return serviceRequest<FrequentlyBoughtTogether>({
    method: Method.GET,
    url: `${baseUrl}/v1/product/frequently-bought-together`,
    body: {
      product_uid: productId,
    },
  });
};

export const fetchYnpProducts = (
  queryParams: ProductQueryParameters,
  baseUrl: Optional<string> = url
): Promise<Result<Products>> => {
  const reOrderSKUFlag = featureFlags.get("order_by_secondary_offers");

  trackExperiment(EXPERIMENTS.Reorder_YNP_Test_Group_1, reOrderSKUFlag ? "2" : "1");

  const params = mapControlParameters({
    ...queryParams,
    orderBySecondaryOffers: reOrderSKUFlag,
  });

  return serviceRequest<Products>({
    method: Method.GET,
    url: `${baseUrl}/v1/product/your-nectar-prices${encodeQueryParams(params)}`,
  });
};

export const fetchNectarProducts = (
  queryParams: ProductQueryParameters,
  baseUrl: Optional<string> = url
): Promise<Result<Products>> => {
  const params = mapControlParameters(queryParams);
  return serviceRequest<Products>({
    method: Method.GET,
    url: `${baseUrl}/v1/nectar_products${encodeQueryParams(params)}`,
  });
};

export const fetchTrendingSearches = (baseUrl: Optional<string> = url): Promise<Result<{ terms: string[] }>> => {
  return serviceRequest<{ terms: string[] }>({
    method: Method.GET,
    url: `${baseUrl}/v1/trending_searches`,
  });
};

export const fetchProductRecommendations = (
  placement: string,
  limit: Optional<string> = "60",
  itemId: Optional<string> = "",
  channel: Optional<string> = "",
  baseUrl: Optional<string> = url
): Promise<Result<ProductRecommendations>> => {
  const params = Object.assign({}, { placement }, { limit }, itemId ? { itemId } : null, channel ? { channel } : null);

  return serviceRequest<ProductRecommendations>({
    method: Method.GET,
    url: `${baseUrl}/v1/recommendations${encodeQueryParams(params)}`,
  });
};

export const fetchDietaryWarnings = (
  eans: string[],
  baseUrl: Optional<string> = url
): Promise<Result<{ eans: string[] }>> => {
  return serviceRequest<{ eans: string[] }>({
    method: Method.GET,
    url: `${baseUrl}/v1/product/dietary-warnings${encodeQueryParams({ eans: eans.join(",") })}`,
  });
};

export const fetchProductsByUids = (
  uids: string[],
  includes: IncludeFilters,
  filterAvailable?: boolean,
  minimised?: boolean,
  storeIdentifier?: string,
  slotDate?: string,
  slotDateTime?: string,
  slotType?: string,
  isSlotLocked?: boolean,
  flexiStores?: string[],
  baseUrl: Optional<string> = url
): Promise<Result<Products>> => {
  const params = Object.assign(
    {},
    { uids: uids.join(",") },
    { "filter[available]": filterAvailable },
    mapIncludes(includes),
    { minimised },
    { store_identifier: storeIdentifier },
    { slot_date: slotDate },
    { slot_datetime: slotDateTime },
    { slot_type: slotType },
    isSlotLocked ? { is_slot_locked: isSlotLocked } : false,
    { flexi_stores: flexiStores }
  );
  return serviceRequest<Products>({ method: Method.GET, url: `${baseUrl}/v1/product${encodeQueryParams(params)}` });
};

export const mapFilters = (filters: RequestFilters): {} =>
  Object.keys(filters).reduce((acc, currentKey) => {
    acc[`filter[${currentKey}]`] = filters[`${currentKey}`];
    return acc;
  }, {});

export const mapIncludes = (includes: IncludeFilters): {} => {
  return Object.keys(includes).reduce((acc, currentKey) => {
    acc[`include[${includes[currentKey]}]`] = true;
    return acc;
  }, {});
};

export const mapControlParameters = ({
  pageNumber,
  pageSize,
  page,
  sort,
  filters,
  includes,
  minimised,
  storeIdentifier,
  region,
  slotDate,
  slotDateTime,
  isSlotLocked,
  slotType,
  flexiStores,
  personalizationId,
  dietaryProfile,
  pattern,
  orderBySecondaryOffers,
  compareSeasonal,
}: ProductQueryParameters) =>
  Object.assign(
    {},
    pageNumber ? { page_number: pageNumber } : null,
    page ? { page_number: page } : null,
    pageSize ? { page_size: pageSize } : null,
    sort ? { sort_order: sort } : null,
    filters ? mapFilters(filters) : null,
    includes ? mapIncludes(includes) : null,
    minimised ? { minimised } : null,
    storeIdentifier ? { store_identifier: storeIdentifier } : null,
    region ? { region } : null,
    slotDate ? { slot_date: slotDate } : null,
    slotDateTime ? { slot_datetime: slotDateTime } : null,
    slotType ? { slot_type: slotType } : null,
    isSlotLocked ? { is_slot_locked: isSlotLocked } : false,
    flexiStores ? { flexi_stores: flexiStores } : null,
    personalizationId ? { personalization_id: personalizationId } : null,
    dietaryProfile ? { dietary_profile: dietaryProfile } : null,
    pattern ? { pattern } : null,
    orderBySecondaryOffers ? { order_by_secondary_offers: orderBySecondaryOffers } : null,
    compareSeasonal ? { compare_seasonal: compareSeasonal } : null
  );

export interface FetchFirstFavouritesParams {
  store_identifier?: string;
  slot_date?: string;
  slot_datetime?: string;
}

export const fetchFirstFavourites = (
  params: FetchFirstFavouritesParams,
  baseUrl: Optional<string> = url
): Promise<Result<Products>> => {
  return serviceRequest<Products>({
    method: Method.GET,
    url: `${baseUrl}/v1/first-favourites${encodeQueryParams(params)}`,
  });
};

export const fetchFavourites = (
  queryParams: ProductQueryParameters,
  pattern: string = favouritePatterns.wcs,
  version2: Optional<boolean> = false,
  baseUrl: Optional<string> = url
): Promise<Result<FavouritesResponse>> => {
  const params = {
    ...mapControlParameters({
      ...queryParams,
      minimised: true,
      ...(pattern === favouritePatterns.wcs ? {} : { pattern }), // TODO: default to wcs favourites without defining (this should change after moving to v2).
    }),
    ...(pattern === favouritePatterns.wcs ? { "include[PRODUCT_AD]": "citrus" } : {}), // hardcoded request param to distinguish from apps, for which no citrus call currently needed.
  };

  if (version2 === true) {
    return serviceRequest<FavouritesResponse>({
      method: Method.GET,
      url: `${baseUrl}/v1/favourites-by-pattern${encodeQueryParams(params)}`,
    });
  }

  return serviceRequest<FavouritesResponse>({
    method: Method.GET,
    url: `${baseUrl}/v1/favourites${encodeQueryParams(params)}`,
  });
};

export interface FetchProductBySeoParams {
  seoUrl: string;
  store_identifier?: string;
  slot_date?: string;
  slot_datetime?: string;
  slot_type?: string;
  is_slot_locked?: boolean;
}

export const fetchProductBySeoUrl = (
  queryParams: FetchProductBySeoParams,
  baseUrl: Optional<string> = url
): Promise<Result<ProductsBySeoUrlResponse>> => {
  const params = {
    "filter[product_seo_url]": `gb/groceries/${queryParams.seoUrl}`,
    "include[ASSOCIATIONS]": true,
    "include[PRODUCT_AD]": "citrus",
    ...(queryParams.store_identifier ? { store_identifier: queryParams.store_identifier } : {}),
    ...(queryParams.slot_date ? { slot_date: queryParams.slot_date } : {}),
    ...(queryParams.slot_datetime ? { slot_datetime: queryParams.slot_datetime } : {}),
    ...(queryParams.slot_type ? { slot_type: queryParams.slot_type } : {}),
    ...(queryParams.is_slot_locked ? { is_slot_locked: queryParams.is_slot_locked } : {}),
  };

  return serviceRequest<ProductsBySeoUrlResponse>({
    method: Method.GET,
    url: `${baseUrl}/v1/product${encodeQueryParams(params)}`,
  });
};

export const fetchProductsByOrder = (
  { orderUid, pageNumber, pageSize, sort, filters, includes, isSlotLocked }: ProductsByOrderParameters,
  baseUrl: Optional<string> = url
): Promise<Result<PaginatedProducts>> => {
  const { storeIdentifier, slotDate, slotDateTime, reservationType } = getCachedSlotReservation() || {};
  const params = {
    "filter[order_uid]": orderUid,
    ...mapControlParameters({
      pageNumber,
      pageSize,
      sort,
      filters,
      includes,
      storeIdentifier,
      slotDate,
      slotDateTime,
      slotType: reservationType,
      isSlotLocked,
    }),
  };
  return serviceRequest<PaginatedProducts>({
    method: Method.GET,
    url: `${baseUrl}/v1/product${encodeQueryParams(params)}`,
  });
};

export const searchProducts = (
  {
    searchTerm,
    pageNumber,
    page,
    pageSize,
    sort,
    filters,
    includes,
    storeIdentifier,
    personalizationId,
    dietaryProfile,
    slotDate,
    slotDateTime,
    slotType,
    isSlotLocked,
    flexiStores,
    region,
    isSearchCategoryMatchEnabled,
    salesWindowVariant,
  }: SearchProductParameters,
  baseUrl: Optional<string> = url
): Promise<Result<SearchResponse>> => {
  const params = {
    "filter[keyword]": searchTerm,
    "include[PRODUCT_AD]": "citrus",
    citrus_max_number_ads: 5,
    ...mapControlParameters({
      pageNumber,
      pageSize,
      page,
      sort,
      filters,
      includes,
      storeIdentifier,
      personalizationId,
      dietaryProfile,
      slotDate,
      slotDateTime,
      slotType,
      isSlotLocked,
      flexiStores,
      region,
    }),
  };

  if (isSearchCategoryMatchEnabled) {
    params.intentClassifierVersion = "a";
  }

  if ((sort === "-relevance" || sort === FAVOURITES_FIRST) && salesWindowVariant)
    return serviceRequest<SearchResponse>({
      method: Method.GET,
      url: `${baseUrl}/v1/product${encodeQueryParams({ ...params, salesWindow: salesWindowVariant })}`,
    });
  else {
    return serviceRequest<SearchResponse>({
      method: Method.GET,
      url: `${baseUrl}/v1/product${encodeQueryParams(params)}`,
    });
  }
};

export const searchProductsForEventsandFeatures = (
  {
    searchTerm,
    pageNumber,
    page,
    pageSize,
    sort,
    filters,
    includes,
    storeIdentifier,
    personalizationId,
    dietaryProfile,
    slotDate,
    slotDateTime,
    slotType,
    isSlotLocked,
    flexiStores,
    region,
    sponsoredProductsCount,
    sponsoredCategoryId,
    isSearchCategoryMatchEnabled,
  }: EventsSearchProductParameters,
  baseUrl: Optional<string> = url
): Promise<Result<SearchResponse>> => {
  const params = {
    "filter[keyword]": searchTerm,
    "include[PRODUCT_AD]": "citrus",
    citrus_placement: "category-only",
    citrus_max_number_ads: sponsoredProductsCount,
    categoryId: sponsoredCategoryId,
    ...mapControlParameters({
      pageNumber,
      pageSize,
      page,
      sort,
      filters,
      includes,
      storeIdentifier,
      personalizationId,
      dietaryProfile,
      slotDate,
      slotDateTime,
      slotType,
      isSlotLocked,
      flexiStores,
      region,
    }),
  };

  if (isSearchCategoryMatchEnabled) {
    params.intentClassifierVersion = "a";
  }

  return serviceRequest<SearchResponse>({
    method: Method.GET,
    url: `${baseUrl}/v1/product${encodeQueryParams(params)}`,
  });
};

export const searchProductsByPromotion = (
  {
    pageNumber,
    page,
    pageSize,
    sort,
    filters,
    includes,
    storeIdentifier,
    personalizationId,
    dietaryProfile,
    slotDate,
    slotDateTime,
    slotType,
    isSlotLocked,
    flexiStores,
    promotionId,
    listId,
    region,
    isSearchCategoryMatchEnabled,
  }: SearchProductParameters,
  baseUrl: Optional<string> = url
): Promise<Result<SearchResponse>> => {
  const params = {
    "filter[keyword]": "",
    hfss_restricted: false,
    ...mapControlParameters({
      pageNumber,
      pageSize,
      page,
      sort,
      filters,
      includes,
      storeIdentifier,
      personalizationId,
      dietaryProfile,
      slotDate,
      slotDateTime,
      slotType,
      isSlotLocked,
      flexiStores,
      region,
    }),
  };

  if (isSearchCategoryMatchEnabled) {
    params.intentClassifierVersion = "a";
  }

  if (promotionId) {
    params["filter[promotion]"] = promotionId;
  }
  if (listId) {
    params["filter[listid]"] = listId;
  }

  return serviceRequest<SearchResponse>({
    method: Method.GET,
    url: `${baseUrl}/v1/product${encodeQueryParams(params)}`,
  });
};

export const searchProductsForCarousel = (
  {
    searchTerm,
    categoryId,
    promotionId,
    pageNumber,
    page,
    pageSize,
    sort,
    filters,
    includes,
    storeIdentifier,
    slotDate,
    slotDateTime,
    slotType,
    isSlotLocked,
    flexiStores,
    region,
    hfss_restricted,
  }: SearchProductParameters,
  baseUrl: Optional<string> = url
): Promise<Result<SearchResponse>> => {
  const params = {
    "filter[keyword]": searchTerm,
    hfss_restricted,
    ...mapControlParameters({
      pageNumber,
      pageSize,
      page,
      sort,
      filters,
      includes,
      storeIdentifier,
      slotDate,
      slotDateTime,
      slotType,
      isSlotLocked,
      flexiStores,
      region,
    }),
  };

  // Add categoryId to params only if it's populated
  if (categoryId) {
    params["filter[category]"] = categoryId;
  }
  if (promotionId) {
    params["filter[promotion]"] = promotionId;
  }
  return serviceRequest<SearchResponse>({
    method: Method.GET,
    url: `${baseUrl}/v1/product${encodeQueryParams(params)}`,
  });
};

export const deleteFavourites = (productUid: string, baseUrl: Optional<string> = url): Promise<Result<void>> => {
  const params: { sourceType: string } = {
    sourceType: "new_favourites_service",
  };

  return serviceRequest<void>({
    method: Method.DELETE,
    url: `${baseUrl}/v1/favourites/${productUid}${encodeQueryParams(params)}`,
  });
};

export const saveFavourite = (productUid: string, baseUrl: Optional<string> = url): Promise<Result<void>> => {
  return serviceRequest<void>({
    method: Method.POST,
    url: `${baseUrl}/v1/product/favourites`,
    body: {
      item: productUid,
    },
  });
};

export const fetchCategoryTree = (baseUrl: Optional<string> = url): Promise<Result<CategoryTreeResponse>> => {
  return serviceRequest<CategoryTreeResponse>({ method: Method.GET, url: `${baseUrl}/categories/tree` });
};

export const fetchSearchSuggestions = async (
  search: string,
  baseUrl: Optional<string> = url
): Promise<{ label: string; value: string }[]> => {
  const params: { search: string; with_findability?: boolean } = {
    search,
  };

  const res = await serviceRequest<SuggestedSearchTerm[]>({
    method: Method.GET,
    url: `${baseUrl}/v1/product/search/suggestions${encodeQueryParams(params)}`,
  });

  return res.data
    ? res.data.map((item: { term: string }) => ({
        label: item.term,
        value: item.term,
      }))
    : [];
};

// fetchBrowseProducts fetches products for a Browse page using its category ID.
export const fetchBrowseProducts = (
  {
    categoryID,
    filters,
    page,
    sort,
    slotDate,
    slotDateTime,
    storeIdentifier,
    slotType,
    isSlotLocked,
    flexiStores,
    region,
    pageType,
    isEventPage,
    sponsoredCategoryId,
    enableFavouritesPriority,
    salesWindowVariant,
  }: FetchBrowseProductsArgs,
  baseUrl: Optional<string> = url
): Promise<Result<PaginatedProducts>> => {
  let pageTypeArg;
  switch (pageType) {
    case BrowsePageType.AISLE:
      pageTypeArg = { aisle: true };
      break;
    case BrowsePageType.DEPARTMENT:
      pageTypeArg = { department: true };
      break;
    case BrowsePageType.ZONE:
      pageTypeArg = { zone: true };
      break;
    default:
      pageTypeArg = { browse: true };
  }

  const params = {
    "filter[keyword]": "",
    "filter[category]": categoryID,
    ...(`${categoryID}`.includes(",") && { "filter[category:operator]": "OR" }),
    ...(pageTypeArg && { ...pageTypeArg }),
    hfss_restricted: false,
    slot_date: slotDate,
    slot_datetime: slotDateTime,
    store_identifier: storeIdentifier,
    slot_type: slotType,
    flexi_stores: flexiStores,
    region,
    categoryId: sponsoredCategoryId,
    ...(isEventPage && { citrus_max_number_ads: 15 }),
    ...mapControlParameters({ filters, pageNumber: parseInt(page), sort, isSlotLocked }),
    ...(pageType === BrowsePageType.SHELF && !sort && { sort_order: FAVOURITES_FIRST }),
    ...(pageType !== BrowsePageType.ZONE && { favouritesPriority: enableFavouritesPriority }),
    ...(pageType === BrowsePageType.ZONE
      ? { page_size: 20 }
      : { "include[PRODUCT_AD]": "citrus", citrus_placement: "category-only" }),
  };

  if (salesWindowVariant && sort !== "-price" && sort !== "-customer rating")
    return serviceRequest<SearchResponse>({
      method: Method.GET,
      url: `${baseUrl}/v1/product${encodeQueryParams({ ...params, salesWindow: salesWindowVariant })}`,
    });
  else {
    return serviceRequest<SearchResponse>({
      method: Method.GET,
      url: `${baseUrl}/v1/product${encodeQueryParams(params)}`,
    });
  }
};

export interface FetchProductAdsParams {
  pageType?: string;
  searchTerm?: string;
  maxNumberOfAds: number;
  maxAttemptCount?: number;
  productFilters?: string[][];
  storeIdentifier?: string;
  slotDate?: string;
  slotDateTime?: string;
  slotType?: string;
  isSlotLocked?: boolean;
  flexiStores?: string[];
  placement?: string;
  offersOnly?: boolean;
}

export const fetchProductAds = (
  params: FetchProductAdsParams,
  baseUrl: Optional<string> = url
): Promise<Result<CitrusProductAds>> => {
  return serviceRequest<CitrusProductAds>({
    method: Method.POST,
    url: `${baseUrl}/v1/product/sponsored`,
    body: {
      page_type: params.pageType,
      search_term: params.searchTerm,
      max_number_of_ads: params.maxNumberOfAds,
      max_attempt_count: params.maxAttemptCount,
      product_filters: params.productFilters,
      store_identifier: params.storeIdentifier,
      slot_date: params.slotDate,
      slot_datetime: params.slotDateTime,
      slot_type: params.slotType,
      is_slot_locked: params.isSlotLocked,
      flexi_stores: params.flexiStores,
      placement: params.placement,
      offers_only: params.offersOnly,
    },
  });
};

export interface FetchProductInterstitialParams {
  alwaysReturnProducts: boolean;
  store_identifier?: string;
  slot_date?: string;
  slot_datetime?: string;
  slot_type?: string;
  is_slot_locked?: boolean;
}

export const fetchInterstitialUpsellProducts = (
  params: FetchProductInterstitialParams,
  baseUrl: Optional<string> = url
): Promise<Result<Products>> => {
  return serviceRequest({
    method: Method.GET,
    url: `${baseUrl}/v1/product/interstitial/upsells${encodeQueryParams(params)}`,
  });
};

export const fetchInterstitialForgottenFavourites = (
  params: FetchProductInterstitialParams,
  baseUrl: Optional<string> = url
): Promise<Result<Products>> => {
  return serviceRequest({
    method: Method.GET,
    url: `${baseUrl}/v1/product/interstitial/forgotten-favourites${encodeQueryParams(params)}`,
  });
};

export const fetchBrowseTree = (): Promise<Result<BrowseServiceResponse>> => {
  return serviceRequest({ method: Method.GET, url: `${url}/v1/product/taxonomy` });
};

export const fetchBrowseZoneCitrusData = ({
  storeIdentifier,
  slotDate,
  region,
  findabilityID,
}: {
  findabilityID: string;
  storeIdentifier?: string;
  slotDate?: string;
  region?: string;
}): Promise<Result<ZoneCitrusResponse>> => {
  return serviceRequest({
    method: Method.POST,
    url: `/groceries-api/gol-services/content/v2/withMagnoliaTemplate/ads`,
    body: {
      placement: "zone",
      slotId: "Zone_1",
      maxNumberOfAds: 4,
      espotIds: ["Zone_above_grid_1", "Zone_above_grid_2", "Zone_above_grid_3", "Zone_above_grid_4"],
      category_ids: findabilityID.split(","),
      store: storeIdentifier,
      delivery_date: slotDate,
      region,
    },
  });
};

export const fetchMegaNav = (): Promise<Result<MegaNavData>> => {
  return serviceRequest<MegaNavData>({
    method: Method.GET,
    url: `${url}/v1/product/meganav`,
  });
};
