import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getChooseShoppingMethodUrl, urls } from "../../../routes";
import { State } from "../../../common/store";
import { selectSlotCutOffModalDescription } from "../AmendOrderBanner.selectors";
import { BasicModal } from "../../../components/Modal/NewModal/Modal.component";

export const AmendOrderCutOffModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}) => {
  const description = useSelector((state: State) => selectSlotCutOffModalDescription(state));
  const history = useHistory();

  return (
    <BasicModal
      title="Your order cut off time has been reached"
      className="modal-width-500"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      primaryButtonText="Book a slot"
      onPrimaryButtonClick={() => {
        setIsOpen(false);
        history.push(getChooseShoppingMethodUrl());
      }}
      secondaryButtonText="View orders"
      onSecondaryButtonClick={() => {
        setIsOpen(false);
        history.push(urls.MY_ORDERS);
      }}
    >
      <p>{description}</p>
    </BasicModal>
  );
};
