import { WithDataState } from "../../common/dataState";
import {
  ProductNectarOffer,
  ProductTileProduct,
  ProductTilePromotion,
} from "../../components/ProductTile/productTile.types";
import { DietaryProfileStatus } from "../../domain/customer/customer";
import { ProductServiceError } from "../../services/product.types";
import { OrderPromiseStatus, ProductsWithAds } from "../../domain/product/product.types";
import { SetUnitParams } from "../../domain/basket/basket.types";

export enum ProductDetailsAction {
  FETCH_PRODUCT_DETAILS_SUCCESS = "FETCH_PRODUCT_DETAILS_SUCCESS",
  FETCH_PRODUCT_DETAILS_PENDING = "FETCH_PRODUCT_DETAILS_PENDING",
  FETCH_PRODUCT_DETAILS_ERROR = "FETCH_PRODUCT_DETAILS_ERROR",
  FETCH_PRODUCT_DETAILS_NOT_FOUND_ERROR = "FETCH_PRODUCT_NOT_FOUND_ERROR",
  AD_IMPRESSION = "AD_IMPRESSION",
  SET_SELECTED_VARIANT = "SET_SELECTED_VARIANT",
}

export interface ProductDetailsTileProps extends ProductDetailsTileContainerProps {
  setSelectedVariant: React.Dispatch<React.SetStateAction<string>>;
  isFavourite: boolean;
  hasNectarAssociated?: boolean;
  currentOrderPromiseStatus?: OrderPromiseStatus;
}

export type ProductDetailsTileContainerProps = {
  onViewReviewsClick: () => void;
  onWriteReviewsClick: () => void;
  product: ProductTileProduct;
  onChangeUnitOfMeasure: (setUnitParams: SetUnitParams) => void;
  useCustomMessaging?: boolean;
  selectedCatchweight?: string | undefined;
  promotions?: ProductTilePromotion[];
  nectarOffer?: ProductNectarOffer | undefined;
};

export interface ProductDetailsDetailsProps {
  product: ProductTileProduct;
  activeTab?: string;
}

export interface ProductDetailsState extends WithDataState {
  productDetails: ProductDetails;
  errors: ProductServiceError[];
}

export interface ProductDetails {
  isProductNotFound: boolean;
}

export type SetSelectedVariant = {
  type: ProductDetailsAction.SET_SELECTED_VARIANT;
  variantUid: string;
};

export type FetchProductDetailsPending = {
  type: ProductDetailsAction.FETCH_PRODUCT_DETAILS_PENDING;
};

export type FetchProductDetailsError = {
  type: ProductDetailsAction.FETCH_PRODUCT_DETAILS_ERROR;
};

export type FetchProductDetailsNotFoundError = {
  type: ProductDetailsAction.FETCH_PRODUCT_DETAILS_NOT_FOUND_ERROR;
};

export type FetchProductDetailsSuccess = {
  type: ProductDetailsAction.FETCH_PRODUCT_DETAILS_SUCCESS;
  productsWithAds: ProductsWithAds;
  errors?: ProductServiceError[];
};

export interface ProductDetailsProps {
  userDetailsDietaryProfile: DietaryProfileStatus;
  hasMerchandisingProduct: boolean;
  showProduct: boolean;
  isError: boolean;
  isNotFound: boolean;
  productTileProduct?: ProductTileProduct;
  alcoholWarning: AlcoholWarning;
  showDietaryProfileWarning: boolean;
  attachments: Attachment[];
}

export interface Attachment {
  name: string;
  description: string;
  path: string;
}

export interface AttachmentsProps {
  attachments: Attachment[];
}

export enum MimeType {
  PDF = "application/pdf",
}

export enum AlcoholWarning {
  NONE = "NONE",
  SCOTTISH_LEGISLATION = "SCOTTISH_LEGISLATION",
  DEFAULT = "DEFAULT",
}

export interface ProductDetailsMerchandisingProductTileProps {
  className?: string;
}

export type AdImpressionAction = {
  type: ProductDetailsAction.AD_IMPRESSION;
  adId: string;
};
