import * as domain from "../../domain/product/product.types";
import { mapProduct } from "../../domain/product/product";
import { AdMetaData, createAdImpressionAction, createAdsTrackingDataAction } from "../../common/ads";
import { AppThunkDispatch } from "../../common/types";
import { fetchDietaryWarnings } from "../../components/DietaryProfile/DietaryWarning/dietaryWarning.actions";
import { fetchProductAds, FetchProductAdsParams } from "../../services/product";
import { AssociationType, getProductAdEans, Product, ProductSource } from "../../domain/product/product.types";
import { getCachedSlotReservation } from "../../domain/slot/slotReservation";
import { useFeatureFlag } from "../../common/firebase/featureFlags.hooks";

const placementProductFilter = "placement:Search-Below_Grid";

export enum SearchResultsActionType {
  FETCHED_BELOW_GRID_PRODUCT_ADS_SUCCESS = "FETCHED_BELOW_GRID_PRODUCT_ADS_SUCCESS",
}

export type CreateFetchBelowGridProductAdsSuccessActionType = {
  type: SearchResultsActionType.FETCHED_BELOW_GRID_PRODUCT_ADS_SUCCESS;
  products: domain.ProductAd[];
};

export const createFetchBelowGridProductAdsSuccessAction = (
  products: domain.ProductAd[]
): CreateFetchBelowGridProductAdsSuccessActionType => {
  return {
    type: SearchResultsActionType.FETCHED_BELOW_GRID_PRODUCT_ADS_SUCCESS,
    products,
  };
};

export const fetchBelowGridProductAdsAction =
  (organicSearchResultsProducts: Product[]) => async (dispatch: AppThunkDispatch) => {
    const useReleventCategories = useFeatureFlag("search_below_grid_relevancy_categories");
    const categoryIds = createCategories(organicSearchResultsProducts, useReleventCategories);
    const { storeIdentifier, slotDate, slotDateTime, flexiStores } = getCachedSlotReservation() || {};
    const params: FetchProductAdsParams = {
      maxNumberOfAds: 6,
      pageType: "Home",
      productFilters: [categoryIds, [placementProductFilter]],
      storeIdentifier,
      slotDate,
      slotDateTime,
      flexiStores,
    };

    const result = await fetchProductAds(params);

    if (result.isSuccess()) {
      if (result.data.ads && result.data.ads.length > 0) {
        const organicProductUids = new Set(organicSearchResultsProducts.map(p => p.productUid));

        const productAds: domain.ProductAd[] = result.data.ads
          .filter(ad => !organicProductUids.has(ad.product.product_uid))
          .filter(ad => ad.ad_id)
          .map(
            ad =>
              ({
                id: ad.ad_id,
                product: mapProduct(ad.product, 0, ProductSource.CITRUS_SEARCH_ADS_BELOW_GRID, AssociationType.NONE),
                type: ad.type,
              } as domain.ProductAd)
          );

        dispatch(createFetchBelowGridProductAdsSuccessAction(productAds));
        dispatch(fetchDietaryWarnings(getProductAdEans(productAds)));

        productAds.forEach(ad => {
          dispatch(createAdImpressionAction(ad.id));
          const AdData: AdMetaData[] = [{ id: ad.id, type: ad.type, productUid: ad.product.productUid }];
          dispatch(createAdsTrackingDataAction(AdData));
        });
      }
    }
  };

export const createCategories = (products: domain.Product[], useReleventCategories: boolean): string[] => {
  const categories = products.reduce((acc: string[], currentValue: domain.Product): string[] => {
    // Filter categories based on relevancyRank only if the feature flag is enabled
    const relevantCategories = useReleventCategories && currentValue.relevancyRank !== 1 ? [] : currentValue.categories;

    relevantCategories.forEach(c => {
      acc.push(`category_id:${c.id}`);
    });

    return acc;
  }, []);
  return Array.from(new Set(categories));
};
