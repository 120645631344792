import { RecipeProductCommon } from "./ATBModal.type";
import { getAccuratePrice, getRecipeProductPrice, isAvailable } from "./ATBModal.utils";

export class RecipeProductSet {
  items: RecipeProductCommon[];

  constructor(products: RecipeProductCommon[]) {
    this.items = products;
  }

  static combine(sets: (RecipeProductSet | null)[]): RecipeProductSet {
    const productViews = sets.reduce((prev, set) => {
      if (set == null) return prev;
      return prev.concat(set.items);
    }, [] as RecipeProductCommon[]);
    return RecipeProductSet.fromUnknownArray(productViews);
  }

  static fromUnknownArray(products?: RecipeProductCommon[]) {
    products = products ?? [];
    products.forEach(p => {
      p.selected = p.selected ?? !p.common;
    });
    return new RecipeProductSet(products.sort(RecipeProductSet._sorter));
  }

  static _sorter(l: RecipeProductCommon, r: RecipeProductCommon): number {
    const lPrice = getRecipeProductPrice(l);
    const rPrice = getRecipeProductPrice(r);
    if (!isAvailable(l) || lPrice == null) return 1;
    if (!isAvailable(r) || rPrice == null) return -1;
    return rPrice.retail_price!.price - lPrice.retail_price!.price;
  }

  clone() {
    return new RecipeProductSet(this.items);
  }

  get length() {
    return this.items.length;
  }

  getTotalPrice(userHasNectar: boolean): number {
    return this.getAvailableAndSelected().reduce((agg, p) => {
      const { price } = getAccuratePrice(p, userHasNectar);
      return agg + price * p.quantity;
    }, 0);
  }

  getAvailable(): RecipeProductCommon[] {
    return this.items.filter(e => isAvailable(e));
  }

  getAvailableAndSelected(): RecipeProductCommon[] {
    return this.items.filter(e => e.selected && isAvailable(e));
  }

  updateItem(productId: string, ingredientId: number, selected: boolean, qty: number): this {
    const tgt = this.items.find(e => e.ingredient_id === ingredientId && e.product_id === productId);
    if (tgt == null) {
      return this;
    }
    tgt.selected = !!selected;
    tgt.quantity = Math.max(0, qty);
    return this;
  }

  swapItem(replacement: RecipeProductCommon) {
    const subIdx = this.items.findIndex(e => e.substitute_identifier === replacement.substitute_identifier);
    if (subIdx === -1) {
      console.error("No existing product found to substitute");
    } else {
      this.items[subIdx] = replacement;
    }
    return this;
  }
}
