import React, { useState } from "react";
import { Container, Button } from "@jsluna/react";
import clsx from "clsx";
import { AmendOrderBannerComponentProps } from "./AmendOrderBanner.types";
import { AmendOrderExpiryModal } from "./AmendOrderModal/AmendOrderSlotExpiryModal.container";
import { AmendOrderCutOffModal } from "./AmendOrderModal/AmendOrderCutOffModal.container";
import { AmendOrderBannerDetails } from "./AmendOrderBannerDetails/AmendOrderBannerDetails.component";
import { Notification } from "../Notification/Notification.component";
import { GOLLink } from "../GOLLink";
import { TrackAndNavigate } from "../TrackAndNavigate/TrackAndNavigate.component";
import { getChooseShoppingMethodUrl, matchesPath, urls } from "../../routes";
import { InlineError } from "../Error/InlineError";
import { AmendDiscardChangesModal } from "../Modal/NewModal/AmendDiscardChangesModal.component";
import { useFeatureFlag } from "../../common/firebase/featureFlags.hooks";

export const AmendOrderBanner = ({
  expiryDetails,
  showSlotExpiryModal,
  showCutOffModal,
  order,
  hasOccasionItems,
  primaryCta,
  showSlotExpiryDetails,
  showCutOffDetails,
  pathname,
  usesFavouritesHeader,
  dispatchSetReservationExpired,
  dispatchSetCutOffExpired,
  showCheckoutCTA,
  startCheckout,
  error,
  isRecipePage,
}: AmendOrderBannerComponentProps) => {
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const [isExpiryModalOpen, setIsExpiryModalOpen] = useState(showSlotExpiryModal);
  const [isCutOffModalOpen, setIsCutOffModalOpen] = useState(showCutOffModal);

  const isBYGABTestEnabled = useFeatureFlag("byg_ab_test");
  const isCheckoutBYGPage = matchesPath(location.pathname, urls.CHECKOUT_BEFORE_YOU_GO);
  const isCheckoutFFPage = matchesPath(location.pathname, urls.CHECKOUT_FORGOTTEN_FAVOURITES);
  const hasShorterHeaderOverwrite = isBYGABTestEnabled && (isCheckoutBYGPage || isCheckoutFFPage);

  return (
    <>
      {!order!.isCutOff && (
        <Container
          role="dialog"
          aria-labelledby="dialogDesc"
          data-testid="amend-order-banner"
          className={clsx(
            "amend-order-banner",
            { "amend-order-banner__margin-top--favourites": usesFavouritesHeader },
            { "amend-order-banner__margin-top--recipes": isRecipePage },
            {
              "amend-order-banner__margin-top--standard":
                !usesFavouritesHeader && !isRecipePage && !hasShorterHeaderOverwrite,
            },
            { "amend-order-banner__margin-top--BYG-AB-test": hasShorterHeaderOverwrite }
          )}
        >
          <Notification className="amend-order-banner__wrapper">
            <div className="amend-order-banner__content">
              <AmendOrderBannerDetails
                order={order}
                hasOccasionItems={hasOccasionItems}
                expiryDetails={expiryDetails}
                showSlotExpiryDetails={showSlotExpiryDetails}
                showCutOffDetails={showCutOffDetails}
                dispatchSetReservationExpired={dispatchSetReservationExpired}
                dispatchSetCutOffExpired={dispatchSetCutOffExpired}
              />
              {showCheckoutCTA && (
                <div className="amend-order-banner__cta-wrapper">
                  {primaryCta.link ? (
                    <GOLLink
                      className="ln-c-button amend-order-banner__button amend-order-banner__button--outlined"
                      data-testid="amend-order-banner-primary-cta"
                      to={primaryCta.link}
                      external
                    >
                      {primaryCta.text}
                    </GOLLink>
                  ) : (
                    <TrackAndNavigate
                      eventData={{
                        eventName: "OrderAmendCheckout",
                        eventLabel: `Checkout clicked from order amend bar - ${pathname}`,
                        eventAction: `Checkout clicked from order amend bar - ${pathname}`,
                      }}
                      navigationCallBack={startCheckout}
                      data-testid="amend-order-banner-primary-cta"
                      className="ln-c-button amend-order-banner__button amend-order-banner__button--outlined"
                    >
                      <Button className="ln-c-button--full amend-order-banner--button-bg-mod">{primaryCta.text}</Button>
                    </TrackAndNavigate>
                  )}
                </div>
              )}
              <div className="amend-order-banner__cta-wrapper">
                <Button
                  className="ln-c-button amend-order-banner__button amend-order-banner__button--text amend-order-banner__button--secondary-cta amend-order-banner--button-bg-mod"
                  data-testid="amend-order-banner-secondary-cta"
                  onClick={() => setIsDiscardChangesModalOpen(true)}
                >
                  Discard changes
                </Button>
              </div>
            </div>
          </Notification>
          {error === "general_error" && (
            <div className="ln-u-margin-bottom*2">
              <InlineError errorMessage="Sorry, something went wrong. Please try to Checkout again." />
            </div>
          )}
          {error === "slot_error" && (
            <div className="ln-u-margin-bottom*2">
              <InlineError
                errorMessage="We can’t retrieve slot information at the moment."
                callToAction={() => window.location.assign(getChooseShoppingMethodUrl())}
                callToActionMessage={{ link: "Retry" }}
              />
            </div>
          )}
        </Container>
      )}

      {showSlotExpiryModal && <AmendOrderExpiryModal isOpen={isExpiryModalOpen} setIsOpen={setIsExpiryModalOpen} />}
      {showCutOffModal && <AmendOrderCutOffModal isOpen={isCutOffModalOpen} setIsOpen={setIsCutOffModalOpen} />}
      <AmendDiscardChangesModal isOpen={isDiscardChangesModalOpen} setIsOpen={setIsDiscardChangesModalOpen} />
    </>
  );
};

AmendOrderBanner.displayName = "AmendOrderBanner";
