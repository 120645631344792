export enum DataState {
  UNKNOWN = "UNKNOWN",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export interface WithDataState {
  dataState: DataState;
}
