import { useEffect, useRef } from "react";

export function useScroll(isScrollable: boolean) {
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isScrollable) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
      }
    }
  }, [isScrollable]);

  return ref;
}

export function useDivScroll(isScrollable: boolean) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isScrollable) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
      }
    }
  }, [isScrollable]);

  return ref;
}

export default useScroll;
