import React from "react";
import { GOLLink } from "../../GOLLink";
import { Item, TemplateArea, TemplateComponent } from "../../../services/contentTypes";
import { GOLUI_PREFIX } from "../../../routes";

interface SeasonalComponentProps {
  seasonal: TemplateArea;
}

const isExternalLink = (url: string): { isExternal: boolean; modifiedUrl: string } => {
  const isInternal = url.includes("gol-ui/");
  let modifiedUrl = url;

  // If it's an internal URL, remove the domain and treat it as internal
  url = isInternal && !url.startsWith("/") ? `/${url}` : `${url}`;
  modifiedUrl = url.substring(url.indexOf(GOLUI_PREFIX));

  return { isExternal: !isInternal, modifiedUrl };
};

const SeasonalLinksComponent: React.FC<SeasonalComponentProps> = ({ seasonal }) => {
  return (
    <div className="mega-nav__column" data-testid="mega-nav-column">
      {seasonal.attributes.map((attribute: TemplateComponent) => (
        <div key={attribute.heading}>
          {attribute.section?.map(section => (
            <div key={section.heading}>
              {section.link?.url ? (
                <div role="menu" className="mega-nav__item mega-nav__title" data-testid="mega-nav-column-header">
                  {(() => {
                    const { isExternal, modifiedUrl } = isExternalLink(section.link.url);
                    return (
                      <GOLLink
                        to={modifiedUrl}
                        external={isExternal}
                        className="mega-nav__item-content"
                        data-testid="mega-nav-item-link"
                      >
                        <span className="mega-nav__item-text" data-testid="mega-nav-item-text">
                          {section.heading}
                        </span>
                      </GOLLink>
                    );
                  })()}
                </div>
              ) : (
                <div className="mega-nav__title" data-testid="mega-nav-column-header">
                  <span className="mega-nav__item-content mega-nav__item-text" data-testid="mega-nav-item-text">
                    {section.heading}
                  </span>
                </div>
              )}

              <ul role="menu" className="mega-nav__list" data-testid="mega-nav-column-list">
                {section.items?.map((item: Item) => (
                  <li
                    key={item.copy}
                    {...(item.link?.url ? { role: "menuitem" } : {})}
                    className="mega-nav__item"
                    data-testid="mega-nav-item"
                  >
                    {item.link?.url ? (
                      (() => {
                        const { isExternal, modifiedUrl } = isExternalLink(item.link.url);
                        return (
                          <GOLLink
                            to={modifiedUrl}
                            external={isExternal}
                            className="mega-nav__item-content"
                            data-testid="mega-nav-item-link"
                          >
                            <span className="mega-nav__item-text" data-testid="mega-nav-item-text">
                              {item.copy}
                            </span>
                          </GOLLink>
                        );
                      })()
                    ) : (
                      <span className="mega-nav__item-content mega-nav__item-text" data-testid="mega-nav-item-text">
                        {item.copy}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SeasonalLinksComponent;
