import { ModalNames } from "./Modal.types";
import { State } from "../../../common/store";
import { DataState } from "../../../common/dataState";
import GolTime from "../../../domain/time";
import * as momentTimeZone from "moment-timezone";
import { formatSlotTimesForAccessibilityLabels } from "../../../domain/slot/slotMessaging";
import { OrderSummary } from "../../../services/order.types";

export const selectIsReserveSlotModalOpen = (state: State): boolean => {
  const {
    modal: { meta },
  } = state;
  return Boolean(meta && meta.name === ModalNames.MODAL_RESERVE_SLOT);
};

export const selectIsAmendOrderModalOpen = (state: State): boolean => {
  const {
    modal: { meta },
  } = state;
  return Boolean(meta && meta.name === ModalNames.MODAL_AMEND_ORDER);
};

export const selectIsConfirmAmendModalOpen = (state: State): boolean => {
  const {
    modal: { meta },
  } = state;
  return Boolean(meta && meta.name === ModalNames.MODAL_CONFIRM_AMEND);
};

export const selectConfirmAmendModalOrderData = (state: State): OrderSummary | undefined => {
  const {
    modal: { meta },
  } = state;

  if (!meta) {
    return;
  }

  return meta.order;
};

export const selectIsSlotFaqModalOpen = (state: State): boolean => {
  const {
    modal: { currentlyVisible },
  } = state;
  return Boolean(currentlyVisible === ModalNames.MODAL_SLOT_FAQ);
};

export const greenVanInfoModalOpen = (state: State): boolean => {
  const {
    modal: { currentlyVisible },
  } = state;
  return Boolean(currentlyVisible === ModalNames.MODAL_GREEN_VAN_INFO);
};

export const faqsModalOpen = (state: State): boolean => {
  const {
    modal: { currentlyVisible },
  } = state;
  return Boolean(currentlyVisible === ModalNames.MODAL_CAC_FAQ);
};

export const buyingAlcoholModalOpen = (state: State): boolean => {
  const {
    modal: { currentlyVisible },
  } = state;
  return Boolean(currentlyVisible === ModalNames.MODAL_BUYING_ALCOHOL);
};

const formatSlotTime = (start: string, end: string): string => {
  return GolTime.fromServices(start).formatDateTimeInterval(end, "dddd Do MMMM[\n]");
};

export const selectSlotTime = (state: State): string => {
  const {
    modal: { meta },
  } = state;
  if (meta && meta.selectedSlot) {
    return formatSlotTime(meta.selectedSlot.startTime, meta.selectedSlot.endTime);
  }
  return "";
};

export const selectAccessibleModalLabel = (state: State): string => {
  const {
    modal: { meta },
  } = state;
  if (meta && meta.selectedSlot) {
    const [date, startTime, endTime] = formatSlotTimesForAccessibilityLabels(meta.selectedSlot);
    const price = meta.selectedSlot.price === "0" ? "free" : `£${meta.selectedSlot.price}`;
    return `Reserve slot on ${date} between ${startTime} and ${endTime} for ${price}`;
  }
  return "";
};

export const formatBookSlotTime = (state: State): string => {
  const {
    modal: { meta },
  } = state;
  if (meta && meta.selectedSlot) {
    return formatBookSlotTimeDisplay(meta.selectedSlot.startTime, meta.selectedSlot.endTime);
  }
  return "";
};

export const formatBookSlotTimeDisplay = (start: string, end: string): string => {
  const t = momentTimeZone.tz(start, GolTime.servicesFormat, "Europe/London");
  const formattedDate = t.format("dddd Do MMMM[\n]");

  //Save the selected date for the changes to trolley page
  const slotSelectedFormatted = t.format(GolTime.dateFormat);
  localStorage.setItem("slot_selected", slotSelectedFormatted);

  return `${formattedDate}${GolTime.formatTimeInterval(start, end)}`;
};

export const selectPrice = (state: State): string => {
  const { meta } = state.modal;
  return meta && meta.selectedSlot
    ? Number(meta.selectedSlot.price) > 0
      ? `£${meta.selectedSlot.price}`
      : "Free"
    : "";
};

export const selectSlotStartTime = (state: State): string => {
  const { meta } = state.modal;
  return meta && meta.selectedSlot && meta.selectedSlot.startTime;
};

export const selectIsDeliveryPassApplicable = (state: State): boolean => {
  const { meta } = state.modal;
  return meta && meta.selectedSlot ? Boolean(meta.selectedSlot.isDeliveryPassApplicable) : false;
};

export const selectIsLoading = (state: State): boolean => state.bookSlotReservation.dataState === DataState.PENDING;

export const selectIsFailed = (state: State): boolean => state.bookSlotReservation.dataState === DataState.FAILED;

export const selectFailureMessage = (state: State): string => state.bookSlotReservation.failureMessage || "";
