import React from "react";
import { ErrorCircle } from "@jsluna/icons";
import { TextButton } from "@jsluna/react";

interface CallToActionMessage {
  preLink?: string;
  link: string;
  postLink?: string;
}

export interface InlineErrorProps {
  errorMessage: string[] | string;
  callToAction?: () => void;
  callToActionMessage?: CallToActionMessage;
  dark?: boolean;
  title?: string;
}

export const InlineError = ({ errorMessage, callToAction, callToActionMessage, dark, title }: InlineErrorProps) => {
  return (
    <div
      data-testid="inline-error"
      className={`${dark ? "inline-error-no-background" : "inline-error"} `}
      aria-live="assertive"
      role="alert"
    >
      <div>
        <ErrorCircle className="inline-error__icon" aria-label="Error" size="large" />
      </div>
      <div>
        {Array.isArray(errorMessage) && errorMessage.length > 1 ? (
          <>
            <div className="inline-error__bold-title">{`${errorMessage.length} errors found:`}</div>
            <ul>
              {errorMessage.map((message: string, index: number) => (
                <li key={message}>{message}</li>
              ))}
            </ul>
          </>
        ) : (
          <>
            {title && <div className="inline-error__bold-title">{title}</div>}
            <span>{Array.isArray(errorMessage) ? errorMessage[0] : errorMessage}</span>
          </>
        )}

        {callToActionMessage?.link.length && (
          <>
            {callToActionMessage.preLink ? ` ${callToActionMessage.preLink} ` : " "}
            <TextButton
              aria-label="Click to try again"
              onClick={callToAction}
              data-testid="error-link"
              className="ln-u-font-weight-regular inline-error__retry-button"
            >
              {callToActionMessage.link}
            </TextButton>{" "}
            {callToActionMessage.postLink ?? ""}
          </>
        )}
      </div>
    </div>
  );
};
