import { Dispatch, MiddlewareAPI } from "redux";
import { matchPath } from "react-router";
import { LOCATION_CHANGE } from "connected-react-router";
import { extractQueryParams } from "../../ProductControls/productControls";
import { State } from "../../../common/store";
import { routes, urls } from "../../../routes";
import { LocationChangeAction } from "../../../common/types";
import { createInternalNavigationAction } from "../../../common/middleware/navigationMiddleware";
import { ProductQueryParameters } from "../../../services/product.types";

export const searchMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) => (next: Dispatch<any>) => async (action: LocationChangeAction) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        const { payload } = action as LocationChangeAction;
        if (isSearch(payload.location.pathname)) {
          const params: ProductQueryParameters = extractQueryParams(payload.location.search);
          const searchTerm =
            params.filters && params.filters.keyword
              ? encodeURIComponent(params.filters.keyword.replace(/%/g, ""))
              : "";
          api.dispatch(createInternalNavigationAction(`${urls.SEARCH_RESULTS_FINDABILITY}/${searchTerm}`));
        }
        break;
      default:
        break;
    }
    next(action);
  };

const isSearch = (path: string) =>
  matchPath(path, {
    path: routes.SEARCH_RESULTS,
    exact: true,
  });

export const isFindabilitySearchRoute = (path: string) =>
  matchPath(path, {
    path: [routes.SEARCH_RESULTS_FINDABILITY, routes.SEARCH_RESULTS_FINDABILITY_WITH_CATEGORIES],
    exact: true,
  });
