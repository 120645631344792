/* eslint-disable no-console */
import { initializeApp } from "firebase/app";
import { getAll, getRemoteConfig, getValue, fetchAndActivate, RemoteConfig } from "firebase/remote-config";
import { Flags, initialFeatureFlagValues } from "./featureFlags";

const initFirebase = (): RemoteConfig => {
  const { apiKey, projectId, appId, refetchAfterMs } = (window as any).GOL.config.firebase;

  const app = initializeApp({
    apiKey,
    projectId,
    appId,
  });

  const remoteConfig = getRemoteConfig(app);

  const refetchAfterMsFromEnv = Number(refetchAfterMs);
  // defaults to 1h
  const minimumFetchIntervalMillis = !isNaN(refetchAfterMsFromEnv) ? refetchAfterMsFromEnv : 1000 * 60 * 60;

  remoteConfig.settings = {
    minimumFetchIntervalMillis,
    fetchTimeoutMillis: 3000, // give up fetching after 3s
  };

  return remoteConfig;
};

const syncConfig = async (remoteConfig: RemoteConfig) => {
  await fetchAndActivate(remoteConfig);
  return remoteConfig;
};

const parseFirebaseFlags = (remoteConfig: RemoteConfig): Flags => {
  const localFlagKeys = Object.keys(initialFeatureFlagValues);
  const firebaseFlagsKeys = Object.keys(getAll(remoteConfig));
  const allFlagKeys = Array.from(new Set([...localFlagKeys, ...firebaseFlagsKeys]));
  return allFlagKeys.reduce(
    (acc: Flags, name: string): Flags => {
      try {
        const value = getValue(remoteConfig, name);
        if (value.getSource() === "remote") {
          // eslint-disable-next-line security/detect-object-injection
          acc[name] = JSON.parse(value.asString());
        } else {
          // eslint-disable-next-line security/detect-object-injection
          acc[name] = initialFeatureFlagValues[name];
        }
      } catch (e) {
        console.error(
          `Failed to parse feature flag '${name}' with value '${getValue(remoteConfig, name).asString()}'`,
          e
        );
        // eslint-disable-next-line security/detect-object-injection
        acc[name] = initialFeatureFlagValues[name];
        return acc;
      }
      return acc;
    },
    // use Object.create(null) instead of {}
    // to prevent "Global Object Injection Sink".
    // By initiating a null object, we wipe
    // the prototype chain empty.
    //
    // See MDN:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/create#custom_and_null_objects
    // and issue
    // https://stackoverflow.com/a/58179430/3943954
    Object.create(null) as Flags
  );
};

export const getFirebaseFlags = async () => {
  const remoteConfig = initFirebase();
  return parseFirebaseFlags(await syncConfig(remoteConfig));
};
