export const getInGridAdPositions = (screenWidth: number, ingridAdsLength: number) => {
  let firstAdPosition = 3;
  let secondAdPosition = ingridAdsLength > 1 ? 9 : 11;

  if (screenWidth < 960 && screenWidth >= 720) {
    firstAdPosition = 2;
    secondAdPosition = 8;
  }

  if (screenWidth < 1400 && screenWidth >= 960) {
    firstAdPosition = 1;
    secondAdPosition = ingridAdsLength > 1 ? 5 : 7;
  }

  return [firstAdPosition, secondAdPosition];
};

export const getInGridAdPositionsOptimised = (screenWidth: number, ingridAdsLength: number) => {
  if (screenWidth < 960 && screenWidth >= 720) {
    return [2, 5];
  }

  if (screenWidth < 1400 && screenWidth >= 960) {
    return [2, ingridAdsLength > 1 ? 7 : 10];
  }

  return [3, ingridAdsLength > 1 ? 9 : 11];
};
