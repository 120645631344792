import { combineReducers } from "redux";
import { DataState } from "../../common/dataState";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import { MealDealActionType, mealDealState } from "./MealDealBuilder.types";

const dataState = createDataStateReducer({
  [MealDealActionType.PRODUCTS_REQUEST]: DataState.PENDING,
  [MealDealActionType.PRODUCTS_SUCCESS]: DataState.SUCCESS,
  [MealDealActionType.PRODUCTS_FAILURE]: DataState.FAILED,
});

export const mealDealBuilderReducer = combineReducers({
  dataState,
});

export const initialState: mealDealState = {
  dataState: dataState(),
};
