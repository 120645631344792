import { Action, Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { ProductActionType, ProductClickedAction, PromotionClickedAction } from "./productTile.types";
import {
  createExternalNavigationAction,
  createInternalNavigationAction,
} from "../../common/middleware/navigationMiddleware";
import { urls } from "../../routes";

// This gives time to record analytics before navigating away from the site.
// It is not required for internal routing within the react app.
const TIME_TO_WAIT_FOR_ANALYTICS = 500;

const pdpFullUrlPrefix = "/shop/gb/groceries/product/details";
const pdpGolUiUrlPrefix = "/gol-ui/product";
const pdpFallbackUrlPrefix = "/shop/ProductDisplay";

export const productTileMiddleware =
  (api: MiddlewareAPI<Dispatch, State>) =>
  (next: Dispatch<Action>) =>
  (action: ProductClickedAction | PromotionClickedAction) => {
    switch (action.type) {
      case ProductActionType.PRODUCT_CLICKED: {
        const { product } = action;
        window.history.replaceState({ productUid: product.productUid }, "");
        if (product && product.fullUrl) {
          const pdpUrl = mapFullUrlToPDPUrl(product.fullUrl);
          api.dispatch(createInternalNavigationAction(pdpUrl));
        }
        break;
      }
      case ProductActionType.PROMOTION_CLICKED: {
        const { promotion } = action;
        if (promotion.url) {
          setTimeout(() => {
            api.dispatch(createExternalNavigationAction(promotion.url));
          }, TIME_TO_WAIT_FOR_ANALYTICS);
        }
        break;
      }
      default:
        break;
    }
    next(action);
  };

// Services returns an absolute product url (including scheme and domain). React router expects a relative url
// for internal routing so we need to map the url coming from services for PDP.
// We currently support loading PDP by either Seo url or a fallback url if the product hasn't been SEO'd
export function mapFullUrlToPDPUrl(fullUrl: string): string {
  const url = new URL(fullUrl);
  const isSeoUrl = url.pathname.startsWith(pdpFullUrlPrefix);
  if (isSeoUrl) {
    const path = url.pathname.replace(pdpFullUrlPrefix, urls.PRODUCT_DETAILS);
    return `${path}${url.search}`;
  }

  const isGolUiUrl = url.pathname.toLowerCase().startsWith(pdpGolUiUrlPrefix);
  if (isGolUiUrl) {
    return `${url.pathname}${url.search}`;
  }

  const isFallbackUrl = url.pathname.startsWith(pdpFallbackUrlPrefix);
  if (isFallbackUrl) {
    return `${urls.PRODUCT_DETAILS}/ProductDisplay${url.search}`;
  }

  throw new Error(`PDP url mapper failure: ${fullUrl}`);
}
