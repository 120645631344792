export enum SlotReservationType {
  NONE = "none",
  DELIVERY = "delivery",
  COLLECTION = "click_and_collect",
}

export interface SlotReservationResponse {
  reservation_type: SlotReservationType;
  is_expired: boolean;
  reserved_until: string;
  delivery_address?: DeliveryAddress;
  click_and_collect_location: CollectionAddress;
  slot: Slot;
  store_identifier: string;
  flexi_stores?: string[];
  is_alcohol_restricted_store: boolean;
  postcode: string;
  region: string;
}

export interface SlotDeliveryInformation {
  hd_minimum_spend: string;
  cnc_minimum_spend: string;
}

export interface DeliveryAddress {
  address_uid: string;
  nickname: string;
  is_primary: boolean;
  address_type: string;
  postcode: string;
  street: string;
  building_name: string;
  building_number: string;
  flat_number: string;
  town: string;
  county: string;
  country_code: string;
  delivery_instructions: string;
}

export interface CollectionAddress {
  postcode: string;
  name: string;
  location_uid?: string;
}

export interface Slot {
  start_time: string;
  end_time: string;
  price: number;
  qualified_price: number;
  is_green: boolean;
  is_same_day: boolean;
  is_delivery_pass_applicable: boolean;
  is_xmas: boolean;
  slot_type?: string;
}

export interface RecurringSlotResponse {
  eligible: boolean;
  registered: boolean;
}

export interface SlotLocationContext {
  delivery_address: DeliveryAddress | null;
  click_and_collect_location: ClickAndCollectLocation | null;
  postcode: string;
}

interface ClickAndCollectLocation {
  location_uid?: string;
  name: string;
  postcode: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
}

export interface SlotType {
  description: string;
  title: string;
  type: string;
}

export interface SlotList {
  slots: SlotItem[];
  weeks: Week[];
  slot_types: SlotType[];
  address_nickname: string;
  postcode: string;
  store_identifier: string;
}

export interface Week {
  number: number;
  start_date: string;
  end_date: string;
  is_xmas_week: boolean;
}

export interface SlotItem {
  slot_type: string;
  start_time: string;
  end_time: string;
  price: number;
  is_green: boolean;
  is_alcohol_restricted: boolean;
  is_available: boolean;
  is_booked_by_customer: boolean;
  booking_key: string;
  is_same_day: boolean;
  is_delivery_pass_applicable: boolean;
  flexi_stores?: string[];
  is_xmas: boolean;
  promise_restriction?: PromiseRestriction;
}

export interface PromiseRestriction {
  is_restricted: boolean;
  product_uids: string[];
  status?: string;
}

export enum SlotStatus {
  SUCCESS = "success",
  CHANGES_REQUIRED = "changes_required",
}

interface LostPromotion {
  product_uid: string;
}

export interface UnavailableProduct {
  product_uid: string;
  alternative_product_uid: string;
  item_uid: string;
}

export interface ReserveSlot {
  status: SlotStatus;
  reservation: SlotReservationResponse;
  lost_promotions: LostPromotion[];
  unavailable_products: UnavailableProduct[];
}

export interface ReserveSlotWithBookingKey {
  reserve_slot: ReserveSlot;
  booking_key: string;
}

export interface SlotMessaging {
  slot_price_message: string;
}

export interface DeliveryPassDays {
  days: string[];
}
