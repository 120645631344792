import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
  PreloadedState,
  Store,
  StoreEnhancer,
} from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware, RouterState } from "connected-react-router";
import { menuItemsReducer } from "../../components/Header/menuItemsReducer";
import { userReducer } from "../../components/Header/userReducer";
import { User } from "../../domain/customer/customer";
import { MenuItem, WCSBreadcrumbs } from "../../components/Header/Header.types";
import { analyticsMiddleware } from "../middleware/analyticsMiddleware/analyticsMiddleware";
import { navigationMiddleware } from "../middleware/navigationMiddleware";
import { userJourney } from "../middleware/userJourney";
import { progressiveRegistrationMiddleware } from "../../views/ProgressiveRegistration/middleware";
import { recommendationsMiddleware } from "../recommendations/middleware";
import { locationReducer, LocationState } from "../location/locationReducer";
import { History } from "history";
import { loginInitialState, loginReducer } from "../login/login.reducer";
import { basketInitialState, basketReducer } from "../../domain/basket/basket.reducers";
import { modalInitialState, modalReducer } from "../../components/Modal/modal.reducers";
import { favouritesMiddleware } from "@favourites/middleware/Favourites.middleware";
import { formsReducer } from "../forms/formsReducer";
import { checkPostcodeInitialState, checkPostcodeReducer } from "../../views/CheckPostcode/checkPostcode.reducer";
import { errorReducer } from "../../components/Error/error.reducer";
import { espotReducer, initialState as espotInitialState } from "../../components/Espots/espot.reducer";
import { favouritesReducer, initialState as favouritesInitialState } from "@favourites/state/Favourites.reducer";
import { appReducer, AppState } from "../appReducer";
import {
  ProgressiveRegistrationFormFieldErrorType,
  ProgressiveRegistrationFormPristineType,
  ProgressiveRegistrationFormValueType,
  ProgressiveRegistrationPending,
} from "../../views/ProgressiveRegistration/types";
import { recommendationsInitialState, recommendationsReducer } from "../recommendations/recommendations.reducer";
import { ModalState } from "../../components/Modal/Modal.types";
import { DataState, WithDataState } from "../dataState";
import {
  CheckPostcodeFormFieldErrorType,
  CheckPostcodeFormPristineType,
  CheckPostcodeFormValueType,
} from "../../views/CheckPostcode/checkPostcode.types";
import { checkPostcodeMiddleware } from "../../views/CheckPostcode/checkPostcode.middleware";
import { RecommendationsState } from "../recommendations/recommendations.types";
import { productTileMiddleware } from "../../components/ProductTile/productTile.middleware";
import { FavouritesState } from "@favourites/Favourites.types";
import { productsReducer } from "../../domain/product/product.reducer";
import { previousOrdersMiddleware } from "../../views/PreviousOrders/previousOrders.middleware";
import { ordersReducer } from "../../domain/order/order.reducer";
import { searchMiddleware } from "../../components/Header/SearchBar/search.middleware";
import { initialState as searchInitialState, searchReducer } from "../../views/SearchResults/search.reducer";
import { SearchStore } from "../../views/SearchResults/search.types";
import { ProductListerState } from "../../views/ProductLister/ProductLister.types";
import {
  initialState as productListerInitialState,
  productListerReducer,
} from "../../views/ProductLister/ProductLister.reducer";
import { previousOrdersInitialState, previousOrdersReducer } from "../../views/PreviousOrders/previousOrders.reducer";
import { PreviousOrdersState } from "../../views/PreviousOrders/previousOrders.types";
import { deferredConditionalActionMiddleware } from "../deferredConditionalAction/deferredConditionalAction.middleware";
import { basketMiddleware } from "../../domain/basket/basket.middleware";
import { Mission } from "../../domain/mission/mission.types";
import { slotReservationMiddleware } from "../../domain/slot/slotReservation.middleware";
import { ProductDetailsState } from "../../views/ProductDetails/productDetails.types";
import { productDetailsInitialState, productDetailsReducer } from "../../views/ProductDetails/productDetails.reducer";
import { productDetailsMiddleware } from "../../views/ProductDetails/productDetails.middleware";
import { dietaryProfileReducer } from "../../components/DietaryProfile/dietaryProfile.reducer";
import { reviewsReducer } from "../../views/ProductDetails/Reviews/reviews.reducer";
import { ProductReview } from "../../domain/product/review";
import { InterleavedEspot } from "../../components/Espots/Espot.types";
import { recurringSlotMiddleware } from "../../views/RecurringSlot/RecurringSlot.middleware";
import { recurringSlotInitialState, recurringSlotReducer } from "../../views/RecurringSlot/RecurringSlot.reducer";
import { RecurringSlotState } from "../../views/RecurringSlot/RecurringSlot.types";
import { adsReducer, AdsStore } from "../ads";
import { Condition } from "../../components/DietaryProfile/DietaryModal/DietaryModal.types";
import { searchResultsMiddleware } from "../../views/SearchResults/search.middleware";

import { ReserveSlotState } from "../../views/HomeDelivery/slotReservation.types";
import { locationMiddleware } from "../middleware/locationMiddleware";
import { getGlobal } from "../../global";
import { redirectsInitialState, redirectsReducer, RedirectsState } from "../redirects/redirects.reducer";
import {
  dietaryWarningsInitialState,
  dietaryWarningsReducer,
} from "../../components/DietaryProfile/DietaryWarning/dietaryWarning.reducer";
import { nectarInitialState, nectarReducer } from "../../domain/nectar/nectar.reducer";
import { NectarState } from "../../domain/nectar/nectar.types";
import { nectarMiddleware } from "../../domain/nectar/nectar.middleware";
import {
  nectarDestinationPageReducer,
  NectarDestinationPageState,
} from "../../views/NectarDestinationPage/state/NectarDestinationPage.reducer";
import { RecipeState } from "../../views/Recipes/Recipe.types";
import { recipeDetailsReducer, initialState as recipeInitialState } from "../../views/Recipes/Recipe.reducer";
import { ProductsState } from "../../domain/product/product.types";
import { OrdersState } from "../../domain/order/order.types";
import { BasketState } from "../../domain/basket/basket.types";
import { SlotReservationState } from "../../domain/slot/slotReservation.types";
import { cncBookingConfirmationMiddleware } from "../../views/ClickAndCollect/BookingConfirmation.middleware";
import { homeDeliveryBookingConfirmationMiddleware } from "../../views/BookingConfirmation/BookingConfirmation.middleware";
import { slotReservationInitialState, slotReservationReducer } from "../../domain/slot/slotReservation.reducer";
import {
  bookReserveSlotInitialState,
  bookSlotReservationReducer,
} from "../../views/HomeDelivery/slotReservation.reducer";
import { RecipeHierarchy } from "../../components/RecipesBrowse/RecipesBrowse.types";
import recipesBrowseReducer, { recipesBrowseInitialState } from "../../components/RecipesBrowse/recipesBrowseReducer";
import { recipeSearchResultsReducer } from "../../views/RecipesSearchResults/RecipeSearch.reducer";
import { RecipeSearchState } from "../../views/RecipesSearchResults/RecipesSearchResults.types";
import { meganavReducer } from "../../components/Header/meganav.reducer";
import { browseAdInitialState, browseAdReducer } from "../../views/Browse/BrowseMagnoliaContent/BrowseAd.reducer";
import {
  SynapticaBreadcrumbs,
  BrowseTaxonomyState,
  ParsedBrowseTaxonomyState,
  BrowseEventsState,
} from "../../views/Browse/Browse.types";
import {
  browseEventsInitialState,
  browseEventsReducer,
  parsedTaxonomyReducer,
  synapticaBreadcrumbsReducer,
  taxonomyReducer,
} from "../../views/Browse/Browse.reducer";
import {
  detectBotReducer,
  initialState as botDetectInitialState,
} from "../../components/Citrus/GoogleBotDetect/CitrusBotDetect.reducer";
import { BotDetectState } from "../../components/Citrus/GoogleBotDetect/CitrusBotDetect.types";
import { BrowseIngridAdState } from "../../views/Browse/BrowseMagnoliaContent/BrowseMagnolia.type";
import { initialState as headerInitialState, headerReducer } from "../../components/Header/headerState/header.reducer";
import { mealDealState, mealDealInitialState } from "../../views/MealDealBuilder/MealDealBuilder.types";
import { mealDealBuilderReducer } from "../../views/MealDealBuilder/MealDealBuilder.reducer";
import { featureTourMiddleware } from "@favourites/middleware/featureTour.middleware";
import {
  brandedPageReducer,
  initialState as brandedPageInitialState,
} from "../../views/BrandedPage/BrandedPage.reducer";
import { BrandedPageState } from "../../views/BrandedPage/BrandedPage.types";
import { EventsFeaturesState, eventsFeaturesInitialState } from "../../views/EventsFeatures/EventsFeatures.types";
import { eventsFeaturesReducer } from "../../views/EventsFeatures/EventsFeatures.reducer";
import { MealPlannerState } from "../../views/RecipeMealPlanner/MealPlanner.types";
import {
  initialState as mealPlannerInitialState,
  mealPlannerReducer,
} from "../../views/RecipeMealPlanner/MealPlanner.reducer";
import { NorthforkState } from "../../components/RecipesAddToBasketModal/ATBModal.type";
import {
  northforkReducer,
  initialState as northforkInitialState,
} from "../../components/RecipesAddToBasketModal/ATBModal.reducer";
import { SearchIngridAdState } from "../../views/SearchResultsFindability/SearchResultFindabilityAds/SearchAd.type";
import {
  searchAdInitialState,
  searchAdReducer,
} from "../../views/SearchResultsFindability/SearchResultFindabilityAds/SearchAd.reducer";

import {
  initialState as yourRecipesFavouritesInitialState,
  recipeFavouritesReducer,
} from "../../views/RecipesFavourites/YourRecipesFavourites.reducer";
import { YourRecipesFavouritesState } from "../../views/RecipesFavourites/YourRecipesFavourites.types";
import { ynpInitialState, ynpReducer } from "../../domain/ynp/ynp.reducer";
import { ynpMiddleware } from "../../domain/ynp/ynp.middleware";
import { YnpState } from "../../domain/ynp/ynp.types";

export interface HeaderState {
  visible: boolean;
  showMinimizedHeader: boolean;
  selectedMeganavCategory: MenuItem | null;
  isMegaNavOpen: boolean;
}

export interface UserState extends WithDataState {
  userDetails?: User;
}

export interface DietaryProfileState {
  optimisticState?: boolean;
  dietaryProfileId?: string;
  dietaryProfileConditions?: Condition[];
  dietaryProfileError: boolean;
  isLoading: boolean;
}

export interface DietaryWarningsState extends WithDataState {
  intolerantEANs: { [key: string]: boolean };
}

export interface ReviewsState extends WithDataState {
  totalReviews: number;
  reviews: ProductReview[];
  reportReviewsDataState: DataState;
}

export type CategoriesState = MenuItem[];

export type WCSBreadcrumbState = WCSBreadcrumbs;

export interface RecipesBrowseState extends WithDataState {
  hierarchy: RecipeHierarchy;
}

export interface LoginState {
  verifiedEmail: string;
  progressiveRegistrationPending: ProgressiveRegistrationPending;
  missions: Mission[];
}

export interface CheckPostcodeState {
  dataState: DataState;
  verifiedPostcode?: string;
}

export type FormsState = {
  progressiveRegistration: {
    values: ProgressiveRegistrationFormValueType;
    pristine: ProgressiveRegistrationFormPristineType;
    fieldErrors: ProgressiveRegistrationFormFieldErrorType;
    fieldWarnings: ProgressiveRegistrationFormFieldErrorType;
    formErrors: string[];
  };
  checkPostcode: {
    values: CheckPostcodeFormValueType;
    pristine: CheckPostcodeFormPristineType;
    fieldErrors: CheckPostcodeFormFieldErrorType;
    fieldWarnings: CheckPostcodeFormFieldErrorType;
    formErrors: string[];
  };
};

export interface ErrorState {
  errCode: number | null;
  errTitle: string | null;
  errDetail: string | null;
}

export interface EspotState {
  espots: InterleavedEspot[];
}

export interface State {
  wcsBreadcrumbs: WCSBreadcrumbState;
  synapticaBreadcrumbs: SynapticaBreadcrumbs;
  browseTaxonomy: BrowseTaxonomyState;
  parsedBrowseTaxonomy: ParsedBrowseTaxonomyState;
  categories: CategoriesState;
  recipesBrowse: RecipesBrowseState;
  products: ProductsState;
  productDetails: ProductDetailsState;
  orders: OrdersState;
  recommendations: RecommendationsState;
  nectarDestinationPage: NectarDestinationPageState;
  user: UserState;
  basket: BasketState;
  location: LocationState;
  login: LoginState;
  modal: ModalState;
  forms: FormsState;
  router: RouterState;
  error: ErrorState;
  espot: EspotState;
  favourites: FavouritesState;
  previousOrders: PreviousOrdersState;
  search: SearchStore;
  app: AppState;
  checkPostcode: CheckPostcodeState;
  slotReservation: SlotReservationState;
  recurringSlot: RecurringSlotState;
  dietaryProfile: DietaryProfileState;
  dietaryWarnings: DietaryWarningsState;
  reviews: ReviewsState;
  ads: AdsStore;
  bookSlotReservation: ReserveSlotState;
  redirects: RedirectsState;
  productLister: ProductListerState;
  nectar: NectarState;
  recipe: RecipeState;
  northfork: NorthforkState;
  recipeSearch: RecipeSearchState;
  browseAd: BrowseIngridAdState;
  botDetectStatus: BotDetectState;
  header: HeaderState;
  mealDealProducts: mealDealState;
  browseEvents: BrowseEventsState;
  brandedPageData: BrandedPageState;
  eventsFeatures: EventsFeaturesState;
  mealPlanner: MealPlannerState;
  searchAd: SearchIngridAdState;
  yourRecipesFavourites: YourRecipesFavouritesState;
  yourNectarPricesOptIn: YnpState;
}

const location = getGlobal("location");
const document = getGlobal("document");
const recaptchaSiteKey = getGlobal("recaptchaSiteKey");
const devExtensionCompose = getGlobal("__REDUX_DEVTOOLS_EXTENSION_COMPOSE__");

export const getInitialState = (): Partial<State> => ({
  categories: [],
  wcsBreadcrumbs: {},
  synapticaBreadcrumbs: {},
  browseTaxonomy: [],
  parsedBrowseTaxonomy: {},
  recipesBrowse: recipesBrowseInitialState,
  recommendations: recommendationsInitialState,
  user: {
    dataState: DataState.UNKNOWN,
  },
  basket: basketInitialState,
  favourites: favouritesInitialState,
  previousOrders: previousOrdersInitialState,
  search: searchInitialState,
  productDetails: productDetailsInitialState,
  location: {
    current: location ? location.href : "",
    referrer: document ? document.referrer : "",
  },
  modal: modalInitialState,
  app: {
    recaptchaSiteKey: recaptchaSiteKey ? recaptchaSiteKey : undefined,
  },
  login: loginInitialState,
  error: {
    errCode: null,
    errTitle: null,
    errDetail: null,
  },
  espot: espotInitialState,
  checkPostcode: checkPostcodeInitialState,
  slotReservation: slotReservationInitialState,
  recurringSlot: recurringSlotInitialState,
  dietaryWarnings: dietaryWarningsInitialState,
  bookSlotReservation: bookReserveSlotInitialState,
  redirects: redirectsInitialState,
  productLister: productListerInitialState,
  nectar: nectarInitialState,
  recipe: recipeInitialState,
  browseAd: browseAdInitialState,
  botDetectStatus: botDetectInitialState,
  header: headerInitialState,
  mealDealProducts: mealDealInitialState,
  browseEvents: browseEventsInitialState,
  brandedPageData: brandedPageInitialState,
  eventsFeatures: eventsFeaturesInitialState,
  mealPlanner: mealPlannerInitialState,
  yourRecipesFavourites: yourRecipesFavouritesInitialState,
  northfork: northforkInitialState,
  searchAd: searchAdInitialState,
  yourNectarPricesOptIn: ynpInitialState,
});

export const configureStore = (browserHistory?: History, state: State = getInitialState() as State): Store<State> => {
  const reducers = {
    categories: menuItemsReducer,
    parsedBrowseTaxonomy: parsedTaxonomyReducer,
    browseTaxonomy: taxonomyReducer,
    wcsBreadcrumbs: meganavReducer,
    synapticaBreadcrumbs: synapticaBreadcrumbsReducer,
    browseEvents: browseEventsReducer,
    recipesBrowse: recipesBrowseReducer,
    products: productsReducer,
    productDetails: productDetailsReducer,
    orders: ordersReducer,
    recommendations: recommendationsReducer,
    user: userReducer,
    basket: basketReducer,
    location: locationReducer,
    modal: modalReducer,
    login: loginReducer,
    forms: formsReducer,
    error: errorReducer,
    espot: espotReducer,
    favourites: favouritesReducer,
    previousOrders: previousOrdersReducer,
    search: searchReducer,
    app: appReducer,
    checkPostcode: checkPostcodeReducer,
    recurringSlot: recurringSlotReducer,
    slotReservation: slotReservationReducer,
    dietaryProfile: dietaryProfileReducer,
    dietaryWarnings: dietaryWarningsReducer,
    reviews: reviewsReducer,
    ads: adsReducer,
    bookSlotReservation: bookSlotReservationReducer,
    redirects: redirectsReducer,
    productLister: productListerReducer,
    nectar: nectarReducer,
    nectarDestinationPage: nectarDestinationPageReducer,
    recipe: recipeDetailsReducer,
    recipeSearch: recipeSearchResultsReducer,
    browseAd: browseAdReducer,
    botDetectStatus: detectBotReducer,
    header: headerReducer,
    mealDealProducts: mealDealBuilderReducer,
    brandedPageData: brandedPageReducer,
    eventsFeatures: eventsFeaturesReducer,
    mealPlanner: mealPlannerReducer,
    yourRecipesFavourites: recipeFavouritesReducer,
    northfork: northforkReducer,
    searchAd: searchAdReducer,
    yourNectarPricesOptIn: ynpReducer,
  } as LooseObject;

  if (browserHistory) {
    reducers.router = connectRouter(browserHistory);
  }

  const navigationMiddlewares = [navigationMiddleware as Middleware];

  if (browserHistory) {
    navigationMiddlewares.push(routerMiddleware(browserHistory));
  }

  const middlewares = [
    thunk,
    ...navigationMiddlewares,
    userJourney as Middleware,
    progressiveRegistrationMiddleware as Middleware,
    analyticsMiddleware as Middleware,
    locationMiddleware as Middleware,
    favouritesMiddleware as Middleware,
    recommendationsMiddleware as Middleware,
    checkPostcodeMiddleware as Middleware,
    productTileMiddleware as Middleware,
    previousOrdersMiddleware as Middleware,
    searchMiddleware as Middleware,
    productDetailsMiddleware as Middleware,
    deferredConditionalActionMiddleware as Middleware,
    basketMiddleware as Middleware,
    slotReservationMiddleware as Middleware,
    recurringSlotMiddleware as Middleware,
    searchResultsMiddleware as Middleware,
    nectarMiddleware as Middleware,
    cncBookingConfirmationMiddleware as Middleware,
    homeDeliveryBookingConfirmationMiddleware as Middleware,
    featureTourMiddleware as Middleware,
    ynpMiddleware as Middleware,
  ];

  let composeEnhancers = compose;

  if (process.env.NODE_ENV === "development") {
    composeEnhancers = devExtensionCompose || compose;
  }

  return createStore(
    combineReducers(reducers),
    state as PreloadedState<State>,
    composeEnhancers(applyMiddleware(...middlewares)) as StoreEnhancer<any>
  );
};
