import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { HeaderProps, MegaNavData, MenuItem } from "./Header.types";
import { ContentWrap } from "../ContentWrap";
import { Logo } from "./Logo";
import { AuthorizedContainer } from "../Authorized";
import { MiniTrolleyContainer } from "./MiniTrolley/MiniTrolley.container";
import { SearchBarContainer } from "./SearchBar/SearchBar.container";
import { Desktop, Mobile, NonMobile, Tablet } from "../ResponsiveProvider";
import { DesktopMenuWithAuthorizedState } from "./DesktopMenu/DesktopMenu";
import { LoginRegisterLink, MultiSearchLink, TopLeftLinks, TopRightLinks } from "./links";
import { DEFAULT_MENU_ITEM_TITLE, getDefaultMenuItems } from "./menuItemsSelectors";
import { CategoriesState, State } from "../../common/store";
import { featureFlags } from "../../common/firebase";
import { DataState } from "../../common/dataState";
import { RecipeHierarchy } from "../RecipesBrowse/RecipesBrowse.types";
import { useHeaderState, useMegaNavState } from "./headerState/useHeaderState";
import { selectParsedTaxonomy } from "../../views/Browse/Browse.selectors";
import { useMegaNavActions } from "./headerState/header.actions";
import { useMenuPromotionalItem } from "./useMenuPromotionalItem";
import { fetchMegaNav } from "../../services/product";
import OccasionsMenu from "./OccasionsMenu/OccasionsMenu";
import { useOccasions, useOccasionsHierarchy } from "./OccasionsMenu/useOccasions";
import { useMeganavCMSDataFetch, useMegaNavItems } from "./MegaNav/MegaNav.hooks";
import { MobileMegaNav } from "./MegaNav/Mobile/MobileMegaNav";
import { DesktopMegaNav } from "./MegaNav/Desktop/DesktopMegaNav";
import { EXPERIMENTS, trackExperiment } from "../../common/analytics";
import { BookDeliveryContainer } from "./BookDelivery/BookDelivery.container";
import { useFeatureFlag } from "../../common/firebase/featureFlags.hooks";
import { headerExperimentVariantEvent } from "../../common/dataLayer/headerEvent";
import clsx from "clsx";
import { getZoneCategoryIds } from "../../views/Browse/BrowseZone/BrowseZone.config";
import { getBrowseNodeInfo } from "../../views/Browse/Browse.hooks";
import { useTrackCMSComponentAnalytics } from "../../common/hooks/useTrackCMSComponentAnalytics";
import { useRestingSearch } from "./SearchBar/hooks/useRestingSearch.hook";
import { MinimizedHeader } from "./MinimizedHeader";

const mapCategoriesStateToMenu = (items: MenuItem[], categories: CategoriesState): MenuItem[] => {
  const groceries = { index: -1 };
  items.forEach((item, i) => {
    if (item.title === DEFAULT_MENU_ITEM_TITLE.GROCERIES) {
      groceries.index = i;
    }
  });

  return [
    ...items.slice(0, groceries.index),
    { ...items[groceries.index], items: categories },
    ...items.slice(groceries.index + 1),
  ];
};

export function removeHierarchyFromRecipes(items: MenuItem[]): MenuItem[] {
  const recipe = { index: -1 };
  items.forEach((item, i) => {
    if (item.title === DEFAULT_MENU_ITEM_TITLE.RECIPES) {
      recipe.index = i;
    }
  });

  if (recipe.index === -1) {
    return items;
  }

  return [...items.slice(0, recipe.index), { ...items[recipe.index], items: [] }, ...items.slice(recipe.index + 1)];
}

const mapRecipesHierarchy = (items: MenuItem[], data: RecipeHierarchy): MenuItem[] => {
  const recipe = { index: -1 };

  items.forEach((item, i) => {
    if (item.title === DEFAULT_MENU_ITEM_TITLE.RECIPES) {
      recipe.index = i;
    }
  });

  if (recipe.index === -1) {
    return items;
  }

  const hierarchy: MenuItem[] =
    data === null
      ? []
      : data?.map<MenuItem>(i => {
          return {
            title: i.title,
            id: i.id,
            url: i.slug ? i.slug : i.id,
            items: i.items.map<MenuItem>(c => {
              return {
                title: c.title,
                id: c.id,
                url: c.slug,
              };
            }),
          };
        });

  return [
    ...items.slice(0, recipe.index),
    { ...items[recipe.index], items: hierarchy },
    ...items.slice(recipe.index + 1),
  ];
};

export const HeaderWrapper = () => {
  React.useEffect(() => {
    trackExperiment(EXPERIMENTS.Global_Header_Test_Group_1, "1");
  }, []);

  const categories = useSelector((state: State) => state.categories);
  const user = useSelector((state: State) => state.user);
  const recipesBrowseHierarchy = useSelector((state: State) => state.recipesBrowse.hierarchy);

  const { promotionalLink } = useMenuPromotionalItem();

  const { mapOccasionsHierarchy } = useOccasionsHierarchy();
  const { isOccasionsEnabled } = useOccasions();

  const withCategories = (state: CategoriesState) => mapCategoriesStateToMenu(getDefaultMenuItems(), state);

  const isFoodToOrderEnabled = featureFlags.get("fto_header_flag");
  const isLoggedIn = user.dataState === DataState.SUCCESS && (user.userDetails ? user.userDetails.isRegistered : false);

  const withRecipesHierarchy = mapRecipesHierarchy(withCategories(categories), recipesBrowseHierarchy);
  const navItems = isOccasionsEnabled ? mapOccasionsHierarchy(withRecipesHierarchy) : withRecipesHierarchy;

  const [megaNavData, setMegaNavData] = useState<MegaNavData | null>(null);

  useEffect(() => {
    const loadMegaNavData = async () => {
      try {
        const result = await fetchMegaNav();
        if (result.isSuccess()) {
          setMegaNavData(result.data);
        } else {
          console.error("Failed to fetch MegaNav data:", result.errors);
          setMegaNavData(null);
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
        setMegaNavData(null);
      }
    };

    loadMegaNavData();
  }, []);

  const props = {
    navItems,
    promotionalLink,
    isFoodToOrderEnabled,
    isLoggedIn,
    megaNavData,
  };

  return <Header {...props} />;
};

function getCurrentPage(pathname: string) {
  if (pathname.includes("/gol-ui/recipes")) {
    return "recipes";
  }
  if (pathname.includes("/gol-ui/groceries")) {
    return "groceries";
  }
  return "other";
}

export const Header = React.memo(
  ({ navItems, promotionalLink, isFoodToOrderEnabled, isLoggedIn, megaNavData }: HeaderProps): React.ReactElement => {
    const { showMinimised } = useHeaderState();
    const { isMegaNavOpen, selectedMeganavCategory } = useMegaNavState();
    const location = useLocation();
    const currentPage = getCurrentPage(location.pathname);

    const isAlignSearchBarWithLogoEnabled = useFeatureFlag("new_global_header");
    const isMinimiseHeaderEnabled = useFeatureFlag("new_global_header");

    const nodeMap = useSelector(selectParsedTaxonomy);
    const taxonomyId = selectedMeganavCategory ? selectedMeganavCategory.url.split("c:")[1] : null;

    const findabilityID = useMemo(() => {
      if (!taxonomyId) return undefined;
      const zoneCategory = getZoneCategoryIds(taxonomyId);
      return zoneCategory ?? getBrowseNodeInfo(taxonomyId, nodeMap)?.findabilityID;
    }, [taxonomyId, nodeMap]);

    // Set contentId to 'Groceries-Home' if taxonomyId is null
    const contentId = taxonomyId ?? "Groceries-Home";

    const { seasonal, ads } = useMeganavCMSDataFetch(contentId, selectedMeganavCategory ? findabilityID : undefined);

    useEffect(() => {
      if (isMinimiseHeaderEnabled) {
        document.documentElement.style.setProperty("--page-layout-small-offset", "80px");
      } else {
        document.documentElement.style.setProperty("--page-layout-small-offset", "24px");
      }
    }, [isMinimiseHeaderEnabled]);

    useEffect(() => {
      const updateScrollPosition = () => {
        const header = document.querySelector("header");
        if (header) {
          const headerHeight = header.offsetHeight;
          const scrollY = window.scrollY;
          document.documentElement.style.setProperty("--mega-nav-top-position", `${headerHeight - scrollY}px`);
          document.documentElement.style.setProperty("--mega-nav-scroll-offset", `${scrollY}px`);
        }
      };

      updateScrollPosition();
      window.addEventListener("scroll", updateScrollPosition);
      window.addEventListener("resize", updateScrollPosition);

      return () => {
        window.removeEventListener("scroll", updateScrollPosition);
        window.removeEventListener("resize", updateScrollPosition);
      };
    }, []);

    useTrackCMSComponentAnalytics(ads.length > 0);

    const restingSearchPreset = useRestingSearch(false);

    const { isOccasionsEnabled, isOccasionsRoute } = useOccasions();
    const { showMegaNav, hideMegaNav } = useMegaNavActions();

    const megaNavItems = useMegaNavItems(navItems, megaNavData);
    const megaNavItemsWithoutRecipeHierarchy = useMemo(() => removeHierarchyFromRecipes(megaNavItems), [megaNavItems]);

    useEffect(() => {
      if (isMegaNavOpen) {
        (window as any).digitalData?.track?.("ev_meganavClick", {
          hit_type: "link",
          data_event_action: "click",
          data_event_category: "meganav",
          data_event_label: "Groceries",
        });
        document.body.classList.add("mega-nav-control");
      } else {
        document.body.classList.remove("mega-nav-control");
      }

      return () => {
        document.body.classList.remove("mega-nav-control");
      };
    }, [isMegaNavOpen]);

    useEffect(() => {
      headerExperimentVariantEvent(isAlignSearchBarWithLogoEnabled);
    }, [isAlignSearchBarWithLogoEnabled]);

    // We don't want to have a transition for pages that already have a sticky header at the top.
    const haveTransition = !(
      (
        window.location.href.includes("/gol-ui/SearchResults") || // Search page.
        window.location.href.includes("/gol-ui/recipes") || // Recipes page.
        window.location.href.match(/\/gol-ui\/groceries\/(.*)\/c:(\d+)/gi)
      ) // Any shelf pages.
    );

    // Use className here instead of template literal for better readability and fewer potential issues
    const minimizedHeaderClass = clsx({
      "header-minimized": true,
      "header-minimized-transition": haveTransition,
      visible: showMinimised,
    });

    const shouldShowMinimized = isAlignSearchBarWithLogoEnabled && showMinimised;

    return (
      <>
        <ContentWrap
          className={`header ${isAlignSearchBarWithLogoEnabled ? currentPage !== "groceries" && "header--tablet" : ""}`}
          data-testid="header-wrapper"
        >
          <Mobile>
            <div className="header-nav-mobile" data-testid="mobile-header-nav">
              <div className="header-nav-mobile-left">
                {Boolean(megaNavItems?.length) && (
                  <MobileMegaNav
                    ads={ads}
                    seasonal={seasonal}
                    open={showMegaNav}
                    isOpen={isMegaNavOpen}
                    onClose={hideMegaNav}
                    navItems={megaNavItems}
                    promotionalLink={promotionalLink}
                    isLoggedIn={isLoggedIn}
                  />
                )}
                <Logo />
              </div>
              <AuthorizedContainer fallback={null}>
                <MiniTrolleyContainer />
              </AuthorizedContainer>
            </div>

            <SearchBarContainer restingSearchPreset={restingSearchPreset} />
            <AuthorizedContainer
              fallback={
                <div className={`ds-mb-1.5 ${!isAlignSearchBarWithLogoEnabled ? "ds-mt-1" : ""}`}>
                  <LoginRegisterLink />
                </div>
              }
            >
              {isAlignSearchBarWithLogoEnabled && <BookDeliveryContainer />}
            </AuthorizedContainer>
          </Mobile>

          <Tablet>
            {(!isAlignSearchBarWithLogoEnabled || currentPage === "groceries") && (
              <div className="top-header">
                <TopLeftLinks />
                <TopRightLinks className="ds-ms-auto" />
              </div>
            )}
            <div className="header-nav-tablet logo-search-container">
              {Boolean(megaNavItems?.length) && !shouldShowMinimized && (
                <MobileMegaNav
                  ads={ads}
                  seasonal={seasonal}
                  open={showMegaNav}
                  isOpen={isMegaNavOpen && !shouldShowMinimized}
                  onClose={hideMegaNav}
                  navItems={megaNavItems}
                  promotionalLink={promotionalLink}
                  isLoggedIn={isLoggedIn}
                />
              )}
              <Logo />
              {!isAlignSearchBarWithLogoEnabled || currentPage === "groceries" ? (
                <MultiSearchLink customStyle={!isAlignSearchBarWithLogoEnabled ? "multi-search__legacy" : ""} />
              ) : (
                <TopRightLinks />
              )}
            </div>
            <div
              className={`header-nav ${
                isAlignSearchBarWithLogoEnabled && currentPage !== "groceries" && "header-nav--tablet"
              }`}
              data-testid="tablet-header-nav"
            >
              {isAlignSearchBarWithLogoEnabled ? (
                <div className="header-nav__container">
                  <SearchBarContainer restingSearchPreset={restingSearchPreset} />
                  {currentPage !== "groceries" && <MultiSearchLink />}
                </div>
              ) : (
                <SearchBarContainer restingSearchPreset={restingSearchPreset} />
              )}
            </div>
          </Tablet>

          <Desktop>
            <div className="top-header">
              <TopLeftLinks />
              <TopRightLinks />
            </div>
            {!isAlignSearchBarWithLogoEnabled && (
              <div className="logo-search-container">
                <Logo />
                <MultiSearchLink customStyle="multi-search__legacy" />
              </div>
            )}
            <div className="header-nav" data-testid="desktop-header-nav">
              {isAlignSearchBarWithLogoEnabled ? (
                <div className="header-nav__container">
                  <Logo />
                  <SearchBarContainer restingSearchPreset={restingSearchPreset} />
                  <MultiSearchLink />
                </div>
              ) : (
                <SearchBarContainer restingSearchPreset={restingSearchPreset} />
              )}
              <DesktopMenuWithAuthorizedState
                onGroceriesActive={showMegaNav}
                onGroceriesNoLongerActive={hideMegaNav}
                isNavOpen={isMegaNavOpen && !shouldShowMinimized}
              />
            </div>
            {isMinimiseHeaderEnabled && (
              <div className={minimizedHeaderClass} data-testid="header-minimized">
                <MinimizedHeader
                  ads={ads}
                  seasonal={seasonal}
                  isMegaNavOpen={isMegaNavOpen}
                  isLoggedIn={isLoggedIn}
                  megaNavItems={megaNavItems}
                  restingSearchPreset={restingSearchPreset}
                  promotionalLink={promotionalLink}
                />
              </div>
            )}
          </Desktop>
        </ContentWrap>

        {isMegaNavOpen && megaNavData && !shouldShowMinimized && (
          <DesktopMegaNav
            ads={ads}
            seasonal={seasonal}
            open={showMegaNav}
            isOpen={isMegaNavOpen && !shouldShowMinimized}
            onClose={hideMegaNav}
            navItems={currentPage === "recipes" ? megaNavItems : megaNavItemsWithoutRecipeHierarchy}
          />
        )}

        {isOccasionsEnabled && isOccasionsRoute && (
          <NonMobile>
            <OccasionsMenu />
          </NonMobile>
        )}
      </>
    );
  }
);

export default HeaderWrapper;
