import React from "react";
import { CalendarBlank, InfoCircle, Time } from "@sainsburys-tech/icons";
import { BasketOrderPromise } from "../../domain/basket/basket.types";
import clsx from "clsx";
import { OrderPromiseStatusType } from "../../domain/product/index";

export type OccasionsInfoProps = {
  orderPromise?: BasketOrderPromise;
  serves?: string;
};

const OccasionsStatusIcon = ({ orderPromise }: { orderPromise: BasketOrderPromise }) => {
  switch (orderPromise.status.type) {
    case OrderPromiseStatusType.AVAILABLE_TO_ADD:
    case OrderPromiseStatusType.TIME_TO_CUTOFF: {
      return <CalendarBlank height={24} data-testid="occasions-calendar-icon" />;
    }
    case OrderPromiseStatusType.TIME_TO_CUTOFF_CLOSE: {
      return <InfoCircle height={24} data-testid="occasions-info-circle-icon" />;
    }
    case OrderPromiseStatusType.BEING_PREPARED: {
      return <Time height={24} data-testid="occasions-time-icon" />;
    }
    default: {
      return null;
    }
  }
};

export const OccasionsInfo = ({ orderPromise, serves }: OccasionsInfoProps) => {
  if (orderPromise == null) return null;

  return (
    <div className="trolley-item__occasions" tabIndex={0}>
      {serves && <span className="trolley-item__occasions-servings">Serves {serves}</span>}
      {orderPromise.status && (
        <div
          className={clsx("trolley-item__occasions__availability", {
            "trolley-item__occasions__availability-expiring":
              orderPromise.status.type === OrderPromiseStatusType.TIME_TO_CUTOFF_CLOSE,
          })}
        >
          <OccasionsStatusIcon orderPromise={orderPromise} />
          {orderPromise.status.label}
        </div>
      )}
    </div>
  );
};
