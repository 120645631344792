import { UnhandledApiErrorAction } from "../../components/Error/error.actions";
import { LocationChangeAction } from "connected-react-router";

export enum CheckPostcodeActionTypes {
  CHECK_POSTCODE_REQUEST = "CHECK_POSTCODE_REQUEST",
  CHECK_POSTCODE_FAILURE = "CHECK_POSTCODE_FAILURE",
  CHECK_POSTCODE_SUCCESS = "CHECK_POSTCODE_SUCCESS",
  CHECK_POSTCODE_REGISTRATION_SUCCESS = "CHECK_POSTCODE_REGISTRATION_SUCCESS",
}

export type CheckPostcodeRequestActionType = {
  type: CheckPostcodeActionTypes;
};

export type CheckPostcodeFailureActionType = {
  type: CheckPostcodeActionTypes;
  error: string;
};

export type CheckPostcodeSuccessActionType = {
  type: CheckPostcodeActionTypes.CHECK_POSTCODE_SUCCESS;
  postcode: string;
};

export type CheckPostcodeRegistrationSuccessActionType = {
  type: CheckPostcodeActionTypes.CHECK_POSTCODE_REGISTRATION_SUCCESS;
  postcode: string;
};

export type CheckPostcodeActions =
  | CheckPostcodeFailureActionType
  | CheckPostcodeSuccessActionType
  | CheckPostcodeRequestActionType
  | UnhandledApiErrorAction
  | LocationChangeAction;

export type ChangeHandler = (name: string) => (value: string) => void;

export interface CheckPostcodeFormValueType {
  postcode: string;
}

export type CheckPostcodeFormPristineType = {
  postcode: boolean;
};

export type CheckPostcodeFormFieldErrorType = {
  postcode: string;
};

export interface CheckPostcodeFormProps {
  values: CheckPostcodeFormValueType;
  pristine: CheckPostcodeFormPristineType;
  fieldErrors: CheckPostcodeFormFieldErrorType;
  isFormValid: boolean;
  onSubmit: () => any;
  createChangeHandler: ChangeHandler;
  pending: boolean;
}
