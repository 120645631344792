import { User } from "../../domain/customer/customer";
import { getEntryPoint, PageViewProperties } from "./analytics";
import { NectarOffers } from "../../domain/nectar/nectar.types";
import { isLoginUrl } from "./digitalData";
import { AdobeDigitalDataProperties, SHOPPING_MODES } from "./types";

export const GENERIC_USER_ID = "-1002";

export enum NECTAR_CUSTOMER_TYPES {
  LINKED_NECTAR = "linked-nectar",
  ANALOGUE_NECTAR = "analogue-nectar",
  NON_NECTAR = "non-nectar",
}

export enum USER_STATUS_TYPES {
  LOGGED_IN = "logged in",
  GUEST = "guest",
  RECOGNISED = "recognised",
}
const mapNectarCustomerType = (user: User) => {
  if (user.hasNectarAssociated) {
    if (user.hasNectarLinked) {
      return NECTAR_CUSTOMER_TYPES.LINKED_NECTAR;
    }

    return NECTAR_CUSTOMER_TYPES.ANALOGUE_NECTAR;
  }

  return NECTAR_CUSTOMER_TYPES.NON_NECTAR;
};

export const createDigitalData = (
  page: PageViewProperties,
  referrer: string,
  user: User,
  nectarOffers: NectarOffers,
  region: string,
  shoppingMode?: string,
  adobeProperties?: AdobeDigitalDataProperties
) => {
  const userStatus = user.id && user.isRegistered ? USER_STATUS_TYPES.LOGGED_IN : USER_STATUS_TYPES.GUEST;
  const entryPoint = getEntryPoint(referrer);
  const profileEvents = [];
  const nectarSavedOffers = nectarOffers.offers?.length;
  const nectarCustomerType = mapNectarCustomerType(user);
  // persists slot booked status across events/prevents unnecessary reset
  const hasSlotBooked = adobeProperties?.data_slot_booked ?? (window as any).digitalData?.data_slot_booked;

  if (isLoginUrl(referrer)) {
    profileEvents.push({ user_event: "login" });
  }

  return {
    experimentList: [],
    ...(window as any).digitalData,
    dataLayerVersion: 1, // v2
    siteVersion: "", // v2
    event: [],
    shoppingMode: shoppingMode || SHOPPING_MODES.NORMAL, // v2
    businessUnit: "groceries", // v2
    serverTimeStamp: null, // v2
    existingOrder: {
      item: page.existingOrder?.item,
      total: page.existingOrder?.total,
    },
    data_search_term: adobeProperties ? adobeProperties.data_search_term || "" : "",
    data_search_typedTerm: adobeProperties ? adobeProperties.data_search_typedTerm || "" : "",
    data_search_method: adobeProperties ? adobeProperties.data_search_method || "" : "",
    data_page_number: adobeProperties ? adobeProperties.data_page_number || "" : "",
    data_page_name: adobeProperties ? adobeProperties.data_page_name || "" : "",
    data_productList_categories: adobeProperties ? adobeProperties.data_productList_categories || "" : "",
    data_productList_filters: adobeProperties ? adobeProperties.data_productList_filters || "" : "",
    data_productList_numberOfProducts: adobeProperties ? adobeProperties.data_productList_numberOfProducts || "" : "",
    data_productList_numberOfProductsShown: adobeProperties
      ? adobeProperties.data_productList_numberOfProductsShown || ""
      : "",
    data_siteSection: adobeProperties ? adobeProperties.data_siteSection || "" : "",
    data_shoppingMode: adobeProperties ? adobeProperties.data_shoppingMode || "" : "",
    data_sortBy: adobeProperties ? adobeProperties.data_sortBy || "" : "",
    data_page_type: adobeProperties ? adobeProperties.data_page_type || "" : "",
    data_slot_price: adobeProperties?.data_slot_price,
    data_slot_type: adobeProperties ? adobeProperties.data_slot_type || "" : "",
    data_slot_minimumLeadTime: adobeProperties
      ? adobeProperties.data_slot_minimumLeadTime === 0
        ? 0
        : adobeProperties.data_slot_minimumLeadTime || ""
      : "",
    data_slot_booked: hasSlotBooked ?? false,
    data_user_loginStatus: adobeProperties ? adobeProperties.data_user_loginStatus || "" : "",
    data_user_customerId: adobeProperties ? adobeProperties.data_user_customerId || "" : "",
    data_customerMessage: adobeProperties ? adobeProperties.data_customerMessage ?? "" : "",
    data_xmas_guide_version: adobeProperties ? adobeProperties.data_xmas_guide_version || "" : "",
    page: {
      newPageTemplate: page.newTemplate, // v2
      siteSection: page.section, // v2
      rendering: "responsive", // v2
      entryPoint, // v2
      aisle: "", // v2
      shell: "", // v2
      rememberedEmailLoggin: null, // v2
      pageTemplate: page.template,
      promotionalTilesPositioning: page.promotionalTilesPositioning || "",
      offersDistribution: page.offersDistribution || "",
      reviewScore: page.reviewScore || "",
      reviewsNumber: page.reviewsNumber || "",
      productName: page.name || "",
      productPrice: page.productPrice || "",
      pageInfo: {
        pageName: page.name,
        newSite: "yes",
        shoppingMode: page.shoppingMode || SHOPPING_MODES.NORMAL,
        breadCrumbs: page.breadCrumbs,
        available: page.available || "",
        section: page.section,
        ...(entryPoint && { loginPageEntryPoint: entryPoint }),
        ...("productImageCount" in page && { productImageCount: page.productImageCount }),
        ...("productVideoCount" in page && { productVideoCount: page.productVideoCount }),
        ...("deliveryType" in page && { deliveryType: page.deliveryType }),
        ...("deliveryLocation" in page && { deliveryLocation: page.deliveryLocation }),
        ...("deliverySlotsViewed" in page && { deliverySlotsViewed: page.deliverySlotsViewed }),
        ...("storeId" in page && { storeId: page.storeId }),
        ...("orderDeliverySlot" in page && { orderDeliverySlot: page.orderDeliverySlot }),
        ...("bookingSlotPrice" in page && { bookingSlotPrice: page.bookingSlotPrice }),
        ...("slotType" in page && { slotType: page.slotType }),
        pageCategory: "",
        aisle: "",
        shelf: "",
        pageTemplate: page.template,
        newPageTemplate: page.newTemplate,
        lang: "en-gb",
        rendering: "web",
      },
      events: [],
      ...page,
    },
    user: {
      identityId: user.identityId || "", // v2
      customerId: user.customerNumber || "", // v2
      websphereId: user.id !== GENERIC_USER_ID ? user.websphereId || "" : "", // v2
      logginStatus: userStatus, // v2
      recContent: "", // v2
      profile: {
        profileInfo: {
          events: profileEvents,
          websphereId: user.id !== GENERIC_USER_ID ? user.websphereId || "" : "",
          user_id: user.customerNumber || "",
          userId: user.customerNumber || "",
          user_status: userStatus,
          identity_id: user.identityId || "",
          is_recurring_slot_registered: user.isRecurringSlotRegistered || false,
          identityId: user.identityId || "",
          nectar_linked: user.hasNectarLinked || false,
          nectar_saved_offers: nectarSavedOffers || 0,
          nectar_card_added: user.hasNectarAssociated || false,
          deliverySlot: page.deliverySlot,
          nectar_customer_type: nectarCustomerType,
          region,
        },
      },
    },
  };
};
