import React from "react";
import clsx from "clsx";
import { Button } from "@sainsburys-tech/fable";
import { ArrowRight } from "@sainsburys-tech/icons";
import { GOLLink } from "../../../GOLLink";
import { MegaNavItemProps } from "../MegaNav.types";
import { checkMegaNavUrl } from "../MegaNavShared";

export const MobileMegaNavItem: React.FC<MegaNavItemProps> = ({ item, onSelected, isGroceryItem }) => {
  if (item.items?.length) {
    return (
      <Button
        className={clsx("megaNavDrawer__childItem--parent", isGroceryItem && "megaNavDrawer__groceryItem")}
        onClick={() => onSelected(item)}
        data-testid="parent-item"
        variant="tertiary"
        aria-expanded="false"
      >
        <span>{item.title}</span>
        <ArrowRight className="megaNavDrawer__childItem-icon" aria-hidden="true" data-testid="arrow-right-icon" />
      </Button>
    );
  }

  const { url, extern } = checkMegaNavUrl(item.url);

  if (!url) {
    return <p className={clsx("megaNavDrawer__item", isGroceryItem && "megaNavDrawer__groceryItem")}>{item.title}</p>;
  }

  return (
    <GOLLink
      to={url}
      external={extern}
      className={clsx("megaNavDrawer__item", isGroceryItem && "megaNavDrawer__groceryItem")}
      onClick={onSelected}
    >
      {item.title}
    </GOLLink>
  );
};
