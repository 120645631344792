import React, { MutableRefObject, useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FilledButton, TextButton } from "@jsluna/react";
import { State } from "../../../common/store";
import { useSlotReservation } from "../../../domain/slot/slotReservation";
import { usePageView } from "../../../common/hooks/usePageViewEvent";
import { getChooseShoppingMethodUrl, matchesPath, urls } from "../../../routes";
import { MiniTrolleyContent } from "./MiniTrolleyContent";
import { MiniTrolleyButtonComponent as MiniTrolleyButton } from "./MiniTrolleyButton.component";
import {
  miniTrolleyBookASlotAnalyticsEvent,
  miniTrolleyCheckoutButtonAnalyticsEvent,
  miniTrolleyEmptyTrolleyAnalyticsEvent,
  sendAnalyticsEvent,
} from "../../../common/dataLayer/analyticsEvent";
import { MinimumSpendProps, MiniTrolleyProps } from "./MiniTrolley.types";
import ArrowUP from "../../../static/mini-trolley-arrow-up.svg";
import { filterNectarOffers } from "../../../domain/nectar/nectar.actions";
import { DataState } from "../../../common/dataState";
import { featureFlags } from "../../../common/firebase";

const UnderMinimumSpendBanner = ({ minimumSpend }: MinimumSpendProps) => (
  <button
    className="minimum-spend ln-u-text-align-center"
    aria-label={`Checkout button disabled. Minimum order is ${minimumSpend}`}
  >
    Minimum order value is {minimumSpend}
  </button>
);

export const MiniTrolleyComponent = ({
  isMobile,
  trolleyUrl,
  dispatchInternalNavigation,
  dispatchEmptyTrolley,
  count,
  items,
  subtotal,
  savings,
  minimumSpend,
  displayMinimumSpend,
  hasSlot,
  enableCheckout,
  isLoading,
  startCheckout,
  error,
  isAllItemsAmendable,
}: MiniTrolleyProps) => {
  const [showMiniTrolley, setShowMiniTrolley] = useState(false);
  const [showFormToEmptyMiniTrolleyPanel, setShowFormToEmptyMiniTrolleyPanel] = useState(false);
  const { slotReservation } = useSlotReservation();
  const nectarOffers = useSelector((state: State) => state.nectar.data.offers);
  const filteredNectarOffers = filterNectarOffers(nectarOffers, slotReservation.reservationStartTime);
  const isCheckoutBYGPage = matchesPath(location.pathname, urls.CHECKOUT_BEFORE_YOU_GO);
  const isCheckoutFFPage = matchesPath(location.pathname, urls.CHECKOUT_FORGOTTEN_FAVOURITES);
  const excludedUrls = [trolleyUrl, urls.CHECKOUT_SUMMARY, urls.CHANGES_TO_TROLLEY_GOLUI];
  const itemsCountCopy = count === 1 ? `(${count} item)` : `(${count} items)`;
  const user = useSelector((state: State) => state.user);
  const isLoggedIn = user.dataState === DataState.SUCCESS && Boolean(user.userDetails?.isRegistered);
  const { sendPageEvent } = usePageView("groceries", "trolley");
  const { sendPageEvent: sendPageEventForLightbox } = usePageView(
    "groceries",
    "trolley",
    undefined,
    true,
    "emptyTrolleyLightbox"
  );

  const timer: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  const startTimer = useCallback(() => {
    timer.current = setTimeout(() => {
      setShowMiniTrolley(false);
    }, 300);
  }, []);

  const handleMiniTrolley = (showMiniTrolley: boolean) => {
    stopTimer();
    if (showMiniTrolley) {
      setShowMiniTrolley(true);
    } else startTimer();
  };

  const handleOnMouseOver = () => {
    if (!isMobile && excludedUrls.every(url => !location.pathname.includes(url))) {
      setShowFormToEmptyMiniTrolleyPanel(false);
      handleMiniTrolley(true);
    }
  };

  const handleOnMouseOut = () => {
    handleMiniTrolley(false);
  };

  const handleClickFullTrolley = (_: Function) => {
    dispatchInternalNavigation(trolleyUrl);
    sendPageEvent();
    handleCloseMiniTrolley();
  };

  const handleClickStartShopping = () => {
    window.location.assign(urls.GROCERIES_URL);
    handleCloseMiniTrolley();
  };

  const handleClickCheckout = () => {
    startCheckout();
    handleCloseMiniTrolley();
    sendAnalyticsEvent(miniTrolleyCheckoutButtonAnalyticsEvent);
  };

  const handleClickBookSlot = () => {
    window.location.assign(getChooseShoppingMethodUrl());
    sendAnalyticsEvent(miniTrolleyBookASlotAnalyticsEvent);
  };

  const handleOnTouchStart = () => {
    if (!isMobile && !location.pathname.includes(urls.CHECKOUT_SUMMARY)) {
      handleMiniTrolley(!showMiniTrolley);
    } else {
      handleClickFullTrolley(sendPageEvent);
    }
  };

  const handleOnKeyUp = (e: React.KeyboardEvent) => {
    if (!isMobile && excludedUrls.every(url => !location.pathname.includes(url))) {
      if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
        e.preventDefault();
        handleMiniTrolley(!showMiniTrolley);
      }
    }
  };

  const handleCloseMiniTrolley = () => {
    handleMiniTrolley(false);
    setShowFormToEmptyMiniTrolleyPanel(false);
  };

  const handleDoEmptyMiniTrolley = () => {
    setShowFormToEmptyMiniTrolleyPanel(false);
    dispatchEmptyTrolley();
    sendAnalyticsEvent(miniTrolleyEmptyTrolleyAnalyticsEvent);
  };

  const handleGoBack = () => {
    setShowFormToEmptyMiniTrolleyPanel(false);
  };

  const handleEscape = (e: React.KeyboardEvent) => {
    if ((e.key === "Esc" || e.key === "Escape") && showMiniTrolley) {
      handleMiniTrolley(!showMiniTrolley);
      handleCloseMiniTrolley();
    }
  };

  const handleShowEmptyMiniTrolleyPanel = (_: Function) => {
    setShowFormToEmptyMiniTrolleyPanel(true);
    sendPageEventForLightbox(["empty trolley lightbox"]);
  };

  const isInMinimizedHeader = document.querySelector(".header-minimized.visible") !== null;
  const headerTrolleyClass = isInMinimizedHeader ? "header-trolley--minimized" : "";
  const miniTrolleyClass = isInMinimizedHeader ? "mini-trolley--minimized" : "";
  const isNewHeader = featureFlags.get("new_global_header");
  const shouldDisplayTrolley = !isNewHeader || (isLoggedIn && count > 0);

  if (!shouldDisplayTrolley) return null;

  return (
    <div className={`header-trolley ${headerTrolleyClass}`}>
      {showMiniTrolley && (
        <div className="header-trolley__mask" data-testid="mini-trolley-mask" onClick={() => handleCloseMiniTrolley} />
      )}

      <MiniTrolleyButton
        isMobile={isMobile}
        count={count}
        subtotal={subtotal}
        onClick={() => handleClickFullTrolley(sendPageEvent)}
        onTouchStart={handleOnTouchStart}
        onMouseEnter={handleOnMouseOver}
        onMouseLeave={handleOnMouseOut}
        onKeyUp={handleOnKeyUp}
        isLoading={isLoading}
      />

      {showMiniTrolley && (
        <div
          className={`mini-trolley ${miniTrolleyClass}`}
          data-testid="mini-trolley"
          onMouseEnter={handleOnMouseOver}
          onMouseLeave={handleOnMouseOut}
        >
          <div className="mini-trolley__cloud" data-testid="mini-trolley-cloud" onKeyUp={handleEscape}>
            <img className="mini-trolley__arrow-up" src={ArrowUP} aria-hidden="true" alt="" />
            <div className="mini-trolley__header">
              My trolley
              <span className="mini-trolley__item-number" data-testid="mini-trolley-quantity-products">
                &nbsp;{itemsCountCopy}
              </span>
              <TextButton
                className="mini-trolley__full-trolley-link"
                data-testid="mini-trolley-full-trolley-link"
                onClick={handleClickFullTrolley}
                aria-label="View full trolley"
                tabIndex={0}
              >
                Full trolley
              </TextButton>
            </div>
            <MiniTrolleyContent
              isLoading={isLoading}
              items={items}
              nectarOffers={filteredNectarOffers}
              showFormToEmptyMiniTrolleyPanel={showFormToEmptyMiniTrolleyPanel}
              error={error}
              hasSlot={hasSlot}
              handleGoBack={handleGoBack}
              handleClickStartShopping={handleClickStartShopping}
              handleDoEmptyMiniTrolley={handleDoEmptyMiniTrolley}
              handleShowEmptyMiniTrolleyPanel={() => handleShowEmptyMiniTrolleyPanel(sendPageEventForLightbox)}
              isAllItemsAmendable={isAllItemsAmendable}
            />
            {displayMinimumSpend && <UnderMinimumSpendBanner minimumSpend={minimumSpend} />}
            <div className="mini-trolley__footer">
              {!hasSlot ? (
                <FilledButton
                  className="mini-trolley__cta-button"
                  onClick={handleClickBookSlot}
                  data-testid="mini-trolley-book-slot-button"
                >
                  Book a slot
                </FilledButton>
              ) : (
                <FilledButton
                  className="mini-trolley__cta-button"
                  data-testid="mini-trolley-checkout-button"
                  disabled={!enableCheckout}
                  onClick={handleClickCheckout}
                >
                  {isCheckoutBYGPage || isCheckoutFFPage ? "Continue to checkout" : "Checkout"}
                </FilledButton>
              )}
              <button className="mini-trolley__close-button" onFocus={handleCloseMiniTrolley} />
              {savings && <div className="mini-trolley__savings">{savings}</div>}
              <div className="mini-trolley__total" data-testid="mini-trolley-subtotal">
                {subtotal}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
