import { Dispatch } from "redux";
import { AppThunkAction } from "../types";
import { Redirects } from "./redirects.reducer";
import { getProductsRedirect } from "../../services/redirects";

export enum RedirectsActionTypes {
  REDIRECTS_REQUEST = "REDIRECTS_REQUEST",
  REDIRECTS_FAILURE = "REDIRECTS_FAILURE",
  REDIRECTS_SUCCESS = "REDIRECTS_SUCCESS",
}

export type RedirectsActions = RedirectsRequestActionType | RedirectsSuccessActionType | RedirectsFailureActionType;

export type RedirectsRequestActionType = {
  type: RedirectsActionTypes.REDIRECTS_REQUEST;
};

export type RedirectsSuccessActionType = {
  type: RedirectsActionTypes.REDIRECTS_SUCCESS;
  searchTerms: Redirects;
};

export type RedirectsFailureActionType = {
  type: RedirectsActionTypes.REDIRECTS_FAILURE;
  searchTerms: Redirects;
};

export const redirectsRequestAction = (): RedirectsRequestActionType => ({
  type: RedirectsActionTypes.REDIRECTS_REQUEST,
});

export const redirectsSuccessAction = (searchTerms: Redirects): RedirectsSuccessActionType => ({
  type: RedirectsActionTypes.REDIRECTS_SUCCESS,
  searchTerms,
});

export const redirectsFailureAction = (searchTerms: Redirects): RedirectsFailureActionType => ({
  type: RedirectsActionTypes.REDIRECTS_FAILURE,
  searchTerms,
});

export const fetchRedirectAction: AppThunkAction = async (dispatch: Dispatch): Promise<void> => {
  dispatch(redirectsRequestAction());

  try {
    const result = await getProductsRedirect();
    dispatch(redirectsSuccessAction(result.data));
  } catch (err) {
    dispatch(redirectsFailureAction({}));
  }
};
