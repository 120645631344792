import { MenuItem, WCSBreadcrumbs } from "./Header.types";
import uuid from "uuid-v4";
import { State } from "../../common/store";
import { urls } from "../../routes";

export enum DEFAULT_MENU_ITEM_TITLE {
  GROCERIES = "Groceries",
  FAVOURITES = "Favourites",
  NECTAR = "Nectar",
  OFFERS = "Offers",
  RECIPES = "Recipes",
  DELIVERY_PASS = "Delivery Pass",
  OCCASIONS = "Occasions",
}

export const getDefaultMenuItems = (): MenuItem[] => [
  { url: urls.GROCERIES_URL, title: DEFAULT_MENU_ITEM_TITLE.GROCERIES, id: uuid() },
  {
    url: urls.FAVOURITES,
    title: DEFAULT_MENU_ITEM_TITLE.FAVOURITES,
    id: uuid(),
    external: false,
  },
  {
    url: urls.NECTAR_DESTINATION,
    title: DEFAULT_MENU_ITEM_TITLE.NECTAR,
    id: uuid(),
    external: false,
  },
  { url: urls.OFFERS, title: DEFAULT_MENU_ITEM_TITLE.OFFERS, id: uuid(), external: false },
  { url: urls.RECIPE, title: DEFAULT_MENU_ITEM_TITLE.RECIPES, id: uuid() },
  {
    url: urls.DELIVERY_PASS,
    title: DEFAULT_MENU_ITEM_TITLE.DELIVERY_PASS,
    authorizedUrl: "/shop/MyPasses?langid44&storeid=10151",
    newTab: true,
    id: uuid(),
  },
];

export const selectWCSBreadcrumbs = (state: State): WCSBreadcrumbs => state.wcsBreadcrumbs;

// Selector is used by BrowseHeader component to create a Link to it's parent category
export const selectWCSUrlForCategory = (category: string) => (state: State) => {
  if (state.synapticaBreadcrumbs[category]) return state.synapticaBreadcrumbs[category].url;
  if (!state.wcsBreadcrumbs[category]) return undefined;
  return state.wcsBreadcrumbs[category].url;
};
