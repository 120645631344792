import React, { createContext, ReactNode, useContext, useMemo } from "react";
import createUniqueId from "lodash.uniqueid";
import { ProductTileContainerProps } from "../ProductTile/productTile.types";

type TileContextProps = {
  children: ReactNode;
  isLastTileInCarousel?: ProductTileContainerProps["isLastTileInCarousel"];
};

type ContextValue = {
  uniqueId: string;
  isLastTileInCarousel: TileContextProps["isLastTileInCarousel"];
};

const TileContext = createContext<ContextValue>({} as ContextValue);

export const TileContextProvider = ({ children, isLastTileInCarousel }: TileContextProps) => {
  // Each tile has a unique ID
  const uniqueId = useMemo(() => createUniqueId(), []);

  const value = {
    uniqueId,
    isLastTileInCarousel,
  };

  return <TileContext.Provider value={value}>{children}</TileContext.Provider>;
};

export const useTileContext = () => {
  const context = useContext(TileContext);

  if (context === undefined) {
    throw new Error("The TileContext is undefined. useTileContext must be used within a TileContext.Provider");
  }

  return context;
};
