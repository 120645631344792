import React from "react";
import { useHistory } from "react-router-dom";
import { getChooseShoppingMethodUrl } from "../../../routes";
import { BasicModal } from "../../../components/Modal/NewModal/Modal.component";

export const AmendOrderExpiryModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}) => {
  const history = useHistory();

  return (
    <BasicModal
      title={"Sorry, your slot has expired"}
      className="modal-width-500"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      primaryButtonText="Book a slot"
      onPrimaryButtonClick={() => {
        setIsOpen(false);
        history.push(getChooseShoppingMethodUrl());
      }}
    >
      <p>Please book a new slot before you check out</p>
    </BasicModal>
  );
};
