import React from "react";
import { Button } from "@sainsburys-tech/fable";
import { Menu } from "@sainsburys-tech/icons";

export const ToggleButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    hasHiddenText
    icon={<Menu />}
    label="Expand Menu"
    onClick={onClick}
    variant="tertiary"
    size="sm"
    colourScheme="monochrome-dark"
    testid="mobile-mega-nav-toggle-button"
  />
);
