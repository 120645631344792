export type NectarDestinationsPageConfigCarousel = {
  title?: string;
  skus?: string[];
  enabled?: boolean;
  seeAllPath?: string;
};

export type NectarDestinationsPageConfigPopularCarousel = {
  title?: string;
  enabled?: boolean;
  seeAllPath?: string;
  interleave_sponsored?: boolean;
};

export type NectarDestinationPageConfigYnpCarousel = {
  title?: string;
  enabled?: boolean;
  seeAllPath?: string;
};

export type NectarDestinationsPageConfig = {
  carousels?: {
    static_sku_list?: NectarDestinationsPageConfigCarousel;
    nectar_popular_promotions?: NectarDestinationsPageConfigPopularCarousel;
    bonus_points?: Omit<NectarDestinationsPageConfigCarousel, "skus" | "seeAllPath">;
    favourites?: NectarDestinationsPageConfigCarousel;
    your_nectar_prices?: NectarDestinationPageConfigYnpCarousel;
  };
  magnolia?: {
    templateName?: string;
    enabled?: boolean;
  };
};

export enum NectarDestinationsPageCarouselsKey {
  NECTAR_STATIC_SKU_LIST_CAROUSEL = "nectarStaticSkuListCarousel",
  NECTAR_POPULAR_PRODUCTS_CAROUSEL = "nectarPopularProductsCarousel",
  NECTAR_FAVOURITES_CAROUSEL = "nectarFavouritesCarousel",
  NECTAR_BONUS_POINTS_CAROUSEL = "nectarBonusPointsCarousel",
  YOUR_NECTAR_PRICES_CAROUSEL = "yourNectarPricesCarousel",
}
