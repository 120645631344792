import { FilterProduct, ProductListControlsQueryParams } from "../../domain/product/product.types";

export interface ProductControlsStateProps {
  sortActive?: string;
  sortOptions?: SortOptionSelectField[];
  pageSizeOptions?: number[];
  pageSizeActive?: number;
  filters: FilterProduct[];
  filtersSelectedCount: number;
  changedControls: (params: ProductListControlsQueryParams) => void;
  resetControls: () => void;
}

export interface ProductControlsContainerProps {
  isPending: boolean;
  pendingStateFilters?: string[];
  pendingStateSortBy?: string;
  pendingStatePageSizeOptions?: number[];
}

export interface SortOptionSelectField {
  value: string;
  label: string;
}

export interface ProductControlsProps extends ProductControlsStateProps, ProductControlsContainerProps {}

export enum ProductControlsActionTypes {
  PRODUCT_CONTROLS_UPDATE = "PRODUCT_CONTROLS_UPDATE",
}

export interface ProductControlsChangedAction {
  type: ProductControlsActionTypes.PRODUCT_CONTROLS_UPDATE;
  params: ProductListControlsQueryParams;
}
