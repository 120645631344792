import React, { ReactNode } from "react";
import { createBrowserHistory } from "history";
import { configureStore } from "./common/store";
import { Provider } from "react-redux";

const isClient = typeof document !== "undefined";

export const browserHistory = isClient ? createBrowserHistory() : null;
const store = browserHistory ? configureStore(browserHistory) : configureStore();

if (browserHistory) {
  browserHistory.listen(() => {
    window.scrollTo(0, 0);
  });
}

export const StoreProvider = ({ children }: { children: ReactNode }) => <Provider store={store}>{children}</Provider>;
