import { ErrorState } from "../../common/store";
import { Action } from "redux";
import { ErrorActionTypes, UnhandledApiErrorAction } from "./error.actions";

const initialState = {
  errCode: null,
  errTitle: null,
  errDetail: null,
};

export const errorReducer = (state: ErrorState | undefined = initialState, action: Action) => {
  switch (action.type) {
    case ErrorActionTypes.UNHANDLED_API_ERROR:
      const errorAction = action as UnhandledApiErrorAction;
      return {
        ...state,
        errCode: errorAction.errCode,
        errTitle: errorAction.errTitle,
        errDetail: errorAction.errDetail,
      };
    default:
      return state;
  }
};
