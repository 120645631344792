import React from "react";
import { handleTrackingMessage } from "./espot.analytics";
import { withAdErrorBoundary, AdErrorBoundaryBannerTypes } from "../AdErrorBoundary";

export interface EspotProps {
  readonly name: string;
  readonly markup: string | null;
  expandHeight?: boolean;
}

interface EspotState {
  espotHeight: number;
}

export const GOL_ESPOT_WRAPPER_ID = "gol-espot-root";

class EspotWithoutErrorHandling extends React.PureComponent<EspotProps, EspotState> {
  static defaultProps = {
    expandHeight: true,
  };

  static ESPOT_HEIGHT_CHECK_INTERVAL_MS: 100;

  iframeRef: React.RefObject<HTMLIFrameElement>;

  constructor(props: EspotProps) {
    super(props);
    this.state = {
      espotHeight: 0,
    };
    this.iframeRef = React.createRef<HTMLIFrameElement>();
  }

  adjustEspotStyle() {
    const { iframeDoc } = this.getIframeContent();
    if (iframeDoc) {
      const { body } = iframeDoc;
      if (body) {
        body.style.margin = "0";
        body.style.padding = "0";
        body.style.overflow = "hidden";

        if (body.innerHTML === "") {
          body.innerHTML = `<div style="overflow: hidden" id="${GOL_ESPOT_WRAPPER_ID}"></div>`;
        }
      }
    }
  }

  resizeIframe = () => {
    const { iframeDoc, iframeWindow } = this.getIframeContent();
    if (iframeWindow && iframeDoc) {
      const siteWrapper = iframeDoc.getElementById(GOL_ESPOT_WRAPPER_ID);
      const currentHeight = this.state.espotHeight;
      if (siteWrapper) {
        const newHeight = siteWrapper.getBoundingClientRect().height;
        if (currentHeight !== newHeight) {
          this.updateEspotHeight(newHeight);
        }
      }
      iframeWindow.setTimeout(this.resizeIframe, EspotWithoutErrorHandling.ESPOT_HEIGHT_CHECK_INTERVAL_MS);
    }
  };

  updateEspotHeight(newHeight: number) {
    this.setState({ espotHeight: newHeight });
  }

  getIframeContent = () => {
    let iframeDoc = null;
    let iframeWindow = null;
    const { current: iframeNode } = this.iframeRef;
    if (iframeNode) {
      iframeDoc = iframeNode.contentDocument;
      iframeWindow = iframeNode.contentWindow;
    }
    return { iframeDoc, iframeWindow };
  };

  injectMarkup = () => {
    const { iframeDoc, iframeWindow } = this.getIframeContent();
    const { markup } = this.props;
    if (markup && iframeDoc) {
      const siteWrapper = iframeDoc.getElementById(GOL_ESPOT_WRAPPER_ID);
      if (siteWrapper) {
        siteWrapper.innerHTML = markup;

        // Inject inline style for quick links container
        const quickLinks = siteWrapper.querySelector(".m-quick-link-container");
        quickLinks && quickLinks.setAttribute("style", "overflow-x:auto");
      }

      // run JS because injecting HTML will not do this
      const scripts = iframeDoc.querySelectorAll("script");
      Array.from(scripts).forEach((script: HTMLScriptElement) => {
        /**
         * Running eval on injected code can cause unexpected errors to be thrown.
         * It is important that this component is wrapped with the withAdErrorBoundary HOC to catch these errors.
         * Should we be using eval? There are some concerns and tips for improvement here:
         * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#never_use_eval!
         */
        (iframeWindow as any).eval(`(function(window, parent, top) {
          ${script.innerHTML}
        })({}, {}, {})`);
        // Remove previously executed script
        if (script.parentNode !== null) {
          script.parentNode.removeChild(script);
        }
      });

      // enable links to go to the correct frame
      // TODO consider using a base tag, however it seems setting it from JS is too late
      const links = iframeDoc.querySelectorAll("a");
      Array.from(links).forEach((link: HTMLAnchorElement) => {
        const linkTarget = link.getAttribute("target");
        const isTargetSelf = linkTarget && linkTarget.toLowerCase() === "_self";
        if (!linkTarget || isTargetSelf) {
          link.setAttribute("target", "_top");
        }
      });
    }
  };

  addContentSquareTestScript = () => {
    const { iframeDoc } = this.getIframeContent();
    const { name } = this.props;

    if (name && iframeDoc) {
      const script = document.createElement("script");
      const pathname = `/espot/${name}`;
      const content = `(function () {
        window._uxa = window._uxa || [];
        if (typeof CS_CONF === 'undefined') {
          window._uxa.push(['setPath', '${pathname}']);
          var mt = document.createElement('script'); mt.type = 'text/javascript'; mt.async = true;
          mt.src = '//t.contentsquare.net/uxa/396640724211a.js';
          document.getElementsByTagName('head')[0].appendChild(mt);
        }
        else {
          window._uxa.push(['trackPageview', '${pathname}']);
        }
      })();`;

      script.innerHTML = content;
      iframeDoc.head.appendChild(script);
      iframeDoc.head.removeChild(script);
    }
  };

  exposeFunctionalIntegrations() {
    const { iframeWindow } = this.getIframeContent();
    if (iframeWindow) {
      (iframeWindow as any).postMessageToHost = (message: { [key: string]: any }) => {
        handleTrackingMessage(message);
      };
      (iframeWindow as any).openRecipe = (recipeId: string) => {
        window.constantco("open-recipe", recipeId);
      };
      (iframeWindow as any).openLandingSpace = (landingSpaceId: string) => {
        window.constantco("open-landing-space", landingSpaceId);
      };
    }
  }

  exposeData() {
    const { iframeWindow } = this.getIframeContent();
    const { name } = this.props;

    if (name && iframeWindow) {
      (iframeWindow as any).gol = {
        espotName: name,
      };
    }
  }

  renderEspot() {
    const { iframeWindow, iframeDoc } = this.getIframeContent();
    if (iframeWindow && iframeDoc) {
      this.adjustEspotStyle();
      this.exposeFunctionalIntegrations();
      this.exposeData();
      this.injectMarkup();
      if (this.props.expandHeight) {
        this.resizeIframe();
      }
    }
  }

  componentDidMount() {
    // typically espot will only receive markup on update
    // but we don't want to rely on this so this will ensure
    // render if markup is ready on mount
    this.renderEspot();
  }

  componentWillUnmount() {
    const { iframeWindow } = this.getIframeContent();
    if (iframeWindow) {
      // block access to the top window but retain postMessage
      iframeWindow.opener = null;
      (iframeWindow as any).sendMessage = null;
    }
  }

  render() {
    const height = this.state.espotHeight;
    const iframeStyle = {
      height: height ? `${height}px` : "100%",
    };
    return (
      <iframe
        // Will be read by screen readers
        id={`espot-${this.props.name}`}
        /** Fixes iframe rendering on Firefox
         * Source(s): https://stackoverflow.com/questions/11331863/render-an-iframe-dynamically-in-firefox
         *            https://stackoverflow.com/a/53732600
         */
        // eslint-disable-next-line no-script-url
        src="javascript:"
        data-testid="espot"
        title="Additional content"
        className="espot"
        style={iframeStyle}
        ref={this.iframeRef}
      />
    );
  }

  componentDidUpdate(prevProps: EspotProps) {
    if (prevProps.markup !== this.props.markup) {
      this.addContentSquareTestScript();
      this.renderEspot();
    }
  }
}

export const Espot = withAdErrorBoundary<EspotProps>(EspotWithoutErrorHandling, AdErrorBoundaryBannerTypes.ESPOT);
