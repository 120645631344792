import { RequestFilters } from "../services/product.types";
import { Filter } from "../domain/product/product.types";
import { BrowseControls } from "./Browse/Browse.types";
import { searchFilterKeys } from "../views/SearchResultsFindability/SearchResultsFindability.types";

export type FilterAdapterInput = PathFilters | RequestFilters | Filter[];
export type FilterAdapterOutput = PathFilters | RequestFilters;

export interface PathFilters {
  dietary?: string;
  brand?: string;
  offers?: boolean;
  offer?: boolean;
  other?: string;
  nav_Brand?: any;
}

export enum FilterVariations {
  "PathFilters" = "PathFilters",
  "RequestFilters" = "RequestFilters",
}

// TYPE PREDICATES
export const isOffersFilter = (s: string): boolean =>
  s.toLowerCase() === BrowseControls.offers || s.toLowerCase() === BrowseControls.offer || s === searchFilterKeys.OFFER;
export const isBrandFilter = (s: string): boolean =>
  s.toLowerCase() === BrowseControls.brand || s === searchFilterKeys.BRAND;
export const isPathFilters = (filters: FilterAdapterInput): filters is PathFilters =>
  Object.keys(filters)[0] in initPathFilters;
export const isRequestFilters = (filters: FilterAdapterInput): filters is RequestFilters =>
  Object.keys(filters)[0] in initRequestFilters && typeof Object.values(filters)[0] === "string";

// INITIALISED TYPE FOR CONVENIENCE
export const initPathFilters: PathFilters = {
  dietary: "",
  brand: "",
  offers: false,
  offer: false,
  other: "",
};

export const initRequestFilters: RequestFilters = {
  diet_Dietary_and_Lifestyle_Options: "",
  nav_Brand: "",
  other: "",
  "nav_Filter-Your-Results": "",
};
