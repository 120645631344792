import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../store";
import { loadKrang } from "./actions";
import { FavouritesActionTypes, FetchFavouritesSuccessAction } from "@favourites/Favourites.types";

export const recommendationsMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) => (next: Dispatch) => (action: FetchFavouritesSuccessAction) => {
    switch (action.type) {
      case FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS:
        const isSlotLocked = api.getState().basket?.basketDetails?.isSlotLocked ?? false;
        const { products } = action as FetchFavouritesSuccessAction;
        if (products && products.products.length > 0) {
          api.dispatch(loadKrang(isSlotLocked));
        }
        break;

      default:
        break;
    }
    next(action);
  };
