import DOMPurify from "isomorphic-dompurify";
import he from "he";

export namespace JSSanitise {
  /**
   * Sanitizes a given string by removing all HTML tags and attributes.
   *
   * @param {string} str - The string to be sanitized.
   * @returns {string} - The sanitized string.
   */
  export const string = (str: string): string => DOMPurify.sanitize(str, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });

  /**
   * Sanitizes a given URL by decoding HTML entities and removing any JavaScript code.
   * Reference: https://cheatsheetseries.owasp.org/cheatsheets/XSS_Filter_Evasion_Cheat_Sheet.html
   *
   * @param {string} url - The URL to be sanitized.
   * @returns {string} - The sanitized URL.
   */
  export const url = (url: string): string => {
    if (!url) return "";

    // decode HTML entities and URI encode
    const htmlDecoded = he.decode(url);
    const urlDecoded = decodeURI(htmlDecoded);

    // find all permutations of "javascript" protocol and remove it
    // regex for tabs, spaces, newlines, and any char from 1-32 in decimal
    const x = "[\\t\\s\\n|\\x01-\\x20]*";
    const pattern = new RegExp(`(^|=)${x}j${x}a${x}v${x}a${x}s${x}c${x}r${x}i${x}p${x}t${x}:.*`, "ig");
    const sanitizedProtocols = urlDecoded.replace(pattern, "");

    if (urlDecoded !== sanitizedProtocols || urlDecoded !== string(sanitizedProtocols)) {
      return "";
    }

    return url;
  };
}
