import { FetchRecommendationsSuccessAction, RecommendationsActionTypes } from "./actions";
import { Reducer } from "redux";
import { DataState } from "../dataState";
import { RecommendationsState } from "./recommendations.types";

export const recommendationsInitialState: RecommendationsState = {
  dataState: DataState.UNKNOWN,
};

export const recommendationsReducer: Reducer<RecommendationsState> = (
  state: RecommendationsState = recommendationsInitialState,
  action: FetchRecommendationsSuccessAction
): RecommendationsState => {
  switch (action.type) {
    case RecommendationsActionTypes.FETCH_RECOMMENDATIONS_SUCCESS: {
      return {
        dataState: DataState.SUCCESS,
      };
    }
    default:
      return state;
  }
};
