import { Action, Dispatch } from "redux";
import { RouterState } from "connected-react-router";
import { mapBasketAddOrderErrors, mapBasketPromotion, mapBasketToDomain } from "./basket";
import { getPickTimeAndStoreNum, getSlotBooked } from "./basket.helpers";
import { selectNudgeValue } from "./basket.selectors";
import {
  AddToBasketOnLoginSuccessAction,
  Basket,
  BasketActionTypes,
  BasketBulkOrderLimitExceededErrorAction,
  BasketError,
  BasketErrorKeys,
  BasketItemQuantityExceededErrorAction,
  BasketMaximumLimitExceededErrorAction,
  BasketProduct,
  BasketScottishLegislationErrorAction,
  ChangeUnitOfMeasureActionParams,
  ChangeUnitOfMeasureOnLoginSuccessAction,
  CleanDeletedProductsAction,
  ClearBasketErrorAction,
  ClickSource,
  CreateDeletedProductAction,
  CreateUpdateBasketAdobeAnalyticsRequestActionParams,
  CreateUpdateBasketAdobeAnalyticsRequestActionType,
  CreateUpdateBasketErrorActionType,
  CreateUpdateBasketRequestActionParams,
  CreateUpdateBasketRequestActionType,
  CreateUpdateBasketSuccessActionType,
  DeleteBasketSuccessAction,
  DeletedBasketProduct,
  FetchBasketSuccessAction,
  ITEMS,
  KG,
  RemoveDeletedProductAction,
  UpdateSubstitutionPreferencesSuccessAction,
} from "./basket.types";
import { Product, ProductType } from "../product/product.types";
import GolTime from "../time";
import { selectAdMetaData } from "../../common/ads";
import { digitalDataGenericTrackEvent } from "../../common/analytics/digitalData";
import { INTERACTION_EVENT_NAMES } from "../../common/analytics/types";
import { calculateUnitPrices, mapBasketProduct } from "../../common/dataLayer/basket";
import { FulfilmentState, PFMCategory } from "../../common/dataLayer/types";
import { createDeferredConditionalAction } from "../../common/deferredConditionalAction";
import { Result } from "../../common/http/request";
import {
  AddToCartAdobeProperties,
  createAddToCartAdobeDigitalDataProperties,
  initializeAddToCartAdobePropertiesObject,
} from "../../common/middleware/analyticsMiddleware/analyticsMiddleware";
import { createInternalNavigationAction } from "../../common/middleware/navigationMiddleware";
import { handleUnauthorized } from "../../common/responseErrorHandler";
import { State } from "../../common/store";
import { AppThunkDispatch, ResponseErrorActionType } from "../../common/types";
import { createOpenGenericModalAction } from "../../components/Modal/modal.actions";
import { createExternalNavigationWindow } from "../../components/RedirectExternal";
import { urls } from "../../routes";
import {
  addItemToBasket,
  addOrderToBasket as servicesAddOrderToBasket,
  deleteBasket as servicesDeleteBasket,
  deleteItemsFromBasket as servicesDeleteItemsFromBasket,
  fetchBasket as servicesFetchBasket,
  updateBasket,
  updateSubstitutionPreferences as servicesUpdateSubstitutionPreferences,
} from "../../services/basket";
import {
  Basket as ServicesBasket,
  BasketItem,
  BasketItemAdd,
  BasketItemQuantityExceededError,
  BasketItemSubstitutionPreference,
  BasketItemUpdate,
  BulkOrderLimitExceededError,
  C62,
  MaximumBasketLimitExceededError,
  ScottishLegislationRestrictionError,
  ScottishLegislationRestrictionRebookDeliveryError,
  UnitOfMeasure,
  EACH,
} from "../../services/basket.types";
import { pushAddToBagEvent } from "../../utils/taggstar";
import { CheckPostcodeActionTypes } from "../../views/CheckPostcode/checkPostcode.types";
import {
  createAddOrderToBasketErrorsAction,
  createAddOrderToBasketFailureAction,
  createAddOrderToBasketRequestAction,
  createAddOrderToBasketSuccessAction,
} from "../../views/PreviousOrders/previousOrders.actions";
import { selectActivePreviousOrder } from "../../views/PreviousOrders/previousOrders.selectors";

export const createUpdateBasketRequestAction = ({
  sku,
  quantityChange,
  uom,
  selectedCatchweight,
  clickSource,
  product,
  basketProduct,
  tileId,
  carouselKey,
  fulfilmentState,
}: CreateUpdateBasketRequestActionParams): CreateUpdateBasketRequestActionType => ({
  type: BasketActionTypes.UPDATE_BASKET_REQUEST,
  sku,
  quantityChange,
  uom,
  selectedCatchweight,
  clickSource,
  product,
  basketProduct,
  tileId,
  carouselKey,
  fulfilmentState,
});

export const createUpdateBasketAdobeAnalyticsRequestAction = ({
  sku,
  quantityChange,
  uom,
  selectedCatchweight,
  clickSource,
  product,
  basketProduct,
  basketProductQuantity,
  basketProductSubtotalPrice,
  basketProductTotalBasePrice,
  basketPriceIncrement,
  nectarPromotion,
  carouselKey,
  fulfilmentState,
  basketItem,
}: CreateUpdateBasketAdobeAnalyticsRequestActionParams): CreateUpdateBasketAdobeAnalyticsRequestActionType => ({
  type: BasketActionTypes.UPDATE_BASKET_ADOBE_ANALYTICS_REQUEST,
  sku,
  quantityChange,
  uom,
  selectedCatchweight,
  clickSource,
  product,
  basketProduct,
  basketProductQuantity,
  basketProductSubtotalPrice,
  basketProductTotalBasePrice,
  basketPriceIncrement,
  nectarPromotion,
  carouselKey,
  fulfilmentState,
  basketItem,
});

export const createUpdateBasketSuccessAction = (basket: Basket): CreateUpdateBasketSuccessActionType => ({
  type: BasketActionTypes.UPDATE_BASKET_SUCCESS,
  basket,
});

export const createUpdateBasketErrorAction = (sku: string): CreateUpdateBasketErrorActionType => ({
  type: BasketActionTypes.UPDATE_BASKET_ERROR,
  sku,
});

export const createDeletedProductAction = (product: DeletedBasketProduct): CreateDeletedProductAction => ({
  type: BasketActionTypes.CREATE_DELETED_PRODUCT_ACTION,
  product,
});

export const removeDeletedProductAction = (sku: string): RemoveDeletedProductAction => ({
  type: BasketActionTypes.REMOVE_DELETED_PRODUCT_ACTION,
  sku,
});

export const cleanDeletedProductsAction = (): CleanDeletedProductsAction => ({
  type: BasketActionTypes.CLEAN_DELETED_PRODUCTS_ACTION,
});

export const addToBasketOnLoginSuccessAction = (
  sku: string,
  unitOfMeasure: UnitOfMeasure,
  selectedCatchweight: string,
  product?: Product,
  basketProduct?: BasketProduct
): AddToBasketOnLoginSuccessAction => ({
  type: BasketActionTypes.ADD_TO_BASKET_ON_LOGIN_SUCCESS,
  sku,
  unitOfMeasure,
  selectedCatchweight,
  product,
  basketProduct,
});

export const changeUnitOfMeasureOnLoginSuccessAction = (
  sku: string,
  unitOfMeasure: UnitOfMeasure,
  clickSource: ClickSource,
  basketProduct: BasketProduct
): ChangeUnitOfMeasureOnLoginSuccessAction => ({
  type: BasketActionTypes.CHANGE_UNIT_OF_MEASURE_ON_LOGIN_SUCCESS,
  sku,
  unitOfMeasure,
  clickSource,
  basketProduct,
});

export const fetchBasketPendingActionCreator = (): Action => ({
  type: BasketActionTypes.FETCH_BASKET_PENDING,
});

export const fetchBasketSuccessActionCreator = (basket: Basket): FetchBasketSuccessAction => ({
  type: BasketActionTypes.FETCH_BASKET_SUCCESS,
  basket,
});

export const createUpdateLocalQuantityAction = (sku: string, quantity: number, tileId?: string) => ({
  type: BasketActionTypes.UPDATE_LOCAL_QUANTITY,
  sku,
  quantity,
  tileId,
});

export const fetchBasketErrorActionCreator = (): Action => ({
  type: BasketActionTypes.FETCH_BASKET_ERROR,
});

export const deleteBasketSuccessActionCreator = (): DeleteBasketSuccessAction => ({
  type: BasketActionTypes.DELETE_BASKET_SUCCESS,
});

export const updateSubstitutionPreferencesSuccessActionCreator = (
  basket: Basket
): UpdateSubstitutionPreferencesSuccessAction => ({
  type: BasketActionTypes.UPDATE_SUBSTITUTION_PREFERENCES_SUCCESS,
  basket,
});

export const basketScottishLegislationErrorActionCreator = (
  title: string,
  detail: string
): BasketScottishLegislationErrorAction => ({
  type: BasketActionTypes.BASKET_SCOTTISH_LEGISLATION_ERROR,
  title,
  detail,
});

export const basketBulkOrderLimitExceededErrorActionCreator = (
  title: string,
  detail: string
): BasketBulkOrderLimitExceededErrorAction => ({
  type: BasketActionTypes.BASKET_BULK_ORDER_LIMIT_EXCEEDED_ERROR,
  title,
  detail,
});

export const basketItemQuantityExceededErrorActionCreator = (
  title: string,
  detail: string
): BasketItemQuantityExceededErrorAction => ({
  type: BasketActionTypes.BASKET_ITEM_QUANTITY_EXCEEDED_ERROR,
  title,
  detail,
});

export const basketMaximumLimitExceededErrorActionCreator = (
  payload: BasketError
): BasketMaximumLimitExceededErrorAction => ({
  type: BasketActionTypes.BASKET_MAXIMUM_LIMIT_EXCEEDED_ERROR,
  payload,
});

export const clearBasketErrorActionCreator = (error: string): ClearBasketErrorAction => ({
  type: BasketActionTypes.CLEAR_BASKET_ERROR,
  error,
});

export const clearAllBasketErrorsActionCreator = (): Action => ({
  type: BasketActionTypes.CLEAR_ALL_BASKET_ERRORS,
});

export const fetchBasket = (calculateOrder?: boolean) => async (dispatch: AppThunkDispatch) => {
  dispatch(fetchBasketPendingActionCreator());

  const { pickTime, storeNum } = getPickTimeAndStoreNum();
  const slotBooked = getSlotBooked();

  const result: Result<ServicesBasket> = await servicesFetchBasket({
    calculateOrder,
    pickTime,
    storeNum,
    slotBooked,
  });

  handleUnauthorized(result)(dispatch);

  if (result.isSuccess()) {
    const basket = mapBasketToDomain(result.data);
    dispatch(fetchBasketSuccessActionCreator(basket));
  } else {
    dispatch(fetchBasketErrorActionCreator());
  }
};

export const addOrderToBasket = () => async (dispatch: Dispatch, getState: () => State) => {
  const orderID = selectActivePreviousOrder(getState()).orderUid;
  dispatch(createAddOrderToBasketRequestAction(orderID));
  const products = getState().products;
  const adobeDigitalDataProperties = initializeAddToCartAdobePropertiesObject();

  const { storeNum, pickTime } = getPickTimeAndStoreNum();
  const slotBooked = getSlotBooked();

  const result = await servicesAddOrderToBasket(orderID, pickTime, storeNum, slotBooked);

  if (result && result.isSuccess()) {
    const isBasketEmpty = result.data.basket.items.length === 0;
    if (products) {
      products.data.forEach(product => {
        if (!isBasketEmpty) {
          var itemAdded = result.data.basket.items.filter(item => {
            return item.product.sku === product.productUid;
          });

          const [firstItem] = itemAdded;

          if (firstItem) {
            const quantity = firstItem.quantity;
            const clickSource = ClickSource.ADD_ALL_PRODUCTS;
            const pageSize = products.data.size;
            const description = "Add All Products";
            const type = product.productType;
            const uom = mapUnitOfMeasure(firstItem.uom, type);
            const addedProductDetail = getAddedProductInformation(
              firstItem,
              product,
              quantity,
              uom,
              pageSize,
              description,
              PFMCategory.PREVIOUS_ORDERS,
              getState
            );

            if (addedProductDetail) {
              const { dataLayerBasketProduct, unitPriceAndSaving, mappedNectarPromotion, adMetaData } =
                addedProductDetail;
              const priceIncrement = addedProductDetail.basketPriceIncrement || 0;
              const properties = createAddToCartAdobeDigitalDataProperties({
                productReview: product,
                product: dataLayerBasketProduct,
                priceIncrement,
                unitPriceAndSaving,
                clickSource,
                nectarPromotion: mappedNectarPromotion,
                productSource: product.source,
                adMetaData,
              });

              fillAddToCartAdobePropertiesObject(properties, adobeDigitalDataProperties);
            }
          }
        }
      });
    }

    digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.ADD_TO_CART, adobeDigitalDataProperties);
    const basket = mapBasketToDomain(result.data.basket);
    dispatch(createUpdateBasketSuccessAction(basket));
    if (result.data.errors && result.data.errors.length > 0) {
      const errors = mapBasketAddOrderErrors(result.data.errors);
      dispatch(createAddOrderToBasketErrorsAction(errors));
    }
    dispatch(createAddOrderToBasketSuccessAction());
  } else {
    const error = result.errors.first();
    if (result.isClientError() && error.code === MaximumBasketLimitExceededError) {
      dispatch(
        basketMaximumLimitExceededErrorActionCreator({
          title: error.title,
          detail: error.detail,
          url: error.meta.information_url,
        })
      );
    } else {
      dispatch(createOpenGenericModalAction());
    }
    dispatch(createAddOrderToBasketFailureAction());
  }
};

// Either product or basketProduct is required
export type CreateSyncBasketQuantityActionParams =
  | {
      sku: string;
      newQuantity: number;
      unitOfMeasure: UnitOfMeasure;
      selectedCatchweight?: string;
      clickSource: ClickSource;
      productType?: ProductType;
      basketProduct: BasketProduct;
      isProductDeleted?: boolean;
      calculateOrder?: boolean;
      product?: Product;
      decrement?: boolean;
      carouselKey?: string;
      tileId?: string;
      fulfilmentState?: FulfilmentState;
    }
  | {
      sku: string;
      newQuantity: number;
      unitOfMeasure: UnitOfMeasure;
      selectedCatchweight?: string;
      clickSource: ClickSource;
      productType?: ProductType;
      basketProduct?: BasketProduct;
      isProductDeleted?: boolean;
      calculateOrder?: boolean;
      product: Product;
      decrement?: boolean;
      carouselKey?: string;
      tileId?: string;
      fulfilmentState?: FulfilmentState;
    };

export const createSyncBasketQuantityAction =
  ({
    sku,
    newQuantity,
    unitOfMeasure,
    selectedCatchweight = "",
    clickSource = ClickSource.PRODUCT_GRID,
    productType,
    basketProduct,
    isProductDeleted,
    calculateOrder = false,
    product,
    decrement,
    carouselKey,
    tileId,
    fulfilmentState,
  }: CreateSyncBasketQuantityActionParams) =>
  async (dispatch: Dispatch) => {
    const productIdentifier = basketProduct ? { item_uid: basketProduct.itemId } : { product_uid: sku };
    const quantityChange: number = basketProduct ? newQuantity - basketProduct.quantity : newQuantity;
    const type = productType || (basketProduct && basketProduct.productType);
    //Passing the decreasing_quantity flag to true when quantity is for an item is reduced so that bulk limit check can be ignored.
    const payload: BasketItemUpdate | BasketItemAdd = {
      product_uid: sku,
      quantity: newQuantity,
      uom: mapUnitOfMeasure(unitOfMeasure, type),
      selected_catchweight: selectedCatchweight,
      ...productIdentifier,
      decreasing_quantity: decrement,
    };

    dispatch(
      createUpdateBasketRequestAction({
        sku,
        quantityChange,
        uom: unitOfMeasure,
        selectedCatchweight,
        clickSource,
        product,
        basketProduct,
        tileId,
        carouselKey,
        fulfilmentState,
      })
    );

    const { pickTime, storeNum } = getPickTimeAndStoreNum();
    const slotBooked = getSlotBooked();

    const result = Boolean(basketProduct)
      ? await updateBasket(payload as BasketItemUpdate, pickTime, storeNum, slotBooked)
      : await addItemToBasket(payload as BasketItemAdd, calculateOrder, pickTime, storeNum, slotBooked);

    if (result.isSuccess()) {
      if (result.data && result.data.items) {
        const addItem = result.data.items.filter(item => item.product.sku === sku);

        const firstItem = addItem && addItem[0];
        const nectarPromotion = firstItem && firstItem.promotions.filter(promotion => promotion.is_nectar_price);

        const firstNectarPromotion = nectarPromotion && nectarPromotion[0];
        const mappedNectarPromotion = firstNectarPromotion ? mapBasketPromotion(firstNectarPromotion) : undefined;
        const basketUpdates = getBasketUpdates(firstItem, basketProduct);

        const { basketProductQuantity, basketProductSubtotalPrice, basketProductTotalBasePrice, basketPriceIncrement } =
          basketUpdates;

        pushAddToBagEvent(product, quantityChange);

        dispatch(
          createUpdateBasketAdobeAnalyticsRequestAction({
            sku,
            quantityChange,
            uom: unitOfMeasure,
            selectedCatchweight,
            clickSource,
            product,
            basketProduct,
            basketProductQuantity,
            basketProductSubtotalPrice,
            basketProductTotalBasePrice,
            basketPriceIncrement,
            nectarPromotion: mappedNectarPromotion,
            carouselKey,
            fulfilmentState,
            basketItem: firstItem,
          })
        );
      }
      const basket = mapBasketToDomain(result.data);
      dispatch(createUpdateBasketSuccessAction(basket));
      if (isProductDeleted && basketProduct && product) {
        dispatch(
          createDeletedProductAction({
            product,
            clickSource,
            selectedCatchweight,
            unit: unitOfMeasure,
          })
        );
      } else {
        dispatch(removeDeletedProductAction(sku));
      }
    } else {
      if (result.isUnauthorised()) {
        const expiryTime = GolTime.addSeconds(30);
        const action = addToBasketOnLoginSuccessAction(sku, unitOfMeasure, selectedCatchweight, product, basketProduct);
        dispatch(createDeferredConditionalAction(CheckPostcodeActionTypes.CHECK_POSTCODE_SUCCESS, expiryTime, action));
        dispatch(createInternalNavigationAction(urls.LOGON_VIEW_IDENTIFIER));
        dispatch({
          type: ResponseErrorActionType.HTTP_UNAUTHORIZED_ERROR,
        });
      }
      dispatch(createUpdateBasketErrorAction(sku));
      dispatch(handleChangeQuantityError(result));
    }
  };

type BasketUpdates = {
  basketProductQuantity: number | undefined;
  basketProductSubtotalPrice: number | undefined;
  basketProductTotalBasePrice: number | undefined;
  basketPriceIncrement: number | undefined;
};

const calculateBasketPriceIncrement = (addedItem: BasketItem, basketProduct: BasketProduct | undefined) => {
  const addedItemSubtotal = addedItem?.subtotal_price;
  const basketSubtotal = basketProduct?.subTotal;

  return basketSubtotal ? Math.round((addedItemSubtotal - basketSubtotal) * 100) / 100 : addedItemSubtotal;
};

export const getBasketUpdates = (addedItem: BasketItem, basketProduct: BasketProduct | undefined): BasketUpdates => {
  return {
    basketProductQuantity: addedItem?.quantity || undefined,
    basketProductSubtotalPrice: addedItem?.subtotal_price || undefined,
    basketProductTotalBasePrice: Number(addedItem?.total_base_price) || undefined,
    basketPriceIncrement: calculateBasketPriceIncrement(addedItem, basketProduct),
  };
};

export const handleChangeQuantityError = (result: Result<ServicesBasket>) => {
  if (result.isClientError()) {
    const error = result.errors.first();
    switch (error.code) {
      case ScottishLegislationRestrictionError:
      case ScottishLegislationRestrictionRebookDeliveryError:
        return basketScottishLegislationErrorActionCreator(error.title, error.detail);
      case BulkOrderLimitExceededError:
        return basketBulkOrderLimitExceededErrorActionCreator(error.title, error.detail);
      case BasketItemQuantityExceededError:
        return basketItemQuantityExceededErrorActionCreator(error.title, error.detail);
      case MaximumBasketLimitExceededError:
        return basketMaximumLimitExceededErrorActionCreator({
          title: error.title,
          detail: error.detail,
          url: error.meta.information_url,
        });
      default:
        return fetchBasketErrorActionCreator();
    }
  }
  return fetchBasketErrorActionCreator();
};

export const deleteBasket = () => {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(fetchBasketPendingActionCreator());

    const result: Result<void> = await servicesDeleteBasket();

    handleUnauthorized(result)(dispatch);

    if (result.isSuccess()) {
      dispatch(deleteBasketSuccessActionCreator());
    } else {
      dispatch(fetchBasketErrorActionCreator());
    }
  };
};

export const updateSubstitutionPreferences = (basketItem: BasketItemSubstitutionPreference) => {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(fetchBasketPendingActionCreator());

    const { pickTime, storeNum } = getPickTimeAndStoreNum();
    const slotBooked = getSlotBooked();

    const result: Result<ServicesBasket> = await servicesUpdateSubstitutionPreferences(
      basketItem,
      pickTime,
      storeNum,
      slotBooked
    );

    dispatch(handleUnauthorized(result));

    if (result.isSuccess()) {
      const basket: Basket = mapBasketToDomain(result.data);
      dispatch(updateSubstitutionPreferencesSuccessActionCreator(basket));
    } else {
      dispatch(fetchBasketErrorActionCreator());
    }
  };
};

export const changeUnitOfMeasureActionCreator = ({
  sku,
  unitOfMeasure,
  clickSource,
  calculateOrder,
  basketProduct,
  product,
}: ChangeUnitOfMeasureActionParams) => {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    if (!basketProduct) {
      return;
    }
    const productType = basketProduct.productType;
    const nudgeValue = selectNudgeValue(unitOfMeasure);

    const handleUnauthorisedUpdateBasket = () => {
      const expiryTime = GolTime.addSeconds(30);
      const action = changeUnitOfMeasureOnLoginSuccessAction(sku, unitOfMeasure, clickSource, basketProduct);
      dispatch(createDeferredConditionalAction(CheckPostcodeActionTypes.CHECK_POSTCODE_SUCCESS, expiryTime, action));
      dispatch(createInternalNavigationAction(urls.LOGON_VIEW_IDENTIFIER));
    };

    dispatch(
      createUpdateBasketRequestAction({
        sku,
        quantityChange: nudgeValue,
        uom: unitOfMeasure,
        clickSource,
        basketProduct,
        product,
      })
    );

    const { pickTime, storeNum } = getPickTimeAndStoreNum();
    const slotBooked = getSlotBooked();

    // remove item from basket
    const removeResult = await updateBasket(
      {
        quantity: 0,
        uom: mapUnitOfMeasure(basketProduct.unitOfMeasure, productType),
        item_uid: basketProduct.itemId,
        product_uid: sku,
      },
      pickTime,
      storeNum,
      slotBooked
    );

    if (!removeResult.isSuccess()) {
      if (removeResult.isUnauthorised()) {
        handleUnauthorisedUpdateBasket();
      }
      dispatch(createUpdateBasketErrorAction(sku));
      dispatch(handleChangeQuantityError(removeResult));
      return;
    }

    //  add new item to basket with default quantity
    dispatch(createUpdateLocalQuantityAction(sku, nudgeValue));
    const addResult = await addItemToBasket(
      {
        quantity: nudgeValue,
        uom: mapUnitOfMeasure(unitOfMeasure, productType),
        product_uid: sku,
      },
      calculateOrder,
      pickTime,
      storeNum,
      slotBooked
    );

    if (!addResult.isSuccess()) {
      if (addResult.isUnauthorised()) {
        handleUnauthorisedUpdateBasket();
      }
      dispatch(createUpdateBasketErrorAction(sku));
      dispatch(handleChangeQuantityError(addResult));
      return;
    }
    const basket = mapBasketToDomain(addResult.data);
    dispatch(createUpdateBasketSuccessAction(basket));
  };
};

export const mapUnitOfMeasure = (uom: UnitOfMeasure, productType?: ProductType): UnitOfMeasure => {
  // This has been added due to the requirement of loose weight items
  // needing to be sent with a uom of C62 to have parity with WCS
  if (productType === ProductType.LOOSE && uom !== KG) {
    return C62;
  }

  return uom === ITEMS ? EACH : KG;
};

export const maximumBasketLimitHelpActionCreator = () => (dispatch: Dispatch<Action>, getState: () => State) => {
  const url = getState().basket.errors.maximumBasketLimitExceeded!.url!;
  dispatch(clearBasketErrorActionCreator(BasketErrorKeys.MAXIMUM_BASKET_LIMIT_EXCEEDED));
  dispatch(createExternalNavigationWindow(url));
};

export const deleteItemsFromBasket = (unavailableProductIds: string[]) => async (dispatch: AppThunkDispatch) => {
  const { pickTime, storeNum } = getPickTimeAndStoreNum();
  const slotBooked = getSlotBooked();
  const deleteResult = await servicesDeleteItemsFromBasket(unavailableProductIds, pickTime, storeNum, slotBooked);

  if (!deleteResult.isSuccess()) {
    dispatch(handleUnauthorized(deleteResult));
    return;
  }
};

export const getAddedProductInformation = (
  addedItem: BasketItem,
  product: Product,
  quantity: number,
  uom: string,
  pageSize: number,
  description: string,
  pfmCategory: PFMCategory,
  getState: () => State
) => {
  const productUid = product.productUid;
  var nectarPromotion = addedItem.promotions.filter(promotion => promotion.is_nectar_price);

  const [firstNectarPromotion] = nectarPromotion;
  const mappedNectarPromotion = firstNectarPromotion ? mapBasketPromotion(firstNectarPromotion) : undefined;

  const basketUpdates = getBasketUpdates(addedItem, undefined);

  const { basketProductQuantity, basketProductSubtotalPrice, basketProductTotalBasePrice, basketPriceIncrement } =
    basketUpdates;

  let unitPriceAndSaving = undefined;

  if (basketProductSubtotalPrice && basketProductTotalBasePrice && basketProductQuantity) {
    unitPriceAndSaving = calculateUnitPrices(
      basketProductSubtotalPrice,
      basketProductTotalBasePrice,
      basketProductQuantity
    );
  }

  const pageNumber = 1;
  const adMetaData = selectAdMetaData(getState(), productUid);

  const dataLayerBasketProduct = mapBasketProduct(
    pageNumber,
    pageSize,
    product,
    uom,
    quantity,
    product.productUid,
    description,
    pfmCategory,
    true,
    undefined,
    adMetaData
  );

  return {
    dataLayerBasketProduct,
    basketPriceIncrement,
    unitPriceAndSaving,
    mappedNectarPromotion,
    adMetaData,
  };
};

export const fillAddToCartAdobePropertiesObject = (
  currentCart: AddToCartAdobeProperties,
  finalCart: AddToCartAdobeProperties
): AddToCartAdobeProperties => {
  const {
    data_product_addToCartItemType,
    data_product_addToCartOriginList,
    data_product_addToCartPrice,
    data_product_addToCartQuantity,
    data_product_addToCartUnitPrice,
    data_product_addToCartWeight,
    data_product_id,
    data_product_offerType,
    data_product_originalPrice,
    data_product_originalUnitPrice,
    data_product_priceSaving,
    data_product_quantityType,
    data_product_reviewCount,
    data_product_reviewScore,
    data_product_unitPriceSaving,
    data_shoppingMode,
  } = currentCart;

  finalCart.data_product_id.push(data_product_id[0]);
  finalCart.data_product_addToCartWeight.push(data_product_addToCartWeight[0]);
  finalCart.data_product_addToCartQuantity.push(data_product_addToCartQuantity[0]);
  finalCart.data_product_addToCartUnitPrice.push(data_product_addToCartUnitPrice[0]);
  finalCart.data_product_reviewCount.push(data_product_reviewCount[0]);
  finalCart.data_product_reviewScore.push(data_product_reviewScore[0]);
  finalCart.data_shoppingMode = data_shoppingMode;

  if (data_product_addToCartItemType[0])
    finalCart.data_product_addToCartItemType.push(data_product_addToCartItemType[0]);
  else {
    finalCart.data_product_addToCartItemType.push("");
  }
  finalCart.data_product_addToCartOriginList = data_product_addToCartOriginList;
  finalCart.data_product_quantityType.push(data_product_quantityType[0]);
  finalCart.data_product_originalUnitPrice.push(data_product_originalUnitPrice[0]);
  finalCart.data_product_addToCartPrice.push(data_product_addToCartPrice[0]);
  finalCart.data_product_originalPrice.push(data_product_originalPrice[0]);
  finalCart.data_product_priceSaving.push(data_product_priceSaving[0]);
  finalCart.data_product_unitPriceSaving.push(data_product_unitPriceSaving[0]);
  finalCart.data_product_offerType.push(data_product_offerType[0]);

  return finalCart;
};

/**
 * Used for adding a single item to basket after a user is redirected to a slot page from a product tile with a
 * restricted promise date.
 *
 * @param router the router object from which the product's order promise is destructured
 * @param pickTime the date time string for the slot reservation
 * @param storeNum the slot reservation store number
 * @returns void
 */
export const addSlotItemToBasket = async (
  router: RouterState<any>,
  pickTime: string,
  storeNum: string
): Promise<Result<ServicesBasket>> => {
  const state = router.location?.state;
  const selectedCatchweight: string = state?.selectedCatchweight;
  const selectedUnit: string = state?.selectedUnit;
  const product: Product = state?.product;
  const nudgeValue = selectNudgeValue(selectedUnit);

  return await addItemToBasket(
    {
      product_uid: product.productUid,
      uom: mapUnitOfMeasure(selectedUnit, product.productType),
      quantity: nudgeValue,
      selected_catchweight: selectedCatchweight,
    },
    false,
    pickTime,
    storeNum,
    true // slot is always booked at this point
  );
};
