import { AppThunkDispatch } from "../../common/types";
import { handleUnauthorized } from "../../common/responseErrorHandler";
import { mapProducts } from "../../domain/product/product";
import {
  FetchBasketProductsErrorAction,
  FetchBasketProductsSuccessAction,
  FetchBasketProductsSuccessWithErrorsAction,
  TrolleyActionTypes,
} from "./trolley.types";
import { fetchProductsByUids } from "../../services/product";
import { ProductErrorCode } from "../../services/product.types";
import { Product, ProductSource } from "../../domain/product/product.types";
import { getCachedSlotReservation } from "../../domain/slot/slotReservation";
import { matchesPath, routes } from "../../routes";

export const fetchBasketProductsSuccessActionCreator = (
  products: Product[],
  productSource: ProductSource
): FetchBasketProductsSuccessAction => ({
  type: TrolleyActionTypes.FETCH_BASKET_PRODUCTS_SUCCESS,
  products,
  productSource,
});

export const DietaryProfileErrorAction = (): FetchBasketProductsSuccessWithErrorsAction => ({
  type: TrolleyActionTypes.DIETARY_PROFILE_ERROR,
});

export const fetchBasketProductsErrorAction = (): FetchBasketProductsErrorAction => ({
  type: TrolleyActionTypes.FETCH_BASKET_PRODUCTS_ERROR,
});

export const getBasketProducts =
  (skus: string[], productSource: ProductSource, isSlotLocked: boolean) => async (dispatch: AppThunkDispatch) => {
    const { storeIdentifier, slotDate, slotDateTime, flexiStores, reservationType } = getCachedSlotReservation() || {};
    const selectedSlot = localStorage.getItem("slot_selected");
    const CTTSlot = selectedSlot ? selectedSlot : undefined;
    const productSlotDate = matchesPath(location.pathname, routes.CHANGES_TO_TROLLEY) ? CTTSlot : slotDate;

    const result = await fetchProductsByUids(
      skus,
      ["DIETARY_PROFILE"],
      false,
      true,
      storeIdentifier,
      productSlotDate,
      slotDateTime,
      reservationType,
      isSlotLocked,
      flexiStores
    );

    dispatch(handleUnauthorized(result));

    if (result.isSuccess()) {
      if (
        result.data.errors &&
        result.data.errors.filter(error => error.code === ProductErrorCode.DIETARY_PROFILE_ERROR).length > 0
      ) {
        dispatch(DietaryProfileErrorAction());
      }
      dispatch(
        fetchBasketProductsSuccessActionCreator(mapProducts(productSource, result.data.products), productSource)
      );
    } else {
      dispatch(fetchBasketProductsErrorAction());
    }
  };
