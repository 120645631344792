import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { optInRecurringSlotThunk, optOutRecurringSlotThunk } from "./RecurringSlot.actions";
import {
  OptOutRecurringSlotActionType,
  RecurringSlotPreferenceActionTypes,
  OptInRecurringSlotActionType,
} from "./RecurringSlot.types";

export const recurringSlotMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) =>
  (next: Dispatch<any>) =>
  async (action: OptInRecurringSlotActionType | OptOutRecurringSlotActionType) => {
    switch (action.type) {
      case RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT:
        const optInRecurringSlotActionType = action as OptInRecurringSlotActionType;

        api.dispatch(
          optInRecurringSlotThunk(
            optInRecurringSlotActionType.slotStartTime,
            optInRecurringSlotActionType.slotEndTime,
            optInRecurringSlotActionType.frequency,
            optInRecurringSlotActionType.storeIdentifier,
            optInRecurringSlotActionType.addressId
          )
        );

        break;

      case RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT:
        api.dispatch(optOutRecurringSlotThunk());
        break;

      default:
        break;
    }

    next(action);
  };
