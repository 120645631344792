import React from "react";
import { TrackClickConnected } from "../../../components/TrackClick";
import { Promotion } from "../../../domain/product";
import clsx from "clsx";
import { Link } from "@sainsburys-tech/fable";
import { ArrowRight } from "@sainsburys-tech/icons";

type MealDealPromotionProps = {
  promotion: Promotion;
  productUid: string;
  isNectar?: boolean;
  isMissedOffer?: boolean;
};

export const MealDealPromotion = ({ promotion, productUid, isNectar, isMissedOffer }: MealDealPromotionProps) => {
  return (
    <TrackClickConnected
      event={isMissedOffer ? "grouped_missed_promotion_modal" : "grouped_promotion_modal"}
      promotion={promotion}
      productUid={productUid}
    >
      <Link
        href={promotion.url}
        className={clsx("grouped-meal-deal-promotion__link", {
          "grouped-meal-deal-promotion__link--purple": isNectar,
        })}
        isStandalone
      >
        <span
          // Set aria-label to exclude the arrow icon from the screen reader
          aria-label={promotion.strapLine}
        >
          {promotion.strapLine}
          <ArrowRight width={24} height={24} />
        </span>
      </Link>
    </TrackClickConnected>
  );
};
