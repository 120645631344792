import React from "react";
import { useDispatch } from "react-redux";
import { BasicModal } from "./Modal.component";
import { ModalNames, ModalsGroupComponentProps } from "./Modal.types";
import { modalWithWrapper } from "./ModalWrapper.component";
import { clearBasketErrorActionCreator } from "../../domain/basket/basket.actions";
import { BasketErrorKeys } from "../../domain/basket/basket.types";

const ItemQuantityExceededModalWithWrapper = modalWithWrapper(BasicModal, ModalNames.MODAL_ITEM_QUANTITY_EXCEEDED);

type ItemQuantityExceededModalProps = {
  title: string;
  description: string;
  bulkOrderingHelp: ModalsGroupComponentProps["bulkOrderingHelp"];
};

export const ItemQuantityExceededModal = ({ title, description, bulkOrderingHelp }: ItemQuantityExceededModalProps) => {
  const dispatch = useDispatch();

  const clearItemQuantityExceededError = () => {
    dispatch(clearBasketErrorActionCreator(BasketErrorKeys.ITEM_QUANTITY_EXCEEDED));
  };

  return (
    <ItemQuantityExceededModalWithWrapper
      title={title}
      description={description}
      primaryButtonText="OK"
      secondaryButtonText="Find out more"
      onPrimaryButtonClick={clearItemQuantityExceededError}
      onSecondaryButtonClick={bulkOrderingHelp}
      onClose={clearItemQuantityExceededError}
    />
  );
};
