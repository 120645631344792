import React from "react";
import { Modal, FilledButton } from "@jsluna/react";
import { useDispatch } from "react-redux";
import { BasketMultiErrorMessageProps, BasketMultiErrorModalProps, ModalNames } from "./Modal.types";
import { TextButton } from "../TextButton";
import { createExternalNavigationWindow } from "../RedirectExternal/RedirectExternal";
import { BasketErrorKeys } from "../../domain/basket/basket.types";
import { clearBasketErrorActionCreator } from "../../domain/basket/basket.actions";
import { modalWithWrapper } from "./ModalWrapper.component";

const BasketMultiErrorModalWithoutWrapper = ({ errors }: BasketMultiErrorModalProps) => {
  const dispatch = useDispatch();

  const clearAddOrderToBasketErrors = () => {
    dispatch(clearBasketErrorActionCreator(BasketErrorKeys.ADD_ORDER_TO_BASKET_ERRORS));
  };

  return (
    <Modal handleClose={clearAddOrderToBasketErrors} className="basketMultiErrorModal" hideCloseButton open>
      <h3 className="basketMultiErrorModal__title">Unable to add all items</h3>
      <ul className="basketMultiErrorModal__unorderedList">
        {errors.map((error, index) => {
          const isLast = index === errors.length - 1;
          return <BasketMultiErrorMessage error={error} isLast={isLast} key={error.productName} />;
        })}
      </ul>

      <FilledButton
        fullWidth
        type="submit"
        value="OK"
        disabled={false}
        onClick={clearAddOrderToBasketErrors}
        className="basketMultiErrorMessage__primary-button"
      >
        OK
      </FilledButton>
    </Modal>
  );
};

export const BasketMultiErrorMessage = ({ error, isLast }: BasketMultiErrorMessageProps) => {
  const dispatch = useDispatch();

  const onClickNewWindow = (url: string) => () => {
    dispatch(createExternalNavigationWindow(url));
    return undefined;
  };

  return (
    <li>
      <p className="basketMultiErrorMessage__productName">{error.productName}</p>
      <p data-testid="multiple-error-detail" className="basketMultiErrorMessage__description">
        {error.detail}
      </p>

      {error.secondaryButtonText ? (
        <TextButton
          className="basketMultiErrorMessage__secondary-button"
          onClick={error.secondaryButtonUrl ? onClickNewWindow(error.secondaryButtonUrl) : undefined}
        >
          {error.secondaryButtonText}
          {/* Luna bug workaround, no support for null as children of modal */}
        </TextButton>
      ) : null}

      {!isLast && <div className="basketMultiErrorMessage__divider" />}
    </li>
  );
};

export const BasketMultiErrorModal = modalWithWrapper<BasketMultiErrorModalProps>(
  BasketMultiErrorModalWithoutWrapper,
  ModalNames.MODAL_ADD_ORDER_TO_BASKET_ERRORS
);
