export const invokeWithRetry = (
  fn: () => void,
  condition: () => boolean,
  retries: number,
  delay: number,
  onFailure: () => void = () => ({})
) => {
  if (condition()) {
    fn();
  } else {
    if (retries > 1) {
      setTimeout(() => invokeWithRetry(fn, condition, retries - 1, delay, onFailure), delay);
    } else {
      onFailure();
    }
  }
};
