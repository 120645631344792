import { matchPath } from "react-router";
import { Pathname } from "history";

const ROUTE_PREFIX = "/(shop|gol-ui|webapp/wcs/stores/servlet)";
const WCS_PREFIX = "/shop";
export const GOLUI_PREFIX = "/gol-ui";
export const GOL_RESOURCES_PREFIX = "/gol-resources";
const WCS_REQUIRED_QUERY_PARAMS = "langId=44&storeId=10151";
const WCS_REQUIRED_QUERY_PARAMS_DELIVERY_PASS = "langId=44&storeId=10201";
export const DEFAULT_STORE_ID = "Sainsburys default store";

export const OAUTH_ERROR_CODE_ACC_DISABLED = "account-disabled";
export const OAUTH_ERROR_CODE_ACC_LOCKED = "account-locked";

export const routes = {
  BROWSE: `${ROUTE_PREFIX}/groceries`,
  BROWSE_FEATURED: `${ROUTE_PREFIX}/featured/groceries/:zoneSlug/:browseIdSlug`,
  MY_ACCOUNT: `${ROUTE_PREFIX}/MyAccount`,
  MY_ORDERS: `${ROUTE_PREFIX}/my-account/orders`,
  MY_COUPONS: `${ROUTE_PREFIX}/my-account/coupons`,
  MY_NECTAR_CARD: `${ROUTE_PREFIX}/my-account/my-nectar-card`,
  EDIT_NECTAR_CARD: `${ROUTE_PREFIX}/my-account/my-nectar-card/edit`,
  VERIFY_NECTAR_CARD: `${ROUTE_PREFIX}/my-account/my-nectar-card/verify`,
  SIGNUP_ON_NECTAR: `${ROUTE_PREFIX}/my-account/my-nectar-card/signup`,
  ORDER_DETAILS: `${ROUTE_PREFIX}/my-account/orders/:orderId`,
  PAYMENT_CARDS: `${ROUTE_PREFIX}/my-account/payment-cards`,
  ADD_PAYMENT_CARD: `${ROUTE_PREFIX}/my-account/payment-cards/add`,
  PAYMENT_CARDS_REDIRECT: `${ROUTE_PREFIX}/my-account/payment-cards/redirect`,
  MY_ADDRESSES: `${ROUTE_PREFIX}/my-account/addresses`,
  CHECK_POSTCODE: `${ROUTE_PREFIX}/CheckPostcode`,
  CHECK_POSTCODE_REGISTRATION: `${ROUTE_PREFIX}/CheckPostcode/Registration`,
  PREVIOUS_ORDERS: `${ROUTE_PREFIX}/PreviousOrders`,
  PRODUCT_DETAILS: `${ROUTE_PREFIX}/Product/**`,
  LOGON_VIEW: `${ROUTE_PREFIX}/LogonView`,
  LOGON_VIEW_IDENTIFIER: `${ROUTE_PREFIX}/LogonView/Identifier`,
  LOGON_VIEW_PASSWORD: `${ROUTE_PREFIX}/LogonView/Password`,
  LOGON_VIEW_PASSWORD_RESET: `${ROUTE_PREFIX}/LogonView/PasswordReset`,
  OAUTH: `${ROUTE_PREFIX}/oauth/*`,
  OAUTH_REDIRECT: `${ROUTE_PREFIX}/oauth/redirect`,
  OAUTH_LOGIN_WITH_TOKEN: `${ROUTE_PREFIX}/oauth/redirect-token`,
  OAUTH_LOGIN: `${ROUTE_PREFIX}/oauth/login`,
  OAUTH_LOGOUT: `${ROUTE_PREFIX}/oauth/logout`,
  OAUTH_LOGOUT_REDIRECT: `${ROUTE_PREFIX}/oauth/logout-redirect`,
  OAUTH_REFRESH: `${ROUTE_PREFIX}/oauth/refresh`,
  OAUTH_REGISTRATION_CONFIRMATION: `${ROUTE_PREFIX}/oauth/register/confirmation`,
  OAUTH_PARTNER_LOGIN: `${ROUTE_PREFIX}/oauth/partner-login`,
  PROGRESSIVE_REGISTRATION: `${ROUTE_PREFIX}/ProgressiveRegistration`,
  PROGRESSIVE_V2_REGISTRATION: `${ROUTE_PREFIX}/oauth/register`,
  FAVOURITES: `${ROUTE_PREFIX}/favourites-as-list`,
  YOUR_RECIPES_FAVOURITES: `${ROUTE_PREFIX}/YourRecipes`,
  SEARCH_RESULTS: `${ROUTE_PREFIX}/SearchDisplayView`,
  SEARCH_RESULTS_FINDABILITY: `${ROUTE_PREFIX}/SearchResults/:term`,
  SEARCH_RESULTS_FINDABILITY_WITH_CATEGORIES: `${ROUTE_PREFIX}/SearchResults/:term/category::category?/sort::sort?`,
  BRAND_SHELF: `${ROUTE_PREFIX}/brands/:brand`,
  CHECKOUT_LOGIN: `${ROUTE_PREFIX}/MyTrolleyCheckout`,
  CHECKOUT_LOGIN_IDENTIFIER: `${ROUTE_PREFIX}/MyTrolleyCheckout/Identifier`,
  CHECKOUT_LOGIN_PASSWORD: `${ROUTE_PREFIX}/MyTrolleyCheckout/Password`,
  ERROR: `${ROUTE_PREFIX}/InternalServerError`,
  RECURRING_SLOT: `${ROUTE_PREFIX}/recurring-slot`,
  BOOKING_CONFIRMATION: `${ROUTE_PREFIX}/slot/confirmation`,
  OAUTH_ERROR: `${ROUTE_PREFIX}/oauth/error/:errorCode`,
  TROLLEY: `${ROUTE_PREFIX}/trolley`,
  PRODUCT_LISTER: `${ROUTE_PREFIX}/ProductLister`,
  PRODUCT_LISTER_SEO: `${ROUTE_PREFIX}/ProductRange`,
  NECTAR_DESTINATION: `${ROUTE_PREFIX}/nectar`,
  NECTAR_PRICES_DESTINATION: `${ROUTE_PREFIX}/nectar-prices`,
  NECTAR_OFFERS_LEGACY: `${ROUTE_PREFIX}/nectar-offers`, // remove this once removed from WCS
  OFFERS: `${ROUTE_PREFIX}/offers`,
  OFFERS_ZONE_PAGE: `${ROUTE_PREFIX}/offers/:zonePath`,
  NECTAR_ABOUT: `${WCS_PREFIX}/gb/groceries/new---trending/nectar--`,
  CHECKOUT_BEFORE_YOU_GO: `${ROUTE_PREFIX}/checkout/before-you-go`,
  CHECKOUT_FORGOTTEN_FAVOURITES: `${ROUTE_PREFIX}/checkout/forgotten-favourites`,
  CHECKOUT_REDIRECT: `${ROUTE_PREFIX}/checkout/redirect`,
  BOOK_SLOT: `${ROUTE_PREFIX}/slot/book`,
  HOME_DELIVERY_BOOK: `${ROUTE_PREFIX}/home-delivery/book`, // used temporarily for testing xmas grid
  CLICK_AND_COLLECT_BOOK: `${ROUTE_PREFIX}/click-and-collect/book`,
  CLICK_AND_COLLECT_CONFIRMATION: `${ROUTE_PREFIX}/click-and-collect/confirmation`,
  CLICK_AND_COLLECT_ABOUT: `${ROUTE_PREFIX}/click-and-collect/about`,
  BANNERX_PREVIEW: `${ROUTE_PREFIX}/banner-preview/bannerx`,
  DELIVERY_PASS_RENEWAL_OPTIONS: `${ROUTE_PREFIX}/delivery-pass/renewal-options`,
  RECIPE_SCRAPBOOK: `${ROUTE_PREFIX}/recipes/scrapbooks/:scrapbookId`,
  ARTICLE: `${ROUTE_PREFIX}/recipes/articles/:articleId`,
  RECIPES_MEAL_PLANNER: `${ROUTE_PREFIX}/recipes/meal-planner`, // meal planner temporary route
  RECIPES_MEAL_PLANNER_CREATE: `${ROUTE_PREFIX}/recipes/meal-planner/create`, // meal planner temporary route
  RECIPES_MEAL_PLANNER_PLAN: `${ROUTE_PREFIX}/recipes/meal-planner/:planID`, // meal planner temporary route
  RECIPE: `${ROUTE_PREFIX}/recipes/:recipeId`,
  RECIPES_SEARCH_RESULTS: `${ROUTE_PREFIX}/recipes/SearchResults/:searchTerm/:searchkey?`,
  CHECKOUT_SUMMARY: `${ROUTE_PREFIX}/checkout/summary`,
  CHECKOUT_PAYMENT_AUTHORISATION: `${ROUTE_PREFIX}/checkout/payment/authorisation`,
  CHECKOUT_PAYMENT_REDIRECT: `${ROUTE_PREFIX}/checkout/payment/redirect`,
  ORDER_CONFIRMATION: `${ROUTE_PREFIX}/checkout/order-confirmation`,
  RECIPES_PAGES: `${ROUTE_PREFIX}/recipes`,
  RECIPES_BROWSE: `${ROUTE_PREFIX}/recipes/browse`,
  RECIPES_BROWSE_RESULTS_LEVEL_TWO: `${ROUTE_PREFIX}/recipes/browse/:levelOne/:levelTwo`,
  RECIPES_BROWSE_RESULTS_LEVEL_ONE: `${ROUTE_PREFIX}/recipes/browse/:levelOne/`,
  RECIPES_HOMEPAGE: `${ROUTE_PREFIX}/recipes`,
  MULTI_SEARCH_PAGE: `${ROUTE_PREFIX}/search-a-list-of-items`,
  CHANGES_TO_TROLLEY: `${ROUTE_PREFIX}/changes-to-trolley`,
  MEAL_DEAL: `${ROUTE_PREFIX}/meal-deal`,
  EVENTS: `${ROUTE_PREFIX}/events/:eventId`,
  FEATURES: `${ROUTE_PREFIX}/features/:eventId`,
  MEAL_DEAL_BUILDER: `${ROUTE_PREFIX}/meal-deal/:mealDealId`,
  BRANDS: `${ROUTE_PREFIX}/brands`,
  CYSM: `${ROUTE_PREFIX}/shopping-method`,
  POSTCODE_CHECK: `${ROUTE_PREFIX}/postcode-check`,
  PROMO_LISTER: `${ROUTE_PREFIX}/promo-lister/:promoId`,
  XMAS_SLOT_GUIDE: `${ROUTE_PREFIX}/christmas-slot-guide`,
  DELIVERY_PASS_CHECKOUT_SUMMARY: `${ROUTE_PREFIX}/delivery-pass/checkout/summary`,
  DELIVERY_PASS_CHECKOUT_REDIRECT: `${ROUTE_PREFIX}/delivery-pass/payment/redirect`,
};

export const urls = {
  GROCERIES_URL: `${WCS_PREFIX}/gb/groceries`,
  DISCOVER_URL: `${WCS_PREFIX}/gb/groceries/discover`,
  BROWSE: `${GOLUI_PREFIX}/groceries`,
  MY_ACCOUNT: `${GOLUI_PREFIX}/MyAccount`,
  MY_ACCOUNT_ADDRESS: `${WCS_PREFIX}/MyAccountAddress?isCheckout=false&${WCS_REQUIRED_QUERY_PARAMS}`,
  WCS_CHECK_ADDRESS: `/webapp/wcs/stores/servlet/CheckAddressView?${WCS_REQUIRED_QUERY_PARAMS}`,
  FAVOURITES: `${GOLUI_PREFIX}/favourites-as-list`,
  YOUR_RECIPES_FAVOURITES: `${GOLUI_PREFIX}/YourRecipes`,
  SEARCH_RESULTS: `${GOLUI_PREFIX}/SearchDisplayView`,
  SEARCH_RESULTS_FINDABILITY: `${GOLUI_PREFIX}/SearchResults`,
  BRAND_SHELF: `${GOLUI_PREFIX}/brandpage`,
  SEARCH_MULTI_PRODUCTS: `${WCS_PREFIX}/ShoppingListDisplay?action=ShoppingListDisplay&${WCS_REQUIRED_QUERY_PARAMS}`,
  PREVIOUS_ORDERS: `${GOLUI_PREFIX}/PreviousOrders`,
  MY_ORDERS: `${GOLUI_PREFIX}/my-account/orders`,
  MY_COUPONS: `${GOLUI_PREFIX}/my-account/coupons`,
  PAYMENT_CARDS: `${GOLUI_PREFIX}/my-account/payment-cards`,
  ADD_PAYMENT_CARD: `${GOLUI_PREFIX}/my-account/payment-cards/add`,
  PAYMENT_CARDS_REDIRECT: `${GOLUI_PREFIX}/my-account/payment-cards/redirect`,
  MY_ADDRESSES: `${GOLUI_PREFIX}/my-account/addresses`,
  LOGON_VIEW: `${WCS_PREFIX}/LogonView`,
  LOGON_VIEW_PASSWORD: `${WCS_PREFIX}/LogonView/Password`,
  LOGON_VIEW_IDENTIFIER: `${WCS_PREFIX}/LogonView/Identifier`,
  LOGON_VIEW_PASSWORD_RESET: `${GOLUI_PREFIX}/LogonView/PasswordReset`,
  OAUTH_REDIRECT: `${GOLUI_PREFIX}/oauth/redirect`,
  OAUTH_LOGIN: `${GOLUI_PREFIX}/oauth/login`,
  OAUTH_LOGOUT: `${GOLUI_PREFIX}/oauth/logout`,
  OAUTH_LOGOUT_REDIRECT: `${GOLUI_PREFIX}/oauth/logout-redirect`,
  OAUTH_REFRESH: `${GOLUI_PREFIX}/oauth/refresh`,
  CHECKOUT_LOGIN_PASSWORD: `${WCS_PREFIX}/MyTrolleyCheckout/Password`,
  CHECK_POSTCODE: `${WCS_PREFIX}/CheckPostcode`,
  CHECK_POSTCODE_REGISTRATION: `${GOLUI_PREFIX}/CheckPostcode/Registration`,
  CHECK_POSTCODE_BOOK_SLOT: `${GOLUI_PREFIX}/CheckPostcode/SlotOption`,
  REGISTRATION_EMAIL_VERIFICATION: `${GOLUI_PREFIX}/RegistrationEmailVerification`,
  CHECKOUT_LOGIN_IDENTIFIER: `${WCS_PREFIX}/MyTrolleyCheckout/Identifier`,
  PROGRESSIVE_REGISTRATION: `${WCS_PREFIX}/ProgressiveRegistration`,
  REGISTRATION_CONFIRMATION: `${WCS_PREFIX}/NewRegistrationSuccessView`,
  PROGRESSIVE_V2_REGISTRATION: `${GOLUI_PREFIX}/oauth/register`,
  OAUTH_REGISTRATION_CONFIRMATION: `${GOLUI_PREFIX}/oauth/register/confirmation`,
  PRODUCT_DETAILS: `${GOLUI_PREFIX}/Product`,
  ERROR_PAGE_URL: `${WCS_PREFIX}/InternalServerError`,
  GROCERIES_MOBILE_URL: `${WCS_PREFIX}/gb/groceries/MobileHomeView?${WCS_REQUIRED_QUERY_PARAMS}`,
  CHECKOUT_REGISTER: `${WCS_PREFIX}/QuickRegistrationFormView?${WCS_REQUIRED_QUERY_PARAMS}&previousPageName=Checkout`,
  CHECKOUT_FLOW: `${WCS_PREFIX}/MyTrolleyCheckout?${WCS_REQUIRED_QUERY_PARAMS}`,
  RESET_PASSWORD_GOL: `${WCS_PREFIX}/ResetPasswordRegisterView?${WCS_REQUIRED_QUERY_PARAMS}`,
  PRODUCTTILE_PROMOTION: `${WCS_PREFIX}/PromotionDisplayView?${WCS_REQUIRED_QUERY_PARAMS}`,
  BOOK_DELIVERY_SLOT: `${WCS_PREFIX}/BookingDeliverySlotDisplayView?${WCS_REQUIRED_QUERY_PARAMS}`,
  CLICK_AND_COLLECT: `${GOLUI_PREFIX}/click-and-collect/book`,
  CLICK_AND_COLLECT_ABOUT: `${GOLUI_PREFIX}/click-and-collect/about`,
  CLICK_AND_COLLECT_CONFIRMATION: `${GOLUI_PREFIX}/click-and-collect/confirmation`,
  FAVOURITE_OFFERS: `${GOLUI_PREFIX}/favourites-as-list?filters[nav_Filter-Your-Results]=Offers`,
  DELIVERY_PASS: `${WCS_PREFIX}/gb/saver`,
  MY_DELIVERY_PASS: `${WCS_PREFIX}/MyDeliveryPassView?existingUser=true&langId=44&storeId=10201`,
  DELIVERY_PASS_VOUCHER: `${WCS_PREFIX}/AddVoucherCodeView?${WCS_REQUIRED_QUERY_PARAMS_DELIVERY_PASS}`,
  DELIVERY_PASS_TCS_AND_CS: `${WCS_PREFIX}/DeliveryPassTCView?catalogId=10021${WCS_REQUIRED_QUERY_PARAMS_DELIVERY_PASS}`,
  DELIVERY_PASS_COOLING_OFF: `${WCS_PREFIX}/DeliveryPassCoolOffView?${WCS_REQUIRED_QUERY_PARAMS_DELIVERY_PASS}`,
  BOOK_COLLECTION_SLOT: `${WCS_PREFIX}/CncBookDeliverySlotDisplayView?${WCS_REQUIRED_QUERY_PARAMS}`,
  COLLECTION_LOCATION_URL: `${WCS_PREFIX}/SelectCncLocationView?${WCS_REQUIRED_QUERY_PARAMS}`,
  POSTCODE_CHECK_THEN_REGISTER: `${WCS_PREFIX}/PostcodeCheckView?${WCS_REQUIRED_QUERY_PARAMS}`,
  QUICK_REGISTRATION: `${WCS_PREFIX}/QuickRegistrationFormView?${WCS_REQUIRED_QUERY_PARAMS}`,
  CHANGES_TO_TROLLEY: `${WCS_PREFIX}/BookDeliverySlot?messageAreaId=bookDeliveryMessageArea&${WCS_REQUIRED_QUERY_PARAMS}`,
  BOOK_SLOT: `${GOLUI_PREFIX}/slot/book`,
  BOOK_HOME_DELIVERY: `${GOLUI_PREFIX}/home-delivery/book`,
  BOOK_CLICK_AND_COLLECT: `${GOLUI_PREFIX}/click-and-collect/book`,
  BOOKING_CONFIRMATION: `${GOLUI_PREFIX}/slot/confirmation`,
  CHANGES_TO_TROLLEY_GOLUI: `${GOLUI_PREFIX}/changes-to-trolley`,
  TROLLEY: `${GOLUI_PREFIX}/trolley`,
  PRODUCT_LISTER: `${GOLUI_PREFIX}/ProductLister`,
  PRODUCT_LISTER_SEO: `${GOLUI_PREFIX}/ProductRange`,
  CHECKOUT_BEFORE_YOU_GO: `${GOLUI_PREFIX}/checkout/before-you-go`,
  CHECKOUT_FORGOTTEN_FAVOURITES: `${GOLUI_PREFIX}/checkout/forgotten-favourites`,
  CHECKOUT_REDIRECT: `${GOLUI_PREFIX}/checkout/redirect`,
  DELIVERY_PASS_RENEWAL_OPTIONS: `${GOLUI_PREFIX}/delivery-pass/renewal-options`,
  WCS_BOOK_SLOT: `/webapp/wcs/stores/servlet/BookDeliverySlotDisplayView?${WCS_REQUIRED_QUERY_PARAMS}`,
  WCS_BROWSE_PRODUCTS: `${WCS_PREFIX}/BrowseAlternateProductsPageView?${WCS_REQUIRED_QUERY_PARAMS}`,
  RECIPES_SEARCH_RESULTS: `${GOLUI_PREFIX}/recipes/SearchResults`,
  RECIPE: `${GOLUI_PREFIX}/recipes`,
  ARTICLE: `${GOLUI_PREFIX}/recipes/articles`,
  SCRAPBOOK: `${GOLUI_PREFIX}/recipes/scrapbooks`,
  RECIPES_BROWSE: `${GOLUI_PREFIX}/recipes/browse`,
  MULTI_SEARCH_RESULTS: `${GOLUI_PREFIX}/SearchResults`,
  MULTI_SEARCH: `${GOLUI_PREFIX}/search-a-list-of-items`,
  WCS_ORDER_CALCULATE: `${WCS_PREFIX}/OrderCalculate?${WCS_REQUIRED_QUERY_PARAMS}&URL=OrderBillingView&isCheckout=true&calculationUsage=-1&calculationUsage=-2&calculationUsage=-3&calculationUsage=-7&calculationUsageId=-1&calculationUsageId=-2&calculationUsageId=-3&calculationUsageId=-5&calculationUsageId=-7&calculationUsageId=-9`,
  CHECKOUT_SUMMARY: `${GOLUI_PREFIX}/checkout/summary`,
  CHECKOUT_PAYMENT_AUTHORISATION: `${GOLUI_PREFIX}/checkout/payment/authorisation`,
  CHECKOUT_PAYMENT_REDIRECT: `${GOLUI_PREFIX}/checkout/payment/redirect`,
  ORDER_CONFIRMATION: `${GOLUI_PREFIX}/checkout/order-confirmation`,
  WCS_XMAS_PAGE: `${WCS_PREFIX}/gb/groceries/Christmas?fromMegaNav=1`,
  WCS_ADD_PAYMENT_CARD: `${WCS_PREFIX}/MyCardsAdd?goluiCheckout=true&${WCS_REQUIRED_QUERY_PARAMS}`,
  WCS_PAYMENT_CARDS: `${WCS_PREFIX}/MyCards?${WCS_REQUIRED_QUERY_PARAMS}`,
  ENCLOSED_VOUCHER_VIEW: `/webapp/wcs/stores/servlet/EnclosedVoucherView?${WCS_REQUIRED_QUERY_PARAMS}`,
  WCS_OFFERS: `${WCS_PREFIX}/gb/groceries/great-offers`,
  MY_NECTAR_CARD: `${GOLUI_PREFIX}/my-account/my-nectar-card`,
  EDIT_NECTAR_CARD: `${GOLUI_PREFIX}/my-account/my-nectar-card/edit`,
  VERIFY_NECTAR_CARD: `${GOLUI_PREFIX}/my-account/my-nectar-card/verify`,
  SIGNUP_ON_NECTAR: `${GOLUI_PREFIX}/my-account/my-nectar-card/signup`,
  NECTAR_DESTINATION: `${GOLUI_PREFIX}/nectar`,
  OFFERS: `${GOLUI_PREFIX}/offers`,
  OFFERS_NECTAR_PRICES: `${GOLUI_PREFIX}/offers/nectar-prices`,
  FAVOURITES_NECTAR_PRICES: `${WCS_PREFIX}/gb/groceries/favourites`,
  BRANDS: `${GOLUI_PREFIX}/brands`,
  EVENTS: `${GOLUI_PREFIX}/events`,
  FEATURES: `${GOLUI_PREFIX}/features`,
  MULTIBUYS: `${GOLUI_PREFIX}/features/multibuys`,
  RECIPES_MEAL_PLANNER: `${GOLUI_PREFIX}/recipes/meal-planner`, // meal planner temporary route
  RECIPES_MEAL_PLANNER_CREATE: `${GOLUI_PREFIX}/recipes/meal-planner/create`,
  ABOUT_HOME_DELIVERY: `${WCS_PREFIX}/gb/groceries/discoverhome/shopping-online/delivery`,
  CYSM: `${GOLUI_PREFIX}/shopping-method`,
  MEAL_DEAL_BUILDER: `${GOLUI_PREFIX}/meal-deal`,
  OCCASIONS: `${GOLUI_PREFIX}/groceries/occasions-by-sainsburys/c:1046171`,
  MY_PREFERENCES: `${WCS_PREFIX}/MyPreferences?${WCS_REQUIRED_QUERY_PARAMS}`,
  DELIVERY_PASS_CHECKOUT_SUMMARY: `${GOLUI_PREFIX}/delivery-pass/checkout/summary`,
  DELIVERY_PASS_ORDER_CONFIRMATION: `${GOLUI_PREFIX}/delivery-pass/checkout/order-confirmation`,
};

export type RouteKeys = keyof typeof routes;

export const matchesPath = (currentPath: Pathname, underTest: string, exact: boolean = true): boolean =>
  Boolean(
    matchPath(currentPath, {
      path: underTest,
      exact,
    })
  );

export const externalUrls = {
  RECURRING_SLOT_TERMS_CONDITIONS: "https://help.sainsburys.co.uk/help/terms-and-conditions/my-regular-shop-slot",
  RESET_PASSWORD_NECTAR: "https://nectar.com/id/start/enter-card-number",
  RESET_PASSWORD_SELECT: "https://select.sainsburys.co.uk/reset-password", // TODO: confirm when id work done
  BULK_ORDERING_HELP: "https://help.sainsburys.co.uk/help/ordering/bulk-order-online",
  MHRA_SAINSBURYS: "https://medicine-seller-register.mhra.gov.uk/search-registry/168",
  NEW_TRENDING: "https://www.sainsburys.co.uk/shop/gb/groceries/new---trending",
  BUYING_ONLINE: "https://help.sainsburys.co.uk/help/terms-and-conditions/buying-online",
  JOIN_NECTAR: "https://www.nectar.com/id/start/nectar-card-check",
  REGISTER_NECTAR: "https://www.nectar.com/id/start/enter-collector-id",
  NECTAR_CARD_CHECK: "https://www.nectar.com/id/start/nectar-card-check",
  GET_NECTAR_APP: "https://nectar.com/nectar-app",
  TERMS_AND_CONDITIONS: "https://www.sainsburys.co.uk/terms",
  NECTAR_DESTINATION_TERMS_AND_CONDITIONS: "https://www.nectar.com/about/privacy-and-legal/nectar-prices",
  NECTAR_BONUS_POINTS_TERMS_AND_CONDITIONS:
    "https://help.sainsburys.co.uk/help/terms-and-conditions/digitalnectarcoupons-t-cs",
  CHOP_CHOP: "https://chopchopapp.co.uk/",
};

export function registrationMoreDetails(postcode: string): string {
  return `${WCS_PREFIX}/RegistrationMoreDetailsFormView?
    ${WCS_REQUIRED_QUERY_PARAMS}&Shipping_zipCode=${postcode}`;
}

export const categoryView = (url: string): string => {
  const prefixedUrl = url.startsWith("/") ? url : `/${url}`;

  return prefixedUrl.startsWith(GOLUI_PREFIX)
    ? prefixedUrl
    : `${WCS_PREFIX}${prefixedUrl}?${WCS_REQUIRED_QUERY_PARAMS}`;
};

export const getChooseShoppingMethodUrl = (): string => {
  const refererParam = `currentPageUrl=${encodeURIComponent(window.location.href)}`;
  return `${urls.CYSM}?${refererParam}`;
};

export const getWCSConfirmAmendOrderUrl = (orderId: string): string =>
  `${WCS_PREFIX}/OrderAmendConfirmView?orderId=${orderId}&catalogId=10162&langId=44&storeId=10151`;

export const getWCSOrderConfirmationUrl = (orderId?: string): string =>
  `/webapp/wcs/stores/servlet/OrderShippingBillingConfirmationView?${WCS_REQUIRED_QUERY_PARAMS}&catalogId=10162&orderId=${orderId}`;
