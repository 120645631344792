import { ContentPage } from "../../../../services/contentTypes";
import { InterleavedBannerAdV2 } from "../../../../components/Citrus/BannerAd/BannerAd.types";

export const getBannerAdsV2GridConfig = (
  sequence: number[],
  options: string[],
  page: ContentPage
): InterleavedBannerAdV2[] => {
  const placeholders: InterleavedBannerAdV2[] = sequence.map((individualSeq, index) => ({
    placement: options[index],
    categoriesPosition: { insertSequence: individualSeq, rowIndex: 0 },
    insertSequence: individualSeq,
    page,
  }));

  return [...placeholders];
};
