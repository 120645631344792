import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { PageViewProperties } from "../../common/analytics/analytics";
import { AnalyticsActionTypes, AnalyticsPageViewAction } from "../../common/analytics/types";

export class InvokeOnMount extends React.Component<AnalyticsPageViewProps & { onMount: () => any }> {
  componentDidMount() {
    this.props.onMount();
  }

  componentDidUpdate(prevProps: any) {
    this.props.onMount();
    if (this.props.page !== prevProps.page) {
      this.props.onMount();
    }
  }

  render() {
    return null;
  }
}

type AnalyticsPageViewProps = {
  page: PageViewProperties;
};

const analyticsPageViewAction = (page: PageViewProperties) => (): AnalyticsPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page,
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: AnalyticsPageViewProps) =>
  bindActionCreators(
    {
      onMount: analyticsPageViewAction(ownProps.page),
    },
    dispatch
  );

export const AnalyticsPageViewEvent = connect(null, mapDispatchToProps)(InvokeOnMount);
