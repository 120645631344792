import React, { useEffect } from "react";
import { MiniTrolleyComponent } from "./MiniTrolley.component";
import { useBasket } from "../../../domain/basket/basket";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../common/store";
import { matchesPath, routes, urls } from "../../../routes";
import { push } from "connected-react-router";
import { DataState } from "../../../common/dataState";
import {
  convertToFormatCurrency,
  selectMiniTrolleyProducts,
  selectSavings,
  selectSubtotal,
} from "./MiniTrolley.selectors";

import { useSlotReservation } from "../../../domain/slot/slotReservation";
import { useResponsive } from "../../ResponsiveProvider";
import { useCheckout } from "../../../domain/checkout/checkout";
import { SlotReservationType } from "../../../domain/slot/slotReservation.types";
import { getBasketProducts } from "../../../views/Trolley/trolley.actions";
import { ProductSource } from "../../../domain/product";
import { featureFlags } from "../../../common/firebase";

export const MiniTrolleyContainer = () => {
  const {
    basket,
    dataState: basketDataState,
    checkoutEnabled,
    emptyBasket,
    fetchBasket,
    isAllItemsAmendable,
  } = useBasket();
  const { slotReservation } = useSlotReservation();
  const { reservationExpired, reservationType } = slotReservation;
  const hasValidSlot = !reservationExpired && reservationType !== SlotReservationType.NONE;
  const products = useSelector((state: State) => selectMiniTrolleyProducts(state));
  const displayMinimumSpend = basket.itemCount > 0 && !basket.hasExceededMinimumSpend && hasValidSlot;
  const isDeliverySlotConfirmationRoute = matchesPath(location.pathname, routes.BOOKING_CONFIRMATION);
  const isCollectionSlotConfirmationRoute = matchesPath(location.pathname, routes.CLICK_AND_COLLECT_CONFIRMATION);
  const { isMobile } = useResponsive();
  const isSlotLocked = basket.isSlotLocked ?? false;

  const isBasketLoading = basketDataState === DataState.PENDING || basketDataState === DataState.UNKNOWN;
  const dispatch = useDispatch();
  const { startCheckout, error } = useCheckout();

  useEffect(() => {
    if (isDeliverySlotConfirmationRoute || isCollectionSlotConfirmationRoute) {
      fetchBasket(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotReservation]);

  useEffect(() => {
    if (basket.itemCount > 0) {
      const skus = products.map(item => item.product.sku);
      dispatch(getBasketProducts(skus, ProductSource.TROLLEY, isSlotLocked));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket.itemCount, isSlotLocked]);

  const isNewHeader = featureFlags.get("new_global_header");
  if (isNewHeader) {
    if (basket.itemCount === 0) {
      return null;
    }
  }

  return (
    <MiniTrolleyComponent
      isMobile={isMobile}
      trolleyUrl={urls.TROLLEY}
      count={basket.itemCount}
      savings={selectSavings(basket.savings)}
      subtotal={selectSubtotal(basket.subTotal)}
      items={products}
      displayMinimumSpend={displayMinimumSpend}
      minimumSpend={convertToFormatCurrency(basket.minimumSpend)}
      enableCheckout={checkoutEnabled}
      hasSlot={hasValidSlot}
      dispatchInternalNavigation={() => dispatch(push(urls.TROLLEY))}
      dispatchEmptyTrolley={emptyBasket}
      isLoading={isBasketLoading}
      startCheckout={() => startCheckout(basket.isInAmendMode, basket.basketId)}
      error={error}
      isAllItemsAmendable={isAllItemsAmendable}
    />
  );
};
