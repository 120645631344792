import { missionsConsentsTypes, ProgressiveRegistrationForm } from "./types";
import { ErrorActionTypes } from "../../components/Error/error.actions";
import { getAuthCookieValue } from "../../utils/cookie";
import { ProgressiveRegistrationRequest } from "../../services/customer";

export interface ErrorDetailsType {
  code: string;
  detail: string;
}

export const handleErrors = async (response: Response): Promise<ErrorDetailsType> => {
  let errors: ErrorDetailsType[] = [];

  if (response.status < 500) {
    try {
      errors = (await response.json()).errors.map(({ code, detail }: ErrorDetailsType) => ({ code, detail }));
    } catch (error) {
      errors.push({
        code: "JSON_DECODING_ERROR",
        detail: "The request is not valid.",
      });
    }
  } else {
    errors.push({
      code: ErrorActionTypes.UNHANDLED_API_ERROR,
      detail: "Internal server error.",
    });
  }

  return Promise.reject(errors);
};

const getConsentValue = (areAllOptionsSelected: boolean, option: boolean) => (areAllOptionsSelected ? true : option);

export const convertCPMFieldToRequest = ({
  cpmSelectedOption,
  ...cpmFormValues
}: Partial<ProgressiveRegistrationForm>) =>
  Object.keys(missionsConsentsTypes).map(key => ({
    mission: key,
    consent: getConsentValue(cpmSelectedOption === "yes", cpmFormValues[missionsConsentsTypes[`${key}`]]),
  }));

export const convertFormValuesToRequestBody = ({
  mobileNumber,
  postcode,
  termsChecked,
  password,
  verifiedEmail,
  ...cpm
}: ProgressiveRegistrationForm): ProgressiveRegistrationRequest => {
  const requestBody: ProgressiveRegistrationRequest = {
    mobile: mobileNumber,
    postcode,
    agreement: termsChecked,
    username: verifiedEmail,
    password,
  };

  const { cpmSelectedOption, ...options } = cpm;
  const shouldSendConsents = Object.keys(options).every(key => options[`${key}`] != null);

  if (shouldSendConsents) {
    if (cpmSelectedOption) {
      requestBody.consents = convertCPMFieldToRequest(cpm);
    } else {
      requestBody.consents = [];
    }
  }

  return requestBody;
};

const url = "/groceries-api/gol-services/customer";

export const callProgressiveRegistrationEndpoint = async (
  formvalues: ProgressiveRegistrationForm
): Promise<ErrorDetailsType | null> => {
  let response: Response;
  try {
    response = await window.fetch(`${url}/customer/progressive-registration`, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        WCAuthToken: encodeURIComponent(getAuthCookieValue() || ""),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(convertFormValuesToRequestBody(formvalues)),
    });

    if (response.status >= 200 && response.status < 400) {
      return null;
    }

    return handleErrors(response);
  } catch (err) {
    return Promise.reject({
      code: "FETCH_FAILURE",
      detail: "You seem to have an issue with your connection, please try again later.",
    });
  }
};

export const getMissionsConsents = async (email: string): Promise<{}> => {
  let response: Response;
  try {
    response = await window.fetch(`${url}/consents/missions?email=${encodeURIComponent(email)}`, {
      credentials: "same-origin",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: null,
    });

    if (response.status >= 200 && response.status < 400) {
      return response.json();
    }

    return handleErrors(response);
  } catch (err) {
    return Promise.reject({
      code: "FETCH_FAILURE",
      detail: "You seem to have an issue with your connection, please try again later.",
    });
  }
};
