import { BotDetectState } from "./CitrusBotDetect.types";
import { getClientIp } from "./ClientIpFetch";

export const ip2long = function (ip: string) {
  let components;

  if ((components = ip.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/))) {
    let iplong = 0;
    let power = 1;
    for (let i = 4; i >= 1; i -= 1) {
      iplong += power * parseInt(components[i]);
      power *= 256;
    }
    return iplong;
  } else return -1;
};

export const inSubNet = (ip: string, subnet: string) => {
  if (!subnet) return false;
  const long_ip = ip2long(ip);
  const mask = subnet.match(/^(.*?)\/(\d{1,2})$/);
  if (!mask) return false;
  const base_ip = ip2long(mask[1]);
  if (mask && base_ip >= 0) {
    const freedom = Math.pow(2, 32 - parseInt(mask[2]));
    return long_ip > base_ip && long_ip < base_ip + freedom - 1;
  } else return false;
};

const getGoogleBotIps = (isIPV4: boolean) => {
  if (!isIPV4) {
    return [
      "2001:4860:4801:10::/64",
      "2001:4860:4801:11::/64",
      "2001:4860:4801:12::/64",
      "2001:4860:4801:13::/64",
      "2001:4860:4801:14::/64",
      "2001:4860:4801:15::/64",
      "2001:4860:4801:16::/64",
      "2001:4860:4801:17::/64",
      "2001:4860:4801:18::/64",
      "2001:4860:4801:19::/64",
      "2001:4860:4801:1a::/64",
      "2001:4860:4801:1b::/64",
      "2001:4860:4801:20::/64",
      "2001:4860:4801:21::/64",
      "2001:4860:4801:22::/64",
      "2001:4860:4801:23::/64",
      "2001:4860:4801:24::/64",
      "2001:4860:4801:25::/64",
      "2001:4860:4801:26::/64",
      "2001:4860:4801:27::/64",
      "2001:4860:4801:28::/64",
      "2001:4860:4801:29::/64",
      "2001:4860:4801:2::/64",
      "2001:4860:4801:2a::/64",
      "2001:4860:4801:2b::/64",
      "2001:4860:4801:2c::/64",
      "2001:4860:4801:2d::/64",
      "2001:4860:4801:2e::/64",
      "2001:4860:4801:2f::/64",
      "2001:4860:4801:30::/64",
      "2001:4860:4801:31::/64",
      "2001:4860:4801:32::/64",
      "2001:4860:4801:33::/64",
      "2001:4860:4801:34::/64",
      "2001:4860:4801:35::/64",
      "2001:4860:4801:36::/64",
      "2001:4860:4801:37::/64",
      "2001:4860:4801:38::/64",
      "2001:4860:4801:39::/64",
      "2001:4860:4801:3::/64",
      "2001:4860:4801:3a::/64",
      "2001:4860:4801:3b::/64",
      "2001:4860:4801:3c::/64",
      "2001:4860:4801:3d::/64",
      "2001:4860:4801:3e::/64",
      "2001:4860:4801:40::/64",
      "2001:4860:4801:41::/64",
      "2001:4860:4801:42::/64",
      "2001:4860:4801:43::/64",
      "2001:4860:4801:44::/64",
      "2001:4860:4801:45::/64",
      "2001:4860:4801:46::/64",
      "2001:4860:4801:47::/64",
      "2001:4860:4801:48::/64",
      "2001:4860:4801:49::/64",
      "2001:4860:4801:4a::/64",
      "2001:4860:4801:50::/64",
      "2001:4860:4801:51::/64",
      "2001:4860:4801:53::/64",
      "2001:4860:4801:60::/64",
      "2001:4860:4801:61::/64",
      "2001:4860:4801:62::/64",
      "2001:4860:4801:63::/64",
      "2001:4860:4801:64::/64",
      "2001:4860:4801:65::/64",
      "2001:4860:4801:66::/64",
      "2001:4860:4801:67::/64",
      "2001:4860:4801:68::/64",
      "2001:4860:4801:69::/64",
      "2001:4860:4801:6a::/64",
      "2001:4860:4801:6b::/64",
      "2001:4860:4801:6c::/64",
      "2001:4860:4801:6d::/64",
      "2001:4860:4801:6e::/64",
      "2001:4860:4801:6f::/64",
      "2001:4860:4801:70::/64",
      "2001:4860:4801:71::/64",
      "2001:4860:4801:72::/64",
      "2001:4860:4801:73::/64",
      "2001:4860:4801:74::/64",
      "2001:4860:4801:75::/64",
      "2001:4860:4801:76::/64",
      "2001:4860:4801:77::/64",
      "2001:4860:4801:80::/64",
      "2001:4860:4801:81::/64",
      "2001:4860:4801:82::/64",
      "2001:4860:4801:83::/64",
      "2001:4860:4801:84::/64",
      "2001:4860:4801:85::/64",
      "2001:4860:4801:86::/64",
      "2001:4860:4801:90::/64",
      "2001:4860:4801:91::/64",
      "2001:4860:4801:92::/64",
      "2001:4860:4801::/64",
      "2001:4860:4801:c::/64",
      "2001:4860:4801:f::/64",
    ];
  }
  return [
    "34.100.182.96/28",
    "34.101.50.144/28",
    "34.118.254.0/28",
    "34.118.66.0/28",
    "34.126.178.96/28",
    "34.146.150.144/28",
    "34.147.110.144/28",
    "34.151.74.144/28",
    "34.152.50.64/28",
    "34.154.114.144/28",
    "34.155.98.32/28",
    "34.165.18.176/28",
    "34.175.160.64/28",
    "34.176.130.16/28",
    "34.64.82.64/28",
    "34.65.242.112/28",
    "34.80.50.80/28",
    "34.88.194.0/28",
    "34.89.10.80/28",
    "34.89.198.80/28",
    "34.96.162.48/28",
    "35.247.243.240/28",
    "66.249.64.0/27",
    "66.249.64.128/27",
    "66.249.64.160/27",
    "66.249.64.192/27",
    "66.249.64.224/27",
    "66.249.64.32/27",
    "66.249.64.64/27",
    "66.249.64.96/27",
    "66.249.65.0/27",
    "66.249.65.128/27",
    "66.249.65.160/27",
    "66.249.65.192/27",
    "66.249.65.224/27",
    "66.249.65.32/27",
    "66.249.65.64/27",
    "66.249.65.96/27",
    "66.249.66.0/27",
    "66.249.66.128/27",
    "66.249.66.192/27",
    "66.249.66.32/27",
    "66.249.66.64/27",
    "66.249.68.0/27",
    "66.249.68.32/27",
    "66.249.68.64/27",
    "66.249.69.0/27",
    "66.249.69.128/27",
    "66.249.69.160/27",
    "66.249.69.192/27",
    "66.249.69.224/27",
    "66.249.69.32/27",
    "66.249.69.64/27",
    "66.249.69.96/27",
    "66.249.70.0/27",
    "66.249.70.128/27",
    "66.249.70.160/27",
    "66.249.70.192/27",
    "66.249.70.224/27",
    "66.249.70.32/27",
    "66.249.70.64/27",
    "66.249.70.96/27",
    "66.249.71.0/27",
    "66.249.71.128/27",
    "66.249.71.160/27",
    "66.249.71.192/27",
    "66.249.71.32/27",
    "66.249.71.64/27",
    "66.249.71.96/27",
    "66.249.72.0/27",
    "66.249.72.128/27",
    "66.249.72.160/27",
    "66.249.72.192/27",
    "66.249.72.224/27",
    "66.249.72.32/27",
    "66.249.72.64/27",
    "66.249.72.96/27",
    "66.249.73.0/27",
    "66.249.73.128/27",
    "66.249.73.160/27",
    "66.249.73.192/27",
    "66.249.73.224/27",
    "66.249.73.32/27",
    "66.249.73.64/27",
    "66.249.73.96/27",
    "66.249.74.0/27",
    "66.249.74.32/27",
    "66.249.74.64/27",
    "66.249.74.96/27",
    "66.249.75.0/27",
    "66.249.75.128/27",
    "66.249.75.160/27",
    "66.249.75.192/27",
    "66.249.75.224/27",
    "66.249.75.32/27",
    "66.249.75.64/27",
    "66.249.75.96/27",
    "66.249.76.0/27",
    "66.249.76.128/27",
    "66.249.76.160/27",
    "66.249.76.192/27",
    "66.249.76.224/27",
    "66.249.76.32/27",
    "66.249.76.64/27",
    "66.249.76.96/27",
    "66.249.77.0/27",
    "66.249.77.128/27",
    "66.249.77.32/27",
    "66.249.77.64/27",
    "66.249.77.96/27",
    "66.249.79.0/27",
    "66.249.79.128/27",
    "66.249.79.160/27",
    "66.249.79.192/27",
    "66.249.79.224/27",
    "66.249.79.32/27",
    "66.249.79.64/27",
    "66.249.79.96/27",
  ];
};

export const isValidIPV4 = (ip: string) => {
  const regexExp = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm;
  return regexExp.test(ip);
};

export const isUserGoogleBot = async (): Promise<BotDetectState> => {
  const clientIp = await getClientIp();
  if (!clientIp)
    return {
      clientIp: undefined,
      isGoogleBot: undefined,
    };
  const isIPV4 = isValidIPV4(clientIp);
  let index = 0;
  const botIpList = getGoogleBotIps(isIPV4);
  if (isIPV4) {
    while (index < botIpList.length - 1) {
      if (inSubNet(clientIp, botIpList[index]))
        return {
          clientIp,
          isGoogleBot: true,
        };
      index++;
    }
  } else {
    while (index < botIpList.length - 1) {
      if (inSubNet(clientIp, botIpList[index]))
        return {
          clientIp,
          isGoogleBot: true,
        };
      index++;
    }
  }
  return {
    clientIp,
    isGoogleBot: false,
  };
};
