import * as momentTimeZone from "moment-timezone";
import moment from "moment";
import padStart from "lodash.padstart";

export enum PartOfDay {
  Morning = "Morning",
  Afternoon = "Afternoon",
  Evening = "Evening",
}

/**
 * GolTime encapsulates time formatting across the application
 */
export default class GolTime {
  static servicesFormat = "YYYY-MM-DDTHH:mm:ssZ";
  static dateFormat = "YYYY-MM-DD";
  static zone = "Europe/London";

  private readonly time: string;

  /**
   * Create a GolTime from the services format
   * @param timestamp
   */
  static fromServices(timestamp: string): GolTime {
    return new GolTime(timestamp);
  }

  /**
   * Formats numeric seconds, e.g. format number 23 as "0:23"
   * @param sec
   */
  static formatSeconds(sec: number): string {
    const mins = Math.floor(sec / 60);
    const secs = String(sec % 60).padStart(2, "0");
    return `${mins}:${secs}`;
  }

  /**
   * Formats a time range, e.g. converts the times from two dates to "9:00 am - 1:00 pm"
   * @param start date to extract the start time from
   * @param end data to extract the end time from
   */
  static formatTimeInterval(start: string, end: string) {
    if (start && end) {
      const period =
        moment(start).tz("Europe/London").format("a") === moment(end).tz("Europe/London").format("a") ? "" : " am";
      return `${moment(start).tz("Europe/London").format("h:mm")}${period} - ${moment(end)
        .tz("Europe/London")
        .format("h:mm a")}`;
    }
    return "";
  }

  /**
   * Formats a time , e.g. converts the time to "9:00 am"
   * @param date date to extract the start time from
   */
  static formatTime(date: string) {
    if (date) {
      return `${moment(date).tz("Europe/London").format("h:mm a")}`;
    }
    return "";
  }

  static now(): GolTime {
    return new GolTime(moment().format(GolTime.servicesFormat));
  }

  static addSeconds(seconds: number): GolTime {
    return new GolTime(moment().add(seconds, "second").format(GolTime.servicesFormat));
  }

  constructor(public timestamp: string) {
    this.time = timestamp;
  }

  isAfter(t: GolTime): boolean {
    return moment(this.time).isAfter(moment(t.time));
  }

  /**
   * Gets the day of the week, e.g. "Monday"
   */
  getDayOfWeek() {
    const t = momentTimeZone.tz(this.time, GolTime.servicesFormat, "Europe/London");
    return days[t.isoWeekday() - 1]; // isoWeekDay starts counting from 1 not 0
  }

  /**
   * Formats a services time as a delivery slot date time in BST,
   * e.g. converts '2018-05-02T20:00:00Z' to '02/05/18, 9:00-10:00pm'.
   */
  formatDateTimeInterval(end: string, dateFormatter: string = "DD/MM/YY, "): string {
    const t = momentTimeZone.tz(this.time, GolTime.servicesFormat, "Europe/London");

    return t.format(dateFormatter) + this.formatTimeInterval(end);
  }

  /**
   * Formats a services time as a delivery slot time in BST,
   * e.g. converts '2018-05-02T20:00:00Z' to 9:00-10:00pm'.
   */
  formatTimeInterval(end: string): string {
    const t = momentTimeZone.tz(this.time, GolTime.servicesFormat, "Europe/London");
    const endTime = momentTimeZone.tz(end, GolTime.servicesFormat, "Europe/London");
    const hour = t.format("h");
    const minute = t.format("m");
    const endMinute = endTime.format("m");
    const prefixMinutes = t.format(`h:${padStart(minute, 2, "0")}-`);
    const suffix = t.format("a");
    if (hour === "12") {
      return `${prefixMinutes}1:00${suffix}`;
    }
    return `${prefixMinutes}${parseInt(hour, 10) + 1}${`:${padStart(endMinute, 2, "0")}`}${suffix}`;
  }

  partOfDay(): PartOfDay {
    const t = momentTimeZone.tz(this.time, GolTime.servicesFormat, "Europe/London");
    if (t.hours() < 12) {
      return PartOfDay.Morning;
    }
    if (t.hours() >= 12 && t.hours() < 17) {
      return PartOfDay.Afternoon;
    }
    return PartOfDay.Evening;
  }

  /**
   * Formats the date time as a date - "2021-21-30"
   */
  formatAsDate(): string {
    return this.format("YYYY-MM-DD");
  }

  /**
   * Formats a GolTime using the provided formatter. Always represents time using "Europe/London"
   * @param formatter is provided by consumer, but defaults to GolTime.servicesFormat
   */
  format(formatter: string = GolTime.servicesFormat): string {
    const t = momentTimeZone.tz(this.time, GolTime.servicesFormat, "Europe/London");
    return t.format(formatter);
  }

  formatToServices(): string {
    return moment(this.time).utc().format();
  }

  /**
   * Returns day in format DDst/nd/rd/th
   * EG: getOrdinalSuffix(10); // returns "10th"
   */
  static getOrdinalSuffix = (day: number): string => {
    if (day >= 11 && day <= 13) {
      return "th";
    }

    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
}

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

/**
 * Returns an array of dates from start to end and all dates in between
 * @example
 * expandDates("2021-10-19", "2021-10-25"); // returns ["2021-10-19", "2021-10-20", "2021-10-21", "2021-10-22", "2021-10-23", "2021-10-24", "2021-10-25"]
 */
export function expandDates(startDate: string, endDate: string, format: string = "YYYY-MM-DD"): string[] {
  const start = moment(startDate);
  const end = moment(endDate);
  const now = start.clone();
  const dates = [];
  while (now.isSameOrBefore(end)) {
    dates.push(now.format(format));
    now.add(1, "days");
  }
  return dates;
}
