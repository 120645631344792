import createUserManager from "./oAuth.config";

// looks up the JWT access token (v2) in the local storage, that was saved beforehand when an user logged in
export const getAccessTokenFromStorage = async (): Promise<string> => {
  const user = await createUserManager().getUser();
  if (!user || !user.access_token) {
    return "";
  }
  return user.access_token;
};
