import Cookies from "js-cookie";

export const getAuthCookieValue = (): string => {
  const cookies = Cookies.get() || {};
  let genericUserCookieValue;
  let registeredUserCookieValue;
  Object.keys(cookies).forEach(name => {
    if (name.indexOf("WC_AUTHENTICATION_") === 0 && cookies[`${name}`] !== "DEL") {
      if (name.endsWith("-1002")) {
        genericUserCookieValue = cookies[`${name}`];
      } else {
        registeredUserCookieValue = cookies[`${name}`];
      }
    }
  });
  return registeredUserCookieValue || genericUserCookieValue || "";
};

type UserConsent = {
  performance: boolean;
  functional: boolean;
  marketing: boolean;
};

export function getUserConsents(): UserConsent {
  const userConsents: UserConsent = {
    performance: false,
    functional: false,
    marketing: false,
  };

  const optanonConsent = Cookies.get("OptanonConsent");
  if (!optanonConsent) {
    return userConsents;
  }

  for (const setting of optanonConsent.split("&")) {
    const [key, value] = setting.split("=");
    if (key === "groups") {
      userConsents.performance = value.indexOf("2:1") > -1;
      userConsents.functional = value.indexOf("3:1") > -1;
      userConsents.marketing = value.indexOf("4:1") > -1;
      break;
    }
  }

  return userConsents;
}
