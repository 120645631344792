import { useCallback, useEffect, useReducer } from "react";
import { ErrorCode, Result, ServiceErrors } from "../common/http/request";
import { DataState } from "../common/dataState";
import { useIsMounted } from "../common/hooks/useIsMounted";

/**
 * Sends a request to a service and manages data state transitions for service call.
 *
 * Using this hook will send a request to the service, and will update the state based upon the result of the
 * request. This is useful for cases where you need to fetch some data to render a page or component. If you
 * need to manage exactly when the requests are sent (e.g. on clicking a button), consider using
 * useServiceRequestCallback(...) instead.
 *
 * state.status uses DataState
 *  - initially starting in the PENDING state
 *  - on response from the service, the state will transition to SUCCESS or FAILURE
 *  - when state is SUCCESS, state.data will be set to the data returned from the service
 *  - when state is FAILED, state.error will be set to the errors returned from the service
 *
 * Usage example
 *  const orderState = useServiceRequest(fetchOrderById, [orderId, true]);
 *  if (orderState.status === DataState.UNKNOWN || orderState.status === DataState.PENDING) {
 *    return <div>Loading...</div>;
 *  }
 *  if (orderState.status === DataState.FAILED) {
 *    return <div>There was an error: {orderState.error?.first().code}</div>;
 *  }
 *  return (
 *    <>
 *      <div>Order number: {orderState.data!.order_uid}</div>
 *      <div>Order type: {orderState.data!.order_type}</div>
 *    </>
 *  )
 *
 * @see ./src/services/HookDemo/HookDemo.tsx for a more comprehensive example
 * @see ./src/services/HookDemo/HookDemo.test.tsx for an example of how to test components using this hook
 * @param serviceFunc   a service function from the services client, e.g. fetchOrders or startAmendingOrder
 * @param args          these are the arguments for the service function
 * @param initialState  optional initial state, defaults to pending DataState
 * @return State        the state of the request, containing status, data returned from request and any errors
 */
export function useServiceRequest<ResultData, ServiceRequestArgs extends any[]>(
  serviceFunc: (...args: ServiceRequestArgs) => Promise<Result<ResultData>>,
  args: ServiceRequestArgs,
  initialState: State<ResultData> = { status: DataState.PENDING }
): State<ResultData> {
  const [state, doRequest] = useServiceRequestCallback(serviceFunc, initialState);

  useEffect(() => {
    doRequest(...args);
    // TO-DO: refactor when useEffectEvent is stable see https://react.dev/reference/react/experimental_useEffectEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, args);

  return state;
}

/**
 * Returns a callback to send a request to a service and manages data state transitions for service call.
 *
 * Does not call the service immediately when used, instead returns a callback to send the request. This is useful
 * for cases where you don't want to call a service when rendering component, but maybe on a different event such as
 * clicking a button. If you wish to load data when rendering a component see useServiceRequest(...)
 *
 * state.status uses DataState
 *  - initially starting in the UNKNOWN state
 *  - on calling the callback, the state transitions to PENDING
 *  - on response from the service, the state will transition to SUCCESS or FAILURE
 *  - when state is SUCCESS, state.data will be set to the data returned from the service
 *  - when state is FAILED, state.error will be set to the errors returned from the service
 *
 * Usage example
 *  const [startAmendState, startAmend] = useServiceRequestCallback(startAmendingOrder);
 *  return (
 *    <>
 *      {startAmendState.status === DataState.PENDING ? "Loading..." : ""}
 *      {startAmendState.status === DataState.FAILED ? "There was an error" : ""}
 *      <button onClick={() => startAmend(orderUID)}>Amend order</button>
 *    </>
 *  )
 *
 * @see ./src/services/HookDemo/HookDemo.tsx for a more comprehensive example
 * @see ./src/services/HookDemo/HookDemo.test.tsx for an example of how to test components using this hook
 * @param serviceFunc   a service function from the services client, e.g. fetchOrders or startAmendingOrder
 * @param initialState  optional initial state, defaults to unknown DataState
 * @return [
 *  State,    the state of the request, containing status, data returned from request and any errors
 *  callback  a callback to trigger the request to service. Args of callback are the args to the service function.
 * ]
 */

export type Callbacks = {
  success?: Function;
  error?: Function;
};

export function useServiceRequestCallback<ResultData, ServiceRequestArgs extends any[]>(
  serviceFunc: (...args: ServiceRequestArgs) => Promise<Result<ResultData>>,
  initialState: State<ResultData> = { status: DataState.UNKNOWN },
  callbacks?: Callbacks
): [State<ResultData>, (...args: ServiceRequestArgs) => void] {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isMountedRef } = useIsMounted();
  const errorCallback = callbacks?.error;
  const successCallback = callbacks?.success;

  const doRequest = useCallback(
    (...args: ServiceRequestArgs) => {
      dispatch(startAction());

      serviceFunc(...args)
        .then(result => {
          if (!result.isSuccess()) {
            if (isMountedRef.current === true) {
              dispatch(errorAction(result.errors));
            }

            errorCallback?.();
            return;
          }

          successCallback?.(result.data);

          if (isMountedRef.current === true) {
            dispatch(successAction(result.data));
          }
        })
        .catch(err => {
          errorCallback?.();

          if (isMountedRef.current === true) {
            dispatch(
              errorAction(
                new ServiceErrors([
                  {
                    code: ErrorCode.UNHANDLED_ERROR,
                    title: "Unhandled error",
                    detail: err ? err.toString() : "unhandled error",
                  },
                ])
              )
            );
          }
        });
    },
    [errorCallback, successCallback, isMountedRef, serviceFunc]
  );

  return [state as State<ResultData>, doRequest];
}

export type State<ResultData> = {
  status: DataState;
  data?: ResultData;
  error?: ServiceErrors;
};

type Action<ResultData> = {
  type: ActionType;
  data?: ResultData;
  error?: ServiceErrors;
};

enum ActionType {
  start = "start",
  success = "success",
  error = "error",
}

function startAction<ResultData>(): Action<ResultData> {
  return {
    type: ActionType.start,
  };
}

function successAction<ResultData>(data: ResultData): Action<ResultData> {
  return {
    type: ActionType.success,
    data,
  };
}

function errorAction<ResultData>(error: ServiceErrors): Action<ResultData> {
  return {
    type: ActionType.error,
    error,
  };
}

function reducer<ResultData>(state: State<ResultData>, action: Action<ResultData>): State<ResultData> {
  switch (action.type) {
    case ActionType.start:
      return {
        ...state,
        status: DataState.PENDING,
      };
    case ActionType.success:
      return {
        status: DataState.SUCCESS,
        data: action.data,
        error: undefined,
      };
    case ActionType.error:
      return {
        status: DataState.FAILED,
        data: undefined,
        error: action.error,
      };
    default:
      return state;
  }
}
