import React, { ChangeEvent, FormEvent, useState } from "react";
import { getValidQuantity } from "../../domain/basket/basket";
import { FilledButton, Form } from "@jsluna/react";
import { Modal } from "@sainsburys-tech/fable";
import { TextButton as Cancel } from "../TextButton";
import { selectNudgeValue } from "../../domain/basket/basket.selectors";
import { KG, UpdateQuantityModalProps } from "../../domain/basket/basket.types";

export const UpdateQuantityModal = ({
  quantity,
  unit,
  setQuantity,
  closeModal,
  productName,
}: UpdateQuantityModalProps) => {
  const [updateValue, setUpdateValue] = useState(quantity);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const isValidNumber = !Number.isNaN(updateValue);
    e.preventDefault();
    if (isValidNumber) {
      const newQuantity = getValidQuantity(updateValue);
      setQuantity(newQuantity);
      closeModal();
    }
  };

  const ensureNumeric = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const re = unit === KG ? /[0-9.]+/g : /[0-9]+/g;
    if (!re.test(e.key) && e.key !== "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Modal
      isOpen={true}
      data-testid="modal-update-quantity"
      layout="reverse"
      aria-label={`update quantity for ${productName}`}
      size="sm"
      className="modal-update-quantity"
    >
      <div className="modal-update-quantity__body">
        <Form onSubmit={handleSubmit}>
          <div className="modal-update-quantity__title">Update quantity</div>
          <input
            type="number"
            step={selectNudgeValue(unit)}
            className="modal-update-quantity__input ln-c-text-input"
            data-testid="modal-update-quantity-input-field"
            value={updateValue}
            aria-label="Input quantity"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUpdateValue(parseFloat(e.target.value))}
            onKeyPress={ensureNumeric}
            min="0"
            max="9999"
          />
          <FilledButton
            className="modal-update-quantity__submit"
            data-testid="modal-update-quantity-submit-button"
            type="submit"
            aria-label={`update quantity for ${productName}`}
          >
            Update
          </FilledButton>
          <Cancel className="ln-u-margin-top*3" data-testid="modal-update-quantity-cancel" onClick={() => closeModal()}>
            Cancel
          </Cancel>
        </Form>
      </div>
    </Modal>
  );
};
