import { Method, Result } from "../common/http/request";
import { encodeQueryParams } from "../common/http/query";
import { serviceRequest } from "./request";
import { getGlobal } from "../global";
import { Banners } from "../components/Citrus/BannerAd/BannerAd.types";
import { ContentBannerLocation, ContentPage, ContentPlacement, MagnoliaTemplate } from "./contentTypes";
import { pushEvent } from "../components/Espots/espot.digitaldata";
import { postLinkTrackEvent } from "../common/analytics/digitalData";
import { IngridAd } from "../components/IngridAd/IngridAd.type";
import { BrowseMagnoliaResponse } from "../views/EventsFeatures/EventsFeatures.types";

const url = "/groceries-api/gol-services/content";
export interface AdsResponseEnvelope<T> {
  placement?: string;
  banner?: {
    contentStandardId?: string;
    slotId?: string;
    banners: T;
  };
  espot?: {
    data: string;
  };
  magnolia?: IngridAd["magnolia"];
}

export * from "./contentTypes";

export async function sendImpression(adId: string | undefined, slotId?: string, name?: string, botDetected?: boolean) {
  if (botDetected) return;
  if (adId) {
    // event for citrus
    try {
      await fetch(`${getGlobal("GOL").config.citrusUrl}/v1/resource/first-i/${adId}`);
    } catch (e) {
      //TODO: Log these in NewRelic or similar
    }
    // event for analytics
    const promo = {
      promo_spot_creative: slotId,
      promo_spot_name: adId,
      promo_spot_id: name ? `Citrus - ${name}` : adId,
    };

    pushEvent({
      eventAction: "load",
      eventName: "promoEvent",
      promo,
    });
    postLinkTrackEvent(window);
  }
}

export async function sendOnClick(adId: string | undefined, slotId?: string, name?: string, botDetected?: boolean) {
  if (botDetected) return;
  if (adId) {
    // event for citrus
    await fetch(`${getGlobal("GOL").config.citrusUrl}/v1/resource/second-c/${adId}`);
    // event for analytics
    const promo = {
      promo_spot_creative: slotId,
      promo_spot_name: adId,
      promo_spot_id: name ? `Citrus - ${name}` : adId,
    };
    pushEvent({
      eventAction: "click",
      eventName: "promoEvent",
      promo,
    });
    postLinkTrackEvent(window);
  }
}

export function fetchBanners(
  location: ContentBannerLocation,
  isHFSSRestricted: boolean,
  page?: ContentPage,
  placement?: ContentPlacement,
  term?: string,
  store?: string,
  deliveryDate?: string,
  categoryIds?: string,
  baseUrl = url
): Promise<Result<AdsResponseEnvelope<Banners>>> {
  return serviceRequest<AdsResponseEnvelope<Banners>>({
    method: Method.GET,
    url: `${baseUrl}/v1/ads${encodeQueryParams({
      ...(page && { page }),
      ...(placement && { placement }),
      location,
      "filter[term]": term,
      "filter[store]": store,
      "filter[delivery_date]": deliveryDate,
      "filter[category_id]": categoryIds,
      hfssRestricted: isHFSSRestricted,
    })}`,
  });
}

export function fetchBrandPageBanner(
  brand: string,
  location: ContentBannerLocation = ContentBannerLocation.TOP,
  page: ContentPage = ContentPage.BRANDSHELF
): Promise<Result<AdsResponseEnvelope<Banners>>> {
  return serviceRequest<AdsResponseEnvelope<Banners>>({
    method: Method.GET,
    url: `${url}/v1/ads${encodeQueryParams({
      page,
      location,
      "filter[brandPage]": brand,
    })}`,
  });
}

export type FetchMagnoliaTemplateParams = {
  template: string;
  slotStartTime?: string;
  hasNectarAssociated: boolean;
  isDigitalCustomer: boolean;
  isGuestCustomer: boolean;
  region?: string;
  baseUrl?: string;
  canBookXmasSlotsEarly?: boolean;
};

export const fetchMagnoliaTemplate = ({
  template,
  slotStartTime,
  hasNectarAssociated,
  isDigitalCustomer,
  isGuestCustomer,
  region,
  canBookXmasSlotsEarly,
  baseUrl = url,
}: FetchMagnoliaTemplateParams): Promise<Result<MagnoliaTemplate>> => {
  const params = Object.assign(
    { name: template },
    slotStartTime ? { slotBooked: slotStartTime } : null,
    !isGuestCustomer ? { has_nectar_associated: hasNectarAssociated } : null,
    { is_digital_customer: isDigitalCustomer },
    { can_book_xmas_slots_early: canBookXmasSlotsEarly },
    region ? { region } : null
  );

  return serviceRequest<MagnoliaTemplate>({
    method: Method.GET,
    url: `${baseUrl}/v1/magnolia/template${encodeQueryParams(params)}`,
  });
};

export type FetchMagnoliaTemplateV2Params = {
  template_id: ContentPage;
  category_ids?: string[];
  hasDeliveryPass?: boolean;
  hasNectarAssociated?: boolean;
  isDigitalCustomer?: boolean;
  isVeryImportantCustomer?: boolean;
  numberOfAds?: number;
  placement?: ContentPlacement;
  region?: string;
  slotDate?: string;
  slotId?: ContentBannerLocation;
  storeIdentifier?: string;
  espotIds?: string[];
  baseUrl?: string;
};

/**
 * This endpoint was intended to only retrieve ads from Magnolia but has since changed to include templates as well.
 * We need to deprecate this endpoint and replace it with something more generic to cater to all use cases.
 * e.g. /content/v2/magnolia/template.
 */
export const fetchMagnoliaTemplateV2 = ({
  template_id,
  category_ids,
  espotIds,
  hasDeliveryPass,
  hasNectarAssociated,
  isDigitalCustomer,
  isVeryImportantCustomer,
  numberOfAds = 2,
  placement,
  region,
  slotDate,
  slotId,
  storeIdentifier,
  baseUrl = url,
}: FetchMagnoliaTemplateV2Params): Promise<Result<BrowseMagnoliaResponse>> => {
  const URL = `${baseUrl}/v2/withMagnoliaTemplate/ads`;

  // TODO: we need a type here for the request body
  const requestBody = {
    template_id,
    category_ids,
    delivery_date: slotDate,
    espotIds,
    has_delivery_pass: hasDeliveryPass,
    has_nectar_associated: hasNectarAssociated,
    is_digital_customer: isDigitalCustomer,
    is_very_important_customer: isVeryImportantCustomer,
    numberOfAds,
    placement,
    region,
    slotId,
    store: storeIdentifier,
  };

  return serviceRequest<BrowseMagnoliaResponse>({ method: Method.POST, url: URL, body: requestBody });
};
