import { ProductNectarOffer } from "../productTile.types";
import covertGbpToNumber from "../../../utils/convertGbpToNumber";
import { PromotionTypes } from "../../../common/types";

export type CalculatePromoTypeProps = {
  hasNectarPromotion: boolean;
  hasTprPromotion: boolean;
  hasYnpPromotion: boolean;
  hasComplexPromotion: boolean;
  nectarOffer?: ProductNectarOffer;
  retailPrice?: string;
  currencyOriginalPrice?: string;
};

export function calculatePromoType({
  hasNectarPromotion,
  hasTprPromotion,
  hasYnpPromotion,
  hasComplexPromotion,
  nectarOffer,
  retailPrice,
  currencyOriginalPrice,
}: CalculatePromoTypeProps) {
  if (hasYnpPromotion) {
    return PromotionTypes.YOUR_NECTAR_PRICE;
  }
  // The presence of retailPrice and originalPrice implies there are promotions
  if (retailPrice && currencyOriginalPrice) {
    const parsedRetailPrice = covertGbpToNumber(retailPrice);
    const convertedOriginalPrice = covertGbpToNumber(currencyOriginalPrice);

    /* 
          The isMoneyOff check is to account for a GOL services/data issue where the originalPrice can be the same as the retail price.
          Should be able to remove this soon once we fix that.
        */

    const isMoneyOff = parsedRetailPrice < convertedOriginalPrice;

    if (hasNectarPromotion && isMoneyOff) {
      return PromotionTypes.NECTAR;
    }

    if (hasTprPromotion && isMoneyOff) {
      return PromotionTypes.TPR;
    }

    if (!hasNectarPromotion && !hasTprPromotion) {
      if (hasComplexPromotion) {
        return nectarOffer ? PromotionTypes.NECTAR_POINTS : PromotionTypes.COMPLEX;
      }
    }
  } else if (nectarOffer) {
    return PromotionTypes.NECTAR_POINTS;
  }
}
