import { Dispatch } from "redux";
import { State } from "../../../common/store";
import { push } from "connected-react-router";
import { DEFAULT_STORE_ID, urls } from "../../../routes";
import { mapControls, mapProduct, mapProducts } from "../../../domain/product/product";
import {
  CreateSearchProductSuccessActionType,
  SearchActionType,
  SearchProducts,
} from "../../../views/SearchResults/search.types";
import { extractQueryParams } from "../../ProductControls/productControls";
import { createResetEspotAvailability } from "../../Espots/espot.actions";
import { SearchResultsPageViewAction } from "../../../common/analytics/analytics";
import { AnalyticsActionTypes } from "../../../common/analytics/types";
import isEqual from "lodash.isequal";
import * as domain from "../../../domain/product/product.types";
import { AdMetaData, createAdImpressionAction, createAdsTrackingDataAction } from "../../../common/ads";
import { AppThunkDispatch } from "../../../common/types";
import { fetchDietaryWarnings } from "../../DietaryProfile/DietaryWarning/dietaryWarning.actions";
import {
  Product,
  ProductAd,
  ProductQueryParameters,
  SearchProductParameters,
  SearchResponse,
} from "../../../services/product.types";
import { searchProducts } from "../../../services/product";
import {
  AssociationType,
  FAVOURITES_FIRST,
  getProductEANs,
  ProductSource,
} from "../../../domain/product/product.types";
import { getCachedSlotReservation } from "../../../domain/slot/slotReservation";
import { SearchAnalyticsType } from "../../../common/dataLayer/types";
import { mapSearchResultsForAnalytics } from "../../../common/dataLayer/search";
import { EXPERIMENTS, trackExperiment } from "../../../common/analytics";

const defaultTopRowProductSpotlightPositions = [1, 2, 3];
const defaultInGridProductAdPositions = [9, 10, 11];

const getSearchResults = (query: ProductQueryParameters) => {
  const searchProductsParams: SearchProductParameters = {
    searchTerm: query.filters ? query.filters.keyword : null,
    includes: [],
    pageNumber: query.pageNumber ? query.pageNumber : 1,
    pageSize: query.pageSize ? query.pageSize : 60,
    sort: query.sort ? query.sort : FAVOURITES_FIRST,
    filters: query.filters,
    storeIdentifier: query.storeIdentifier,
    flexiStores: query.flexiStores,
    slotDate: query.slotDate,
    slotDateTime: query.slotDateTime,
    salesWindowVariant: query.salesWindowVariant,
  };

  if (query.salesWindowVariant) {
    trackExperiment(EXPERIMENTS.Findability_Test_Group_8, `${query.salesWindowVariant}`);
  }

  return searchProducts(searchProductsParams);
};

export const createSearchAction =
  (searchTerm: string, trending: boolean): ((dispatch: Dispatch, getState: () => State) => void) =>
  async (dispatch: Dispatch, getState: () => State) => {
    if (!searchTerm) {
      return;
    }

    // Fix malformed URI issues caused by reserved symbols within search terms
    const safeSearchTerm = encodeURIComponent(searchTerm.replace(/%/g, ""));
    const result = getState().redirects;
    const url = trending
      ? `${urls.SEARCH_RESULTS_FINDABILITY}/${safeSearchTerm}?trending=true`
      : `${urls.SEARCH_RESULTS_FINDABILITY}/${safeSearchTerm}`;

    if (result?.searchTerms) {
      const redirectElement = result.searchTerms[searchTerm.toLowerCase()];
      if (redirectElement) {
        window.location.assign(`${redirectElement}&ost=${searchTerm}`);
        return;
      }
    }
    dispatch(push(url));
  };

export const createSearchProductSuccessAction = (products: SearchProducts): CreateSearchProductSuccessActionType => {
  return {
    type: SearchActionType.SEARCH_PRODUCTS_SUCCESS,
    products,
  };
};

export const searchProductFailureAction = (error: string) => {
  return {
    type: SearchActionType.SEARCH_PRODUCTS_FAILURE,
    message: error,
  };
};

export const searchResultsPageViewAction = (
  searchAnalyticsType: SearchAnalyticsType,
  isPagination: boolean
): SearchResultsPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: `searchResultsPage:${searchAnalyticsType.originalSearchTerm}`,
    template: isPagination ? "search_pagination" : "search",
    section: "searchResultsPage",
    newTemplate: "searchresults",
    search: [searchAnalyticsType],
  },
});

function getCurrentRequestParams(state: State) {
  return extractQueryParams(state.router.location.search);
}

export const createSearchProductAction =
  (
    currentQuery: ProductQueryParameters,
    topRowProductPositions: number[] = defaultTopRowProductSpotlightPositions,
    inGridProductAdPositions: number[] = defaultInGridProductAdPositions
  ) =>
  async (dispatch: AppThunkDispatch, getState: () => State) => {
    // Do not create an action if search term is empty
    if (currentQuery.filters && currentQuery.filters.keyword) {
      const previousCall = getCurrentRequestParams(getState());
      dispatch({ type: SearchActionType.SEARCH_PRODUCTS_REQUEST });
      const slot = getCachedSlotReservation();
      const currentQuerySlot = { ...currentQuery };
      if (slot && slot.storeIdentifier && slot.storeIdentifier !== DEFAULT_STORE_ID) {
        currentQuerySlot.storeIdentifier = slot.storeIdentifier;
      }
      const result = await getSearchResults(currentQuerySlot);
      if (result.isSuccess()) {
        if (result.data.redirect_to) {
          window.location.assign(result.data.redirect_to);
        } else {
          const lastRequestParams = getCurrentRequestParams(getState());
          if (isEqual(lastRequestParams, currentQuery)) {
            if (previousCall && previousCall.filters && previousCall.filters.keyword === currentQuery.filters.keyword) {
              // checking if the page number has change after checking that the keyword doesn't, that means the user is paginating
              const isPagination = previousCall.pageNumber !== currentQuery.pageNumber;

              // if there is no change in the filters its probably the initial request or a reload (initial might have 0 extra filters)
              if (isEqual(previousCall.filters, currentQuery.filters)) {
                dispatch(
                  searchResultsPageViewAction(
                    mapSearchResultsForAnalytics(currentQuery.filters.keyword, result.data),
                    isPagination
                  )
                );
              }
            } else {
              dispatch(
                searchResultsPageViewAction(
                  mapSearchResultsForAnalytics(currentQuery.filters.keyword, result.data),
                  false
                )
              );
              dispatch(createResetEspotAvailability());
            }
            const products = mapSearchProducts(
              dispatch,
              ProductSource.SEARCH_RESULTS,
              result.data,
              topRowProductPositions,
              inGridProductAdPositions
            );
            dispatch(createSearchProductSuccessAction(products));
            dispatch(fetchDietaryWarnings(getProductEANs(products.products)));
          }
        }
      } else {
        dispatch(
          searchProductFailureAction(
            result.errors !== null && result.errors.first() !== null
              ? result.errors.first().detail
              : "Failure happened while fetching the search results."
          )
        );
      }
    }
  };

export const refetchSearchProductsAction =
  (
    topRowProductPositions: number[] = defaultTopRowProductSpotlightPositions,
    inGridProductAdPositions: number[] = defaultInGridProductAdPositions
  ) =>
  async (dispatch: AppThunkDispatch, getState: () => State) => {
    const state = getState();
    const previousCall: ProductQueryParameters = extractQueryParams(state.router.location.search);

    const result = await getSearchResults(previousCall);
    if (result.isSuccess()) {
      const products = mapSearchProducts(
        dispatch,
        ProductSource.SEARCH_RESULTS,
        result.data,
        topRowProductPositions,
        inGridProductAdPositions
      );
      dispatch(fetchDietaryWarnings(getProductEANs(products.products)));
      dispatch(createSearchProductSuccessAction(products));
    }
  };

export const mapSearchProducts = (
  dispatch: Dispatch,
  source: ProductSource,
  servicesProducts: SearchResponse,
  topRowProductPositions: number[],
  inGridProductAdPositions: number[]
): SearchProducts => {
  return {
    products: mapProductsWithAdsAndSpotlight(
      dispatch,
      source,
      servicesProducts,
      topRowProductPositions,
      inGridProductAdPositions
    ),
    controls: mapControls(servicesProducts.controls),
    errors: servicesProducts.errors,
    suggestedTerm: servicesProducts.suggested_search_terms,
  };
};

const mapProductsWithAdsAndSpotlight = (
  dispatch: Dispatch,
  source: ProductSource,
  servicesProducts: SearchResponse,
  topRowProductPositions: number[],
  inGridProductAdPositions: number[]
): domain.Product[] => {
  const products = mapProducts(source, servicesProducts.products);

  mergeSpotlightProducts(
    servicesProducts.spotlight_products ? servicesProducts.spotlight_products : [],
    servicesProducts.ads ? servicesProducts.ads.sponsored_products.spotlight_product_ads : [],
    topRowProductPositions,
    dispatch,
    products
  );

  if (servicesProducts.ads) {
    mergeAdsWithProducts(
      servicesProducts.ads.sponsored_products.in_grid_product_ads,
      inGridProductAdPositions,
      dispatch,
      products
    );
  }

  return products;
};

function mergeAdsWithProducts(ads: ProductAd[], adPositions: number[], dispatch: Dispatch, products: domain.Product[]) {
  ads.forEach((ad, i) => {
    if (hasMoreProductsThanPositions(i, adPositions)) {
      return;
    }

    if (ad.ad_id) {
      dispatch(createAdImpressionAction(ad.ad_id));
      const adMeta: AdMetaData[] = [{ id: ad.ad_id, type: ad.type, productUid: ad.product.product_uid }];
      dispatch(createAdsTrackingDataAction(adMeta));
      const product = mapProduct(ad.product, 0, ProductSource.CITRUS_SEARCH_ADS_IN_GRID, AssociationType.NONE);
      products.splice(adPositions[`${i}`] - 1, 0, product);
    }
  });
}

const mergeSpotlightProducts = (
  spotlightProducts: Product[],
  spotlightAdProducts: ProductAd[],
  positions: number[],
  dispatch: Dispatch,
  products: domain.Product[]
) => {
  let positionsFilled = 0;

  spotlightProducts.forEach((product: Product) => {
    if (hasMoreProductsThanPositions(positionsFilled, positions)) {
      return;
    }

    products.splice(
      positions[positionsFilled++] - 1,
      0,
      mapProduct(product, 0, ProductSource.SEARCH_RESULTS, AssociationType.NONE)
    );
  });

  if (hasMoreProductsThanPositions(positionsFilled, positions)) {
    return;
  }

  mergeAdsWithProducts(spotlightAdProducts, positions.slice(0).splice(positionsFilled), dispatch, products);
};

const hasMoreProductsThanPositions = (i: number, positions: number[]) => i > positions.length - 1;
