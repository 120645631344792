import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { FetchUserAction, UserActionTypes } from "../../components/Authorized";
import { fetchNectarOffersAction } from "./nectar.actions";

export const nectarMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) => (next: Dispatch<any>) => (action: FetchUserAction) => {
    switch (action.type) {
      case UserActionTypes.FETCH_USER_SUCCESS:
        if (action.user.isRegistered) {
          api.dispatch(fetchNectarOffersAction());
        }
        break;
      default:
        break;
    }
    next(action);
  };
