import { useEffect, useRef } from "react";

/**
 * Uses a ref to achieve focus trapping to ensure keyboard focus remains within a specific area
 * such as a modal or a dialog, enhancing accessibility and usability for users.
 * Also allows user to close modal/dialog with the escape key.
 */

export const useFocusTrap = (isModalOpen: boolean, setIsModalOpen: (isOpen: boolean) => any): void => {
  const modalRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isModalOpen) {
      const modalElement = modalRef.current;
      // add any focusable HTML element you want to include to this string
      const focusableElements: NodeListOf<HTMLInputElement> | undefined = modalElement?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      let firstElement: HTMLInputElement | null;
      let lastElement: HTMLInputElement | null;
      if (focusableElements) {
        firstElement = focusableElements[0];
        lastElement = focusableElements[focusableElements.length - 1];
      }

      const handleTabEvent = (e: KeyboardEvent) => {
        if (e.code === "Tab") {
          if (e.shiftKey && document.activeElement === firstElement) {
            e.preventDefault();
            lastElement?.focus();
          } else if (!e.shiftKey && document.activeElement === lastElement) {
            e.preventDefault();
            firstElement?.focus();
          }
        }
        if (e.code === "Escape") {
          setIsModalOpen(false);
        }
      };

      window.addEventListener("keydown", handleTabEvent);

      return () => {
        window.removeEventListener("keydown", handleTabEvent);
      };
    }
  }, [isModalOpen, setIsModalOpen]);
};
