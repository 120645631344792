import { Method, Result } from "../common/http/request";
import { Optional } from "../common/types";
import { GetTimeResponse, LoginRequest, LoginResponse } from "./login.types";
import { serviceRequest } from "./request";

const url = "/groceries-api/gol-services/login";

// exchange access token with WCS session
export const loginWithAccessToken = (
  loginRequest: LoginRequest,
  baseUrl: Optional<string> = url
): Promise<Result<LoginResponse>> =>
  serviceRequest<LoginResponse>({ method: Method.POST, url: `${baseUrl}/v1/login-access-token`, body: loginRequest });

// call before initiating oauth login flow, used for analytics
export const loginStart = (baseUrl: Optional<string> = url): Promise<Result<LoginResponse>> =>
  serviceRequest<LoginResponse>({ method: Method.POST, url: `${baseUrl}/v1/login-start` });

export const getTime = (baseUrl: Optional<string> = url): Promise<Result<GetTimeResponse>> =>
  serviceRequest<GetTimeResponse>({ method: Method.GET, url: `${baseUrl}/v1/time` });
