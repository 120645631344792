import React, { FocusEventHandler, forwardRef } from "react";
import { isEmpty, cloneDeep } from "lodash";
import clsx from "clsx";
import { PillItem } from "./BrowseHeaderPills.types";
import { BrowsePageType, BrowseTaxonomyState, BrowseTreeNode, CATEGORY_ID_IDENTIFIER } from "../Browse.types";
import { capitalise, concatAllNonEmptyStrings, createFormattedPath } from "../../../common/utils";
import { GOLLink } from "../../../components/GOLLink";
import { useBrowsePathRedirect } from "../Browse.hooks";
import { AnalyticsEvent } from "../../../common/dataLayer/types";
import { digitalDataGenericTrackEvent } from "../../../common/analytics";

const Pill = ({ label, url, isSelected, pageType }: PillItem) => {
  const refinedURL = useBrowsePathRedirect(url, true);
  return !isEmpty(label) && !isEmpty(url) ? (
    <GOLLink
      to={refinedURL}
      className={clsx("browse-header-pill", isSelected && "browse-header-pill--selected")}
      onClick={() => {
        const eventData = `${pageType ? capitalise(pageType.toLowerCase()) : "Browse"} Pill Click ${label}`;
        const analyticsEvent: AnalyticsEvent = {
          data_event_category: "click",
          data_event_action: eventData,
          data_event_label: eventData,
        };
        digitalDataGenericTrackEvent(eventData, analyticsEvent);
      }}
    >
      {label}
    </GOLLink>
  ) : null;
};

function makePills(nodes: BrowseTreeNode[], tree: string[], lookup: number): PillItem[] {
  const pills: PillItem[] = [];
  nodes.forEach((node, index) => {
    const { name: label, id } = node;
    const isSelected = index === lookup;
    const name = createFormattedPath(node.name);
    // nodes with zero pimid and zero children are not considered valid
    const isValidNode = parseInt(node.pimid) !== 0 || node.children.length;
    const url = concatAllNonEmptyStrings(["/gol-ui/groceries", ...tree, name, CATEGORY_ID_IDENTIFIER.concat(id)], "/");
    isValidNode && pills.push({ label, url, isSelected });
  });

  return pills;
}

function getChildren(lookups: number[], taxonomy: BrowseTreeNode[], trail: string[] = []): PillItem[] {
  if (isEmpty(taxonomy)) return [];
  const lookUpsClone = cloneDeep(lookups);
  const nextIndex = lookUpsClone.shift();
  const nextNode = nextIndex === undefined ? null : taxonomy[nextIndex];
  if (!nextNode) return [];

  const updatedTrail = trail.concat(createFormattedPath(nextNode.name));

  // If reached the end of the lookups, make pills at one level BELOW.
  return isEmpty(lookUpsClone)
    ? makePills(nextNode.children, updatedTrail, lookUpsClone[0])
    : getChildren(lookUpsClone, nextNode.children, updatedTrail);
}

// TODO: Delete this method if remains unused in future
export const siblings = (nodes: BrowseTreeNode[], targetCatIndex: number): BrowseTreeNode[] =>
  nodes.filter((node, ind) => ind !== targetCatIndex);

function getSiblings(lookups: number[], taxonomy: BrowseTreeNode[], trail: string[] = []): PillItem[] {
  if (isEmpty(taxonomy)) return [];
  // Applying shift directly on lookups arg mutates value in redux
  const lookUpsClone = cloneDeep(lookups);
  const nextIndex = lookUpsClone.shift();
  const nextNode = nextIndex === undefined ? null : taxonomy[nextIndex];
  if (!nextNode) return [];
  const updatedTrail = trail.concat(createFormattedPath(nextNode.name));
  // If one step from reaching the end of the lookups, make pills at the current level.
  return lookUpsClone.length === 1
    ? makePills(nextNode.children, updatedTrail, lookUpsClone[0])
    : getSiblings(lookUpsClone, nextNode.children, updatedTrail);
}

export function makePillItems(taxonomy: BrowseTaxonomyState, pageType: BrowsePageType, lookups: number[]): PillItem[] {
  if (pageType === BrowsePageType.UNKNOWN) return [];
  return pageType === BrowsePageType.SHELF ? getSiblings(lookups, taxonomy) : getChildren(lookups, taxonomy);
}

export interface BrowseHeaderPillsProps {
  className?: string;
  pillItems: PillItem[];
  pageType: BrowsePageType;
}

export const BrowseHeaderPills = forwardRef<HTMLUListElement, BrowseHeaderPillsProps>(
  ({ className, pillItems, pageType }, ref) => {
    const handlePillFocus: FocusEventHandler<HTMLLIElement> = event => {
      event.currentTarget.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    };

    return pillItems.length ? (
      <ul className={clsx("browse-pill-list", "browse-pill-list__disable-scrollbars", className)} ref={ref}>
        {pillItems.map(pi => (
          <li key={pi.label} onFocus={handlePillFocus}>
            <Pill label={pi.label} url={pi.url} isSelected={pi.isSelected} pageType={pageType} />
          </li>
        ))}
      </ul>
    ) : null;
  }
);
