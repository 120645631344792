import React, { createRef, ReactNode } from "react";
import ReactDOM from "react-dom";

const exitElementRef = createRef<HTMLDivElement>();

const Entrance = ({ children }: { children: ReactNode }) => {
  if (exitElementRef.current !== null) {
    return ReactDOM.createPortal(children, exitElementRef.current);
  }
  return null;
};

const Exit = () => <div data-testid="full-screen-portal-exit-container" ref={exitElementRef} />;

export const FullScreenPortal = { Entrance, Exit };
