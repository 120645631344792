export interface DietaryProfileProps {
  updateStatus: () => void;
  enabled: boolean;
}

export enum DietaryProfileUpdateActionTypes {
  DIETARY_PROFILE_UPDATE_REQUEST = "DIETARY_PROFILE_UPDATE_REQUEST",
  DIETARY_PROFILE_UPDATE_SUCCESS = "DIETARY_PROFILE_UPDATE_SUCCESS",
}

export type UpdateDietaryProfileStatusRequestActionType = {
  type: DietaryProfileUpdateActionTypes.DIETARY_PROFILE_UPDATE_REQUEST;
  enabled: boolean;
};

export type UpdateDietaryProfileStatusSuccessActionType = {
  type: DietaryProfileUpdateActionTypes.DIETARY_PROFILE_UPDATE_SUCCESS;
};
