import React, { createContext } from 'react'
import clsx from 'clsx'

import { ThemeContextType, ThemeProviderProps } from '../types'

/**
 * Context used to provide the current theme.
 * Default context is null.
 */
const ThemeContext = createContext<ThemeContextType | null>(null)

/**
 * Retrieves the theme context using React's useContext hook.
 * @returns The context containing the theme.
 */
const useTheme = () => {
  return React.useContext(ThemeContext)
}

const defaultElement = 'div'

/**
 * Provider function provides the theme context to its children.
 * @param children - The children elements that receive the vocab context.
 * @param brand - The brand to apply
 * @param density - The density to apply
 * @param as - The semantic element type to render
 * @returns a theme context provider that wraps its children.
 */
const ThemeProvider = <C extends React.ElementType = typeof defaultElement>({
  as,
  children,
  brand,
  density,
  ...rest
}: ThemeProviderProps<C>) => {
  const Element = as || defaultElement

  return (
    <ThemeContext.Provider value={{ brand, density }}>
      <Element
        className={clsx(
          `ds-theme--${brand}`,
          density && `ds-density--${density}`
        )}
        data-brand={brand}
        data-density={density || undefined}
        {...rest}
      >
        {children}
      </Element>
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext, useTheme }
