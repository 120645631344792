import React from "react";
import { NotificationProps } from "./Notification.types";
import { WithChildren } from "../../common/types";
import clsx from "clsx";

export const Notification = ({ children, className }: NotificationProps & WithChildren) => (
  <div className={clsx("notification", className)}>{children}</div>
);

Notification.displayName = "Notification";
