import { Method } from "../http/request";
import { serviceRequest } from "../../services/request";

export enum CitrusAnalyticsType {
  IMPRESSION = "IMPRESSION",
  CLICK = "CLICK",
  ATB = "ADD_TO_CART",
}

export interface CitrusAnalyticsProps {
  brandPageId: string;
  serveId: string;
  events: {
    eventType: CitrusAnalyticsType;
    productCode: string;
  }[];
}

export type BrandCitrusIdType = Pick<CitrusAnalyticsProps, "brandPageId" | "serveId">;

export const sendCitrusAnalytics = async ({ brandPageId, serveId, events }: CitrusAnalyticsProps) => {
  const URL = "/groceries-api/gol-services/content/v1/citrus/sendEvents";
  const result = await serviceRequest({
    method: Method.POST,
    url: URL,
    body: {
      brandPageId,
      serveId,
      events,
    },
  });
  if (!result.isSuccess()) console.error(",Citrus Impression couldn't be tracked");
};

export const getCampaignName = (ctaLink?: string) => {
  const name = ctaLink ? new URLSearchParams(ctaLink.split("?")[1]).get("name") ?? undefined : undefined;
  return name;
};
