import { DataState } from "../dataState";
import { AnyAction } from "redux";

const initialState = DataState.UNKNOWN;

interface DataStateRules {
  [key: string]: DataState;
}

export const createDataStateReducer =
  (rules: DataStateRules) =>
  (state = initialState, action?: AnyAction): DataState => {
    let newState = state;
    Object.keys(rules).forEach((actionType: string) => {
      if (action && actionType === action.type) {
        newState = rules[`${actionType}`];
      }
    });
    return newState;
  };
