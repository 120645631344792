import { DataState, WithDataState } from "../../common/dataState";
import GolTime from "../../domain/time";

export interface RecurringSlotPreferences {
  registered: boolean;
  eligible: boolean;
}

export interface RecurringSlotProps {
  order: string | null;
  isLoadingOrder: boolean;
  isEligible: boolean;
  isRegistered: boolean;
  confirmState: DataState;
  nextRecurringSlot: NextRecurringSlot | undefined;
  optingOut: boolean;
  hasOptOut: boolean;
  hasOptOutFailed: boolean;
  isSkipOptOutFeatureFlagEnabled: boolean;
  onClickOptIn: (
    slotStartTime: string,
    slotEndTime: string,
    storeIdentifier: string,
    addressId: string,
    frequency: number
  ) => void;
  onClickShowDeliveryCosts: () => void;
  onClickOptOut: () => void;
  onLoadOptIn: () => void;
  onLoadMyRecurringSlot: () => void;
}

export interface NextRecurringSlot {
  startTime: GolTime;
  endTime: GolTime;
  expireTime: GolTime;
  emailTime: GolTime;
  address: SlotAddress;
  storeIdentifier: string;
}

export interface SlotAddress {
  id: string;
  nickname: string;
  postcode: string;
}

export interface RecurringSlotState {
  optIn: UpdateSlotState;
  optOut: OptOutSlotState;
  current: MyRecurringSlotInformation;
}

export interface MyRecurringSlotInformation extends WithDataState {
  eligible: boolean | undefined;
  registered: boolean | undefined;
}

export interface UpdateSlotState extends WithDataState {}
export interface OptOutSlotState extends WithDataState {}

export type FetchRecurringSlotSuccess = {
  type: RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_SUCCESS;
  recurringSlotPreferences: RecurringSlotPreferences;
};

export type FetchRecurringSlotFailed = {
  type: RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_FAILED;
};

export type FetchRecurringSlotPending = {
  type: RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_PENDING;
};

export interface OptInRecurringSlotActionType {
  type: RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT;
  slotStartTime: GolTime;
  slotEndTime: GolTime;
  frequency: number;
  storeIdentifier: string;
  addressId: string;
}

export type OptInRecurringSlotFailed = {
  type: RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT_FAILED;
};

export type OptInRecurringSlotSuccess = {
  type: RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT_SUCCESS;
};

export interface OptOutRecurringSlotActionType {
  type: RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT;
}

export type OptOutRecurringSlotPending = {
  type: RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_PENDING;
};

export type OptOutRecurringSlotFailed = {
  type: RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_FAILED;
};
export type OptOutRecurringSlotSuccess = {
  type: RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_SUCCESS;
};

export type DispatchableRecurringSlotActionTypes = {
  type: RecurringSlotPreferenceActionTypes;
};

export enum RecurringSlotPreferenceActionTypes {
  OPT_IN_RECURRING_SLOT = "OPT_IN_RECURRING_SLOT",
  OPT_IN_RECURRING_SLOT_SUCCESS = "OPT_IN_RECURRING_SLOT_SUCCESS",
  OPT_IN_RECURRING_SLOT_FAILED = "OPT_IN_RECURRING_SLOT_FAILED",

  OPT_OUT_RECURRING_SLOT = "OPT_OUT_RECURRING_SLOT",
  OPT_OUT_RECURRING_SLOT_PENDING = "OPT_OUT_RECURRING_SLOT_PENDING",
  OPT_OUT_RECURRING_SLOT_SUCCESS = "OPT_OUT_RECURRING_SLOT_SUCCESS",
  OPT_OUT_RECURRING_SLOT_FAILED = "OPT_OUT_RECURRING_SLOT_FAILED",

  FETCH_RECURRING_SLOT_PENDING = "FETCH_RECURRING_SLOT_PENDING",
  FETCH_RECURRING_SLOT_FAILED = "FETCH_RECURRING_SLOT_FAILED",
  FETCH_RECURRING_SLOT_SUCCESS = "FETCH_RECURRING_SLOT_SUCCESS",
}
