import { RequestProps, ResponseData } from "../../MealDealBuilder.types";
import { serviceRequest } from "../../../../services/request";
import { Method } from "../../../../common/http/request";

export const fetchCMSDataForMealDeal = async ({
  template_id,
  storeId,
  delivery_date,
  region,
  has_nectar_associated,
  is_digital_customer,
  has_delivery_pass,
  is_very_important_customer,
}: RequestProps) => {
  const SHELF_AD_URL = "/groceries-api/gol-services/content/v2/withMagnoliaTemplate/ads";
  const requestBody: RequestProps = {
    template_id,
    storeId,
    delivery_date,
    region,
    has_nectar_associated,
    is_digital_customer,
    has_delivery_pass,
    is_very_important_customer,
  };

  return serviceRequest<ResponseData>({ method: Method.POST, url: SHELF_AD_URL, body: requestBody });
};
