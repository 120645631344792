import { AdImpressionAction, ProductDetailsAction } from "../../views/ProductDetails/productDetails.types";
import { ProductUid } from "../../domain/product/product.types";

export enum AdsActionType {
  ADS_TRACKING_DATA = "ADS_TRACKING_DATA",
}

export type AdsTrackingDataAction = {
  type: AdsActionType.ADS_TRACKING_DATA;
  data: AdMetaData[];
};

export const createAdsTrackingDataAction = (data: AdMetaData[]): AdsTrackingDataAction => ({
  type: AdsActionType.ADS_TRACKING_DATA,
  data,
});

export interface AdMetaData {
  id: string;
  type: string;
  productUid: string;
}

export interface AdsStore {
  products: Map<ProductUid, AdMetaData>;
}

export const createAdImpressionAction = (adId: string): AdImpressionAction => ({
  type: ProductDetailsAction.AD_IMPRESSION,
  adId,
});
