import React from "react";
import { connect } from "react-redux";
import {
  NavigationActionTypes,
  ExternalNavigationAction,
  ExternalNavigationWindowAction,
} from "../../common/middleware/navigationMiddleware";

export class RedirectExternalComponent extends React.Component<{
  createExternalNavigation: (to: string) => ExternalNavigationAction;
  createExternalNavigationWindow: (to: string) => ExternalNavigationWindowAction;
  to: string;
}> {
  componentDidMount() {
    this.props.createExternalNavigation(this.props.to);
  }

  render() {
    return null;
  }
}

export const createExternalNavigation = (path: string) => ({
  type: NavigationActionTypes.EXTERNAL_NAVIGATION,
  path,
});

export const createExternalNavigationWindow = (path: string) => ({
  type: NavigationActionTypes.EXTERNAL_NAVIGATION_WINDOW,
  path,
});

export const RedirectExternal = connect(null, {
  createExternalNavigation,
  createExternalNavigationWindow,
})(RedirectExternalComponent);
