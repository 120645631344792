import { combineReducers } from "redux";
import {
  BrandedActionTypes,
  BrandedPageState,
  CreateFetchBrandedSKUsAndCMSSuccessActionType,
} from "./BrandedPage.types";
import { DataState } from "../../common/dataState";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";

export const initialState: BrandedPageState = {
  dataState: DataState.UNKNOWN,
  data: {
    meta: { title: "Sainsbury's", description: "Branded Page" },
    facetContent: { data: [] },
    citrusContent: { data: [] },
    magnoliaContent: { data: [] },
    skus: [],
    header: { heading: "", introCopy: "", themeColour: "", backgroundColour: "" },
    footer: { data: [] },
    brandPageId: "",
    serveId: "",
  },
};

const dataState = createDataStateReducer({
  [BrandedActionTypes.SKUSANDCMSREQUEST]: DataState.PENDING,
  [BrandedActionTypes.SKUSANDCMSSUCCESS]: DataState.SUCCESS,
  [BrandedActionTypes.SKUSANDCMSFAILED]: DataState.FAILED,
});

const brandedDataReducer = (
  state: BrandedPageState["data"] = initialState.data,
  action: CreateFetchBrandedSKUsAndCMSSuccessActionType
) => {
  if (action.type === BrandedActionTypes.SKUSANDCMSSUCCESS) {
    return action.payload;
  }
  return state;
};

export const brandedPageReducer = combineReducers({ dataState, data: brandedDataReducer });
