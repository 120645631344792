import React from "react";
import clsx from "clsx";
import { ArrowRight } from "@jsluna/icons";
import { GOLLink } from "../../../GOLLink";
import { MegaNavItemProps } from "../MegaNav.types";
import { checkMegaNavUrl } from "../MegaNavShared";

export const MegaNavItem: React.FC<MegaNavItemProps> = ({ item, onSelected, isSelected }) => {
  const { url, extern } = checkMegaNavUrl(item.url);
  return (
    <li
      role="menuitem"
      className={clsx("mega-nav__item", isSelected && "mega-nav__item--selected")}
      data-testid="mega-nav-item"
    >
      {item.items?.length ? (
        <button
          className="mega-nav__item-content"
          type="button"
          onClick={() => onSelected(item)}
          data-testid="mega-nav-item-button"
        >
          <span className="mega-nav__item-text" data-testid="mega-nav-item-text">
            {item.title}
          </span>
          <ArrowRight className="mega-nav__item-icon" data-testid="mega-nav-item-icon" />
        </button>
      ) : (
        <GOLLink
          to={url}
          external={extern}
          onClick={() =>
            (window as any).digitalData?.track("ev_meganavClick", {
              hit_type: "link",
              data_event_action: "click",
              data_event_category: "meganav",
              data_event_label: item.title,
            })
          }
          className="mega-nav__item-content"
          data-testid="mega-nav-item-link"
        >
          <span className="mega-nav__item-text" data-testid="mega-nav-item-text">
            {item.title}
          </span>
        </GOLLink>
      )}
    </li>
  );
};
