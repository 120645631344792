import React from "react";

export interface NectarPointsProps {
  points: number;
  className?: string;
}

export const NectarPoints = ({ points, className = "" }: NectarPointsProps) => {
  return (
    <p className={`trolley-item__nectar-offer-points ${className}`}>
      <b>{points}</b> Nectar points
    </p>
  );
};
