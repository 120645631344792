import React from "react";
import { Modal, Button, ButtonGroup, Display1 } from "@sainsburys-tech/fable";
import { useDispatch, useSelector } from "react-redux";
import { createCloseCurrentlyVisibleModalAction } from "./modal.actions";
import { ModalNames } from "./Modal.types";
import { State } from "../../common/store";
import { Tick } from "@sainsburys-tech/icons";

export default function ModalAtbSuccess() {
  const isOpen = useSelector((state: State) => state.modal.currentlyVisible === ModalNames.MODAL_BASKET_ADD_SUCCESS);
  const dispatch = useDispatch();
  function close() {
    dispatch(createCloseCurrentlyVisibleModalAction());
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      testid="global-modal-atb-success"
      hasClickawayClose
      renderFooter={
        <ButtonGroup>
          <Button variant="primary" onClick={close}>
            Ok
          </Button>
        </ButtonGroup>
      }
    >
      <Display1 icon={<Tick color="green" />}>Items added to trolley</Display1>
    </Modal>
  );
}
