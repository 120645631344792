export interface AmendOrderBannerComponentProps {
  showSlotExpiryModal: boolean;
  showCutOffModal: boolean;
  expiryDetails: ExpiryDetails;
  showCutOffDetails: boolean;
  showSlotExpiryDetails: boolean;
  order: AmendOrderBannerOrder | null;
  hasOccasionItems: boolean;
  primaryCta: AmendOrderBannerPrimaryCta;
  dispatchSetCutOffExpired: () => void;
  dispatchSetReservationExpired: () => void;
  dispatchOpenDiscardChangesModal: () => void;
  pathname: string;
  usesFavouritesHeader: boolean;
  showCheckoutCTA: boolean;
  startCheckout: () => void;
  error: string | undefined;
  isRecipePage: boolean;
}

export interface AmendOrderBannerDetailsProps {
  order: AmendOrderBannerOrder | null;
  hasOccasionItems: boolean;
  expiryDetails: ExpiryDetails;
  showSlotExpiryDetails: boolean;
  showCutOffDetails: boolean;
  dispatchSetReservationExpired: () => void;
  dispatchSetCutOffExpired: () => void;
}

export enum AmendOrderModalTypes {
  SLOT_EXPIRED_MODAL = "SLOT_EXPIRED_MODAL",
  SLOT_CUT_OFF_MODAL = "SLOT_CUT_OFF_MODAL",
}

export interface AmendOrderModalProps {
  title: string;
  description: string;
  handleClose: () => void;
  FilledButtonLink: string;
  FilledButtonText: string;
  secondaryButtonLink?: string;
  secondaryButtonText?: string;
}

export enum ExpiryDetailsReason {
  SLOT_EXPIRATION = "SLOT_EXPIRATION",
  CUT_OFF_TIME = "CUT_OFF_TIME",
}

export enum ModalTypes {
  SLOT_EXPIRED_MODAL = "SLOT_EXPIRED_MODAL",
  SLOT_CUT_OFF_MODAL = "SLOT_CUT_OFF_MODAL",
}

export interface ExpiryStatus {
  isNearExpiry: boolean;
  expiryDateAndTime: string;
  expiryDateAndTimeFormatted: string;
}

export type ExpiryDetails =
  | (ExpiryStatus & {
      reason: ExpiryDetailsReason;
    })
  | null;

export interface AmendOrderBannerDetailsBaseProps {
  orderId: string;
  hasOccasionItems: boolean;
  viewOrderLink: string;
}

export interface AmendOrderBannerDetailsWithCountdownProps extends AmendOrderBannerDetailsBaseProps {
  expiryDateAndTime: string;
  onCountdownComplete: () => void;
}

export interface AmendOrderBannerCutOffDetailsProps extends AmendOrderBannerDetailsBaseProps {
  expiryDateAndTimeFormatted: string | null;
}

export interface AmendOrderBannerSlotExpiryDetailsProps extends AmendOrderBannerDetailsBaseProps {
  expiryDateAndTimeFormatted: string | null;
}

export interface AmendOrderBannerOrder {
  orderId: string;
  viewOrderLink: string;
  isCutOff: boolean;
}

export type AmendOrderBannerPrimaryCta = {
  link?: string;
  text: string;
};

export enum AmendOrderBannerActionTypes {
  SET_CUT_OFF_EXPIRED = "SET_CUT_OFF_EXPIRED",
}

export type SetCutOffExpiredAction = {
  type: AmendOrderBannerActionTypes;
};

export interface AmendOrderBannerLoadingIndicatorProps {
  usesFavouritesHeader: boolean;
}
