import { DataState } from "../../common/dataState";
import { State } from "../../common/store";

export const selectBookingKey = (state: State): string =>
  state.modal.meta ? state.modal.meta.selectedSlot.bookingKey : "";

export const selectStoreNumber = (state: State): string => (state.modal.meta ? state.modal.meta.storeNumber : "");

export function selectSlotStoreAndFlexi(state: State): {
  status: DataState;
  storeId: string | undefined;
  flexiStoreIds: string[] | undefined;
} {
  return {
    status: state.slotReservation.dataState,
    storeId: state.slotReservation.slotReservation.storeIdentifier || undefined,
    flexiStoreIds: state.slotReservation.slotReservation.reservationFlexiStores || undefined,
  };
}
