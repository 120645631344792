import { MagnoliaTemplate, TemplateArea } from "../../services/contentTypes";
import { DataState } from "../../common/dataState";
import { Controls, RequestFilters, ResponseFilter } from "../../services/product.types";

export enum BrandedActionTypes {
  SKUSANDCMSREQUEST = "SKUSANDCMSREQUEST",
  SKUSANDCMSFETCH = "SKUSANDCMSFETCH",
  SKUSANDCMSSUCCESS = "SKUSANDCMSSUCCESS",
  SKUSANDCMSFAILED = "SKUSANDCMSFAILED",
}

export interface BrandedPageComponentProps {
  products: string[];
  dataStatus: DataState;
  filters: ResponseFilter[];
  addProductForViewportAnalytics: any;
}

export interface BrandedHookReturn {
  landingURL: string;
  facetSlug: string | null;
  requestFilters: RequestFilters | undefined;
  sort: string;
  page: string;
}

export interface BrandedProductServiceProps {
  skus: string[];
  filters: RequestFilters | undefined;
  sort: string;
  page: string;
  storeIdentifier: string | undefined;
  flexiStores: string[] | undefined;
  slotDate: string | undefined;
  setControls?: (data: Controls) => void;
}

export interface BrandedTemplateResponse {
  citrus: {
    realisedBrandPageId: string;
    brandPageTemplateId: string;
    brandPageId: string;
    data: {
      type?: string;
      [key: string]: unknown;
      products?: BrandedSKUResponseProductObject[];
      meta?: {
        refinedPageHeader: string;
        refinedPageIntroCopy: string;
        refinedPageThemeColour: string;
        refinedPageBackgroundColour: string;
      };
    }[];
  };
  data: TemplateArea[];
  meta: {
    title: string;
    description: string;
  };
  message?: string;
}

export interface BrandedSKUResponseProductObject {
  name: string;
  productSelection: {
    productCode: string;
    catalogId: string;
    position: string;
    sellerId: string;
  }[];
}

export type BrandedStateSKUObject = {
  [key: string]: string[];
};

export interface BrandedPageState {
  dataState: DataState;
  data: {
    facetContent: MagnoliaTemplate;
    citrusContent: MagnoliaTemplate;
    meta: BrandedTemplateResponse["meta"];
    magnoliaContent: MagnoliaTemplate;
    skus: BrandedStateSKUObject[];
    header: {
      heading: string;
      introCopy: string;
      themeColour: string;
      backgroundColour: string;
    };
    footer: MagnoliaTemplate;
    brandPageId: string; // Required meta for citrus purpose
    serveId: string; // Required meta for citrus purpose
  };
}

export interface CreateFetchBrandedSKUsAndCMSSuccessActionType {
  type: BrandedActionTypes.SKUSANDCMSSUCCESS;
  payload: BrandedPageState["data"];
}

export interface BrandedQuickLinkGroup {
  type: string;
  included: {
    quickLinkName: string;
    type: string;
    name: string;
    link?: {
      url?: string;
    };
    onLinkClick: () => void;
  }[];
}
