import { Action } from "redux";

export interface BasicModalProps {
  title: string;
  description: string | JSX.Element;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => Action | void;
  onSecondaryButtonClick?: () => Action | void;
  onClose?: () => void;
  isOpen?: boolean;
  loadingMessage?: string;
  isLoading?: boolean;
  className?: string;
  accessibleButtonLabel?: string;
}

export interface ButtonWrapperProps {
  primaryButtonText: string;
  onPrimaryButtonClick: () => Action | void;
  loadingMessage?: string;
  isLoading?: boolean;
  accessibleLabel?: string;
  className: string;
}

export enum ModalNames {
  MODAL_RESERVE_SLOT = "reserveSlot",
  MODAL_SLOT_FAQ = "slotFaq",
  MODAL_GREEN_VAN_INFO = "greenVanInfo",
  MODAL_CONFIRM_AMEND = "confirmAmendMode",
  MODAL_CAC_FAQ = "clickAndCollectFaq",
  MODAL_BUYING_ALCOHOL = "buyingAlcohol",
  MODAL_AMEND_ORDER = "amendOrder",
}

export interface ModalState {
  currentlyVisible: string | null;
  meta?: {
    [key: string]: any;
  };
}

export enum ModalActionTypes {
  CURRENTLY_VISIBLE_MODAL = "CURRENTLY_VISIBLE_MODAL",
  CLOSE_CURRENTLY_VISIBLE_MODAL = "CLOSE_CURRENTLY_VISIBLE_MODAL",
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
}

export interface ModalCurrentlyVisibleAction {
  type: ModalActionTypes.CURRENTLY_VISIBLE_MODAL;
  name: string;
}

export interface CloseCurrentlyVisibleModalAction {
  type: ModalActionTypes.CLOSE_CURRENTLY_VISIBLE_MODAL;
  name: string;
}

export interface ReserveSlotMapStateToProps {
  title: string;
  description: JSX.Element;
  isOpen: boolean;
  primaryButtonText: string;
  secondaryButtonText: string;
  loadingMessage: string;
  isLoading: boolean;
  className: string;
}

export interface ReserveSlotMapDispatchToProps {
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
}

export interface DeliveryCostMapStateToProps {
  title: string;
  description: string;
  isOpen: boolean;
  primaryButtonText: string;
}

export interface DeliveryCostMapDispatchToProps {
  onPrimaryButtonClick: () => void;
}
