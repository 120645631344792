import { Method, Result } from "../common/http/request";
import { serviceRequest } from "./request";

const url = "/groceries-api/gol-services/recipe";

export interface ServiceDropdown {
  navLinkText: string;
  navLinkURL: string;
  tag: string;
  position: string;
  title: string;
  description: string;
  navLinkFooterText?: string[];
  navLinkFooterTextTitle?: string;
}

export interface ServiceCollection {
  topNavigationText: string;
  position: string;
  topNavDisplay: boolean;
  navLinkURL: string;
  l1NavDetails: ServiceDropdown[] | undefined;
  tag: string;
  title: string;
  description: string;
  footerText?: string[];
  footerTextTitle?: string;
}

export interface HierarchyServiceResponse {
  Hierarchy: ServiceCollection[];
}

export default function fetchRecipeHierarchy(): Promise<Result<HierarchyServiceResponse>> {
  return serviceRequest<HierarchyServiceResponse>({ method: Method.GET, url: `${url}/v1/recipe_hierarchy` });
}
