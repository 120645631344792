import isEmpty from "lodash.isempty";
import { GOLUI_PREFIX } from "../../routes";
import { Method } from "../../common/http/request";
import { serviceRequest } from "../../services/request";
import { BrowseMagnoliaResponse, RequestProps } from "./EventsFeatures.types";
import { BrowseProductsControlsParams, Controls } from "../../services/product.types";
import { ESPOT_IDS } from "../../components/Citrus/MultiAd/MultiAd.utils";
import { ContentBannerLocation, ContentPlacement } from "../../services/contentTypes";
import { retrieveBrowseDigitalDataProperties } from "../Browse/BrowseMagnoliaContent/BrowseAnalytics.utils";
import { trimSlashes } from "../../utils/trimSlashes/trimSlashes";

export const fetchForEventsAndFeatures = async ({
  template_id,
  storeId,
  delivery_date,
  region,
  has_nectar_associated,
  is_digital_customer,
  has_delivery_pass,
  is_very_important_customer,
}: RequestProps) => {
  const SHELF_AD_URL = "/groceries-api/gol-services/content/v2/withMagnoliaTemplate/ads";
  const categoryIdsArray = `/${template_id}`?.split(",");
  const requestBody: RequestProps = {
    template_id,
    storeId,
    delivery_date,
    placement: ContentPlacement.CATEGORY_ONLY,
    espotIds: ESPOT_IDS[ContentBannerLocation.EVENT_IN_GRID_1],
    slotId: ContentBannerLocation.SHELF_IN_GRID_1,
    region,
    has_nectar_associated,
    is_digital_customer,
    has_delivery_pass,
    is_very_important_customer,
    category_ids: categoryIdsArray,
  };

  return serviceRequest<BrowseMagnoliaResponse>({ method: Method.POST, url: SHELF_AD_URL, body: requestBody });
};

export const getEventsAndFeaturesSubStrings = (path: string) => {
  const OPTION_DELIMITER = "opt/";
  const { pathname, facetSlug } = stripAndExtractFacetValue(path);
  const segments: string[] = pathname.split(OPTION_DELIMITER);
  const options = segments.length > 1 ? OPTION_DELIMITER.concat(segments[1]) : "";
  const landingURL = isEventsRoute(path)
    ? `${GOLUI_PREFIX}/events/${pathname.split("/")[3]}`
    : `${GOLUI_PREFIX}/features/${pathname.split("/")[3]}`;
  const filters = trimSlashes(segments[0].replace(landingURL, ""));
  return {
    landingURL,
    options,
    filters,
    facetSlug,
  };
};

export const stripAndExtractFacetValue = (pathname: string) => {
  if (pathname.includes("facet")) {
    const facetSlug = pathname.split("/").find(slug => slug.includes("facet"));
    const strippedPathname = pathname.replace(`/${facetSlug}`, "");
    return {
      pathname: strippedPathname,
      facetSlug: facetSlug ?? null,
    };
  }
  return {
    pathname,
    facetSlug: null,
  };
};

export const isEventsRoute = (path: string): boolean => {
  return path ? path.includes("/gol-ui/events") : false;
};

export const isFeaturesRoute = (path: string): boolean => {
  return path ? path.includes("/gol-ui/features") : false;
};

export const validateControls = (controls: BrowseProductsControlsParams): BrowseProductsControlsParams => {
  const validEntries = Object.entries(controls).filter(c => !isEmpty(c) && !isEmpty(c[0]));
  return Object.fromEntries(validEntries) as BrowseProductsControlsParams;
};

// Utils for Analytics
export const retrieveEventsDigitalDataProperties = (controls?: Controls) => {
  // Resusing the browse digital data utils
  // TODO :  Move the browse method to a more common location
  return retrieveBrowseDigitalDataProperties("events", controls);
};
