import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../store";
import GolTime from "../../domain/time";
import { ActionTypes, DeferredAction, DeferredConditionalAction } from "./deferredConditionalAction.types";
import * as sessionStore from "../../common/sessionStorage";

const sessionStoragePrefix = "DEFERRED_ACTION_";

// TODO: add docs explaining how this works and the problems it's trying to solve
//  Limitation of the key is that we can only support one action per condition
//  Condition to re-dispatch: expiry time and type
export const deferredConditionalActionMiddleware =
  (api: MiddlewareAPI<Dispatch, State>) => (next: Dispatch) => (action: DeferredConditionalAction) => {
    switch (action.type) {
      case ActionTypes.PERSIST_CONDITIONAL_ACTION:
        const persistedActionKey = `${sessionStoragePrefix}${action.persistedAction.type}`;
        const existingAction = sessionStore.get(persistedActionKey);
        if (existingAction) {
          const actions = JSON.parse(existingAction) as DeferredAction[];
          sessionStore.set(persistedActionKey, JSON.stringify([...actions, action.persistedAction]));
          break;
        }
        sessionStore.set(persistedActionKey, JSON.stringify([action.persistedAction]));
        break;
      default:
        const key = `${sessionStoragePrefix}${action.type}`;
        const storedAction = sessionStore.get(key);
        if (storedAction) {
          const deferredActions = JSON.parse(storedAction) as DeferredAction[];
          deferredActions.forEach(a => {
            const isExpired = GolTime.now().isAfter(new GolTime(a.expiryTime));
            if (!isExpired) {
              api.dispatch(a.redispatchedAction);
            }
          });
          sessionStore.remove(key);
        }
        break;
    }

    next(action);
  };
