import { useState, useCallback, useMemo } from "react";
import { MenuItem } from "../Header.types";

export const useMegaNavLogic = (navItems: MenuItem[]) => {
  const [selectedCategory, setSelectedCategory] = useState<MenuItem | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<MenuItem | null>(null);

  const categories = useMemo(() => {
    const item = navItems?.find?.(e => e.title === "Groceries")?.items ?? null;
    return item ?? [];
  }, [navItems]);

  const createSubcategoryWithoutItems = useCallback(
    (subcategory: MenuItem): MenuItem => ({
      ...subcategory,
      items: undefined,
    }),
    []
  );

  const handleCategorySelect = useCallback((category: MenuItem | null) => {
    if (category?.items?.length) {
      setSelectedCategory(prevCategory => {
        setSelectedSubcategory(null);
        if (prevCategory?.id !== category.id) {
          window.digitalData?.track?.("ev_meganavClick", {
            hit_type: "link",
            data_event_action: "click",
            data_event_category: "meganav",
            data_event_label: category.title,
          });
          return category;
        } else {
          // Reset the category when it's clicked again
          return null;
        }
      });
    } else {
      setSelectedCategory(null);
      setSelectedSubcategory(null);
    }
  }, []);

  const handleSubcategorySelect = useCallback((subcategory: MenuItem | null) => {
    setSelectedSubcategory(prevSubcategory => {
      if (!subcategory?.items?.length || (prevSubcategory && prevSubcategory?.id === subcategory.id)) {
        // Reset the subcategory when it's clicked again
        return null;
      } else {
        window.digitalData?.track?.("ev_meganavClick", {
          hit_type: "link",
          data_event_action: "click",
          data_event_category: "meganav",
          data_event_label: subcategory.title,
        });
        return subcategory;
      }
    });
  }, []);

  const processedSelectedCategory = useMemo(() => {
    if (!selectedCategory) return null;
    return selectedCategory;
  }, [selectedCategory]);

  const processedSelectedSubcategory = useMemo(() => {
    if (!selectedSubcategory) return null;
    return selectedSubcategory;
  }, [selectedSubcategory]);

  return {
    categories,
    selectedCategory: processedSelectedCategory,
    selectedSubcategory: processedSelectedSubcategory,
    handleCategorySelect,
    handleSubcategorySelect,
    createSubcategoryWithoutItems,
  };
};
