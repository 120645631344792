import { DataState, WithDataState } from "../dataState";
import {
  RedirectsActions,
  RedirectsActionTypes,
  RedirectsSuccessActionType,
  RedirectsFailureActionType,
} from "./redirects.actions";
import { createDataStateReducer } from "../dataState/dataState.reducer";
import { combineReducers } from "redux";

export type Redirects = { [key: string]: string };

export interface RedirectsState extends WithDataState {
  searchTerms: Redirects;
}

export const redirectsInitialState: RedirectsState = {
  dataState: DataState.UNKNOWN,
  searchTerms: {},
};

const dataState = createDataStateReducer({
  [RedirectsActionTypes.REDIRECTS_REQUEST]: DataState.PENDING,
  [RedirectsActionTypes.REDIRECTS_SUCCESS]: DataState.SUCCESS,
  [RedirectsActionTypes.REDIRECTS_FAILURE]: DataState.FAILED,
});

export const redirectsDataReducer = (
  state = redirectsInitialState.searchTerms,
  action: RedirectsActions
): { [key: string]: string } => {
  switch (action.type) {
    case RedirectsActionTypes.REDIRECTS_SUCCESS:
      return (action as RedirectsSuccessActionType).searchTerms;
    case RedirectsActionTypes.REDIRECTS_FAILURE:
      return (action as RedirectsFailureActionType).searchTerms;
    default:
      return state;
  }
};

export const redirectsReducer = combineReducers({
  dataState,
  searchTerms: redirectsDataReducer,
});
