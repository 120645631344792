import React from "react";
import NectarSVG from "../../static/nectar-logo.svg";
import NectarWithoutTextSVG from "../../static/nectar-logo-without-text.svg";
import { Logo } from "./Logo";

export const NectarLogo = ({ className, withText = true }: { className?: string; withText?: boolean }) => {
  const image = withText ? NectarSVG : NectarWithoutTextSVG;
  const classNames = ["nectar-logo"];
  if (className) {
    classNames.push(className);
  }
  return (
    <Logo className={classNames.join(" ")}>
      <img data-testid="nectar-logo" role="presentation" src={image} alt="Nectar logo" className="logo-image" />
    </Logo>
  );
};

NectarLogo.displayName = "NectarLogo";
