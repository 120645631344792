import { useEffect, useRef, useCallback } from "react";
import { INTERACTION_EVENT_NAMES } from "../../analytics/types";
import { digitalDataGenericTrackEvent } from "../../analytics/digitalData";
import { BrandCitrusIdType, CitrusAnalyticsType, sendCitrusAnalytics } from "../../citrus";
import { getUserConsents } from "../../../utils/cookie";
import { DataState } from "../../../common/dataState";

interface State {
  products: Product[];
  brandCitrusIds?: BrandCitrusIdType;
}

export interface Product {
  id: string;
  offer: string;
  sent: boolean;
  key: string;
}

/**
 * The architecture is essentially that useProducts facilitates sending the events based on some products in a list,
 * but useTimedCall facilitates adding products to that list.
 * useProducts makes the analytics calls every 4 seconds.
 * In useTimedCall we have an intersection observer which starts a timer for 2 seconds when the element is intersecting at least 50%.
 * After those 2 seconds have passed we add the product to the list.
 *
 * @param searchStatus - The DataState of the request that was made to fetch the products being tracked.
 * @param lenProducts - The number of products that could be tracked. We may not reach this max value due to the user not viewing all of the products.
 * @param brandedCitrusInfo - Used by brand pages to track specific citrus attributes.
 * @returns two callbacks - addProductForViewportAnalytics and scrollCallback.
 * addProductForViewportAnalytics - This is how we add products to the batch for the next analytics call.
 * scrollCallback - A callback to start the timer.
 */

export const useProducts = (searchStatus: DataState, lenProducts: number, brandedCitrusInfo?: BrandCitrusIdType) => {
  const products = useRef<Product[]>([]);
  const threshold = lenProducts < 20 && lenProducts > 0 ? lenProducts : 20;
  const stateRef = useRef<State>({
    products: [],
    brandCitrusIds: brandedCitrusInfo ? brandedCitrusInfo : undefined,
  });
  const timer: null | NodeJS.Timeout = null;
  const timerRef = useRef<null | NodeJS.Timeout>(timer);

  // Sends products to analytics and mark them as sent
  const sendData = useCallback(() => {
    const ids = [];
    const offers = [];
    for (let i = 0; i < stateRef.current.products.length; i++) {
      if (!stateRef.current.products[parseInt(String(i))].sent) {
        ids.push(stateRef.current.products[parseInt(String(i))].id);
        offers.push(stateRef.current.products[parseInt(String(i))].offer);
        stateRef.current.products[parseInt(String(i))].sent = true;
      }
    }

    if (ids.length === 0) {
      return;
    }

    if (Boolean(brandedCitrusInfo)) {
      const { performance } = getUserConsents();
      if (performance && stateRef.current.brandCitrusIds) {
        const { brandPageId, serveId } = stateRef.current.brandCitrusIds;
        sendCitrusAnalytics({
          brandPageId,
          serveId,
          events: ids.map(id => ({
            productCode: id,
            eventType: CitrusAnalyticsType.IMPRESSION,
          })),
        });
      }
    } else {
      digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.PRODUCT_VIEW, {
        data_product_id: ids,
        data_product_offerType: offers,
      });
    }
  }, [brandedCitrusInfo]);

  // Starts a timer to call sendData regardless of the items to send
  // The timer gets reset every time the user scrolls (vertically on window, or horizontally on a carousel)
  // The idea is to make sure we track the items if the user stop doing any action
  const startTimer = useCallback(
    timer => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timer = null;
      }

      timerRef.current = setTimeout(() => {
        sendData();
      }, 4 * 1000);
    },
    [sendData]
  );

  // returned to handle carousel scrolling
  const scrollCallback = useCallback(() => {
    startTimer(timer);
  }, [startTimer]);

  // Resets lists on new search
  useEffect(() => {
    if (searchStatus === DataState.SUCCESS) {
      products.current = [];
      startTimer(timer);
    }
  }, [searchStatus, startTimer]);

  // add scroll debounce event to window
  // default window scroll
  useEffect(() => {
    startTimer(timer);

    window.addEventListener("scroll", scrollCallback);

    return () => {
      window.removeEventListener("scroll", scrollCallback);
    };
    // TO-DO: refactor when useEffectEvent is stable see https://react.dev/reference/react/experimental_useEffectEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Adds ID to tracked products list making sure it's unique in the list
  // Requires a key that is more unique than the id, so that we can add the same product that is in multiple carousels
  const addProductForViewportAnalytics = (id: string, offer: string, key: string) => {
    if (stateRef.current.products.filter(p => p.key === key).length > 0) return;
    products.current = [...products.current, { id, offer, sent: false, key }];
    const p = products.current.filter(p => !p.sent);
    stateRef.current.products = products.current;
    stateRef.current.brandCitrusIds = brandedCitrusInfo ? brandedCitrusInfo : undefined;
    if (lenProducts > 0 && p.length > 0 && (p.length >= threshold || p.length === lenProducts)) {
      sendData();
    }
  };

  return {
    addProductForViewportAnalytics,
    scrollCallback,
  };
};

export type UseProductsReturnType = ReturnType<typeof useProducts>;
