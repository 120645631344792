import { Dispatch } from "redux";
import { UserState } from "../../common/store";
import { DataState } from "../../common/dataState";
import { DietaryProfileStatus, User } from "../../domain/customer/customer";
import { CustomerProfileResponse, fetchCustomerProfile } from "../../services/customer";

export enum UserActionTypes {
  FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS",
  FETCH_USER_PENDING = "FETCH_USER_PENDING",
  FETCH_USER_FAILURE = "FETCH_USER_FAILURE",
}

export interface FetchUserAction {
  type: UserActionTypes.FETCH_USER_SUCCESS | UserActionTypes.FETCH_USER_PENDING | UserActionTypes.FETCH_USER_FAILURE;
  user: User;
}

export const fetchUserAction = () => (dispatch: Dispatch<any>, getState: () => { user: UserState }) => {
  const { user: stateUser } = getState();
  const userIsAuthorized = stateUser.userDetails ? stateUser.userDetails.isRegistered : false;
  const isUserStateUnknown = stateUser.dataState === DataState.UNKNOWN;
  if (isUserStateUnknown || !userIsAuthorized) {
    dispatch(fetchUser());
  }
};

export const fetchUser = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: UserActionTypes.FETCH_USER_PENDING } as FetchUserAction);

  try {
    const result = await fetchCustomerProfile();

    if (result.isSuccess()) {
      dispatch({ type: UserActionTypes.FETCH_USER_SUCCESS, user: mapUser(result.data) } as FetchUserAction);
      // temporary workaround for SAM until PCI Phase 4 is complete
      if (result.data.is_sam_agent) {
        localStorage.setItem("feature-flags", JSON.stringify({ pci_phase_2: false }));
      }
    } else {
      dispatch({ type: UserActionTypes.FETCH_USER_FAILURE } as FetchUserAction);
    }
  } catch (e) {
    dispatch({ type: UserActionTypes.FETCH_USER_FAILURE } as FetchUserAction);
  }
};

const mapUser = (res: CustomerProfileResponse): User => {
  return {
    id: res.user_id,
    customerId: res.customer_id,
    customerNumber: res.customer_number,
    websphereId: res.personalization_id,
    identityId: res.identity_id,
    isRegistered: res.is_registered,
    isNewCustomer: res.is_new_customer,
    givenName: res.given_name,
    familyName: res.family_name,
    email: res.email,
    phoneNumber: res.primary_phone,
    isRecurringSlotRegistered: res.is_recurring_slot_registered,
    dietaryProfile: mapDietaryProfile(res.dietary_profile),
    hasDeliveryPass: !!res.delivery_pass_expiry_date && new Date(res.delivery_pass_expiry_date) > new Date(),
    deliveryPassExpiryDate: res.delivery_pass_expiry_date,
    deliveryPassId: res.delivery_pass_id,
    isDeliveryPassAutoRenewalEnabled: res.delivery_pass_auto_renewal,
    hasNectarLinked: res.has_nectar_linked,
    hasNectarAssociated: res.has_nectar_associated,
    isDigitalCustomer: res.is_digital_nectar,
    isVeryImportantCustomer: res.is_very_important_customer,
    postcode: res.postcode,
    isColleague: res.is_colleague,
    isSamAgent: res.is_sam_agent,
    defaultSubstitutionPreference: res.default_substitution_preference,
  };
};

const mapDietaryProfile = (dietaryProfile: string): DietaryProfileStatus => {
  if (dietaryProfile === DietaryProfileStatus.ENABLED) {
    return DietaryProfileStatus.ENABLED;
  }

  if (dietaryProfile === DietaryProfileStatus.DISABLED) {
    return DietaryProfileStatus.DISABLED;
  }

  return DietaryProfileStatus.NONE;
};
