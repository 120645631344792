export interface BookDeliveryContainerProps {
  deliveryDate: string;
  deliveryTime: string;
  deliveryIsExpired: boolean;
  isLoaded: boolean;
  isPending: boolean;
  isFailed: boolean;
  isNoBooking: boolean;
  isGreen: boolean;
  deliveryTypeText: string;
  bookSlotUrl: string;
  chooseDeliveryMethod: () => void;
  bookSlot: () => void;
  isDelivery: boolean;
}

export interface BookDeliveryComponentProps extends BookDeliveryContainerProps {
  isMobile: boolean;
  isTablet: boolean;
}

export enum DeliveryType {
  COLLECT = "Collect",
  DELIVERY = "Delivery",
}
