import React from "react";

type ErrorPageProps = {
  header: string;
  description: string;
  image: JSX.Element;
  callToAction?: JSX.Element | string;
  isSectioned?: boolean; // This flag is used to determine if the error page is not the main content of the page
};
export const ErrorPage = ({ header, description, image, callToAction, isSectioned = false }: ErrorPageProps) => (
  <div
    id="main"
    className={`error-page ln-u-text-align-center ln-o-section padding-top-default ${
      !isSectioned ? "margin-top-default" : ""
    }`}
  >
    {!isSectioned ? <h1 className="error-page__header">{header}</h1> : <h2 className="ln-u-display-2">{header}</h2>}
    <div className="error-page__description">{description}</div>
    <div className="error-page__image">{image}</div>
    {callToAction && <div className="error-page__callToAction">{callToAction}</div>}
  </div>
);
ErrorPage.displayName = "ErrorPage";
