import { AnyAction } from "redux";
import { BOT_CHECK_STATUS } from "./CitrusBotDetect.actions";
import { BotDetectState } from "./CitrusBotDetect.types";

export const initialState = {
  isGoogleBot: undefined,
  clientIp: undefined,
};

export const detectBotReducer = (state: BotDetectState = initialState, action: AnyAction): BotDetectState => {
  switch (action.type) {
    case BOT_CHECK_STATUS:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
