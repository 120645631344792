import { Dispatch, Action } from "redux";
import { getQueryParameter } from "../url";
import { LocationState } from "../location/locationReducer";
import { mapMissions } from "../../domain/mission/missions";
import { Mission } from "../../domain/mission/mission.types";
import { MissionsResponse } from "../../services/customer";

const defaultCustomerSearch = `{"missions": [{ "name": "PIANO", "is_origin": true }]}`;

const getGolAppDataStore = () => (window as any).GOLAPP;

const waitForDataFromGolApp = (name: string, callback: (value: string) => void) => {
  const golAppDataStore = getGolAppDataStore();
  Object.defineProperty(golAppDataStore, name, {
    set(value: string) {
      callback(value);
    },
  });
};

export const getItemFromWebView = (name: string) =>
  new Promise((resolve, reject) => {
    const golAppDataStore = getGolAppDataStore();
    if (golAppDataStore[`${name}`]) {
      resolve(golAppDataStore[`${name}`]);
    } else {
      waitForDataFromGolApp(name, resolve);
    }
  });

export enum GolAppDataSuccess {
  GOL_APP_DATA_SUCCESS = "GOL_APP_DATA_SUCCESS",
}

export type GolAppDataSuccessAction = {
  type: GolAppDataSuccess.GOL_APP_DATA_SUCCESS;
  data: any;
};

export const createGolAppDataSuccess = (data: any): GolAppDataSuccessAction => ({
  type: GolAppDataSuccess.GOL_APP_DATA_SUCCESS,
  data,
});

export const createGolAppData = () => async (dispatch: Dispatch<Action>) => {
  const verifiedEmail = await getItemFromWebView("ProgRegVerifiedEmail");

  // once the apps are setting CustomerSearchResponse, we can delete the 'default' functionality
  const search = Promise.race([
    getItemFromWebView("CustomerSearchResponse"),
    new Promise(resolve => {
      setTimeout(() => resolve(defaultCustomerSearch), 1000);
    }),
  ]);

  const humanVerification = Promise.race([
    getItemFromWebView("HumanVerificationToken"),
    new Promise(resolve => {
      setTimeout(() => resolve(""), 1000);
    }),
  ]);

  const [searchResponse, humanVerificationToken] = await Promise.all([search, humanVerification]);

  const response: MissionsResponse = JSON.parse(searchResponse as string);
  const missions: Mission[] = mapMissions(response.missions);

  dispatch(createGolAppDataSuccess({ verifiedEmail, missions, humanVerificationToken }));
};

export const isInsideWebView = ({ location }: { location: LocationState }): boolean =>
  getQueryParameter(location.current, "ua_owner") === "golapp";
