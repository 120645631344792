import { useEffect, useMemo } from "react";
import { useFeatureFlag } from "../../../../common/firebase/featureFlags.hooks";
import { filterTrendingSearchestoCurrentWeek } from "../utils/restingSearch";
import { useServiceRequestCallback } from "../../../../services/hooks";
import { fetchTrendingSearches } from "../../../../services/product";
import { DataState } from "../../../../common/dataState";

export interface RestingSearchV2 {
  suggested_enabled: boolean;
  magnolia_enabled: boolean;
  track_experiment: boolean;
  terms: string[];
  status: DataState;
}

export const useTrendingSearch = (): RestingSearchV2 => {
  const restingSearchFF = useFeatureFlag("resting_search");
  const restingSearchV2FF = useFeatureFlag("resting_search_v2");
  const [data, fetchTrendingSearchesCallback] = useServiceRequestCallback(fetchTrendingSearches);

  useEffect(() => {
    if (restingSearchV2FF) {
      fetchTrendingSearchesCallback();
    }
  }, [fetchTrendingSearchesCallback, restingSearchV2FF]);

  const restingSearch = useMemo(() => {
    return filterTrendingSearchestoCurrentWeek(restingSearchFF);
  }, [restingSearchFF]);

  return {
    ...restingSearchFF,
    status: restingSearchV2FF ? data.status : DataState.SUCCESS,
    terms: restingSearchV2FF ? data?.data?.terms ?? [] : restingSearch.terms.map(term => term.SEARCH_TERM),
  };
};
