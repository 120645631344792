import { PreviousOrder, ProductsStatus } from "./previousOrders.types";
import { State } from "../../common/store";
import GolTime from "../../domain/time";
import { getQueryParameter } from "../../common/url";
import orderBy from "lodash.orderby";
import { DataState } from "../../common/dataState";
import { formatCurrency } from "../../common/format";
import { Order } from "../../domain/order/order.types";
import { createProductsByCategorySelector } from "../product.selectors";
import { ProductsViews } from "../../domain/product/product.types";

export const selectIsError = (state: State): boolean =>
  state.orders.dataState === DataState.FAILED || state.previousOrders.dataState === DataState.FAILED;

export const selectAreOrdersPending = (state: State): boolean => state.orders.dataState === DataState.PENDING;

export const selectOrdersDataState = (state: State): DataState => state.orders.dataState;

export const selectPreviousOrderStatus = (state: State): ProductsStatus => {
  if (state.previousOrders.dataState === DataState.PENDING) {
    return ProductsStatus.PENDING;
  }

  if (state.previousOrders.dataState === DataState.SUCCESS) {
    return ProductsStatus.READY;
  }

  return ProductsStatus.UNKNOWN;
};

export const selectAddAllToTrolleyPending = (state: State): boolean =>
  state.previousOrders.addAllToTrolleyDataState === DataState.PENDING;

export const selectPreviousOrders = (state: State): PreviousOrder[] =>
  state.orders.data.orders.length > 0
    ? orderBy(state.orders.data.orders, "slotStartTime", "desc").map(mapPreviousOrder)
    : [];

export const selectActivePreviousOrder = (state: State): PreviousOrder => {
  const orderUid = getQueryParameter(state.router.location.search, "order");
  const ordersByDate = orderBy(state.orders.data.orders, "slotStartTime", "desc");
  if (!orderUid) {
    return mapPreviousOrder(ordersByDate[0]);
  }

  const order = ordersByDate.filter(o => o.orderUid === orderUid).pop();
  return order ? mapPreviousOrder(order!) : mapPreviousOrder(ordersByDate[0]);
};

export const selectPreviousOrdersByCategory = (state: State) =>
  createProductsByCategorySelector(state, ProductsViews.PREVIOUS_ORDERS);

// Ensure in-grid espots receive slotReservation
export const selectEspots = (state: State) =>
  state.slotReservation.dataState === DataState.SUCCESS || state.slotReservation.dataState === DataState.FAILED
    ? state.favourites.gridEspots
    : undefined;

/**
 * @param state
 * @returns the most recently delivered order
 */
export function selectLastDeliveredOrder(state: State): Order | undefined {
  return orderBy(
    state.orders.data?.orders.filter(order => order.status === "previous"),
    "slotStartTime",
    "desc"
  )[0];
}

const mapPreviousOrder = (order: Order): PreviousOrder => {
  return {
    time: formatOrder(order),
    orderUid: order.orderUid,
  };
};

const formatOrder = (order: Order): string => {
  const golTime = GolTime.fromServices(order.slotStartTime);
  const golEndTime = GolTime.fromServices(order.slotEndTime);
  return `${golTime.formatDateTimeInterval(golEndTime.timestamp)} - ${formatCurrency(order.total)}`;
};
