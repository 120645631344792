import { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { DataState } from "../../dataState";

/**
 * Debounces state transitions.
 *
 * Motivation: often we'll render some loading UI while some data is being fetched. When on a fast internet connection and the request completes
 * very quickly this will mean we see a flash of loading UI followed by the expected content which is not a smooth user experience. This hook
 * limits the minimum amount of time some loading UI should be on the page to 1 second so that users don't see a flash of content.
 *
 * Note: this does not take in to account if the request was successful, you have to handle that yourself in the component.
 * @param dataState the state of whatever request is in progress.
 * @returns isLoading if the request is loading or not.
 */
export const useLoadingDebounce = (dataState: DataState) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const debouncedFn = debounce(() => {
      setIsLoading(false);
    }, 1000);

    if (dataState === DataState.PENDING || dataState === DataState.UNKNOWN) {
      setIsLoading(true);
    } else {
      debouncedFn();
    }

    return debouncedFn.cancel;
  }, [dataState]);

  return { isLoading };
};
