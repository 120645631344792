import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../common/store";
import { createCloseCurrentlyVisibleModalAction, createCurrentlyVisibleModalAction } from "./modal.actions";
import { ModalNames, ModalWrapperComponentProps } from "./Modal.types";
import { clearAllBasketErrorsActionCreator } from "../../domain/basket/basket.actions";

export function modalWithWrapper<T extends Object>(WrappedComponent: React.ComponentType<T>, modalName: ModalNames) {
  const selectModalOpen = (state: State): boolean =>
    state.modal.currentlyVisible === null || state.modal.currentlyVisible === modalName;

  const ModalWrapperComponent = (props: ModalWrapperComponentProps<T>) => {
    const modalOpen = useSelector(selectModalOpen);

    const dispatch = useDispatch();

    const dispatchModalOpenActionCreator = useCallback(() => {
      dispatch(createCurrentlyVisibleModalAction(modalName));
    }, [dispatch]);

    const dispatchModalCloseActionCreator = useCallback(() => {
      dispatch(createCloseCurrentlyVisibleModalAction());
      dispatch(clearAllBasketErrorsActionCreator());
    }, [dispatch]);

    const { isOpen } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
      let isOpenAlter = false;

      if (isOpen && modalOpen) {
        dispatchModalOpenActionCreator();
        isOpenAlter = true;
      }

      setIsModalOpen(isOpenAlter);
    }, [dispatchModalOpenActionCreator, isOpen, modalOpen]);

    useEffect(() => {
      return () => dispatchModalCloseActionCreator();
    }, [dispatchModalCloseActionCreator]);

    return <WrappedComponent {...props} isOpen={isModalOpen} />;
  };

  return ModalWrapperComponent;
}
