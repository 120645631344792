import { Method, Result } from "../common/http/request";
import { Optional } from "../common/types";
import { serviceRequest } from "./request";
import { Coupons } from "./coupon.types";

const url = "/groceries-api/gol-services/coupon";

export const fetchCoupons = (baseUrl: Optional<string> = url): Promise<Result<Coupons>> => {
  return serviceRequest({ method: Method.GET, url: `${baseUrl}/v1/coupon` });
};

export const applyCouponRequest = (
  couponCode: string,
  isOmniChannelCoupon: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<void>> => {
  return serviceRequest<void>({
    method: Method.POST,
    url: `${baseUrl}/v1/coupon/apply`,
    body: {
      coupon_code: couponCode,
      occ_coupon: isOmniChannelCoupon,
    },
  });
};

export const removeCouponRequest = (
  couponCode: string,
  isOmniChannelCoupon: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<void>> => {
  return serviceRequest<void>({
    method: Method.DELETE,
    url: `${baseUrl}/v1/coupon/apply`,
    body: {
      coupon_code: couponCode,
      occ_coupon: isOmniChannelCoupon,
    },
  });
};
