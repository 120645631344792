import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { App } from "./App";
import "./index.scss";
import { serviceWorkerClient } from "./common/serviceWorkerClient";
import { StoreProvider, browserHistory } from "./store-provider";
import { featureFlags } from "./common/firebase/featureFlags";
import { ResponsiveProvider } from "./components/ResponsiveProvider";

(async () => {
  await featureFlags.sync();
  Object.freeze(featureFlags);

  if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MSW === "true") {
    const { worker } = require("./testing/mswMocks/browser");
    worker.start({
      findWorker: (scriptURL: string, _mockServiceWorkerUrl: string) => scriptURL.includes("mockServiceWorker"),
    });
  }

  ReactDOM.render(
    <StoreProvider>
      <ConnectedRouter history={browserHistory!}>
        <ResponsiveProvider>
          <App />
        </ResponsiveProvider>
      </ConnectedRouter>
    </StoreProvider>,
    document.getElementById("root")
  );

  await serviceWorkerClient.register();
})();
