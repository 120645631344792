import * as React from "react";
import { Container } from "@jsluna/react";
import { WithChildren } from "../../common/types";
import { HTMLAttributes } from "react";

export const ContentWrap = ({ children, ...rest }: WithChildren & HTMLAttributes<any> & ContentWrapProps) => (
  <Container soft {...rest}>
    {children}
  </Container>
);

interface ContentWrapProps {
  soft?: Boolean;
}
