import { Dispatch } from "redux";
import {
  ExternalNavigationAction,
  InternalNavigationAction,
  NavigationActionTypes,
} from "../../../common/middleware/navigationMiddleware";
import { State } from "../../../common/store";
import { getChooseShoppingMethodUrl } from "../../../routes";
import { getBookSlotUrl } from "../../../domain/slot/slotReservation";

export const chooseShoppingMethodNavigationAction = (dispatch: Dispatch): InternalNavigationAction => {
  return dispatch<InternalNavigationAction>({
    type: NavigationActionTypes.INTERNAL_NAVIGATION,
    path: getChooseShoppingMethodUrl(),
  });
};

export const bookSlotAction = (dispatch: Dispatch, getState: () => State): ExternalNavigationAction => {
  return dispatch<ExternalNavigationAction>({
    type: NavigationActionTypes.EXTERNAL_NAVIGATION,
    path: getBookSlotUrl(getState().slotReservation.slotReservation),
  });
};
