import React from "react";
import { ContentWrap } from "../ContentWrap";
import { externalUrls } from "../../routes";

export const FooterContent = () => (
  <ContentWrap soft={false} className="footer">
    <div className="footer__links">
      <a
        className="footer__link"
        data-testid="footer-link-accessibility"
        href="https://help.sainsburys.co.uk/help/terms-and-conditions/our-commitment"
      >
        Accessibility
      </a>
      <a
        className="footer__link"
        data-testid="footer-link-cookie-policy"
        href="https://privacy-hub.sainsburys.co.uk/cookie-policy/"
      >
        Cookie policy
      </a>
      <a
        className="footer__link"
        data-testid="footer-link-keyworker-discount"
        href="https://secure.gocertify.me/at/sainsburys/key-worker"
      >
        Keyworker Discount
      </a>
      <a
        className="footer__link"
        data-testid="footer-link-privacy"
        href="https://privacy-hub.sainsburys.co.uk/privacy-policy/"
      >
        Privacy policy
      </a>
      <button className="footer__link optanon-toggle-display" data-testid="cookie-law-link">
        Manage Cookies
      </button>
      <a
        className="footer__link"
        data-testid="footer-link-contact-us"
        href="https://help.sainsburys.co.uk/help/how_to_contact_us"
      >
        Contact us
      </a>
      <a className="footer__link" data-testid="footer-link-help-centre" href="https://help.sainsburys.co.uk/">
        Help centre
      </a>
      <a
        className="footer__link"
        data-testid="footer-link-terms-and-conditions"
        href={externalUrls.TERMS_AND_CONDITIONS}
      >
        Terms &amp; Conditions
      </a>
    </div>
  </ContentWrap>
);

FooterContent.displayName = "FooterContent";
