import { LookupTable } from "../../common/lookupTable";
import { DataState, WithDataState } from "../../common/dataState";
import { OrderPromiseStatus, Product, ProductType, Promotion } from "../product/product.types";
import { FulfilmentState } from "../../common/dataLayer/types";
import { BasketItem } from "../../services/basket.types";

export interface BasketProduct {
  sku: string;
  productUrl: string;
  image: string;
  itemId: string;
  productName: string;
  productType: ProductType;
  quantity: number;
  subTotal: number;
  unitOfMeasure: UnitOfMeasure;
  promotions: BasketPromotion[];
  selectedCatchweight?: string;
  allowSubstitutions?: boolean;
  totalBasePrice?: string;
  isYourNectarPrice?: boolean;
  maxQuantityLimit?: number;
  orderPromise?: BasketOrderPromise;
  isSupplyChainOrderable?: boolean;
}

export type BasketOrderPromise = {
  earliestPromiseDate: string;
  lastAmendmentDate?: string;
  status: OrderPromiseStatus;
};

export type BasketPromotion = Pick<
  Promotion,
  | "strapLine"
  | "url"
  | "promotionUid"
  | "promotionMissed"
  | "isNectarPrice"
  | "startDate"
  | "endDate"
  | "originalPrice"
  | "type"
>;

export enum BasketPromotionTypes {
  SIMPLE = "Simple",
  COMPLEX = "Complex",
}

export interface DeletedBasketProduct {
  product: Product;
  clickSource: ClickSource;
  selectedCatchweight: string;
  unit: string;
}

export type UnitOfMeasure = string;
export const KG: UnitOfMeasure = "kg";
export const ITEMS: UnitOfMeasure = "ea";

export interface BasketErrors {
  scottishLegislation?: BasketError;
  bulkOrderLimitExceeded?: BasketError;
  itemQuantityExceeded?: BasketError;
  maximumBasketLimitExceeded?: BasketError;
  addOrderToBasketErrors?: BasketAddOrderError[];
  dietaryProfileError?: BasketError;
}

export interface BasketError {
  title: string;
  detail: string;
  url?: string;
}

export interface BasketCouponError {
  code: string;
  message: string;
}

export interface BasketAddOrderError {
  code: string;
  detail: string;
  productName: string;
  infoUrl?: string;
}

export enum BasketErrorKeys {
  SCOTTISH_LEGISLATION = "scottishLegislation",
  BULK_ORDER_LIMIT_EXCEEDED = "bulkOrderLimitExceeded",
  ITEM_QUANTITY_EXCEEDED = "itemQuantityExceeded",
  MAXIMUM_BASKET_LIMIT_EXCEEDED = "maximumBasketLimitExceeded",
  ADD_ORDER_TO_BASKET_ERRORS = "addOrderToBasketErrors",
}

export interface Basket {
  basketId: string;
  orderId: string;
  subTotal: number;
  total: number;
  savings: number;
  nectarSavings: number;
  vouchersSavings: number;
  slotPrice?: number;
  minimumSpend: number;
  hasExceededMinimumSpend: boolean;
  longestPromiseDate?: string;
  isSlotLocked?: boolean;
  itemCount: number;
  items: BasketProduct[];
  isInAmendMode: boolean;
  delivery_instructions: string;
  hasYnpItemsInBasket: boolean;
  error?: BasketCouponError[];
}

export interface BasketData {
  items: LookupTable<BasketProduct, "sku">;
}

export interface BasketState extends WithDataState {
  basketDetails: Basket; // TODO this will be removed in a future commit and replaced with a list view inside `data`
  data: BasketData;
  errors: BasketErrors;
  quantities: {
    [key: string]: {
      quantity: number;
      loading: boolean;
      loadingCatchweight?: boolean;
      lastTileId?: string;
    };
  };
  deletedFromBasket: DeletedBasketProduct[];
}

export type BasketScottishLegislationErrorAction = {
  type: BasketActionTypes.BASKET_SCOTTISH_LEGISLATION_ERROR;
  title: string;
  detail: string;
};

export type BasketBulkOrderLimitExceededErrorAction = {
  type: BasketActionTypes.BASKET_BULK_ORDER_LIMIT_EXCEEDED_ERROR;
  title: string;
  detail: string;
};

export type BasketItemQuantityExceededErrorAction = {
  type: BasketActionTypes.BASKET_ITEM_QUANTITY_EXCEEDED_ERROR;
  title: string;
  detail: string;
};

export type BasketMaximumLimitExceededErrorAction = {
  type: BasketActionTypes.BASKET_MAXIMUM_LIMIT_EXCEEDED_ERROR;
  payload: BasketError;
};

export type FetchBasketSuccessAction = {
  type: BasketActionTypes.FETCH_BASKET_SUCCESS;
  basket: Basket;
};

export type ClearBasketErrorAction = {
  type: BasketActionTypes.CLEAR_BASKET_ERROR;
  error: string;
};

export type DeleteBasketSuccessAction = {
  type: BasketActionTypes.DELETE_BASKET_SUCCESS;
};

export type UpdateSubstitutionPreferencesSuccessAction = {
  type: BasketActionTypes.UPDATE_SUBSTITUTION_PREFERENCES_SUCCESS;
  basket: Basket;
};

export type UpdateLocalQuantityAction = {
  type: BasketActionTypes.UPDATE_LOCAL_QUANTITY;
  sku: string;
  quantity: number;
  tileId?: string;
};

export type UpdateBasketRequestAction = {
  type: BasketActionTypes.UPDATE_BASKET_REQUEST;
  sku: string;
  updateType: BasketUpdateType;
  quantityChange: number;
  itemId: string;
  uom: string;
  quantity: number;
  selectedCatchweight?: string;
  tileId?: string;
};

export type AddToBasketOnLoginSuccessAction = {
  type: BasketActionTypes.ADD_TO_BASKET_ON_LOGIN_SUCCESS;
  sku: string;
  unitOfMeasure: UnitOfMeasure;
  selectedCatchweight: string;
  product?: Product;
  basketProduct?: BasketProduct;
};

export type ChangeUnitOfMeasureOnLoginSuccessAction = {
  type: BasketActionTypes.CHANGE_UNIT_OF_MEASURE_ON_LOGIN_SUCCESS;
  sku: string;
  unitOfMeasure: UnitOfMeasure;
  clickSource: ClickSource;
  basketProduct?: BasketProduct;
  product?: Product;
};

export type CreateDeletedProductAction = {
  type: BasketActionTypes.CREATE_DELETED_PRODUCT_ACTION;
  product: DeletedBasketProduct;
};

export type RemoveDeletedProductAction = {
  type: BasketActionTypes.REMOVE_DELETED_PRODUCT_ACTION;
  sku: string;
};

export type CleanDeletedProductsAction = {
  type: BasketActionTypes.CLEAN_DELETED_PRODUCTS_ACTION;
};

export interface CreateUpdateBasketSuccessActionType {
  type: BasketActionTypes.UPDATE_BASKET_SUCCESS;
  basket: Basket;
}

export enum BasketUpdateType {
  ADD,
  SUBTRACT,
}

export enum BasketActionTypes {
  FETCH_BASKET_PENDING = "FETCH_BASKET_PENDING",
  FETCH_BASKET_SUCCESS = "FETCH_BASKET_SUCCESS",
  FETCH_BASKET_ERROR = "FETCH_BASKET_ERROR",

  ADD_TO_BASKET_ON_LOGIN_SUCCESS = "ADD_TO_BASKET_ON_LOGIN_SUCCESS",
  CHANGE_UNIT_OF_MEASURE_ON_LOGIN_SUCCESS = "CHANGE_UNIT_OF_MEASURE_ON_LOGIN_SUCCESS",

  DELETE_BASKET_SUCCESS = "DELETE_BASKET_SUCCESS",

  BASKET_SCOTTISH_LEGISLATION_ERROR = "BASKET_SCOTTISH_LEGISLATION_ERROR",
  BASKET_BULK_ORDER_LIMIT_EXCEEDED_ERROR = "BASKET_BULK_ORDER_LIMIT_EXCEEDED_ERROR",
  BASKET_ITEM_QUANTITY_EXCEEDED_ERROR = "BASKET_ITEM_QUANTITY_EXCEEDED_ERROR",
  BASKET_MAXIMUM_LIMIT_EXCEEDED_ERROR = "BASKET_MAXIMUM_LIMIT_EXCEEDED_ERROR",
  CLEAR_BASKET_ERROR = "CLEAR_BASKET_ERROR",
  CLEAR_ALL_BASKET_ERRORS = "CLEAR_ALL_BASKET_ERRORS",

  UPDATE_BASKET_REQUEST = "UPDATE_BASKET_REQUEST",
  UPDATE_BASKET_SUCCESS = "UPDATE_BASKET_SUCCESS",
  UPDATE_BASKET_ERROR = "UPDATE_BASKET_ERROR",
  UPDATE_BASKET_ADOBE_ANALYTICS_REQUEST = "UPDATE_BASKET_ADOBE_ANALYTICS_REQUEST",

  UPDATE_LOCAL_QUANTITY = "UPDATE_LOCAL_QUANTITY",
  UPDATE_LOCAL_AMEND_MODE = "UPDATE_LOCAL_AMEND_MODE",

  UPDATE_SUBSTITUTION_PREFERENCES_SUCCESS = "UPDATE_SUBSTITUTION_PREFERENCES_SUCCESS",
  CREATE_DELETED_PRODUCT_ACTION = "CREATE_DELETED_PRODUCT_ACTION",
  REMOVE_DELETED_PRODUCT_ACTION = "REMOVE_DELETED_PRODUCT_ACTION",
  CLEAN_DELETED_PRODUCTS_ACTION = "CLEAN_DELETED_PRODUCTS_ACTION",
}

export enum ClickSource {
  MINI_TROLLEY = "miniTrolley",
  PRODUCT_GRID = "productGrid",
  PRODUCT_DETAILS = "product_details",
  TROLLEY = "trolley",
  CHANGES_TO_TROLLEY = "changesToTrolley",
  NECTAR_ADD_ALL_BONUS_POINTS = "nectarAddAllBonusPoints",
  ADD_ALL_PRODUCTS = "addAllProducts",
  FREQUENTLY_BOUGHT_TOGETHER = "frequentlyBoughtTogether",
  OUT_OF_STOCK = "outOfStock",
  VIEW_SIMILAR = "viewSimilar",
}

export interface CreateUpdateBasketRequestActionParams {
  sku: string;
  quantityChange: number;
  uom: UnitOfMeasure;
  selectedCatchweight?: string;
  clickSource: ClickSource;
  product?: Product;
  basketProduct?: BasketProduct;
  tileId?: string;
  carouselKey?: string;
  fulfilmentState?: FulfilmentState;
}

export interface CreateUpdateBasketAdobeAnalyticsRequestActionParams {
  sku: string;
  quantityChange: number;
  uom: UnitOfMeasure;
  selectedCatchweight?: string;
  clickSource: ClickSource;
  product?: Product;
  basketProduct?: BasketProduct;
  basketProductQuantity?: number;
  basketProductSubtotalPrice?: number;
  basketProductTotalBasePrice?: number;
  basketPriceIncrement?: number;
  nectarPromotion?: BasketPromotion;
  carouselKey?: string;
  fulfilmentState?: FulfilmentState;
  basketItem?: BasketItem;
}

export interface CreateUpdateBasketAdobeAnalyticsRequestActionType {
  type: BasketActionTypes.UPDATE_BASKET_ADOBE_ANALYTICS_REQUEST;
  sku: string;
  quantityChange: number;
  uom: UnitOfMeasure;
  selectedCatchweight?: string;
  clickSource: ClickSource;
  product?: Product;
  basketProduct?: BasketProduct;
  basketProductQuantity?: number;
  basketProductSubtotalPrice?: number;
  basketProductTotalBasePrice?: number;
  basketPriceIncrement?: number;
  nectarPromotion?: BasketPromotion;
  carouselKey?: string;
  fulfilmentState?: FulfilmentState;
  basketItem?: BasketItem;
}

export interface CreateUpdateBasketRequestActionType {
  type: BasketActionTypes.UPDATE_BASKET_REQUEST;
  sku: string;
  quantityChange: number;
  uom: UnitOfMeasure;
  selectedCatchweight?: string;
  clickSource: ClickSource;
  product?: Product;
  basketProduct?: BasketProduct;
  tileId?: string;
  carouselKey?: string;
  fulfilmentState?: FulfilmentState;
}

export interface CreateUpdateBasketErrorActionType {
  type: BasketActionTypes.UPDATE_BASKET_ERROR;
  sku: string;
}

export type ChangeUnitOfMeasureActionParams = {
  sku: string;
  unitOfMeasure: UnitOfMeasure;
  clickSource: ClickSource;
  calculateOrder?: boolean;
  basketProduct?: BasketProduct;
  product?: Product;
};

export type UseBasket = {
  basket: Basket;
  dataState: DataState;
  error: BasketErrors;
  deletedFromBasket: DeletedBasketProduct[];
  checkoutEnabled: boolean;
  hasOccasionItems: boolean;
  isAllItemsAmendable: boolean;
  isSomeItemsNonAmendableSoon: boolean;
  fetchBasket: (calculateOrder?: boolean) => void;
  emptyBasket: () => void;
};

export type UseBasketItem = {
  quantity: number;
  unit: string;
  catchweight?: string;
  isLoading: boolean;
  isLoadingCatchweight?: boolean;
  increment: (incrementParams: IncrementParams) => void;
  decrement: (decrementParams: DecrementParams) => void;
  setQuantity: (setQuantityParams: SetQuantityParams) => void;
  setUnitOfMeasure: (setUnitParams: SetUnitParams) => void;
  setCatchweight: (selectedCatchweight: string, clickSource: ClickSource, product?: Product, tileId?: string) => void;
  setSubstitutionPreference: (allowSubstitutes: boolean) => void;
  orderPromiseStatus?: OrderPromiseStatus;
  lastTileId: string;
};

export type SetQuantityParams = {
  newQuantity: number;
  unit: UnitOfMeasure;
  catchweight?: string;
  productType?: ProductType;
  clickSource: ClickSource;
  calculateOrder?: boolean;
  delay?: boolean;
  product?: Product;
  decrement?: boolean;
  carouselKey?: string;
  tileId?: string;
  fulfilmentState?: FulfilmentState;
};

export type SetOrderPromiseStatusParams = {
  sku: string;
  product: Product;
  orderPromiseStatus: OrderPromiseStatus;
};

export type SetUnitParams = {
  sku: string;
  unit: UnitOfMeasure;
  clickSource: ClickSource;
  calculateOrder?: boolean;
  product?: Product;
};

export type IncrementParams = {
  unit: UnitOfMeasure;
  catchweight?: string;
  productType?: ProductType;
  clickSource: ClickSource;
  calculateOrder?: boolean;
  product?: Product;
  carouselKey?: string;
  tileId?: string;
  fulfilmentState?: FulfilmentState;
};

export type DecrementParams = {
  unit: UnitOfMeasure;
  catchweight?: string;
  clickSource: ClickSource;
  product?: Product;
  tileId?: string;
  fulfilmentState?: FulfilmentState;
};

export type UpdateQuantityModalProps = {
  quantity: number;
  unit: string;
  setQuantity: (quantity: number) => void;
  closeModal: () => void;
  productName: string;
};
