import { LoginState } from "../store";
import { Action } from "redux";
import { LoginActionTypes } from "./login.actions";
import { GolAppDataSuccess, GolAppDataSuccessAction } from "../golapp";
import { ProgressiveRegistrationActionTypes } from "../../views/ProgressiveRegistration/types";

export const loginInitialState: LoginState = {
  verifiedEmail: "",
  progressiveRegistrationPending: false,
  missions: [],
};

export const loginReducer = (state: LoginState = loginInitialState, action: Action): LoginState => {
  switch (action.type) {
    case ProgressiveRegistrationActionTypes.PROGRESSIVE_REGISTRATION_REQUEST:
      return {
        ...state,
        progressiveRegistrationPending: true,
      };

    case ProgressiveRegistrationActionTypes.PROGRESSIVE_REGISTRATION_SUCCESS:
    case ProgressiveRegistrationActionTypes.PROGRESSIVE_REGISTRATION_FAILURE:
      return {
        ...state,
        progressiveRegistrationPending: false,
      };

    case GolAppDataSuccess.GOL_APP_DATA_SUCCESS:
      const appDataSuccessAction = action as GolAppDataSuccessAction;
      return {
        ...loginInitialState,
        verifiedEmail: appDataSuccessAction.data.verifiedEmail,
        missions: appDataSuccessAction.data.missions,
      };

    case LoginActionTypes.RESET_STATE:
      return loginInitialState;

    default:
      return state;
  }
};
