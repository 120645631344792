import { Action, Dispatch } from "redux";
import {
  FetchOriginalSlotReservationFailureAction,
  FetchOriginalSlotReservationRequestAction,
  FetchOriginalSlotReservationSuccessAction,
  FetchSlotReservationFailureAction,
  FetchSlotReservationRequestAction,
  FetchSlotReservationSuccessAction,
  SetReservationExpiredAction,
  SlotReservation,
  SlotReservationActionTypes,
  SlotReservationState,
} from "./slotReservation.types";
import {
  cacheSlotReservation,
  deleteCachedSlotReservation,
  isValidSlotReservationResponse,
  mapSlotReservation,
} from "./slotReservation";
import { setSelectedSlotTypeActionCreator } from "./slotList.actions";
import { State } from "../../common/store";
import {
  fetchSlotReservationByOrderUid,
  fetchSlotReservation as servicesFetchSlotReservation,
} from "../../services/slot";

export const fetchSlotReservationRequestActionCreator = (): FetchSlotReservationRequestAction => ({
  type: SlotReservationActionTypes.FETCH_SLOT_RESERVATION_REQUEST,
});

export const fetchSlotReservationSuccessActionCreator = (
  slotReservation: SlotReservation
): FetchSlotReservationSuccessAction => ({
  type: SlotReservationActionTypes.FETCH_SLOT_RESERVATION_SUCCESS,
  slotReservation,
});

export const fetchSlotReservationFailureActionCreator = (error: string): FetchSlotReservationFailureAction => ({
  type: SlotReservationActionTypes.FETCH_SLOT_RESERVATION_FAILURE,
  error,
});

export function fetchSlotReservation() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchSlotReservationRequestActionCreator());

    const result = await servicesFetchSlotReservation();
    if (result.isSuccess()) {
      const reservedSlot = result.data.slot;
      if (reservedSlot && reservedSlot.slot_type && !result.data.is_expired) {
        dispatch(setSelectedSlotTypeActionCreator(reservedSlot.slot_type));
      }

      dispatch(fetchSlotReservationSuccessActionCreator(mapSlotReservation(result.data)));
      // Check if the slot is valid before caching. Slot is invalid if it has expired or if the slot type is "none".
      // WCS will return a response even when the slot has expired or the slot type is "none".
      if (isValidSlotReservationResponse(result.data)) {
        const slot = result.data.slot;

        cacheSlotReservation({
          storeIdentifier: result.data.store_identifier,
          region: result.data.region,
          slotStartTime: slot?.start_time,
          slotDateTime: slot?.start_time,
          flexiStores: result.data.flexi_stores,
          postCode: result.data.postcode,
          reservationType: result.data.reservation_type,
          storeName: result.data.click_and_collect_location ? result.data.click_and_collect_location.name : undefined,
          slotEndTime: slot?.end_time,
          qualifiedPrice: slot?.qualified_price.toFixed(2),
        });
        return;
      }

      if (result.data.store_identifier) {
        cacheSlotReservation({
          storeIdentifier: result.data.store_identifier,
          flexiStores: result.data.flexi_stores,
          region: result.data.region,
        });
        return;
      }

      deleteCachedSlotReservation();
    } else {
      dispatch(fetchSlotReservationFailureActionCreator(result.errors.first().detail));
    }
  };
}

export const createFetchOriginalSlotSuccessAction = (
  slot: SlotReservation | null
): FetchOriginalSlotReservationSuccessAction => ({
  type: SlotReservationActionTypes.FETCH_ORIGINAL_SLOT_RESERVATION_SUCCESS,
  slotReservation: slot ? slot : null,
});

export const fetchOriginalSlotReservationRequestActionCreator = (): FetchOriginalSlotReservationRequestAction => ({
  type: SlotReservationActionTypes.FETCH_ORIGINAL_SLOT_RESERVATION_REQUEST,
});

export const fetchOriginalSlotReservationFailureActionCreator = (
  error: string
): FetchOriginalSlotReservationFailureAction => ({
  type: SlotReservationActionTypes.FETCH_ORIGINAL_SLOT_RESERVATION_FAILURE,
  error,
});

export const fetchOriginalSlotReservation = (orderId: string) => async (dispatch: Dispatch<Action>) => {
  const result = await fetchSlotReservationByOrderUid(orderId);

  dispatch(fetchOriginalSlotReservationRequestActionCreator());

  if (result.isSuccess()) {
    dispatch(createFetchOriginalSlotSuccessAction(mapSlotReservation(result.data)));
  } else {
    dispatch(fetchOriginalSlotReservationFailureActionCreator(result.errors.first().detail));
  }
};

export const setReservationExpiredActionCreator = (slotReservation: SlotReservation): SetReservationExpiredAction => ({
  type: SlotReservationActionTypes.SET_RESERVATION_EXPIRED,
  slotReservation,
});

export const setReservationExpired = () => (dispatch: Dispatch<Action>, getState: () => State) => {
  const state = getState();
  const { slotReservation } = state.slotReservation as SlotReservationState;
  const expiredSlotReservation: SlotReservation = {
    ...slotReservation,
    reservationExpired: true,
  };
  dispatch(setReservationExpiredActionCreator(expiredSlotReservation));
};
