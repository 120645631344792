import { AdobeDigitalDataProperties, AnalyticsActionTypes } from "../../common/analytics/types";
import {
  ClickAndCollectConfirmationPageViewAction,
  ClickAndCollectPageViewAction,
  DeliverySlotsViewed,
} from "../../common/analytics/analytics";
import { OrdersSummary, OrderSummary } from "../../services/order.types";
import GolTime from "../../domain/time";
import { ClickAndCollectStore } from "./useStores";
import { State as ResultState } from "../../services/hooks";
import { DataState } from "../../common/dataState";
import { SingleSlot } from "../../components/BookSlot/Slot.component";
import { AnalyticsEvent } from "../../common/dataLayer/types";

export const clickAndCollectPageViewAction = (
  visibleSlots: SingleSlot[],
  storeId: string,
  adobeProperties: AdobeDigitalDataProperties
): ClickAndCollectPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "click and collect booking",
    template: "click and collect",
    newTemplate: "clickandcollectslots",
    section: "delivery and collection",
    deliveryType: "collection",
    collectionSlotsViewed: visibleSlots
      .filter(s => !s.isDummySlot)
      .map(slot => ({
        start_time: new GolTime(slot.startTime).format("HH:mm"),
        end_time: new GolTime(slot.endTime).format("HH:mm"),
        price: mapPriceToNumber(slot.price),
        status: mapSlotToStatus(slot),
        date: new GolTime(slot.startTime).format("YYYY-MM-DD"),
      })),
    storeId,
  },
  adobeProperties,
});

export const clickAndCollectConfirmationPageViewAction = (
  chosenSlot: DeliverySlotsViewed,
  storeId: string,
  adobeProperties: AdobeDigitalDataProperties
): ClickAndCollectConfirmationPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "collection reserved",
    template: "click and collect",
    newTemplate: "clickandcollectslots",
    section: "delivery and collection",
    deliveryType: "collection",
    storeId,
    collectionSlotsViewed: chosenSlot,
  },
  adobeProperties,
});

export const continueShoppingButtonAnalyticsEvent = (source: "switchlink"): AnalyticsEvent => ({
  eventCategory: "Click and collect",
  eventName: "continueshopping",
  eventAction: "Continue Shopping",
  eventLabel: source,
});

export function associateOrdersWithSlots(
  visibleSlots: SingleSlot[],
  orders: ResultState<OrdersSummary>,
  selectedStore?: ClickAndCollectStore
): SingleSlot[] {
  if (!selectedStore || orders.status !== DataState.SUCCESS) {
    return visibleSlots;
  }
  return visibleSlots.map(slot => {
    const associatedOrder = orders
      .data!.orders.filter(order => slotHasAssociatedOrder(selectedStore.storeId, slot, order))
      .pop();
    if (associatedOrder) {
      slot.associatedOrder = {
        isCutOff: associatedOrder.is_cutoff,
        status: associatedOrder.status,
        orderUid: associatedOrder.order_uid,
      };
    }
    return slot;
  });
}

function slotHasAssociatedOrder(storeId: string, slot: SingleSlot, order: OrderSummary): boolean {
  const startTimeIsEqual = new Date(slot.startTime).getTime() === new Date(order.slot_start_time).getTime();
  const endTimeIsEqual = new Date(slot.endTime).getTime() === new Date(order.slot_end_time).getTime();
  return (
    storeId === order.click_and_collect_uid &&
    startTimeIsEqual &&
    endTimeIsEqual &&
    order.order_type === "click_and_collect"
  );
}

export function getSelectedStore(
  stores: ClickAndCollectStore[],
  selectedStoreLocationId?: string,
  selectedStoreIdentifier?: string
): ClickAndCollectStore | undefined {
  if (selectedStoreLocationId) {
    const selectedStore = stores
      .filter(store => store.locationId === selectedStoreLocationId && store.storeId === selectedStoreIdentifier)
      .pop();
    if (selectedStore) {
      return selectedStore;
    }
  }
  return stores.length > 0 ? stores[0] : undefined;
}

const mapPriceToNumber = (price: string): number => {
  if (price === "Free") {
    return 0;
  }
  return Number(price);
};

const mapSlotToStatus = (slot: SingleSlot): number => {
  if (!slot.isAvailable || slot.isBookedByCustomer) {
    return 1;
  }
  if (!slot.isDeliveryPassApplicable && !slot.isGreen) {
    return 2;
  }
  if (!slot.isDeliveryPassApplicable && slot.isGreen) {
    return 3;
  }
  if (slot.isDeliveryPassApplicable && !slot.isGreen) {
    return 4;
  }
  if (slot.isDeliveryPassApplicable && slot.isGreen) {
    return 5;
  }
  return 6;
};
