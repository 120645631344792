import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { changeUnitOfMeasureActionCreator, createSyncBasketQuantityAction } from "./basket.actions";
import {
  AddToBasketOnLoginSuccessAction,
  BasketActionTypes,
  ChangeUnitOfMeasureOnLoginSuccessAction,
  ClickSource,
  KG,
} from "./basket.types";

export const basketMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) =>
  (next: Dispatch<any>) =>
  (action: AddToBasketOnLoginSuccessAction | ChangeUnitOfMeasureOnLoginSuccessAction) => {
    switch (action.type) {
      case BasketActionTypes.ADD_TO_BASKET_ON_LOGIN_SUCCESS:
        api.dispatch(
          createSyncBasketQuantityAction({
            sku: action.sku,
            newQuantity: action.unitOfMeasure === KG ? 0.5 : 1,
            unitOfMeasure: action.unitOfMeasure,
            clickSource: ClickSource.PRODUCT_GRID,
            product: action.product!,
            basketProduct: action.basketProduct!,
          })
        );
        break;
      case BasketActionTypes.CHANGE_UNIT_OF_MEASURE_ON_LOGIN_SUCCESS:
        api.dispatch(
          changeUnitOfMeasureActionCreator({
            sku: action.sku,
            unitOfMeasure: action.unitOfMeasure,
            clickSource: action.clickSource,
            basketProduct: action.basketProduct,
            product: action.product,
          })
        );
        break;
      default:
        break;
    }
    next(action);
  };
