import { Product, ProductSource } from "../../domain/product/product.types";

export type ProductOption = {
  value: string;
  label: string;
  unitPrice: string;
};

export enum TrolleyActionTypes {
  FETCH_BASKET_PRODUCTS_SUCCESS = "FETCH_BASKET_PRODUCTS_SUCCESS",
  DIETARY_PROFILE_ERROR = "DIETARY_PROFILE_ERROR",
  FETCH_BASKET_PRODUCTS_ERROR = "FETCH_BASKET_PRODUCTS_ERROR",
}

export type FetchBasketProductsSuccessAction = {
  type: TrolleyActionTypes.FETCH_BASKET_PRODUCTS_SUCCESS;
  products: Product[];
  productSource: ProductSource;
};

export type FetchBasketProductsSuccessWithErrorsAction = { type: TrolleyActionTypes.DIETARY_PROFILE_ERROR };

export type FetchBasketProductsErrorAction = { type: TrolleyActionTypes.FETCH_BASKET_PRODUCTS_ERROR };
