import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PageViewProperties } from "../../common/analytics/analytics";
import {
  AdobeDigitalDataProperties,
  AnalyticsActionTypes,
  AnalyticsTrackPageLoadAction,
  PAGE_LOAD_EVENT_NAMES,
} from "../../common/analytics/types";
import { useBasket } from "../../domain/basket/basket";
import { AdobePageName, AdobePageType, generateAdobeAnalyticsPageProperties } from "../../utils";

type AnalyticsPageViewProps = {
  page: PageViewProperties;
  eventName: PAGE_LOAD_EVENT_NAMES;
  adobeProperties: AdobeAnalyticsPageViewProperties;
};

export type AdobeAnalyticsPageViewProperties = {
  pageType: AdobePageType;
  pageName: AdobePageName;
  extraPageData: string[];
};

export const analyticsPageViewTrackAction = (
  page: PageViewProperties,
  eventName: PAGE_LOAD_EVENT_NAMES,
  adobeProperties: AdobeDigitalDataProperties
): AnalyticsTrackPageLoadAction => ({
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD,
  page,
  eventName,
  adobeProperties,
});

export const AnalyticsPageViewTrackEvent = (props: AnalyticsPageViewProps) => {
  const { basket } = useBasket();
  const dispatch = useDispatch();

  const adobeProperties = generateAdobeAnalyticsPageProperties({
    basket,
    pageType: props.adobeProperties.pageType,
    pageName: props.adobeProperties.pageName,
    extraPageData: props.adobeProperties.extraPageData,
  });

  useEffect(() => {
    dispatch(analyticsPageViewTrackAction(props.page, props.eventName, adobeProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
