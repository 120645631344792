import Cookies from "js-cookie";
import { getUserConsents } from "../cookie";

/*
 * Clear Taggstar cookies on opt-out
 * This is a temporary solution until we are able to
 * do it the proper way via Tealium
 */
class TaggstarCookieManagerClass {
  private _hasCleared: boolean;

  constructor() {
    this._hasCleared = false;
  }

  private getDomain() {
    const host = window.location.host.toLowerCase();

    return host.includes("js-devops.co.uk") ? ".js-devops.co.uk" : ".sainsburys.co.uk";
  }

  clearCookies() {
    const { marketing } = getUserConsents();

    if (this._hasCleared || marketing) {
      return;
    }

    const domain = this.getDomain();

    Cookies.remove("_taggstar_exp", { domain });
    Cookies.remove("_taggstar_ses", { domain });
    Cookies.remove("_taggstar_vid", { domain });

    this._hasCleared = true;
  }
}

export const TaggstarCookieManager = new TaggstarCookieManagerClass();
