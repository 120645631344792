import { Basket } from "../domain/basket/basket.types";

export enum AdobePageType {
  GROCERIES = "groceries",
}

export enum AdobePageName {
  OFFERS = "offers",
  SLOT_BOOKING = "slot booking",
  CHECKOUT = "checkout",
  MY_ACCOUNT = "my account",
  TROLLEY = "trolley",
  SEARCH_RESULTS = "search results",
  NECTAR_PRICES = "nectar prices",
  RECIPES = "recipes",
  MEALPLAN = "meal plan",
  MEALDEAL = "meal deal",
  MEALPLAN_CREATE = "meal plan create",
  BROWSE = "browse",
  PRODUCT_LISTING = "product listing",
  PRODUCT = "product",
  FAVOURITES = "favourites",
  FAVOURITES_RECIPES = "favourites recipes",
  AUTHENTICATION = "authentication",
  BRANDED_PAGE = "brands",
  EVENTS = "events",
  FEATURES = "features",
  HOMEPAGE = "homepage",
  MEAL_DEAL_BUILDER = "meal deals",
  MULTI_SEARCH = "multi search",
  OCCASIONS = "occasions",
  PROMO_LISTER = "promo lister",
  XMAS_SLOT_GUIDE = "christmas slot guide",
}

export enum AdobeExtraPageData {
  SELECT_FULFILMENT_METHOD = "select fulfilment method",
  ARTICLE = "article",
  DELIVERY = "delivery",
  RESERVATION_CONFIRMATION = "reservation confirmation",
  SUMMARY = "summary",
  BEFORE_YOU_GO = "before you go",
  FORGOTTEN_FAVOURITES = "forgotten favourites",
  CLICK_AND_COLLECT = "click and collect",
  ORDERS = "orders",
  LOGIN = "login",
  REDIRECT = "redirect",
  LOGOUT = "logout",
  REGISTER = "register",
  CONFIRMATION = "confirmation",
  ORDER_DETAILS = "order details",
  PREVIOUSLY_ORDERED_PRODUCTS = "previously ordered products",
  SELECT_PREFERENCES = "select preferences",
  RECIPE_DETAIL = "recipe detail",
  SEARCH_RESULTS = "search results",
  NECTAR_CARD = "nectar card",
  EDIT = "edit",
  SIGNUP = "signup",
  VERIFY = "verify",
}

export type AdobeProps = {
  basket?: Basket;
  pageType: AdobePageType;
  pageName: string;
  siteSection?: string;
  extraPageData?: string[];
};

export function generateAdobeAnalyticsPageProperties(adobeProps: AdobeProps) {
  if (!adobeProps.siteSection) {
    adobeProps.siteSection = adobeProps.pageName;
  }

  let appendData = "";
  if (adobeProps.extraPageData?.length) {
    appendData =
      adobeProps.siteSection === AdobePageName.BRANDED_PAGE ||
      adobeProps.siteSection === AdobePageName.EVENTS ||
      adobeProps.siteSection === AdobePageName.FEATURES
        ? adobeProps.extraPageData.join(":")
        : `:${adobeProps.extraPageData.join(":")}`;
  }

  const shoppingMode = adobeProps.basket?.isInAmendMode ? "amend" : "primary";
  const pageViewName = `web:${adobeProps.pageType}:${adobeProps.pageName}`;

  const data_siteSection = adobeProps.siteSection;
  const data_page_name = pageViewName + appendData;
  const data_shoppingMode = shoppingMode;

  return {
    data_siteSection,
    data_page_name,
    data_shoppingMode,
  };
}
