import { WithKey } from "../../MealDealBuilder.types";
import uuid from "uuid-v4";

/**
 * Attaches a unique key to each element of the object array.
 * Will generate runtime errors if given an array containing undefined elements
 */
export function withKey<T extends Object>(inp: T[]): WithKey<T>[] {
  return inp.map(item => ({ ...item, key: uuid() }));
}
