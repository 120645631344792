/* 
  This module contains functions to communicate with service worker.
  It is simply a client used to register the service worker and interact with it.
*/

import { GOLUI_PREFIX } from "../../routes";

const register = async (): Promise<void> => {
  if ("serviceWorker" in navigator) {
    // swPath is where the service worker is registered based on the webpack config. Search 'golui.sw' in the webpack config to see it.
    const swPath = `${GOLUI_PREFIX}/static/js/golui.sw.js`;
    window.addEventListener("load", async () => {
      try {
        await navigator.serviceWorker.register(swPath, { scope: "/" });
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line no-console
          console.error(
            `Couldn't serve ${swPath} securely, please provide secure context and headers. https://github.com/JSainsburyPLC/gol-ui/blob/master/ui-dev-proxy/README.md#couldnt-serve-goluiswjs-securely-please-provice-secure-context-and-headers`
          );
        } else {
          throw err;
        }
      }
    });
  }
};

const clearCache = async (): Promise<void> => {
  if ("serviceWorker" in navigator) {
    const registeredSW = navigator.serviceWorker.controller;
    if (registeredSW && registeredSW.state === "activated") {
      registeredSW.postMessage({ command: "deleteCache" });
    }
  }
};

export const serviceWorkerClient = {
  register,
  clearCache,
};
