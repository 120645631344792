export interface LookupTableItems<V extends object> {
  [key: string]: V;
}

type StringKey<V> = Extract<keyof V, string>;

type CreateLookup<V extends object, K extends StringKey<V>> = (items: V[], lookupKey: K) => LookupTableItems<V>;

export class LookupTable<V extends object, K extends StringKey<V>> {
  private readonly lookup: LookupTableItems<V>;
  private readonly items: V[];

  static create<V extends object, K extends StringKey<V>>(items: V[], lookupKey: K, createLookup?: CreateLookup<V, K>) {
    return new LookupTable(items, lookupKey, createLookup);
  }

  static empty() {
    return new LookupTable([], "" as never);
  }

  static createLookup<V extends object, K extends StringKey<V>>(items: V[], lookupKey: K): LookupTableItems<V> {
    const lookup = {};
    items.forEach(item => {
      const key = item[lookupKey as string];
      lookup[`${key}`] = item;
    });
    return lookup;
  }

  constructor(items: V[], lookupKey: K, createLookup?: CreateLookup<V, K>) {
    this.lookup = (createLookup || LookupTable.createLookup)(items, lookupKey);
    this.items = items;
  }

  getItems(): V[] {
    return this.items;
  }

  getByKey(key: string): V {
    return this.lookup[`${key}`];
  }
}
