import { DietaryWarningsState } from "../../../common/store";
import { DataState } from "../../../common/dataState";
import {
  FetchDietaryWarningsActionTypes,
  FetchDietaryWarningsFailedActionType,
  FetchDietaryWarningsPendingActionType,
  FetchDietaryWarningsSuccessActionType,
  ReFetchDietaryWarningsSuccessActionType,
} from "./dietaryWarning.actions";

export const dietaryWarningsInitialState: DietaryWarningsState = {
  dataState: DataState.UNKNOWN,
  intolerantEANs: {},
};

export const dietaryWarningsReducer = (
  state: DietaryWarningsState = dietaryWarningsInitialState,
  action:
    | FetchDietaryWarningsPendingActionType
    | FetchDietaryWarningsSuccessActionType
    | FetchDietaryWarningsFailedActionType
    | ReFetchDietaryWarningsSuccessActionType
): DietaryWarningsState => {
  switch (action.type) {
    case FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_PENDING:
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    case FetchDietaryWarningsActionTypes.RE_FETCH_DIETARY_WARNINGS_SUCCESS:
      const intolerantEANs = action.eans.reduce((acc, curr) => {
        acc[`${curr}`] = true;
        return acc;
      }, {} as { [key: string]: boolean });
      return {
        ...state,
        intolerantEANs,
        dataState: DataState.SUCCESS,
      };

    case FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_SUCCESS:
      const warnings = action.eans.reduce((acc, curr) => {
        acc[`${curr}`] = true;
        return acc;
      }, {} as { [key: string]: boolean });
      return {
        ...state,
        intolerantEANs: {
          ...state.intolerantEANs,
          ...warnings,
        },
        dataState: DataState.SUCCESS,
      };
    case FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_FAILED:
      return {
        ...state,
        dataState: DataState.FAILED,
      };
    default:
      return state;
  }
};
