import { AdsResponseEnvelope } from "../../../services/content";
import { Banners } from "../../../components/Citrus/BannerAd/BannerAd.types";
import { ContentBannerLocation, ContentPlacement, TemplateArea } from "../../../services/contentTypes";
import { BrowsePageType } from "../Browse.types";
import { IngridAd } from "../../../components/IngridAd/IngridAd.type";
import { ReactNode } from "react";

export interface BrowseIngridAdState {
  ads: IngridAd[];
}

export interface BrowseMagnoliaData {
  aboveGrid?: TemplateArea;
  belowGrid?: TemplateArea;
  meta?: BrowseMeta;
  aboveGridSecondary?: TemplateArea;
}

export interface BrowseMagnoliaResponse extends BrowseMagnoliaData, BrowseIngridAdState {}

export enum BrowseIngridAdActionType {
  BROWSE_AD_REQUEST = "BROWSE_AD_REQUEST",
  BROWSE_AD_SUCCESS = "BROWSE_AD_SUCCESS",
  BROWSE_AD_FAILURE = "BROWSE_AD_FAILURE",
  BROWSE_AD_RESET = "BROWSE_AD_RESET",
}

export interface BrowseMagnoliaRequestProps {
  pageType: BrowsePageType;
  categoryID: string;
  browseID: string;
  storeId?: string;
  deliveryDate?: string;
  region?: string;
  hasNectar?: boolean;
  digitalCustomer?: boolean;
  hasDeliveryPass?: boolean;
  isVeryImportantCustomer?: boolean;
}

export interface BrowseMagnoliaRequestPayload {
  category_ids: string[];
  template_id: string;
  slotId: ContentBannerLocation;
  espotIds: string[];
  placement?: ContentPlacement;
  store?: string;
  delivery_date?: string;
  region?: string;
  has_nectar_associated?: boolean;
  is_digital_customer?: boolean;
  has_delivery_pass?: boolean;
  is_very_important_customer?: boolean;
}

export interface BrowseMeta {
  pageDescription?: string;
  pageHeader?: string;
  pageIntroCopy?: string;
  pageTitle?: string;
  seoFooterCopy?: string;
  refinedTitle?: string;
  unrefinedTitle?: string;
  refinedDescription?: string;
  unrefinedDescription?: string;
  refinedPageHeader?: string;
  unrefinedPageHeader: string;
  refinedPageIntroCopy?: string;
  unrefinedPageIntroCopy: string;
  noIndex?: boolean;
  noFilter?: boolean;
  canonicalTag?: string;
}

export interface BrowseMetaCommon {
  categoryName?: string;
  filterData?: string;
}

export interface BrowseHeaderType extends BrowseMetaCommon {
  id: string;
  pageType: BrowsePageType;
  category: string;
  lookups: number[];
  tree: string;
  shouldHideFilters: boolean;
  pageHeader: BrowseMeta["unrefinedPageHeader"];
  pageDescription: BrowseMeta["unrefinedPageIntroCopy"];
  breadCrumbs?: ReactNode;
}

export interface BrowseFooterType extends BrowseMetaCommon {
  seoFooterCopy: BrowseMeta["seoFooterCopy"];
}

export interface BrowseMetaTagsType {
  filters: any;
  tree: string;
  browseID: string;
  category: string;
  seoData: any;
}

export interface BrowseBeyondGridType {
  magnoliaTemplate?: TemplateArea;
  magnoliaTemplateSecondary?: TemplateArea;
  optionalCitrusData?: AdsResponseEnvelope<Banners>[];
}
