import { Action } from "redux";
import {
  BrowseEventsState,
  BrowseTaxonomyState,
  ParsedBrowseTaxonomyState,
  SynapticaBreadcrumbs,
} from "./Browse.types";
import {
  ActionTypes,
  ActionTypes as BrowseActionTypes,
  BrowseEventsSuccessAction,
  CreatedSynapticaBreadcrumbsAction,
  FetchTaxonomyFailureAction,
  FetchTaxonomySuccessAction,
  ParseTaxonomySuccessAction,
} from "./Browse.actions";
import { DataState } from "../../common/dataState";

const isSuccess = (
  action: FetchTaxonomySuccessAction | FetchTaxonomyFailureAction | Action
): action is FetchTaxonomySuccessAction => action.type === BrowseActionTypes.FETCH_TAXONOMY_SUCCESS;

const initialState: BrowseTaxonomyState = [];

export function taxonomyReducer(
  state: BrowseTaxonomyState = initialState,
  action: FetchTaxonomySuccessAction | FetchTaxonomyFailureAction | Action
): BrowseTaxonomyState {
  if (isSuccess(action) && action.data) {
    return action.data;
  }
  return state;
}

const isParseSuccess = (action: ParseTaxonomySuccessAction | Action): action is ParseTaxonomySuccessAction =>
  action.type === BrowseActionTypes.PARSE_TAXONOMY_SUCCESS;

const parsedInitialState: ParsedBrowseTaxonomyState = {};
export const parsedTaxonomyReducer = (
  state: ParsedBrowseTaxonomyState = parsedInitialState,
  action: ParseTaxonomySuccessAction | Action
) => {
  if (isParseSuccess(action) && action.data) {
    return action.data;
  }
  return state;
};

const isSynapticaBreadcrumbs = (
  action: CreatedSynapticaBreadcrumbsAction | Action
): action is CreatedSynapticaBreadcrumbsAction => action.type === BrowseActionTypes.CREATE_BREADCRUMBS_SUCCESS;

export const synapticaBreadcrumbsReducer = (
  state: SynapticaBreadcrumbs = {},
  action: CreatedSynapticaBreadcrumbsAction | Action
) => {
  if (isSynapticaBreadcrumbs(action) && action.data) {
    return action.data;
  }
  return state;
};

export const browseEventsInitialState: BrowseEventsState = {
  dataStatus: DataState.UNKNOWN,
  data: [],
};

export const browseEventsReducer = (
  state: BrowseEventsState = browseEventsInitialState,
  action: BrowseEventsSuccessAction
) => {
  if (action.type === ActionTypes.FETCH_EVENTS_SUCCESS) {
    return {
      data: action.data,
      dataStatus: DataState.SUCCESS,
    };
  }
  return state;
};
