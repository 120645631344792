import { AdsResponseEnvelope, MagnoliaTemplate, TemplateArea } from "../../services/content";
import React from "react";
import { AuthorizedContainer } from "../Authorized";
import { Logo } from "./Logo";
import { BookDeliveryContainer } from "./BookDelivery/BookDelivery.container";
import { LoginRegisterLink, MultiSearchLink, TopRightLinks } from "./links";
import { MobileMegaNav } from "./MegaNav/Mobile/MobileMegaNav";
import { MiniTrolleyContainer } from "./MiniTrolley/MiniTrolley.container";
import { RestingSearchV2 } from "./SearchBar/hooks/useTrendingSearch.hook";
import { SearchBarContainer } from "./SearchBar/SearchBar.container";
import { MenuItem } from "./Header.types";
import { useMegaNavActions } from "./headerState/header.actions";
import { LinkFlag } from "./useMenuPromotionalItem";
import { Banners } from "../Citrus/BannerAd/BannerAd.types";
import { useHeaderState } from "./headerState/useHeaderState";

interface MinimizedHeaderProps {
  isLoggedIn: boolean;
  megaNavItems: MenuItem[];
  isMegaNavOpen: boolean;
  ads: AdsResponseEnvelope<Banners>[];
  seasonal?: TemplateArea;
  promotionalLink: boolean | LinkFlag;
  restingSearchPreset: {
    template: MagnoliaTemplate | null;
    isLoading: boolean;
    magnoliaError: boolean;
    restingSearch: RestingSearchV2;
  };
}

export function MinimizedHeader({
  restingSearchPreset,
  megaNavItems,
  isMegaNavOpen,
  promotionalLink,
  ads,
  seasonal,
  isLoggedIn = false,
}: Readonly<MinimizedHeaderProps>) {
  const { showMegaNav, hideMegaNav } = useMegaNavActions();
  const { showMinimised } = useHeaderState();

  return (
    <div
      className="header-minimized__container ds-transition-all ds-duration-200 ds-ease-in-out"
      data-testid="minimized-header-container"
    >
      {Boolean(megaNavItems?.length) && (
        <MobileMegaNav
          ads={ads}
          seasonal={seasonal}
          open={showMegaNav}
          isOpen={isMegaNavOpen && showMinimised}
          onClose={hideMegaNav}
          navItems={megaNavItems}
          promotionalLink={promotionalLink}
          isLoggedIn={isLoggedIn}
        />
      )}
      <div className="ds-h-full ds-flex ds-items-center ds-min-w-[140px] ds-mt-0.5 ds-ms-1 ds-transition-transform ds-duration-200 ds-ease-in">
        <Logo />
      </div>

      <div
        className="header-nav ds-w-full ds-mx-1 ds-mt-1 ds-transition-all ds-duration-200 ds-ease-in"
        data-testid="desktop-header-nav"
      >
        <SearchBarContainer restingSearchPreset={restingSearchPreset} />
        <MultiSearchLink customStyle="non-mobile-version" />
      </div>
      <div className="ds-ms-auto ds-flex ds-items-center">
        <AuthorizedContainer
          fallback={
            <div className="ds-flex ds-items-center ds-gap-3 ds-mr-2 ds-ml-4">
              <LoginRegisterLink />
              <BookDeliveryContainer />
              <MiniTrolleyContainer />
            </div>
          }
        >
          <div className={`ds-flex ds-items-center ds-gap-3 ds-mr-2 ${!isLoggedIn ? "ds-ml-4" : "ds-ml-2"}`}>
            <TopRightLinks className="!ds-m-0" />
          </div>
        </AuthorizedContainer>
      </div>
    </div>
  );
}
