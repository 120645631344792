import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { urls } from "../../routes";
import {
  changeUnitOfMeasureActionCreator,
  createSyncBasketQuantityAction,
  CreateSyncBasketQuantityActionParams,
  createUpdateLocalQuantityAction,
  deleteBasket,
  fetchBasket,
  updateSubstitutionPreferences,
} from "./basket.actions";
import {
  Basket,
  BasketAddOrderError,
  BasketOrderPromise,
  BasketProduct,
  BasketPromotion,
  BasketPromotionTypes,
  ClickSource,
  DecrementParams,
  IncrementParams,
  KG,
  SetQuantityParams,
  SetUnitParams,
  UseBasket,
  UseBasketItem,
} from "./basket.types";
import { State } from "../../common/store";
import { selectNudgeValue } from "./basket.selectors";
import {
  Basket as ServicesBasket,
  BasketAddOrderError as ServicesBasketAddOrderError,
  BasketItem,
  BasketItemSubstitutionPreference,
  BasketPromotion as ServicesBasketPromotion,
  OrderPromise,
} from "../../services/basket.types";
import { OrderPromiseStatus, OrderPromiseStatusType, Product, Promotion } from "../product/product.types";
import { featureFlags } from "../../common/firebase/featureFlags";
import { formatPrice } from "../../common/format";
import { NectarOffer } from "../../services/nectar.types";

export const ADD_TO_BASKET_KG_INCREMENT = 0.5;
export const ADD_TO_BASKET_ITEM_INCREMENT = 1;
export const WAIT_BEFORE_PRODUCT_QUANTITY_SYNC_MS = 750;
export const WCS_MIN_ACCEPTABLE_QUANTITY = 0.005;
export const ONE_HOUR = 3600000;

export const getValidQuantity = (newQuantity: number) => {
  const isBiggerThanMin = newQuantity >= WCS_MIN_ACCEPTABLE_QUANTITY;
  return isBiggerThanMin ? newQuantity : 0;
};

export const calculateNewQuantity = (currentQuantity: number, nudgeValue: number) => {
  const newQuantity = Math.max(0, currentQuantity + nudgeValue);
  return getValidQuantity(newQuantity);
};

export const formatQuantity = (quantity: number, unit: string) => {
  const significantDecimalPlaces = quantity % 1 ? Math.min(2, String(quantity).split(".").pop()!.length) : 0;
  return Boolean(quantity && unit === KG)
    ? `${quantity.toFixed(significantDecimalPlaces)} ${KG}`
    : `${quantity.toFixed(significantDecimalPlaces)}`;
};

export const mapBasketPromotion = (item: ServicesBasketPromotion): BasketPromotion => ({
  promotionUid: item.promotion_uid,
  url: getPromotionUrl(item.strap_line, item.promotion_uid),
  strapLine: item.strap_line,
  promotionMissed: item.promotion_missed,
  isNectarPrice: item.is_nectar_price,
  type: item.type,
});

const mapBasketOrderPromise = (item: OrderPromise): BasketOrderPromise => ({
  earliestPromiseDate: item.earliest_promise_date,
  lastAmendmentDate: item.last_amendment_date,
  status: item.status,
});

const getPromotionUrl = (strapline: string, promotionId: string): string => {
  const mealDealsLive = featureFlags.get("meal_deal_live");
  if (strapline.toLocaleLowerCase().indexOf("meal deal") !== -1 && mealDealsLive) {
    return `${urls.MEAL_DEAL_BUILDER}/${promotionId}`;
  } else {
    return `${urls.PRODUCTTILE_PROMOTION}&promotionId=${promotionId}`;
  }
};

const mapBasketItem = (item: BasketItem): BasketProduct => {
  return {
    sku: item.product && item.product.sku,
    productUrl: item.product && item.product.full_url,
    image: item.product && item.product.image,
    itemId: item.item_uid,
    productName: item.product && item.product.name,
    productType: item.product && item.product.product_type,
    quantity: item.quantity,
    subTotal: item.subtotal_price,
    unitOfMeasure: item.uom,
    promotions: mapBasketPromotions(item.promotions),
    selectedCatchweight: item.selected_catchweight,
    allowSubstitutions: item.allow_substitutions,
    totalBasePrice: item.total_base_price,
    isYourNectarPrice: item.is_your_nectar_price,
    maxQuantityLimit: item.max_quantity_limit,
    isSupplyChainOrderable: item.is_supply_chain_orderable,
    orderPromise: item.promise ? mapBasketOrderPromise(item.promise) : undefined,
  };
};

const mapBasketItems = (items: BasketItem[]): BasketProduct[] => items.map(mapBasketItem);

export const mapBasketToDomain = (data: ServicesBasket): Basket => {
  const items = mapBasketItems(data.items);

  return {
    basketId: data.basket_id,
    orderId: data.order_id,
    subTotal: data.subtotal_price,
    total: data.total_price,
    savings: data.savings,
    nectarSavings: data.nectar_savings,
    vouchersSavings: data.vouchers_savings,
    slotPrice: data.slot_price,
    minimumSpend: data.minimum_spend,
    hasExceededMinimumSpend: data.has_exceeded_minimum_spend,
    longestPromiseDate: data.longest_promise_date,
    isSlotLocked: data.is_slot_locked,
    itemCount: data.item_count,
    items,
    isInAmendMode: data.is_in_amend_mode,
    delivery_instructions: data.delivery_instructions,
    hasYnpItemsInBasket: items.some(item => !!item.isYourNectarPrice),
    error: data.error,
  };
};

const mapBasketPromotions = (items: ServicesBasketPromotion[]): BasketPromotion[] =>
  items.map(item => mapBasketPromotion(item));

// Overrides potentially broken basket promotion url with correct product promotion url
export const getBasketPromotionsWithProductPromotionURL = (
  basketPromotions: BasketPromotion[],
  product: Product | undefined
): BasketPromotion[] => {
  return basketPromotions.map(basketPromotion => {
    const matchingProductPromotion = product?.promotions?.find(
      (productPromotion: Promotion) => productPromotion.promotionUid === basketPromotion.promotionUid
    );
    return {
      ...basketPromotion,
      url: matchingProductPromotion ? matchingProductPromotion.url : basketPromotion.url,
      startDate: matchingProductPromotion ? matchingProductPromotion.startDate : basketPromotion.startDate,
      endDate: matchingProductPromotion ? matchingProductPromotion.endDate : basketPromotion.endDate,
    };
  });
};

// Utility function used for basket and product tiles to get price and offer states
export const getBasketItemPromotionDetails = (
  mappedPromotions: BasketPromotion[],
  subTotal: number,
  totalBasePrice?: string,
  isYourNectarPrice?: boolean,
  nectarOffer?: NectarOffer
) => {
  const isNectarPrice = mappedPromotions.some(p => p.isNectarPrice && p.type === BasketPromotionTypes.SIMPLE);
  const multibuyPromotions = mappedPromotions.filter(promo => promo.type === BasketPromotionTypes.COMPLEX);
  const missedMultibuyPromotions = multibuyPromotions.filter(promo => promo.promotionMissed);
  const appliedMultibuyPromotions = multibuyPromotions.filter(promo => !promo.promotionMissed);
  const hasMultibuyPromotions = multibuyPromotions.length > 0;
  const originalPrice = totalBasePrice ? formatPrice(totalBasePrice, true) : "";
  const price = formatPrice(subTotal, true);
  const isRedOffer = originalPrice !== price && !isNectarPrice && !isYourNectarPrice && multibuyPromotions.length === 0;
  const isOnlyNectarPointsOffer = !isNectarPrice && !isYourNectarPrice && !isRedOffer && nectarOffer !== undefined;
  const isAppliedRegularMultibuyPromotions = appliedMultibuyPromotions.some(promo => !promo.isNectarPrice);
  const isAppliedNectarMultibuyPromotions = appliedMultibuyPromotions.some(promo => promo.isNectarPrice);

  return {
    isNectarPrice,
    originalPrice,
    price,
    isRedOffer,
    isOnlyNectarPointsOffer,
    missedMultibuyPromotions,
    appliedMultibuyPromotions,
    hasMultibuyPromotions,
    isAppliedRegularMultibuyPromotions,
    isAppliedNectarMultibuyPromotions,
  };
};

export const mapBasketAddOrderErrors = (errors: ServicesBasketAddOrderError[]): BasketAddOrderError[] =>
  errors.map(error => {
    return {
      code: error.code,
      detail: error.detail,
      productName: error.meta.name,
      infoUrl: error.meta.information_url,
    };
  });

export const useBasket = (): UseBasket => {
  const basketState = useSelector((state: State) => state.basket);
  const dispatch = useDispatch();
  const { itemCount, hasExceededMinimumSpend, items } = basketState.basketDetails;
  const occasionItems = items != null ? items.filter(item => item.isSupplyChainOrderable) : [];

  const hasNonAmendableOccasionItems = occasionItems.some(item =>
    item.orderPromise != null ? item.orderPromise.status?.type === OrderPromiseStatusType.BEING_PREPARED : false
  );

  const hasOccasionItemsAboutToBePrepared = occasionItems.some(item =>
    item.orderPromise != null ? item.orderPromise.status?.type === OrderPromiseStatusType.TIME_TO_CUTOFF_CLOSE : false
  );

  return {
    basket: basketState.basketDetails,
    dataState: basketState.dataState,
    error: basketState.errors,
    checkoutEnabled: itemCount > 0 && hasExceededMinimumSpend,
    hasOccasionItems: occasionItems.length > 0,
    isAllItemsAmendable: !hasNonAmendableOccasionItems,
    isSomeItemsNonAmendableSoon: hasOccasionItemsAboutToBePrepared,
    deletedFromBasket: basketState.deletedFromBasket,
    fetchBasket: (calculateOrder?: boolean) => dispatch(fetchBasket(calculateOrder)),
    emptyBasket: () => dispatch(deleteBasket()),
  };
};

export const useBasketItem = (sku: string): UseBasketItem => {
  const basketState = useSelector((state: State) => state.basket);
  const item = basketState.data.items.getByKey(sku);
  const itemQuantity = basketState.quantities[`${sku}`];
  const dispatch = useDispatch();
  const orderPromiseStatus: OrderPromiseStatus | undefined =
    basketState.data.items.getByKey(sku) && item.orderPromise?.status;

  const [debouncedSync] = useState(() =>
    debounce(
      (params: CreateSyncBasketQuantityActionParams) => dispatch(createSyncBasketQuantityAction(params)),
      WAIT_BEFORE_PRODUCT_QUANTITY_SYNC_MS
    )
  );
  //Added a new param(decrement). It is set to true to indicate that product quantity is being reduced.
  const setQuantity = ({
    newQuantity,
    unit,
    catchweight,
    productType,
    clickSource,
    calculateOrder = false,
    delay = false,
    product,
    decrement,
    carouselKey,
    tileId,
    fulfilmentState,
  }: SetQuantityParams) => {
    dispatch(createUpdateLocalQuantityAction(sku, newQuantity, tileId));
    const quantityActionParams: CreateSyncBasketQuantityActionParams = {
      sku,
      newQuantity,
      unitOfMeasure: unit,
      clickSource,
      selectedCatchweight: catchweight,
      productType,
      basketProduct: item,
      isProductDeleted: newQuantity === 0,
      calculateOrder,
      product,
      decrement,
      carouselKey,
      tileId,
      fulfilmentState,
    };
    if (!delay) {
      dispatch(createSyncBasketQuantityAction(quantityActionParams));
    } else if (!itemQuantity || newQuantity === 0) {
      debouncedSync.cancel();
      dispatch(createSyncBasketQuantityAction(quantityActionParams));
    } else {
      debouncedSync(quantityActionParams);
    }
  };

  const increment = ({
    unit,
    catchweight,
    productType,
    clickSource,
    calculateOrder,
    product,
    carouselKey,
    tileId,
    fulfilmentState,
  }: IncrementParams) => {
    const quantity = itemQuantity ? itemQuantity.quantity : 0;
    const nudgeValue = selectNudgeValue(unit);
    const newQuantity = calculateNewQuantity(quantity, +nudgeValue);
    setQuantity({
      newQuantity,
      unit,
      catchweight,
      productType,
      clickSource,
      calculateOrder,
      delay: true,
      product,
      carouselKey,
      tileId,
      fulfilmentState,
    });
  };

  const decrement = ({ unit, catchweight, clickSource, product, tileId, fulfilmentState }: DecrementParams) => {
    const nudgeValue = selectNudgeValue(unit);
    const newQuantity = calculateNewQuantity(itemQuantity.quantity, -nudgeValue);
    setQuantity({
      newQuantity,
      unit,
      catchweight,
      clickSource,
      delay: true,
      product,
      decrement: true,
      tileId,
      fulfilmentState,
    });
  };

  const setUnitOfMeasure = ({ sku, unit, clickSource, calculateOrder, product }: SetUnitParams) => {
    dispatch(
      changeUnitOfMeasureActionCreator({
        sku,
        unitOfMeasure: unit,
        clickSource,
        calculateOrder,
        basketProduct: item,
        product,
      })
    );
  };

  const setCatchweight = (selectedCatchweight: string, clickSource: ClickSource, product: Product, tileId?: string) => {
    dispatch(
      createSyncBasketQuantityAction({
        sku,
        newQuantity: itemQuantity.quantity,
        unitOfMeasure: item.unitOfMeasure,
        clickSource,
        selectedCatchweight,
        basketProduct: item,
        product,
        tileId,
      })
    );
  };

  const setSubstitutionPreference = (allowSubstitutes: boolean) => {
    const basketItem: BasketItemSubstitutionPreference = {
      allow_substitutions: allowSubstitutes,
      item_uid: item.itemId,
      product_uid: item.sku,
    };
    dispatch(updateSubstitutionPreferences(basketItem));
  };

  return {
    quantity: itemQuantity ? itemQuantity.quantity : 0,
    unit: item ? item.unitOfMeasure : "",
    catchweight: item ? item.selectedCatchweight : undefined,
    isLoading: itemQuantity ? itemQuantity.loading : false,
    isLoadingCatchweight: itemQuantity ? itemQuantity.loadingCatchweight : false,
    increment,
    decrement,
    setQuantity,
    setUnitOfMeasure,
    setCatchweight,
    setSubstitutionPreference,
    orderPromiseStatus,
    lastTileId: itemQuantity?.lastTileId || "",
  };
};
