import { BrowseIngridAdActionType } from "./BrowseMagnolia.type";
import { IngridAd } from "../../../components/IngridAd/IngridAd.type";

export const createBrowseIngridAdRequestAction = () => ({ type: BrowseIngridAdActionType.BROWSE_AD_REQUEST });

export const createBrowseIngridAdResetAction = () => ({ type: BrowseIngridAdActionType.BROWSE_AD_RESET });

export const createBrowseIngridAdSuccessAction = (ads: IngridAd[]) => ({
  type: BrowseIngridAdActionType.BROWSE_AD_SUCCESS,
  ads,
});

export const createBrowseIngridAdFailureAction = () => ({
  type: BrowseIngridAdActionType.BROWSE_AD_FAILURE,
});
