import { Action as ReduxAction, AnyAction } from "redux";
import { State } from "./store";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

export type WithAnyProps = {
  [key: string]: any;
};

export type WithChildren = {
  children?: any;
};

export type Optional<T> = T | undefined;

export type Nullable<T> = T | null;

export type AppThunkDispatch = ThunkDispatch<State, unknown, AnyAction>;
export type AppThunkAction<ReturnType = void> = ThunkAction<ReturnType, State, unknown, AnyAction>;

export interface LocationChangeAction extends ReduxAction {
  payload: {
    location: {
      pathname: string;
      search: string;
    };
  };
}

export enum ResponseErrorActionType {
  HTTP_CLIENT_ERROR = "HTTP_CLIENT_ERROR",
  HTTP_SERVER_ERROR = "HTTP_SERVER_ERROR",
  HTTP_UNAUTHORIZED_ERROR = "HTTP_UNAUTHORIZED_ERROR",
}

export enum PromoMechanicIds {
  NECTAR_PROMOTION = "99",
  SO_HALF_PRICE = "1",
  SO_SAVE_X_VALUE = "2",
  SO_SAVE_X_PERCENT = "3",
  SO_FIXED_PRICE = "4",
  IO_HALF_PRICE = "5",
  IO_SAVE_X_VALUE = "6",
  IO_SAVE_X_PERCENT = "7",
  IO_FIXED_PRICE = "8",
  BUY_X_GET_X_POINTS = "9",
  SPEND_X_GET_X_POINTS = "10",
  BUY_ONE_GET_ONE_FREE = "11",
  THREE_FOR_TWO = "12",
  BUY_X_FOR_Y_SAME_PRICE = "13",
  BUY_X_FOR_Y_VARIABLE_PRICE = "14",
  BUY_X_SAVE_Y_VALUE = "15",
  BUY_ITEM_1_GET_ITEM_2_FREE = "16",
  BUY_ITEM_1_GET_ITEM_2_FOR_X_VALUE = "17",
  BUY_X_SAVE_Y_PERCENT = "18",
  BUY_X_SAVE_Y_PERCENT_ROLLING = "19",
  SPEND_X_VALUE_SAVE_Y_VALUE = "20",
  SPEND_X_SAVE_Y_PERCENT = "21",
  BUY_X_GET_Y_CHEAPEST_FREE = "22",
  MULTI_DEAL_FOR_X_VALUE = "23",
  MULTI_DEAL_SAVE_X_VALUE = "24",
  MULTI_DEAL_GET_X_POINTS = "25",
  MULTI_DEAL_SAVE_Y_PERCENT = "26",
}

export enum PromotionTypes {
  NECTAR = "NECTAR",
  TPR = "TPR",
  COMPLEX = "COMPLEX",
  NECTAR_POINTS = "NECTAR_POINTS",
  YOUR_NECTAR_PRICE = "YOUR_NECTAR_PRICE",
}

export type ClassPrefix = "pt" | "pd";

export enum KeyboardEventKey {
  ESCAPE = "Escape",
  ENTER = "Enter",
  SPACE = " ",
}
