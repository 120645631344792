import { Optional } from "../types";
import fetchWithTimeout from "./fetchWithTimeout";

export enum Method {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
}

export enum ErrorCode {
  JSON_PARSE_ERROR = "JSON_PARSE_ERROR",
  UNHANDLED_ERROR = "UNHANDLED_ERROR",
  NETWORK_CLIENT_ERROR = "NETWORK_CLIENT_ERROR",
}

/**
 * request returns a promise with the result of the api call. It never
 * throws errors, so users are guaranteed to receive a resolved promise with
 * either data or errors. Therefore users should not use try/catch semantics.
 * Usage:
 *  const {data, error} = await request<>
 */
export const request = <T>(
  method: Method,
  url: string,
  body: Optional<object | string> = undefined,
  headers: Optional<object> = {},
  isJson: Optional<boolean> = true,
  timeout: Optional<number> = 30000
): Promise<Result<T>> => errorAdapter(doRequest(method, url, body, headers, isJson, timeout));

/**
 * Represents the result of an api call. Contains the response {@code data} T, the raw {@code response}
 * and any service {@code errors}
 */
export class Result<T> {
  readonly errors: ServiceErrors;
  readonly data: T;
  readonly response: Response;

  constructor(errors: ServiceErrors, response: Response, data: T) {
    this.errors = errors;
    this.data = data;
    this.response = response;
  }

  isClientError(): boolean {
    if (this.response) {
      return this.response.status >= 400 && this.response.status < 500;
    }
    return false;
  }

  isUnauthorised(): boolean {
    if (this.response) {
      return this.isClientError() && this.response.status === 401;
    }
    return false;
  }

  isSuccess(): boolean {
    if (this.response) {
      return this.response.status >= 200 && this.response.status < 400;
    }
    return false;
  }

  isServerError(): boolean {
    if (this.response) {
      return this.response.status >= 500;
    }
    return true;
  }
}

export interface ApiError {
  errors: ServiceError[];
}

export class ServiceErrors {
  readonly errors: ServiceError[];

  constructor(errors: ServiceError[]) {
    this.errors = errors;
  }

  public hasCode = (code: string): boolean => {
    return this.errors.some(e => e.code === code);
  };

  public first = (): ServiceError => {
    return this.errors[0];
  };

  public getByCode = (code: string): ServiceError => {
    return this.errors.filter(e => e.code)[0];
  };
}

export interface ServiceError {
  code: string;
  title: string;
  detail: string;
  meta?: any;
}

type ResponseHandler<T> = (response: Response) => Promise<Result<T>>;

const handleResponse = async <T>(resp: Response): Promise<Result<T>> => {
  let responseJSON;
  try {
    const text = await resp.text();
    if (text && text.length > 0) {
      responseJSON = JSON.parse(text);
    }
  } catch (error) {
    const generalClientError = new ServiceErrors([
      {
        code: ErrorCode.JSON_PARSE_ERROR,
        title: "Response not parseable",
        detail: error.toString(),
      },
    ]);
    return new Result<T>(generalClientError, resp, null as any);
  }

  if (resp.status >= 200 && resp.status < 400) {
    return new Result<T>(new ServiceErrors([]), resp, responseJSON);
  }
  const apiErrors = responseJSON as ApiError;
  return new Result<T>(new ServiceErrors(apiErrors.errors), resp, null as any);
};

const doRequest = async <T>(
  method: Method,
  url: string,
  body: any,
  headers: {},
  isJson: boolean,
  timeout: number
): Promise<Result<T>> => {
  try {
    const resp: Response = await fetchWithTimeout({
      url: new URL(url, window.location.href).toString(),
      options: {
        method,
        credentials: "same-origin" as const,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...Object.keys(headers).reduce((acc, name) => {
            acc[`${name}`] = headers[`${name}`];
            return acc;
          }, {}),
        },
        body: body ? (isJson ? JSON.stringify(body) : body) : null,
        timeout,
      },
    });
    const responseHandler: ResponseHandler<T> = (response: Response) => handleResponse(response);
    return responseHandler(resp);
  } catch (e) {
    const generalClientError = new ServiceErrors([
      {
        code: "NETWORK_ERROR",
        title: "Network error",
        detail: e.message ? e.message : "",
      },
    ]);
    return new Result<T>(generalClientError, null as any, null as any);
  }
};

/**
 * Handles any errors thrown from {@code promise} and resolves those errors as data
 */
const errorAdapter = <T>(promise: Promise<Result<T>>): Promise<Result<T>> => {
  return promise
    .then(result => result)
    .catch(error => {
      const networkClientError = new ServiceErrors([
        {
          code: ErrorCode.NETWORK_CLIENT_ERROR,
          title: "Network Client Error",
          detail: error.toString(),
        },
      ]);
      return new Result<T>(networkClientError, null as any, null as any);
    });
};
