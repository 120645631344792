import { LoginState } from "../../common/store";
import { Mission } from "../../domain/mission/mission.types";

export type ProgressiveRegistrationPending = boolean;

export type changeHandler = (name: keyof ProgressiveRegistrationFormValueType) => (value: string | boolean) => void;

export type createChangeHandler = (dispatch: any) => changeHandler;

export enum cpmOptions {
  cpmSainsburys = "cpmSainsburys",
  cpmArgos = "cpmArgos",
  cpmSainsburysBank = "cpmSainsburysBank",
  cpmTu = "cpmTu",
  cpmArgosFinancial = "cpmArgosFinancial",
  cpmHabitat = "cpmHabitat",
}

export type CPMOptionsValueType = { [key in cpmOptions]?: boolean };

export type MappedMissionsConsentsApiResponseType = {
  [key in cpmOptions]?: {
    value: boolean;
  };
};

export interface MappedMissionsConsentReponseType extends MappedMissionsConsentsApiResponseType {
  cpmSelectedOption?: object;
}

export interface ProgressiveRegistrationMandatoryValueType {
  termsChecked: boolean;
  password: string;
  mobileNumber: string;
  postcode: string;
  cpmSelectedOption?: string;
}

export interface ProgressiveRegistrationFormValueType
  extends CPMOptionsValueType,
    ProgressiveRegistrationMandatoryValueType {
  cpmSelectedOption?: string;
}

export interface ProgressiveRegistrationFormPristineType extends CPMOptionsValueType {
  termsChecked: boolean;
  password: boolean;
  mobileNumber: boolean;
  postcode: boolean;
  cpmSelectedOption?: boolean;
}

export type ProgressiveRegistrationFormFieldErrorType = {
  termsChecked: string;
  password: string;
  mobileNumber: string;
  postcode: string;
  cpmSelectedOption?: string;
  cpmArgos?: string;
  cpmArgosFinancial?: string;
  cpmSainsburys?: string;
  cpmSainsburysBank?: string;
  cpmTu?: string;
  cpmHabitat?: string;
};

export interface FormProps {
  values: ProgressiveRegistrationFormValueType;
  pristine: ProgressiveRegistrationFormPristineType;
  fieldErrors: ProgressiveRegistrationFormFieldErrorType;
  fieldWarnings: ProgressiveRegistrationFormFieldErrorType;
  email: LoginState["verifiedEmail"];
  isSubmitPending: ProgressiveRegistrationPending;
  isFormValid: boolean;
  onSubmit: (values: ProgressiveRegistrationFormValueType) => void;
  createChangeHandler: createChangeHandler;
  getErrorAndWarning: (field: keyof ProgressiveRegistrationFormFieldErrorType) => { error: string; warning: string };
}

export type ProgressiveRegistrationFormErrorType = { [P in keyof ProgressiveRegistrationMandatoryValueType]: string };

export type ProgressiveRegistrationFormWarningType = {
  mobileNumber: string;
  postcode: string;
};

export interface ProgressiveRegistrationForm extends ProgressiveRegistrationMandatoryValueType {
  verifiedEmail: LoginState["verifiedEmail"];
  cpmSelectedOption?: string;
  cpmArgos?: boolean;
  cpmArgosFinancial?: boolean;
  cpmSainsburys?: boolean;
  cpmSainsburysBank?: boolean;
  cpmTu?: boolean;
  cpmHabitat?: boolean;
}

export enum missionsConsentsTypes {
  AFS = "cpmArgosFinancial",
  ARGOS = "cpmArgos",
  BANK = "cpmSainsburysBank",
  GOL = "cpmSainsburys",
  TU = "cpmTu",
  HABITAT = "cpmHabitat",
}

export enum ProgressiveRegistrationActionTypes {
  PROGRESSIVE_REGISTRATION_REQUEST = "PROGRESSIVE_REGISTRATION_REQUEST",
  PROGRESSIVE_REGISTRATION_SUCCESS = "PROGRESSIVE_REGISTRATION_SUCCESS",
  PROGRESSIVE_REGISTRATION_FAILURE = "PROGRESSIVE_REGISTRATION_FAILURE",
  PROGRESSIVE_REGISTRATION_ERROR = "PROGRESSIVE_REGISTRATION_ERROR",
}

export interface ProgressiveRegistrationCPMProps {
  formValues: ProgressiveRegistrationFormValueType;
  onChange: changeHandler;
}

export interface ProgressiveRegistrationActionFormProps extends ProgressiveRegistrationCPMProps {
  formPristine: ProgressiveRegistrationFormPristineType;
  fieldErrors: ProgressiveRegistrationFormErrorType;
  fieldWarnings: ProgressiveRegistrationFormWarningType;
}

export interface ProgressiveRegistrationCPMOptionProps {
  value: keyof CPMOptionsValueType;
  checked: CPMOptionsValueType[keyof CPMOptionsValueType];
  onChange: changeHandler;
}

export interface ConfirmChangesProps extends ProgressiveRegistrationActionFormProps {
  isFormValid: boolean;
  isSubmitPending: boolean;
  email: string;
  originMission: Mission;
  forgotPasswordUrl: string;
}

export type ValidatorResult = {
  warning?: boolean;
  error?: boolean;
};

export enum errorName {
  PASSWORD = "password",
}

export enum errorType {
  ACCOUNT_BLOCKED = "ACCOUNT_BLOCKED",
  HUMAN_VERIFICATION_FAILED = "HUMAN_VERIFICATION_FAILED",
}
