import { createSelector } from "reselect";
import { State } from "../../common/store";
import {
  ButtonStatus,
  ItemType,
  ProductOption,
  ProductPage,
  ProductTileListContainerProps,
  ProductListItem,
  ProductTileListSingleRowProps,
  ProductTilePrice,
  ProductTileProduct,
  ProductTilePromotion,
} from "./productTile.types";
import { InterleavedEspot } from "../Espots/Espot.types";
import { AdsInsertionRules, InterleavedBannerAd, InterleavedBannerAdV2 } from "../Citrus/BannerAd/BannerAd.types";
import { BasketProduct, ITEMS, KG, UnitOfMeasure } from "../../domain/basket/basket.types";
import {
  ProductUid,
  Product,
  MultiVariant,
  Promotion,
  Catchweight,
  RetailPrice,
  UnitPrice,
  ProductType,
} from "../../domain/product/product.types";
import { getPDPCanonical } from "./utils";

export enum maxLengthString {
  PRODUCT_TILE_TITLE_STRING = 60,
  PRODUCT_TILE_MULTIVARIANT_STRING = 80,
  PRODUCT_TILE_PROMOTIONS_OPTION_STRING = 50,
  PRODUCT_TILE_MULTIBUY_MINITROLLEY = 20,
}

export const mapProductTileProduct = (
  product: Product,
  isScrolledIntoView: boolean,
  basketProduct?: BasketProduct
): ProductTileProduct => ({
  product,
  productOptions: mapProductOptions(product),
  productPage: ProductPage.PRODUCT_TILE,
  promotions: product.promotions && mapPromotions(product.promotions),
  retailPrice: product.retailPrice && mapRetailPrice(product.retailPrice),
  unitPrice: product.unitPrice && mapUnitPrice(product.unitPrice),
  selectedUnitOfMeasure: mapSelectedUnitOfMeasure(basketProduct),
  isScrolledIntoView,
  productCTA: product.productCTA,
  productHeader: product.productHeader,
  messages: product.messages,
  canonicalUrl: getPDPCanonical(),
});

const mapProductOptions = (product: Product): ProductOption[] | undefined => {
  if (product && product.productType === ProductType.CATCHWEIGHT) {
    return mapCatchweightProductOptions(product.catchweight!);
  }
  if (product && product.productType === ProductType.MULTIVARIANT) {
    return mapMultiVariantProductOptions(product.multivariants!);
  }
  return undefined;
};

const mapMultiVariantProductOptions = (multivariants: MultiVariant[]): ProductOption[] =>
  multivariants.map(({ displayName, productUid, retailPrice, unitPrice }) => ({
    value: productUid,
    label: displayName,
    retailPrice: mapRetailPrice(retailPrice),
    unitPrice: mapUnitPrice(unitPrice),
  }));

export const mapCatchweightProductOptions = (catchweights: Catchweight[]): ProductOption[] =>
  catchweights.map(({ range, retailPrice, unitPrice }) => ({
    value: range,
    label: stringFormatter(range, maxLengthString.PRODUCT_TILE_MULTIVARIANT_STRING),
    retailPrice: mapRetailPrice(retailPrice),
    unitPrice: mapUnitPrice(unitPrice),
  }));

export const mapPromotions = (promotions: Promotion[]): ProductTilePromotion[] =>
  promotions.map(
    ({
      promotionUid,
      strapLine,
      url,
      promotionMissed,
      originalPrice,
      endDate,
      promoMechanicId,
      promoType,
      promoGroup,
      isNectar,
    }) => ({
      promotionUid,
      strapLine: stringFormatter(strapLine, maxLengthString.PRODUCT_TILE_PROMOTIONS_OPTION_STRING),
      url,
      promotionMissed,
      originalPrice,
      endDate,
      promoMechanicId,
      promoType,
      promoGroup,
      isNectar,
    })
  );

export const mapRetailPrice = (retailPrice: RetailPrice): ProductTilePrice | undefined => {
  // retail price can get set to 0 when the product is no longer ranged.
  return retailPrice.price && retailPrice.price !== 0
    ? {
        price: currencyFormatter(retailPrice.price),
        measure: retailPrice.measure,
        wasPrice: Boolean(retailPrice.wasPrice) ? currencyFormatter(retailPrice.wasPrice!) : undefined,
      }
    : undefined;
};

export const mapUnitPrice = (unitPrice: UnitPrice): ProductTilePrice | undefined => {
  // unit price can get set to 0 when the product is no longer ranged.
  return unitPrice.price && unitPrice.price !== 0
    ? {
        price: currencyFormatter(unitPrice.price),
        measure: formatMeasure(unitPrice.measureAmount, unitPrice.measure),
      }
    : undefined;
};

const mapSelectedUnitOfMeasure = (basketProduct?: BasketProduct): UnitOfMeasure => {
  if (!basketProduct) {
    return ITEMS;
  }
  return basketProduct.unitOfMeasure;
};

export const selectProduct = (state: State, productUid: ProductUid): Product | undefined =>
  state.products.data.get(productUid);

export const selectFullUrlForProduct = (state: State, productUid: ProductUid): string | undefined => {
  const product = selectProduct(state, productUid);
  return product ? product.fullUrl : undefined;
};

export const selectFavouriteUid = (state: State, productUid: ProductUid): string | null => {
  const product = selectProduct(state, productUid);
  return product ? product.favouriteUid : null;
};

export const selectIsFirstFavourites = (state: State, productUid: ProductUid): boolean => {
  const recommendations = state.products.views.first_favourites || [];
  if (Array.isArray(recommendations) && recommendations.length) {
    if (recommendations.includes(productUid)) {
      return true;
    }
  }
  return false;
};

export const currencyFormatter = (value: string | number): string => {
  // cast to number if string
  const valueNumeric = typeof value === "number" ? value : parseFloat(value);
  // minus sign depending on value
  const minusSign = valueNumeric < 0 ? "-" : "";
  // is below £1
  const belowPound = Math.abs(valueNumeric) < 1;
  if (belowPound) {
    // Split on decimal dot to get decimal string and remove leading 0
    const pence = valueNumeric.toFixed(2).split(".")[1].replace(/^0/g, "");
    // Format with p
    return `${minusSign}${pence}p`;
  }
  // Clamp at 2 decimal digits and add pound sign
  return `${minusSign}£${Math.abs(valueNumeric).toFixed(2)}`;
};

export const stringFormatter = (value: string, maxLength: maxLengthString): string => {
  let result: string = value;
  if (value && value.length > maxLength) {
    result = `${value.substring(0, maxLength)}...`;
  }
  return result;
};

export const formatMeasure = (measureAmount: number = 1, measure: string): string =>
  `${measureAmount === 1 ? "" : measureAmount}${measure}`;

export const formatQuantity = (quantity: number, selectedUnitOfMeasure: UnitOfMeasure): string => {
  // if quantity contains decimal part compute the length of decimal places to display with max of 2
  const significantDecimalPlaces = quantity % 1 ? Math.min(2, String(quantity).split(".").pop()!.length) : 0;
  return Boolean(selectedUnitOfMeasure === KG && quantity)
    ? `${quantity.toFixed(significantDecimalPlaces)} ${KG}`
    : `${quantity.toFixed(significantDecimalPlaces)}`;
};

export const selectBasketQuantity = (state: State, productUid: ProductUid) =>
  state.basket.quantities[`${productUid}`] || { quantity: 0, loading: false };

export const getButtonStatus = (isLoading: boolean, isDisabled?: boolean) => {
  if (isDisabled) {
    return ButtonStatus.DISABLED;
  }
  if (isLoading) {
    return ButtonStatus.PENDING;
  }
  return ButtonStatus.AVAILABLE;
};

const selectInsertSequence = (insertionRules: AdsInsertionRules, isCategorySort: boolean | undefined): number =>
  isCategorySort ? insertionRules.categoriesPosition.insertSequence : insertionRules.insertSequence;

export const selectProductTileListItems = ({
  products,
  espots,
  bannerAds,
  bannerAdsV2,
  isCategorySort,
}: {
  products: ProductTileListContainerProps["products"];
  espots?: ProductTileListContainerProps["espots"];
  bannerAds?: InterleavedBannerAd[];
  bannerAdsV2?: InterleavedBannerAdV2[];
  isCategorySort: boolean;
}): ProductListItem[] => {
  let items: ProductListItem[] = [];

  products.forEach((product: ProductUid, index) => {
    items.push({ item: product, type: ItemType.PRODUCT });

    if (Array.isArray(espots)) {
      const isLastProduct: boolean = products.length === index + 1;
      const currentPositionEspots: InterleavedEspot[] = espots.filter(espot =>
        isLastProduct
          ? selectInsertSequence(espot, isCategorySort) >= index
          : selectInsertSequence(espot, isCategorySort) === index
      );

      if (currentPositionEspots.length) {
        items = items.concat(
          currentPositionEspots.map(espot => ({
            item: espot,
            type: ItemType.ESPOT,
          }))
        );
      }
    }

    if (Array.isArray(bannerAds)) {
      const isLastProduct = products.length === index + 1;
      const currentPositionAds = bannerAds.filter((bannerAd: InterleavedBannerAd) =>
        isLastProduct
          ? selectInsertSequence(bannerAd, isCategorySort) >= index
          : selectInsertSequence(bannerAd, isCategorySort) === index
      );

      if (currentPositionAds.length) {
        items = items.concat(
          currentPositionAds.map(bannerAd => ({
            item: bannerAd,
            type: ItemType.IN_GRID_BANNER,
          }))
        );
      }
    }

    if (Array.isArray(bannerAdsV2)) {
      const isLastProduct = products.length === index + 1;
      const currentPositionAds = bannerAdsV2.filter((bannerAdV2: InterleavedBannerAdV2) =>
        isLastProduct
          ? selectInsertSequence(bannerAdV2, isCategorySort) >= index
          : selectInsertSequence(bannerAdV2, isCategorySort) === index
      );

      if (currentPositionAds.length) {
        items = items.concat(
          currentPositionAds.map(bannerAdV2 => ({
            item: bannerAdV2,
            type: ItemType.IN_GRID_BANNER_V2,
          }))
        );
      }
    }
  });

  return items;
};

export const selectProductTileListItemsCached = createSelector(
  (
    products: ProductTileListContainerProps["products"],
    espots: ProductTileListContainerProps["espots"],
    bannerAds: ProductTileListContainerProps["bannerAds"],
    bannerAdsV2: ProductTileListContainerProps["bannerAdsV2"],
    productAds: ProductTileListContainerProps["products"],
    isCategorySort: boolean
  ) => ({ products, espots, bannerAds, bannerAdsV2, productAds, isCategorySort }),
  selectProductTileListItems
);

export const selectProductTileListExtraRowItems = ({
  productAds,
  itemType = ItemType.PRODUCT_AD,
}: {
  productAds?: ProductTileListContainerProps["products"];
  itemType?: ItemType;
}) => {
  let items: ProductTileListSingleRowProps["items"] = [];
  if (Array.isArray(productAds)) {
    items = items.concat(
      productAds.map(productAd => ({
        item: productAd,
        type: itemType,
      }))
    );
  }
  return items;
};

export const selectProductTileListExtraRowItemsCached = createSelector(
  (productAds?: ProductTileListContainerProps["products"]) => ({ productAds }),
  selectProductTileListExtraRowItems
);
