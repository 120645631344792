import React from "react";
import { DeliveryPassLogo as Logo } from "../../components/Logo";
import { GOLLink } from "@components/GOLLink";
import { ContentSkipTo } from "@components/Header/AccessibleSkipTo.component";
import { HeaderWrapper } from "@components/Header/HeaderWrapper/HeaderWrapper";
import { urls } from "../../routes";
import { useResponsive } from "../../components/ResponsiveProvider";

export const CheckoutHeader = () => {
  const { isMobile } = useResponsive();
  return (
    <div>
      <HeaderWrapper className={"delivery-pass__checkout-header ln-c-card--soft header__card"}>
        <div className="checkout-header__skipto-links">
          <ContentSkipTo />
        </div>
        <div className="delivery-pass__checkout-header__content">
          {!isMobile && (
            <div className="delivery-pass__checkout-header__item">
              Explore more at{" "}
              <GOLLink to={urls.BROWSE} aria-label={`Groceries Home`}>
                <u>Sainsburys.co.uk</u>
              </GOLLink>
            </div>
          )}
          <div className="checkout-header__item ln-u-padding-top*2">
            <GOLLink to={urls.DELIVERY_PASS} aria-label={`Delivery Pass Home`}>
              <Logo className="delivery-pass__checkout-header__logo" />
            </GOLLink>
          </div>
        </div>
      </HeaderWrapper>
    </div>
  );
};
