import { InterleavedEspot } from "./Espot.types";

export * from "./espot.actions";
export * from "./espot.reducer";
export * from "./espot.analytics";
export * from "./espot.digitaldata";
export * from "./Espot.types";
export * from "./Espot.component";
export * from "./Espot.container";
export * from "./FeatureFlagEspot";

export const favouritePlacement: InterleavedEspot[] = [
  {
    espotParams: {
      name: "NZ_Favourites_Responsive_Grid_1",
    },
    insertSequence: 3,
    categoriesPosition: { rowIndex: 0, insertSequence: 3 },
  },
];

export const searchResultsPlacement: InterleavedEspot[] = [
  {
    espotParams: {
      name: "NZ_Search_Responsive_Grid_1_By_SearchTerm",
    },
    insertSequence: 3,
    categoriesPosition: { rowIndex: 0, insertSequence: 3 },
  },
];
