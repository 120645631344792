import React from "react";
import { EspotConnectedContainer } from "./Espot.container";
import { EspotContainerProps } from "./Espot.types";
import { FeatureFlagControlledAd } from "../Citrus/FeatureFlagControlledAd/FeatureFlagControlledAd";

export const FeatureFlagEspot = (props: EspotContainerProps) => (
  <FeatureFlagControlledAd>
    <EspotConnectedContainer {...props} />
  </FeatureFlagControlledAd>
);
