import React from "react";
import { BasicModalProps, ModalNames } from "./Modal.types";
import { modalWithWrapper } from "./ModalWrapper.component";
import { useHandleScrollbarWidth } from "../../common/hooks/useHandleScrollbarWidth";
import { BasicModalWithClose } from "./BasicModalWithClose/BasicModalWithClose";

type FavouriteSaveErrorWithWrapperProps = Pick<BasicModalProps, "title" | "description" | "primaryButtonText">;

const FavouriteSaveErrorWithWrapper = modalWithWrapper<FavouriteSaveErrorWithWrapperProps>(
  BasicModalWithClose,
  ModalNames.MODAL_SAVE_FAVOURITE_ERROR
);

const FavouriteSaveErrorWithHook: any = (props: FavouriteSaveErrorWithWrapperProps) => {
  useHandleScrollbarWidth();
  return <FavouriteSaveErrorWithWrapper {...props} />;
};

export const FavouriteSaveErrorModal = () => (
  <FavouriteSaveErrorWithHook
    title="Save to favourites"
    description="There has been an error saving this favourite."
    primaryButtonText="OK"
  />
);
