import GolTime from "../../domain/time";
import { AnyAction } from "redux";
import { DeferredConditionalAction, ActionTypes } from "./deferredConditionalAction.types";

export const createDeferredConditionalAction = (
  type: string,
  expiryTime: GolTime,
  action: AnyAction
): DeferredConditionalAction => ({
  type: ActionTypes.PERSIST_CONDITIONAL_ACTION,
  persistedAction: {
    type,
    expiryTime: expiryTime.timestamp,
    redispatchedAction: action,
  },
});
