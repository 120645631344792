import React from "react";
import { Route } from "react-router-dom";
import { GOLLink } from "../../components/GOLLink";
import { SainsburysLogo } from "../../components/Logo";
import { routes, urls } from "../../routes";
import { ContentWrap } from "../../components/ContentWrap";
import { Account, ArrowLeft } from "@jsluna/icons";

export const Header = () => (
  <div data-testid="header" className="header-background-minimal">
    <ContentWrap className="header pr-header">
      <Route
        path={routes.PROGRESSIVE_REGISTRATION}
        exact
        render={() => (
          <GOLLink to={urls.LOGON_VIEW_IDENTIFIER} className="pr-back-link" data-testid="pr-header-back-link">
            <ArrowLeft />
            <span className="pr-link-text-wrap"> Back</span>
          </GOLLink>
        )}
      />
      <SainsburysLogo className="pr-js-logo" />
      <Route
        path={routes.OAUTH_REGISTRATION_CONFIRMATION}
        exact
        render={() => (
          <GOLLink to={urls.MY_ACCOUNT} className="pr-my-account-link" data-testid="my-account-link">
            <Account className="pr-my-account-link__icon" />
            <span className="pr-my-account-link__text">My account</span>
          </GOLLink>
        )}
      />
    </ContentWrap>
  </div>
);
