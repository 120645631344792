import React from "react";
import EmptyTrolleyImage from "../../../static/empty-trolley.svg";
import { FilledButton, LinkButton, OutlinedButton, ProgressIndicator, ProgressSpinner } from "@jsluna/react";
import { ProductTileMiniComponent } from "../../ProductTile/ProductTileMini.component";
import { MiniTrolleyContentProps } from "./MiniTrolley.types";
import { ProductTileMiniProduct } from "../../ProductTile/productTile.types";
import { getChooseShoppingMethodUrl } from "../../../routes";
import { InlineError } from "../../Error/InlineError";

export const MiniTrolleyContent = ({
  isLoading,
  items,
  nectarOffers,
  showFormToEmptyMiniTrolleyPanel,
  error,
  handleGoBack,
  handleClickStartShopping,
  handleDoEmptyMiniTrolley,
  handleShowEmptyMiniTrolleyPanel,
  isAllItemsAmendable,
}: MiniTrolleyContentProps) => {
  if (showFormToEmptyMiniTrolleyPanel) {
    return (
      <div className="mini-trolley__content mini-trolley__content--flex">
        <div className="mini-trolley__panel">
          <span className="mini-trolley__panel-title" data-testid="mini-trolley-panel-title">
            Are you sure you want to empty your trolley?
          </span>
          <span className="mini-trolley__panel-subtitle" data-testid="mini-trolley-panel-subtitle">
            If you empty your trolley, all items will be removed.{" "}
            <span className="no-break">This cannot be undone.</span>
          </span>
          <OutlinedButton
            className="mini-trolley__panel-button-left"
            data-testid="mini-trolley-panel-button-back"
            onClick={handleGoBack}
          >
            Go back
          </OutlinedButton>
          <FilledButton
            className="mini-trolley__panel-button-right"
            data-testid="mini-trolley-panel-button"
            onClick={handleDoEmptyMiniTrolley}
          >
            Empty trolley
          </FilledButton>
        </div>
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="mini-trolley__content mini-trolley__content--flex">
        <div className="mini-trolley__panel-loading-container">
          <ProgressIndicator
            className="ln-u-justify-content mini-trolley__panel-loading"
            data-testid="mini-trolley-panel-loading"
            loading
            preventFocus
          >
            <ProgressSpinner size="small" />
          </ProgressIndicator>
        </div>
      </div>
    );
  }
  if (items.length > 0) {
    return (
      <div className="mini-trolley__content">
        {error === "general_error" && (
          <div className="ln-u-margin-bottom*2">
            <InlineError errorMessage="Sorry, something went wrong. Please try to Checkout again." />
          </div>
        )}
        {error === "slot_error" && (
          <div className="ln-u-margin-bottom*2">
            <InlineError
              errorMessage="We can’t retrieve slot information at the moment."
              callToAction={() => window.location.assign(getChooseShoppingMethodUrl())}
              callToActionMessage={{ link: "Retry" }}
            />
          </div>
        )}
        {items.map((item: ProductTileMiniProduct) => {
          const nectarOffer =
            nectarOffers && nectarOffers.find(offer => offer.skus.find(offerSku => offerSku === item.product.sku));

          return (
            <ProductTileMiniComponent
              key={item.product.itemId}
              productUid={item.product.sku}
              image={item.product.image}
              url={item.product.productUrl}
              name={item.product.productName}
              subTotal={item.product.subTotal}
              promotions={item.product.promotions}
              showPromotionPrice={item.showPromotionPrice}
              nectarOffer={nectarOffer}
              unitOfMeasure={item.product.unitOfMeasure}
              totalBasePrice={item.product.totalBasePrice}
              isYourNectarPrice={item.product.isYourNectarPrice}
              isSupplyChainOrderable={item.product.isSupplyChainOrderable}
              orderPromise={item.product.orderPromise}
              serves={item.serves}
            />
          );
        })}
        <div className="mini-trolley__empty-button-wrapper">
          <LinkButton
            color="dark"
            data-testid="mini-trolley-empty-trolley-link"
            onClick={handleShowEmptyMiniTrolleyPanel}
            disabled={!isAllItemsAmendable}
          >
            Empty Trolley
          </LinkButton>
        </div>
      </div>
    );
  }

  return (
    <div className="mini-trolley__content mini-trolley__content--flex">
      <div className="mini-trolley__panel">
        <img className="mini-trolley__panel-image" src={EmptyTrolleyImage} aria-hidden="true" alt="" />
        <span className="mini-trolley__panel-title" data-testid="mini-trolley-panel-title">
          Your Trolley is empty
        </span>
        <span className="mini-trolley__panel-subtitle" data-testid="mini-trolley-panel-subtitle">
          Add some items to get started
        </span>
        <OutlinedButton
          className="mini-trolley__panel-button"
          data-testid="mini-trolley-panel-button"
          onClick={handleClickStartShopping}
        >
          Start shopping
        </OutlinedButton>
      </div>
    </div>
  );
};
