import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { Promotion, YourNectarPrice } from "../../domain/product/product.types";
import { OfferType } from "../../common/dataLayer/types";
import { PromoMechanicIds } from "../../common/types";

export function useTimedCall(
  ref: React.RefObject<HTMLAnchorElement> | React.RefObject<HTMLDivElement>,
  callback: any,
  id: string,
  promotions: Promotion[] | undefined,
  key: string,
  yourNectarPrice?: YourNectarPrice
) {
  const timer: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  const startTimer = useCallback(() => {
    stopTimer();

    let price = OfferType.ORIGINAL_PRICE;
    if (yourNectarPrice) {
      price = OfferType.YOUR_NECTAR_PRICE;
    } else if (promotions && promotions.length > 0) {
      price =
        promotions[0].promoMechanicId === PromoMechanicIds.NECTAR_PROMOTION
          ? OfferType.NECTAR_PRICE
          : OfferType.TYPICAL_PRICE_REDUCTION;
    }

    timer.current = setTimeout(() => {
      callback(id, price, key);
    }, 2000);
    // TO-DO: refactor when useEffectEvent is stable see https://react.dev/reference/react/experimental_useEffectEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const node: HTMLAnchorElement | HTMLDivElement | null = ref.current;

    // Check when product will become visible to add it to seen products list
    if (callback) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            startTimer();
          } else {
            stopTimer();
          }
        },
        { threshold: 0.5 }
      );

      node && observer.observe(node);

      return () => {
        node && observer.unobserve(node);
        stopTimer();
      };
    }
    // TO-DO: refactor when useEffectEvent is stable see https://react.dev/reference/react/experimental_useEffectEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
