import React from "react";
import { Espot } from "./Espot.component";
import { State } from "../../common/store";
import { connect } from "react-redux";
import { selectSlot } from "../../views/slot.selectors";
import { createSetEspotUnavailable } from "./espot.actions";
import { AppThunkDispatch } from "../../common/types";
import { fetchEspot } from "../../services/espots";
import { EspotContainerProps } from "./Espot.types";

interface EspotContainerState {
  markup: string | null;
  hasErrors: boolean;
}

/**
 * This component should not be rendered by any component other than FeatureFlagEspot.
 *
 * This is so that we can ensure that the "all_ad_components_enabled" feature flag can turn off ALL espots if we ever need to.
 */
export class EspotContainer extends React.PureComponent<EspotContainerProps, EspotContainerState> {
  // contain the JS errors inside espots so that the page tree is not removed by React
  static getDerivedStateFromError() {
    return {
      hasErrors: true,
    };
  }

  constructor(props: EspotContainerProps) {
    super(props);
    this.state = {
      markup: null,
      hasErrors: false,
    };
  }

  async fetchData() {
    const result = await fetchEspot(this.props.espotParams);
    if (result.isSuccess()) {
      this.setState({
        markup: result.data.data,
        hasErrors: false,
      });
    } else {
      this.setState({
        hasErrors: true,
        markup: null,
      });
      this.props.setEspotUnavailable(this.props.espotParams.name);
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate({ espotParams }: EspotContainerProps): void {
    if (espotParams.search_term !== this.props.espotParams.search_term) {
      this.fetchData();
    }
  }

  getSafeName() {
    return this.props.espotParams.name.replace(/\W/g, "");
  }

  render() {
    return this.state.hasErrors ? null : (
      <Espot markup={this.state.markup} name={this.getSafeName()} expandHeight={this.props.expandHeight} />
    );
  }
}

const mapStateToProps = (state: State, ownProps: EspotContainerProps): Partial<EspotContainerProps> => {
  const slot = selectSlot(state);
  return {
    espotParams: {
      ...ownProps.espotParams,
      store_identifier: slot ? slot.storeIdentifier : undefined,
    },
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): Partial<EspotContainerProps> => ({
  setEspotUnavailable: (espotName: string) => dispatch(createSetEspotUnavailable(espotName)),
});

/**
 * This component should not be rendered by any component other than FeatureFlagEspot.
 *
 * This is so that we can ensure that the "all_ad_components_enabled" feature flag can turn off ALL espots if we ever need to.
 */
export const EspotConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(EspotContainer);
