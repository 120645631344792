import { LocationState } from "./locationReducer";
import { matchesPath, routes } from "../../routes";
import { JSSanitise } from "../../utils/JSSanitise";

export const DefaultLoginRedirect = "/gol-ui/MyAccount";

export const blockedRedirectRefererUrlPaths: string[] = [
  "/shop/ResetPassword",
  "/webapp/wcs/stores/servlet/ResetPassword",
  "/shop/ResetPasswordConfirmView",
  "/webapp/wcs/stores/servlet/ResetPasswordConfirmView",
  "/shop/LogoutByeForNowView",
  "/webapp/wcs/stores/servlet/LogoutByeForNowView",
  "/shop/Logoff",
  "/webapp/wcs/stores/servlet/Logoff",
  "/shop/PostcodeCheckView",
  "/webapp/wcs/stores/servlet/PostcodeCheckView",
  "/shop/QuickRegistrationFormView",
  "/webapp/wcs/stores/servlet/QuickRegistrationFormView",
  "/shop/CheckPostCode",
  "/webapp/wcs/stores/servlet/CheckPostCode",
  "/shop/ReLogonFormView",
  "/webapp/wcs/stores/servlet/ReLogonFormView",
  "/shop/MyDetailsUpdate",
  "/webapp/wcs/stores/servlet/MyDetailsUpdate",
  "/gol-ui/InternalServerError",
  "/",
];

export const cleanUrlQuery = (url: string): string => {
  if (!url) {
    return "";
  }

  const u = new URL(url);
  return `${u.origin}${u.pathname}`;
};

export const getPathFromFullUrl = (url: string): string => {
  if (!url) {
    return "";
  }
  // If the url doesn't start with http, we need to add a dummy domain to be able to parse it
  const u = new URL(url, !startsWithHttp(url) ? "http://example.com" : undefined);
  return u.pathname;
};

const isSameDomain = (original: string, url: string): boolean => {
  if (!original || !url) {
    return false;
  }
  return new URL(original).host === new URL(url).host;
};

const isSameUrl = (url1: string, url2: string): boolean => cleanUrlQuery(url1) === cleanUrlQuery(url2);

export const isUrlPathInList = (url: string, list: string[]): boolean => list.indexOf(getPathFromFullUrl(url)) >= 0;

export const startsWithHttp = (url: string) => /^https?:/.test(url);

const getValidRedirectUri = (initialLocation: string): string | null => {
  try {
    const initialUrl = new URL(initialLocation);
    const receivedUrl = initialUrl.searchParams.get("redirectUri");

    if (receivedUrl) {
      const redirectUrl = new URL(receivedUrl);
      const currentHost = initialUrl.host;
      const redirectHost = redirectUrl.host;

      const currentDomain = currentHost.replace("www.", ".");

      if (redirectHost.endsWith(currentDomain)) {
        return redirectUrl.href;
      }
    }
  } catch {
    return null;
  }
  return null;
};

export function selectLoginRedirectUrl(
  locationState: LocationState,
  redirectRefererUrlPaths: string[] = blockedRedirectRefererUrlPaths
): string {
  const sanitizedCurrent = JSSanitise.url(locationState.current);
  const sanitizedReferrer = JSSanitise.url(locationState.referrer);
  // 1. Extract redirectUri from the query,
  // check if it comes from the same domain and return it
  const redirectUri = getValidRedirectUri(sanitizedCurrent);
  if (redirectUri) {
    return redirectUri;
  }

  // verify referrer and return it
  if (
    sanitizedReferrer &&
    isSameDomain(sanitizedCurrent, sanitizedReferrer) &&
    !isSameUrl(sanitizedCurrent, sanitizedReferrer) &&
    !isUrlPathInList(sanitizedReferrer, redirectRefererUrlPaths)
  ) {
    return sanitizedReferrer;
  }

  if (!sanitizedCurrent) {
    return DefaultLoginRedirect;
  }

  const url = new URL(sanitizedCurrent);

  // Whitelisted param productId or filters[keyword](WCS catEntryId) is used to fetch products on non-SEO URL
  const redirectPath = url.searchParams.has("productId")
    ? `${url.pathname}?productId=${url.searchParams.get("productId")}`
    : url.searchParams.has("filters[keyword]")
    ? `${url.pathname}?filters[keyword]=${url.searchParams.get("filters[keyword]")}`
    : url.pathname;

  return redirectPath !== "/" && !matchesPath(redirectPath, routes.OAUTH) ? redirectPath : DefaultLoginRedirect;
}
