import { useMemo } from "react";
import { useResponsive } from "../../../components/ResponsiveProvider";
import { ContentPage } from "../../../services/contentTypes";
import { useInGridAdLength } from "../../../components/IngridAd/hooks";
import { getInGridAdPositions, getBannerAdsV2GridConfig } from "./utils";

interface UseStrategicMagnoliaGridConfig {
  page: ContentPage;
  placements: string[];
}

export const useStrategicMagnoliaGridConfig = ({ page, placements }: UseStrategicMagnoliaGridConfig) => {
  const { width: screenWidth } = useResponsive();
  const { ingridAdsLength } = useInGridAdLength({ page });

  const bannerAdsV2GridConfig = useMemo(() => {
    const adPositions = getInGridAdPositions(screenWidth, ingridAdsLength);

    return getBannerAdsV2GridConfig(adPositions, placements, page);
  }, [ingridAdsLength, page, placements, screenWidth]);

  return { bannerAdsV2GridConfig };
};
