import { DataState } from "../../common/dataState";
import { Product, ProductControls } from "../../domain/product/product.types";

export enum ProductListerActionType {
  PRODUCTS_REQUEST = "PRODUCT_LISTER_REQUEST",
  PRODUCTS_SUCCESS = "PRODUCT_LISTER_SUCCESS",
  PRODUCTS_FAILURE = "PRODUCT_LISTER_FAILURE",
}

export interface ProductListerState {
  dataState: DataState;
}

export interface CreateProductListerSuccessActionType {
  type: ProductListerActionType;
  productData: { products: Product[]; controls?: ProductControls };
}

export interface CreateProductListerFailureActionType {
  type: ProductListerActionType;
  message: string;
}
