import { State } from "../../common/store";
import {
  BrowseEventsState,
  BrowseTaxonomyState,
  ParsedBrowseTaxonomyState,
  SynapticaBreadcrumbs,
} from "./Browse.types";

export const selectParsedTaxonomy = (state: State): ParsedBrowseTaxonomyState => state.parsedBrowseTaxonomy;
export const selectTaxonomy = (state: State): BrowseTaxonomyState => state.browseTaxonomy;
export const selectSynapticaBreadcrumbs = (state: State): SynapticaBreadcrumbs => state.synapticaBreadcrumbs;
export const selectEventIds = (state: State): BrowseEventsState => state.browseEvents;
