import { AnyAction } from "redux";
import { DietaryProfileUpdateActionTypes, UpdateDietaryProfileStatusRequestActionType } from "./DietaryProfile.types";
import { DietaryProfileState } from "../../common/store";
import { FoodMaestroActionTypes } from "./DietaryModal/DietaryModal.types";

export const dietaryProfileReducer = (
  state: DietaryProfileState = { optimisticState: undefined, dietaryProfileError: false, isLoading: false },
  action: AnyAction
): DietaryProfileState => {
  switch (action.type) {
    case DietaryProfileUpdateActionTypes.DIETARY_PROFILE_UPDATE_REQUEST:
      const updateDietaryProfileStatusRequestAction = action as UpdateDietaryProfileStatusRequestActionType;
      return {
        ...state,
        optimisticState: updateDietaryProfileStatusRequestAction.enabled,
      };
    case FoodMaestroActionTypes.SET_PROFILE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FoodMaestroActionTypes.SET_CONDITIONS:
      return {
        ...state,
        dietaryProfileConditions: action.payload,
        isLoading: false,
      };
    case FoodMaestroActionTypes.SET_PROFILEID:
      return {
        ...state,
        dietaryProfileId: action.payload,
        isLoading: false,
      };
    case FoodMaestroActionTypes.ERROR:
      return {
        ...state,
        dietaryProfileError: true,
        isLoading: false,
      };
    case FoodMaestroActionTypes.FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case FoodMaestroActionTypes.RESET:
      const stateClone = { ...state };
      delete stateClone.dietaryProfileConditions;
      return {
        ...stateClone,
        dietaryProfileError: false,
        isLoading: false,
      };
    default:
      return state;
  }
};
