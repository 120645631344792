import { DataState } from "../../common/dataState";
import { UserState } from "../../common/store";
import {
  Controls,
  ProductQueryParameters,
  ProductServiceError,
  SuggestedSearchTerm,
} from "../../services/product.types";
import { CachedSlotReservation } from "../../domain/slot/slotReservation.types";
import { PaginatedProducts } from "../../domain/product/product.types";
export interface SearchQueryParams {
  term: string;
  trending?: string;
  category?: string;
  sort?: string;
}

export const searchFilterKeys = {
  DIETARY: "diet_Dietary_and_Lifestyle_Options",
  BRAND: "nav_Brand",
  OTHER: "other",
  OFFER: "nav_Filter-Your-Results",
  PROMOTION: "promotion",
};

export interface SearchResultsData {
  term: string;
  controls?: Controls;
  refetch: () => void;
  autocorrect: string | null;
  isMultiSearch: boolean;
  isHFSSRestricted: boolean | undefined;
  categoryMatch: boolean | undefined;
  isSearchArgosReady: boolean;
}

export interface SearchResultFindabiltyInput {
  term: string;
  category?: string;
  sort?: string;
  pageNumber?: number;
  brand: string | null;
  dietary: string | null;
  other: string | null;
  offer: string | null;
  isSlotLocked: boolean;
}

export type UserDetails = UserState["userDetails"];

export type FetchResultsParams = SearchResultFindabiltyInput & {
  slot?: CachedSlotReservation;
  promotion?: string;
  salesWindowVariant: number;
};

export interface SearchResultsArgs extends ProductQueryParameters {
  isSearchCategoryMatchEnabled?: boolean;
  term: string;
  salesWindowVariant: number;
}

export enum SearchActionType {
  SEARCH_PRODUCTS_REQUEST = "SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAILURE = "SEARCH_PRODUCTS_FAILURE",
}

export type CreateSearchProductSuccessActionType = {
  type: SearchActionType.SEARCH_PRODUCTS_SUCCESS;
  products: SearchProducts;
};

export interface SearchProducts extends PaginatedProducts {
  suggestedTerm?: SuggestedSearchTerm[];
  errors?: ProductServiceError[];
}

export interface SearchStore {
  dataState: DataState;
}

export enum PageVariants {
  "BROWSE",
  "FINDABILITY",
  "MEAL_DEAL_BUILDER",
  "BRANDED",
  "LISTER_BUILDER",
  "EVENTS_BUILDER",
  "FEATURES",
  "FAVOURITES",
}
