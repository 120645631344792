import { DeliveryType } from "./BookDelivery.types";
import { SlotReservation, SlotReservationType } from "../../../domain/slot/slotReservation.types";

export const mapDeliveryTypeText = (slotReservation: SlotReservation): string => {
  if (slotReservation) {
    if (slotReservation.reservationType === SlotReservationType.DELIVERY) {
      return DeliveryType.DELIVERY;
    }
    if (slotReservation.reservationType === SlotReservationType.CLICK_AND_COLLECT) {
      return DeliveryType.COLLECT;
    }
  }
  return "";
};
