import React, { useEffect, useRef, useState } from "react";
import { FilledButton, IconButton, TextButton } from "@jsluna/react";
import { Plus, Minus } from "@jsluna/icons";
import { ProgressIndicator, ProgressSpinner } from "@jsluna/progress";
import clsx from "clsx";
import { formatQuantity, useBasketItem, useBasket } from "../../domain/basket/basket";
import { ClickSource, UnitOfMeasure } from "../../domain/basket/basket.types";
import { UpdateQuantityModal } from "../Modal/UpdateQuantityModal.component";
import { EmptyTrolleyModal } from "../Modal/NewModal/EmptyTrolleyModal.component";
import { selectNudgeValue } from "../../domain/basket/basket.selectors";
import { Product, ProductType } from "../../domain/product/product.types";
import { useTileContext } from "../TileContext";
import { FulfilmentState } from "../../common/dataLayer/types";
import { Button } from "@sainsburys-tech/fable";

export type BasketControlsProps = {
  sku: string;
  productName: string;
  productType?: ProductType;
  selectedUnit?: UnitOfMeasure;
  selectedCatchweight?: string;
  reverseButtonOrder?: boolean;
  isDisabled?: boolean;
  clickSource: ClickSource;
  product?: Product;
  CTAText?: string;
  carouselKey?: string;
  fulfilmentState?: FulfilmentState;
  handleFbtOnSearch?: (index: number, productId: string) => void;
  rowIndex?: number;
  buttonVariant?: "primary" | "secondary" | "tertiary";
};

export const BasketControls = ({
  sku,
  productName,
  productType,
  selectedUnit,
  selectedCatchweight,
  reverseButtonOrder,
  isDisabled,
  clickSource,
  product,
  CTAText,
  carouselKey,
  fulfilmentState,
  handleFbtOnSearch,
  rowIndex,
  buttonVariant,
}: BasketControlsProps) => {
  const { basket } = useBasket();
  const {
    quantity,
    unit,
    catchweight,
    increment,
    decrement,
    setQuantity,
    isLoading,
    isLoadingCatchweight,
    lastTileId,
  } = useBasketItem(sku);

  const [isUpdateQuantityModalOpen, setIsUpdateQuantityModalOpen] = useState(false);
  const [isEmptyTrolleyModalOpen, setIsEmptyTrolleyModalOpen] = useState(false);
  const [focus, setFocus] = useState("");
  const { uniqueId } = useTileContext();
  const refs = {
    addButton: useRef(null),
    removeButton: useRef(null),
    quantityButton: useRef(null),
  };

  useEffect(() => {
    const currentFocus = refs[focus]?.current;
    if (currentFocus && !isLoading && lastTileId === uniqueId) {
      currentFocus.focus();
    }
    // TO-DO: refactor using ref callback see https://stackoverflow.com/a/67906087
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, focus, lastTileId]);

  const relevantUnit = selectedUnit || unit;
  const relevantCatchweight = selectedCatchweight || catchweight;

  const quantityFormatted = formatQuantity(quantity, relevantUnit);
  const isQuantityAMediumString = quantityFormatted.length > 3;
  const isQuantityALongString = quantityFormatted.length > 6;

  const setQuantityCallback = (newQuantity: number) => {
    //checking if the quantity is decrease or increased and passing the flag to services
    //This is needed to ensure we skip the bulk limit check while decreasing quantity of any item.
    let decrement = false;
    if (newQuantity < quantity) {
      decrement = true;
    }
    setQuantity({
      newQuantity,
      unit: relevantUnit,
      catchweight: relevantCatchweight,
      clickSource,
      product,
      decrement,
      carouselKey,
      tileId: uniqueId,
      fulfilmentState,
    });
  };
  const minProductQuantity = selectNudgeValue(relevantUnit);
  const isLastTrolleyItem = basket.itemCount === 1 && quantity === minProductQuantity;

  const productText = product?.occasion
    ? `occasion ${productName},serves ${product?.occasion?.serves},takes ${product?.promise?.status.label}`
    : `${productName}`;
  const updateAriaLabelText = isDisabled
    ? `You cannot edit the quantity of, ${productText}, this item it is being prepared.`
    : `${quantityFormatted} ${productText} in trolley. Update quantity`;
  const removeAriaLabelText = isDisabled
    ? `You cannot remove ${productText}, this item it is being prepared`
    : `Remove ${productText} from trolley`;
  const addAriaLabelText = isDisabled
    ? `You cannot add, ${productText}, this item it is being prepared.`
    : `Add ${productText} to trolley`;

  const handleFetchFbtOnAdd = () => {
    if (typeof handleFbtOnSearch === "function") {
      if (product) handleFbtOnSearch(rowIndex!, product.productUid);
    }
  };

  const ButtonComponent = buttonVariant === "secondary" ? Button : FilledButton;
  const buttonProps = buttonVariant === "secondary" ? { isFullWidth: true, variant: "secondary" } : { fullWidth: true };

  if (isLoading) {
    return (
      <ProgressIndicator
        preventFocus
        className="ln-u-justify-content-center pt-button__loading pd-button__loading"
        data-testid="basket-item-loading"
        loading
      >
        <ProgressSpinner size="small" />
      </ProgressIndicator>
    );
  }
  if (quantity === 0) {
    return (
      <div className="pt-button">
        <ButtonComponent
          className="pt__add-button--reduced-height"
          data-testid="add-button"
          innerRef={refs.addButton}
          disabled={isDisabled}
          onClick={() => {
            increment({
              unit: relevantUnit,
              catchweight: relevantCatchweight,
              productType,
              clickSource,
              product,
              carouselKey,
              tileId: uniqueId,
              fulfilmentState,
            });
            setFocus("addButton");
            handleFetchFbtOnAdd();
          }}
          aria-label={`Add ${productText} to trolley`}
          {...buttonProps}
        >
          {CTAText ?? "Add"}
        </ButtonComponent>
      </div>
    );
  }

  const removeButton = (
    <IconButton
      className="pt-button__dec pt-button__dec--reduced-height"
      data-testid="pt-button-dec"
      variant="filled"
      fullWidth
      label="Remove"
      hideLabel
      innerRef={refs.removeButton}
      aria-label={removeAriaLabelText}
      disabled={isDisabled || isLoadingCatchweight}
      onClick={() => {
        isLastTrolleyItem
          ? setIsEmptyTrolleyModalOpen(true)
          : decrement({
              unit: relevantUnit,
              catchweight: relevantCatchweight,
              clickSource,
              product,
              tileId: uniqueId,
              fulfilmentState,
            });
        setFocus("removeButton");
      }}
    >
      <Minus aria-label="Minus" />
    </IconButton>
  );

  const addButton = (
    <IconButton
      className="pt-button__inc pt-button__inc--reduced-height"
      data-testid="pt-button-inc"
      variant="filled"
      fullWidth
      label="Add"
      hideLabel
      innerRef={refs.addButton}
      aria-label={addAriaLabelText}
      disabled={isDisabled || isLoadingCatchweight}
      onClick={() => {
        increment({
          unit: relevantUnit,
          catchweight: relevantCatchweight,
          clickSource,
          product,
          carouselKey,
          tileId: uniqueId,
          fulfilmentState,
        });
        setFocus("addButton");
      }}
    >
      <Plus aria-label="Plus" />
    </IconButton>
  );

  return (
    <>
      {reverseButtonOrder ? addButton : removeButton}
      <TextButton
        className={clsx("pt-button__quantity pt-button__quantity--reduced-height", {
          "pt-button__quantity--s": isQuantityAMediumString,
          "pt-button__quantity--xs": isQuantityALongString,
        })}
        data-testid="pt-button-quantity"
        variant="filled"
        label="Update quantity"
        aria-label={`${updateAriaLabelText}`}
        fullWidth
        innerRef={refs.quantityButton}
        disabled={isDisabled || isLoadingCatchweight}
        onClick={() => {
          setIsUpdateQuantityModalOpen(true);
          setFocus("quantityButton");
        }}
      >
        {quantityFormatted}
      </TextButton>
      {reverseButtonOrder ? removeButton : addButton}
      {(focus === "addButton" || focus === "removeButton") && (
        <span aria-live="polite" aria-label={`${quantityFormatted} ${productText} in trolley`} />
      )}
      {isUpdateQuantityModalOpen && (
        <UpdateQuantityModal
          quantity={quantity}
          unit={relevantUnit}
          setQuantity={setQuantityCallback}
          closeModal={() => setIsUpdateQuantityModalOpen(false)}
          productName={productName}
        />
      )}
      <EmptyTrolleyModal isOpen={isEmptyTrolleyModalOpen} setIsOpen={setIsEmptyTrolleyModalOpen} />
    </>
  );
};
