import React from "react";
import clsx from "clsx";
import { urls } from "../../../routes";
import { ArrowDown, ArrowUp } from "@jsluna/icons";
import { Link } from "@sainsburys-tech/fable";
import { GOLLink } from "../../../components/GOLLink";
import { useFeatureFlag } from "../../../common/firebase/featureFlags.hooks";

export interface DesktopGroceriesMenuLinkProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  selected?: boolean;
  megaNavFetchFailed?: boolean;
}

export const DesktopGroceriesMenuLink: React.FC<DesktopGroceriesMenuLinkProps> = ({
  onClick,
  selected,
  megaNavFetchFailed,
}) => {
  const newGlobalHeader = useFeatureFlag("new_global_header");
  return newGlobalHeader ? (
    <Link
      href={megaNavFetchFailed ? urls.BROWSE : "/shop/gb/groceries"}
      external={true}
      className={clsx("nav__menu-link", { "nav__menu-link--selected": selected })}
      onClick={onClick}
      data-testid="desktop-nav-item-link"
      aria-label="Groceries"
    >
      Groceries
      {selected ? (
        <ArrowUp className="nav__menu-chevron" alt="" title="Close groceries menu" />
      ) : (
        <ArrowDown className="nav__menu-chevron" alt="" title="Open groceries menu" />
      )}
    </Link>
  ) : (
    <GOLLink
      to={megaNavFetchFailed ? urls.BROWSE : "/shop/gb/groceries"}
      external={true}
      className={clsx("nav__menu-link", { "nav__menu-link--selected": selected })}
      onClick={onClick}
      data-testid="desktop-nav-item-link"
      aria-label="Groceries"
    >
      Groceries
      {selected ? (
        <ArrowUp className="nav__menu-chevron" alt="" title="Close groceries menu" />
      ) : (
        <ArrowDown className="nav__menu-chevron" alt="" title="Open groceries menu" />
      )}
    </GOLLink>
  );
};
