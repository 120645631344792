import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { routes } from "../../routes";
import { Header as ProgressiveRegistrationHeader } from "../../views/ProgressiveRegistration/Header";
import DefaultHeader from "./Header.component";
import { CheckoutHeader } from "../../views/Checkout/CheckoutHeader.component";
import { CheckoutHeader as DeliveryPassHeader } from "../../views/DeliveryPass/CheckoutHeader.component";
import RecipesBrowse from "../RecipesBrowse/RecipesBrowse.component";
import { HeaderWrapper } from "./HeaderWrapper/HeaderWrapper";
import { useHeaderActions } from "./headerState/header.actions";
import { useHeaderPortal } from "./HeaderPortal/hooks/useHeaderPortal";
import { useFeatureFlag } from "../../common/firebase/featureFlags.hooks";

enum SCROLL_DIRECTIONS {
  UP = "up",
  DOWN = "down",
}

const HeaderRouter = () => {
  const [scrollDirection, setScrollDirection] = useState<SCROLL_DIRECTIONS>(SCROLL_DIRECTIONS.UP);
  const { hideMinimizedHeader, showMinimizedHeader } = useHeaderActions();
  const { headingRef } = useHeaderPortal<HTMLDivElement>();
  const isBYGABTestEnabled = useFeatureFlag("byg_ab_test");

  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    if (!headingRef.current) {
      return;
    }

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const currentScrollDirection = scrollY > lastScrollY ? SCROLL_DIRECTIONS.DOWN : SCROLL_DIRECTIONS.UP;
      const headerComponent = headingRef.current;

      if (headerComponent && headerComponent.parentElement) {
        const headerHeight = headerComponent.parentElement.offsetHeight;

        if (currentScrollDirection !== scrollDirection) {
          setScrollDirection(currentScrollDirection);
        }

        if (scrollY > headerHeight - 10) {
          showMinimizedHeader();
        } else {
          hideMinimizedHeader();
        }
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateScrollDirection();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    updateScrollDirection();

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollDirection, headingRef, hideMinimizedHeader, showMinimizedHeader]);

  return (
    <Switch>
      <Route path={routes.OAUTH} render={() => <ProgressiveRegistrationHeader />} />
      <Route path={routes.LOGON_VIEW} render={() => <ProgressiveRegistrationHeader />} />
      <Route path={routes.CHECKOUT_LOGIN} render={() => <ProgressiveRegistrationHeader />} />
      <Route path={routes.OAUTH_REGISTRATION_CONFIRMATION} component={ProgressiveRegistrationHeader} />
      <Route path={routes.PROGRESSIVE_REGISTRATION} component={ProgressiveRegistrationHeader} />
      <Route path={routes.DELIVERY_PASS_RENEWAL_OPTIONS} component={ProgressiveRegistrationHeader} />
      <Route
        path={routes.CHECKOUT_BEFORE_YOU_GO}
        render={() =>
          isBYGABTestEnabled ? (
            <CheckoutHeader />
          ) : (
            <HeaderWrapper>
              <DefaultHeader />
            </HeaderWrapper>
          )
        }
      />
      <Route
        path={routes.CHECKOUT_FORGOTTEN_FAVOURITES}
        render={() =>
          isBYGABTestEnabled ? (
            <CheckoutHeader />
          ) : (
            <HeaderWrapper>
              <DefaultHeader />
            </HeaderWrapper>
          )
        }
      />
      <Route path={routes.CHECKOUT_SUMMARY} render={() => <CheckoutHeader />} />
      <Route path={routes.CHECKOUT_PAYMENT_AUTHORISATION} render={() => <CheckoutHeader />} />
      <Route path={routes.CHECKOUT_PAYMENT_REDIRECT} render={() => <CheckoutHeader />} />
      <Route
        path={routes.RECIPES_PAGES}
        render={() => (
          <HeaderWrapper>
            <DefaultHeader />
            <RecipesBrowse />
          </HeaderWrapper>
        )}
      />
      <Route path={routes.PROGRESSIVE_V2_REGISTRATION} render={() => <ProgressiveRegistrationHeader />} />
      <Route path={routes.DELIVERY_PASS_CHECKOUT_SUMMARY} render={() => <DeliveryPassHeader />} />
      <Route
        render={() => (
          <HeaderWrapper>
            <DefaultHeader />
          </HeaderWrapper>
        )}
      />
    </Switch>
  );
};

HeaderRouter.displayName = "HeaderRouter";

export const HeaderContainer = withRouter(HeaderRouter);
