import { ProductReview, ProductReviewRating } from "../../../domain/product/review";
import { ProductTileProduct } from "src/components/ProductTile/productTile.types";
import { Recipe } from "../../../views/Recipes/Recipe.types";

export enum ProductReviews {
  FETCH_PRODUCT_REVIEWS_PENDING = "FETCH_PRODUCT_REVIEWS_PENDING",
  FETCH_PRODUCT_REVIEWS_FAILED = "FETCH_PRODUCT_REVIEWS_FAILED",
  FETCH_PRODUCT_REVIEWS_SUCCESS = "FETCH_PRODUCT_REVIEWS_SUCCESS",
  REPORT_REVIEWS_PENDING = "REPORT_REVIEWS_PENDING",
  REPORT_REVIEWS_FAILED = "REPORT_REVIEWS_FAILED",
  REPORT_REVIEWS_SUCCESS = "REPORT_REVIEWS_SUCCESS",
  REPORT_REVIEWS_RESET = "REPORT_REVIEWS_RESET",
}

export type ProductReviewsFailedActionType = {
  type: ProductReviews.FETCH_PRODUCT_REVIEWS_FAILED;
};

export type ProductReviewsPendingActionType = {
  type: ProductReviews.FETCH_PRODUCT_REVIEWS_PENDING;
};

export type ProductReviewsSuccessActionType = {
  type: ProductReviews.FETCH_PRODUCT_REVIEWS_SUCCESS;
  totalReviews: number;
  reviews: ProductReview[];
};

export type ReportReviewsFailedActionType = {
  type: ProductReviews.REPORT_REVIEWS_FAILED;
};

export type ReportReviewsPendingActionType = {
  type: ProductReviews.REPORT_REVIEWS_PENDING;
};

export type ReportReviewsSuccessActionType = {
  type: ProductReviews.REPORT_REVIEWS_SUCCESS;
};

export type ReportReviewsResetActionType = {
  type: ProductReviews.REPORT_REVIEWS_RESET;
};

export interface ReviewTabProps {
  isLoading: boolean;
  totalReviews: number;
  pctRecommended?: number;
  averageRating: ProductReviewRating;
  productTileProduct?: ProductTileProduct;
  recipe?: Recipe;
  reviews: ProductReview[];
  onPageChange: (pageNumber: number, numPerPage: number, sendAnalytics: boolean) => void;
  onWriteReviewClick: () => void;
  onReviewHelpful?: (reviewId: string) => "NOT_IMPLEMENTED";
  onReviewNotHelpful?: (reviewId: string) => "NOT_IMPLEMENTED";
  onReportReview: (reviewId: string, message: string) => void;
  onReportReviewClosed: () => void;
  reportReviewFailed: boolean;
  reportReviewSuccess: boolean;
}
