import { AppThunkDispatch } from "../../../common/types";
import { State } from "../../../common/store";
import { DietaryProfileStatus } from "../../../domain/customer/customer";
import * as services from "../../../services/product";
import { ProductsViews } from "../../../domain/product/product.types";

export const fetchDietaryWarnings =
  (eans: string[]) =>
  async (dispatch: AppThunkDispatch, getState: () => State): Promise<void> => {
    if (eans.length === 0) {
      return;
    }

    const userDetails = getState().user.userDetails;
    if (userDetails && (!userDetails.isRegistered || userDetails.dietaryProfile === DietaryProfileStatus.NONE)) {
      return;
    }

    dispatch(fetchDietaryWarningsPending());
    dispatch(fetchDietaryWarningsFailed());

    /*
    // Initial deactivation of Food Maestro, logic left in function until future tool is put in place
    const result = await services.fetchDietaryWarnings(eans);

    if (result.isSuccess()) {
      dispatch(fetchDietaryWarningsSuccess(result.data.eans));
    } else {
      dispatch(fetchDietaryWarningsFailed());
    }
    */
  };

export function refetchSearchDietaryWarnings() {
  return async (dispatch: AppThunkDispatch, getState: () => State) => {
    const products = getState().products.data;
    const searchProductsUids = getState().products.views[ProductsViews.SEARCH_RESULTS];
    const productsEans = searchProductsUids.reduce((acc: string[], uid: string) => {
      const product = products.get(uid);
      return product ? [...acc, ...product.eans] : acc;
    }, [] as string[]);

    if (productsEans.length === 0) {
      return;
    }

    const userDetails = getState().user.userDetails;
    if (userDetails && (!userDetails.isRegistered || userDetails.dietaryProfile === DietaryProfileStatus.NONE)) {
      return;
    }

    const result = await services.fetchDietaryWarnings(productsEans);

    if (result.isSuccess()) {
      dispatch(refetchDietaryWarningsSuccess(result.data.eans));
    } else {
      dispatch(fetchDietaryWarningsFailed());
    }
  };
}

export enum FetchDietaryWarningsActionTypes {
  FETCH_DIETARY_WARNINGS_PENDING = "FETCH_DIETARY_WARNINGS_PENDING",
  FETCH_DIETARY_WARNINGS_SUCCESS = "FETCH_DIETARY_WARNINGS_SUCCESS",
  FETCH_DIETARY_WARNINGS_FAILED = "FETCH_DIETARY_WARNINGS_FAILED",
  RE_FETCH_DIETARY_WARNINGS_SUCCESS = "RE_FETCH_DIETARY_WARNINGS_SUCCESS",
}

export type FetchDietaryWarningsPendingActionType = {
  type: FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_PENDING;
};

export type FetchDietaryWarningsSuccessActionType = {
  type: FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_SUCCESS;
  eans: string[];
};

export type ReFetchDietaryWarningsSuccessActionType = {
  type: FetchDietaryWarningsActionTypes.RE_FETCH_DIETARY_WARNINGS_SUCCESS;
  eans: string[];
};

export type FetchDietaryWarningsFailedActionType = {
  type: FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_FAILED;
};

export const fetchDietaryWarningsPending = (): FetchDietaryWarningsPendingActionType => ({
  type: FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_PENDING,
});

export const fetchDietaryWarningsSuccess = (eans: string[]): FetchDietaryWarningsSuccessActionType => ({
  type: FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_SUCCESS,
  eans,
});

export const refetchDietaryWarningsSuccess = (eans: string[]): ReFetchDietaryWarningsSuccessActionType => ({
  type: FetchDietaryWarningsActionTypes.RE_FETCH_DIETARY_WARNINGS_SUCCESS,
  eans,
});

export const fetchDietaryWarningsFailed = (): FetchDietaryWarningsFailedActionType => ({
  type: FetchDietaryWarningsActionTypes.FETCH_DIETARY_WARNINGS_FAILED,
});
