import { Action, Dispatch, MiddlewareAPI } from "redux";
import { State } from "../store";
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { Location } from "history";

export const locationMiddleware = (api: MiddlewareAPI<Dispatch, State>) => (next: Dispatch) => (action: Action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const previousLocation = api.getState().router.location as Location<{ previousLocation?: Location }>;
      if (previousLocation.state && previousLocation.state.previousLocation) {
        // remove current url's previous location to stop nesting
        previousLocation.state.previousLocation = undefined;
      }

      // update action with previous location
      const locationChangeAction = action as LocationChangeAction<{ previousLocation?: Location }>;
      locationChangeAction.payload.location.state = locationChangeAction.payload.location.state || {};
      locationChangeAction.payload.location.state.previousLocation = previousLocation;
      break;
    default:
      break;
  }
  next(action);
};
