/**
 * Encodes query parameters in URLs.
 * @param params the query params to encode
 * @param withStartSymbol whether we should include '&' in the returned string
 * @example
 * encodeQueryParams({ id: "123", a: "456" }); // returns ?id=123&a=456
 * encodeQueryParams({ id: "123", a: "456" }, false); // returns id=123&a=456
 * encodeQueryParams({ id: ["123", "456"] }); // returns ?id=123&id=456
 * encodeQueryParams({ id: undefined, name: "jan" }); // returns ?name=jan
 */
export const encodeQueryParams = (params: { [key: string]: any }, withStartSymbol: boolean = true): string => {
  if (!params || !Object.keys(params).length) {
    return "";
  }

  const initialValue: string[] = [];
  const pairs = Object.keys(params).reduce((acc, key) => {
    const value = params[`${key}`];
    if (value === undefined) {
      return acc;
    }

    if (Array.isArray(value)) {
      value.forEach((v: any) => acc.push(`${key}=${encodeURIComponent(v)}`));
    } else {
      acc.push(`${key}=${encodeURIComponent(value)}`);
    }
    return acc;
  }, initialValue);

  if (pairs.length) {
    const startSymbol = withStartSymbol ? "?" : "";
    return `${startSymbol}${pairs.join("&")}`;
  }

  return "";
};

export function queryParamValue(queryString: string, queryParam: string): string | null {
  const searchParams = new URLSearchParams(queryString);
  return searchParams.get(queryParam);
}
