import { ExperimentVariantEvent } from "./types";
import { addExperimentEvent } from "./analyticsEvent";
import { EXPERIMENTS } from "../analytics/digitalData";

export const headerExperimentVariantEvent = (featureFlag: boolean): void => {
  const analyticsEvent: ExperimentVariantEvent = {
    name: EXPERIMENTS.Global_Header_Test_Group_1,
    variant: `${featureFlag ? 2 : 1}`,
  };

  addExperimentEvent(analyticsEvent);
};
