import { loginWithAccessToken } from "./login";
import { getAccessTokenFromStorage } from "../common/oAuth/oAuth.helpers";
import { baseIdentityUrl } from "../common/oAuth/oAuth.config";
import { jwtVerify, createRemoteJWKSet } from "jose";

export type fnValidateJWT = (token: string) => Promise<object | undefined>;

/**
 * Update WC Authentication cookies via Identity Workflow (call Login Service
 * with JWT to get a fresh WC session cookie). All the cookies should be
 * held by browser
 */
export const updateWcCookies = (): Promise<boolean> => {
  return updateCookies(validateJWT)
    .then(result => result)
    .catch(() => false);
};

export const updateCookies = async (validate: fnValidateJWT): Promise<boolean> => {
  const jwtToken = await getAccessTokenFromStorage();
  if (!jwtToken) {
    return false;
  }

  // validate signature and token
  return validate(jwtToken)
    .catch(() => Promise.reject(new Error("JwtToken is invalid")))
    .then(decoded => {
      if (!!decoded) {
        return loginWithAccessToken({
          access_token: jwtToken,
          food_profile_create: false,
        });
      }
      return Promise.reject(new Error("Payload is invalid"));
    })
    .then(response => {
      if (response.isSuccess()) {
        return Promise.resolve(response.data.wc_token !== "");
      }
      return Promise.resolve(false);
    });
};

// validates and decodes the JWT token. Returns decoded payload in case of success or undefined for error
export const validateJWT: fnValidateJWT = async (token: string): Promise<object | undefined> => {
  const JWKS = createRemoteJWKSet(new URL(`${baseIdentityUrl}/.well-known/jwks.json`));

  try {
    const { payload } = await jwtVerify(token, JWKS);

    return payload;
  } catch (err) {
    return undefined;
  }
};
