import moment from "moment-timezone";
import { SingleSlot } from "../../components/BookSlot/Slot.component";
import { QualifyingBasketAmount, UnqualifiedSlotPrice } from "./slotReservation.types";
import GolTime from "../time";

export enum DeliveryPassStatus {
  NOT_ELIGIBLE_FOR_DAY_OF_WEEK = "NOT_ELIGIBLE_FOR_DAY_OF_WEEK",
  NOT_ELIGIBLE_ONCE_PER_DAY = "NOT_ELIGIBLE_ONCE_PER_DAY",
  ELIGIBLE = "ELIGIBLE",
  NONE = "NONE",
}

export const slotPricingMessaging = (
  basketSubTotal: number,
  isDeliveryPassApplicable: boolean,
  deliveryPassDays: string[],
  slotPrice: string,
  slotDateTime: string,
  isDeliveryPassAutoRenewalEnabled: boolean,
  deliveryPassExpiryDate?: string
): string => {
  if (!slotDateTime) {
    return "";
  }

  const deliveryPassStatus = mapDeliveryPassStatus(
    deliveryPassDays,
    slotDateTime,
    isDeliveryPassApplicable,
    isDeliveryPassAutoRenewalEnabled,
    deliveryPassExpiryDate
  );

  if (deliveryPassStatus === DeliveryPassStatus.NONE) {
    if (basketSubTotal < 40) {
      return `*Spend £${QualifyingBasketAmount.DELIVERY} or more to get your delivery for ${slotPrice}. <br>${unqualifiedPricingInfo}`;
    }
    return `*As your order is over £${QualifyingBasketAmount.DELIVERY}, your delivery charge will be ${slotPrice}. <br>${unqualifiedPricingInfo}`;
  }

  if (deliveryPassStatus === DeliveryPassStatus.NOT_ELIGIBLE_FOR_DAY_OF_WEEK) {
    if (basketSubTotal < 40) {
      return `*${ineligibleDeliveryPassMidweekMessage} <br>Spend £${QualifyingBasketAmount.DELIVERY} or more to get your delivery for ${slotPrice}. ${unqualifiedPricingInfo}`;
    }
    return `*${ineligibleDeliveryPassMidweekMessage} <br>As your order is over £${QualifyingBasketAmount.DELIVERY}, your delivery will be ${slotPrice}. ${unqualifiedPricingInfo}`;
  }

  if (deliveryPassStatus === DeliveryPassStatus.ELIGIBLE) {
    if (basketSubTotal < 40) {
      return `*Spend £${QualifyingBasketAmount.DELIVERY} or more to get your delivery for free. <br>${unqualifiedPricingInfo}`;
    }
    return `*As your order is over £${QualifyingBasketAmount.DELIVERY}, your delivery will be free. <br>${unqualifiedPricingInfo}`;
  }

  if (deliveryPassStatus === DeliveryPassStatus.NOT_ELIGIBLE_ONCE_PER_DAY) {
    if (basketSubTotal < 40) {
      return `*${ineligibleDeliveryPassOnceDayMessage} <br>Spend £${QualifyingBasketAmount.DELIVERY} or more to get your delivery for ${slotPrice}. ${unqualifiedPricingInfo}`;
    }
    return `*${ineligibleDeliveryPassOnceDayMessage} <br>As your order is over £${QualifyingBasketAmount.DELIVERY}, your delivery will be ${slotPrice}. ${unqualifiedPricingInfo}`;
  }

  return "";
};

export function mapDeliveryPassStatus(
  deliveryPassDays: string[],
  slotDateTime: string,
  isDeliveryPassApplicable: boolean,
  isDeliveryPassAutoRenewalEnabled: boolean,
  deliveryPassExpiryDate?: string
): DeliveryPassStatus {
  if (!deliveryPassDays || deliveryPassDays.length === 0) {
    return DeliveryPassStatus.NONE;
  }

  const deliveryPassIsExpired = deliveryPassExpiryDate && new Date(deliveryPassExpiryDate) < new Date(slotDateTime);
  if (deliveryPassIsExpired && !isDeliveryPassAutoRenewalEnabled) {
    return DeliveryPassStatus.NONE;
  }

  if (isDeliveryPassEligibleForDayOfWeek(slotDateTime, deliveryPassDays)) {
    if (isDeliveryPassApplicable) {
      return DeliveryPassStatus.ELIGIBLE;
    }
    return DeliveryPassStatus.NOT_ELIGIBLE_ONCE_PER_DAY;
  }

  return DeliveryPassStatus.NOT_ELIGIBLE_FOR_DAY_OF_WEEK;
}

function isDeliveryPassEligibleForDayOfWeek(slotStartTime: string, deliveryPassDays: string[]): boolean {
  const dayOfWeek = new GolTime(slotStartTime).getDayOfWeek();
  return deliveryPassDays.some(day => day.toUpperCase() === dayOfWeek.toUpperCase());
}

const unqualifiedPricingInfo = `Orders below £${QualifyingBasketAmount.DELIVERY} will be charged £${UnqualifiedSlotPrice.DELIVERY} for delivery.`;

const ineligibleDeliveryPassMidweekMessage = `This order is not eligible for free delivery as your Delivery Pass Midweek can only be used on Tuesdays, Wednesdays and Thursdays.`;

const ineligibleDeliveryPassOnceDayMessage = `This order is not eligible for free delivery as your Delivery Pass can only be used for one free delivery per day.`;

export const formatSlotTimesForAccessibilityLabels = (slot: SingleSlot): string[] => {
  const timezone = "Europe/London";
  const timeFormat = "h mm A";
  const dateFormat = "dddd Do MMMM";
  const formattedDate = moment(slot.startTime).tz(timezone).format(dateFormat);
  const formattedStartTime = moment(slot.startTime).tz(timezone).format(timeFormat);
  const formattedEndTime = moment(slot.endTime).tz(timezone).format(timeFormat);

  return [formattedDate, formattedStartTime, formattedEndTime];
};

export const formatSlotTimesDataTestId = (slot: SingleSlot): string => {
  const timezone = "Europe/London";
  const timeFormat = "k:mm";
  const formattedStartTime = moment(slot.startTime).tz(timezone).format(timeFormat);
  const morning = ["6:30", "7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00"];
  const afternoon = ["12:30", "13:00", "13:30", "14:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00"];
  const evening = ["18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"];

  if (morning.indexOf(formattedStartTime) > -1) {
    return "morning";
  }
  if (afternoon.indexOf(formattedStartTime) > -1) {
    return "afternoon";
  }
  if (evening.indexOf(formattedStartTime) > -1) {
    return "evening";
  }
  return "";
};
