import { WithDataState } from "../../common/dataState";

export enum RecipeSearchResultsType {
  RECIPE_SEARCH_REQUEST = "RECIPE_SEARCH_REQUEST",
  RECIPE_SEARCH_SUCCESS = "RECIPE_SEARCH_SUCCESS",
  ARTICLE_SEARCH_SUCCESS = "ARTICLE_SEARCH_SUCCESS",
  SCRAPBOOK_SEARCH_SUCCESS = "SCRAPBOOK_SEARCH_SUCCESS",
  RECIPE_SEARCH_FAILURE = "RECIPE_SEARCH_FAILURE",
  RESET_SEARCH_RESULTS = "RESET_SEARCH_RESULTS",
  RECIPE_FILTER_UPDATE = "RECIPE_FILTER_UPDATE",
}

export enum SearchPageType {
  SEARCH_PAGE_UPDATE = "SEARCH_PAGE_UPDATE",
  SEARCH_PAGE_RESET = "SEARCH_PAGE_RESET",
}

export interface CreateRecipeSearchSuccessActionType {
  type: RecipeSearchResultsType;
  searchResultItems: SearchResults;
}

export interface CreateRecipeSearchFailureActionType {
  type: RecipeSearchResultsType;
  message: string;
}

export interface RecipeSearchState extends WithDataState {
  recipeSearchTabReducer: ResultsTabs;
  recipeSearchReducer?: { recipeSearch: SearchResults; articleSearch: SearchResults; scrapbookSearch: SearchResults };
  searchPageReducer?: { currentPage?: number; totalPages?: number };
}

export interface RecipesSearchResultItem {
  id: string;
  name: string;
  image: string;
  labels?: string[];
  scrapbookRecipesCount?: number;
}

export interface RecipeSearchParams {
  searchTerm: string;
  searchkey?: string;
}

export interface FilterValue {
  id: string;
  value: string;
  selected: boolean;
  enabled: boolean;
}
export interface RecipeSearchFiltersByCategory {
  key: string;
  label: string;
  values: FilterValue[];
}
export interface SearchResults {
  totalResultsCount: number;
  searchResults: RecipesSearchResultItem[];
  filters?: RecipeSearchFiltersByCategory[];
}

export enum ResultsTabs {
  RESULTS_RECIPES = "Recipes",
  RESULTS_ARTICLES = "Articles",
  RESULTS_SCRAPBOOKS = "Scrapbooks",
}

export enum ContentType {
  RECIPE = "recipe",
  ARTICLE = "article",
  SCRAPBOOK = "scrapbooks",
}

export enum RecipeSearchTabSelectedActionType {
  RECIPE_SEARCH_RECIPE_TAB = "RECIPE_SEARCH_RECIPE_TAB",
  RECIPE_SEARCH_ARTICLE_TAB = "RECIPE_SEARCH_ARTICLE_TAB",
  RECIPE_SEARCH_SCRAPBOOK_TAB = "RECIPE_SEARCH_SCRAPBOOK_TAB",
}

export interface RecipeSearchTabSelectedAction {
  type: RecipeSearchTabSelectedActionType;
}
