import { Action } from "redux";
import { FormsState } from "../store";
import {
  FormActionTypes,
  FormCreateActionType,
  FormChangeActionType,
  FormErrorActionType,
  FormWarningActionType,
  FormResetActionType,
  FormFieldTouchActionType,
} from "./formsActions";

// TODO type the createFormState so that it carries info about the form keys
// possibly involves index types: http://www.typescriptlang.org/docs/handbook/advanced-types.html#index-types

interface InitialStateType {
  [key: string]: {
    values: {};
    pristine: {};
    fieldErrors: {};
    fieldWarnings: {};
    formErrors: string[];
  };
}

export const initialState: InitialStateType | {} = {};

type formsReducerType = (
  state: InitialStateType | FormsState,
  action: FormCreateActionType | FormChangeActionType | FormErrorActionType | Action
) => InitialStateType | FormsState;

export const formsReducer: formsReducerType = (state = initialState, action) => {
  switch (action.type) {
    case FormActionTypes.FORM_CREATE: {
      return {
        ...state,
        [(action as FormCreateActionType).form]: (action as FormCreateActionType).state,
      };
    }

    case FormActionTypes.FORM_CHANGE: {
      const { form, name, value } = action as FormChangeActionType;
      return {
        ...state,
        [form]: {
          ...state[`${form}`],
          values: {
            ...state[`${form}`].values,
            [name]: value,
          },
          pristine: {
            ...state[`${form}`].pristine,
            [name]: false,
          },
          fieldErrors: {
            ...state[`${form}`].fieldErrors,
            [name]: "",
          },
          fieldWarnings: {
            ...state[`${form}`].fieldWarnings,
            [name]: "",
          },
        },
      };
    }

    case FormActionTypes.FORM_ERROR: {
      const { form, name, error, pristine } = action as FormErrorActionType;
      let changeSet: { fieldErrors: object; pristine?: object } | { formErrors: string[] };
      if (name) {
        changeSet = {
          fieldErrors: {
            ...state[`${form}`].fieldErrors,
            [name]: error,
          },
        };
        if (typeof pristine === "boolean") {
          changeSet.pristine = {
            ...state[`${form}`].pristine,
            [name]: pristine,
          };
        }
      } else {
        changeSet = {
          formErrors: [error],
        };
      }
      return {
        ...state,
        [form]: {
          ...state[`${form}`],
          ...changeSet,
        },
      };
    }

    case FormActionTypes.FORM_WARNING: {
      const { form, name, warning } = action as FormWarningActionType;
      const changeSet = name
        ? {
            fieldWarnings: {
              ...state[`${form}`].fieldWarnings,
              [name]: warning,
            },
          }
        : {
            formWarning: [warning],
          };
      return {
        ...state,
        [form]: {
          ...state[`${form}`],
          ...changeSet,
        },
      };
    }

    case FormActionTypes.FORM_RESET: {
      const { form } = action as FormResetActionType;
      return {
        ...state,
        [form]: initialState[`${form}`],
      };
    }

    case FormActionTypes.FORM_RESET_ERRORS: {
      const { form } = action as FormResetActionType;
      return {
        ...state,
        [form]: {
          ...state[`${form}`],
          fieldErrors: Object.keys(state[`${form}`].fieldErrors).reduce((returnValue, currentKey) => {
            returnValue[`${currentKey}`] = "";
            return returnValue;
          }, {}),
          formErrors: [],
        },
      };
    }

    case FormActionTypes.FORM_RESET_WARNINGS: {
      const { form } = action as FormResetActionType;
      return {
        ...state,
        [form]: {
          ...state[`${form}`],
          fieldWarnings: Object.keys(state[`${form}`].fieldWarnings).reduce((returnValue, currentKey) => {
            returnValue[`${currentKey}`] = "";
            return returnValue;
          }, {}),
        },
      };
    }

    case FormActionTypes.FIELD_TOUCH: {
      const { form, names } = action as FormFieldTouchActionType;
      return {
        ...state,
        [form]: {
          ...state[`${form}`],
          pristine: {
            ...state[`${form}`].pristine,
            ...names.reduce((acc: object, name: string) => {
              acc[`${name}`] = false;
              return acc;
            }, {}),
          },
        },
      };
    }

    default:
      return state;
  }
};
