import { AnyAction } from "redux";
import { AdMetaData, AdsActionType, AdsStore, AdsTrackingDataAction } from "./ads.types";
import { updateMap } from "../../domain/product/product";

export const adsInitialState = {
  products: new Map(),
};

export const adsReducer = (state: AdsStore = adsInitialState, action: AnyAction) => {
  switch (action.type) {
    case AdsActionType.ADS_TRACKING_DATA:
      const data: AdMetaData[] = (action as AdsTrackingDataAction).data;

      return {
        products: updateMap(state.products, data),
      };
    default:
      return state;
  }
};

export const initialState: AdsStore = adsInitialState;
