import React from "react";
import { SainsburysBlack } from "@sainsburys-tech/images"; // todo replace with correct img
import { Logo } from "./Logo";

export const DeliveryPassLogo = ({ className }: { className?: string }) => {
  const classNames = ["delivery-pass-logo"];
  if (className) {
    classNames.push(className);
  }
  return (
    <Logo className={classNames.join(" ")}>
      <SainsburysBlack className="logo-image" />
    </Logo>
  );
};

DeliveryPassLogo.displayName = "DeliveryPassLogo";
