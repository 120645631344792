import { Action } from "redux";
import { UserState } from "../../common/store";
import { DataState } from "../../common/dataState";
import { FetchUserAction, UserActionTypes } from "../Authorized";
import { ResponseErrorActionType } from "../../common/types";

const initialState = { dataState: DataState.UNKNOWN };

export function userReducer(state: UserState | undefined = initialState, action: FetchUserAction | Action): UserState {
  switch (action.type) {
    case UserActionTypes.FETCH_USER_SUCCESS:
      const fetchUserAction = action as FetchUserAction;
      return { ...state, userDetails: { ...fetchUserAction.user }, dataState: DataState.SUCCESS } as UserState;
    case UserActionTypes.FETCH_USER_PENDING:
      return {
        ...state,
        dataState: DataState.PENDING,
      } as UserState;
    case UserActionTypes.FETCH_USER_FAILURE:
    case ResponseErrorActionType.HTTP_UNAUTHORIZED_ERROR:
      return {
        dataState: DataState.FAILED,
      } as UserState;
    default:
      return state;
  }
}
