import GolTime from "../time";
import * as momentTimeZone from "moment-timezone";
import {
  ClickAndCollectLocation,
  Order as ServicesOrder,
  OrderControls as ServicesOrderControls,
  OrderDeliveryAddress as ServicesOrderDeliveryAddress,
  OrderItem as ServicesOrderItem,
  OrderItemProduct as ServicesOrderItemProduct,
  PaginatedOrders as ServicesPaginatedOrders,
  Payment as ServicesPayment,
} from "../../services/order.types";
import {
  CollectionAddress,
  Order,
  OrderControls,
  OrderDeliveryAddress,
  OrderDetails,
  OrderItem,
  OrderItemProduct,
  PaginatedOrders,
  Payment,
} from "./order.types";

const mapDeliveryAddress = (deliveryAddress: ServicesOrderDeliveryAddress): OrderDeliveryAddress => {
  return {
    nickname: deliveryAddress.nickname,
    addressId: deliveryAddress.address_uid,
    postcode: deliveryAddress.postcode,
  };
};

const mapCollectionAddress = (collectionLocation: ClickAndCollectLocation): CollectionAddress => {
  return {
    name: collectionLocation.name,
    postcode: collectionLocation.postcode,
  };
};

const mapOrderItemProduct = (product: ServicesOrderItemProduct): OrderItemProduct => {
  return {
    name: product.name,
    imageUrl: product.image_url,
    productUrl: product.product_url,
    productUid: product.product_uid,
  };
};

const mapOrderItem = (orderItem: ServicesOrderItem): OrderItem => {
  return {
    quantity: orderItem.quantity,
    subTotal: orderItem.sub_total,
    product: mapOrderItemProduct(orderItem.product),
    allowSubstitutions: orderItem.allow_substitutions,
  };
};

const mapOrderItems = (orderItems: ServicesOrderItem[]): OrderItem[] => orderItems.map(mapOrderItem);

const mapPayment = (payment: ServicesPayment): Payment => {
  return {
    card_uid: payment.card_uid,
    cardNetwork: payment.card_network,
    maskedCardNumber: payment.masked_card_number,
  };
};

export const mapOrder = (order: ServicesOrder, orderDetails: boolean = false): Order | OrderDetails => {
  const mappedOrder = {
    orderUid: order.order_uid,
    receiptUrl: order.receipt_url,
    total: order.total,
    slotStartTime: order.slot_start_time,
    slotEndTime: order.slot_end_time,
    orderType: order.order_type,
    status: order.status,
    isCutOff: order.is_cutoff,
    isCancellable: order.is_cancellable,
    slotVanStatus: order.slot_van_status,
    storeIdentifier: order.store_identifier,
    deliveryAddress: order.delivery_address && mapDeliveryAddress(order.delivery_address),
    collectionAddress: order.click_and_collect_location && mapCollectionAddress(order.click_and_collect_location),
    cutOffTime:
      order.cutoff_time && momentTimeZone.tz(order.cutoff_time, "Europe/London").format(GolTime.servicesFormat),
  };
  return orderDetails
    ? {
        ...mappedOrder,
        subTotal: order.sub_total,
        deliveryCost: order.slot_price,
        vouchersSavings: order.vouchers_savings,
        orderItems: order.order_items ? mapOrderItems(order.order_items) : [],
        payment: order.payment ? mapPayment(order.payment) : undefined,
      }
    : mappedOrder;
};

export const mapOrders = (orders: ServicesOrder[]): Order[] => orders.map(order => mapOrder(order));

export const mapPaginatedOrders = (servicesOrders: ServicesPaginatedOrders): PaginatedOrders => {
  return {
    orders: mapOrders(servicesOrders.orders),
    controls: mapControls(servicesOrders.controls),
  };
};

export const mapControls = (controls: ServicesOrderControls): OrderControls => {
  if (!controls) {
    return {
      pageSizeOptions: [],
      pageSizeActive: 10,
      pageLast: 10,
      pageNumber: 1,
      recordCount: 100,
    };
  }
  return {
    pageSizeOptions: controls.page.size_options,
    pageSizeActive: controls.page.size,
    pageLast: controls.page.last,
    pageNumber: controls.page.active,
    recordCount: controls.total_record_count,
  };
};
