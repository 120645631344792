import { RefObject, createRef, useMemo } from "react";
import { useHeaderState } from "../headerState/useHeaderState";

const headerRef = createRef<Element>();

/**
 * Use this Hook to get realtime visible header height at page.
 * This returns the height of `header-wrapper`
 */
export function useHeaderHeight<T extends HTMLElement>() {
  const { isHeaderVisible } = useHeaderState();

  const headerHeight: number = useMemo(
    () => (isHeaderVisible && headerRef.current ? headerRef.current.clientHeight : 0),
    [isHeaderVisible]
  );

  return useMemo(() => ({ headerRef: headerRef as RefObject<T>, headerHeight }), [headerHeight]);
}
