import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { PreviousOrdersAction, PreviousOrdersActionTypes, SelectPreviousOrderAction } from "./previousOrders.types";
import { extractQueryParams, mergeQueryParams } from "../../components/ProductControls/productControls";
import { fetchProductsByOrder } from "./previousOrders.actions";
import { queryParamValue } from "../../common/http/query";
import first from "lodash.first";
import { ProductControlsActionTypes } from "../../components/ProductControls/productControls.types";
import { createInternalNavigationAction } from "../../common/middleware/navigationMiddleware";
import { matchesPath, routes } from "../../routes";
import { resetProductControlsActionCreator } from "../../components/ProductControls/productControls.actions";
import { FetchOrdersSuccessAction, Order, OrdersAction } from "../../domain/order/order.types";

export const previousOrdersMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) => (next: Dispatch<any>) => async (action: PreviousOrdersActionTypes) => {
    const {
      router: { location },
      orders,
    } = api.getState();

    const queryParams = extractQueryParams(location.search);

    switch (action.type) {
      case OrdersAction.FETCH_ORDERS_SUCCESS: {
        if (!matchesPath(location.pathname, routes.PREVIOUS_ORDERS)) {
          break;
        }

        const previousOrders = (action as FetchOrdersSuccessAction).paginatedOrders.orders;

        if (!previousOrders.length) {
          break;
        }

        api.dispatch(
          fetchProductsByOrder(
            queryParams,
            getOrderId(previousOrders, api.getState()),
            api.getState().basket.basketDetails
          )
        );

        break;
      }

      case PreviousOrdersAction.SELECT_PREVIOUS_ORDER: {
        if (!matchesPath(location.pathname, routes.PREVIOUS_ORDERS)) {
          break;
        }

        const { orderUid } = action as SelectPreviousOrderAction;
        const searchString = mergeQueryParams(location.search, { pageNumber: 1 }, { order: orderUid });

        api.dispatch(createInternalNavigationAction(`${location.pathname}?${searchString}`));
        api.dispatch(resetProductControlsActionCreator() as any);

        break;
      }

      case ProductControlsActionTypes.PRODUCT_CONTROLS_UPDATE: {
        if (!matchesPath(location.pathname, routes.PREVIOUS_ORDERS)) {
          break;
        }

        api.dispatch(
          fetchProductsByOrder(
            queryParams,
            getOrderId(orders.data.orders, api.getState()),
            api.getState().basket.basketDetails
          )
        );

        break;
      }

      default:
        break;
    }

    next(action);
  };

/**
 * returns the order id defined in the URL if present, otherwise returns the most recent
 * order from the store
 */
function getOrderId(previousOrders: Order[], state: State): string {
  const orderIdParam = queryParamValue(state.router.location.search, "order");
  if (!orderIdParam) {
    return first(previousOrders)!.orderUid;
  }

  const orderExists = previousOrders.some(order => order.orderUid === orderIdParam);
  if (orderExists) {
    return orderIdParam;
  }
  return first(previousOrders)!.orderUid;
}
