import React, { useEffect, useRef, useState } from "react";
import { Label2, Body1 } from "@sainsburys-tech/fable";
import { ArrowDown, ArrowUp } from "@sainsburys-tech/icons";
import { useLocation } from "react-router";
import { featureFlags } from "../../../common/firebase";
import { useSelector } from "react-redux";
import { State } from "../../../common/store";
import { KeyboardEventKey } from "../../../common/types";
import clsx from "clsx";
import { useHeaderState } from "../headerState/useHeaderState";

const OccasionsMenu = () => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
  const [openCategory, setOpenCategory] = useState<string | undefined>(undefined);
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const navRef = useRef<HTMLDivElement | null>(null);

  const showHeader = useSelector((state: State) => state.header.visible);
  const { pathname } = useLocation();
  const { showMinimised } = useHeaderState();
  const links = featureFlags.get("occasions_navigation");

  const handleKeyDown = (event: React.KeyboardEvent<Element>, id: string, index: number) => {
    if (event.key === KeyboardEventKey.ENTER || event.key === KeyboardEventKey.SPACE) {
      event.preventDefault();
      if (openCategory === id) {
        setIsSubmenuOpen(false);
        setOpenCategory(undefined);
      } else {
        setIsSubmenuOpen(true);
        setOpenCategory(id);
      }
      buttonRefs.current[index]?.focus();
    }

    handleEscKeyDown(event, index);
  };

  const handleEscKeyDown = (event: KeyboardEvent | React.KeyboardEvent<Element>, index?: number) => {
    if (event.key === KeyboardEventKey.ESCAPE) {
      event.preventDefault();
      setIsSubmenuOpen(false);
      setOpenCategory(undefined);
      (index || index === 0) && buttonRefs.current[index]?.focus();
    }
  };

  const handleClick = (id: string, index: number) => {
    if (openCategory === id) {
      setIsSubmenuOpen(false);
      setOpenCategory(undefined);
      buttonRefs.current[index]?.blur();
    } else {
      setIsSubmenuOpen(true);
      setOpenCategory(id);
      buttonRefs.current[index]?.focus();
    }
  };

  const handleClickAway = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setIsSubmenuOpen(false);
      setOpenCategory(undefined);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscKeyDown);
    document.addEventListener("mousedown", handleClickAway);
    return () => {
      document.removeEventListener("keydown", handleEscKeyDown);
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, []);

  useEffect(() => {
    if (!showHeader) {
      setIsSubmenuOpen(false);
      setOpenCategory(undefined);
    }
  }, [showHeader]);

  const isActive = (id: string): boolean => isSubmenuOpen && openCategory === id;
  const isCurrentPath = (path: string): boolean => pathname.includes(path);

  return (
    <nav
      data-testid="occasions-menu"
      aria-label="occasions-menu"
      className={`occasions-menu${showMinimised ? " minimized" : ""}`}
      ref={navRef}
    >
      <ul id="occasions-menu" data-testid="occasions-menu-category-items" className="parent-links">
        {links.categories.map((category, index) => (
          <li key={category.id} className={"parent-link"}>
            <button
              ref={el => (buttonRefs.current[index] = el)}
              data-testid={`occasions-menu-category-${category.id}`}
              onClick={() => handleClick(category.id, index)}
              onKeyDown={(e: React.KeyboardEvent<Element>) => handleKeyDown(e, category.id, index)}
              aria-expanded={isActive(category.id)}
              className="parent-link__button"
            >
              <Label2
                icon={isActive(category.id) ? <ArrowUp /> : <ArrowDown />}
                className={clsx({ active: isActive(category.id) })}
                colour="monochrome-dark"
              >
                {category.label}
              </Label2>
            </button>
            {isActive(category.id) && (
              <ul
                data-testid="occasions-menu-list-items"
                className={clsx("child-links-container", {
                  active: isActive(category.id),
                  inactive: !isActive(category.id),
                })}
              >
                <div
                  className={clsx("child-links", { active: isActive(category.id), inactive: !isActive(category.id) })}
                >
                  {links.categories
                    .find(category => category.id === openCategory)
                    ?.links.map(item => (
                      <li
                        key={item.link}
                        className={clsx("child-link")}
                        data-testid={`occasions-menu-item-${item.label.replaceAll(" ", "-").toLowerCase()}`}
                      >
                        <Body1
                          as="a"
                          href={item.link}
                          colour="monochrome-dark"
                          onKeyDown={(e: KeyboardEvent) => handleEscKeyDown(e, index)}
                          className={clsx("child-link__button", { active: isCurrentPath(item.link) })}
                        >
                          {item.label}
                        </Body1>
                      </li>
                    ))}
                </div>
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default OccasionsMenu;
