import { Method } from "../common/http/request";
import { serviceRequest } from "../services/request";
import { MeganavMagnoliaResponse } from "./megaNav.types";

interface MegaNavRequestProps {
  template_id: string;
  store?: string;
  delivery_date?: string;
  region?: string;
  contentId: string;
  has_nectar_associated?: boolean;
  is_digital_customer?: boolean;
  has_delivery_pass?: boolean;
  is_very_important_customer?: boolean;
  espotIds?: string[];
  category_ids?: string[];
  findabilityID?: string;
  placement?: string;
  slotId?: string;
  maxNumberOfAds?: number;
}

export async function fetchMeganavContent({
  template_id,
  store,
  delivery_date,
  region,
  contentId,
  has_nectar_associated,
  is_digital_customer,
  has_delivery_pass,
  is_very_important_customer,
  findabilityID,
  placement,
  slotId,
  maxNumberOfAds,
}: MegaNavRequestProps) {
  const CONTENT_URL = "/groceries-api/gol-services/content/v2/withMagnoliaTemplate/ads";
  const requestBody: MegaNavRequestProps = {
    template_id,
    store,
    delivery_date,
    region,
    contentId,
    has_nectar_associated,
    is_digital_customer,
    has_delivery_pass,
    is_very_important_customer,
    category_ids: findabilityID ? findabilityID.split(",") : [],
    placement,
    slotId,
    maxNumberOfAds,
    espotIds: ["Meganav_top", "Meganav_middle", "Meganav_bottom"],
  };
  return serviceRequest<MeganavMagnoliaResponse>({ method: Method.POST, url: CONTENT_URL, body: requestBody });
}
