import { getOrderBeingAmended } from "./amendOrderBanner";
import { Dispatch, Action } from "redux";
import { createCloseModalAction, createOpenModalAction } from "../Modal/modal.actions";
import { AmendOrderModalTypes, AmendOrderBannerActionTypes, SetCutOffExpiredAction } from "./AmendOrderBanner.types";
import { ModalNames } from "../Modal/Modal.types";
import { AppThunkAction } from "../../common/types";
import { OrderStatus } from "../../services/order.types";

export type createSlotModalActionType = () => AppThunkAction;

export const openSlotExpiryModalActionCreator: createSlotModalActionType = () => (dispatch: Dispatch) => {
  dispatch(
    createOpenModalAction({
      name: AmendOrderModalTypes.SLOT_EXPIRED_MODAL,
    })
  );
};

export const openCutOffModalActionCreator: createSlotModalActionType = () => (dispatch: Dispatch) => {
  dispatch(
    createOpenModalAction({
      name: AmendOrderModalTypes.SLOT_CUT_OFF_MODAL,
    })
  );
};

export const openDiscardChangesModalActionCreator = () => (dispatch: Dispatch) => {
  dispatch(
    createOpenModalAction({
      name: ModalNames.MODAL_AMEND_DISCARD_CHANGES,
    })
  );
};

export const closeAmendOrderModalActionCreator: createSlotModalActionType = () => (dispatch: Dispatch) => {
  dispatch(createCloseModalAction());
};

export const setCutOffExpiredActionCreator = (): SetCutOffExpiredAction => ({
  type: AmendOrderBannerActionTypes.SET_CUT_OFF_EXPIRED,
});

export const setCutOffExpired =
  (orderStatus: OrderStatus | undefined, isInAmendMode: boolean) => (dispatch: Dispatch<Action>) => {
    const orderBeingAmended = getOrderBeingAmended(orderStatus, isInAmendMode);

    if (orderBeingAmended) {
      dispatch(setCutOffExpiredActionCreator());
    }
  };
