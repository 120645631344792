import React from "react";
import { FilledButton } from "@jsluna/react";
import { Modal } from "@sainsburys-tech/fable";
import { BasicModalProps } from "./Modal.types";
import { TextButton } from "../TextButton";

export const BasicModal = ({
  title,
  description,
  onClose,
  onPrimaryButtonClick,
  primaryButtonText,
  onSecondaryButtonClick,
  secondaryButtonText,
}: BasicModalProps) => (
  <Modal
    isOpen={true}
    data-testid="basic-modal"
    onClose={onClose}
    layout="reverse"
    size="sm"
    header={{
      label: "",
      supportingText: "",
      isDismissible: true,
      icon: "",
    }}
  >
    <div className="basicModal">
      <h3 data-testid="basic-modal-title" className="basicModal__title ln-u-text-align-left">
        {title}
      </h3>
      <p className="basicModal__description ln-u-text-align-left" data-testid="basic-modal-description">
        {description}
      </p>
      <FilledButton
        data-testid="basic-modal-button"
        fullWidth
        type="submit"
        value="OK"
        disabled={false}
        onClick={onPrimaryButtonClick}
        className="ln-u-margin-bottom"
      >
        {primaryButtonText}
      </FilledButton>

      {secondaryButtonText ? (
        <TextButton
          className="ln-u-margin-top*2"
          darkTheme={secondaryButtonText === "Cancel"}
          data-testid="basic-modal-cancel-button"
          onClick={onSecondaryButtonClick}
        >
          {secondaryButtonText}
          {/* Luna bug workaround, no support for null as children of modal */}
        </TextButton>
      ) : null}
    </div>
  </Modal>
);
