import { cloneDeep } from "lodash";
import { CitrusAnalyticsType, sendCitrusAnalytics } from "../../common/citrus";
import { State } from "../../common/store";
import { GOLUI_PREFIX } from "../../routes";
import { Controls, PaginatedProducts, ResponseFilter } from "../../services/product.types";
import { retrieveBrowseDigitalDataProperties } from "../Browse/BrowseMagnoliaContent/BrowseAnalytics.utils";
import {
  BrandedPageState,
  BrandedQuickLinkGroup,
  BrandedStateSKUObject,
  BrandedTemplateResponse,
} from "./BrandedPage.types";
import { getUserConsents } from "../../utils/cookie";
import { trimSlashes } from "../../utils/trimSlashes/trimSlashes";
import { DataState } from "../../common/dataState";
import { MagnoliaTemplate } from "../../services/contentTypes";
import { AnalyticsEvent } from "../../common/dataLayer/types";
import { digitalDataGenericTrackEvent } from "../../common/analytics";

export const injectFacetCallback = (
  templateObject: BrandedQuickLinkGroup,
  handleQuickLinkInternalNavigation: (facetName: string) => void
) => ({
  ...templateObject,
  included: templateObject.included.map(quickLinkObject => ({
    ...quickLinkObject,
    onLinkClick: () => {
      // Send event
      const analyticsEvent: AnalyticsEvent = {
        data_event_category: "Quicklink Click",
        data_event_action: "click",
        data_event_label: `facet:${quickLinkObject.quickLinkName}`,
      };
      digitalDataGenericTrackEvent("ev_brandQuicklinkClick", analyticsEvent);

      quickLinkObject.link?.url
        ? window.open(quickLinkObject.link.url, "_self")
        : handleQuickLinkInternalNavigation(quickLinkObject.quickLinkName);
    },
    link: undefined,
  })),
});

export const prepareQuickLinksForBranded = (
  data: BrandedTemplateResponse["citrus"]["data"],
  handleQuickLinkInternalNavigation: (facetName: string) => void
): BrandedPageState["data"]["facetContent"] => ({
  data: [
    {
      type: "area",
      id: "C",
      desc: "Area C",
      attributes: data
        .filter(templateObject => (templateObject as any).mobileLayout === "slider")
        .map(templateObject => ({
          ...templateObject,
          included: (templateObject as any).included
            // Remove null entries
            .filter((quickLinkItem: BrandedQuickLinkGroup["included"][0]) => Boolean(quickLinkItem))
            // convert quickLinkName values to lowercase
            .map((quickLinkItem: BrandedQuickLinkGroup["included"][0]) => ({
              ...quickLinkItem,
              quickLinkName: quickLinkItem.quickLinkName?.toLowerCase(),
            })),
        }))
        .map(templateObject => injectFacetCallback(templateObject as any, handleQuickLinkInternalNavigation)),
    },
  ],
});

export const prepareCitrusDataForBranded = (
  data: BrandedTemplateResponse["citrus"]["data"]
): BrandedPageState["data"]["citrusContent"] => {
  return {
    data: [
      {
        type: "area",
        id: "B",
        desc: "Area B",
        attributes: data.filter(
          templateObject =>
            templateObject.type !== "" &&
            templateObject.type !== "meta" &&
            (templateObject as any).mobileLayout !== "slider"
        ),
      },
    ],
  };
};

export const prepareBrandedHeader = (
  data: BrandedTemplateResponse["citrus"]["data"]
): BrandedPageState["data"]["header"] => {
  const headerMetaObject = data.find(templateObject => templateObject.type === "meta");
  const {
    refinedPageHeader: heading,
    refinedPageIntroCopy: introCopy,
    refinedPageThemeColour: themeColour,
    refinedPageBackgroundColour: backgroundColour,
  } = headerMetaObject && headerMetaObject.meta
    ? headerMetaObject.meta
    : {
        refinedPageHeader: "",
        refinedPageIntroCopy: "",
        refinedPageThemeColour: "",
        refinedPageBackgroundColour: "",
      };
  return { heading, introCopy, themeColour, backgroundColour };
};

export const prepareMagnoliaDataForBranded = (
  data: BrandedTemplateResponse["data"]
): BrandedPageState["data"]["magnoliaContent"] => {
  return {
    data: data.filter(templateArea => templateArea.id === "A"),
  };
};

export const prepareBrandedFooter = (data: BrandedTemplateResponse["data"]): BrandedPageState["data"]["footer"] => {
  return {
    data: data.filter(templateArea => templateArea.id === "B").map(templateArea => ({ ...templateArea, id: "footer" })),
  };
};

export const extractBrandedSKUInfo = (
  data: BrandedTemplateResponse["citrus"]["data"]
): BrandedPageState["data"]["skus"] => {
  const extractedProductObject = data.find(obj => !obj.type);
  if (!extractedProductObject || !extractedProductObject.products) return [];
  const unstructuredSKUData = extractedProductObject.products;
  const structuredSKUData = unstructuredSKUData.map(({ name, productSelection }) => ({
    [name]: productSelection.map(({ productCode }) => productCode),
  }));
  return structuredSKUData;
};

export const segregateBrandedPageData = (
  data: BrandedTemplateResponse,
  handleQuickLinkInternalNavigation: (facetName: string) => void
): BrandedPageState["data"] => {
  const { citrus, meta, data: magnolia } = data;
  const DEFAULT_TEMPLATE = { data: [] };
  return {
    facetContent: citrus
      ? prepareQuickLinksForBranded(citrus.data, handleQuickLinkInternalNavigation)
      : DEFAULT_TEMPLATE,
    citrusContent: citrus ? prepareCitrusDataForBranded(citrus.data) : DEFAULT_TEMPLATE,
    magnoliaContent: magnolia ? prepareMagnoliaDataForBranded(magnolia) : DEFAULT_TEMPLATE,
    skus: citrus ? extractBrandedSKUInfo(citrus.data) : [],
    meta: meta ?? { title: "", description: "" },
    header: citrus
      ? prepareBrandedHeader(citrus.data)
      : { heading: "", introCopy: "", themeColour: "", backgroundColour: "" },
    footer: magnolia ? prepareBrandedFooter(magnolia) : DEFAULT_TEMPLATE,
    brandPageId: data.citrus.brandPageId,
    serveId: data.citrus.realisedBrandPageId,
  };
};

export const brandedFilterUpdate = (filters: ResponseFilter[]) => {
  // Filter out extra multi-select-filters apart from Offers and Nectar Products
  // Presence of other multi-select-filters prevents render of Offers filter
  try {
    const updatedFilters = cloneDeep(filters);
    const multiSelectFilterIndex = updatedFilters.findIndex(e => e.key === "nav_Filter-Your-Results");
    updatedFilters[multiSelectFilterIndex].values = updatedFilters[multiSelectFilterIndex].values.filter(
      e => e.label === "Offers & Nectar Prices"
    );
    return updatedFilters;
  } catch (e) {
    return filters;
  }
};

export const isBrandedPage = (pathname: string) => (pathname ? pathname.includes("/gol-ui/brands/") : false);

export const stripAndExtractFacetValue = (pathname: string) => {
  if (pathname.includes("facet")) {
    const facetSlug = pathname.split("/").find(slug => slug.includes("facet"));
    const strippedPathname = pathname.replace(`/${facetSlug}`, "");
    return {
      pathname: strippedPathname,
      facetSlug: facetSlug ?? null,
    };
  }
  return {
    pathname,
    facetSlug: null,
  };
};

export const getBrandedSubStrings = (path: string) => {
  const OPTION_DELIMITER = "opt/";
  const { pathname, facetSlug } = stripAndExtractFacetValue(path);
  const segments: string[] = pathname.split(OPTION_DELIMITER);
  const options = segments.length > 1 ? OPTION_DELIMITER.concat(segments[1]) : "";
  const landingURL = `${GOLUI_PREFIX}/brands/${pathname.split("/")[3]}`;
  const filters = trimSlashes(segments[0].replace(landingURL, ""));
  return {
    landingURL,
    options,
    filters,
    facetSlug,
  };
};

export const getUnfilteredBrandedURL = (pathname: string): string => {
  const { landingURL, facetSlug } = getBrandedSubStrings(pathname);
  return facetSlug ? `${landingURL}/${facetSlug}` : landingURL;
};

export const updateBrandedSortOptions = (data: PaginatedProducts) => {
  const allowedSortOptions = [
    {
      display: "Relevance",
      value: "-relevance",
    },
    {
      display: "Price - Low to High",
      value: "price_asc",
    },
    {
      display: "Price - High to Low",
      value: "price_desc",
    },
    {
      display: "Ratings - High to Low",
      value: "rating",
    },
  ];
  return {
    ...data,
    controls: {
      ...data.controls,
      sort: {
        active: data.controls.sort.active === "none" ? "-relevance" : data.controls.sort.active,
        options: allowedSortOptions,
      },
    },
  };
};

// Utils for Analytics
export const retrieveBrandedDigitalDataProperties = (controls?: Controls) => {
  // Reusing the branded digital data utils
  // Move the browse method to a more common location
  return retrieveBrowseDigitalDataProperties("brands", controls);
};

export const updatedCitrusEvent = (state: State, productCode: string, eventType: CitrusAnalyticsType) => {
  const { brandPageId, serveId } = state.brandedPageData.data;
  const { performance } = getUserConsents();
  if (performance && brandPageId && serveId && productCode && eventType) {
    sendCitrusAnalytics({
      brandPageId,
      serveId,
      events: [
        {
          productCode,
          eventType,
        },
      ],
    });
  } else console.error("Citrus event could not be tracked");
};

export const getProductSKUs = (
  facetSlug: string | null,
  dataStatus: DataState,
  dataSkus: BrandedStateSKUObject[],
  facetContent: MagnoliaTemplate
) => {
  // Constants for facet delimiter and default facet value
  const FACET_DELEMITER = ":";
  const DEFAULT_FACET = "Products";
  const ALT_DEFAULT_FACET = "AllProducts";

  // The facet value on url differs from that used as a key against product skus
  // This sub-routine helps mapping the correct values required to fetch the skus
  const mapURLFacetToProductFacetValue = (slug: string) => {
    try {
      return dataStatus === DataState.SUCCESS
        ? (facetContent.data[0].attributes[0] as BrandedQuickLinkGroup).included.find(
            quickLinkItem => quickLinkItem.quickLinkName === slug
          )?.name || DEFAULT_FACET
        : DEFAULT_FACET;
    } catch (e) {
      return DEFAULT_FACET;
    }
  };

  // Extract facet value from the facetSlug or use the default value
  let facetValue =
    facetSlug === null ? DEFAULT_FACET : mapURLFacetToProductFacetValue(facetSlug.split(FACET_DELEMITER)[1]);

  // Check if the data state is SUCCESS
  if (dataStatus === DataState.SUCCESS) {
    // Check the edge case where default facet is AllProducts and not Products
    const facetResponseKeys = dataSkus.map(facetObject => Object.keys(facetObject)[0]);
    facetValue = facetResponseKeys.includes(facetValue) ? facetValue : ALT_DEFAULT_FACET;
    // Find the facetProductsObject based on the facetValue
    const facetProductsObject = dataSkus.find((facetObject: BrandedStateSKUObject) =>
      Object.keys(facetObject).includes(facetValue)
    );

    // If facetProductsObject is not null, return the corresponding products, else return an empty array of SKUSs
    return facetProductsObject ? facetProductsObject[facetValue] : [];
  } else {
    // If data state is not SUCCESS, return an empty array of SKUs
    return [];
  }
};
