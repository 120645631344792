import { Dispatch } from "redux";
import {
  DispatchableRecurringSlotActionTypes,
  FetchRecurringSlotFailed,
  FetchRecurringSlotPending,
  FetchRecurringSlotSuccess,
  OptOutRecurringSlotActionType,
  OptOutRecurringSlotFailed,
  OptOutRecurringSlotPending,
  OptOutRecurringSlotSuccess,
  RecurringSlotPreferenceActionTypes,
  RecurringSlotPreferences,
  OptInRecurringSlotActionType,
  OptInRecurringSlotFailed,
  OptInRecurringSlotSuccess,
} from "./RecurringSlot.types";
import { MyRecurringSlotPageViewAction, RecurringSlotOptInPageViewAction } from "../../common/analytics/analytics";
import { AnalyticsActionTypes } from "../../common/analytics/types";
import GolTime from "../../domain/time";
import { fetchRecurringSlot, optInRecurringSlot, optOutRecurringSlot } from "../../services/slot";
import { RecurringSlotResponse } from "../../services/slot.types";

export const createOptInRecurringSuccessAction = (): OptInRecurringSlotSuccess => ({
  type: RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT_SUCCESS,
});

export const OptInRecurringFailedAction = (): OptInRecurringSlotFailed => ({
  type: RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT_FAILED,
});

export const optInRecurringSlotAction = (
  slotStartTime: GolTime,
  slotEndTime: GolTime,
  frequency: number,
  storeIdentifier: string,
  addressId: string
): OptInRecurringSlotActionType => ({
  type: RecurringSlotPreferenceActionTypes.OPT_IN_RECURRING_SLOT,
  slotStartTime,
  slotEndTime,
  frequency,
  storeIdentifier,
  addressId,
});

export const createOptOutRecurringSlotAction = (): OptOutRecurringSlotActionType => ({
  type: RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT,
});

export const createOptOutRecurringSlotPendingAction = (): OptOutRecurringSlotPending => ({
  type: RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_PENDING,
});

export const createOptOutRecurringSlotFailedAction = (): OptOutRecurringSlotFailed => ({
  type: RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_FAILED,
});

export const createOptOutRecurringSlotSuccessAction = (): OptOutRecurringSlotSuccess => ({
  type: RecurringSlotPreferenceActionTypes.OPT_OUT_RECURRING_SLOT_SUCCESS,
});

export const createFetchRecurringSlotPendingAction = (): FetchRecurringSlotPending => ({
  type: RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_PENDING,
});

export const createFetchRecurringSlotFailedAction = (): FetchRecurringSlotFailed => ({
  type: RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_FAILED,
});

export const createFetchRecurringSlotSuccessAction = (
  recurringSlotPreferences: RecurringSlotPreferences
): FetchRecurringSlotSuccess => ({
  type: RecurringSlotPreferenceActionTypes.FETCH_RECURRING_SLOT_SUCCESS,
  recurringSlotPreferences,
});

export const recurringSlotOptInPageViewAction = (): RecurringSlotOptInPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "recurringSlotOptIn",
    template: "recurringSlot",
    newTemplate: "recurringSlot",
    section: "recurringSlotOptIn",
  },
});

export const myRecurringSlotPageViewAction = (): MyRecurringSlotPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "myRecurringSlot",
    template: "recurringSlot",
    newTemplate: "recurringSlot",
    section: "myRecurringSlot",
  },
});

export const optInRecurringSlotThunk =
  (slotStartTime: GolTime, slotEndTime: GolTime, frequency: number, storeIdentifier: string, addressId: string) =>
  async (dispatch: Dispatch<DispatchableRecurringSlotActionTypes>): Promise<void> => {
    const result = await optInRecurringSlot(slotStartTime, slotEndTime, frequency, storeIdentifier, addressId);

    if (!result.isSuccess()) {
      dispatch(OptInRecurringFailedAction());
      return;
    }

    dispatch(createOptInRecurringSuccessAction());
  };

export const fetchRecurringSlotThunk =
  () =>
  async (dispatch: Dispatch<DispatchableRecurringSlotActionTypes>): Promise<void> => {
    dispatch(createFetchRecurringSlotPendingAction());

    const result = await fetchRecurringSlot();

    if (!result.isSuccess()) {
      dispatch(createFetchRecurringSlotFailedAction());
      return;
    }

    dispatch(createFetchRecurringSlotSuccessAction(mapRecurringSlot(result.data)));
  };

export const optOutRecurringSlotThunk =
  () =>
  async (dispatch: Dispatch<DispatchableRecurringSlotActionTypes>): Promise<void> => {
    dispatch(createOptOutRecurringSlotPendingAction());

    const result = await optOutRecurringSlot();

    if (!result.isSuccess()) {
      dispatch(createOptOutRecurringSlotFailedAction());
      return;
    }

    dispatch(createOptOutRecurringSlotSuccessAction());
  };

function mapRecurringSlot(response: RecurringSlotResponse): RecurringSlotPreferences {
  return {
    registered: response.registered,
    eligible: response.eligible,
  };
}
