import { FetchBrowseProductsArgs, RequestFilters, SearchResponse } from "../../services/product.types";
import { initPathFilters, isOffersFilter } from "../../views/filters.types";
import { BreadcrumbsItem } from "../../components/Breadcrumbs/Breadcrumbs.types";
import { Banners } from "../../components/Citrus/BannerAd/BannerAd.types";
import { AdsResponseEnvelope } from "../../services/content";
import { DataState } from "../../common/dataState";

export interface BrowseEntity {
  tree: string;
  browseID: string;
}

export interface BrowseOptions {
  sort: string;
  page: string;
}

export interface BrowsePathSubstrings {
  tree: string;
  browseID: string;
  prefixedBrowseID: string;
  landingURL: string;
  filters: string;
  options: string;
}
export type BrowsePathError = string;
export type BrowseHookResponseData = BrowseEntity &
  BrowseOptions & {
    argsFilters: RequestFilters;
    metaFilters: RequestFilters;
    landingURL: string;
    noIndex: boolean;
  };

export interface BrowseHookResponse {
  data: BrowseHookResponseData;
  err?: BrowsePathError;
}

export enum BrowseControls {
  dietary = "dietary",
  brand = "brand",
  offers = "offers",
  other = "other",
  sort = "sort",
  page = "page",
  offer = "offer",
}

export const CATEGORY_ID_IDENTIFIER = "c:";
export const OPTION_DELIMITER = "opt/";

// TYPE GUARDS
const hasID = (s: string): boolean => s.includes(CATEGORY_ID_IDENTIFIER);
const isExpectedKey = (s: string): boolean => s in initPathFilters || s in initBrowseOptions;
const isExpectedKeyValuePair = (s: string): boolean => s.includes(":") && isExpectedKey(s.split(":")[0]);
export const isExpected = (s: string): boolean => hasID(s) || isExpectedKeyValuePair(s) || isOffersFilter(s);
export const isValidPath = (s: string): boolean => hasID(s);

// INITIALISED TYPE FOR CONVENIENCE
export const initBrowsePathSubstrings: BrowsePathSubstrings = {
  tree: "",
  browseID: "",
  prefixedBrowseID: "",
  landingURL: "",
  filters: "",
  options: "",
};

export const initBrowseOptions: BrowseOptions = {
  sort: "",
  page: "",
};

export interface SynapticaBreadcrumbs {
  [trail: string]: BreadcrumbsItem;
}

export interface ParsedBrowseTaxonomyState {
  [key: string]: {
    lookups: number[];
    findabilityID: string;
  };
}

export interface ParsedBrowseNode {
  taxonomyType: BrowseTaxonomyType;
  pageType: BrowsePageType;
  findabilityID: string;
  lookups: number[];
}

export interface BrowseTreeNode {
  name: string;
  id: string;
  pimid: string;
  children: BrowseTreeNode[];
}

export interface BrowseServiceResponse {
  data: BrowseTreeNode[];
}

// BrowseTaxonomyType reflects the ecommerce browse journey of
// Top Level Categories -> Category Listing Page -> Product Listing Page
export enum BrowseTaxonomyType {
  TLC = "TLC",
  CLP = "CLP",
  PLP = "PLP",
  UNKNOWN = "UNKNOWN",
}

// BrowsePageType corresponds to BrowseTaxonomyType
export enum BrowsePageType {
  ZONE = "ZONE",
  DEPARTMENT = "DEPARTMENT",
  AISLE = "AISLE",
  SHELF = "SHELF",
  UNKNOWN = "UNKNOWN",
}

export enum BrowseAnalyticsPageName {
  SHELF = "shelfPage",
  DEPARTMENT = "departmentPage",
  AISLE = "aislePage",
  ZONE = "zonePage",
}
export enum BrowseAnalyticsPageTemplate {
  SHELF = "shelf",
  DEPARTMENT = "department",
  AISLE = "aisle",
  ZONE = "zone",
}

export interface BrowseNodeType {
  taxonomyType: BrowseTaxonomyType;
  pageType: BrowsePageType;
}

export type BrowseTaxonomyState = BrowseTreeNode[];
export type BrowseFetchResultData = SearchResponse;

export interface BrowseProductListProps {
  requestArgs: FetchBrowseProductsArgs;
  landingURL: string;
  tree: string;
  pageType: BrowsePageType;
  eventIdsStatus: DataState;
  isOccasionsRoute: boolean;
}

export interface BrowsePageProps {
  path: string;
  findabilityID: string;
  pageType: BrowsePageType;
  lookups: number[];
  isOccasionsRoute: boolean;
}

export interface RequestFiltersWithMeta {
  argsFilters: RequestFilters;
  metaFilters: RequestFilters;
}

export interface ZoneCitrusResponse {
  ads: AdsResponseEnvelope<Banners>[];
}

export interface BrowseEventsState {
  dataStatus: DataState;
  data: string[];
}
