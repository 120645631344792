type FetchWithTimeoutParams = {
  url: string;
  options: RequestInit & { timeout: number };
};

const fetchWithTimeout = async ({ url, options: { timeout, ...options } }: FetchWithTimeoutParams) => {
  const controller = new AbortController();

  const timerId = setTimeout(() => {
    controller.abort();
  }, timeout);

  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });

    clearTimeout(timerId);

    return response;
  } catch (error) {
    if (controller.signal.aborted) {
      throw new Error(`Request exceeded timeout of ${timeout}ms`);
    } else {
      throw error;
    }
  }
};

export default fetchWithTimeout;
