export const stripAndExtractListValue = (pathname: string) => {
  if (pathname.includes("list")) {
    const listIdSlug = pathname.split("/").find(slug => slug.includes("list"));
    const strippedPathname = pathname.replace(`/${listIdSlug}`, "");
    return {
      pathname: strippedPathname,
      listIdSlug: listIdSlug ?? null,
    };
  }
  return {
    pathname,
    listIdSlug: null,
  };
};
