import { push, replace } from "connected-react-router";
import { Action, Dispatch, MiddlewareAPI } from "redux";
import { ErrorActionTypes } from "../../components/Error/error.actions";
import { State } from "../store";
import { urls } from "../../routes";
import { startsWithHttp } from "../location/locationSelectors";

export enum NavigationActionTypes {
  INTERNAL_NAVIGATION = "INTERNAL_NAVIGATION",
  REPLACE_HISTORY = "REPLACE_HISTORY",
  EXTERNAL_NAVIGATION = "EXTERNAL_NAVIGATION",
  EXTERNAL_NAVIGATION_WINDOW = "EXTERNAL_NAVIGATION_WINDOW",
}

export const createExternalNavigationAction = (path: string): ExternalNavigationAction => ({
  type: NavigationActionTypes.EXTERNAL_NAVIGATION,
  path,
});

export const createInternalNavigationAction = (path: string): InternalNavigationAction => ({
  type: NavigationActionTypes.INTERNAL_NAVIGATION,
  path,
});

export const createReplaceHistoryAction = (path: string): ReplaceHistoryAction => ({
  type: NavigationActionTypes.REPLACE_HISTORY,
  path,
});

export type createNavigationActionType = (
  urlOrPath: string,
  isExternal?: boolean
) => InternalNavigationAction | ExternalNavigationAction;
export const createNavigationAction: createNavigationActionType = (urlOrPath, isExternal = false) => {
  if (isExternal || startsWithHttp(urlOrPath)) {
    return {
      type: NavigationActionTypes.EXTERNAL_NAVIGATION,
      path: urlOrPath,
    };
  }
  return {
    type: NavigationActionTypes.INTERNAL_NAVIGATION,
    path: urlOrPath,
  };
};

export type InternalNavigationAction = {
  type: NavigationActionTypes.INTERNAL_NAVIGATION;
  path: string;
};

export type ReplaceHistoryAction = {
  type: NavigationActionTypes.REPLACE_HISTORY;
  path: string;
};

export type ExternalNavigationAction = {
  type: NavigationActionTypes.EXTERNAL_NAVIGATION;
  path: string;
};

export type ExternalNavigationWindowAction = {
  type: NavigationActionTypes.EXTERNAL_NAVIGATION_WINDOW;
  path: string;
};

export const navigationMiddleware =
  (api: MiddlewareAPI<Dispatch, State>) => (next: Dispatch<Action>) => (action: Action) => {
    switch (action.type) {
      case ErrorActionTypes.UNHANDLED_API_ERROR:
        next(push(urls.ERROR_PAGE_URL));
        break;
      case NavigationActionTypes.INTERNAL_NAVIGATION:
        const internalNavigationAction = action as InternalNavigationAction;
        next(push(internalNavigationAction.path));
        break;
      case NavigationActionTypes.REPLACE_HISTORY:
        next(replace((action as ReplaceHistoryAction).path));
        break;
      case NavigationActionTypes.EXTERNAL_NAVIGATION:
        const externalNavigationAction = action as ExternalNavigationAction;
        window.location.assign(externalNavigationAction.path);
        break;
      case NavigationActionTypes.EXTERNAL_NAVIGATION_WINDOW:
        const externalNavigationWindowAction = action as ExternalNavigationWindowAction;
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        window.open(externalNavigationWindowAction.path);
        break;
      default:
        break;
    }
    next(action);
  };
