import React from "react";
import clsx from "clsx";
import { Link as RouterLink, useLocation } from "react-router-dom";

export enum LinkButtonAppearance {
  OUTLINED = "OUTLINED",
  FILLED = "FILLED",
  TEXT = "TEXT",
}

export interface LinkProps {
  external?: boolean;
  newTab?: boolean;
  to: string;
  children?: any;
  linkButtonAppearance?: LinkButtonAppearance;
  preserveQuery?: boolean;

  [key: string]: any;
}

export const GOLLink = ({
  external = false,
  newTab = false,
  to,
  children,
  linkButtonAppearance,
  preserveQuery = false,
  ...props
}: LinkProps) => {
  const { search } = useLocation();
  const target = newTab ? "_blank" : "_self";

  const className = clsx(props.className, {
    "ln-c-button": !!linkButtonAppearance,
    "ln-c-button--outlined": linkButtonAppearance === LinkButtonAppearance.OUTLINED,
    "ln-c-button--filled": linkButtonAppearance === LinkButtonAppearance.FILLED,
    "ln-c-button--text": linkButtonAppearance === LinkButtonAppearance.TEXT,
  });

  const mergedProps = {
    ...props,
    className: className.length === 0 ? undefined : className,
  };

  const destination = !external && preserveQuery ? `${to}${search}` : to;

  return external ? (
    <a href={destination} target={target} {...mergedProps}>
      {children}
    </a>
  ) : (
    <RouterLink to={destination} {...mergedProps}>
      {children}
    </RouterLink>
  );
};

GOLLink.displayName = "GOLLink";
