import React from "react";
import { ProgressIndicator, ProgressSpinner } from "@jsluna/react";
import { Trolley } from "@jsluna/icons";

interface MiniTrolleyButtonComponentProps {
  isLoading: boolean;
  isMobile: boolean;
  count: number;
  subtotal: string;
  onClick: () => void;
  onTouchStart: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onKeyUp: (e: React.KeyboardEvent) => void;
}

export const MiniTrolleyButtonComponent = ({
  isLoading,
  subtotal,
  isMobile,
  count,
  onClick,
  onTouchStart,
  onMouseEnter,
  onMouseLeave,
  onKeyUp,
}: MiniTrolleyButtonComponentProps) => {
  const handleOnTouchEnd = (e: React.TouchEvent) => {
    e.preventDefault();
  };

  const getAriaLabel = () => {
    if (count === 0) return "Trolley is empty";
    const verb = count === 1 ? "is" : "are";
    const items = count === 1 ? "item" : "items";
    return `There ${verb} currently ${count} ${items} in trolley`;
  };

  return isLoading ? (
    <ProgressIndicator
      className="ln-u-justify-content header-trolley__loading"
      loading
      data-testid="header-trolley-loading"
      preventFocus
    >
      {!isMobile ? <ProgressSpinner size="small" color="light" /> : <ProgressSpinner size="icon" />}
    </ProgressIndicator>
  ) : (
    <button
      data-testid="header-trolley"
      className="header-trolley__button skipto-trolley"
      onClick={onClick}
      onTouchStart={onTouchStart}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchEnd={handleOnTouchEnd}
      onKeyUp={onKeyUp}
      tabIndex={0}
    >
      <div
        className="header-trolley__icon-wrapper"
        aria-labelledby="header-trolley__count header-trolley__subtotal header-trolley"
        role="note"
      >
        <Trolley className="header-trolley__icon" id="header-trolley" />
        <span
          className={count > 0 ? "header-trolley__count" : "header-trolley__empty"}
          id="header-trolley__count"
          aria-label={getAriaLabel()}
          aria-hidden={true}
        >
          {count > 0 && count}
        </span>
      </div>
      <span
        className="header-trolley__total"
        data-testid="header-trolley-total"
        id="header-trolley__subtotal"
        aria-label={`${subtotal} in total.`}
        aria-hidden={true}
      >
        {subtotal}
      </span>
    </button>
  );
};
