import { OrderItem } from "../../services/order.types";
import { featureFlags } from "../../common/firebase";
import { Basket } from "../../domain/basket/basket.types";
import { Product } from "../../domain/product";
import { extractCategory, isTaggstarEnabledProduct } from "./utils";

export enum TaggstarEventType {
  CATEGORY_VISIT = "categoryVisit",
  PRODUCT_VISIT = "productVisit",
  BASKET_VISIT = "basketVisit",
  CONVERSION = "conversion",
  ADD_TO_BAG = "addToBag",
}

type CategoryEventData = { items: { id: string; oos: boolean }[]; category?: string };

type ProductVisitEventData = {
  id: string;
  category?: string;
  name?: string;
  // Out of stock
  oos: boolean;
  price: number;
  currency: string;
};

type BasketVisitEventData = {
  lines: { id: string; quantity: number; price?: number; category?: string }[];
};

type ConversionEventData = {
  purchaseId: string;
  revenue: number;
  currency?: string;
  orders: { id: string; quantity: number; price: number; category?: string }[];
};

type AddToBagEventData = {
  id: string;
  quantity: number;
};

type EventMap = {
  [TaggstarEventType.CATEGORY_VISIT]: CategoryEventData;
  [TaggstarEventType.PRODUCT_VISIT]: ProductVisitEventData;
  [TaggstarEventType.BASKET_VISIT]: BasketVisitEventData;
  [TaggstarEventType.CONVERSION]: ConversionEventData;
  [TaggstarEventType.ADD_TO_BAG]: AddToBagEventData;
};

export type TaggstarClient = {
  pushEvent<T extends TaggstarEventType>(eventType: T, data: EventMap[T]): Promise<void>;
};

// This queue is for cases where the taggstar script is not loaded yet
// This will enqueue the events until the taggstar script is loaded and ready
// Once the script is loaded, the queued events will be sent to taggstar
// And any further events will be sent directly to taggstar instead of being queued
let taggstarEventQueue: { eventType: TaggstarEventType; data: any }[] = [];
const taggstarEventQueueTimer = setInterval(async () => {
  if (window.taggstarReady) {
    clearInterval(taggstarEventQueueTimer);
    taggstarEventQueue.forEach(({ eventType, data }) => {
      window.taggstar?.pushEvent(eventType, data);
    });
    taggstarEventQueue = [];
  }
}, 500);

export function fireTaggstarEvent<T extends TaggstarEventType>(eventType: T, data: EventMap[T]) {
  if (!window.taggstarReady) {
    taggstarEventQueue.push({ eventType, data });
    return;
  }

  window.taggstar?.pushEvent(eventType, data);
}

export function pushProductVisitEvent(product: Product) {
  if (!featureFlags.get("taggstar") || !isTaggstarEnabledProduct(product)) {
    return;
  }

  fireTaggstarEvent(TaggstarEventType.PRODUCT_VISIT, {
    id: product.productUid,
    category: extractCategory(product),
    name: product.name,
    oos: !product.available,
    price: product.retailPrice?.price ?? 0,
    currency: "GBP",
  });
}

export function pushAddToBagEvent(product: Product | undefined, quantity: number) {
  if (!featureFlags.get("taggstar") || !isTaggstarEnabledProduct(product) || quantity <= 0) {
    return;
  }

  fireTaggstarEvent(TaggstarEventType.ADD_TO_BAG, {
    id: product!.productUid,
    quantity,
  });
}

function isInvalidConversionEvent(revenueString: string, items: Basket["items"] | OrderItem[]) {
  const revenue = Number(revenueString);
  return revenue <= 0 || items.length === 0;
}

export function pushConversionEventViaBasketProduct(
  purchaseId: string,
  revenue: string,
  items: Basket["items"],
  itemCategories: Record<string, string>
) {
  if (!featureFlags.get("taggstar")) {
    return;
  }

  if (isInvalidConversionEvent(revenue, items)) {
    return;
  }

  fireTaggstarEvent(TaggstarEventType.CONVERSION, {
    purchaseId,
    revenue: Number(revenue),
    currency: "GBP",
    orders: items.map(bp => ({
      id: bp.sku,
      quantity: bp.quantity,
      price: bp.subTotal,
      category: itemCategories[bp.sku],
    })),
  });
}

export function pushConversionEventViaOrderItems(
  purchaseId: string,
  revenue: string,
  items: OrderItem[],
  itemCategories: Record<string, string>
) {
  if (!featureFlags.get("taggstar")) {
    return;
  }

  if (isInvalidConversionEvent(revenue, items)) {
    return;
  }

  fireTaggstarEvent(TaggstarEventType.CONVERSION, {
    purchaseId,
    revenue: Number(revenue),
    currency: "GBP",
    orders: items.map(oi => {
      return {
        id: oi.product.product_uid!,
        quantity: oi.quantity,
        price: oi.sub_total,
        category: itemCategories[oi.product.product_uid!],
      };
    }),
  });
}
