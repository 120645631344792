import { Recipe } from "../../views/Recipes/Recipe.types";
import { ProductType } from "../../domain/product";
import { RecipeProductSet } from "./RecipeProductSet";
import { WithDataState } from "../../common/dataState";
import { PromoGroup } from "../../services/product.types";

export interface RecipeCatchweight {
  unit_price: RecipeUnitPrice;
  retail_price: RecipeRetailPrice;
  range: string;
}

export enum RecipeATBActions {
  RECIPE_PRODUCT_REQUEST = "RECIPE_PRODUCT_REQUEST",
  RECIPE_PRODUCT_SUCCESS = "RECIPE_PRODUCT_SUCCESS",
  RECIPE_PRODUCT_FAILED = "RECIPE_PRODUCT_FAILED",
  RECIPE_PRODUCT_CLEAR = "RECIPE_PRODUCT_CLEAR",
  RECIPE_PRODUCT_ITEM_UPDATE = "RECIPE_PRODUCT_ITEM_UPDATE",
  RECIPE_PRODUCT_ITEM_SWAP = "RECIPE_PRODUCT_ITEM_SWAP",
}

export enum RecipesAddIngredientsPriority {
  PRICE = "Budget",
  WASTE = "Least waste",
}

export const RECIPE_ATB_RESERVED_COMMON_NAME = "__recipe-common";
export const RECIPE_ATB_RESERVED_SHARED_NAME = "__recipe-shared";

export const RECIPE_PRIORITY_PRICE = RecipesAddIngredientsPriority.PRICE;
export const RECIPE_PRIORITY_WASTE = RecipesAddIngredientsPriority.WASTE;

export interface RecipeProduct {
  product_id: string;
  product_name: string;
  product_type: ProductType;
  sub_text: string;
  brand: string;
  image_url: string;
  full_url: string;
  quantity: number;
  common: boolean;
  selected?: boolean;
  unit_of_order: string;
  substitute_identifier: string;
  substitute_products_amount: number;
  catchweight?: RecipeCatchweight[];
  retail_price?: RecipeRetailPrice;
  ean: string;
  ingredient_id: number;
  ingredient_name: string;
  ingredient_amount: string;
  ingredient_unitName: string;
  unit_price?: RecipeUnitPrice;
  promotions?: RecipeIngredientPromotion[];
  your_nectar_price?: RecipeYNPrice;
  recipe_ids: string[];
}

export interface RecipeYNPrice {
  without_nectar_price: number;
  start_time: string;
  expiry_time: string;
}

export interface RecipeRetailPrice {
  measure: string;
  price: number;
  was_price?: number;
}

export interface RecipeUnitPrice {
  measure: string;
  measure_amount: number;
  price: number;
}

export interface RecipeProductCommon {
  product_id: string;
  product_name: string;
  product_type: ProductType;
  brand: string;
  image_url: string;
  full_url: string;
  quantity: number;
  common: boolean;
  selected?: boolean;
  substitute_identifier: string;
  substitute_products_amount: number;
  catchweight?: RecipeCatchweight[];
  retail_price?: RecipeRetailPrice;
  ingredient_id: number;
  ingredient_amount: string;
  ingredient_unitName: string;
  ingredient_name: string;
  unit_price?: RecipeUnitPrice;
  promotions?: RecipeIngredientPromotion[];
  your_nectar_price?: RecipeYNPrice;
  recipe_ids: string[];
  sharedRecipes?: string;
}

export interface RecipeIngredientPromotion {
  end_date: string;
  icon: string;
  link: string;
  original_price: number;
  promo_mechanic_id: string;
  promotion_uid: string;
  start_date: string;
  strap_line: string;
  promo_group: PromoGroup;
  is_nectar: boolean;
}

export type RecipeNFRecipeState = {
  priority: RecipesAddIngredientsPriority;
  serves: string;
  ingredients: RecipeProductSet;
};

export type RecipeNFProductState = {
  recipe: Record<string, RecipeNFRecipeState>;
  common: RecipeProductSet | null;
  shared: RecipeProductSet | null;
};

export interface NorthforkState extends WithDataState {
  products: RecipeNFProductState;
}

export type RecipeProductUpdater = (product_id: string, ingredient_id: number, selected: boolean, qty: number) => void;

export interface AddToBasketModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  recipes: Recipe[];
}

export interface ModalHeaderProps {
  recipe: Recipe;
  priority: RecipesAddIngredientsPriority;
  setPriority: React.Dispatch<React.SetStateAction<RecipesAddIngredientsPriority>>;
  serves: string;
  setServes: React.Dispatch<React.SetStateAction<string>>;
}

export interface ModalFooterProps {
  recipeId?: string;
  handleBasketAdd: VoidFunction;
}

export interface TabButtonProps {
  enum_key: RecipesAddIngredientsPriority;
  callback: (opt: RecipesAddIngredientsPriority) => void;
  currentPriority: RecipesAddIngredientsPriority;
}

export enum RecipesBasketAddEvents {
  SUCCESS,
  UNAUTHORIZED,
  CLIENT_ERROR,
  SERVER_ERROR,
}
