import React from "react";
import { Redirect, useLocation } from "react-router-dom";

export type RedirectWithQueryStringProps = { to: string };

export const RedirectWithQueryString = ({ to }: RedirectWithQueryStringProps) => {
  const { search } = useLocation();

  return <Redirect to={{ pathname: to, search }} />;
};
