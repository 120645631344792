import React from "react";
import clsx from "clsx";
import { Container, ProgressIndicator, ProgressSpinner } from "@jsluna/react";
import { Notification } from "../../Notification/Notification.component";
import { AmendOrderBannerLoadingIndicatorProps } from "../AmendOrderBanner.types";

export const AmendOrderBannerLoadingIndicator = ({ usesFavouritesHeader }: AmendOrderBannerLoadingIndicatorProps) => (
  <Container
    className={clsx(
      "amend-order-banner",
      "ln-o-container--soft",
      { "amend-order-banner__margin-top--favourites": usesFavouritesHeader },
      { "amend-order-banner__margin-top--standard": !usesFavouritesHeader }
    )}
    data-testid="amend-order-banner-loading"
  >
    <Notification className="amend-order-banner__wrapper">
      <ProgressIndicator className="ln-u-justify-content-center" loading>
        <ProgressSpinner size="medium" color="dark" />
      </ProgressIndicator>
    </Notification>
  </Container>
);

AmendOrderBannerLoadingIndicator.displayName = "AmendOrderBannerLoadingIndicator";
