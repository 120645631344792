import { DisplayOrder, DisplayOrders, OrderGroup, OrdersPageControlProps } from "./myOrders.types";
import groupBy from "lodash.groupby";
import { State } from "../../common/store";
import { formatCurrency } from "../../common/format";
import GolTime from "../../domain/time";
import qs from "qs";
import { DeliveryDate, Order } from "../../domain/order/order.types";

export const selectDisplayOrders = (state: State): DisplayOrders => {
  const displayOrders = state.orders.data.orders.sort(sortByISO8601DateStringAsc).map(mapDisplayOrder);

  const groups = groupBy(displayOrders, (order: DisplayOrder) => {
    return getOrderGroup(order);
  });

  const upcoming = groups[OrderGroup.UPCOMING] || [];
  const previous = groups[OrderGroup.PREVIOUS] || [];

  return {
    upcoming,
    previous: previous.reverse(),
  };
};

const sortByISO8601DateStringAsc = (orderA: Order, orderB: Order): number =>
  orderA.slotStartTime < orderB.slotStartTime ? -1 : orderA.slotStartTime > orderB.slotStartTime ? 1 : 0;

function getOrderGroup(order: DisplayOrder): OrderGroup {
  if (order.isScheduled) {
    return OrderGroup.UPCOMING;
  }
  return OrderGroup.PREVIOUS;
}

function mapDisplayOrder(order: Order): DisplayOrder {
  const isAmendable = !order.isCutOff;
  const isScheduled = selectIsScheduled(order.status);
  return {
    formattedTotal: formatCurrency(order.total),
    orderNumber: order.orderUid,
    deliveryType: order.orderType,
    formattedDate: formatDeliveryDate(order.slotStartTime, order.slotEndTime),
    viewOrderLink: createViewOrderLink(order.orderUid, isAmendable, isScheduled),
    amendOrderLink: createAmendOrderLink(order.orderUid),
    isAmendable,
    isScheduled,
    isCancellable: order.isCancellable,
    address: createAddress(order),
  };
}

export const createViewOrderLink = (orderID: string, isAmendable: boolean, isScheduled: boolean): string => {
  const scheduledOrderQueryParam = isAmendable && isScheduled ? "true" : "false";
  return `/shop/OrderSummaryDisplay?orderId=${orderID}&scheduledOrder=${scheduledOrderQueryParam}&catalogId=10162&langId=&storeId=10151`;
};

export const createAmendOrderLink = (orderID: string) => {
  return `/shop/OrderAmendConfirmView?orderId=${orderID}&catalogId=10162&langId=44&storeId=10151`;
};

export const formatDeliveryDate = (
  orderStartTime: string,
  orderEndTime: string,
  dateOnly: boolean = false,
  withYear: boolean = true
): DeliveryDate => {
  const startTime = new GolTime(orderStartTime);
  const endTime = new GolTime(orderEndTime);
  const slotDate = withYear ? startTime.format("dddd Do MMMM YYYY") : startTime.format("dddd Do MMMM");
  const slotEndTime = endTime.format("h:mma");
  const slotStartTime = startTime.format("h:mma");
  return dateOnly
    ? {
        slotDate: `${slotDate}, ${slotStartTime}-${slotEndTime}`,
      }
    : {
        slotDate,
        slotStartTime,
        slotEndTime,
      };
};

const selectIsScheduled = (status: string): boolean => status === "scheduled";

export const createAddress = (order: Order): string => {
  const { deliveryAddress, collectionAddress } = order;

  if (deliveryAddress) {
    return `${deliveryAddress.nickname}, ${deliveryAddress.postcode}`;
  }

  if (collectionAddress) {
    return `${collectionAddress.name}, ${collectionAddress.postcode}`;
  }

  return "This address no longer exists in your address book";
};

export const selectPageControls = (state: State): OrdersPageControlProps | undefined => {
  if (state.orders.controls) {
    const { pageNumber, pageLast, recordCount } = state.orders.controls;

    return {
      pageNumber,
      pageLast,
      recordCount,
    };
  }
  return undefined;
};

export const selectPageNumber = (state: State): number | undefined => {
  const { pageNumber } = qs.parse(state.router.location.search, { ignoreQueryPrefix: true });

  return pageNumber ? parseInt(pageNumber, 10) : undefined;
};
