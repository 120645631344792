import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { ArrowRight } from "@jsluna/icons";
import { GroupedMealDealsModal } from "../../components/Modal/GroupedMealDealsModal/GroupedMealDealsModal";
import { MissedPromotion } from "./MultibuyPromotionList.component";

type MissedMultiplePromotionsProps = {
  missedPromotion: MissedPromotion;
  productUid: string;
};

export const MissedMultiplePromotions = ({ missedPromotion, productUid }: MissedMultiplePromotionsProps) => {
  const promoType = missedPromotion.isNectar ? "Nectar" : "offers";
  const multibuyType = missedPromotion.isMealDeal ? "Meal Deals" : "Multi Buys";

  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <button
        className={clsx("missed-multibuy", {
          "missed-multibuy--nectar": missedPromotion.isNectar,
        })}
        onClick={() => setIsOpen(true)}
        data-testid="pt-mini-promotion-missed-flag"
      >
        <div>
          <strong>Missed Offer:&nbsp;</strong>
          {multibuyType} available with {promoType}
        </div>
        <ArrowRight className="missed-multibuy__arrow" />
      </button>
      <GroupedMealDealsModal
        promotions={missedPromotion.items}
        productUid={productUid}
        isOpen={isOpen}
        isNectar={missedPromotion.isNectar}
        isMultiBuy={!missedPromotion.isMealDeal}
        onClose={onClose}
        isMissedOffer={true}
      />
    </>
  );
};
