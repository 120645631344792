import { AnyAction } from "redux";
import { matchPath } from "react-router-dom";
import { LOCATION_CHANGE } from "connected-react-router";
import { Product, ProductSource, ProductsState, ProductsViews, ProductsWithAds } from "./product.types";
import { FavouritesActionTypes } from "@favourites/Favourites.types";
import { updateMap, removeDeletedFavourite } from "./product";
import { RecommendationsActionTypes } from "../../common/recommendations/actions";
import { PreviousOrdersAction } from "../../views/PreviousOrders/previousOrders.types";
import { SearchActionType } from "../../views/SearchResults/search.types";
import { ProductDetailsAction } from "../../views/ProductDetails/productDetails.types";
import { routes } from "../../routes";
import { SearchResultsActionType } from "../../views/SearchResults/search.actions";
import * as domain from "./product.types";
import { TrolleyActionTypes } from "../../views/Trolley/trolley.types";
import { ProductListerActionType } from "../../views/ProductLister/ProductLister.types";
import { BrandShelfActionType } from "../../views/BrandPage/BrandPage.hooks";
import { isBrowseRoute } from "../../views/Browse/Browse.utils";
import { MealDealActionType } from "../../views/MealDealBuilder/MealDealBuilder.types";
import { EventsFeaturesActionType } from "../../views/EventsFeatures/EventsFeatures.types";

export const initialState: ProductsState = {
  data: new Map(),
  views: {
    [ProductsViews.FAVOURITES]: [],
    [ProductsViews.FAVOURITES_ADS]: [],
    [ProductsViews.FAVOURITES_ALTERNATIVES]: [],
    [ProductsViews.SEASONAL_FAVOURITES]: [],
    [ProductsViews.RECOMMENDATIONS]: [],
    [ProductsViews.FIRST_FAVOURITES]: [],
    [ProductsViews.PREVIOUS_ORDERS]: [],
    [ProductsViews.SEARCH_RESULTS]: [],
    [ProductsViews.SEARCH_RESULTS_RELEVANT]: [],
    [ProductsViews.SEARCH_RESULTS_RELATED]: [],
    [ProductsViews.SEARCH_RESULTS_ADS_BELOW_GRID]: [],
    [ProductsViews.PRODUCT_DETAILS]: [],
    [ProductsViews.PRODUCT_DETAILS_SPONSORED_PRODUCT]: [],
    [ProductsViews.PRODUCT_LISTER]: [],
    [ProductsViews.MEAL_DEAL_BUILDER]: [],
    [ProductsViews.LISTER_BUILDER]: [],
    [ProductsViews.EVENTS_BUILDER]: [],
    [ProductsViews.NECTAR_DESTINATION_PAGE]: {
      [ProductsViews.NDP_NECTAR_OFFERS_CAROUSEL]: [],
      [ProductsViews.NDP_NECTAR_FAVOURITES_CAROUSEL]: [],
      [ProductsViews.NDP_BONUS_POINTS_CAROUSEL]: [],
    },
  },
};

export const productsReducer = (state: ProductsState = initialState, action: AnyAction): ProductsState => {
  switch (action.type) {
    case FavouritesActionTypes.DELETE_FAVOURITES_FROM_PRODUCTS: {
      const { productUid } = action;
      return {
        data: removeDeletedFavourite(state.data, productUid),
        views: {
          ...state.views,
          ...(isBrowseRoute(window.location.pathname) && {
            search_results: state.views.search_results.filter(productId => productId !== productUid),
          }),
        },
      };
    }
    case SearchResultsActionType.FETCHED_BELOW_GRID_PRODUCT_ADS_SUCCESS: {
      const products = action.products as domain.ProductAd[];
      return {
        ...state,
        data: updateMap(
          state.data,
          products.map(p => p.product)
        ),
        views: {
          ...state.views,
          [ProductsViews.SEARCH_RESULTS_ADS_BELOW_GRID]: products.map(p => p.product.productUid),
        },
      };
    }
    case FavouritesActionTypes.UPDATE_RECOMMENDATIONS:
    case FavouritesActionTypes.FETCH_FIRST_FAVOURITES_SUCCESS:
    case FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS_WITH_ERRORS_FALLBACK: {
      const { products } = action;
      return {
        data: updateMap(state.data, products),
        views: {
          ...state.views,
          [ProductsViews.FIRST_FAVOURITES]: products.map(({ productUid }: Product) => productUid),
        },
      };
    }

    case FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS: {
      const {
        products: { products, controls },
      } = action;

      return {
        data: updateMap(state.data, products),
        views: {
          ...state.views,
          [ProductsViews.FAVOURITES]: products.map(({ productUid }: Product) => productUid),
          [ProductsViews.FAVOURITES_ADS]: products.filter(
            ({ source }: Product) =>
              !!(source === ProductSource.CROSS_SELL || source === ProductSource.CITRUS_XSELL_AD_FAVOURITES)
          ),
          [ProductsViews.FAVOURITES_ALTERNATIVES]: products.filter(
            ({ associationParentProductUid }: Product) =>
              !!(associationParentProductUid != null && associationParentProductUid !== "")
          ),
        },
        controls,
      };
    }

    case FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_SUCCESS: {
      const {
        products: { products },
      } = action;

      return {
        ...state,
        data: updateMap(state.data, products),
        views: {
          ...state.views,
          [ProductsViews.SEASONAL_FAVOURITES]: products.map(({ productUid }: Product) => productUid),
        },
      };
    }

    case ProductListerActionType.PRODUCTS_SUCCESS: {
      const { products, controls } = action.productData;
      return {
        data: updateMap(state.data, products),
        views: {
          ...state.views,
          [ProductsViews.PRODUCT_LISTER]: products.map(({ productUid }: Product) => productUid),
        },
        controls,
      };
    }

    case BrandShelfActionType.SUCCESS: {
      const { products } = action;
      return {
        ...state,
        data: updateMap(state.data, products),
      };
    }

    // clear the product details and google recommendations views when navigating to PDP so we don't show
    // the previous product before the current product loads.
    case LOCATION_CHANGE: {
      const match = matchPath(action.payload.location.pathname, {
        path: routes.PRODUCT_DETAILS,
        exact: true,
      });

      if (!match) {
        return state;
      }

      return {
        ...state,
        views: {
          ...state.views,
          [ProductsViews.PRODUCT_DETAILS]: [],
          [ProductsViews.FIRST_FAVOURITES]: [],
        },
      };
    }

    case ProductDetailsAction.FETCH_PRODUCT_DETAILS_SUCCESS: {
      const productsWithAds = action.productsWithAds as ProductsWithAds;
      let data = updateMap(state.data, productsWithAds.products);

      if (productsWithAds.ads) {
        data = updateMap<Product>(
          data,
          productsWithAds.ads.sponsoredProducts.productAds.map(p => p.product)
        );
      }

      const productUids = productsWithAds.products.map(({ productUid }: Product) => productUid);

      const hasMultivariants = !!productsWithAds.products[0].multivariants?.length;

      if (hasMultivariants) {
        const variantUid = productsWithAds.products[0].multivariants?.[0].productUid;

        if (variantUid) {
          productUids[0] = variantUid;
        }
      }

      return {
        data,
        views: {
          ...state.views,
          [ProductsViews.PRODUCT_DETAILS]: productUids,
          [ProductsViews.PRODUCT_DETAILS_SPONSORED_PRODUCT]: productsWithAds.ads
            ? productsWithAds.ads.sponsoredProducts.productAds.map(p => p.product.productUid)
            : [],
        },
      };
    }

    case ProductDetailsAction.SET_SELECTED_VARIANT: {
      /*
        The reason we're not just setting a new array here with the new uid is because we rely on the second position
        in the array for merchandising products. So we're just replacing the first product in the array with the new uid.

        See selectMerchandisingProduct in productDetails.selectors.ts for context.
      */
      const newProductDetailsView = [...(state.views[ProductsViews.PRODUCT_DETAILS] as domain.ProductUid[])];
      newProductDetailsView[0] = action.variantUid;

      return {
        ...state,
        views: {
          ...state.views,
          [ProductsViews.PRODUCT_DETAILS]: newProductDetailsView,
        },
      };
    }

    case RecommendationsActionTypes.FETCH_RECOMMENDATIONS_SUCCESS: {
      const { recommendations } = action;

      return {
        ...state,
        data: updateMap(state.data, recommendations),
        views: {
          ...state.views,
          [ProductsViews.RECOMMENDATIONS]: recommendations.map(({ productUid }: Product) => productUid),
        },
      };
    }

    case MealDealActionType.PRODUCTS_SUCCESS: {
      const {
        products: { products, controls, suggestedTerm, errors },
      } = action;

      return {
        data: updateMap(state.data, products),
        views: {
          ...initialState.views,
          [ProductsViews.MEAL_DEAL_BUILDER]: products ? products.map(({ productUid }: Product) => productUid) : [],
        },
        controls,
        errors,
        suggestedTerm,
      };
    }
    case EventsFeaturesActionType.PRODUCTS_SUCCESS: {
      const {
        products: { products, controls, suggestedTerm, errors },
      } = action;

      return {
        data: updateMap(state.data, products),
        views: {
          ...initialState.views,
          [ProductsViews.EVENTS_BUILDER]: products ? products.map(({ productUid }: Product) => productUid) : [],
        },
        controls,
        errors,
        suggestedTerm,
      };
    }

    case SearchActionType.SEARCH_PRODUCTS_SUCCESS: {
      const {
        products: { products, relevancyProducts, controls, suggestedTerm, errors },
      } = action;

      return {
        data: updateMap(state.data, products, true),
        views: {
          ...initialState.views,
          [ProductsViews.SEARCH_RESULTS]: products ? products.map(({ productUid }: Product) => productUid) : [],
          [ProductsViews.SEARCH_RESULTS_RELEVANT]:
            relevancyProducts && relevancyProducts.relevant
              ? relevancyProducts.relevant.map(({ productUid }: Product) => productUid)
              : [],
          [ProductsViews.SEARCH_RESULTS_RELATED]:
            relevancyProducts && relevancyProducts.related
              ? relevancyProducts.related.map(({ productUid }: Product) => productUid)
              : [],
        },
        controls,
        errors,
        suggestedTerm,
      };
    }

    case PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_SUCCESS: {
      const {
        products: { products, controls },
      } = action;

      return {
        data: updateMap(state.data, products),
        views: {
          ...state.views,
          [ProductsViews.PREVIOUS_ORDERS]: products.map(({ productUid }: Product) => productUid),
        },
        controls,
      };
    }

    case TrolleyActionTypes.FETCH_BASKET_PRODUCTS_SUCCESS: {
      const { products, productSource } = action;

      return {
        ...state,
        data: updateMap(state.data, products),
        views: {
          ...state.views,
          ...(productSource === ProductSource.CHECKOUT
            ? { [ProductsViews.CHECKOUT]: products.map(({ productUid }: Product) => productUid) }
            : { [ProductsViews.TROLLEY]: products.map(({ productUid }: Product) => productUid) }),
        },
      };
    }

    default:
      return state;
  }
};
