import { SlotReservationActionTypes, SlotReservationState } from "./slotReservation.types";
import { AmendOrderBannerActionTypes } from "../../components/AmendOrderBanner/AmendOrderBanner.types";
import { Dispatch, MiddlewareAPI, Action } from "redux";
import { State } from "../../common/store";
import { fetchSlotReservation, fetchOriginalSlotReservation } from "./slotReservation.actions";
import { UserActionTypes } from "../../components/Authorized";
import { BasketActionTypes, FetchBasketSuccessAction } from "../basket/basket.types";
import {
  openCutOffModalActionCreator,
  openSlotExpiryModalActionCreator,
} from "../../components/AmendOrderBanner/AmendOrderBanner.actions";
import { urls } from "../../routes";

export const slotReservationMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) => (next: Dispatch<any>) => async (action: Action) => {
    switch (action.type) {
      case UserActionTypes.FETCH_USER_SUCCESS:
        const location = api.getState().location;
        if (
          !location.current.includes(urls.TROLLEY) &&
          !location.current.includes(urls.CLICK_AND_COLLECT_CONFIRMATION) &&
          !location.current.includes(urls.BOOKING_CONFIRMATION) &&
          !location.current.includes(urls.ORDER_CONFIRMATION) &&
          !location.current.includes(urls.CHECKOUT_PAYMENT_REDIRECT)
        ) {
          api.dispatch(fetchSlotReservation());
        }
        break;

      case UserActionTypes.FETCH_USER_FAILURE:
        api.dispatch(fetchSlotReservation());
        break;

      case BasketActionTypes.FETCH_BASKET_SUCCESS:
        const basketAction = action as FetchBasketSuccessAction;
        const { basketId: basketOrderId, isInAmendMode } = basketAction.basket;
        const { originalSlotReservation } = api.getState().slotReservation as SlotReservationState;
        const slotOrderIdMatchesBasketOrderId =
          originalSlotReservation && originalSlotReservation.orderId === basketOrderId;

        if (isInAmendMode && (!originalSlotReservation || !slotOrderIdMatchesBasketOrderId)) {
          api.dispatch(fetchOriginalSlotReservation(basketAction.basket.orderId));
        }
        break;

      case SlotReservationActionTypes.SET_RESERVATION_EXPIRED:
        api.dispatch(openSlotExpiryModalActionCreator());
        break;

      case AmendOrderBannerActionTypes.SET_CUT_OFF_EXPIRED:
        api.dispatch(openCutOffModalActionCreator());
        break;

      default:
        break;
    }

    next(action);
  };
