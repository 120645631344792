import { AnyAction } from "redux";
import { BrowseIngridAdActionType, BrowseIngridAdState } from "./BrowseMagnolia.type";

export const browseAdInitialState: BrowseIngridAdState = {
  ads: [],
};

export const browseAdReducer = (
  state: BrowseIngridAdState = browseAdInitialState,
  action: AnyAction
): BrowseIngridAdState => {
  switch (action.type) {
    case BrowseIngridAdActionType.BROWSE_AD_SUCCESS:
      return {
        ads: action.ads,
      };
    case BrowseIngridAdActionType.BROWSE_AD_REQUEST:
      return {
        ads: [],
      };
    case BrowseIngridAdActionType.BROWSE_AD_FAILURE:
      return {
        ads: [],
      };
    case BrowseIngridAdActionType.BROWSE_AD_RESET:
      return browseAdInitialState;
    default:
      return state;
  }
};
