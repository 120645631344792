import { sendAnalyticsEvent } from "./analyticsEvent";
import { AnalyticsEventType, SearchAnalyticsType, SortByType, FindabilitySortByType, BrandedSortByType } from "./types";
import { SearchResponse } from "../../services/product.types";

export const sendSortByAnalytics = (value: string) => {
  const event = {
    eventName: AnalyticsEventType.SEARCH_SORTBY_CHANGE,
    eventCategory: "Sort by selection",
    eventAction: "Sort by interaction",
    eventLabel: SortByType[`${value}`] || FindabilitySortByType[`${value}`] || BrandedSortByType[`${value}`],
  };

  sendAnalyticsEvent(event);
};

export const sendFilterAnalytics = (key: string, label: string, selected: boolean) => {
  sendAnalyticsEvent({
    eventName: "filterChanges",
    eventCategory: "Filter interaction",
    eventAction: selected ? "Filter selection" : "Filter removal",
    eventLabel: makeEventLabel(key, label),
  });
};

const makeEventLabel = (key: string, label: string) => {
  switch (key) {
    case "cat_Category":
      return `category:${label}|`;
    case "subcat_SubCategory":
      return `subCategory:${label}|`;
    case "nav_Filter-Your-Results":
      return `options:${label}|`;
    case "nav_Brand":
      return `topBrands:${label}|`;
    case "diet_Dietary_and_Lifestyle_Options":
      return `dietaryLifestyle:${label}|`;
    default:
      return `${key}:${label}|`;
  }
};

export const mapSearchResultsForAnalytics = (searchTerm: string, data: SearchResponse): SearchAnalyticsType => {
  return {
    originalSearchTerm: searchTerm,
    onsiteSearchResults: data.controls.total_record_count.toString(),
    onsiteSearchTerm:
      data.suggested_search_terms && data.suggested_search_terms[0].type === "AUTOCORRECT"
        ? data.suggested_search_terms[0].term
        : searchTerm,
  };
};
