import { Order } from "../../services/order.types";
import { Basket } from "../../domain/basket/basket.types";

export function generateBasketDiff(oldBasketOrder: Order, newBasket: Basket): Basket["items"] {
  const diffedItems: Basket["items"] = [];

  for (const newItem of newBasket.items) {
    const oldItem = oldBasketOrder.order_items?.find(item => item.product.product_uid === newItem.sku);

    if (!oldItem) {
      diffedItems.push(newItem);
      continue;
    }

    if (newItem.quantity > oldItem.quantity) {
      diffedItems.push({
        ...newItem,
        quantity: newItem.quantity - oldItem.quantity,
        subTotal: newItem.subTotal - oldItem.sub_total,
      });
    }
  }

  return diffedItems;
}
