import { Order } from "../services/order.types";

export const formatAddress = (order: Order): string => {
  const { delivery_address, click_and_collect_location } = order;
  if (click_and_collect_location) return `${click_and_collect_location.name}, ${click_and_collect_location.postcode}`;

  if (delivery_address) {
    let addressString = "";
    if (delivery_address.flat_number) addressString = addressString + delivery_address.flat_number + ", ";
    if (delivery_address.building_name) addressString = addressString + delivery_address.building_name + ", ";
    if (delivery_address.building_number) addressString = addressString + delivery_address.building_number + " ";
    if (delivery_address.street) addressString = addressString + delivery_address.street + ", ";
    if (delivery_address.postcode) addressString = addressString + delivery_address.postcode;
    return addressString;
  }
  return "This address no longer exists in your address book";
};
