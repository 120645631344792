import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { fetchYnpOptIns } from "./ynp.actions";
import { FetchSlotReservationSuccessAction, SlotReservationActionTypes } from "../slot/slotReservation.types";
import { featureFlags } from "../../common/firebase";
import { AppThunkDispatch } from "../../common/types";

export const ynpMiddleware =
  (api: MiddlewareAPI<AppThunkDispatch, State>) => (next: Dispatch) => (action: FetchSlotReservationSuccessAction) => {
    const isFetchYnpOptInsEnabled = featureFlags.get("fetch_ynp_opt_ins");

    switch (action.type) {
      case SlotReservationActionTypes.FETCH_SLOT_RESERVATION_SUCCESS:
        if (isFetchYnpOptInsEnabled) api.dispatch(fetchYnpOptIns(action.slotReservation));
        break;

      default:
        break;
    }

    next(action);
  };
