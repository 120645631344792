import React from "react";
import Countdown from "react-countdown-now";

interface CountdownComponentProps {
  expiryDateAndTime: string;
  onComplete: () => void;
}

interface CountdownRendererProps {
  minutes: number;
  seconds: number;
}

export const CountdownRenderer = ({ minutes, seconds }: CountdownRendererProps) => {
  const timeRemaining =
    minutes >= 1 ? `${minutes + 1} minutes` : seconds > 1 ? `${seconds} seconds` : `${seconds} second`;

  return <span className="bold-text">{` ${timeRemaining}`}</span>;
};

export const CountdownComponent = ({ expiryDateAndTime, onComplete }: CountdownComponentProps) => (
  <Countdown date={expiryDateAndTime} onComplete={onComplete} renderer={CountdownRenderer} />
);

CountdownComponent.displayName = "CountdownComponent";
