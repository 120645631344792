import GolTime from "../domain/time";
import { State } from "../common/store";
import { SlotReservation, SlotReservationState, SlotReservationType } from "../domain/slot/slotReservation.types";
import { DataState } from "../common/dataState";

export const selectSlotStartTime = (state: State): GolTime | undefined => {
  const { slotReservation } = state.slotReservation as SlotReservationState;
  if (slotReservation) {
    return new GolTime(slotReservation.reservationStartTime);
  }
  return undefined;
};

export const selectSlot = (state: State): SlotReservation =>
  (state.slotReservation as SlotReservationState).slotReservation;

export function selectHasValidSlot(state: State): boolean {
  const reservation = state.slotReservation?.slotReservation;
  const reservationType = reservation?.reservationType ?? SlotReservationType.NONE;

  const hasReservation = !!reservation;
  const isNotExpired = reservation && !reservation.isExpired;
  const isValidType = reservationType !== SlotReservationType.NONE;

  return hasReservation && isNotExpired && isValidType;
}

export const isSlotReservationPending = (state: State): boolean =>
  state.slotReservation.dataState === DataState.PENDING;

export const selectSlotStatus = (state: State): DataState => state.slotReservation.dataState;
