import React, { useMemo } from "react";
import { Modal } from "@sainsburys-tech/fable";
import { Promotion } from "../../../domain/product";
import { digitalDataGenericTrackEvent, INTERACTION_EVENT_NAMES } from "../../../common/analytics";
import { MealDealPromotion } from "./MealDealPromotion";
import { createPortal } from "react-dom";

type GroupedMealDealsModalProps = {
  promotions: Promotion[];
  productUid: string;
  isOpen: boolean;
  onClose: () => void;
  isNectar?: boolean;
  isMultiBuy?: boolean;
  isMissedOffer?: boolean;
};

export const GroupedMealDealsModal = ({
  promotions,
  productUid,
  isNectar,
  isMultiBuy,
  isMissedOffer,
  isOpen,
  onClose,
}: GroupedMealDealsModalProps) => {
  const headerLabel = useMemo(
    () => `${isMultiBuy ? "Multi Buys" : "Meal deals"} available${isNectar ? " with Nectar" : ""}`,
    [isNectar, isMultiBuy]
  );
  return createPortal(
    <Modal
      header={{
        label: headerLabel,
        isDismissible: true,
      }}
      isOpen={isOpen}
      onClose={(event: React.MouseEvent<HTMLDialogElement>) => {
        onClose();
        if (event?.type === "close") {
          digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.OFFER_LINK_CTA_CLICK, {
            hit_type: "link",
            data_event_category: "Promo Strapline Modal",
            data_event_action: "click",
            data_event_label: `${headerLabel} | Close | ${productUid}`,
          });
        }
      }}
    >
      <div className="grouped-meal-deals-modal" data-testid="grouped-meal-deals-modal">
        {promotions.map(promotion => (
          <MealDealPromotion
            promotion={promotion}
            productUid={productUid}
            key={promotion.promotionUid}
            isNectar={isNectar}
            isMissedOffer={isMissedOffer}
          />
        ))}
      </div>
    </Modal>,
    document.body
  );
};
