import moment from "moment";
import "moment-timezone";
import GolTime from "../../domain/time";
import { BookingConfirmationPageViewAction } from "../../common/analytics/analytics";
import { AdobeDigitalDataProperties, AnalyticsActionTypes } from "../../common/analytics/types";
import { DeliveryPassStatus, mapDeliveryPassStatus } from "../../domain/slot/slotMessaging";
import { formatPrice } from "../../common/format";
import { DeliveryAddress, QualifyingBasketAmount, SlotReservation } from "../../domain/slot/slotReservation.types";

export const formatSlotPrice = (
  slotPrice: number,
  qualifyingSlotPrice: number,
  basketTotal: number,
  deliveryPassDays: string[],
  slotDateTime: string,
  isDeliveryPassApplicable: boolean,
  isDeliveryPassAutoRenewalEnabled: boolean,
  deliveryPassExpiryDate?: string
): string => {
  const deliveryPassStatus = mapDeliveryPassStatus(
    deliveryPassDays,
    slotDateTime,
    isDeliveryPassApplicable,
    isDeliveryPassAutoRenewalEnabled,
    deliveryPassExpiryDate
  );
  if (deliveryPassStatus === DeliveryPassStatus.ELIGIBLE && basketTotal >= QualifyingBasketAmount.DELIVERY) {
    return "Free";
  }
  const price = basketTotal >= QualifyingBasketAmount.DELIVERY ? qualifyingSlotPrice : slotPrice;
  return formatPrice(price || "Free");
};

export const formatSlotTime = (slotReservation: SlotReservation): string => {
  const { reservationStartTime, reservationEndTime } = slotReservation;
  return GolTime.formatTimeInterval(reservationStartTime, reservationEndTime);
};

export const formatSlotDate = (slotReservation: SlotReservation): string => {
  const { reservationStartTime, reservationEndTime } = slotReservation;
  if (reservationStartTime && reservationEndTime) {
    return `${moment(reservationStartTime).tz("Europe/London").format("ddd D MMM")}`;
  }
  return "";
};

const createAddress = (deliveryAddress: DeliveryAddress): string => {
  const addressElements = [
    deliveryAddress.flatNumber,
    deliveryAddress.buildingNumber,
    deliveryAddress.buildingName,
    deliveryAddress.street,
    deliveryAddress.town,
    deliveryAddress.postcode,
  ];

  return addressElements.filter(Boolean).join(", ");
};

export const formatAddress = (slotReservation: SlotReservation): string => {
  const { deliveryAddress } = slotReservation;

  if (deliveryAddress) {
    return createAddress(deliveryAddress);
  }
  return "";
};

export const bookingConfirmationPageView = (
  slotReservation: SlotReservation,
  adobeProperties: AdobeDigitalDataProperties
): BookingConfirmationPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "delivery reserved",
    template: "booking slot",
    newTemplate: "deliveryslots",
    section: "delivery and collection",
    deliveryType: "delivery",
    deliveryLocation: slotReservation.reservationPostCode,
    storeId: slotReservation.storeIdentifier,
    orderDeliverySlot: mapReservationTime(slotReservation),
    bookingSlotPrice: slotReservation.slotPrice ? slotReservation.slotPrice : 0,
    slotType: slotReservation.slotType ? slotReservation.slotType : "",
  },
  adobeProperties,
});

const mapReservationTime = (slot: SlotReservation): string => {
  return `${slot.reservationStartTime} - ${slot.reservationEndTime}`;
};
