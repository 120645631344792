import { CollectionItem, DropdownItem, RecipeHierarchy } from "./RecipesBrowse.types";
import { ServiceDropdown, ServiceCollection, HierarchyServiceResponse } from "../../services/recipe-hierarchy";
import { Nullable } from "../../common/types";
import { urls } from "../../routes";

export enum ActionTypes {
  RECIPES_BROWSE_FETCH_SUCCESS = "FETCH_RECIPES_BROWSE_SUCCESS",
  RECIPES_BROWSE_FETCH_FAILURE = "FETCH_RECIPES_BROWSE_FAILURE",
  RECIPES_BROWSE_SELECTED = "RECIPES_BROWSE_SELECTED",
}

export interface RecipeHierarchyAction {
  type: ActionTypes;
  payload: RecipeHierarchy;
}

export function fetchHierarchySuccess(payload: RecipeHierarchy): RecipeHierarchyAction {
  return {
    type: ActionTypes.RECIPES_BROWSE_FETCH_SUCCESS,
    payload,
  };
}

export function fetchHierarchyFailure(payload: RecipeHierarchy): RecipeHierarchyAction {
  return {
    type: ActionTypes.RECIPES_BROWSE_FETCH_SUCCESS,
    payload,
  };
}

export const mapResponseToState: (dt: HierarchyServiceResponse) => Nullable<RecipeHierarchy> = dt => {
  const qualified = dt.Hierarchy.filter(i => isQualified(i));
  if (qualified.length === 0) {
    return null;
  }

  const collections = qualified.map(i => makeCollection(i));
  const viewAllCollections: CollectionItem = {
    title: "All",
    description: "all",
    id: "all",
    hasDropdown: true,
    items: qualified.map<DropdownItem>(i => {
      return !i.l1NavDetails
        ? {
            title: i.title || i.topNavigationText,
            description: i.description,
            id: i.navLinkURL,
            slug: `${urls.RECIPE}/${i.navLinkURL}`,
            tag: i.tag,
          }
        : {
            title: i.title || i.topNavigationText,
            description: i.description,
            id: i.navLinkURL,
            slug: `${urls.RECIPES_BROWSE}/${i.navLinkURL}`,
            tag: i.tag,
          };
    }),
    tag: "All",
  };
  return [viewAllCollections, ...collections];
};

function isQualified(dt: any): dt is ServiceCollection {
  return (
    dt !== undefined &&
    (dt as ServiceCollection).topNavigationText !== undefined &&
    (dt as ServiceCollection).navLinkURL !== undefined &&
    (dt as ServiceCollection).topNavDisplay !== undefined &&
    (dt as ServiceCollection).tag !== undefined
  );
}

const makeCollection: (dt: ServiceCollection) => CollectionItem = dt => {
  const { topNavigationText, navLinkURL, l1NavDetails, topNavDisplay, tag, description } = dt;

  if (!l1NavDetails)
    return {
      title: topNavigationText,
      description,
      id: navLinkURL,
      slug: `${urls.RECIPE}/${navLinkURL}`,
      items: [],
      hasDropdown: topNavDisplay,
      tag,
    };

  const viewAll: DropdownItem = {
    title: `All ${topNavigationText}`,
    description,
    id: navLinkURL,
    slug: `${urls.RECIPES_BROWSE}/${navLinkURL}`,
    tag,
  };

  const dropdownItems: DropdownItem[] = makeDropdown(l1NavDetails, navLinkURL);
  return {
    title: topNavigationText,
    description,
    id: navLinkURL,
    items: [viewAll, ...dropdownItems],
    hasDropdown: topNavDisplay,
    tag,
    footerText: dt.footerText,
    footerTextTitle: dt.footerTextTitle,
  };
};

const makeDropdown: (dt: ServiceDropdown[], collectionSlug: string) => DropdownItem[] = (dt, collectionSlug) => {
  return dt
    .map(i => {
      return {
        title: i.title || i.navLinkText,
        description: i.description,
        id: i.navLinkURL,
        slug: `${urls.RECIPES_BROWSE}/${collectionSlug}/${i.navLinkURL}`,
        tag: i.tag,
        footerText: i.navLinkFooterText,
        footerTextTitle: i.navLinkFooterTextTitle,
      };
    })
    .sort((a, b) => (a.title > b.title ? 1 : -1));
};
