import { DataState } from "../../common/dataState";

export const isDataStateSettled = (dataState: DataState) => {
  switch (dataState) {
    case DataState.FAILED:
    case DataState.SUCCESS:
      return true;

    default:
      return false;
  }
};

export function isDataStateSuccess(dataState: DataState) {
  return dataState === DataState.SUCCESS;
}

export function isDataStateUnknown(dataState: DataState) {
  return dataState === DataState.UNKNOWN;
}

export function isDataStatePending(dataState: DataState) {
  return dataState === DataState.PENDING;
}
