import { WithDataState } from "../../common/dataState";
import { OrdersSummary, OrderSummary } from "../../services/order.types";
import { State } from "../../services/hooks";

export enum OrdersAction {
  FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS",
  FETCH_ORDERS_ERROR = "FETCH_ORDERS_ERROR",
  FETCH_ORDERS_PENDING = "FETCH_ORDERS_PENDING",
  STOP_AMENDING_ORDER_SUCCESS = "STOP_AMENDING_ORDER_SUCCESS",
  STOP_AMENDING_ORDER_ERROR = "STOP_AMENDING_ORDER_ERROR",
  STOP_AMENDING_ORDER_PENDING = "STOP_AMENDING_ORDER_PENDING",
  START_AMENDING_ORDER_SUCCESS = "START_AMENDING_ORDER_SUCCESS",
  START_AMENDING_ORDER_ERROR = "START_AMENDING_ORDER_ERROR",
  START_AMENDING_ORDER_PENDING = "START_AMENDING_ORDER_PENDING",
  CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS",
}

export interface FetchOrdersSuccessAction {
  type: OrdersAction.FETCH_ORDERS_SUCCESS;
  paginatedOrders: PaginatedOrders;
}

export interface FetchOrdersPendingAction {
  type: OrdersAction.FETCH_ORDERS_PENDING;
}

export interface FetchOrdersErrorAction {
  type: OrdersAction.FETCH_ORDERS_ERROR;
}

export interface StopAmendingOrderSuccessAction {
  type: OrdersAction.STOP_AMENDING_ORDER_SUCCESS;
}

export interface StopAmendingOrderPendingAction {
  type: OrdersAction.STOP_AMENDING_ORDER_PENDING;
}

export interface StopAmendingOrderErrorAction {
  type: OrdersAction.STOP_AMENDING_ORDER_ERROR;
}

export interface StartAmendingOrderSuccessAction {
  type: OrdersAction.START_AMENDING_ORDER_SUCCESS;
}

export interface StartAmendingOrderPendingAction {
  type: OrdersAction.START_AMENDING_ORDER_PENDING;
}

export interface StartAmendingOrderErrorAction {
  type: OrdersAction.START_AMENDING_ORDER_ERROR;
}

export interface CancelOrderSuccessAction {
  type: OrdersAction.CANCEL_ORDER_SUCCESS;
  orderUid: string;
}

export type OrdersActionTypes =
  | FetchOrdersSuccessAction
  | FetchOrdersPendingAction
  | FetchOrdersErrorAction
  | StopAmendingOrderPendingAction
  | StopAmendingOrderSuccessAction
  | StopAmendingOrderErrorAction
  | StartAmendingOrderPendingAction
  | StartAmendingOrderSuccessAction
  | StartAmendingOrderErrorAction
  | CancelOrderSuccessAction;

export interface Orders {
  orders: Order[];
}

export interface OrderDeliveryAddress {
  nickname: string;
  addressId: string;
  postcode: string;
}

export interface CollectionAddress {
  name: string;
  postcode: string;
}

export interface Payment {
  card_uid: string;
  cardNetwork: string;
  maskedCardNumber: string;
}

export interface OrderItemProduct {
  name: string;
  imageUrl: string;
  productUrl: string;
  productUid?: string;
}

export interface OrderItem {
  quantity: number;
  subTotal: number;
  product: OrderItemProduct;
  allowSubstitutions: boolean;
}

export interface OrderDetails extends Order {
  subTotal: number;
  deliveryCost: number;
  vouchersSavings: number;
  orderItems: OrderItem[];
  payment?: Payment;
}

export interface Order {
  orderUid: string;
  receiptUrl: string | null;
  total: number;
  slotStartTime: string;
  slotEndTime: string;
  orderType: string;
  status: string;
  isCutOff: boolean;
  isCancellable: boolean;
  slotVanStatus?: string;
  storeIdentifier: string;
  deliveryAddress?: OrderDeliveryAddress;
  collectionAddress?: CollectionAddress;
  cutOffTime?: string | null;
}

export interface OrdersState extends WithDataState {
  data: Orders;
  controls?: OrderControls;
}

export interface DeliveryDate {
  slotDate: string;
  slotStartTime?: string;
  slotEndTime?: string;
}

export interface OrderControls {
  pageSizeOptions: number[];
  pageSizeActive: number;
  pageLast: number;
  pageNumber: number;
  recordCount: number;
}

export interface PaginatedOrders {
  orders: Order[];
  controls: OrderControls;
}

export interface UseOrders {
  fetchOrders: (pageNumber: number, pageSize: number) => void;
  orders: State<OrdersSummary>;
  getNextAmendableDelivery: (orderType: "delivery" | "click_and_collect") => OrderSummary | undefined;
  upcomingDeliveries: UpcomingOrders;
  startAmending: (orderUid: string) => void;
}

export interface UpcomingOrders {
  [slotTimes: string]: OrderSummary;
}
