import { State } from "../../common/store";
import { BasketError, BasketErrorKeys, BasketProduct, KG } from "./basket.types";
import { createSelector } from "reselect";
import { ADD_TO_BASKET_ITEM_INCREMENT, ADD_TO_BASKET_KG_INCREMENT } from "./basket";

const getIsAnyModalVisible = (state: State) => state.modal.currentlyVisible !== null;

export const selectBasketProduct = (state: State, sku: BasketProduct["sku"]): BasketProduct =>
  state.basket.data.items.getByKey(sku);

export const selectBasketError = (state: State): string | undefined => {
  const errors = [BasketErrorKeys.BULK_ORDER_LIMIT_EXCEEDED, BasketErrorKeys.ITEM_QUANTITY_EXCEEDED]
    .map(errorName => (state.basket.errors[`${errorName}`] ? state.basket.errors[`${errorName}`] : null))
    .filter(error => error)
    .map(error => (error as BasketError)!.detail);
  return errors.length ? errors[0] : undefined;
};

const errorBasketSelector = (state: State, basketErrorKey: string): BasketError | undefined =>
  state.basket.errors && state.basket.errors[`${basketErrorKey}`]
    ? state.basket.errors[`${basketErrorKey}`]
    : undefined;

export const maximumBasketLimitExceededErrorSelectorCached = createSelector(
  [getIsAnyModalVisible, (state: State) => errorBasketSelector(state, BasketErrorKeys.MAXIMUM_BASKET_LIMIT_EXCEEDED)],
  (isAnyModalVisible, error) => (isAnyModalVisible ? undefined : error)
);

export const itemQuantityExceededErrorSelectorCached = createSelector(
  [getIsAnyModalVisible, (state: State) => errorBasketSelector(state, BasketErrorKeys.ITEM_QUANTITY_EXCEEDED)],
  (isAnyModalVisible, error) => (isAnyModalVisible ? undefined : error)
);

export const bulkOrderLimitExceededErrorSelectorCached = createSelector(
  [getIsAnyModalVisible, (state: State) => errorBasketSelector(state, BasketErrorKeys.BULK_ORDER_LIMIT_EXCEEDED)],
  (isAnyModalVisible, error) => (isAnyModalVisible ? undefined : error)
);

export const scottishLegislationErrorSelectorCached = createSelector(
  (state: State) => errorBasketSelector(state, BasketErrorKeys.SCOTTISH_LEGISLATION),
  error => error
);

export const selectIsBasketInAmendMode = (state: State): boolean => state.basket.basketDetails.isInAmendMode;

export const selectNudgeValue = (unit: string) =>
  unit === KG ? ADD_TO_BASKET_KG_INCREMENT : ADD_TO_BASKET_ITEM_INCREMENT;
