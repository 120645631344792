import { Dispatch } from "redux";
import { MenuItem } from "./Header.types";
import { State } from "../../common/store";
import uuid from "uuid-v4";
import { fetchCategoryTree } from "../../services/product";
import { Category, CategoryTreeResponse } from "../../services/product.types";
import {
  readyForMigration,
  createWCSBreadcrumbsAction,
  isGOLUILink,
  createWCSURL,
  prependSlashToMigratedURL,
} from "./meganav.action";
import { createFormattedPath } from "../../common/utils";

export enum ActionTypes {
  FETCH_MENU_ITEMS_FAILURE = "FETCH_MENU_ITEMS_FAILURE",
  FETCH_MENU_ITEMS_SUCCESS = "FETCH_MENU_ITEMS_SUCCESS",
}

export interface FetchMenuItemsSuccessAction {
  type: ActionTypes.FETCH_MENU_ITEMS_SUCCESS;
  menuItems: MenuItem;
}

export interface FetchMenuItemsFailureAction {
  type: ActionTypes.FETCH_MENU_ITEMS_FAILURE;
}

const fetchMenuItemsSuccessAction = (menuItems: MenuItem): FetchMenuItemsSuccessAction => ({
  type: ActionTypes.FETCH_MENU_ITEMS_SUCCESS,
  menuItems,
});

const fetchMenuItemsFailureAction = (): FetchMenuItemsFailureAction => ({
  type: ActionTypes.FETCH_MENU_ITEMS_FAILURE,
});

export function fetchMenuItemsAction() {
  return async (dispatch: Dispatch<any>, getState: () => State) => {
    if (getState().categories.length === 0) {
      const result = await fetchCategoryTree();
      if (result.isSuccess()) {
        dispatch(fetchMenuItemsSuccessAction(mapMenuItems(result.data)));
        dispatch(createWCSBreadcrumbsAction(result.data.category_hierarchy));
      } else {
        dispatch(fetchMenuItemsFailureAction());
      }
    }
  };
}

export const WCS_URL_PREFIX = "gb/groceries/";

export const mapMenuItems = (categoryTree: CategoryTreeResponse): MenuItem => {
  return mapMenuItem(categoryTree.category_hierarchy);
};

export const mapMenuItem = (category: Category, taxonomy?: string): MenuItem => {
  const { s: url, n: label } = category;
  const currentPath = createFormattedPath(label);
  const aggregatedPath = taxonomy ? taxonomy.concat("/", currentPath) : currentPath;

  const item: MenuItem = {
    title: category.n,
    url: readyForMigration(url) ? prependSlashToMigratedURL(url) : createWCSURL(url),
    id: uuid(),
    external: !isGOLUILink(url),
  };

  if (category.c) {
    item.items = category.c.map(i => mapMenuItem(i, aggregatedPath));
  }

  return item;
};
