import { useEffect, useState } from "react";
import { MagnoliaTemplate } from "../../../../services/content";
import { DataState } from "../../../../common/dataState";
import { useLoadingDebounce, useMagnoliaTemplateRequest } from "../../../../common/hooks";
import { isDataStateSuccess, isDataStateUnknown } from "../../../../utils/isDataStateSettled/isDataStateSettled";
import { useTrendingSearch } from "./useTrendingSearch.hook";

export const templateName = "search-one";

export const useRestingSearch = (hideRestingSearch?: boolean) => {
  const restingSearch = useTrendingSearch();

  const [template, setTemplate] = useState<MagnoliaTemplate | null>(null);

  const [{ data: magnoliaResponse, status: magnoliaRequestDataState }, initiateMagnoliaRequest] =
    useMagnoliaTemplateRequest();

  const { isLoading } = useLoadingDebounce(magnoliaRequestDataState);

  useEffect(() => {
    if (isDataStateUnknown(magnoliaRequestDataState) && restingSearch?.magnolia_enabled && !hideRestingSearch) {
      initiateMagnoliaRequest({ template: templateName });
    }
  }, [initiateMagnoliaRequest, magnoliaRequestDataState, restingSearch, hideRestingSearch]);

  useEffect(() => {
    if (isDataStateSuccess(magnoliaRequestDataState)) {
      if (magnoliaResponse?.data) {
        setTemplate({ ...magnoliaResponse });
      }
    }
  }, [magnoliaRequestDataState, magnoliaResponse]);

  return {
    template,
    isLoading,
    magnoliaError: magnoliaRequestDataState === DataState.FAILED,
    restingSearch,
  };
};
