type AdditionalAttributes = {
  [key: string]: any;
};

export type ErrorReporter = (err: Error, additionalAttributes?: AdditionalAttributes) => void;

// this function is not a react hook. A ticket has been created to address this: https://sainsburys-jira.valiantys.net/browse/GO-7619
/** @deprecated this code is deprecated. Use code in NewRelicClient instead */
export const useReportError = (): ErrorReporter => (err: Error, additionalAttributes?: AdditionalAttributes) => {
  if ((window as any).newrelic && (window as any).newrelic.noticeError) {
    (window as any).newrelic.noticeError(err, additionalAttributes);
  }
};
