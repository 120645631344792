import moment from "moment";
import "moment-timezone";
import GolTime from "../../domain/time";
import { formatPrice } from "../../common/format";
import { QualifyingBasketAmount, SlotReservation } from "../../domain/slot/slotReservation.types";

export const createAddress = (slotReservation: SlotReservation): string => {
  if (slotReservation.reservationAddressNickname) {
    return `${slotReservation.reservationAddressNickname}, ${slotReservation.reservationPostCode}`;
  }
  return "";
};

export const formatSlotTime = (slotReservation: SlotReservation): string => {
  const { reservationStartTime, reservationEndTime } = slotReservation;
  return GolTime.formatTimeInterval(reservationStartTime, reservationEndTime);
};

export const formatSlotDate = (slotReservation: SlotReservation): string => {
  const { reservationStartTime, reservationEndTime } = slotReservation;
  if (reservationStartTime && reservationEndTime) {
    return `${moment(reservationStartTime).tz("Europe/London").format("ddd D MMM")}`;
  }
  return "";
};

/**
 * Returns the formatted qualified price (e.g. £0) if the basket total is >= £40.
 * Otherwise returns the slot price (e.g. £4)
 */
export const formatSlotPrice = (slotQualifiedPrice: number, slotPrice: number, basketTotal: number): string => {
  const price = basketTotal >= QualifyingBasketAmount.DELIVERY ? slotQualifiedPrice : slotPrice;
  return formatPrice(price || "Free");
};
