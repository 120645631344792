import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer, Page, PageBody, PageFooter, PageHeader } from "@jsluna/react";
import { HeaderContainer } from "./components/Header/Header.container";
import { FooterContent } from "./components/Footer/FooterContent";
import { Main } from "./Main";
import { AppLayout } from "./AppLayout";
import { createAppMountAction } from "./App.actions";
import { createGolAppData, isInsideWebView } from "./common/golapp";
import { createEspotControlData } from "./common/espotControl/espotControl";
import { isSimplifiedView, shouldHideFooter } from "./common/simplifiedView";
import { FullScreenPortal } from "./components/FullScreenPortal/FullScreenPortal";
import { AmendOrderBannerSwitch } from "./components/AmendOrderBanner/AmendOrderBanner.container";
import { HelmetRouter } from "./HelmetRouter";
import { FooterIcons } from "./components/Footer/FooterLogos";
import { fetchRedirectAction } from "./common/redirects/redirects.actions";
import { setBotDetectStatus } from "./components/Citrus/GoogleBotDetect/CitrusBotDetect.actions";
import { State } from "./common/store";
import clsx from "clsx";
import { useFeatureFlag } from "./common/firebase/featureFlags.hooks";
import { matchPath, useLocation } from "react-router";
import { routes } from "./routes";
import { TourJourneyTypes } from "@favourites/FeatureTour.types";
import { featureTour } from "@favourites/components/FeatureTour/featureTour";
import { UnrecoverableErrorBoundary } from "./components/ErrorBoundaries/UnrecoverableErrorBoundary/UnrecoverableErrorBoundary";
import { TaggstarCookieManager } from "./utils/taggstar/TaggstarCookieManager";
import { PageThemeContextProvider, PageTheme } from "./components/PageTheme/PageTheme";

export const App = () => {
  const isInsideWebViewState: boolean = useSelector((state: State) => isInsideWebView(state));
  const isSimplifiedViewState: boolean = useSelector(
    (state: State) => isSimplifiedView(state) || isSimplifiedView(state)
  );
  const shouldDispatchBotDetect = useFeatureFlag("google_bot_test");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldDispatchBotDetect) {
      dispatch(setBotDetectStatus());
    }

    dispatch(createAppMountAction());
    dispatch(fetchRedirectAction);
    dispatch(createEspotControlData());

    if (isInsideWebViewState) {
      dispatch(createGolAppData());
    }
  }, [dispatch, isInsideWebViewState, shouldDispatchBotDetect]);

  useEffect(() => {
    featureTour.initialise();
  }, []);

  useEffect(() => {
    TaggstarCookieManager.clearCookies();

    const session = featureTour.getSession();

    const isFavouritesPage = matchPath(pathname, {
      path: routes.FAVOURITES,
      exact: true,
    })?.isExact;

    ref.current?.focus();

    featureTour.setSession({
      enabled: session.enabled,
      type: isFavouritesPage ? TourJourneyTypes.ENHANCED : TourJourneyTypes.NORMAL,
    });
  }, [pathname]);

  const themeEnabled = useFeatureFlag("brands_background");

  return (
    <UnrecoverableErrorBoundary>
      <FullScreenPortal.Exit />
      <AppLayout
        className={clsx({
          "simplified-view": isSimplifiedViewState,
          "hide-footer": !isSimplifiedViewState && shouldHideFooter(),
        })}
      >
        <HelmetRouter />
        <PageThemeContextProvider>
          <Page>
            <PageHeader>
              <div ref={ref} tabIndex={-1}>
                <HeaderContainer />
              </div>
              <AmendOrderBannerSwitch />
            </PageHeader>
            <PageTheme enabled={themeEnabled}>
              <PageBody>
                <Main />
              </PageBody>
            </PageTheme>
            <PageFooter>
              <FooterIcons />
              <Footer data-testid="footer" className="app-layout-footer">
                <FooterContent />
              </Footer>
            </PageFooter>
          </Page>
        </PageThemeContextProvider>
      </AppLayout>
    </UnrecoverableErrorBoundary>
  );
};
