import React from "react";
import { Card } from "@jsluna/react";
import { ProductTileMiniProps } from "./productTile.types";
import { ProductTileImage } from "./ProductTileImage.component";
import clsx from "clsx";
import { BasketControls } from "../BasketControls/BasketControls";
import { ClickSource } from "../../domain/basket/basket.types";
import { useSelector } from "react-redux";
import { State } from "../../common/store";
import { getBasketItemPromotionDetails, useBasketItem } from "../../domain/basket/basket";
import { getUnitPrice } from "../../views/Trolley/trolley";
import { formatPrice } from "../../common/format";
import { TileContextProvider } from "../TileContext";
import { MultibuyPromotionList } from "../../views/Trolley/MultibuyPromotionList.component";
import { OccasionsInfo } from "../../views/Trolley/OccasionsInfo.component";
import { PromoBanner } from "../../views/Trolley/PromoBanner.component";
import { NectarPoints } from "../../views/Trolley/NectarPoints.component";
import { OrderPromiseStatusType } from "../../domain/product";

const FormattedUnitPriceSpan = ({ formattedUnitPrice }: { formattedUnitPrice: string }) => (
  <span className="ds-theme--sainsburys ln-u-caption pt-mini__unit-price" data-testid="pt-mini-unit-price">
    {formattedUnitPrice}
  </span>
);

const PriceDisplay = ({
  isNectarPrice,
  isRedOffer,
  originalPrice,
  price,
  formattedUnitPrice,
  isAppliedRegularMultibuyPromotions,
  isAppliedNectarMultibuyPromotions,
}: {
  isNectarPrice: boolean;
  isRedOffer: boolean;
  originalPrice: string;
  price: string;
  formattedUnitPrice: string;
  isAppliedRegularMultibuyPromotions: boolean;
  isAppliedNectarMultibuyPromotions: boolean;
}) => {
  if ((isNectarPrice && !isAppliedRegularMultibuyPromotions) || isAppliedNectarMultibuyPromotions) {
    return (
      <>
        <div className="trolley-item__nectar-price-before ln-u-margin-top*1/2">
          <b>{originalPrice}</b> Without Nectar
        </div>
        <div>
          <span
            className="trolley-item__nectar-price-after ln-u-display-1 ln-u-margin-right*1/2"
            data-testid="pt-mini-total"
          >
            {price}
          </span>
          <span className="ln-u-font-size-caption">
            <FormattedUnitPriceSpan formattedUnitPrice={formattedUnitPrice} />
          </span>
        </div>
      </>
    );
  } else if (isRedOffer || isAppliedRegularMultibuyPromotions) {
    return (
      <div className="trolley-item__offer-wrapper">
        <div className="trolley-item__offer-before pt-mini__offer ln-u-margin-top*1/2">{originalPrice}</div>
        <div>
          <span className="trolley-item__offer-after ln-u-display-1 ln-u-margin-right*1/2" data-testid="pt-mini-total">
            {price}
          </span>
          <FormattedUnitPriceSpan formattedUnitPrice={formattedUnitPrice} />
        </div>
      </div>
    );
  }

  return (
    <span className="ln-u-display-1 ln-u-margin-right*1/2">
      <span data-testid="pt-mini-total">{price}</span>
      <FormattedUnitPriceSpan formattedUnitPrice={formattedUnitPrice} />
    </span>
  );
};

export const ProductTileMiniComponent = ({
  productUid,
  image,
  url,
  name,
  subTotal,
  unitPrice = 0,
  promotions,
  showPromotionPrice,
  unitOfMeasure,
  nectarOffer,
  totalBasePrice,
  isYourNectarPrice = false,
  isSupplyChainOrderable,
  orderPromise,
  serves,
}: ProductTileMiniProps) => {
  const { catchweight } = useBasketItem(productUid);
  const product = useSelector((state: State) => state.products.data.get(productUid));

  const {
    price,
    isNectarPrice,
    originalPrice,
    isRedOffer,
    isOnlyNectarPointsOffer,
    missedMultibuyPromotions,
    appliedMultibuyPromotions,
    isAppliedRegularMultibuyPromotions,
    isAppliedNectarMultibuyPromotions,
  } = getBasketItemPromotionDetails(promotions, subTotal, totalBasePrice, isYourNectarPrice, nectarOffer);

  const formattedUnitPrice = getUnitPrice(product, catchweight).split("/").join(" / ");
  const unitPriceAltered = unitPrice ? `${formatPrice(unitPrice, true)} / ${unitOfMeasure}` : "";
  const isNonAmendableOccasionItem = orderPromise?.status?.type === OrderPromiseStatusType.BEING_PREPARED;

  return (
    <TileContextProvider>
      <Card className="pt-mini pt__controls--right" data-testid="pt-mini" hard>
        <div className="pt-mini__image">
          <ProductTileImage imageSrc={image} url={url} productName={name} showOccasionBanner={isSupplyChainOrderable} />
        </div>
        <div className="pt-mini__description">
          <PromoBanner
            isNectarPrice={isNectarPrice}
            isRedOffer={isRedOffer}
            isMultibuyApplied={appliedMultibuyPromotions.length > 0}
            nectarPoints={nectarOffer?.points}
            isYourNectarPrice={isYourNectarPrice}
            isOnlyNectarPointsOffer={isOnlyNectarPointsOffer}
            offerExpired={false}
          />
          <div className="pt-mini__title">
            <a href={url} className="pt-mini__link" dangerouslySetInnerHTML={{ __html: name }} />
          </div>
          {isSupplyChainOrderable && <OccasionsInfo orderPromise={orderPromise} serves={serves} />}
          {isOnlyNectarPointsOffer && nectarOffer !== undefined && (
            <NectarPoints points={nectarOffer.points} className="ln-u-text-align-left" />
          )}
          <div className="pt-mini__cost">
            <div
              className={clsx("pt-mini__total", {
                "pt-mini__total--promo": showPromotionPrice,
                "pt-mini__total--promo-nectar":
                  isNectarPrice || isYourNectarPrice || isRedOffer || appliedMultibuyPromotions.length > 0,
              })}
            >
              <PriceDisplay
                isAppliedRegularMultibuyPromotions={isAppliedRegularMultibuyPromotions}
                isAppliedNectarMultibuyPromotions={isAppliedNectarMultibuyPromotions}
                isNectarPrice={isNectarPrice || isYourNectarPrice}
                isRedOffer={isRedOffer}
                originalPrice={originalPrice}
                price={price}
                formattedUnitPrice={formattedUnitPrice}
              />
            </div>
            {unitPriceAltered}
          </div>
        </div>
        <div className="pt-mini__buttons" data-testid="pt-mini-buttons">
          <BasketControls
            sku={productUid}
            productName={name}
            reverseButtonOrder={true}
            clickSource={ClickSource.MINI_TROLLEY}
            product={product}
            isDisabled={isNonAmendableOccasionItem}
          />
        </div>
        <div className="pt-mini__promotions-container">
          <MultibuyPromotionList
            missedMultibuyPromotions={missedMultibuyPromotions}
            appliedMultibuyPromotions={appliedMultibuyPromotions}
            showMissedPromotions={true}
            productUid={productUid}
          />
        </div>
      </Card>
    </TileContextProvider>
  );
};
