import React from "react";
import { ContentWrap } from "../ContentWrap";
import { Route, Switch, withRouter } from "react-router-dom";
import { routes } from "../../routes";
import { SainsburysLogo, NectarLogo } from "../Logo";
import { Footer } from "@jsluna/react";

const FooterIconsRouter = () => (
  <Switch>
    <Route
      path={routes.PROGRESSIVE_V2_REGISTRATION}
      render={() => (
        <Footer data-testid="footer-icons" className="app-layout-footer-icons">
          <ContentWrap soft={false} className="footer">
            <div className="footer__icons">
              <SainsburysLogo />
              <NectarLogo />
            </div>
          </ContentWrap>
        </Footer>
      )}
    />
  </Switch>
);

FooterIconsRouter.displayName = "FooterIconsRouter";
export const FooterIcons = withRouter(FooterIconsRouter);
