import React, { ReactNode } from "react";

type AppLayoutProps = {
  children: ReactNode;
  className?: string;
};

export const AppLayout = ({ children, className = "" }: AppLayoutProps) => (
  <div className={`app ${className}`}>{children}</div>
);
