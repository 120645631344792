import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectBrowseAd, selectSearchAd, selectEventAd, selectFavouritesAd } from "../../IngridAd.selectors";
import { IngridAdComponentProps } from "../../IngridAd.type";
import { ContentPage } from "../../../../services/contentTypes";

interface UseAdSelectorParams {
  placement: IngridAdComponentProps["placement"];
  page: IngridAdComponentProps["page"];
}

export const useAdSelector = ({ placement, page }: UseAdSelectorParams) => {
  const { inGridAd } = useMemo(() => {
    let inGridAd;

    switch (page) {
      case ContentPage.BROWSE:
        inGridAd = selectBrowseAd(placement);
        break;
      case ContentPage.SEARCH:
        inGridAd = selectSearchAd(placement);
        break;
      case ContentPage.FAVOURITES:
        inGridAd = selectFavouritesAd(placement);
        break;
      default:
        inGridAd = selectEventAd(placement);
    }

    return { inGridAd };
  }, [page, placement]);

  const ad = useSelector(inGridAd);

  return { ad };
};
