import { ClickSource } from "../../../../domain/basket/basket.types";
import { ProductSource } from "../../../../domain/product/product.types";
import { AddToCartOrigin } from "../../../dataLayer/types";
import { getOriginFromCarouselKey } from "./getOriginFromCarouselKey";
import { getOriginFromClickSource } from "./getOriginFromClickSource";
import { getOriginFromProductSource } from "./getOriginFromProductSource";

export const mapProductSourceToAddToCartOrigin = (
  productSource: ProductSource | string | undefined,
  clickSource?: ClickSource,
  carouselKey?: string
): string => {
  let addToCartOrigin = "";
  const originFromClickSource = getOriginFromClickSource(clickSource);
  if (originFromClickSource === undefined) {
    try {
      const url = new URL(window.location.href);
      const searchRedirectParam = url.searchParams.get("searchRedirect");
      if (searchRedirectParam) {
        return AddToCartOrigin.SEARCH_REDIRECT.toString();
      }
    } catch (e) {
      // Failed to add to cart origin
      console.log('Failed to determine "add to cart origin" from query string');
    }
  }
  const originFromCarouselKey = getOriginFromCarouselKey(productSource, carouselKey);
  const originFromProductSource = getOriginFromProductSource(productSource, carouselKey);

  addToCartOrigin = originFromClickSource ?? originFromCarouselKey ?? originFromProductSource ?? "";

  return addToCartOrigin.toString();
};
