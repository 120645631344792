import { Optional } from "../common/types";
import { Method, Result } from "../common/http/request";
import { serviceRequest } from "./request";
import { AllCard, Authorisation, Card, Iframe, ProcessPayment } from "./checkout.types";

const url = "/groceries-api/gol-services/checkout";

export const startCheckout = (
  version: Optional<string> = "v1",
  baseUrl: Optional<string> = url
): Promise<Result<void>> =>
  // serviceRequest(Method.GET, `${baseUrl}/v1/checkout/start`);
  serviceRequest({ method: Method.GET, url: `${baseUrl}/${version}/checkout/start` });

export const getSavedCard = (baseUrl: Optional<string> = url): Promise<Result<Card[]>> =>
  serviceRequest({ method: Method.GET, url: `${baseUrl}/v1/checkout/payment-cards` });

export const setDefaultCard = (bmCardId: string, baseUrl: Optional<string> = url): Promise<Result<void>> =>
  serviceRequest({ method: Method.PUT, url: `${baseUrl}/v1/checkout/payment-cards/${bmCardId}` });

export const deletePaymentCard = (bmCardId: string, baseUrl: Optional<string> = url): Promise<Result<void>> =>
  serviceRequest({ method: Method.DELETE, url: `${baseUrl}/v1/checkout/payment-cards/${bmCardId}` });

export const authorisePayment = (
  paymentCardUid: string,
  isStaffDiscountChecked: boolean,
  businessChannel: string,
  pickTime: string,
  storeNum: string,
  baseUrl: Optional<string> = url
): Promise<Result<Authorisation>> => {
  return serviceRequest({
    method: Method.POST,
    url: `${baseUrl}/v1/checkout/authorisation`,
    body: {
      card_uid: paymentCardUid,
      staff_discount_confirmed: isStaffDiscountChecked,
      pick_time: pickTime,
      store_number: storeNum,
    },
    headers: { "Business-Channel": businessChannel },
  });
};

export const startPayment = (
  paymentCardUid: string,
  isStaffDiscountChecked: boolean,
  pickTime: string,
  storeNum: string,
  baseUrl: Optional<string> = url
): Promise<Result<Iframe>> => {
  return serviceRequest({
    method: Method.POST,
    url: `${baseUrl}/v1/checkout/payment/start`,
    body: {
      card_uid: paymentCardUid,
      staff_discount_confirmed: isStaffDiscountChecked,
      pick_time: pickTime,
      store_number: storeNum,
      calculate_substitutes: false,
    },
  });
};

export const startDeliveryPassPayment = (
  orderId: string,
  paymentCardUid: string,
  isStaffDiscountChecked: boolean,
  amount: string,
  termsCond1: boolean,
  termsCond2: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<Iframe>> => {
  return serviceRequest({
    method: Method.POST,
    url: `${baseUrl}/v1/checkout/delivery-pass/start`,
    body: {
      order_id: orderId,
      card_uid: paymentCardUid,
      staff_discount_confirmed: isStaffDiscountChecked,
      amount,
      terms_cond_1: termsCond1,
      terms_cond_2: termsCond2,
    },
  });
};

export const updateDeliveryInstructions = (
  instructions: string,
  baseUrl: Optional<string> = url
): Promise<Result<void>> =>
  serviceRequest({ method: Method.PUT, url: `${baseUrl}/v1/checkout/delivery-instructions`, body: { instructions } });

export const getAllCards = (baseUrl: Optional<string> = url): Promise<Result<AllCard[]>> =>
  serviceRequest({ method: Method.GET, url: `${baseUrl}/v1/checkout/all-cards` });

export const getPaymentIframe = (baseUrl: Optional<string> = url): Promise<Result<Iframe>> => {
  return serviceRequest({
    method: Method.GET,
    url: `${baseUrl}/v1/checkout/payment-cards/iframe`,
  });
};

export const processPaymentCard = (baseUrl: Optional<string> = url): Promise<Result<void>> => {
  return serviceRequest({
    method: Method.GET,
    url: `${baseUrl}/v1/checkout/payment-cards/process`,
  });
};

export const processPayment = (baseUrl: Optional<string> = url): Promise<Result<ProcessPayment>> => {
  return serviceRequest({
    method: Method.GET,
    url: `${baseUrl}/v1/checkout/payment/process`,
  });
};

export const processDeliveryPassPayment = (baseUrl: Optional<string> = url): Promise<Result<ProcessPayment>> => {
  return serviceRequest({
    method: Method.GET,
    url: `${baseUrl}/v1/checkout/delivery-pass/process`,
  });
};
