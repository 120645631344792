import { createSelectorCreator, defaultMemoize } from "reselect";
import { State } from "../../common/store";
import { extractQueryParams } from "../../components/ProductControls/productControls";
import { ProductErrorCode } from "../../services/product.types";
import { InterleavedEspot, EspotParams } from "../../components/Espots/Espot.types";
import { ProductsViews, ProductUid } from "../../domain/product/product.types";
import { DietaryProfileStatus } from "../../domain/customer/customer";
import isEqual from "lodash.isequal";
import { DataState } from "../../common/dataState";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const createSearchResultsProductsUidSelector = createDeepEqualSelector(
  [(state: State): ProductUid[] => state.products.views[ProductsViews.SEARCH_RESULTS]],
  products => products
);

export const createSearchResultsRelevantProductsUidSelector = createDeepEqualSelector(
  [(state: State): ProductUid[] => state.products.views[ProductsViews.SEARCH_RESULTS_RELEVANT]],
  relevantProducts => relevantProducts
);

export const createSearchResultsRelatedProductsUidSelector = createDeepEqualSelector(
  [(state: State): ProductUid[] => state.products.views[ProductsViews.SEARCH_RESULTS_RELATED]],
  relatedProducts => relatedProducts
);

export const createSearchBelowGridProductAdsProductUidsSelector = createDeepEqualSelector(
  [(state: State): ProductUid[] => state.products.views[ProductsViews.SEARCH_RESULTS_ADS_BELOW_GRID]],
  (views: string[]) => views
);

export const searchStatus = (state: State): DataState => state.search.dataState;

export const selectSearchTerm = (state: State): string => {
  const searchQuery = state.router ? state.router.location.search : "";
  const params = extractQueryParams(searchQuery);
  const { filters } = params;
  return (filters && filters.keyword) || "";
};

export const createSuggestedTermSelector = createDeepEqualSelector(
  [(state: State) => state.products.suggestedTerm],
  suggestedTerm => {
    if (!suggestedTerm) {
      return undefined;
    }
    return suggestedTerm[0];
  }
);

export const selectRecordCount = (state: State): number =>
  state.products.controls ? state.products.controls.recordCount : 0;

export const mapEspots = (
  espots: State["espot"]["espots"],
  searchTerm: string
): (InterleavedEspot & { espotParams: EspotParams & { search_term: string } })[] => {
  const ret = espots
    .filter(espot => espot.available)
    .map(interleavedEspot => ({
      ...interleavedEspot,
      espotParams: {
        ...interleavedEspot.espotParams,
        search_term: searchTerm,
      },
    }));
  return ret;
};

export const createAvailableEspotsCachedSelector = createDeepEqualSelector(
  [(state: State) => state.espot.espots, selectSearchTerm],
  mapEspots
);

export const selectIsDietaryProfileError = (state: State) => {
  const { user } = state;
  return !!(
    (user.userDetails && user.userDetails.dietaryProfile === DietaryProfileStatus.ENABLED) ||
    (state.products.errors &&
      state.products.errors.filter(error => error.code === ProductErrorCode.DIETARY_PROFILE_ERROR).length > 0)
  );
};
