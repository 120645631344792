import React from "react";
import { NectarPoints } from "./NectarPoints.component";

const createNectarPriceBanner = (bannerText: string, nectarPoints: number | undefined) => (
  <>
    <div className="trolley-item__nectar-price-banner" title={bannerText} data-testid="offer-price-banner">
      {bannerText}
    </div>
    {nectarPoints && <NectarPoints points={nectarPoints} />}
  </>
);

const RedOfferBanner = ({ nectarPoints }: { nectarPoints?: number }) => (
  <>
    <div className="trolley-item__offer-banner">Offer</div>
    {nectarPoints && <NectarPoints points={nectarPoints} />}
  </>
);

const NectarOfferBanner = ({ nectarPoints, isNectarPrice }: { nectarPoints?: number; isNectarPrice: boolean }) => {
  const nectarOfferText = isNectarPrice ? "Nectar Price" : "Your Nectar Price";

  return createNectarPriceBanner(nectarOfferText, nectarPoints);
};

const NectarPointsBanner = () => (
  <div className="trolley-item__nectar-offer-banner">
    <span>Bonus&nbsp;points</span>
  </div>
);

export interface PromoBannerProps {
  isNectarPrice: boolean;
  isRedOffer: boolean;
  isMultibuyApplied: boolean;
  nectarPoints?: number;
  isYourNectarPrice?: boolean;
  offerExpired?: boolean;
  isOnlyNectarPointsOffer: boolean;
}

export const PromoBanner = ({
  isNectarPrice,
  isRedOffer,
  nectarPoints,
  isYourNectarPrice,
  isMultibuyApplied,
  offerExpired,
  isOnlyNectarPointsOffer,
}: PromoBannerProps) => {
  return (
    <>
      {!offerExpired && !isMultibuyApplied && (
        <>
          {isRedOffer && <RedOfferBanner nectarPoints={nectarPoints} />}
          {(isNectarPrice || isYourNectarPrice) && (
            <NectarOfferBanner isNectarPrice={isNectarPrice} nectarPoints={nectarPoints} />
          )}
        </>
      )}
      {isOnlyNectarPointsOffer && <NectarPointsBanner />}
    </>
  );
};
