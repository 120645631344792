import { useMemo } from "react";
import { useQueryParam } from "../useQueryParam/useQueryParam";

/**
 * @param path - The relative path from a url that you want to add the currentPageUrl query string param to.
 * @returns If there is a currentPageUrl param then it will add that to the provided path. Otherwise the provided path is returned.
 */

export const useCurrentPageUrlParam = (path: string) => {
  const currentPageUrl = useQueryParam("currentPageUrl").queryParam;

  const pathWithQspConsidered = useMemo(() => {
    if (currentPageUrl) {
      return `${path}?currentPageUrl=${currentPageUrl}`;
    }

    return path;
  }, [currentPageUrl, path]);

  return pathWithQspConsidered;
};
