import { DataState } from "../../common/dataState";
import { ProductServiceError, SuggestedSearchTerm } from "../../services/product.types";
import { PaginatedProducts } from "../../domain/product/product.types";

export enum SearchActionType {
  SEARCH_PRODUCTS_REQUEST = "SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAILURE = "SEARCH_PRODUCTS_FAILURE",
}

export type CreateSearchProductSuccessActionType = {
  type: SearchActionType.SEARCH_PRODUCTS_SUCCESS;
  products: SearchProducts;
};

export interface SearchProducts extends PaginatedProducts {
  suggestedTerm?: SuggestedSearchTerm[];
  errors?: ProductServiceError[];
}

export type SearchProductsSuccessAction = {
  type: SearchActionType.SEARCH_PRODUCTS_SUCCESS;
  products: PaginatedProducts;
};

export interface SearchStore {
  dataState: DataState;
}
