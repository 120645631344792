export const pushEvent = (event: DigitalDataEvent) => {
  const { digitalData } = window as any;
  if (event && digitalData && Array.isArray(digitalData.event)) {
    digitalData.event.push(event);
  }
};

export interface DigitalDataEvent {
  eventAction: string;
  eventName: string;

  [key: string]: any;
}
