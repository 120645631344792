import React, { useCallback } from "react";
import debounce from "lodash.debounce";
import { sendImpression } from "../../../../services/content";
import { IngridAd } from "../../IngridAd.type";
import { getCampaignName } from "../../../../common/citrus";

interface UseImpressionHandlerParams {
  adRef: React.RefObject<HTMLDivElement>;
  banner: IngridAd["banner"] | undefined;
  isImpressionSent: boolean;
  setIsImpressionSent: (isImpressionSent: boolean) => void;
}

export const useImpressionHandler = ({
  adRef,
  banner,
  isImpressionSent,
  setIsImpressionSent,
}: UseImpressionHandlerParams) => {
  const sendImpressionOnViewPortPresence = () => {
    const percentVisible = 50;
    const adElement = adRef.current;

    if (!adElement) return;
    const rect = adElement.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    if (
      !(
        Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
      )
    ) {
      if (!isImpressionSent) {
        if (banner) {
          const { citrusAdId, citrusSlotId, ctaLink } = banner.banners[0];
          const name = getCampaignName(ctaLink);
          sendImpression(citrusAdId, citrusSlotId, name);
          setIsImpressionSent(true);
        }
      }
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSendImpression = useCallback(debounce(sendImpressionOnViewPortPresence, 100), [banner]);

  return { handleSendImpression };
};
