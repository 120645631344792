import { combineReducers, AnyAction } from "redux";
import { DataState } from "../../common/dataState";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import { RecipeActionType, RecipeState, Recipe } from "./Recipe.types";

const dataState = createDataStateReducer({
  [RecipeActionType.RECIPE_REQUEST]: DataState.PENDING,
  [RecipeActionType.RECIPE_SUCCESS]: DataState.SUCCESS,
  [RecipeActionType.RECIPE_FAILURE]: DataState.FAILED,
});

export const initialState: RecipeState = {
  dataState: DataState.UNKNOWN,
  recipe: {
    recipeId: "",
    name: "",
    cookingTime: "",
    description: "",
    ingredients: [],
    prepTime: "",
    readyIn: "",
    serves: "",
    image: "",
    author: "",
    method: [],
    recipePills: [],
    nutrition: {
      carbs: "",
      carbs_pc_intake: "",
      energy_kcal: "",
      energy_kj: "",
      energy_pc_intake: "",
      energy_per_100: "",
      fat: "",
      fat_col: "",
      fat_pc_intake: "",
      fibre: "",
      fibre_pc_intake: "",
      protein: "",
      protein_pc_intake: "",
      salt: "",
      salt_col: "",
      salt_pc_intake: "",
      saturates: "",
      saturates_col: "",
      saturates_pc_intake: "",
      sugar: "",
      sugar_col: "",
      sugar_pc_intake: "",
    },
    schema: {
      cookTime: "",
      ingredients: [],
      prepTime: "",
      method: "",
      recipeCategory: "",
      recipeCuisine: "",
      readyTime: "",
      recipeYield: "",
      keywords: [],
      logoImage: "",
    },
  },
};

const recipeReducer = (state: Recipe = initialState.recipe, action: AnyAction): Recipe => {
  switch (action.type) {
    case RecipeActionType.RECIPE_SUCCESS:
      return {
        ...action.recipe,
      };
    default:
      return state;
  }
};

export const recipeDetailsReducer = combineReducers({
  dataState,
  recipe: recipeReducer,
});
