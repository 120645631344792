import {
  BasketActionTypes,
  BasketBulkOrderLimitExceededErrorAction,
  BasketItemQuantityExceededErrorAction,
  BasketMaximumLimitExceededErrorAction,
  BasketScottishLegislationErrorAction,
  BasketState,
  ClearBasketErrorAction,
  CreateDeletedProductAction,
  FetchBasketSuccessAction,
  RemoveDeletedProductAction,
  UpdateBasketRequestAction,
  UpdateLocalQuantityAction,
} from "./basket.types";
import { DataState } from "../../common/dataState";
import { AnyAction } from "redux";
import { LookupTable } from "../../common/lookupTable";
import { ResponseErrorActionType } from "../../common/types";
import { AddOrderToBasketErrorsAction, PreviousOrdersAction } from "../../views/PreviousOrders/previousOrders.types";
import { TrolleyActionTypes } from "../../views/Trolley/trolley.types";
import { ProductErrorCode } from "../../services/product.types";

export const basketInitialState: BasketState = {
  dataState: DataState.UNKNOWN,
  data: {
    items: LookupTable.empty(),
  },
  errors: {},
  basketDetails: {
    basketId: "",
    orderId: "",
    delivery_instructions: "",
    subTotal: 0,
    total: 0,
    savings: 0,
    nectarSavings: 0,
    vouchersSavings: 0,
    slotPrice: 0,
    minimumSpend: 0,
    hasExceededMinimumSpend: false,
    itemCount: 0,
    items: [],
    isInAmendMode: false,
    hasYnpItemsInBasket: false,
  },
  quantities: {},
  deletedFromBasket: [],
};

export const basketReducer = (state: BasketState = basketInitialState, action: AnyAction): BasketState => {
  switch (action.type) {
    case BasketActionTypes.UPDATE_LOCAL_QUANTITY: {
      const { sku, quantity, tileId } = action as UpdateLocalQuantityAction;

      return {
        ...state,
        quantities: {
          ...state.quantities,
          [sku]: {
            ...state.quantities[`${sku}`],
            quantity,
            lastTileId: tileId,
          },
        },
      };
    }

    case BasketActionTypes.UPDATE_BASKET_REQUEST: {
      const { sku, quantityChange, tileId } = action as UpdateBasketRequestAction;
      return {
        ...state,
        dataState: DataState.PENDING,
        errors: {},
        quantities: {
          ...state.quantities,
          [sku]: {
            ...state.quantities[`${sku}`],
            loading: quantityChange !== 0,
            loadingCatchweight: quantityChange === 0,
            lastTileId: tileId,
          },
        },
      };
    }

    case PreviousOrdersAction.ADD_ORDER_TO_BASKET_REQUEST:
    case BasketActionTypes.FETCH_BASKET_PENDING:
      return {
        ...state,
        dataState: DataState.PENDING,
      };

    case BasketActionTypes.FETCH_BASKET_SUCCESS:
    case BasketActionTypes.UPDATE_BASKET_SUCCESS:
    case BasketActionTypes.UPDATE_SUBSTITUTION_PREFERENCES_SUCCESS:
      const successAction = action as FetchBasketSuccessAction;
      const actionItems = successAction.basket.items;
      const data = { items: LookupTable.create(actionItems, "sku") };
      const quantities = actionItems.reduce((acc, basketProduct) => {
        acc[basketProduct.sku] = {
          ...state.quantities?.[basketProduct.sku],
          quantity: basketProduct.quantity,
          loading: false,
          loadingCatchweight: false,
        };
        return acc;
      }, {});

      return {
        ...state,
        data,
        basketDetails: {
          ...successAction.basket,
        },
        dataState: DataState.SUCCESS,
        quantities,
      };

    case BasketActionTypes.CREATE_DELETED_PRODUCT_ACTION:
      const { product } = action as CreateDeletedProductAction;
      return {
        ...state,
        deletedFromBasket: [...state.deletedFromBasket, product],
      };

    case BasketActionTypes.REMOVE_DELETED_PRODUCT_ACTION: {
      const { sku } = action as RemoveDeletedProductAction;
      const newState = state.deletedFromBasket.filter(item => item.product.productUid !== sku);
      return {
        ...state,
        deletedFromBasket: [...newState],
      };
    }

    case BasketActionTypes.CLEAN_DELETED_PRODUCTS_ACTION: {
      return {
        ...state,
        deletedFromBasket: [],
      };
    }

    case BasketActionTypes.UPDATE_BASKET_ERROR: {
      const basketProduct = state.data.items.getByKey(action.sku);
      return {
        ...state,
        dataState: DataState.FAILED,
        quantities: {
          ...state.quantities,
          [action.sku]: {
            ...state.quantities[action.sku],
            quantity: basketProduct ? basketProduct.quantity : 0,
            loading: false,
            loadingCatchweight: false,
          },
        },
      };
    }

    case PreviousOrdersAction.ADD_ORDER_TO_BASKET_FAILURE:
    case BasketActionTypes.FETCH_BASKET_ERROR:
      return {
        ...state,
        dataState: DataState.FAILED,
      };

    case BasketActionTypes.DELETE_BASKET_SUCCESS:
      return {
        ...basketInitialState,
        dataState: DataState.SUCCESS,
      };

    case PreviousOrdersAction.ADD_ORDER_TO_BASKET_ERRORS:
      const addOrderToBasketErrorsAction = action as AddOrderToBasketErrorsAction;
      return {
        ...state,
        errors: {
          ...state.errors,
          addOrderToBasketErrors: addOrderToBasketErrorsAction.errors,
        },
      };

    case TrolleyActionTypes.DIETARY_PROFILE_ERROR:
    case TrolleyActionTypes.FETCH_BASKET_PRODUCTS_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          dietaryProfileError: {
            title: ProductErrorCode.DIETARY_PROFILE_ERROR,
            detail: ProductErrorCode.DIETARY_PROFILE_ERROR,
          },
        },
      };

    case BasketActionTypes.BASKET_SCOTTISH_LEGISLATION_ERROR:
      const scottishLegislationAction = action as BasketScottishLegislationErrorAction;
      return {
        ...state,
        dataState: DataState.FAILED,
        errors: {
          ...state.errors,
          scottishLegislation: {
            title: scottishLegislationAction.title,
            detail: scottishLegislationAction.detail,
          },
        },
      };

    case BasketActionTypes.BASKET_BULK_ORDER_LIMIT_EXCEEDED_ERROR:
      const bulkOrderLimitExceededAction = action as BasketBulkOrderLimitExceededErrorAction;
      return {
        ...state,
        dataState: DataState.FAILED,
        errors: {
          ...state.errors,
          bulkOrderLimitExceeded: {
            title: bulkOrderLimitExceededAction.title,
            detail: bulkOrderLimitExceededAction.detail,
          },
        },
      };

    case BasketActionTypes.BASKET_ITEM_QUANTITY_EXCEEDED_ERROR:
      const itemQuantityExceededErrorAction = action as BasketItemQuantityExceededErrorAction;
      return {
        ...state,
        dataState: DataState.FAILED,
        errors: {
          ...state.errors,
          itemQuantityExceeded: {
            title: itemQuantityExceededErrorAction.title,
            detail: itemQuantityExceededErrorAction.detail,
          },
        },
      };

    case BasketActionTypes.BASKET_MAXIMUM_LIMIT_EXCEEDED_ERROR:
      const totalQuantityExceededErrorAction = action as BasketMaximumLimitExceededErrorAction;
      return {
        ...state,
        dataState: DataState.FAILED,
        errors: {
          ...state.errors,
          maximumBasketLimitExceeded: {
            title: totalQuantityExceededErrorAction.payload.title,
            detail: totalQuantityExceededErrorAction.payload.detail,
            url: totalQuantityExceededErrorAction.payload.url,
          },
        },
      };

    case BasketActionTypes.CLEAR_BASKET_ERROR:
      const clearBasketErrorAction = action as ClearBasketErrorAction;
      return {
        ...state,
        errors: {
          ...state.errors,
          [clearBasketErrorAction.error]: undefined,
        },
      };

    case BasketActionTypes.CLEAR_ALL_BASKET_ERRORS:
      return {
        ...state,
        errors: {},
      };

    case ResponseErrorActionType.HTTP_UNAUTHORIZED_ERROR:
      return basketInitialState;

    default:
      return state;
  }
};
