import { getProductPosition, mapDomainProductToDataLayerProduct } from "./product";
import { createAnalyticsEvent, sendAnalyticsEvent } from "./analyticsEvent";
import {
  AnalyticsEvent,
  AnalyticsEventType,
  Basket,
  BasketProduct,
  PageDescription,
  PFMCategory,
  Product,
  QuantityType,
  CheckoutSummaryPageDescription,
  TrolleyPageDescription,
  UnitPriceAndSaving,
  OfferType,
  FulfilmentState,
} from "./types";
import { AdMetaData } from "../../common/ads";
import {
  BasketUpdateType,
  UnitOfMeasure,
  BasketProduct as DomainBasketProduct,
  Basket as DomainBasket,
  KG,
} from "../../domain/basket/basket.types";
import { ProductType, Product as DomainProduct } from "../../domain/product/product.types";
import { NectarOffer } from "../../services/nectar.types";
import { FavouriteSourceForAnalyticsTypes, FavouriteType } from "@favourites/Favourites.types";

export const calculateUnitPrices = (
  currentTotal: number,
  originalTotal: number,
  quantity: number
): UnitPriceAndSaving => {
  let unitPrice = 0;
  let originalUnitPrice = 0;
  let unitPriceSaving = 0;
  if (quantity > 0) {
    unitPrice = Math.round((currentTotal / quantity) * 100) / 100;
    originalUnitPrice = Math.round((originalTotal / quantity) * 100) / 100;
    unitPriceSaving = Math.round((originalUnitPrice - unitPrice) * 100) / 100;
  }
  return {
    unitPrice,
    originalUnitPrice,
    unitPriceSaving,
  };
};

export const mapDomainBasketProductToDataLayerBasketProduct = (
  product: DomainBasketProduct,
  pageDescription: PageDescription | string,
  isNectarOffer: boolean,
  nectarPoints: number,
  fulfilmentState?: FulfilmentState
): BasketProduct => {
  const quantityType = mapQuantityType(product.productType, product.unitOfMeasure);
  const quantityValue = quantityType === QuantityType.WEIGHT ? 1 : product.quantity;
  const unitPriceAndSaving = calculateUnitPrices(product.subTotal, Number(product.totalBasePrice), quantityValue);
  const pmfCategory = () => {
    if (pageDescription === TrolleyPageDescription) {
      return PFMCategory.TROLLEY;
    }
    if (pageDescription === CheckoutSummaryPageDescription) {
      return PFMCategory.CHECKOUT;
    }
    return PFMCategory.MINI_TROLLEY;
  };

  return {
    sku: product.sku,
    name: product.productName,
    finalPrice: product.subTotal,
    originalUnitPrice: unitPriceAndSaving.originalUnitPrice,
    unitPrice: unitPriceAndSaving.unitPrice,
    unitPriceSaving: unitPriceAndSaving.unitPriceSaving,
    priceType: product.unitOfMeasure,
    imageURL: product.image,
    isOnOffer: product.promotions.length > 0,
    isSpotlight: null,
    offerPromoId: null,
    offerDateStartTimeStamp: null,
    offerDateEndTimeStamp: null,
    isUnitAvailable: null,
    isWeightAvailable: null,
    isWeightRangeAvailable: null,
    isMultiVariantAvailable: null,
    list: pageDescription,
    position: null,
    isUserFavorite: null,
    favouriteSource: FavouriteSourceForAnalyticsTypes.NONE,
    quantityType,
    quantityValue,
    pfmCategory: pmfCategory(),
    parentSku: null,
    adId: null,
    adType: null,
    nectar_offer: isNectarOffer,
    offerType: setOfferType(product),
    nectarPoints,
    fulfilmentState,
    promotions: product.promotions,
  };
};

export const setOfferType = (product: DomainBasketProduct): string => {
  if (product.isYourNectarPrice) {
    return OfferType.YOUR_NECTAR_PRICE;
  } else if (product.promotions && product.promotions.length > 0) {
    const promotion = product.promotions[0];
    if (promotion.isNectarPrice) {
      return OfferType.NECTAR_PRICE;
    } else {
      return OfferType.TYPICAL_PRICE_REDUCTION;
    }
  }
  return OfferType.ORIGINAL_PRICE;
};

export const getDataLayerBasket = (basket: DomainBasket, nectarOffers: NectarOffer[], list: PageDescription) => {
  let totalNectarItems = 0;
  let totalNectarValue = 0;
  const products: Product[] = basket.items.map((product: DomainBasketProduct) => {
    const isNectarOffer = nectarOffers.some(offer => offer.skus.find(offerSku => offerSku === product.sku));
    const nectarOffer = nectarOffers.find(offer => offer.skus.find(offerSku => offerSku === product.sku));
    const nectarPoints = nectarOffer ? nectarOffer.points : 0;
    if (isNectarOffer) {
      totalNectarItems += 1;
      totalNectarValue += product.subTotal;
    }
    return mapDomainBasketProductToDataLayerBasketProduct(product, list, isNectarOffer, nectarPoints);
  });

  return {
    totalNectarValue,
    totalNectarItems,
    products,
  };
};

export const setBasket = (basket: DomainBasket, nectarOffers: NectarOffer[], list: PageDescription) => {
  const { totalNectarItems, totalNectarValue, products } = getDataLayerBasket(basket, nectarOffers, list);

  const dataLayerBasket: Basket = {
    totalNectarItems,
    totalNectarValue,
    totalItems: basket.itemCount,
    totalValue: basket.subTotal,
    products,
    orderId: basket.basketId,
  };

  (window as any).digitalData.basket = dataLayerBasket;

  const analyticsEvent: AnalyticsEvent = {
    eventName: AnalyticsEventType.BASKET_UPDATED,
  };

  sendAnalyticsEvent(analyticsEvent);
};

export const clearBasket = () => {
  const { digitalData } = window as any;
  if (digitalData) {
    digitalData.basket = undefined;
  }
};

export const mapQuantityType = (productType: ProductType, uom: UnitOfMeasure): QuantityType => {
  if (productType === ProductType.LOOSE && uom === KG) {
    return QuantityType.WEIGHT;
  }

  return QuantityType.UNITS;
};

export const basketUpdateTypeToAnalyticsEventTypeMap = {
  [BasketUpdateType.ADD]: AnalyticsEventType.ADD_TO_BASKET,
  [BasketUpdateType.SUBTRACT]: AnalyticsEventType.REMOVE_FROM_BASKET,
};

export const sendBasketUpdateEventFromProduct = (
  pageNumber: number,
  pageSize: number,
  product: DomainProduct,
  uom: UnitOfMeasure,
  quantityIncrement: number,
  updateType: BasketUpdateType,
  productUid: string,
  pageDescription: PageDescription | string,
  pfmCategory: PFMCategory | string,
  isNectarOffer: boolean,
  basketId: string,
  selectedCatchweight?: string,
  adMetaData?: AdMetaData,
  seasonalPosition?: number,
  fulfilmentState?: FulfilmentState
) => {
  const dataLayerBasketProduct = mapBasketProduct(
    pageNumber,
    pageSize,
    product,
    uom,
    quantityIncrement,
    productUid,
    pageDescription,
    pfmCategory,
    isNectarOffer,
    selectedCatchweight,
    adMetaData,
    seasonalPosition,
    fulfilmentState
  );

  (window as any).digitalData.basket.orderId = basketId;

  const analyticsEvent = createAnalyticsEvent(basketUpdateTypeToAnalyticsEventTypeMap[`${updateType}`], {
    ...dataLayerBasketProduct,
  });
  sendAnalyticsEvent(analyticsEvent);
};

export const mapBasketProduct = (
  pageNumber: number,
  pageSize: number,
  product: DomainProduct,
  uom: UnitOfMeasure,
  quantityIncrement: number,
  productUid: string,
  pageDescription: PageDescription | string,
  pfmCategory: PFMCategory | string,
  isNectarOffer: boolean,
  selectedCatchweight?: string,
  adMetaData?: AdMetaData,
  seasonalPosition?: number,
  fulfilmentState?: FulfilmentState
): BasketProduct => {
  const productPosition =
    seasonalPosition !== undefined && seasonalPosition > 0
      ? seasonalPosition
      : getProductPosition(pageNumber, pageSize, product);

  const favouriteSource =
    (product.favouriteSource && mapFavouriteSource[product.favouriteSource]) || FavouriteSourceForAnalyticsTypes.NONE;

  const mappedProduct = mapDomainProductToDataLayerProduct(
    productPosition,
    product,
    pfmCategory,
    pageDescription,
    selectedCatchweight,
    productUid,
    adMetaData,
    isNectarOffer
  );

  const quantityType = mapQuantityType(product.productType, uom);

  const dataLayerBasketProduct: BasketProduct = {
    ...mappedProduct,
    quantityType,
    quantityValue: quantityIncrement,
    favourite: product.favourite,
    favouriteSource,
    fulfilmentState,
    promotions: product.promotions,
  };

  return dataLayerBasketProduct;
};

export const mapFavouriteSource = {
  [FavouriteType.MANUALLY_ADDED]: FavouriteSourceForAnalyticsTypes.MANUALLY_ADDED,
  [FavouriteType.FREQUENTLY_BOUGHT]: FavouriteSourceForAnalyticsTypes.FREQUENTLY_BOUGHT,
};

export const sendBasketUpdateEventFromBasket = (
  product: DomainBasketProduct,
  uom: UnitOfMeasure,
  quantityIncrement: number,
  updateType: BasketUpdateType,
  pageDescription: PageDescription,
  isNectarOffer: boolean,
  basketId: string,
  nectarPoints: number,
  fulfilmentState?: FulfilmentState
) => {
  const quantityType = mapQuantityType(product.productType, uom);
  const quantityValue = quantityType === QuantityType.WEIGHT ? 1 : product.quantity;
  const price = calculateUnitPrices(product.subTotal, Number(product.totalBasePrice), quantityValue);

  const mappedBasketProduct = mapDomainBasketProductToDataLayerBasketProduct(
    product,
    pageDescription,
    isNectarOffer,
    nectarPoints,
    fulfilmentState
  );
  const dataLayerBasketProduct: BasketProduct = {
    ...mappedBasketProduct,
    quantityType,
    quantityValue: quantityIncrement,
    finalPrice: price.unitPrice,
  };

  (window as any).digitalData.basket.orderId = basketId;

  const analyticsEvent = createAnalyticsEvent(
    basketUpdateTypeToAnalyticsEventTypeMap[`${updateType}`],
    dataLayerBasketProduct
  );

  sendAnalyticsEvent(analyticsEvent);
};
