import { Dispatch } from "redux";
import { State } from "../../common/store";
import { mergeQueryParams } from "./productControls";
import { ProductControlsActionTypes, ProductControlsChangedAction } from "./productControls.types";
import { createInternalNavigationAction } from "../../common/middleware/navigationMiddleware";
import { isFindabilitySearchRoute } from "../Header/SearchBar/search.middleware";
import { Filter, ProductListControlsQueryParams } from "../../domain/product/product.types";
import {
  createNewPathWithNewFilters,
  getUnfilteredBrowseURL,
  getUnfilteredEventsURL,
  getUnfilteredMealDealsURL,
} from "../../views/filters.utils";
import { createNewPaginatedURL } from "../../views/Browse/Browse.utils";
import { PageVariants } from "../../views/SearchResultsFindability/SearchResultsFindability.types";
import { getUnfilteredBrandedURL } from "../../views/BrandedPage/BrandedPage.utils";

export const searchEngineFriendlyFiltersControls = (newFilters: ProductListControlsQueryParams, page: PageVariants) => {
  return (dispatch: Dispatch, getState: () => State): void => {
    const { pathname } = getState().router.location;
    const filtersToAdd: Filter[] = newFilters.filters || [];
    const newURL = createNewPathWithNewFilters(pathname, filtersToAdd, page);

    dispatch(createInternalNavigationAction(newURL));
    dispatch(productControlsChangedActionCreator(newFilters));
  };
};

export const searchEngineFriendlyPaginationControls = (
  newOptions: ProductListControlsQueryParams,
  pageVariant: PageVariants
) => {
  return (dispatch: Dispatch, getState: () => State): void => {
    const { pathname } = getState().router.location;
    const newPagePosition: number = newOptions.pageNumber || 1;
    const newURL = createNewPaginatedURL(pathname, newPagePosition, pageVariant);

    dispatch(createInternalNavigationAction(newURL));
    dispatch(productControlsChangedActionCreator(newOptions));
  };
};

export const changedProductControlsActionCreator = (newQueryParams: ProductListControlsQueryParams) => {
  return (dispatch: Dispatch, getState: () => State): void => {
    const searchString = mergeQueryParams(getState().router.location.search, mapControls(newQueryParams));
    dispatch(createInternalNavigationAction(`${getState().router.location.pathname}?${searchString}`));
    dispatch(productControlsChangedActionCreator(newQueryParams));
  };
};

export const favouritesChangedProductControlsActionCreator = (
  newQueryParams: ProductListControlsQueryParams,
  location: string
) => {
  return (dispatch: Dispatch, getState: () => State): void => {
    const searchString = mergeQueryParams(getState().router.location.search, mapControls(newQueryParams));
    if (isFindabilitySearchRoute(getState().router.location.pathname)) {
      const { pathname, search } = getState().router.location;
      dispatch(createInternalNavigationAction(`${pathname}${search}`));
    } else {
      if (searchString) {
        dispatch(createInternalNavigationAction(`${getState().router.location.pathname}?${searchString}${location}`));
      } else {
        dispatch(createInternalNavigationAction(`${getState().router.location.pathname}${location}`));
      }
    }
    dispatch(productControlsChangedActionCreator(newQueryParams));
  };
};

export const searchEngineFriendlyReset = (page: PageVariants) => {
  return (dispatch: Dispatch, getState: () => State): void => {
    const { pathname } = getState().router.location;
    if (page === PageVariants.BROWSE) dispatch(createInternalNavigationAction(getUnfilteredBrowseURL(pathname)));
    else if (page === PageVariants.BRANDED) dispatch(createInternalNavigationAction(getUnfilteredBrandedURL(pathname)));
    else if (page === PageVariants.EVENTS_BUILDER || page === PageVariants.FEATURES)
      dispatch(createInternalNavigationAction(getUnfilteredEventsURL(pathname)));
    else if (page === PageVariants.MEAL_DEAL_BUILDER)
      dispatch(createInternalNavigationAction(getUnfilteredMealDealsURL(pathname)));
    else dispatch(productControlsChangedActionCreator({}));
  };
};
export const resetProductControlsActionCreator = () => {
  return (dispatch: Dispatch, getState: () => State): void => {
    const searchString = removeFiltersQueryParams(getState().router.location.search);
    dispatch(createInternalNavigationAction(`${getState().router.location.pathname}?${searchString}`));
    dispatch(productControlsChangedActionCreator({}));
  };
};

function removeFiltersQueryParams(queryString: string): string {
  const params: URLSearchParams = new URLSearchParams(queryString);
  const newParams = new URLSearchParams();

  params.forEach((val, key) => {
    if (!key.startsWith("filter") || key.startsWith("filters[keyword]")) {
      newParams.set(key, val);
    }
  });
  return decodeURIComponent(newParams.toString());
}

const mapControls = (params: ProductListControlsQueryParams): ProductListControlsQueryParams => {
  const controls: ProductListControlsQueryParams = {};
  if (params.pageNumber) {
    controls.pageNumber = params.pageNumber;
  }
  if (params.pageSize) {
    controls.pageSize = params.pageSize;
  }
  if (params.sort) {
    controls.sort = params.sort;
  }

  if (params.filters) {
    controls.filters = params.filters;
  }
  return controls;
};

export const productControlsChangedActionCreator = (
  params: ProductListControlsQueryParams
): ProductControlsChangedAction => ({
  type: ProductControlsActionTypes.PRODUCT_CONTROLS_UPDATE,
  params,
});
