import {
  CacheResponse,
  NectarDestinationPageActions,
  NectarDestinationPageRequestIds,
  UpdateAddAllToTrolleyDataState,
  UpdateReqDataState,
} from "./actions/types";
import { DataState } from "../../../common/dataState";
import { Product } from "../../../domain/product/product.types";

export type NectarDestinationPageState = {
  addAllBonusPointsToBasketDataState: DataState;
  addAllYourNectarPricesToBasketDataState: DataState;
  carousels: {
    [NectarDestinationPageRequestIds.BONUS_POINTS_PRODUCTS]: {
      dataState: DataState;
      products: Product[];
    };
    [NectarDestinationPageRequestIds.FAVOURITES_PRODUCTS]: {
      dataState: DataState;
      products: Product[];
    };
    [NectarDestinationPageRequestIds.POPULAR_PRODUCTS]: {
      dataState: DataState;
      products: Product[];
    };
    [NectarDestinationPageRequestIds.SPONSORED_POPULAR_PRODUCTS]: {
      dataState: DataState;
      products: Product[];
    };
    [NectarDestinationPageRequestIds.STATIC_SKU_LIST_PRODUCTS]: {
      dataState: DataState;
      products: Product[];
    };
    [NectarDestinationPageRequestIds.YNP_PRODUCTS]: {
      dataState: DataState;
      products: Product[];
    };
  };
};

export type ActionTypes = UpdateAddAllToTrolleyDataState | CacheResponse | UpdateReqDataState;

export const initialState = {
  addAllBonusPointsToBasketDataState: DataState.UNKNOWN,
  addAllYourNectarPricesToBasketDataState: DataState.UNKNOWN,
  carousels: {
    [NectarDestinationPageRequestIds.BONUS_POINTS_PRODUCTS]: {
      dataState: DataState.UNKNOWN,
      products: [],
    },
    [NectarDestinationPageRequestIds.FAVOURITES_PRODUCTS]: {
      dataState: DataState.UNKNOWN,
      products: [],
    },
    [NectarDestinationPageRequestIds.POPULAR_PRODUCTS]: {
      dataState: DataState.UNKNOWN,
      products: [],
    },
    [NectarDestinationPageRequestIds.SPONSORED_POPULAR_PRODUCTS]: {
      dataState: DataState.UNKNOWN,
      products: [],
    },
    [NectarDestinationPageRequestIds.STATIC_SKU_LIST_PRODUCTS]: {
      dataState: DataState.UNKNOWN,
      products: [],
    },
    [NectarDestinationPageRequestIds.YNP_PRODUCTS]: {
      dataState: DataState.UNKNOWN,
      products: [],
    },
  },
};

export const nectarDestinationPageReducer = (
  state: NectarDestinationPageState = initialState,
  action: ActionTypes
): NectarDestinationPageState => {
  switch (action.type) {
    case NectarDestinationPageActions.UPDATE_ADD_ALL_BONUS_OFFERS_TO_BASKET_DATA_STATE:
      return {
        ...state,
        addAllBonusPointsToBasketDataState: action.dataState,
      };

    case NectarDestinationPageActions.UPDATE_ADD_ALL_YOUR_NECTAR_PRICES_TO_BASKET_DATA_STATE:
      return {
        ...state,
        addAllYourNectarPricesToBasketDataState: action.dataState,
      };

    case NectarDestinationPageActions.UPDATE_REQ_DATA_STATE: {
      return {
        ...state,
        carousels: {
          ...state.carousels,
          [action.requestId]: {
            ...state.carousels[action.requestId],
            dataState: action.dataState,
          },
        },
      };
    }

    case NectarDestinationPageActions.CACHE_RESPONSE: {
      return {
        ...state,
        carousels: {
          ...state.carousels,
          [action.requestId]: {
            products: action.products,
            dataState: DataState.SUCCESS,
          },
        },
      };
    }

    default:
      return state;
  }
};
