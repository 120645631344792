import { NewRelicClientType } from "./utils/newRelicClient/newRelicClient";
import { TaggstarClient } from "./utils/taggstar";

declare global {
  interface Window {
    foo: { bar: string };
    newrelic?: NewRelicClientType;
    taggstar?: TaggstarClient;
    taggstarReady?: boolean;
    digitalData?: any;
    Rokt?: {
      createLauncher: (settings: Record<string, string | boolean>) => Promise<{
        selectPlacements: (config: { attributes: Record<string, string> }) => Promise<void>;
        terminate: () => Promise<void>;
      }>;
    };
    BV?: {
      ui: (type: string, action: string, options: Record<string, any>) => void;
    };
  }

  var today: string;
  var tomorrow: string;
  var yesterday: string;
}

export const getGlobal = (name: string): any => {
  return typeof window !== "undefined" ? (window as { [key: string]: any })[`${name}`] : null;
};
