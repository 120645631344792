import { RecipeProduct } from "../../components/RecipesAddToBasketModal/ATBModal.type";
import { WithDataState } from "../../common/dataState";
import { ArticleRecipe } from "../Articles/Article.types";

export enum RecipeActionType {
  RECIPE_REQUEST = "RECIPE_REQUEST",
  RECIPE_SUCCESS = "RECIPE_SUCCESS",
  RECIPE_FAILURE = "RECIPE_FAILURE",
}

export interface RecipeState extends WithDataState {
  recipe: Recipe;
}

export interface CreateRecipeSuccessActionType {
  type: RecipeActionType;
  recipe: Recipe;
}

export interface CreateRecipeFailureActionType {
  type: RecipeActionType;
  message: string;
}

export interface IngredientsData {
  key: string;
  values: Array<string>;
}

export interface MethodData {
  key?: string;
  values: Array<string>;
}

export interface Nutrition {
  carbs: string;
  carbs_pc_intake: string;
  energy_kcal: string;
  energy_kj: string;
  energy_pc_intake: string;
  energy_per_100: string;
  fat: string;
  fat_col: string;
  fat_pc_intake: string;
  fibre: string;
  fibre_pc_intake: string;
  protein: string;
  protein_pc_intake: string;
  salt: string;
  salt_col: string;
  salt_pc_intake: string;
  saturates: string;
  saturates_col: string;
  saturates_pc_intake: string;
  sugar: string;
  sugar_col: string;
  sugar_pc_intake: string;
}

export interface Schema {
  cookTime?: string;
  ingredients: string[];
  prepTime?: string;
  method: string;
  recipeCategory?: string;
  recipeCuisine?: string;
  readyTime?: string;
  recipeYield?: string;
  keywords?: Array<string>;
  logoImage?: string;
}

export interface Recipe {
  recipeId: string;
  // Should be removed once renamed in backend
  id?: string;
  name: string;
  serves: string;
  cookingTime?: string;
  description?: string;
  ingredients?: IngredientsData[];
  prepTime?: string;
  readyIn?: string;
  image?: string;
  method: MethodData[];
  recipePills: string[];
  nutrition: Nutrition;
  schema: Schema;
  videoUrl?: string;
  metaTitle?: string;
  metaDescription?: string;
  relatedRecipes?: ArticleRecipe[];
  author?: string;
  imageAltText?: string;
  createdDate?: string;
  publishDate?: string;
  updateDate?: string;
}

export interface RecipeService {
  id: string;
  name: string;
  description: string;
  ingredients: IngredientsData[];
  method: MethodData[];
  author?: string;
  cookingTime?: string;
  prepTime?: string;
  readyIn?: string;
  serves?: string;
  image?: string;
  imageAltText?: string;
  recipePills: string[];
  nutrition?: Nutrition;
  schema: Schema;
  createdDate?: string;
  ccrecipeId: string;
  videoUrl?: string;
  metaTitle?: string;
  metaDescription?: string;
  relatedRecipes?: ArticleRecipe[];
  publishDate?: string;
  updateDate?: string;
}

export interface RecipeResponseService {
  response: RecipeService;
}

export interface RecipeShoppability {
  ingredientCount: number;
  offerCount: number;
  price: number;
  serves: number;
  shoppable: boolean;
}

export interface RecipeProductLookup {
  id: string;
  // recipe_name: string;
  products: RecipeProduct[];
}

export interface RecipeProductResponse {
  response: {
    recipes: RecipeProductLookup[];
    commonProducts: RecipeProduct[];
    sharedProducts: RecipeProduct[];
  };
}

export enum TabKey {
  RECIPE_INGREDIENTS = "Ingredients",
  RECIPE_METHOD = "Method",
  RECIPE_REVIEWS = "Reviews",
}
