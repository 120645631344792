import { DeliveryDate } from "../../domain/order/order.types";

export enum OrderGroup {
  UPCOMING = "UPCOMING",
  PREVIOUS = "PREVIOUS",
}

export interface DisplayOrders {
  upcoming: DisplayOrder[];
  previous: DisplayOrder[];
}

export interface DisplayOrder {
  formattedTotal: string;
  orderNumber: string;
  deliveryType: string;
  formattedDate: DeliveryDate;
  viewOrderLink: string;
  amendOrderLink: string;
  isAmendable: boolean;
  isScheduled: boolean;
  isCancellable: boolean;
  address: string;
}

export interface NextScheduledOrder {
  deliveryType: string;
  formattedDate: DeliveryDate;
  changeOrderLink: string;
  isAmendable: boolean;
  isInAmendMode: boolean;
}

export interface OrdersPageControlProps {
  pageNumber: number;
  pageLast: number;
  recordCount: number;
}
