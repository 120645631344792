import React from "react";
import { BasicModal } from "./Modal.component";
import { useDispatch, useSelector } from "react-redux";
import { stopAmendingOrder } from "../../../domain/order/order.actions";
import { SlotDetails } from "../Modal.types";
import { useSlotReservation } from "../../../domain/slot/slotReservation";
import { selectOriginalSlotDetails } from "../Modal.selectors";
import { matchesPath, urls } from "../../../routes";
import { State } from "../../../common/store";

export interface AmendDiscardChangesModalProps {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}

export const AmendDiscardChangesModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}) => {
  const { originalSlotReservation } = useSlotReservation();
  const slotDetails: SlotDetails | undefined = selectOriginalSlotDetails(originalSlotReservation);
  const dispatch = useDispatch();
  const pathname = useSelector((state: State) => state.router.location.pathname);

  return (
    <BasicModal
      title="Discard changes to your order?"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      primaryButtonText="Discard Changes"
      onPrimaryButtonClick={async () => {
        await dispatch(stopAmendingOrder());
        if (matchesPath(pathname, urls.CHANGES_TO_TROLLEY_GOLUI)) {
          window.location.assign(urls.GROCERIES_URL);
        }
        setIsOpen(false);
      }}
      secondaryButtonText="Keep Changes"
      onSecondaryButtonClick={() => {
        setIsOpen(false);
      }}
      className="modal-width-500"
    >
      {slotDetails && (
        <p>
          <span className="modal-discard-changes__description ln-u-margin-top*2" data-testid="basic-modal-description">
            If you discard changes, your order will revert back to the original, without amendments. Your original order
            is:
          </span>
          <span className="modal-discard-changes__subtitle ln-u-margin-top*2">
            {slotDetails!.orderType === "click_and_collect" ? "Collection time" : "Delivery time"}
          </span>
          <span className="modal-discard-changes__description">{slotDetails!.time}</span>
          <span className="modal-discard-changes__subtitle ln-u-margin-top*2">
            {slotDetails!.orderType === "click_and_collect" ? "Collection address" : "Delivery address"}
          </span>
          <span className="modal-discard-changes__description">{slotDetails!.address}</span>
        </p>
      )}
    </BasicModal>
  );
};
