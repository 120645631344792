import React, { ReactElement, ReactNode } from "react";

type Function<T> = () => T;

export type ConditionalRenderProps<T> = {
  predicate: Function<T> | T;
  children: ReactNode;
};

type ConditionalRenderType = React.FC<ConditionalRenderProps<boolean>>;

export const ConditionalRender: ConditionalRenderType = ({ predicate, children }) => {
  let condition;
  if (typeof predicate === "boolean") {
    condition = predicate;
  }
  if (typeof predicate === "function") {
    condition = predicate();
  }
  return condition ? (children as ReactElement<any>) : null;
};

ConditionalRender.displayName = "ConditionalRender";
