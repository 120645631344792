import { Product } from "../../domain/product/product.types";
import { AnalyticsEvent, Attributes, ExperimentVariantEvent } from "./types";
import { sendAnalyticsEvent, addExperimentEvent } from "./analyticsEvent";
import { EXPERIMENTS } from "../analytics/digitalData";

export const deleteMessage: AnalyticsEvent = {
  eventName: "favouritedeleteaction",
  eventCategory: "Delete favourite action",
  eventAction: "Total count of users clicking on the heart icon to remove a favourite",
  eventLabel: "Total count of users clicking on the heart icon to remove a favourite",
};

export const seasonalDeleteMessage: AnalyticsEvent = {
  eventName: "remove",
  eventCategory: "Delete seasonal favourite action",
  eventAction: "User clicked on the heart icon to remove a seasonal favourite",
  eventLabel: "seasonalFavourites",
};

export const favouritesAlternativesModalCloseEvent: AnalyticsEvent = {
  eventName: "ev_favouritesAlternativesModalClose",
  eventCategory: "Favourites",
  eventAction: "Close alternatives modal",
};

export const favouritesSeeAlternativesButtonClickEvent: AnalyticsEvent = {
  eventName: "ev_favouritesSeeAlternativesButtonClick",
  eventCategory: "Favourites",
  eventAction: "Click",
  eventLabel: "See alternatives button",
};

export const favouritesProductAPICallEvent: AnalyticsEvent = {
  eventName: "ev_favouritesProductApiCall",
  eventLabel: "See alternatives button (available|unavailable)",
  eventCategory: "Favourites",
  eventAction: "Product API call",
  stockout_count: "0",
  isSeeAlternativesButtonAvailable: "false",
  non_interaction: true,
};

export const sendFavouritesProductAPICallEvent = (products: Product[]): void => {
  const hasAlternativesButtonAvailable = products.filter(p => p.alternatives?.length).length > 0;
  const analyticsEvent: AnalyticsEvent = {
    ...favouritesProductAPICallEvent,
    eventLabel: `See alternatives button ${hasAlternativesButtonAvailable ? "available" : "unavailable"}`,
    stockout_count: String(products.filter(p => p.available === false).length),
    isSeeAlternativesButtonAvailable: String(hasAlternativesButtonAvailable),
  };

  sendAnalyticsEvent(analyticsEvent);
};

export const sendFavouritesDeleteEvent = (): void => {
  const analyticsEvent: AnalyticsEvent = { ...deleteMessage };
  sendAnalyticsEvent(analyticsEvent);
};

export const sendFavouritesSeasonalDeleteEvent = (): void => {
  const analyticsEvent: AnalyticsEvent = { ...seasonalDeleteMessage };
  sendAnalyticsEvent(analyticsEvent);
};

export const sendFavouriteDeleteModalEvent = (
  isFavouritesModalFirstInteraction: boolean,
  disabledModal: boolean
): void => {
  const eventActionMessage = `DeleteActionEvent | ${
    isFavouritesModalFirstInteraction ? "Is" : "Isn't"
  } first interaction | ${disabledModal ? "Did" : "Didn't"} click on 'Don't show this message again' checkbox |`;
  const analyticsEvent: AnalyticsEvent = {
    ...deleteMessage,
    eventAction: eventActionMessage,
    eventLabel: eventActionMessage,
  };
  sendAnalyticsEvent(analyticsEvent);
};

export const sendFavouriteDeleteOneClickEvent = (): void => {
  const action = `DeleteActionEvent | Isn't first interaction | Did click on 'Don't show this message again' checkbox |`;
  const analyticsEvent: AnalyticsEvent = {
    ...deleteMessage,
    eventAction: action,
    eventLabel: action,
  };
  sendAnalyticsEvent(analyticsEvent);
};

export const sendFavouritesAlternativeModalClickEvent = (action: "open" | "close"): void => {
  switch (action) {
    case "close":
      sendAnalyticsEvent(favouritesAlternativesModalCloseEvent);
      break;
    case "open":
      sendAnalyticsEvent(favouritesSeeAlternativesButtonClickEvent);
      break;
  }
};

export const sendFavouriteOauthCTAClickEvent = (): void => {
  const action = `sendFavouriteOauthCTAClickEvent`;
  const analyticsEvent: AnalyticsEvent = {
    eventName: action,
    eventCategory: `Send Favourite Oauth CTA Click Event`,
    eventAction: `Total count of users clicking on the explore popular favourites on the ready to start shopping page`,
    eventLabel: `Total count of users clicking on the explore popular favourites on the ready to start shopping page`,
  };
  sendAnalyticsEvent(analyticsEvent);
};

export const favouriteOauthCTAPageLoadEventControl = (): void => {
  const action = `favouriteOauthCTAPageLoadEventCONTROL`;
  const analyticsEvent: AnalyticsEvent = {
    eventName: action,
    eventCategory: `Send Favourite Oauth Page Load Event`,
    eventAction: `Total count of users visiting the ready to start shopping page - CONTROL`,
    eventLabel: `Total count of users visiting the ready to start shopping page - CONTROL`,
  };
  sendAnalyticsEvent(analyticsEvent);
};

export const favouriteOauthCTAPageLoadEventVariant = (): void => {
  const action = `favouriteOauthCTAPageLoadEventVARIANT`;
  const analyticsEvent: AnalyticsEvent = {
    eventName: action,
    eventCategory: `Send Favourite Oauth Page Load Event`,
    eventAction: `Total count of users visiting the ready to start shopping page - VARIANT`,
    eventLabel: `Total count of users visiting the ready to start shopping page - VARIANT`,
  };
  sendAnalyticsEvent(analyticsEvent);
};

export const addToFavouritesExperimentVariantEvent = (featureFlag: Object): void => {
  const isFavouriteFeatureTourEnabled = featureFlag["feature_tour"];
  const analyticsEvent: ExperimentVariantEvent = {
    name: EXPERIMENTS.Favourites_Test_Group_3,
    variant: `${featureFlag ? (isFavouriteFeatureTourEnabled ? 2 : 3) : 1}`,
  };

  addExperimentEvent(analyticsEvent);
};

export const startShoppingAnalyticsEvent = (): void => {
  const action = `NoFavouritesStartShoppingEvent`;
  const analyticsEvent: AnalyticsEvent = {
    eventName: action,
    eventCategory: `No Favourites Start Shopping Action`,
    eventAction: `Total count of users clicking on the start shopping button on No Favourites page`,
    eventLabel: `Total count of users clicking on the start shopping button on No Favourites page`,
  };
  sendAnalyticsEvent(analyticsEvent);
};

export const fetchSeasonalFavouritesEvent = (products: Product[]): void => {
  const attributes: Attributes = {
    seasonalProductCount: products.length,
  };
  const action = `seasonalFavourites`;
  const analyticsEvent: AnalyticsEvent = {
    eventAction: "view",
    eventName: action,
    eventCategory: "fetch seasonal favourites action",
    eventLabel: "Users that have called the seasonal favourites endpoint in order to get seasonal favourites list",
    attributes,
  };
  sendAnalyticsEvent(analyticsEvent);
};
