import { Result } from "../http/request";
import { AnyAction, Dispatch } from "redux";
import { ResponseErrorActionType } from "../types";

export const handleServerError = (result: Result<any>) => (dispatch: Dispatch<AnyAction>) => {
  if (result.isServerError()) {
    dispatch({
      type: ResponseErrorActionType.HTTP_SERVER_ERROR,
    });
  }
};

export const handleUnauthorized =
  (result: Result<any>) =>
  (dispatch: Dispatch<AnyAction>): void => {
    if (result.isUnauthorised()) {
      dispatch({
        type: ResponseErrorActionType.HTTP_UNAUTHORIZED_ERROR,
      });
    }
  };
