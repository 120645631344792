import { Dispatch } from "redux";
import { YnpActions, YnpActionTypes } from "./ynp.types";
import { fetchYnpOptIns as fetchOptIns, optInForAllProducts as optIn } from "../../services/nectar";
import { SlotReservation } from "../slot/slotReservation.types";
import { getDateFromSlotStartTime } from "../slot/slotReservation";
import { State } from "../../common/store";
import { refetchYnpProducts } from "../../views/NectarDestinationPage/state/actions/refetchYnpProducts/refetchYnpProducts";

export const fetchYnpOptIns = (slotReservation: SlotReservation) => async (dispatch: Dispatch<YnpActions>) => {
  dispatch({ type: YnpActionTypes.FETCH_PENDING });

  const storeId = slotReservation?.storeIdentifier;
  const slotDate = getDateFromSlotStartTime(slotReservation.reservationStartTime);

  if (!slotDate) {
    dispatch({ type: YnpActionTypes.FETCH_NOT_SUCCESSFUL });
    console.error("Can't fetch YNP opt ins: Slot date not known");
    return;
  }

  if (!storeId) {
    dispatch({ type: YnpActionTypes.FETCH_NOT_SUCCESSFUL });
    console.error("Can't fetch YNP opt ins: Store id not known");
    return;
  }

  const result = await fetchOptIns({
    storeId,
    slotDate,
  });

  if (result.isSuccess()) {
    dispatch({
      type: YnpActionTypes.FETCH_SUCCESSFUL,
      payload: {
        optedIn: result.data.opted_in,
        notOptedIn: result.data.not_opted_in,
      },
    });
  } else {
    dispatch({ type: YnpActionTypes.FETCH_NOT_SUCCESSFUL });
  }
};

export const optInForAllProducts = () => async (dispatch: Dispatch<YnpActions>, getState: () => State) => {
  dispatch({ type: YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_PENDING });

  const store = getState();
  const slotReservation = store.slotReservation.slotReservation;

  const storeId = slotReservation?.storeIdentifier;
  const slotDate = getDateFromSlotStartTime(slotReservation.reservationStartTime);

  if (!slotDate) {
    console.error("Can't opt in to YNPs: Slot date not known");
    return;
  }

  if (!storeId) {
    console.error("Can't opt in to YNPs: Store id not known");
    return;
  }

  const result = await optIn({ storeId, slotDate });

  // The nectar API always returns a successful response even if there are errors.
  // It just puts the errors into an array, so we need to check for errors.
  const noErrors = result.isSuccess() && result.data.offer_response_failures.length === 0;

  if (noErrors) {
    dispatch({ type: YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_SUCCESSFUL });
    dispatch(refetchYnpProducts());
  } else {
    dispatch({ type: YnpActionTypes.OPT_IN_FOR_ALL_PRODUCTS_NOT_SUCCESSFUL });
  }
};
