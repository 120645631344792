const golProductPathSegment = "/gol-ui/product/";

/**
 * Checks the window for any path segments in the url that represent a category.
 * If some exist, they are removed.
 * This is because one product can appear under several URLs if they appear in multiple categories e.g.
 * https://www.sainsburys.co.uk/gol-ui/product/large-bar-chocolate/cadbury-dairy-milk-200g (category: large-bar-chocolate)
 * https://www.sainsburys.co.uk/gol-ui/product/all-price-lock/cadbury-dairy-milk-200g (category: all-price-lock)
 * https://www.sainsburys.co.uk/gol-ui/product/foodcupboard-essentials/cadbury-dairy-milk-200g (category: foodcupboard-essentials)
 * https://www.sainsburys.co.uk/gol-ui/product/cadbury-dairy-milk-200g (category: none)
 *
 * All of the above urls are for the same product.
 *
 * @returns {string} The canonical URL without any category path segments e.g.
 * https://www.sainsburys.co.uk/gol-ui/product/large-bar-chocolate/cadbury-dairy-milk-200g -> https://www.sainsburys.co.uk/gol-ui/product/cadbury-dairy-milk-200g
 *
 * Note: we could not use a regular expression for security reasons: https://github.com/eslint-community/eslint-plugin-security/blob/main/docs/regular-expression-dos-and-node.md
 */

export const getPDPCanonical = () => {
  const pathname = window.location.pathname;

  // productPathSegment can never not have a value because the PDP throws an error before getting to this point if there was an issue fetching the product.
  const productPathSegment = pathname.split("/").pop()!;

  return window.location.origin + golProductPathSegment + productPathSegment;
};
