import { Action as ReduxAction } from "redux";
import { mapProducts } from "../../domain/product/product";
import * as domain from "../../domain/product/product.types";
import { getCachedSlotReservation } from "../../domain/slot/slotReservation";
import { fetchDietaryWarnings } from "../../components/DietaryProfile/DietaryWarning/dietaryWarning.actions";
import { getProductEANs } from "../../domain/product/product.types";
import { AppThunkAction, AppThunkDispatch } from "../types";
import { fetchProductRecommendations, fetchProductsByUids } from "../../services/product";
import { ProductRecommendationsPlacement } from "../../services/product.types";

export enum RecommendationsActionTypes {
  FETCH_RECOMMENDATIONS_SUCCESS = "FETCH_RECOMMENDATIONS_SUCCESS",
}

export type FetchRecommendationsSuccessAction = {
  type: RecommendationsActionTypes | ReduxAction;
  recommendations: domain.Product[];
};

export const fetchRecommendationsSuccessActionCreator = (
  recommendations: domain.Product[]
): FetchRecommendationsSuccessAction => ({
  type: RecommendationsActionTypes.FETCH_RECOMMENDATIONS_SUCCESS,
  recommendations,
});

export const loadKrang = (isSlotLocked: boolean): AppThunkAction => {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    const { storeIdentifier, slotDate, slotDateTime, flexiStores, reservationType } = getCachedSlotReservation() ?? {};
    const krangRecommendations = await fetchProductRecommendations(ProductRecommendationsPlacement.COMPLEMENTARY, "18");

    if (krangRecommendations.isSuccess()) {
      const productRecommendations = await fetchProductsByUids(
        krangRecommendations.data.products.map(krangProduct => krangProduct.product_uid),
        [],
        true,
        true,
        storeIdentifier,
        slotDate,
        slotDateTime,
        reservationType,
        isSlotLocked,
        flexiStores
      );

      if (productRecommendations.isSuccess()) {
        //filter existing favourite products out of krang
        const recommendedProducts = productRecommendations.data
          ? mapProducts(
              domain.ProductSource.KRANG,
              productRecommendations.data.products.filter(product => !product.is_favourite)
            )
          : [];

        dispatch(fetchRecommendationsSuccessActionCreator(recommendedProducts));
        dispatch(fetchDietaryWarnings(getProductEANs(recommendedProducts)));
      }
    }
  };
};
