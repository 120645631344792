import React, { useMemo } from "react";
import { FilledButton } from "@jsluna/react";
import { OutlinedButton } from "@jsluna/button";

export enum ButtonTypes {
  FILLED = "FILLED",
  OUTLINED = "OUTLINED",
}

type ReloadButtonProps = {
  testId?: string;
  className?: string;
  onClick?: () => void;
  buttonType?: ButtonTypes;
};

const ButtonComponents = {
  [ButtonTypes.FILLED]: FilledButton,
  [ButtonTypes.OUTLINED]: OutlinedButton,
};

export const ReloadButton = ({
  testId = "error-button",
  className = "error-page__button--primary",
  onClick = () => window.location.reload(),
  buttonType = ButtonTypes.FILLED,
}: ReloadButtonProps) => {
  const ButtonComponent = useMemo(() => ButtonComponents[buttonType], [buttonType]);

  return (
    <ButtonComponent data-testid={testId} className={className} onClick={onClick}>
      Try again
    </ButtonComponent>
  );
};

ReloadButton.displayName = "ReloadButton";
