import clsx from "clsx";
import { Theme } from "../../MealDealBuilder.types";

interface GetThemeClassesParams {
  theme: Theme | undefined;
}

export const getThemeClasses = ({ theme }: GetThemeClassesParams) => {
  const themeClasses = clsx({
    "theme-white": theme === "light",
    [`theme-${theme}`]: !!theme && theme !== Theme.LIGHT,
  });

  return { themeClasses };
};
