/* eslint-disable security/detect-non-literal-fs-filename */
// TODO: re-enable the above rule when https://github.com/nodesecurity/eslint-plugin-security/pull/89 is merged
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { AnalyticsEvent } from "../../common/dataLayer/types";

// Props method to make either navigationCallBack or url required
type TrackAndNavigateProps =
  | {
      eventData: AnalyticsEvent | { eventInfo: AnalyticsEvent };
      children: JSX.Element;
      className?: string;
      navigationCallBack: (...args: any) => void;
      url?: string;
    }
  | {
      eventData: AnalyticsEvent | { eventInfo: AnalyticsEvent };
      children: JSX.Element;
      className?: string;
      navigationCallBack?: (...args: any) => void;
      url: string;
    };

export const TrackAndNavigate = ({
  eventData,
  children,
  className,
  navigationCallBack,
  url,
}: TrackAndNavigateProps) => {
  const dispatch = useDispatch();
  const doNavigation = () => {
    if (navigationCallBack) {
      navigationCallBack();
    }

    if (url) {
      url.startsWith("/gol-ui") ? dispatch(push(url)) : window.location.assign(url);
    }
  };

  const a = {
    link: (z: boolean) => z,
  };
  const z = true;
  a.link(z);

  const callAnalytics = useCallback(
    (clickEvent: React.MouseEvent<HTMLDivElement>) => {
      clickEvent.stopPropagation();
      clickEvent.preventDefault();

      const utagLinkCanBeCalled =
        (window as any).utag && (window as any).utag.link && typeof (window as any).utag.link === "function";
      if (utagLinkCanBeCalled) {
        (window as any).digitalData.event.push(eventData);
        (window as any).utag.link(eventData, () => {
          doNavigation();
        });
      } else {
        doNavigation();
      }
    },
    // TO-DO: refactor when useEffectEvent is stable see https://react.dev/reference/react/experimental_useEffectEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigationCallBack, url]
  );

  return (
    <div className={className} onClickCapture={callAnalytics}>
      {children}
    </div>
  );
};

TrackAndNavigate.displayName = "TrackAndNavigate";
