import React from "react";
import clsx from "clsx";
import { MobileMegaNavItem } from "./MobileMegaNavItem";
import { MegaNavColumnProps } from "../MegaNav.types";
import { LinkFlag } from "../../useMenuPromotionalItem";
import { PromoLink } from "../../../../common/firebase";

export const BrowseSection: React.FC<
  MegaNavColumnProps & {
    promotionalLink: LinkFlag | boolean;
    promotionalLink2: PromoLink | null;
  }
> = ({ items, onSelected, promotionalLink, promotionalLink2 }) => (
  <div data-testid="browse-section">
    {items.map(nvi => (
      <li
        className={clsx(
          nvi.items?.length ? "megaNavDrawer__childItem" : "megaNavDrawer__item",
          nvi.title === "Offers" && "offer"
        )}
        key={nvi.id}
      >
        <MobileMegaNavItem item={{ ...nvi, url: nvi.url ?? "#" }} onSelected={onSelected} />
      </li>
    ))}
    {promotionalLink && typeof promotionalLink !== "boolean" && (
      <li className="megaNavDrawer__item" key="nav-item-link-promotions">
        <MobileMegaNavItem
          item={{
            title: promotionalLink.title || "",
            url: promotionalLink.url,
            id: "promotional-link",
          }}
          onSelected={onSelected}
        />
      </li>
    )}
    {promotionalLink2 && Object.keys(promotionalLink2).length > 0 && (
      <li className="megaNavDrawer__item" key="nav-item-link-promotions">
        <MobileMegaNavItem
          item={{
            title: promotionalLink2.title || "",
            url: promotionalLink2.url,
            id: "promotional-link",
          }}
          onSelected={onSelected}
        />
      </li>
    )}
  </div>
);
