import React, { HTMLAttributes } from "react";
import clsx from "clsx";
import { WithChildren } from "../../common/types";

interface TextButtonProps {
  className?: string;
  darkTheme?: boolean;
}

export const TextButton = ({
  children,
  className,
  darkTheme = true,
  ...props
}: WithChildren & TextButtonProps & HTMLAttributes<any>) => {
  const theme = darkTheme ? "ln-c-link--dark" : "ln-c-link--alpha";
  return (
    <button
      data-testid="text-button"
      className={clsx("ln-c-link", "ln-c-link--bare", "ln-c-link--cta", className, theme)}
      {...props}
    >
      {children}
    </button>
  );
};

TextButton.displayName = "TextButton";
