import { BasketProduct } from "../../domain/basket/basket.types";
import { extractCategory } from "./utils";
import { OrderItem } from "../../services/order.types";
import { Product } from "../../services/product.types";

export function createCategoryMapViaBasketProducts(basketProducts: BasketProduct[], productList: Product[]) {
  return basketProducts.reduce((acc, product) => {
    acc[product.sku] = extractCategory(productList.find(prod => product.sku === prod.product_uid));
    return acc;
  }, {});
}

export function createCategoryMapViaOrderItems(orderItems: OrderItem[], productList: Product[]) {
  return orderItems.reduce((acc, product) => {
    acc[product.product.product_uid!] = extractCategory(
      productList.find(prod => product.product.product_uid === prod.product_uid)
    );
    return acc;
  }, {});
}
