import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../common/store";
import { FetchSlotReservationSuccessAction, SlotReservationActionTypes } from "../../domain/slot/slotReservation.types";
import { matchesPath, routes } from "../../routes";
import { bookingConfirmationPageView } from "./bookingConfirmation";
import {
  AdobeExtraPageData,
  AdobePageName,
  AdobePageType,
  generateAdobeAnalyticsPageProperties,
} from "../../utils/generateAdobeAnalyticsPageProperties";
import { SlotTypeAnalytics, SlotTypeValue } from "../../components/BookSlot/bookSlot.types";

export const homeDeliveryBookingConfirmationMiddleware =
  (api: MiddlewareAPI<Dispatch<any>, State>) =>
  (next: Dispatch<any>) =>
  (action: FetchSlotReservationSuccessAction) => {
    switch (action.type) {
      case SlotReservationActionTypes.FETCH_SLOT_RESERVATION_SUCCESS:
        const location = api.getState().router.location;
        if (matchesPath(location.pathname, routes.BOOKING_CONFIRMATION)) {
          const basket = api.getState().basket.basketDetails;
          const { data_siteSection, data_page_name, data_shoppingMode } = generateAdobeAnalyticsPageProperties({
            basket,
            pageType: AdobePageType.GROCERIES,
            pageName: AdobePageName.SLOT_BOOKING,
            extraPageData: [AdobeExtraPageData.DELIVERY, AdobeExtraPageData.RESERVATION_CONFIRMATION],
          });
          let slotType;
          if (action.slotReservation) {
            if (action.slotReservation.slotType === SlotTypeValue.Saver) {
              slotType = SlotTypeAnalytics.Saver;
            } else if (action.slotReservation.slotType === SlotTypeValue.Standard) {
              slotType = action.slotReservation.reservationIsGreen
                ? SlotTypeAnalytics.StandardGreen
                : SlotTypeAnalytics.Standard;
            }
          }
          api.dispatch(
            bookingConfirmationPageView(action.slotReservation, {
              data_siteSection,
              data_page_name,
              data_shoppingMode,
              data_slot_price: action.slotReservation.slotQualifiedPrice
                ? action.slotReservation.slotQualifiedPrice
                : undefined,
              data_slot_type: slotType,
            })
          );
        }
        break;
      default:
        break;
    }
    next(action);
  };
