import { combineReducers } from "redux";
import { DataState } from "../../common/dataState";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import { SearchActionType, SearchStore } from "./search.types";

const dataState = createDataStateReducer({
  [SearchActionType.SEARCH_PRODUCTS_REQUEST]: DataState.PENDING,
  [SearchActionType.SEARCH_PRODUCTS_SUCCESS]: DataState.SUCCESS,
  [SearchActionType.SEARCH_PRODUCTS_FAILURE]: DataState.FAILED,
});

export const searchReducer = combineReducers({
  dataState,
});

export const initialState: SearchStore = {
  dataState: dataState(),
};
