import { BotDetectState } from "../../components/Citrus/GoogleBotDetect/CitrusBotDetect.types";
import { AnalyticsActionTypes, AnalyticsPageViewAction, AnalyticsTrackPageLoadAction } from "./types";
import { SearchAnalyticsType, ExistingOrder } from "../../common/dataLayer/types";
import { BrowseAnalyticsPageName, BrowseAnalyticsPageTemplate } from "../../views/Browse/Browse.types";

export const UTAG_GROUP_CALL_DELAY = 1000;
export const UTAG_GROUP_CALL_DELAY_FAVOURITES = 1500;

export type PageName = string;
export type PageViewProperties = {
  name: string;
  template: string;
  newTemplate: string;
  section: string;
  productId?: string;
  productImageCount?: number;
  productVideoCount?: number;
  available?: boolean;
  search?: SearchAnalyticsType[];
  deliverySlotsViewed?: DeliverySlotsViewed[];
  deliveryLocation?: string;
  storeId?: string;
  deliveryType?: string;
  orderDeliverySlot?: string;
  deliverySlot?: string;
  bookingSlotPrice?: number;
  slotType?: string;
  promotionalTilesPositioning?: string;
  offersDistribution?: string;
  reviewScore?: number;
  reviewsNumber?: number;
  productPrice?: number;
  shoppingMode?: string;
  existingOrder?: ExistingOrder;
  breadCrumbs?: string;
};

export enum LoginEntryVariant {
  DEFAULT = "variant 1",
  CHECKOUT = "variant 2 - checkout as a guest",
  FULL_TROLLEY = "variant 3 - full trolley view",
}

export const staticPageAnalyticsValues: Record<PageName, PageViewProperties> = {
  Login: {
    name: "loginPage",
    template: "login and registration",
    newTemplate: "loginandregistration",
    section: "login and registration",
  },
  LoginIdentifier: {
    name: "registration and login:login:step 1",
    template: "login",
    newTemplate: "login",
    section: "login and registration",
  },
  LoginPassword: {
    name: "registration and login:login:step 2",
    template: "login",
    newTemplate: "login",
    section: "login and registration",
  },
  CheckPostcode: {
    name: "loginPageStep1Variant2",
    template: "login and registration",
    newTemplate: "postcodecheck",
    section: "login and registration",
  },
  ProgressiveRegistrationForm: {
    name: "registration and login:progressive registration:registration",
    template: "progressive registration form",
    newTemplate: "progressiveregistrationform",
    section: "login and registration",
  },
  ProgressiveRegistrationSuccess: {
    name: "registration and login:progressive registration:success registration",
    template: "progressive registration confirmation page",
    newTemplate: "progressiveregistrationconfirmationpage",
    section: "login and registration",
  },
  OAuthLogin: {
    name: "oauthLoginPage",
    template: "login and registration",
    newTemplate: "loginandregistration",
    section: "login and registration",
  },
  OAuthRedirect: {
    name: "oauthRedirectPage",
    template: "login and registration",
    newTemplate: "loginandregistration",
    section: "login and registration",
  },
  OAuthLoginError: {
    name: "oauthLoginErrorPage",
    template: "login and registration",
    newTemplate: "loginandregistration",
    section: "login and registration",
  },
  OAuthLogout: {
    name: "oauthLogoutPage",
    template: "logout",
    newTemplate: "logout",
    section: "logout",
  },
  OAuthLogoutRedirect: {
    name: "oauthLogoutRedirectPage",
    template: "logout",
    newTemplate: "logout",
    section: "logout",
  },
  ProgressiveRegistrationV2: {
    name: "oauthProgressiveRegistrationPage",
    template: "login and registration",
    newTemplate: "loginandregistration",
    section: "login and registration",
  },
  ProgressiveRegistrationV2Success: {
    name: "oauthProgressiveRegistrationSuccessPage",
    template: "login and registration",
    newTemplate: "loginandregistration",
    section: "login and registration",
  },
  OrderDetails: {
    name: "orderDetails",
    template: "my account scheduled order list",
    newTemplate: "myaccount:orderdetails",
    section: "order details",
  },
  OrderList: {
    name: "myAccountScheduledOrderList",
    template: "my account scheduled order list",
    newTemplate: "myaccount:myorders",
    section: "my account scheduled order list",
  },
  MyAccount: {
    name: "myAccount",
    template: "my account",
    newTemplate: "myaccount",
    section: "my account",
  },
  Trolley: {
    name: "trolley",
    template: "trolley",
    newTemplate: "trolley",
    section: "trolley",
  },
  Error: {
    name: "genericError",
    template: "error",
    newTemplate: "error",
    section: "error",
  },
  AboutClickAndCollect: {
    name: "aboutClickAndCollect",
    template: "click and collect",
    newTemplate: "clickandcollect",
    section: "click and collect",
  },
};

export interface ProgressiveRegistrationPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: "registration and login:progressive registration:registration";
    template: "progressive registration form";
    newTemplate: "progressiveregistrationform";
    section: "login and registration";
  };
}

export interface ProgressiveRegistrationV2PageViewAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: PageViewProperties & {
    name: "oauthProgressiveRegistrationPage";
    template: "login and registration";
    newTemplate: "loginandregistration";
    section: "login and registration";
  };
}

export interface ProgressiveRegistrationSuccessPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: "registration and login:progressive registration:success registration";
    template: "progressive registration confirmation page";
    newTemplate: "progressiveregistrationconfirmationpage";
    section: "login and registration";
  };
}

export interface ProgressiveRegistrationV2SuccessAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: PageViewProperties & {
    name: "oauthProgressiveRegistrationSuccessPage";
    template: "login and registration";
    newTemplate: "loginandregistration";
    section: "login and registration";
  };
}

export interface OrderListPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: "myAccountScheduledOrderList";
    template: "my account scheduled order list";
    newTemplate: "myaccount:myorders";
    section: "my account scheduled order list";
  };
}

export interface OrderDetailsPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "orderDetails";
    template: "my account scheduled order list";
    newTemplate: "myaccount:orderdetails";
    section: "order details";
  };
}
export interface OAuthRedirectPageViewAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: {
    name: "oauthRedirectPage";
    template: "login and registration";
    newTemplate: "loginandregistration";
    section: "login and registration";
  };
}

export interface FavouritesPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: "favouritesSingleList";
    template: "favouriteslist";
    newTemplate: "favouriteslist";
    section: "favouritesSingleList";
  };
}

export interface NoFavouritesPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: "firstFavouritesSingleList";
    template: "firstFavouriteslist";
    newTemplate: "firstFavouritesSingleList";
    section: "firstFavouriteslist";
  };
}

export interface RecurringSlotOptInPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: "recurringSlotOptIn";
    template: "recurringSlot";
    newTemplate: "recurringSlot";
    section: "recurringSlotOptIn";
  };
}

export interface MyRecurringSlotPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: "myRecurringSlot";
    template: "recurringSlot";
    newTemplate: "recurringSlot";
    section: "myRecurringSlot";
  };
}

export interface SearchResultsPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: string;
    template: string;
    newTemplate: "searchresults";
    section: "searchResultsPage";
    search: SearchAnalyticsType[];
  };
}

export interface FindabilitySearchPageViewAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: PageViewProperties & {
    name: string;
    template: string;
    newTemplate: "searchresults" | "multisearchresults";
    section: "searchResultsPage";
    search: SearchAnalyticsType[];
    isCategoryMatch: boolean;
    categoryMatchHierarchy?: string;
    isGoogleBotDetected?: BotDetectState;
  };
}

export interface ProductListerPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: string;
    template: "ProductLister";
    newTemplate: "productLister";
    section: "productListerPage";
  };
}

export interface PreviousOrdersPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: "favourites:previous orders";
    template: "favouritesPreviousOrder";
    newTemplate: "favouritesPreviousOrder";
    section: "previous orders";
  };
}

export interface ProductDetailsPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    template: "productdetails";
    newTemplate: "pdp";
    productId: string;
    brand: string;
    productImageCount: number;
    productVideoCount: number;
    available: boolean;
    productPrice: number;
    productName: string;
  };
}

export interface NectarOffersPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "nectar offers";
    template: "nectar offers page";
    newTemplate: "nectaroffers";
    section: "nectaroffers";
  };
}

export interface OffersPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "groceries:offers";
    template: "offers";
    newTemplate: "offers";
    section: "offers";
  };
}

export interface OffersZonePageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: string;
    template: "offers-zone";
    newTemplate: "offers-zone";
    section: "offers-zone";
  };
}

export interface BookSlotPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "delivery booking";
    template: "booking slot";
    newTemplate: "deliveryslots";
    section: "delivery and collection";
    deliveryType: "delivery";
    deliverySlotsViewed: DeliverySlotsViewed[];
    deliveryLocation: string;
    storeId: string;
  };
}

export interface ClickAndCollectPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "click and collect booking";
    template: "click and collect";
    newTemplate: "clickandcollectslots";
    section: "delivery and collection";
    deliveryType: "collection";
    collectionSlotsViewed?: DeliverySlotsViewed[];
    storeId: string;
  };
}

export interface ClickAndCollectConfirmationPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "collection reserved";
    template: "click and collect";
    newTemplate: "clickandcollectslots";
    section: "delivery and collection";
    deliveryType: "collection";
    storeId: string;
    collectionSlotsViewed: DeliverySlotsViewed;
  };
}

// snake-case here enables simple assignment in Tealium
export interface DeliverySlotsViewed {
  date: string;
  start_time: string;
  end_time: string;
  status: number;
  price: number;
}

export interface ChangesToTrolleyPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "groceries:checkout:changes to your trolley";
    template: "delivery and collection";
    newTemplate: "alternateproductspage";
    section: "slot booking";
  };
}

export interface BookingConfirmationPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "delivery reserved";
    template: "booking slot";
    newTemplate: "deliveryslots";
    section: "delivery and collection";
    deliveryType: "delivery";
    deliveryLocation: string;
    storeId: string;
    orderDeliverySlot: string;
    bookingSlotPrice: number;
    slotType: string;
  };
}

export interface BrowsePageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: BrowseAnalyticsPageName;
    template: BrowseAnalyticsPageTemplate;
    section: string;
    breadCrumbs: string;
    newTemplate: "plp";
  };
}

export interface RecipesPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: string;
    template: "recipes";
    newTemplate: RecipesTemplate;
    section: "recipes";
    recipesSearchTerm?: string;
  };
}

export interface MealPlanPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: string;
    template: "mealPlan";
    newTemplate: RecipesTemplate;
    section: "recipes";
  };
}

export interface MealDealPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: PageViewProperties & {
    name: string;
    template: string;
    newTemplate: string;
    section: string;
  };
}

export interface XmasSlotGuidePageViewAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: PageViewProperties & {
    name: string;
    template: string;
    newTemplate: string;
    section: string;
  };
}

export interface RecipesPageViewTrackAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: PageViewProperties & {
    name: string;
    template: "recipes";
    newTemplate: RecipesTemplate;
    section: "recipes";
    recipesSearchTerm?: string;
  };
}

export interface TrolleyPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "trolley";
    template: "trolley";
    newTemplate: "trolley";
    section: "trolley";
  };
}

export interface OAuthLogoutPageViewAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: {
    name: "oauthLogoutPage";
    template: "logout";
    newTemplate: "logout";
    section: "logout";
  };
}

export interface CYSMPageViewTrackAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: {
    name: "CYSMPage";
    template: "CYSM";
    newTemplate: "CYSM";
    section: "CYSM";
  };
}

export interface PromoListerPageViewTrackAction extends AnalyticsTrackPageLoadAction {
  type: AnalyticsActionTypes.DIGITAL_DATA_TRACK_PAGE_LOAD;
  page: PageViewProperties & {
    template: "promo lister";
    newTemplate: "promo lister";
    section: "promo lister";
  };
}

export interface HomePageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "web:groceries:homepage";
    template: "homepage";
    newTemplate: "homepage";
    section: "homepage";
  };
}

export interface NectarPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "web:groceries:my account:nectar card";
    template: "my account";
    newTemplate: "my account";
    section: "my account";
  };
}

export interface NectarEditPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "web:groceries:my account:nectar card:edit";
    template: "my account";
    newTemplate: "my account";
    section: "my account";
  };
}

export interface NectarSignUpPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "web:groceries:my account:nectar card:signup";
    template: "my account";
    newTemplate: "my account";
    section: "my account";
  };
}

export interface NectarVerifyPageViewAction extends AnalyticsPageViewAction {
  type: AnalyticsActionTypes.PAGE_VIEW;
  page: {
    name: "web:groceries:my account:nectar card:verify";
    template: "my account";
    newTemplate: "my account";
    section: "my account";
  };
}

export enum RecipesTemplate {
  RECIPE = "recipes:recipepage",
  ARTICLE = "recipes:articlepage",
  SEARCH_RESULTS = "recipes:searchresultspage",
  SCRAPBOOK = "recipes:scrapbookpage",
  HOMEPAGE = "recipes:homepage",
  BROWSE = "recipes:browse",
  MEALPLAN = "recipes:mealPlan",
  MYRECIPES = "recipes:myrecipes",
}

export const getEntryPoint = (referrerUrl: string): LoginEntryVariant => {
  const pageName = referrerUrl.split(/[?#]/, 1)[0].split("/").slice(-1)[0];
  switch (pageName) {
    case "MyTrolleyCheckout":
      return LoginEntryVariant.CHECKOUT;
    case "AjaxOrderItemDisplayView":
      return LoginEntryVariant.FULL_TROLLEY;
    default:
      return LoginEntryVariant.DEFAULT;
  }
};
