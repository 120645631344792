import * as domain from "./product.types";
import {
  Assets,
  AssociationType,
  AverageWeight,
  PaginatedProducts,
  Product,
  ProductSource,
  ProductUid,
  SortOptions,
} from "./product.types";
import { LookupTable, LookupTableItems } from "../../common/lookupTable";
import GolTime from "../time";
import {
  Attachment,
  Catchweight,
  Category,
  Controls,
  MultiVariant,
  NectarPrice,
  PaginatedProducts as ServicesPaginatedProducts,
  Product as ServicesProduct,
  ProductHeader,
  PromotionResponse,
  RetailPrice,
  Sort,
  UnitPrice,
  YourNectarPrice,
} from "../../services/product.types";
import { GOLUI_PREFIX } from "../../routes";

export const removeUnavailableProducts = (products: domain.Product[]) => products.filter(product => product.available);

/**
 * Returns a map of domain products.
 *
 * @param {source} Where in the website the product is being shown.
 * @param {products} The raw API response of the product data.
 * @param {nestAssociations} If there are associated products, map the associated products recursively via this function and assign them as alternatives to the current product.
 * @param {list} The list of products which this product is shown in e.g. the name of a carousel. Used for analytics purposes.
 *
 * @return {products} An array of products which have been digested and have properties set which are relevant to other parts of the app.
 */

export const mapProducts = (
  source: ProductSource | string,
  products: ServicesProduct[],
  nestAssociations = false,
  list?: string
): domain.Product[] => {
  const associations: Map<ProductUid, AssociationType> = getAllAssociations(products);
  const alreadyAdded: Set<ProductUid> = new Set();

  return products.reduce((acc, current) => {
    const productHasAssociations = current.associations.length > 0;
    if (productHasAssociations && !alreadyAdded.has(current.product_uid)) {
      const associationType = associations.has(current.product_uid)
        ? mapAssociationType(current.associations[0].association_type)
        : AssociationType.NONE;
      const expires = associations.has(current.product_uid) ? current.associations[0].expires : undefined;
      const mappedProduct = mapProduct(current, acc.length, source, associationType, expires, undefined, list);
      acc.push(mappedProduct);
      alreadyAdded.add(current.product_uid);
    }
    if (!productHasAssociations && !associations.has(current.product_uid) && !alreadyAdded.has(current.product_uid)) {
      const mappedProduct = mapProduct(current, acc.length, source, AssociationType.NONE, undefined, undefined, list);
      acc.push(mappedProduct);
      alreadyAdded.add(current.product_uid);
    }

    if (productHasAssociations && nestAssociations) {
      for (const association of current.associations) {
        if (
          association.association_type !== AssociationType.APE &&
          association.association_type !== AssociationType.KRANG &&
          association.association_type !== AssociationType.APE_OVERRIDE
        ) {
          continue;
        }
        const parentIndex = acc.findIndex(product => product.productUid === current.product_uid);
        const associatedProduct = association.product;
        const index =
          acc[Number(parentIndex)]?.alternatives?.length != null ? acc[Number(parentIndex)].alternatives!.length : 0;

        const mappedAssociatedProduct = mapProduct(
          associatedProduct,
          index,
          source,
          mapAssociationType(association.association_type),
          association.expires,
          current.product_uid,
          list
        );

        mappedAssociatedProduct.productCTA = undefined;
        mappedAssociatedProduct.productHeader = undefined;

        if (parentIndex > -1) {
          mappedAssociatedProduct.associationParentProductUid = acc[Number(parentIndex)].productUid;
          if (acc[Number(parentIndex)].alternatives != null) {
            acc[Number(parentIndex)].alternatives?.push(mappedAssociatedProduct);
          } else {
            acc[Number(parentIndex)].alternatives = [mappedAssociatedProduct];
          }
        }
      }
    } else if (productHasAssociations) {
      const firstAssociation = current.associations[0].product;
      if (!alreadyAdded.has(firstAssociation.product_uid)) {
        const type = associations.get(firstAssociation.product_uid);
        const mappedProduct = mapProduct(
          firstAssociation,
          acc.length,
          source,
          type!,
          current.associations[0].expires,
          current.product_uid,
          list
        );
        acc.push(mappedProduct);
        alreadyAdded.add(firstAssociation.product_uid);
      }
    }

    return acc;
  }, [] as domain.Product[]);
};

export const removeUnwantedProducts = (products: Product[]): domain.Product[] => {
  const removeItems = localStorage.getItem("firstFavouritesIgnoredProducts");

  const removeItemsArray = removeItems && removeItems.split(",");

  const removedItems = removeItemsArray && removeItemsArray.shift();

  if (removedItems === null) {
    return products;
  }
  return products.filter(
    obj => removeItemsArray && removeItemsArray.every(productUid => obj.productUid !== productUid)
  );
};

const getAllAssociations = (products: ServicesProduct[]): Map<ProductUid, AssociationType> =>
  products.reduce((acc, curr) => {
    curr.associations.forEach(a => {
      acc.set(a.product.product_uid, mapAssociationType(a.association_type));
    });
    return acc;
  }, new Map());

function mapAssets(assets: Assets): Assets {
  return {
    images: assets.images ?? [],
    video: assets.video ?? [],
  };
}

export const mapProduct = (
  product: ServicesProduct,
  index: number,
  source: ProductSource | string,
  associationType: domain.AssociationType,
  expires?: string,
  parentProductUid?: string,
  list?: string
): domain.Product => {
  return {
    productUid: product.product_uid,
    favouriteUid: product.favourite_uid,
    favourite: product.is_favourite ?? false,
    favouriteSource: product.favourite_type,
    zone: product.zone ? product.zone : undefined,
    department: product.department ? product.department : undefined,
    productType: product.product_type,
    isSupplyChainOrderable: product.is_supply_chain_orderable,
    name: product.name,
    eans: product.eans ?? [],
    retailPrice: product.retail_price,
    unitPrice: mapUnitPrice(product.unit_price),
    averageWeight: mapAverageWeight(product.average_weight),
    nectarPrice: mapNectarPrice(product.nectar_price),
    yourNectarPrice: mapYourNectarPrice(product.your_nectar_price),
    image: product.assets.plp_image,
    assets: mapAssets(product.assets),
    imageThumbnail: product.image_thumbnail,
    imageThumbnailSmall: product.image_thumbnail_small,
    fullUrl: product.full_url === null ? undefined : product.full_url,
    pdpDeepLink: product.pdp_deep_link,
    available: product.is_available,
    isSpotlight: product.is_spotlight,
    isIntolerant: product.is_intolerant,
    isMHRA: product.is_mhra,
    occasion: product.occasion ?? undefined,
    promise: mapOrderPromise(product.promise),
    attributes: product.attributes,
    displayIcons: product.display_icons,
    productCTA: mapProductHeader(product.cta),
    productHeader: mapProductHeader(product.header),
    labels: product.labels.map(l => ({
      labelUid: l.label_uid,
      color: l.color,
      text: l.text,
      link: l.link,
      linkOpensInNewWindow: l.link_opens_in_new_window,
      altText: l.alt_text,
    })),
    badges: product.badges.map(b => ({
      badgeUid: b.badge_uid,
      color: b.color,
      text: b.text,
      iconUrl: b.icon_url,
      link: b.link,
      linkOpensInNewWindow: b.link_opens_in_new_window,
      altText: b.alt_text,
    })),
    promotions: mapPromotions(product.promotions),
    associationType,
    associationParentProductUid: parentProductUid,
    expires,
    multivariants:
      product.multivariants &&
      product.multivariants.map((multivariant: MultiVariant) => ({
        productUid: multivariant.product_uid,
        displayName: multivariant.display_name,
        promotions: mapPromotions(multivariant.promotions),
        retailPrice: mapVariantRetailPrice(multivariant.retail_price),
        unitPrice: {
          price: multivariant.unit_price.price,
          measure: multivariant.unit_price.measure,
          measureAmount: multivariant.unit_price.measure_amount,
        },
      })),
    catchweight:
      product.catchweight &&
      product.catchweight.map((catchweight: Catchweight) => ({
        range: catchweight.range,
        retailPrice: mapVariantRetailPrice(catchweight.retail_price),
        unitPrice: {
          price: catchweight.unit_price.price,
          measure: catchweight.unit_price.measure,
          measureAmount: catchweight.unit_price.measure_amount,
        },
      })),
    position: index + 1,
    source,
    reviews: {
      isEnabled: product.reviews.is_enabled,
      productUid: product.reviews.product_uid,
      averageRating: product.reviews.average_rating,
      numberOfReviews: product.reviews.total,
    },
    breadcrumbs: product.breadcrumbs,
    detailsHtml: product.details_html,
    importantInformation: product.important_information,
    description: product.description,
    attachments: mapAttachments(product.attachments),
    categories: product.categories.map(p => ({ id: p.id })),
    messages: product.messages,
    relevancyRank: product.relevancyRank,
    list: list ?? undefined,
    notForEU: product.not_for_eu,
  };
};

export const mapOrderPromise = (promise?: any): domain.ProductOrderPromise | undefined => {
  if (promise) {
    return {
      status: promise.status,
      earliestPromiseDate: promise.earliest_promise_date,
      lastAmendmentDate: promise.last_amendment_date,
    };
  }

  return undefined;
};

export const mapProductHeader = (productHeader?: ProductHeader): domain.ProductHeader | undefined => {
  if (productHeader) {
    return {
      type: domain.ProductFeature[productHeader.type],
      text: productHeader.text,
    };
  }
  return undefined;
};

export const mapAssociationType = (associationType: string): domain.AssociationType => {
  switch (associationType) {
    case "X-SELL":
      return domain.AssociationType.CROSSELL;
    case "UPSELL":
      return domain.AssociationType.UPSELL;
    case "APE":
      return domain.AssociationType.APE;
    case "APE_OVERRIDE":
      return domain.AssociationType.APE_OVERRIDE;
    case "KRANG":
      return domain.AssociationType.KRANG;
    case "Citrus":
      return domain.AssociationType.CITRUS_CROSSELL;
    default:
      return domain.AssociationType.NONE;
  }
};

export const mapPaginatedProducts = (
  source: ProductSource,
  servicesProducts: ServicesPaginatedProducts,
  nestAssociations = false
): PaginatedProducts => {
  return {
    products: mapProducts(source, servicesProducts.products, nestAssociations),
    controls: mapControls(servicesProducts.controls),
  };
};

export const mapControls = (controls: Controls): domain.ProductControls => ({
  sortOptions: mapSortOptions(controls.sort),
  activeSort: controls.sort.active,
  pageSizeOptions: controls.page.size_options,
  pageSizeActive: controls.page.size,
  filters: controls.filters,
  pageLast: controls.page.last,
  pageNumber: controls.page.active,
  recordCount: controls.total_record_count,
});

export const mapSortOptions = (sort: Sort): SortOptions[] =>
  sort.options.map(option => ({
    display: option.display,
    value: option.value,
  }));

const mapPromotions = (promotions: PromotionResponse[] | undefined): domain.Promotion[] | undefined => {
  return promotions === undefined
    ? undefined
    : promotions.map(promotion => ({
        promotionUid: promotion.promotion_uid,
        url: promotion.link,
        icon: promotion.icon,
        strapLine: promotion.strap_line,
        startDate: promotion.start_date,
        endDate: promotion.end_date,
        originalPrice: promotion.original_price,
        promoMechanicId: promotion.promo_mechanic_id,
        promoType: promotion.promo_type,
        promoGroup: promotion.promo_group,
        isNectar: promotion.is_nectar,
      }));
};

export const createLookupWithMultivariants = (items: domain.Product[]): LookupTableItems<domain.Product> => {
  const lookup = LookupTable.createLookup(items, "productUid");
  items.forEach(product => {
    if (product.multivariants) {
      product.multivariants.forEach(multivariantProduct => {
        lookup[multivariantProduct.productUid] = product;
      });
    }
  });
  return lookup;
};

export interface MappableItem {
  productUid: ProductUid;
  multivariants?: domain.Product["multivariants"];
  description?: domain.Product["description"];
  assets?: domain.Product["assets"];
  detailsHtml?: domain.Product["detailsHtml"];
  breadcrumbs?: domain.Product["breadcrumbs"];
  associationType?: domain.Product["associationType"];
  productCTA?: domain.Product["productCTA"];
  productHeader?: domain.Product["productHeader"];
  zone?: domain.Product["zone"];
  department?: domain.Product["department"];
  promotions?: domain.Product["promotions"];
}

export const removeDeletedFavourite = <T extends MappableItem>(
  map: Map<ProductUid, T>,
  productUid: string
): Map<ProductUid, T> => {
  map.delete(productUid);
  return map;
};

export const saveFavourite = (state: Map<ProductUid, Product>, product: Product): Map<ProductUid, Product> => {
  return new Map([...state, [product.productUid, { ...product, favourite: true }]]);
};

export const addToMap = <T extends MappableItem>(
  map: Map<ProductUid, T>,
  product: T,
  updateMutableFields: boolean
): void => {
  const cachedProduct = map.get(product.productUid);
  product.productCTA =
    !updateMutableFields && cachedProduct?.productCTA !== undefined ? cachedProduct?.productCTA : product.productCTA;
  product.productHeader =
    !updateMutableFields && cachedProduct?.productHeader !== undefined
      ? cachedProduct?.productHeader
      : product.productHeader;

  product.zone = cachedProduct?.zone ?? product.zone;
  product.department = cachedProduct?.department ?? product.department;

  if (cachedProduct && (!product.detailsHtml || !product.assets?.images?.length)) {
    const description = cachedProduct.description ?? product.description;
    const assets = cachedProduct.assets?.images?.length ? cachedProduct.assets : product.assets;
    const detailsHtml = cachedProduct.detailsHtml ?? product.detailsHtml;
    const breadcrumbs = cachedProduct.breadcrumbs?.length ? cachedProduct.breadcrumbs : product.breadcrumbs;

    const productDetailed: any = {
      ...product,
      description,
      assets,
      detailsHtml,
      breadcrumbs,
    };

    map.set(product.productUid, productDetailed);
  } else {
    map.set(product.productUid, product);
  }

  if (product.multivariants) {
    product.multivariants.forEach(multivariantProduct => {
      const { promotions, productUid } = multivariantProduct;
      map.set(productUid, { ...product, promotions, productUid });
    });
  }
};

export const updateMap = <T extends MappableItem>(
  map: Map<ProductUid, T>,
  products: T[],
  updateMutableFields: boolean = false
): Map<ProductUid, T> => {
  const newMap = new Map(map);

  // This is for IE11 only which will not copy over elements, we need to do it manually
  if (newMap.size !== map.size) {
    map.forEach((value, key) => newMap.set(key, value));
  }

  products.forEach((product: T) => addToMap<T>(newMap, product, updateMutableFields));
  return newMap;
};

// If the user has a slot booked, we need to remove any associated products (e.g. replacement products and upsell)
// that expire before the slot time. For example, we don't want to show a replacement product if it is not buyable.
export const shouldShowProduct = (product: Product, slotTime?: GolTime): boolean => {
  if (!slotTime) {
    return true;
  }

  const isAssociatedProduct = product.associationType !== AssociationType.NONE;
  if (isAssociatedProduct && product.expires) {
    const productExpiry = new GolTime(product.expires);
    if (slotTime.isAfter(productExpiry)) {
      return false;
    }
  }
  return true;
};

const mapUnitPrice = (unitPrice?: UnitPrice): domain.UnitPrice | undefined =>
  unitPrice && {
    price: unitPrice.price,
    measure: unitPrice.measure,
    measureAmount: unitPrice.measure_amount,
  };

const mapAverageWeight = (averageWeight?: AverageWeight): domain.AverageWeight | undefined =>
  averageWeight && {
    amount: averageWeight.amount,
    measure: averageWeight.measure,
  };

const mapNectarPrice = (nectarPrice?: NectarPrice): domain.NectarPrice | undefined =>
  nectarPrice && {
    retailPrice: nectarPrice.retail_price,
    measure: nectarPrice.measure,
    unitPrice: nectarPrice.unit_price,
    url: nectarPrice.url,
  };

const mapYourNectarPrice = (yourNectarPrice?: YourNectarPrice): domain.YourNectarPrice | undefined =>
  yourNectarPrice && {
    withoutNectarPrice: yourNectarPrice.without_nectar_price,
    startTime: yourNectarPrice.start_time,
    expiryTime: yourNectarPrice.expiry_time,
  };

const mapVariantRetailPrice = (retailPrice: RetailPrice): domain.RetailPrice => ({
  price: retailPrice.price,
  measure: retailPrice.measure,
  wasPrice: retailPrice.was_price,
});

const mapAttachments = (attachments: Attachment[]): domain.Attachment[] =>
  attachments.map(attachment => ({
    fileName: attachment.file_name,
    fileDescription: attachment.file_description,
    filePath: attachment.file_path,
    mimeType: attachment.mime_type,
  }));

export const mapCategoriesToLinks = (categories: Category[]): domain.ProductCategoryLink[] =>
  categories.map(category => ({
    url: category.s.startsWith(GOLUI_PREFIX.slice(1)) ? `/${category.s}` : `/shop/${category.s}`,
    name: category.n,
  }));
