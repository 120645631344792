import { Basket } from "../../services/basket.types";
import { DataState } from "../../common/dataState";
import { SearchProducts } from "../SearchResultsFindability/SearchResultsFindability.types";
import { ProductUid } from "../../domain/product";
import { ItemType } from "../../components/ProductTile/productTile.types";
import { RequestFilters } from "../../services/product.types";
import { TemplateArea } from "../../services/contentTypes";

export type WithKey<T> = T & { key: string };

export enum MealDealActionType {
  PRODUCTS_REQUEST = "MEAL_DEAL_PRODUCT_REQUEST",
  PRODUCTS_SUCCESS = "MEAL_DEAL_PRODUCT_SUCCESS",
  PRODUCTS_FAILURE = "MEAL_DEAL_PRODUCT_FAILURE",
}

export interface ProductTileUid {
  item: ProductUid;
  type: ItemType;
}

export interface mealDealState {
  dataState: DataState;
}

export const mealDealInitialState: mealDealState = {
  dataState: DataState.UNKNOWN,
};

export interface CreateMealDealBuilderSuccessActionType {
  type: MealDealActionType.PRODUCTS_SUCCESS;
  products: SearchProducts;
}

export interface CreateMealDealBuilderFailureActionType {
  type: MealDealActionType.PRODUCTS_FAILURE;
  message: string;
}

export interface MealDealItem {
  item_uid: string;
  quantity: number;
  subtotal_price: number;
  product: {
    sku: string;
    name: string;
    image: string;
    full_url: string;
  };
  promotions: PromotionForMealDealBuilder[];
  savings?: number;
}

export interface PromotionForMealDealBuilder {
  promotion_uid: string;
  strap_line: string;
  promotion_missed: boolean;
  promo_list_id: string;
}

export interface MealDealOffer {
  promotionId: number;
  strapLine: string;
  mechanicId: number;
  promoQuantity?: number;
  promoText: string;
  promoType?: string;
  promotion_end_date?: string;
  promotion_start_date?: string;
  list_detail: MealDealOfferList[];
  isNectarPromo: "Y" | "N";
}

export interface MealDealOfferList {
  listId: number;
  listName: string;
  promoQuantity: number;
}
export interface PillItem {
  url: string;
  target: string;
  title: string;
  listId: number;
  copy: string;
  isSelected?: boolean;
}
export interface MealDealBuilderHeaderPillsProps {
  className?: string;
  pillItems: PillItem[];
  pageType: string;
  mealDealId: string;
}

export interface MealDealPromotions {
  promotion: MealDealOffer[];
}

export interface CompleteDeal {
  saving: string;
  items: MealDealItem[];
}

export interface MealDeal {
  completed_deals: CompleteDeal[] | null;
  missed_deals: MealDealItem[][];
  promotion_uid: string;
}

export interface MealDealBasket extends Basket {
  meal_deals: MealDeal[];
}

export interface MealDealBuilderHookReturn {
  landingURL: string;
  listIdSlug: string | null;
  requestFilters: RequestFilters | undefined;
  sort: string;
  page: string;
}

export interface RequestProps {
  template_id: string;
  storeId?: string;
  delivery_date?: string;
  region?: string;
  has_nectar_associated?: boolean;
  is_digital_customer?: boolean;
  has_delivery_pass?: boolean;
  is_very_important_customer?: boolean;
}

export interface ResponseData {
  aboveGrid?: TemplateArea;
  belowGrid?: TemplateArea;
  seo?: TemplateArea;
  meta?: Meta;
  aboveGridSecondary?: TemplateArea;
}

export interface Meta {
  description: string;
  title: string;
  header: Header;
  pageHeader: string;
  pageIntroCopy: string;
  canonicalTag: string;
}

export interface Header {
  theme: Theme;
  backgroundColor?: string;
  backgroundImage?: BackgroundImage;
  pills?: PillItem[];
}

export interface BackgroundImage {
  url: string;
  altText: string;
}

export interface MealDealCMSTemplateConfig {
  [key: string]: [value: string];
}

export interface MealDealMetaTagsType {
  filters: any;
  canonicalPath: string;
  seoData: any;
}

export interface Offer {
  offerData: MealDealOffer | null;
  offerStatus: DataState;
}

export type MealDealCategory = MealDealOfferList & { product?: MealDealItem };

export enum Theme {
  WHITE = "white",
  LIGHTER = "lighter",
  LIGHT = "light",
  BASE = "base",
  DARK = "dark",
  DARKER = "darker",
  BLACK = "black",
}
