import { AppThunkDispatch } from "../../../common/types";
import { BotDetectState } from "./CitrusBotDetect.types";
import { isUserGoogleBot } from "./CitrusBotDetect.utils";

export const BOT_CHECK_STATUS = "BOT_CHECK_STATUS";

export const setBotCheckStatus = (payload: BotDetectState) => {
  return {
    type: BOT_CHECK_STATUS,
    payload,
  };
};

export const setBotDetectStatus = () => async (dispatch: AppThunkDispatch) => {
  isUserGoogleBot()
    .then(({ isGoogleBot, clientIp }) =>
      dispatch(
        setBotCheckStatus({
          isGoogleBot,
          clientIp,
        })
      )
    )
    .catch(() => {
      dispatch(
        setBotCheckStatus({
          isGoogleBot: false,
        })
      );
    });
};
