import { concatAllNonEmptyStrings, getCharsAfter, createFormattedPath } from "../../common/utils";
import { WCSBreadcrumbs } from "./Header.types";
import { Category } from "../../services/product.types";

export const CATEGORY_ID_IDENTIFIER = "c:";
export enum ActionTypes {
  CREATE_WCS_BREADCRUMBS = "CREATE_WCS_BREADCRUMBS",
}

export interface CreateWCSBreadcrumbsAction {
  type: ActionTypes.CREATE_WCS_BREADCRUMBS;
  wcsBreadcrumbs: WCSBreadcrumbs;
}

export const createWCSBreadcrumbsAction = (categoryTree: Category): CreateWCSBreadcrumbsAction => ({
  type: ActionTypes.CREATE_WCS_BREADCRUMBS,
  wcsBreadcrumbs: createWCSBreadcrumbs(categoryTree),
});

export const getCategoryID = (s: string) => getCharsAfter(s, CATEGORY_ID_IDENTIFIER);
export const readyForMigration = (url: string): boolean => url.includes(CATEGORY_ID_IDENTIFIER);
export const isGOLUILink = (s: string): boolean => s.startsWith("gol-ui");
export const prependSlashToMigratedURL = (url: string) => concatAllNonEmptyStrings(["/", url], "");

// createWCSURL creates WCS URLs for migration-pending categories
export const createWCSURL = (path: string): string => {
  const prefix = isGOLUILink(path) ? "/" : "/shop/";
  return concatAllNonEmptyStrings([prefix, path], "");
};

const isLeafNode = (tree: Category): boolean => tree.c === undefined;

// createWCSBreadcrumbs returns a flat hash map that associates all categories with their WCS info.
export const createWCSBreadcrumbs = (tree: Category, crumbTrail?: string, wcsInfo?: WCSBreadcrumbs): WCSBreadcrumbs => {
  const { s: url, n: label } = tree;
  const infoMap: WCSBreadcrumbs = wcsInfo || {};
  const formattedPath = createFormattedPath(label);
  const wcsURL = createWCSURL(url);
  const aggregatedCrumbTrail =
    crumbTrail && crumbTrail !== "groceries" ? crumbTrail.concat("/", formattedPath) : formattedPath;

  const updatedMap: WCSBreadcrumbs = Object.assign({}, infoMap, {
    [aggregatedCrumbTrail]: {
      url: wcsURL,
      label,
    },
  });

  if (isLeafNode(tree)) return updatedMap;

  const subCategories = tree.c as Category[];

  const childMaps: WCSBreadcrumbs[] = subCategories
    ? subCategories.map<WCSBreadcrumbs>(sc => createWCSBreadcrumbs(sc, aggregatedCrumbTrail, updatedMap))
    : [];
  return childMaps.reduce((m, current) => Object.assign({}, m, current), {});
};
