import { State, useServiceRequestCallback } from "../../services/hooks";
import { applyCouponRequest, fetchCoupons, removeCouponRequest } from "../../services/coupon";
import { Coupons } from "../../services/coupon.types";
import { useReducer } from "react";
import { DataState } from "../../common/dataState";
import { ServiceErrors } from "../../common/http/request";

enum ActionType {
  start = "start",
  success = "success",
  error = "error",
}

type Action<ResultData> = {
  type: ActionType;
  data?: ResultData;
  error?: ServiceErrors;
};

export type UseCoupon = {
  listCouponsData: State<Coupons>;
  applyCouponData: State<void>;
  removeCouponData: State<any>;
  fetchCoupons: () => void;
  applyCoupon: (couponCode: string, isOmniChannelCoupon: boolean) => void;
  removeCoupon: (couponCode: string, isOmniChannelCoupon: boolean, isNectar: boolean) => void;
};

export const useCoupon = (): UseCoupon => {
  const [couponsListState, fetchCouponsList] = useServiceRequestCallback(fetchCoupons);
  const [applyCouponState, applyCoupon] = useServiceRequestCallback(applyCouponRequest);
  const [state, dispatch] = useReducer(reducer, { status: DataState.UNKNOWN });

  return {
    listCouponsData: couponsListState,
    applyCouponData: applyCouponState,
    removeCouponData: state,
    fetchCoupons: fetchCouponsList,
    applyCoupon,
    removeCoupon: removeCoupon(dispatch),
  };
};

const removeCoupon = (dispatch: any) => (couponCode: string, isOmniChannelCoupon: boolean, isNectar: boolean) => {
  dispatch({
    type: ActionType.start,
  });
  return removeCouponRequest(couponCode, isOmniChannelCoupon)
    .then(result => {
      if (!result.isSuccess()) {
        dispatch({
          type: ActionType.error,
          data: {
            couponCode,
            isNectar,
          },
          error: result.errors,
        });
        return;
      }
      dispatch({
        type: ActionType.success,
        data: {
          couponCode,
          isNectar,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: ActionType.error,
        data: {
          couponCode,
          isNectar,
        },
        error,
      });
    });
};

function reducer<ResultData>(state: State<ResultData>, action: Action<ResultData>): State<ResultData> {
  switch (action.type) {
    case ActionType.start:
      return {
        status: DataState.PENDING,
        data: undefined,
        error: undefined,
      };
    case ActionType.success:
      return {
        status: DataState.SUCCESS,
        data: action.data,
        error: undefined,
      };
    case ActionType.error:
      return {
        status: DataState.FAILED,
        data: action.data,
        error: action.error,
      };
    default:
      return state;
  }
}
