import { sendImpression } from "../../services/content";
import { digitalDataGenericTrackEvent, INTERACTION_EVENT_NAMES } from "../analytics";
import { pushEvent } from "../../components/Espots/espot.digitaldata";
import { sendAnalyticsEvent } from "./analyticsEvent";
import { AnalyticsEvent } from "./types";

const viewedComponents = new Set<Element>();
let impressionBatch: string[] = [];
let impressionTimeout: NodeJS.Timeout | null = null;
const MAX_BATCH_SIZE = 10;
const STOP_SCROLL_DELAY = 4000;

export const CMScontentViewAnalyticsEvent = {
  eventName: INTERACTION_EVENT_NAMES.CMS_COMPONENT_VIEW,
  eventCategory: "CMS Component",
  eventAction: "Impression",
};

/**
 * onClick handler for any magnolia cms component template.
 * @param {React.MouseEvent<HTMLElement>} event (required) - the click event. The fn is built to handle the bubbling of the event for any child elements within the anchor links which are on the page.
 *
 * The following attributes are applied to link elements via the MagnoliaRenderer component (using @sainsburys-tech/bolt under the hood):
 * - '[data-name="ev_cmsComponentClick"]'
 * - '[data-label="some - label"]'
 * - '[data-parent="parent name"]'
 *
 * It can be useful to add a "event.preventDefault();" as the first line during development/debugging to avoid constantly navigating.
 */
export const magnoliaTemplateClickHandler = (event: React.MouseEvent<HTMLElement>) => {
  const target = event.target;

  if (target instanceof HTMLElement) {
    const parentWithDataLabel = target?.closest('[data-name="ev_cmsComponentClick"]') as HTMLElement;

    if (parentWithDataLabel) {
      trackParentClick(parentWithDataLabel);
    } else {
      const eventAction = target.closest('[data-event-action="click"]') as HTMLElement;
      if (eventAction) {
        sendMagnoliaClickTrackEvent(eventAction);
      }
    }
  }
};

const trackParentClick = (parent: HTMLElement) => {
  const eventLabel = parent.getAttribute("data-label") ?? undefined;
  const linkParentHeader = parent.getAttribute("data-parent") ?? undefined;
  const linkDestination = parent.getAttribute("href") ?? undefined;

  eventLabel &&
    sendAnalyticsEvent({
      eventName: INTERACTION_EVENT_NAMES.CMS_COMPONENT_CLICK,
      eventCategory: "CMS Component",
      eventAction: "Click",
      ...(eventLabel ? { eventLabel } : {}),
      ...(linkParentHeader ? { linkParentHeader } : {}),
      ...(linkDestination ? { linkDestination } : {}),
    });
};

export const sendMagnoliaClickTrackEvent = (target: HTMLElement) => {
  const componentClassification = target.getAttribute("data-component-classification") ?? undefined;
  const eventLabel = target.getAttribute("data-event-label") ?? undefined;
  const eventCategory = target.getAttribute("data-event-category") ?? undefined;
  const eventActionType = target.getAttribute("data-event-action") ?? undefined;

  const analyticsEvent: AnalyticsEvent = {
    ...(eventLabel ? { data_event_label: eventLabel } : {}),
    ...(eventCategory ? { data_event_category: eventCategory } : {}),
    ...(eventActionType ? { data_event_action: eventActionType } : {}),
    ...(componentClassification ? { data_component_classification: componentClassification } : {}),
  };
  digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.CMS_COMPONENT_CLICK, analyticsEvent);
};

const sendImpressions = () => {
  if (impressionBatch.length > 0) {
    const eventLabel = impressionBatch.join(", ");
    eventLabel &&
      sendAnalyticsEvent({
        ...CMScontentViewAnalyticsEvent,
        eventLabel,
      });
    impressionBatch = [];
  }
  if (impressionTimeout) {
    clearTimeout(impressionTimeout);
    impressionTimeout = null;
  }
};

// Function to handle observed CMS component entering viewport
const handleIntersection = (entries: IntersectionObserverEntry[]) => {
  entries.forEach(entry => {
    const element = entry.target;
    const isVisible = checkVisibility(entry);

    if (isVisible && !viewedComponents.has(element)) {
      const hrefValue = element.getAttribute("href") ?? "";
      const url = new URL(hrefValue, "https://sainsburys.co.uk");
      const name = url?.searchParams.get("name") ?? undefined;

      viewedComponents.add(element);
      handlePromoImpressionEvent(element, url, name);
      handleImpressionEventBatch(element, name);
    }
  });
};

const checkVisibility = (entry: IntersectionObserverEntry): boolean => {
  return entry.isIntersecting && entry.intersectionRatio >= 0.5; // 50% of the component is visible
};

const handlePromoImpressionEvent = (element: Element, url?: URL, name?: string) => {
  const adId = url?.searchParams.get("adId") ?? null;

  if (name && adId) {
    const promo = {
      promo_spot_name: adId,
      promo_spot_id: name ? `Citrus - ${name}` : adId,
    };
    pushEvent({
      eventAction: "impression",
      eventName: "promoEvent",
      promo,
    });
  }
};

const handleImpressionEventBatch = (element: Element, name?: string) => {
  const eventLabel = element.getAttribute("data-label") ?? "";

  if (eventLabel && !name) {
    impressionBatch.push(eventLabel);

    // If batch reaches max size, send impressions immediately
    if (impressionBatch.length >= MAX_BATCH_SIZE) {
      sendImpressions();
    } else {
      // If the user stops scrolling for 4 seconds, send the impressions
      if (impressionTimeout) clearTimeout(impressionTimeout);
      impressionTimeout = setTimeout(() => sendImpressions(), STOP_SCROLL_DELAY);
    }
  }
};

const getDocumentCmsComponents = (): NodeListOf<Element> => {
  const w: any = window;
  const document: Document = w.document;
  return document.querySelectorAll("[data-name='ev_cmsComponentLoad']");
};

const processCmsComponent = (component: Element, isZoneCitrusGroup?: boolean): string => {
  let eventLabel = "";
  if (component.getAttribute("data-label")) {
    // Detect if the component is Citrus associated and send Citrus Impression
    const destination = component.getAttribute("href") ?? "";
    const isCitrusAssociatedContent = destination.includes("shotgun");
    const url = isCitrusAssociatedContent ? new URL(destination, "https://sainsburys.co.uk") : null;
    const adId = url?.searchParams.get("adId") ?? null;
    const name = url?.searchParams.get("name") ?? undefined;
    if (adId && isZoneCitrusGroup) {
      sendImpression(adId, "Zone_1", name);
      /**
       * For the above scenario the slot ID has been hardcoded.
       * This is because currently only zone pages show citrus
       * ads in above grid section. Feel free to modify this as
       * we progress further with different approach for Browse
       */
    } else {
      eventLabel = component.getAttribute("data-label") + ", ";
    }
  }
  return eventLabel;
};

const sendCmsComponentLoadEvent = (eventLabel: string) => {
  eventLabel = eventLabel.trim().substring(0, eventLabel.length - 2); // remove trailing comma ", "
  if (eventLabel) {
    sendAnalyticsEvent({
      eventName: INTERACTION_EVENT_NAMES.CMS_COMPONENT_LOAD,
      eventCategory: "CMS Component",
      eventAction: "Load",
      eventLabel,
    });
  }
};

// Adds the observer to track impressions when components come into view
const trackCmsComponents = (components: NodeListOf<Element>, cmsIntersectionObserver: IntersectionObserver) => {
  components.forEach((component: Element) => {
    component.setAttribute("data-name", "ev_cmsComponentLoadTracked");
    if (!viewedComponents.has(component)) {
      cmsIntersectionObserver.observe(component);
    }
  });
};

const DOCUMENT_LOAD_CHECK_INTERVAL = 2000;
const MAX_INTERVAL_REPETITIONS = 10;
export const trackCMSComponentAnalytics = (isZoneCitrusGroup?: boolean) => {
  const cmsIntersectionObserver = new IntersectionObserver(handleIntersection, {
    root: null,
    threshold: 0.5,
  });
  let intervalRepetitions = 0;

  const interval = setInterval(() => {
    try {
      const cmsComponents = getDocumentCmsComponents();
      let eventLabel = "";

      cmsComponents.forEach(component => {
        eventLabel += processCmsComponent(component, isZoneCitrusGroup);
      });

      if (cmsComponents.length) {
        sendCmsComponentLoadEvent(eventLabel);
        trackCmsComponents(cmsComponents, cmsIntersectionObserver);
        clearInterval(interval);
      }

      // Wait at max 20 seconds for page to render CMS components
      if (intervalRepetitions >= MAX_INTERVAL_REPETITIONS) {
        clearInterval(interval);
      } else {
        intervalRepetitions++;
      }
    } catch (e) {
      console.error("Error in trackCMSComponentAnalytics interval", e);
    }
  }, DOCUMENT_LOAD_CHECK_INTERVAL);

  return () => cmsIntersectionObserver.disconnect();
};
