import { handleUnauthorized } from "../../common/responseErrorHandler";
import { mapPaginatedOrders } from "./order";
import {
  CancelOrderSuccessAction,
  FetchOrdersErrorAction,
  FetchOrdersPendingAction,
  FetchOrdersSuccessAction,
  OrdersAction,
  PaginatedOrders,
  StopAmendingOrderErrorAction,
  StopAmendingOrderPendingAction,
  StopAmendingOrderSuccessAction,
} from "./order.types";
import { AppThunkDispatch } from "../../common/types";
import {
  cancelOrder,
  fetchOrders as servicesFetchOrders,
  startAmendingOrder as servicesStartAmendingOrder,
  stopAmendingOrder as servicesStopAmendingOrder,
} from "../../services/order";
import { push } from "connected-react-router";
import { urls } from "../../routes";
import { fetchBasket } from "../basket/basket.actions";
import { fetchSlotReservation } from "../slot/slotReservation.actions";

export const fetchOrdersSuccessAction = (paginatedOrders: PaginatedOrders): FetchOrdersSuccessAction => ({
  type: OrdersAction.FETCH_ORDERS_SUCCESS,
  paginatedOrders,
});

export const fetchOrdersErrorAction = (): FetchOrdersErrorAction => ({
  type: OrdersAction.FETCH_ORDERS_ERROR,
});

export const fetchOrdersPendingAction = (): FetchOrdersPendingAction => ({
  type: OrdersAction.FETCH_ORDERS_PENDING,
});

export const fetchOrders =
  (pageNumber: number = 1, pageSize: number = 10) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(fetchOrdersPendingAction());

    const result = await servicesFetchOrders(pageNumber, pageSize);
    dispatch(handleUnauthorized(result));

    if (result.isSuccess()) {
      dispatch(fetchOrdersSuccessAction(mapPaginatedOrders(result.data)));
    } else {
      dispatch(fetchOrdersErrorAction());
    }
  };

export const stopAmendingOrderSuccessAction = (): StopAmendingOrderSuccessAction => ({
  type: OrdersAction.STOP_AMENDING_ORDER_SUCCESS,
});

export const stopAmendingOrderErrorAction = (): StopAmendingOrderErrorAction => ({
  type: OrdersAction.STOP_AMENDING_ORDER_ERROR,
});

export const stopAmendingOrderPendingAction = (): StopAmendingOrderPendingAction => ({
  type: OrdersAction.STOP_AMENDING_ORDER_PENDING,
});

export const stopAmendingOrder =
  () =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(stopAmendingOrderPendingAction());

    const result = await servicesStopAmendingOrder();

    if (result.isSuccess()) {
      dispatch(stopAmendingOrderSuccessAction());
      dispatch(fetchBasket());
      dispatch(fetchSlotReservation());
    } else {
      dispatch(stopAmendingOrderErrorAction());
    }
  };

export const startAmendingOrderSuccessAction = (): StopAmendingOrderSuccessAction => ({
  type: OrdersAction.STOP_AMENDING_ORDER_SUCCESS,
});

export const startAmendingOrderErrorAction = (): StopAmendingOrderErrorAction => ({
  type: OrdersAction.STOP_AMENDING_ORDER_ERROR,
});

export const startAmendingOrderPendingAction = (): StopAmendingOrderPendingAction => ({
  type: OrdersAction.STOP_AMENDING_ORDER_PENDING,
});

export const startAmendingOrder =
  (orderUid: string, setShowErrorBanner: (showErrorBanner: boolean) => void, setIsOpen: (arg0: boolean) => void) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(startAmendingOrderPendingAction());
    const result = await servicesStartAmendingOrder(orderUid);
    if (result.isSuccess()) {
      setIsOpen(false);
      dispatch(startAmendingOrderSuccessAction());
      dispatch(push(urls.TROLLEY));
    } else {
      setShowErrorBanner(true);
    }
  };

export const cancelOrderSuccessAction = (orderUid: string): CancelOrderSuccessAction => ({
  type: OrdersAction.CANCEL_ORDER_SUCCESS,
  orderUid,
});

interface CancelOrderRequestProps {
  orderId: string;
  setShowErrorBanner: (showErrorBanner: boolean) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const cancelOrderRequest =
  ({ orderId, setShowErrorBanner, setIsOpen }: CancelOrderRequestProps) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    const result = await cancelOrder(orderId);
    if (result.isSuccess()) {
      setIsOpen(false);
      dispatch(cancelOrderSuccessAction(orderId));
      dispatch(push(urls.MY_ORDERS));
    } else {
      setShowErrorBanner(true);
    }
  };
