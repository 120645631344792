import { Dispatch, MiddlewareAPI } from "redux";
import { State } from "../../../common/store";
import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router";
import { routes } from "../../../routes";
import { AppThunkDispatch, LocationChangeAction } from "../../../common/types";
import {
  fetchFavouritesThunk,
  fetchSeasonalFavouritesThunk,
  newFetchFavouritesThunk,
} from "@favourites/state/Favourites.actions";
import { extractQueryParams } from "../../../components/ProductControls/productControls";
import { FavouritesActionTypes, DeleteFavouritesSuccessAction } from "@favourites/Favourites.types";
import { serviceWorkerClient } from "../../../common/serviceWorkerClient";
import { ModalNames } from "../../../components/Modal/Modal.types";
import { selectIsRegistered } from "../../../components/Authorized";
import { waitForState } from "../../../common/middleware";
import { useFeatureFlag } from "../../../common/firebase/featureFlags.hooks";

export function favouritesMiddleware(api: MiddlewareAPI<AppThunkDispatch, State>) {
  const { isStateReady: isRegisteredUserLoggedIn, checkState } = waitForState(selectIsRegistered);

  return (next: Dispatch) => (action: LocationChangeAction | DeleteFavouritesSuccessAction) => {
    checkState(api.getState());

    switch (action.type) {
      case LOCATION_CHANGE:
        const { payload } = action as LocationChangeAction;

        const isFavourites = isFavouritesPage(payload.location.pathname);

        if (isFavourites) {
          const params = extractQueryParams(payload.location.search);
          params.isSlotLocked = !!api.getState().basket?.basketDetails?.isSlotLocked;

          isRegisteredUserLoggedIn.then(() => {
            if (useFeatureFlag("seasonal_favourites")) {
              api.dispatch(fetchSeasonalFavouritesThunk(params));
            }

            if (useFeatureFlag("add_to_favourites")) {
              api.dispatch(newFetchFavouritesThunk(params));
            } else {
              api.dispatch(fetchFavouritesThunk(params));
            }
          });
        }

        break;

      /** @deprecated to be refactored after add to favourites release */
      case FavouritesActionTypes.DELETE_FAVOURITES_SUCCESS:
        serviceWorkerClient.clearCache();
        break;

      /** @deprecated to be refactored after add to favourites release */
      case ModalNames.MODAL_REMOVE_FROM_FAVOURITES_ERROR:
        serviceWorkerClient.clearCache();
        break;

      default:
        break;
    }
    next(action);
  };
}

export const isFavouritesPage = (path: string) =>
  matchPath(path, {
    path: [routes.FAVOURITES],
    exact: true,
  });
