import React from "react";
import { TemplateArea } from "../../services/contentTypes";
import { UseProductsReturnType } from "../../common/hooks/useProducts";

export const ButtonTexts = {
  clickAndCollect: "Click & Collect",
  homeDelivery: "Delivery",
};

export enum HomepageSponsoredProductsCarouselsKey {
  HOME_PAGE_SPONSORED_PRODUCTS = "homepage-sponsored-products",
}

export interface ActionsItem {
  url: string;
  label: string;
  icon: React.ReactNode;
  class?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export interface MagnoliaWrapperProps {
  template: TemplateArea;
}

export interface HomepageSponsoredCarouselProps {
  addProductForViewportAnalytics: UseProductsReturnType["addProductForViewportAnalytics"];
  scrollCallback: UseProductsReturnType["scrollCallback"];
}
