import { PreviousOrdersAction, PreviousOrdersState } from "./previousOrders.types";
import { DataState } from "../../common/dataState";
import { combineReducers } from "redux";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import { ResponseErrorActionType } from "../../common/types";

export const previousOrdersInitialState: PreviousOrdersState = {
  dataState: DataState.UNKNOWN,
  addAllToTrolleyDataState: DataState.UNKNOWN,
};

const dataState = createDataStateReducer({
  [PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_PENDING]: DataState.PENDING,
  [PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_SUCCESS]: DataState.SUCCESS,
  [PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_ERROR]: DataState.FAILED,
  [ResponseErrorActionType.HTTP_UNAUTHORIZED_ERROR]: previousOrdersInitialState.dataState,
});

const addAllToTrolleyDataState = createDataStateReducer({
  [PreviousOrdersAction.ADD_ORDER_TO_BASKET_REQUEST]: DataState.PENDING,
  [PreviousOrdersAction.ADD_ORDER_TO_BASKET_SUCCESS]: DataState.SUCCESS,
  [PreviousOrdersAction.ADD_ORDER_TO_BASKET_FAILURE]: DataState.FAILED,
  [ResponseErrorActionType.HTTP_UNAUTHORIZED_ERROR]: previousOrdersInitialState.addAllToTrolleyDataState,
});

export const previousOrdersReducer = combineReducers({
  dataState,
  addAllToTrolleyDataState,
});
