import React from "react";
import { MobileMegaNavItem } from "./MobileMegaNavItem";
import { MegaNavColumnProps } from "../MegaNav.types";
import { GOLLink } from "../../../GOLLink";
import { checkMegaNavUrl } from "../MegaNavShared";

const titleClasses = "megaNavDrawer__groceriesHome megaNavDrawer__item megaNavDrawer__groceryItem";

export const GroceriesSection: React.FC<MegaNavColumnProps> = ({
  title,
  items,
  onSelected,
  titleUrl,
  showHomeSuffix,
}) => {
  const { url, extern } = titleUrl ? checkMegaNavUrl(titleUrl) : { url: "", extern: false };
  return (
    <div data-testid="groceries-section">
      {titleUrl ? (
        <GOLLink to={url} external={extern} className={titleClasses}>
          {title !== "Groceries" && "View "} {title} {showHomeSuffix && "Home"}
        </GOLLink>
      ) : (
        <span className={titleClasses}>{title}</span>
      )}
      {items?.map(item => (
        <li key={item.id} className="megaNavDrawer__item">
          <MobileMegaNavItem item={item} onSelected={onSelected} isGroceryItem={true} />
        </li>
      ))}
    </div>
  );
};
