import { Method, Result } from "../common/http/request";
import { Optional } from "../common/types";
import {
  Basket,
  BasketAddOrder,
  BasketItemAdd,
  BasketItemSubstitutionPreference,
  BasketItemUpdate,
} from "./basket.types";
import { encodeQueryParams } from "../common/http/query";
import { serviceRequest } from "./request";

const url = "/groceries-api/gol-services/basket";

type fetchBasketParams = {
  calculateOrder?: boolean;
  baseUrl?: string;
  pickTime?: string;
  storeNum?: string;
  slotBooked?: boolean;
};
export const fetchBasket = ({
  calculateOrder,
  pickTime,
  storeNum,
  slotBooked,
  baseUrl = url,
}: fetchBasketParams): Promise<Result<Basket>> => {
  const params = {
    calculate: calculateOrder,
    pick_time: pickTime,
    store_number: storeNum,
    slot_booked: slotBooked,
  };
  return serviceRequest<Basket>({ method: Method.GET, url: `${baseUrl}/v2/basket${encodeQueryParams(params)}` });
};

export const deleteBasket = (baseUrl: Optional<string> = url): Promise<Result<void>> =>
  serviceRequest<void>({ method: Method.DELETE, url: `${baseUrl}/v2/basket` });

export const updateBasket = (
  basketItem: BasketItemUpdate,
  pickTime?: string,
  storeNum?: string,
  slotBooked?: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<Basket>> => {
  const params = {
    pick_time: pickTime,
    store_number: storeNum,
    slot_booked: slotBooked,
  };

  return serviceRequest<Basket>({
    method: Method.PUT,
    url: `${baseUrl}/v2/basket${encodeQueryParams(params)}`,
    body: {
      items: [basketItem],
    },
  });
};

export const addItemToBasket = (
  basketItem: BasketItemAdd,
  calculateOrder = false,
  pickTime?: string,
  storeNum?: string,
  slotBooked?: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<Basket>> => {
  const params = {
    calculate: calculateOrder || undefined,
    pick_time: pickTime,
    store_number: storeNum,
    slot_booked: slotBooked,
  };
  return serviceRequest<Basket>({
    method: Method.POST,
    url: `${baseUrl}/v2/basket/item${encodeQueryParams(params)}`,
    body: basketItem,
  });
};

export const addItemsToBasket = (
  basketItems: BasketItemAdd[],
  calculateOrder = false,
  pickTime?: string,
  storeNum?: string,
  slotBooked?: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<Basket>> => {
  const params = {
    calculate: calculateOrder || undefined,
    pick_time: pickTime,
    store_number: storeNum,
    slot_booked: slotBooked,
  };

  // Move C62 to end of array as temporary fix for the catchweight issue
  basketItems.sort((a, b) => {
    const vA = a.uom === "C62" ? 1 : 0;
    const vB = b.uom === "C62" ? 1 : 0;

    if (vA < vB) {
      return -1;
    } else if (vA > vB) {
      return 1;
    }

    return 0;
  });

  return serviceRequest<Basket>({
    method: Method.POST,
    url: `${baseUrl}/v2/basket/items${encodeQueryParams(params)}`,
    body: basketItems,
  });
};

export const deleteItemsFromBasket = (
  basketItemIds: string[],
  pickTime?: string,
  storeNum?: string,
  slotBooked?: boolean,
  basketItemIdsV2?: {
    sku: string;
    itemId: string;
  }[],
  force?: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<Basket>> => {
  const params = {
    pick_time: pickTime,
    store_number: storeNum,
    slot_booked: slotBooked,
    force_delete: force,
  };
  if (basketItemIdsV2) {
    return serviceRequest<Basket>({
      method: Method.DELETE,
      url: `${baseUrl}/v2/basket/items${encodeQueryParams(params)}`,
      body: { items: basketItemIdsV2 },
    });
  } else {
    return serviceRequest<Basket>({
      method: Method.DELETE,
      url: `${baseUrl}/v2/basket/items${encodeQueryParams(params)}`,
      body: { item_ids: basketItemIds },
    });
  }
};

export const addOrderToBasket = (
  orderID: string,
  pickTime?: string,
  storeNum?: string,
  slotBooked?: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<BasketAddOrder>> => {
  const params = {
    pick_time: pickTime,
    store_number: storeNum,
    slot_booked: slotBooked,
  };
  return serviceRequest<BasketAddOrder>({
    method: Method.POST,
    url: `${baseUrl}/v2/basket/item/_addFromOrder${encodeQueryParams(params)}`,
    body: {
      order_uid: orderID,
    },
  });
};

export const updateSubstitutionPreferences = (
  basketItem: BasketItemSubstitutionPreference,
  pickTime?: string,
  storeNum?: string,
  slotBooked?: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<Basket>> => {
  const params = {
    pick_time: pickTime,
    store_number: storeNum,
    slot_booked: slotBooked,
  };
  return serviceRequest<Basket>({
    method: Method.PUT,
    url: `${baseUrl}/v2/basket/substitutions${encodeQueryParams(params)}`,
    body: {
      items: [basketItem],
    },
  });
};
