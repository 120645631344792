import React from "react";
import { ErrorPage } from "./ErrorPage";
import fruitImage from "../../static/empty-trolley.svg";
import { urls } from "../../routes";
import { GOLLink } from "../GOLLink";
import { Helmet } from "react-helmet";

interface NotFoundErrorPageProps {
  noindex?: boolean;
}

export const NotFoundErrorPage: React.FC<NotFoundErrorPageProps> = ({ noindex = false }) => (
  <>
    {noindex && (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    )}
    <ErrorPage
      header="Oops!"
      description="We’re sorry but the page cannot be found"
      image={<img alt="Fruit" src={fruitImage} />}
      callToAction={
        <>
          Here are some helpful links instead:
          <ol className="error-page__link-list">
            <li>
              <GOLLink to={urls.GROCERIES_URL} external>
                Home
              </GOLLink>
            </li>
            <li>
              <GOLLink to={urls.FAVOURITES} external>
                Favourites
              </GOLLink>
            </li>
            <li>
              <GOLLink to={urls.DISCOVER_URL} external>
                Discover
              </GOLLink>
            </li>
            <li>
              <GOLLink to={urls.BOOK_DELIVERY_SLOT} external>
                Book Delivery
              </GOLLink>
            </li>
            <li>
              <GOLLink to={urls.TROLLEY}>My Trolley</GOLLink>
            </li>
          </ol>
        </>
      }
    />
  </>
);
NotFoundErrorPage.displayName = "NotFoundErrorPage";
