import { OrderSummary } from "../../services/order.types";
import { WithDataState } from "../../common/dataState";
import { SlotType } from "../../services/slot.types";

export interface SlotList {
  slots: SingleSlotDay;
  saverSlots: SingleSlotDay;
  totalDays?: number;
  weeks: Week[];
  slotTypes: SlotType[];
  selectedSlotType: string;
  slotTable: SlotsByTime;
  postcode: string;
  addressNickname: string;
  noAvailableSlots: boolean;
}

export interface MapSlotList {
  slots: SingleSlotDay;
  weeks: Week[];
  postcode: string;
  addressNickname: string;
  noAvailableSlots: boolean;
}

export interface SlotsByTime {
  [time: string]: SingleSlot[];
}

export function containsSlot(slots: SingleSlot[], slot: SingleSlot): boolean {
  return slots.some(s => s.startTime === slot.startTime && s.endTime === slot.endTime);
}

export interface MapSlotTable {
  slotsByTime: SlotsByTime;
}

export interface SingleSlotDay {
  [key: string]: SingleSlotDayTime; // key is date "2020-01-01"
}

export interface SingleSlotDayTime {
  [key: string]: SingleSlot[]; // key is time of day ie "afternoon": [...slots]
}

export interface SingleSlot {
  startTime: string;
  endTime: string;
  price: string;
  isGreen: boolean;
  isAlcoholRestricted: boolean;
  isAvailable: boolean;
  isBookedByCustomer: boolean;
  isDeliveryPassApplicable: boolean;
  bookingKey: string;
  isSameDay: boolean;
  isDummySlot?: boolean;
  associatedOrder?: OrderSummary;
  slotType: string;
}

export interface Week {
  number: number;
  startDate: string;
  endDate: string;
}

export interface Hours {
  [key: string]: string[]; // "morning": ["8:00 - 9:00 am"]
}

export interface SlotListState extends WithDataState {
  data: SlotList;
}

export type FetchSlotListRequestAction = {
  type: SlotListActionTypes.FETCH_SLOT_LIST_REQUEST;
};

export type ResetSlotListStateAction = {
  type: SlotListActionTypes.RESET_SLOT_LIST_STATE;
};

export type FetchSlotListSuccessAction = {
  type: SlotListActionTypes.FETCH_SLOT_LIST_SUCCESS;
  data: MapSlotList;
};

export type FetchSaverSlotListSuccessAction = {
  type: SlotListActionTypes.FETCH_SAVER_SLOT_LIST_SUCCESS;
  data: MapSlotList;
};

export type FetchSlotTableSuccessAction = {
  type: SlotListActionTypes.FETCH_SLOT_TABLE_SUCCESS;
  data: MapSlotTable;
};

export type FetchSlotListFailureAction = {
  type: SlotListActionTypes.FETCH_SLOT_LIST_FAILURE;
  message: string;
};

export type FetchSlotListSlotTypesAction = {
  type: SlotListActionTypes.FETCH_SLOT_LIST_SLOT_TYPES;
  data: SlotType[];
};

export type SetSelectedSlotTypeAction = {
  type: SlotListActionTypes.SET_SELECTED_SLOT_TYPE;
  slotType: string;
};

export enum SlotListActionTypes {
  FETCH_SLOT_LIST_REQUEST = "FETCH_SLOT_LIST_REQUEST",
  FETCH_SLOT_LIST_SUCCESS = "FETCH_SLOT_LIST_SUCCESS",
  FETCH_SAVER_SLOT_LIST_SUCCESS = "FETCH_SAVER_SLOT_LIST_SUCCESS",
  FETCH_SLOT_LIST_FAILURE = "FETCH_SLOT_LIST_FAILURE",
  RESET_SLOT_LIST_STATE = "RESET_SLOT_LIST_STATE",
  FETCH_SLOT_LIST_SLOT_TYPES = "FETCH_SLOT_LIST_SLOT_TYPES",
  SET_SELECTED_SLOT_TYPE = "SET_SELECTED_SLOT_TYPE",
  FETCH_SLOT_TABLE_SUCCESS = "FETCH_SLOT_TABLE_SUCCESS",
}

export type SlotTypes = {
  SAVER_SLOT: string;
  HOUR_SLOT: string;
};
