import { useEffect, useRef } from "react";

/*
  This hook is useful for when a component or hook has an asynchronous action that could potentially complete after the component is unmounted from the DOM.

  An example of this might be where a HTTP request completes after a component is unmounted from the DOM. In this scenario you wouldn't want to try to update that component, because it is no longer in the component tree.
*/

export const useIsMounted = () => {
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return { isMountedRef };
};
