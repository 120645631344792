import { LocationChangeAction } from "../../common/types";
import { ProductControlsChangedAction } from "../../components/ProductControls/productControls.types";
import { ProductsByCategory } from "@favourites/Favourites.types";
import { DataState, WithDataState } from "../../common/dataState";
import { InterleavedEspot } from "../../components/Espots/Espot.types";
import { PaginatedProducts, Product, ProductUid } from "../../domain/product/product.types";
import { FetchOrdersSuccessAction } from "../../domain/order/order.types";
import { BasketAddOrderError } from "../../domain/basket/basket.types";

export type PreviousOrdersActionTypes =
  | LocationChangeAction
  | SelectPreviousOrderAction
  | FetchPreviousOrdersSuccess
  | FetchOrdersSuccessAction
  | ProductControlsChangedAction;

export enum PreviousOrdersAction {
  SELECT_PREVIOUS_ORDER = "SELECT_PREVIOUS_ORDER",
  FETCH_PRODUCTS_BY_ORDER_SUCCESS = "FETCH_PRODUCTS_BY_ORDER_SUCCESS",
  FETCH_PRODUCTS_BY_ORDER_PENDING = "FETCH_PRODUCTS_BY_ORDER_PENDING",
  FETCH_PRODUCTS_BY_ORDER_ERROR = "FETCH_PRODUCTS_BY_ORDER_ERROR",
  ADD_ORDER_TO_BASKET_REQUEST = "ADD_ORDER_TO_BASKET_REQUEST",
  ADD_ORDER_TO_BASKET_SUCCESS = "ADD_ORDER_TO_BASKET_SUCCESS",
  ADD_ORDER_TO_BASKET_FAILURE = "ADD_ORDER_TO_BASKET_FAILURE",
  ADD_ORDER_TO_BASKET_ERRORS = "ADD_ORDER_TO_BASKET_ERRORS",
}

export type DispatchablePreviousOrdersActionTypes = {
  type: PreviousOrdersAction;
};

export type SelectPreviousOrderAction = {
  type: PreviousOrdersAction.SELECT_PREVIOUS_ORDER;
  orderUid: string;
};

export interface UsePreviousOrdersReturnValue {
  previousOrders: PreviousOrder[];
  isError: boolean;
  areOrdersPending: boolean;
  products: ProductUid[];
  isCategorySort: boolean;
  productsByCategory: ProductsByCategory[];
  isAddAllPending: boolean;
  productsStatus: ProductsStatus;
  espots?: InterleavedEspot[];
  exampleProduct?: Product;
  isNIPostcode: boolean;
  addAllProductsToTrolley: () => void;
  onOrderSelect: (orderUid: string) => void;
  onLoad: () => void;
}

export enum ProductsStatus {
  PENDING = "PENDING",
  READY = "READY",
  UNKNOWN = "UNKNOWN",
}

export interface PreviousOrdersContainerDispatchToProps {}

export interface PreviousOrder {
  time: string;
  orderUid: string;
}

export interface PreviousOrdersSelectProps {
  orders: PreviousOrder[];
  defaultOrder: PreviousOrder;
  onSelect: (orderUid: string) => void;
  isPending: boolean;
}

export type FetchPreviousOrdersSuccess = {
  type: PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_SUCCESS;
  products: PaginatedProducts;
};

export type FetchPreviousOrdersPending = {
  type: PreviousOrdersAction.FETCH_PRODUCTS_BY_ORDER_PENDING;
};

export interface PreviousOrdersState extends WithDataState {
  addAllToTrolleyDataState: DataState;
}

export type AddOrderToBasketRequestAction = {
  type: PreviousOrdersAction.ADD_ORDER_TO_BASKET_REQUEST;
  orderID: string;
};

export type AddOrderToBasketFailureAction = {
  type: PreviousOrdersAction.ADD_ORDER_TO_BASKET_FAILURE;
};

export type AddOrderToBasketErrorsAction = {
  type: PreviousOrdersAction.ADD_ORDER_TO_BASKET_ERRORS;
  errors: BasketAddOrderError[];
};
