import { AnyAction, Dispatch } from "redux";
import { push, RouterState } from "connected-react-router";
import { selectBookingKey, selectStoreNumber } from "./slotReservation.selectors";
import {
  ReserveSlotFailureAction,
  ReserveSlotRequestAction,
  ReserveSlotSuccessAction,
  SetSlotReferrerAction,
  SlotReservationActions,
} from "./slotReservation.types";
import { createCloseCurrentlyVisibleModalAction, createCloseModalAction } from "./Modal/modal.actions";
import { selectSlotStartTime } from "./Modal/modal.selectors";
import { ModalNames } from "./Modal/Modal.types";
import { useFeatureFlag } from "../../common/firebase/featureFlags.hooks";
import { encodeQueryParams, queryParamValue } from "../../common/http/query";
import { createExternalNavigationAction } from "../../common/middleware/navigationMiddleware";
import { State } from "../../common/store";
import {
  addSlotItemToBasket,
  createUpdateBasketErrorAction,
  createUpdateBasketSuccessAction,
} from "../../domain/basket/basket.actions";
import GolTime from "../../domain/time";
import { urls } from "../../routes";
import { reserveSlot } from "../../services/slot";
import { ReserveSlot, ReserveSlotWithBookingKey, SlotStatus } from "../../services/slot.types";
import { mapBasketToDomain } from "../../domain/basket/basket";

export const createReserveSlotRequestAction = (): ReserveSlotRequestAction => ({
  type: SlotReservationActions.RESERVE_SLOT_REQUEST,
});

export const createReserveSlotSuccessAction = (): ReserveSlotSuccessAction => ({
  type: SlotReservationActions.RESERVE_SLOT_SUCCESS,
});

export const createReserveSlotFailureAction = (message: string): ReserveSlotFailureAction => ({
  type: SlotReservationActions.RESERVE_SLOT_FAILURE,
  message,
});

export const createReserveSlotWithBookingKey = (
  reserveSlot: ReserveSlot,
  bookingKey: string
): ReserveSlotWithBookingKey => ({
  reserve_slot: reserveSlot,
  booking_key: bookingKey,
});

export const reserveSlotAction =
  () =>
  async (dispatch: Dispatch<AnyAction>, getState: () => State): Promise<void> => {
    dispatch(createReserveSlotRequestAction());

    const state: State = getState();
    const bookingKey = selectBookingKey(state);
    const slotStartTime = selectSlotStartTime(state);
    const storeNumber = selectStoreNumber(state);
    const formattedSlotTime = new GolTime(slotStartTime).formatToServices();
    const result = await reserveSlot(bookingKey, false, formattedSlotTime, storeNumber);
    const changesToTrolleyGolUI = useFeatureFlag("changes_to_trolley");
    const router: RouterState<any> = state.router;
    const isOccasionsEnabled = useFeatureFlag("occasions");

    if (result.isSuccess()) {
      // if the user is redirected from a 'change slot' button on a product tile
      if (isOccasionsEnabled && router.location.state?.isOccasionsRestricted) {
        const addResult = await addSlotItemToBasket(router, formattedSlotTime, storeNumber);

        if (addResult.isSuccess()) {
          const basket = mapBasketToDomain(addResult.data);
          dispatch(createUpdateBasketSuccessAction(basket));
          handleSlotSuccess(dispatch, state, result, changesToTrolleyGolUI, bookingKey);
        } else {
          dispatch(createUpdateBasketErrorAction(router.location?.state?.product.productUid));
          dispatch(createReserveSlotFailureAction("ADD_TO_BAG_FAILURE"));
          dispatch(createCloseCurrentlyVisibleModalAction(ModalNames.MODAL_RESERVE_SLOT));
          dispatch(createCloseModalAction());
        }
      } else {
        handleSlotSuccess(dispatch, state, result, changesToTrolleyGolUI, bookingKey);
      }
    } else {
      dispatch(createReserveSlotFailureAction(result.errors.first().detail));
    }
  };

const handleSlotSuccess = (
  dispatch: Dispatch<AnyAction>,
  state: State,
  result: any,
  changesToTrolleyGolUI: boolean,
  bookingKey: string
) => {
  dispatch(createReserveSlotSuccessAction());
  dispatch(createCloseCurrentlyVisibleModalAction(ModalNames.MODAL_RESERVE_SLOT));
  dispatch(createCloseModalAction());

  const referrerUrl = queryParamValue(state.router.location.search, "currentPageUrl");

  if (result.data.status === SlotStatus.SUCCESS) {
    const params = encodeQueryParams({ currentPageUrl: referrerUrl ?? undefined });
    return dispatch(push(`${urls.BOOKING_CONFIRMATION}${params}`));
  }
  if (result.data.status === SlotStatus.CHANGES_REQUIRED) {
    if (changesToTrolleyGolUI) {
      const reserveSlotWithBooking = createReserveSlotWithBookingKey(result.data, bookingKey);
      dispatch(push(urls.CHANGES_TO_TROLLEY_GOLUI, reserveSlotWithBooking));
    }

    if (!changesToTrolleyGolUI) {
      const nextPageAfterBookingSlot = referrerUrl
        ? encodeQueryParams({ currentPageUrl: referrerUrl ? referrerUrl : undefined })
        : "";

      // The previousPageName query param is required by WCS to ensure the user is sent to the confirmation page after confirming changes.
      // When we take over the confirm changes page, we should remove this query param.
      const nextPage = nextPageAfterBookingSlot ?? "";
      const previousPageNameParam = window.location.origin + urls.BOOKING_CONFIRMATION + nextPage;
      const params = encodeQueryParams(
        {
          slot: bookingKey,
          previousPageName: previousPageNameParam,
        },
        false
      );
      dispatch(createExternalNavigationAction(`${urls.CHANGES_TO_TROLLEY}&${params}`));
    }
  }
};

export const createSetSlotReferrerAction = (referrer: string): SetSlotReferrerAction => ({
  type: SlotReservationActions.SET_SLOT_REFERRER,
  data: {
    referrer,
  },
});
