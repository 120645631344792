import { getFirebaseFlags } from "./firebase";
import moment from "moment";
import "moment-timezone";
import { offersConfig, OffersConfig } from "../../views/Offers/config/offers.config";
import { RouteKeys, urls } from "../../routes";
import { NectarDestinationsPageConfig } from "../../views/NectarDestinationPage/types";
import { AddFavouritesFlag, BrandsBackgrounds } from "@favourites/Favourites.types";
import { PromotionalFlag } from "../../components/Header/useMenuPromotionalItem";
import { EventCarouselConfig } from "../../views/Browse/BrowseZone/BrowseZone.types";
import { MealDealCMSTemplateConfig } from "../../views/MealDealBuilder/MealDealBuilder.types";
import { OccasionsNavigationConfig } from "@components/Header/OccasionsMenu/useOccasions";
import { SlotsEventBannerConfig } from "../../components/BookSlot/EventBannerCoordinator/EventBannerCoordinator";
import { RestingSearch } from "../../components/Header/SearchBar/utils/restingSearch";
import { TaggstarConfig } from "../../utils/taggstar/utils";

export type Flags = {
  add_to_favourites: AddFavouritesFlag;
  occasions: boolean;
  occasions_navigation: OccasionsNavigationConfig;
  recipes_share_feature: boolean;
  ads_conditionals: boolean;
  findability_v5: boolean;
  show_static_cnc_messaging: boolean;
  book_slot_banner_espot: boolean;
  fetch_future_slot_weeks: boolean;
  click_and_collect_promo_banner: boolean;
  cookie_law_link: boolean;
  citrus_banners: boolean;
  search_category_match_ab_test: boolean;
  citrus_favourites_trio_banners: boolean;
  offers_trio_banners: boolean;
  offers_trio_banners_single_call: boolean;
  special_logo: string;
  custom_product_messaging: boolean;
  first_favourite: boolean;
  test: boolean;
  promotional_link: PromotionalFlag | null;
  promotional_link2: PromoLink | null;
  findability_search: boolean;
  findability_autosuggest: boolean;
  findability_orchestrator: boolean;
  fto_header_flag: boolean;
  attachments: boolean;
  recurring_slot_skip_opt_out: boolean;
  first_favourite_oauth_entry_point: boolean;
  click_and_collect: boolean;
  favourites_some_products_not_in_stock: boolean;
  nectar_offers: boolean;
  nectar_offers_add_all: boolean;
  seasonal_favourites: boolean;
  cnc_start_amend_order_modal: boolean;
  nectar_header: boolean;
  basket_v2: boolean;
  collection_locations_page_size: boolean;
  cnc_slot_banner_espot: boolean;
  favourites_product_cta_alt: boolean;
  disable_tealium_product_adtracking: boolean;
  get_favourites_from_v2: boolean;
  get_wcs_favourites_from_v2: boolean;
  offers_config: OffersConfig;
  alternatives_modal: boolean;
  relevancy_rank: boolean;
  disable_zonal_ads: boolean;
  changes_to_trolley: boolean;
  google_bot_test: boolean;
  favourites_single_alternative: boolean;
  nectar_destination_page: NectarDestinationsPageConfig;
  nectar_prices_offer_skus_to_exclude: string[] | undefined;
  meal_deal_live: boolean;
  meal_deal_hub_noindex: boolean;
  browse_pills_nav_type: "NONE" | "SCROLL" | "ACCORDION";
  zone_featured: boolean;
  browse_zone_send_page_event: boolean;
  use_cached_findability_results: boolean;
  event_zone_list: string[];
  cms_carousel_zone_list: string[];
  show_ynp_change_slot_banner: boolean;
  recipe_scrapbooks_enabled: boolean;
  event_carousel_skus: EventCarouselConfig | null;
  northfork_calculation_waste: boolean;
  meal_deal_cms_template_ids: MealDealCMSTemplateConfig | null;
  split_savings: boolean;
  trolley_nectar_card: boolean;
  favourites_magnolia: boolean;
  homepage: boolean;
  homepage_speedy_basket: boolean;
  taggstar: boolean;
  pdp_accordions: boolean;
  food_to_order_trial_modal: boolean;
  use_food_basket_service: boolean;
  use_food_basket_service_v2: boolean;
  use_food_basket_service_v3: boolean;
  pdp_meta_desc_template: boolean;
  grouped_meal_deals: boolean;
  favourites_in_grid_ads_kill_switch: boolean;
  pci_phase_2: boolean;
  enable_favourites_priority: boolean;
  meal_deal_builder_nectar_widget: boolean;
  xmas_slot_guide_enabled: boolean;
  new_favourites_filter: boolean;
  slots_event_banner_config: SlotsEventBannerConfig | undefined;
  rokt: boolean;
  sales_window: number;
  order_by_secondary_offers: boolean;
  resting_search: RestingSearch;
  search_below_grid_relevancy_categories: boolean;
  taggstar_config: TaggstarConfig;
  all_ad_components_enabled: boolean;
  left_align_header: boolean;
  hide_desc_mobile: HideDescMobile;
  disabled_routes: RouteKeys[];
  byg_ab_test: boolean;
  brands_background: boolean;
  brands_background_config: BrandsBackgrounds;
  golui_my_addresses: boolean;
  byg_ab_test_page_order: boolean;
  new_global_header: boolean;
  new_filter_pages: { browse: boolean; search: boolean; favourites: boolean; brand: boolean; events: boolean };
  recipe_reviews_enabled: boolean;
  sponsored_drawer: boolean;
  frequently_bought_together: 1 | 2; // 1 = bottom , 2 = top
  slot_v2: boolean;
  krang_alternatives: boolean;
  product_tile_experiment: 1 | 2 | 3 | 4; // 1 = Control | 2 = VariantA | 3 = VariantB | 4 = VariantC
  pci_phase_3: boolean;
  ndp_your_nectar_price_slot_prompt_enabled: boolean;
  seasonal_favourites_db: boolean;
  similar_products_drawer: boolean;
  track_product_tile: boolean;
  fetch_ynp_opt_ins: boolean;
  trackFBT: boolean;
  resting_search_v2: boolean;
};

export interface PromoLink {
  url: string;
  title: string;
}

export type HideDescMobile = {
  enabled: boolean;
  disabled_urls: string[];
};
export type EventDates = {
  [key: string]: string[]; // key is event name, value is the dates, e.g. {"xmas": ["2021-12-20"]}
};

export interface NestedFlags<K> {
  [key: string]: K | NestedFlags<K>;
}

export type ExtendedFlags<K> = Flags | NestedFlags<K>;

export const initialFeatureFlagValues: Flags = {
  add_to_favourites: false,
  occasions: false,
  use_food_basket_service: false,
  use_food_basket_service_v2: false,
  use_food_basket_service_v3: false,
  recipes_share_feature: false,
  ads_conditionals: false,
  findability_v5: false,
  show_static_cnc_messaging: getDefaultShowStaticClickAndCollectMessaging(),
  book_slot_banner_espot: false,
  fetch_future_slot_weeks: false,
  click_and_collect_promo_banner: false,
  cookie_law_link: false,
  citrus_banners: false,
  search_category_match_ab_test: false,
  citrus_favourites_trio_banners: false,
  offers_trio_banners: false,
  offers_trio_banners_single_call: false,
  special_logo: "",
  custom_product_messaging: false,
  test: false,
  first_favourite: false,
  promotional_link: null,
  promotional_link2: null,
  findability_search: true,
  findability_autosuggest: true,
  findability_orchestrator: true,
  fto_header_flag: false,
  attachments: false,
  recurring_slot_skip_opt_out: false,
  first_favourite_oauth_entry_point: false,
  click_and_collect: false,
  favourites_some_products_not_in_stock: false,
  nectar_offers: false,
  nectar_offers_add_all: false,
  seasonal_favourites: false,
  cnc_start_amend_order_modal: false,
  nectar_header: false,
  basket_v2: false,
  collection_locations_page_size: false,
  cnc_slot_banner_espot: false,
  favourites_product_cta_alt: false,
  disable_tealium_product_adtracking: false,
  get_favourites_from_v2: false,
  get_wcs_favourites_from_v2: false,
  krang_alternatives: false,
  offers_config: offersConfig,
  alternatives_modal: false,
  relevancy_rank: false,
  disable_zonal_ads: false,
  changes_to_trolley: false,
  google_bot_test: false,
  favourites_single_alternative: true,
  nectar_destination_page: {
    carousels: {
      static_sku_list: {
        title: "Meet Nectar Prices",
        seeAllPath: urls.OFFERS_NECTAR_PRICES,
        enabled: false,
        skus: [
          "2188027",
          "6639802",
          "7975950",
          "7276469",
          "2171869",
          "2055633",
          "2835778",
          "6258977",
          "2022000",
          "8006673",
          "7898788",
          "6420082",
          "6025026",
          "8031215",
          "7710577",
          "7987553",
          "7169889",
          "7847220",
          "7989676",
          "7587583",
        ],
      },
      nectar_popular_promotions: {
        title: "Explore Nectar Prices",
        enabled: true,
        seeAllPath: urls.OFFERS_NECTAR_PRICES,
        interleave_sponsored: false,
      },
      bonus_points: {
        title: "Nectar bonus points",
        enabled: true,
      },
      favourites: {
        title: "Nectar Prices on your Favourites",
        seeAllPath: urls.FAVOURITES_NECTAR_PRICES,
        enabled: true,
      },
      your_nectar_prices: {
        title: "Your Nectar Prices",
        enabled: true,
      },
    },
    magnolia: {
      templateName: "nectar",
      enabled: true,
    },
  },
  nectar_prices_offer_skus_to_exclude: undefined,
  meal_deal_live: false,
  meal_deal_hub_noindex: false,
  browse_pills_nav_type: "NONE",
  zone_featured: false,
  browse_zone_send_page_event: false,
  use_cached_findability_results: true,
  event_zone_list: [],
  cms_carousel_zone_list: [],
  show_ynp_change_slot_banner: false,
  recipe_scrapbooks_enabled: false,
  event_carousel_skus: null,
  northfork_calculation_waste: false,
  split_savings: false,
  trolley_nectar_card: false,
  favourites_magnolia: false,
  homepage: false,
  homepage_speedy_basket: false,
  taggstar: false,
  meal_deal_cms_template_ids: null,
  pdp_accordions: false,
  food_to_order_trial_modal: false,
  pdp_meta_desc_template: true,
  grouped_meal_deals: false,
  favourites_in_grid_ads_kill_switch: false,
  hide_desc_mobile: {
    enabled: false,
    disabled_urls: [],
  },
  pci_phase_2: false,
  enable_favourites_priority: false,
  meal_deal_builder_nectar_widget: false,
  xmas_slot_guide_enabled: false,
  new_favourites_filter: false,
  occasions_navigation: {
    categories: [
      {
        id: "shop-by-category",
        label: "Shop by category",
        links: [
          {
            label: "All food",
            link: "/gol-ui/groceries/occasions-by-sainsburys/all-food/c:1046172",
          },
          {
            label: "Sandwiches & Wraps",
            link: "/gol-ui/groceries/occasions-by-sainsburys/sandwiches-and-wraps/c:1046173",
          },
          {
            label: "Buffet & Sharing",
            link: "/gol-ui/groceries/occasions-by-sainsburys/buffet-and-sharing/c:1046174",
          },
          {
            label: "Cakes",
            link: "/gol-ui/groceries/occasions-by-sainsburys/cakes/c:1046175",
          },
          {
            label: "Desserts",
            link: "/gol-ui/groceries/occasions-by-sainsburys/desserts/c:1046176",
          },
          {
            label: "Party Food",
            link: "/gol-ui/groceries/occasions-by-sainsburys/party-food/c:1046177",
          },
          {
            label: "Sushi",
            link: "/gol-ui/groceries/occasions-by-sainsburys/sushi/c:1046178",
          },
          {
            label: "Dining",
            link: "/gol-ui/groceries/occasions-by-sainsburys/dining/c:1046179",
          },
          {
            label: "Salads & Fruit",
            link: "/gol-ui/groceries/occasions-by-sainsburys/salads-and-fruit/c:1046180",
          },
          {
            label: "Special Diets",
            link: "/gol-ui/groceries/occasions-by-sainsburys/special-diets/c:1046181",
          },
          {
            label: "Vegetarian",
            link: "/gol-ui/groceries/occasions-by-sainsburys/vegetarian/c:1046182",
          },
          {
            label: "Vegan",
            link: "/gol-ui/groceries/occasions-by-sainsburys/vegan/c:1046183",
          },
          {
            label: "Gluten Free",
            link: "/gol-ui/groceries/occasions-by-sainsburys/gluten-free/c:1046184",
          },
        ],
      },
      {
        id: "shop-by-occasion",
        label: "Shop by Occasion",
        links: [
          {
            label: "Work Events",
            link: "/gol-ui/features/work-events",
          },
          {
            label: "Children's Parties",
            link: "/gol-ui/features/fto-childrens-birthday-parties",
          },
          {
            label: "Birthdays & Celebrations",
            link: "/gol-ui/features/fto-birthdays-and-celebrations",
          },
          {
            label: "Entertaining & Hosting",
            link: "/gol-ui/features/fto-entertaining",
          },
        ],
      },
    ],
  },
  slots_event_banner_config: undefined,
  rokt: false,
  sales_window: 1,
  order_by_secondary_offers: false,
  resting_search: {
    suggested_enabled: false,
    magnolia_enabled: false,
    track_experiment: false,
    terms: [],
  },
  search_below_grid_relevancy_categories: false,
  brands_background: false,
  brands_background_config: {},
  taggstar_config: {
    overrideEnabled: false,
    overrides: {},
  },
  all_ad_components_enabled: true,
  left_align_header: false,
  disabled_routes: [],
  byg_ab_test: false,
  golui_my_addresses: false,
  byg_ab_test_page_order: false,
  new_global_header: false,
  new_filter_pages: { browse: false, search: true, favourites: false, brand: false, events: false },
  recipe_reviews_enabled: false,
  sponsored_drawer: false,
  frequently_bought_together: 1,
  slot_v2: false,
  product_tile_experiment: 1,
  pci_phase_3: false,
  ndp_your_nectar_price_slot_prompt_enabled: false,
  seasonal_favourites_db: false,
  similar_products_drawer: false,
  track_product_tile: false,
  fetch_ynp_opt_ins: false,
  trackFBT: false,
  resting_search_v2: false,
};

// this is a temporary flag that will be removed next week
function getDefaultShowStaticClickAndCollectMessaging(): boolean {
  const now = moment.tz("Europe/London");
  return now.isAfter(moment("2021-11-08T00:00:00+01:00"));
}

/**
 * FeatureFlags is a singleton used to manage the state of Firebase feature flags.
 * Flags are loaded once on app startup then accessed via `get()`, `getAll()` and `find()`.
 * If you need to update the current flags, this is possible via `sync()` but
 * this should really be an exception.
 *
 * @example
 * import { featureFlags } from "firebase/featureFlags";
 * const isNewPDP = featureFlags.get("new_pdp_page");
 */
export class FeatureFlags {
  private data: Flags = initialFeatureFlagValues;

  /**
   * `get` provides a way to access a single feature flag value
   */
  public get<K extends keyof Flags>(key: K): Flags[`${K}`] {
    return this.data[`${key}`];
  }

  /**
   * `getAll` loads all previously synced feature flags
   */
  public getAll(): Flags {
    return this.data;
  }

  private findRecursively<K extends keyof NestedFlags<K>>(
    input: string,
    paths: string[],
    data: any = this.data
  ): boolean {
    const key = paths.shift() as string;

    if (data[key] === undefined) {
      console.error(`Unable to find feature flag '${input}'`);
      return false;
    }

    if (typeof data[key] === "object") {
      if (paths.length === 0) {
        return data[key];
      }

      return this.findRecursively(input, paths, data[key]);
    } else {
      return data[key];
    }
  }

  /**
   * `find` provides a way to search objects and return a nested value using dot notation
   * @example
   * import { featureFlags } from "firebase/featureFlags";
   * const isNewFeature = featureFlags.get("new_feature.banner");
   */
  public find<K extends keyof NestedFlags<K>>(input: string): boolean {
    const paths = input.split(".");

    return this.findRecursively(input, paths);
  }

  /**
   * `set` manually modifies a single feature flag value
   * it should only be used in stories and tests
   */
  public set<K extends keyof Flags>(key: K, value: Flags[K]): void {
    // eslint-disable-next-line security/detect-object-injection
    this.data[key] = value;
  }

  /**
   * `setObject` manually modifies a single feature flag value
   * it should only be used in stories and tests
   */
  public setObject(key: string, value: object | boolean): void {
    // eslint-disable-next-line security/detect-object-injection
    this.data[key] = value;
  }

  /**
   * `sync` provides a way to reload the flags from firebase
   */
  public sync = async () => {
    try {
      const data = await getFeatureFlags();
      if (data) {
        this.data = {
          ...this.data,
          ...data.featureFlags,
        };
      }
    } catch (e) {
      // failed to sync flags. Use default values.
      // eslint-disable-next-line no-console
      console.error("failed to sync feature flags", e);
    }
  };
}

export async function getFeatureFlags(): Promise<{ error: Error; featureFlags: Flags }> {
  const flagsEnvironment = parseJsonOrEmpty(process.env.REACT_APP_FLAGS);
  const flagsLocalStorage = parseJsonOrEmpty(localStorage.getItem("feature-flags"));
  let flagsFirebase, error;

  try {
    flagsFirebase = await getFirebaseFlags();
  } catch (err) {
    flagsFirebase = initialFeatureFlagValues;
    error = err;
  }

  const flags = {
    ...flagsEnvironment,
    ...(flagsFirebase || {}),
    ...flagsLocalStorage,
  };

  return { error, featureFlags: flags };
}

function parseJsonOrEmpty(json: string | undefined | null) {
  try {
    if (json) {
      return JSON.parse(json);
    }
  } catch (err) {
    return {};
  }
  return {};
}

// create a single instance of flags and use this during lifetime of the app
const featureFlags = new FeatureFlags();

export { featureFlags };
