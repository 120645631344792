import { useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import uuid from "uuid-v4";
import { MenuItem } from "../Header.types";
import { featureFlags } from "../../../common/firebase";
import { Product } from "../../../domain/product/product.types";
import { urls } from "../../../routes";

type UseOccasionsProps = {
  isOccasionsRoute: boolean;
  isOccasionsEnabled: boolean;
  isOccasionsProduct: (product: Product) => boolean;
};

export type OccasionsNavigationConfig = {
  categories: OccasionsCategory[];
};

type OccasionsCategory = {
  id: string;
  label: string;
  links: OccasionsLink[];
};

type OccasionsLink = {
  label: string;
  link: string;
};

type UseOccasionsHierarchyProps = {
  mapOccasionsHierarchy: (items: MenuItem[]) => MenuItem[];
};

export enum HierarchyLabel {
  OCCASIONS = "Occasions",
  OCCASIONS_HOME = "Occasions Home",
}

/**
 * Hook used for generic helpers related to the occasions feature.
 *
 * @returns UseOccasionsProps
 */
export const useOccasions = (): UseOccasionsProps => {
  const { pathname } = useLocation();

  const isOccasionsEnabled = featureFlags.get("occasions");

  const links = featureFlags.get("occasions_navigation");

  const combinedCategories = links?.categories?.flatMap(category => category.links) ?? [];

  const isOccasionsRoute = combinedCategories.some(item => {
    return decodeURI(item.link) === pathname || urls.OCCASIONS === pathname;
  });

  const isOccasionsProduct = useCallback((product: Product): boolean => {
    return !!product?.isSupplyChainOrderable;
  }, []);

  return {
    isOccasionsRoute,
    isOccasionsEnabled,
    isOccasionsProduct,
  };
};

/**
 * Used to map a configuration object of navigation links retrieved from Firebase to a `MenuItem[]` format
 * required for the mobile navigation hierarchy.
 *
 * @returns MenuItem[]
 */
export const useOccasionsHierarchy = (): UseOccasionsHierarchyProps => {
  const mapOccasionsHierarchy = useMemo(
    () =>
      (items: MenuItem[]): MenuItem[] => {
        const configuration = featureFlags.get("occasions_navigation");

        if (!(configuration?.categories?.length > 0)) {
          return items;
        }

        const hierarchy: MenuItem = {
          id: uuid(),
          title: HierarchyLabel.OCCASIONS,
          url: urls.OCCASIONS,
          items: [
            {
              id: HierarchyLabel.OCCASIONS_HOME.replace(" ", "-").toLowerCase(),
              title: HierarchyLabel.OCCASIONS_HOME,
              url: urls.OCCASIONS,
              items: [],
            },
          ],
        };

        const categoryMap = new Map();

        configuration.categories.forEach(category => {
          if (!categoryMap.has(category.id)) {
            categoryMap.set(category.id, {
              id: category.id,
              title: category.label,
              url: category.id, // the category level node won't redirect to a page
              items: [],
            });
          }

          if (category.links.length > 0) {
            category.links.forEach(link => {
              categoryMap.get(category.id).items.push({
                id: link.label.replace(" ", "-").toLowerCase(),
                title: link.label,
                url: link.link,
              });
            });
          }
        });

        hierarchy.items = [...(hierarchy.items ?? []), ...[...categoryMap.values()]];

        return [...items, hierarchy];
      },
    []
  );

  return {
    mapOccasionsHierarchy,
  };
};

export const defaultNavigationLinks: OccasionsNavigationConfig = {
  categories: [
    {
      id: "shop-by-category",
      label: "Shop by category",
      links: [
        {
          label: "All food",
          link: "/gol-ui/groceries/occasions-by-sainsburys/all-food/c:1046172",
        },
        {
          label: "Sandwiches & Wraps",
          link: "/gol-ui/groceries/occasions-by-sainsburys/sandwiches-and-wraps/c:1046173",
        },
        {
          label: "Buffet & Sharing",
          link: "/gol-ui/groceries/occasions-by-sainsburys/buffet-and-sharing/c:1046174",
        },
        {
          label: "Cakes",
          link: "/gol-ui/groceries/occasions-by-sainsburys/cakes/c:1046175",
        },
        {
          label: "Desserts",
          link: "/gol-ui/groceries/occasions-by-sainsburys/desserts/c:1046176",
        },
        {
          label: "Party Food",
          link: "/gol-ui/groceries/occasions-by-sainsburys/party-food/c:1046177",
        },
        {
          label: "Sushi",
          link: "/gol-ui/groceries/occasions-by-sainsburys/sushi/c:1046178",
        },
        {
          label: "Dining",
          link: "/gol-ui/groceries/occasions-by-sainsburys/dining/c:1046179",
        },
        {
          label: "Salads & Fruit",
          link: "/gol-ui/groceries/occasions-by-sainsburys/salads-and-fruit/c:1046180",
        },
        {
          label: "Special Diets",
          link: "/gol-ui/groceries/occasions-by-sainsburys/special-diets/c:1046181",
        },
        {
          label: "Vegetarian",
          link: "/gol-ui/groceries/occasions-by-sainsburys/vegetarian/c:1046182",
        },
        {
          label: "Vegan",
          link: "/gol-ui/groceries/occasions-by-sainsburys/vegan/c:1046183",
        },
        {
          label: "Gluten Free",
          link: "/gol-ui/groceries/occasions-by-sainsburys/gluten-free/c:1046184",
        },
      ],
    },
    {
      id: "shop-by-occasion",
      label: "Shop by Occasion",
      links: [
        {
          label: "Work Events",
          link: "/gol-ui/features/work-events",
        },
        {
          label: "Children's Parties",
          link: "/gol-ui/features/fto-childrens-birthday-parties",
        },
        {
          label: "Birthdays & Celebrations",
          link: "/gol-ui/features/fto-birthdays-and-celebrations",
        },
        {
          label: "Entertaining & Hosting",
          link: "/gol-ui/features/fto-entertaining",
        },
      ],
    },
  ],
};
