import { ProductTilePromotion, ProductOption } from "../components/ProductTile/productTile.types";
import { MultiVariant } from "../domain/product/product.types";

export function getPromotions(
  promotions: ProductTilePromotion[] | undefined,
  multivariants: MultiVariant[] | undefined,
  selectedDropdownOption: ProductOption
) {
  if (multivariants) {
    const promotionsInMultivariants = multivariants.filter(
      multivariantProduct => multivariantProduct.productUid === selectedDropdownOption.value
    );
    if (promotionsInMultivariants.length > 0) {
      promotions = promotionsInMultivariants[0].promotions;
    }
  }
  return promotions;
}
