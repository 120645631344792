import GolTime from "../../domain/time";

export function removeDuplicates(ls: string[]): string[] {
  const m: { [name: string]: number } = {};
  for (const item of ls) {
    if (!m[`${item}`]) {
      m[`${item}`] = 1;
    }
  }
  return Object.keys(m);
}

/**
 * Capitalises the first character of each word in a string (Start Case).
 *
 * @param {string} str - The string to convert to Start Case.
 * @returns capitaliseAllWords("I am hungry for some ice cream") -> "I Am Hungry For Some Ice Cream"
 */
export function capitaliseAllWords(str: string): string {
  return str
    .split(" ")
    .map(s => s.replace(s.charAt(0), s.charAt(0).toUpperCase()))
    .join(" ");
}

export const getCharsBefore = (s: string | undefined, identifier: string): string =>
  s ? s.slice(0, s.indexOf(identifier)) : "";

export const getCharsAfter = (s: string, identifier: string): string =>
  s.includes(identifier) ? s.slice(s.indexOf(identifier) + identifier.length) : "";

export const getCharsBetween = (s: string, begin: string, end: string): string =>
  s.slice(s.indexOf(begin) + begin.length + 1, s.includes(end) ? s.indexOf(end) : s.length);

const removeSlash = (ch: string | undefined): string => (typeof ch === "undefined" || /\//.test(ch) ? "" : ch);
// removeBookends returns a string copy without the slashes at the ends. e.g. removeBookEnds("/foo/") === "foo"
export const removeBookends = (s: string): string => {
  const chars = s.split("");
  const start = chars.shift();
  const end = chars.pop();
  return [removeSlash(start), chars.join(""), removeSlash(end)].join("").trim();
};

export const concatAllNonEmptyStrings = (arr: string[], delimiter: string): string =>
  arr.filter(s => s.length).join(delimiter);

// ampersand matches all "&" characters and their surrounding whitespaces
export const ampersand: RegExp = /\s*[&]\s*/g;
// commaOrWhitespaces matches all commas and their surrounding whitespaces, or multiple whitespaces
export const commaOrWhitespace: RegExp = /\s*[,\s]\s*/g;
export const capitalise = (s: string): string => s.slice(0, 1).toUpperCase().concat(s.slice(1));

// createFormattedPath formats strings in a URL-friendly fashion. e.g. createFormattedPath("Fruit & vegetables") === "fruit-and-vegetables"
export const createFormattedPath = (s: string): string =>
  s.toLowerCase().replace(ampersand, "-and-").replace(commaOrWhitespace, "-");

export const hyphenate = (s: string): string => s.replace(/ /g, "-");
export const dehyphenate = (s: string): string => s.replace(/-/g, " ");

const encodeForBrowse = (s: string): string => {
  switch (s) {
    case ".":
      return "%2E";
    case "'":
      return "%27";
    case "(":
      return "%28";
    case ")":
      return "%29";
    default:
      return encodeURI(s);
  }
};

export const encodeSpecialCharacters = (s: string): string =>
  s
    .split("")
    .map(ch => encodeForBrowse(ch))
    .join("");

/**
 * Determines if a date has expired by checking against the current date.
 * If the date is undefined, we determine that the date has not expired, because we can't say for certain that it has.
 *
 * @param {string | undefined} date - the date to compare against the current date.
 * @returns {boolean} true if the date provided is before today, false if the date provided is in the future.
 */
export const hasDateExpired = (date: string | undefined) => {
  if (date) {
    // GOL Services can return an end date of "0001-01-01T00:00:00Z" in some cases. We do NOT want to consider this date expired.
    if (date === "0001-01-01T00:00:00Z") return false;

    return GolTime.now().isAfter(new GolTime(date));
  }

  return false;
};

export const replacePlaceholders = (phrase: string, placeholder: string, replacement: string): string => {
  return phrase.replace(placeholder, replacement);
};
