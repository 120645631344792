import { AnyAction } from "redux";
import { ModalActionTypes, ModalState } from "./Modal.types";

export const modalInitialState: ModalState = {
  currentlyVisible: null,
};

export const modalReducer = (state: ModalState = modalInitialState, action: AnyAction): ModalState => {
  // AD_x define the action type
  switch (action.type) {
    case ModalActionTypes.CURRENTLY_VISIBLE_MODAL:
      return {
        ...state,
        currentlyVisible: action.name,
      };
    case ModalActionTypes.CLOSE_CURRENTLY_VISIBLE_MODAL:
      return {
        ...state,
        currentlyVisible: null,
      };
    case ModalActionTypes.OPEN_MODAL:
      return {
        currentlyVisible: null,
        meta: action.meta,
      };
    case ModalActionTypes.CLOSE_MODAL:
      return {
        ...state,
        meta: undefined,
      };
    default:
      return state;
  }
};
