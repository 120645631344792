import React, { ReactNode } from "react";
import { FeatureEnabled } from "../../../common/firebase";

type FeatureFlagControlledAdProps = {
  children: ReactNode;
};

export const FeatureFlagControlledAd = ({ children }: FeatureFlagControlledAdProps) => (
  <FeatureEnabled flag="all_ad_components_enabled">{children}</FeatureEnabled>
);
