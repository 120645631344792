export const browsePathRedirects = [
  {
    redirectURL: "/gol-ui/groceries/meat-and-fish/ham-cooked-meats-and-pâté/all-ham-cooked-meats-and-pâté",
    redirectSynapticaURL: "/gol-ui/groceries/meat-and-fish/ham-cooked-meats-and-pate/all-ham-cooked-meats-and-pâté",
    landingURL: "/gol-ui/groceries/meat-and-fish/ham-cooked-meats-and-pate/all-ham-cooked-meats-and-pate",
  },
  {
    redirectURL: "gol-ui/groceries/meat-and-fish/ham-cooked-meats-and-pâté/pâté",
    redirectSynapticaURL: "gol-ui/groceries/meat-and-fish/ham-cooked-meats-and-pate/pâté",
    landingURL: "/gol-ui/groceries/meat-and-fish/ham-cooked-meats-and-pate/pate",
  },
  // {
  //   redirectURL: "gol-ui/groceries/beauty-and-cosmetics/men's-grooming/shaving-and-beard-care/aftershave",
  //   redirectSynapticaURL: "gol-ui/groceries/beauty-and-cosmetics/men's-grooming/shaving-and-beard-care/aftershave",
  //   landingURL: "/gol-ui/groceries/beauty-and-cosmetics/mens-grooming/shaving-and-beard-care/aftershave",
  // },
];
