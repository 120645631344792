import { CheckPostcodeState } from "../../common/store";
import {
  CheckPostcodeActions,
  CheckPostcodeActionTypes,
  CheckPostcodeRegistrationSuccessActionType,
} from "./checkPostcode.types";
import { ErrorActionTypes } from "../../components/Error/error.actions";
import { DataState } from "../../common/dataState";

export const checkPostcodeInitialState: CheckPostcodeState = {
  dataState: DataState.UNKNOWN,
};

const checkPostcodeReducer = (
  state: CheckPostcodeState | undefined = checkPostcodeInitialState,
  action: CheckPostcodeActions
): CheckPostcodeState => {
  switch (action.type) {
    case CheckPostcodeActionTypes.CHECK_POSTCODE_REQUEST:
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    case CheckPostcodeActionTypes.CHECK_POSTCODE_FAILURE:
    case ErrorActionTypes.UNHANDLED_API_ERROR:
      return {
        ...state,
        dataState: DataState.FAILED,
      };
    case CheckPostcodeActionTypes.CHECK_POSTCODE_SUCCESS:
      return {
        ...state,
        dataState: DataState.SUCCESS,
      };
    case CheckPostcodeActionTypes.CHECK_POSTCODE_REGISTRATION_SUCCESS:
      return {
        ...state,
        dataState: DataState.SUCCESS,
        verifiedPostcode: (action as CheckPostcodeRegistrationSuccessActionType).postcode,
      };
    default:
      return state;
  }
};

export { checkPostcodeReducer };
