import uuid from "uuid-v4";
import { SlotReservationState } from "../../domain/slot/slotReservation.types";
import { useCallback } from "react";
import { State } from "../../common/store";
import { featureFlags } from "../../common/firebase/featureFlags";
import { createSelectorCreator, defaultMemoize } from "reselect";
import isEqual from "lodash.isequal";
import { useSelector } from "react-redux";

export interface LinkFlag {
  url: string;
  title: string;
  id: string;
}

export interface PromotionalFlag {
  default: {
    url: string;
    title: string;
  };
  custom?: {
    url: string;
    title: string;
  };
  rules?: Rules;
}
export interface Rules {
  condition: string;
  criteria: string;
  match: string;
}

export interface Config {
  flag: PromotionalFlag | null;
  slot: SlotReservationState;
}

export const useMenuPromotionalItem = () => {
  const getLinkFromFeatureFlag = useCallback(
    (flag: PromotionalFlag | null, slot: SlotReservationState): LinkFlag | false => {
      const JSON_MEMBERS = {
        LINK: ["url", "title"],
        CUSTOM_FLAG: ["default", "custom", "rules"],
        RULES: ["condition", "criteria", "match"],
        RULES_CONDITION: ["exclude", "include"],
        CRITERIA: ["region"],
      };

      const hasValidatedMembers = (list: string[], validatedJson: any): boolean =>
        list.every(key => Object.keys(validatedJson).includes(key) && validatedJson[`${key}`]);

      const useCustomLink = (rules: Rules | undefined, slot: SlotReservationState): boolean | null => {
        if (!rules || !slot) {
          return null;
        }

        if (JSON_MEMBERS.RULES_CONDITION.includes(rules.condition) && JSON_MEMBERS.CRITERIA.includes(rules.criteria)) {
          return rules.condition === JSON_MEMBERS.RULES_CONDITION[0]
            ? slot.slotReservation[rules.criteria] !== rules.match
            : slot.slotReservation[rules.criteria] === rules.match;
        }
        return null;
      };

      const validateRules = (config: Config): LinkFlag | false => {
        const { flag, slot } = config;
        if (!flag) {
          return false;
        }

        const hasCustomRules = hasValidatedMembers(JSON_MEMBERS.CUSTOM_FLAG, flag);
        if (hasCustomRules) {
          if (hasValidatedMembers(JSON_MEMBERS.RULES, flag.rules)) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const isCustomLink = useCustomLink(flag.rules, slot);
            if (isCustomLink !== null && flag.custom) {
              return isCustomLink ? { ...flag.custom, id: uuid() } : { ...flag.default, id: uuid() };
            }
            return false;
          }
        }

        return (
          hasValidatedMembers(JSON_MEMBERS.LINK, flag.default) && {
            ...flag.default,
            id: uuid(),
          }
        );
      };

      try {
        return validateRules({ flag, slot });
      } catch (e) {
        return false;
      }
    },
    []
  );

  const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

  const createPromotionalLinkSelector = createDeepEqualSelector(
    [(state: State) => state.slotReservation, (state: State) => featureFlags.get("promotional_link")],
    (slotReservation, flag) => getLinkFromFeatureFlag(flag, slotReservation)
  );

  const promotionalLink = useSelector((state: State) => createPromotionalLinkSelector(state));

  return { promotionalLink };
};
