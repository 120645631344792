import React from "react";
import { GOLLink } from "../../../GOLLink";
import { urls } from "../../../../routes";
import { Button } from "@sainsburys-tech/fable";

export const MyAccountSection: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <>
    <li className="megaNavDrawer__item myAccountSection">
      <span className="megaNavDrawer__item--active">My account</span>
    </li>
    <li className="megaNavDrawer__item">
      <Button className="megaNavDrawer__link" onClick={onClick} data-testid="my-account-link" variant="tertiary">
        <GOLLink to={urls.MY_ACCOUNT} external={false}>
          Account details
        </GOLLink>
      </Button>
    </li>
    <li className="megaNavDrawer__item">
      <a data-testid="logout-link" href={urls.OAUTH_LOGOUT}>
        Log out
      </a>
    </li>
  </>
);
