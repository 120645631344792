import { State } from "../store";

/* Stateful helper for middleware where you want to 'pause'
 mid action and wait for some state */

export const waitForState = (predicate: (state: State) => boolean) => {
  let res: (value: unknown) => void;
  const isStateReady = new Promise(resolve => {
    res = resolve;
  });
  const checkState = (state: State) => {
    const checkResult = predicate(state);
    if (checkResult) {
      res(checkResult);
    }
  };
  return { isStateReady, checkState };
};
