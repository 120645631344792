export enum DietaryProfileStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  NONE = "NONE",
}

export interface User {
  id: string;
  customerId: string;
  customerNumber: string;
  websphereId: string;
  identityId: string;
  isRegistered: boolean;
  isNewCustomer?: boolean;
  givenName: string;
  familyName?: string;
  email: string;
  phoneNumber: string;
  isRecurringSlotRegistered: boolean;
  dietaryProfile: DietaryProfileStatus;
  hasDeliveryPass: boolean;
  deliveryPassExpiryDate?: string;
  deliveryPassId?: string;
  isDeliveryPassAutoRenewalEnabled: boolean;
  postcode: string;
  hasNectarLinked?: boolean;
  hasNectarAssociated?: boolean;
  nectar_saved_offers?: number;
  isColleague?: boolean;
  isDigitalCustomer?: boolean;
  isVeryImportantCustomer?: boolean;
  isSamAgent: boolean;
  defaultSubstitutionPreference: boolean;
}
