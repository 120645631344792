import { formatCurrency } from "../../common/format";
import { stringFormatter, maxLengthString } from "../../components/ProductTile/ProductTile.selectors";
import { ProductOption } from "./trolley.types";
import { SlotReservationTypeFormatted } from "../../domain/slot/slotReservation.types";
import { Product, ProductType, UnitPrice } from "../../domain/product/product.types";
import { Basket } from "../../domain/basket/basket.types";
import { PromoMechanicIds } from "../../common/types";

const formatPence = (value: number): string => `${Math.abs(value * Math.pow(10, 2)).toFixed()}p`;

export const formatUnitPrice = (unitPrice: UnitPrice): string => {
  const { price, measureAmount, measure } = unitPrice;

  if (price === 0) return "";

  const formattedPrice = price >= 1 ? formatCurrency(price) : formatPence(price);
  return `${formattedPrice} / ${measureAmount !== 1 ? measureAmount : ""}${measure}`;
};

export const getAnalyticsDetails = (
  isFreeSlotTest: boolean,
  source: string,
  testIdentifier: string,
  reservationType: SlotReservationTypeFormatted,
  basketSubTotal: number
): string =>
  isFreeSlotTest
    ? `${source} ${testIdentifier} ${reservationType} - subtotal: ${formatCurrency(basketSubTotal)}`
    : source;

const isCatchweightProduct = (product?: Product) => {
  return product && product.catchweight && product.productType === ProductType.CATCHWEIGHT;
};

export const getCatchweightProductOptions = (product?: Product): ProductOption[] => {
  if (!product || !isCatchweightProduct(product) || !Array.isArray(product.catchweight)) {
    return [];
  }
  return product.catchweight.map(({ range, unitPrice }) => ({
    value: range,
    label: stringFormatter(range, maxLengthString.PRODUCT_TILE_MULTIVARIANT_STRING),
    unitPrice: formatUnitPrice(unitPrice),
  }));
};

const getCatchweightProductUnitPrice = (selectedCatchweight: string, product?: Product): string => {
  const selectedOption = getCatchweightProductOptions(product).find(option => option.value === selectedCatchweight);
  return selectedOption ? selectedOption.unitPrice : "";
};

export const getUnitPrice = (product?: Product, selectedCatchweight?: string): string =>
  isCatchweightProduct(product) && selectedCatchweight
    ? getCatchweightProductUnitPrice(selectedCatchweight, product)
    : product && product.unitPrice
    ? formatUnitPrice(product.unitPrice)
    : "";

export const getMissedNectarSavingsAmount = (basket: Basket, products: Map<string, Product>): number =>
  basket.items.reduce((total: number, basketItem) => {
    const product = products.get(basketItem.sku);
    const nectarPromotion =
      product && product.promotions?.find(p => p.promoMechanicId === PromoMechanicIds.NECTAR_PROMOTION);

    if (nectarPromotion) {
      const originalPrice = basketItem.subTotal / basketItem.quantity;
      let nectarPrice = product?.retailPrice?.price;

      if (isCatchweightProduct(product)) {
        const catchweightProduct = product.catchweight?.find(
          variant => variant.range === basketItem.selectedCatchweight
        );
        nectarPrice = catchweightProduct?.retailPrice.price;
      }

      if (originalPrice && nectarPrice) {
        const productSaving = (originalPrice - nectarPrice) * basketItem.quantity;
        return total + productSaving;
      }
    }

    return total;
  }, 0);
