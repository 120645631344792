import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { MenuItem } from "../Header.types";

export enum HeaderActionTypes {
  SHOW_HEADER = "SHOW_HEADER",
  HIDE_HEADER = "HIDE_HEADER",
  SHOW_MEGANAV = "SHOW_MEGANAV",
  HIDE_MEGANAV = "HIDE_MEGANAV",
  SHOW_MINIMIZED_HEADER = "SHOW_MINIMIZED_HEADER",
  HIDE_MINIMIZED_HEADER = "HIDE_MINIMIZED_HEADER",
  SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY",
}

export type HeaderVisibilityAction = {
  type: HeaderActionTypes;
};

export const useHeaderActions = () => {
  const dispatch = useDispatch();

  const showHeader = useCallback(() => dispatch({ type: HeaderActionTypes.SHOW_HEADER }), [dispatch]);
  const hideHeader = useCallback(() => dispatch({ type: HeaderActionTypes.HIDE_HEADER }), [dispatch]);
  const showMinimizedHeader = useCallback(
    () => dispatch({ type: HeaderActionTypes.SHOW_MINIMIZED_HEADER }),
    [dispatch]
  );
  const hideMinimizedHeader = useCallback(
    () => dispatch({ type: HeaderActionTypes.HIDE_MINIMIZED_HEADER }),
    [dispatch]
  );

  return { showHeader, hideHeader, showMinimizedHeader, hideMinimizedHeader };
};

export const useMegaNavActions = () => {
  const dispatch = useDispatch();

  const showMegaNav = useCallback(() => dispatch({ type: HeaderActionTypes.SHOW_MEGANAV }), [dispatch]);
  const hideMegaNav = useCallback(() => dispatch({ type: HeaderActionTypes.HIDE_MEGANAV }), [dispatch]);
  const setSelectedMeganavCategory = useCallback(
    (selectedMeganavCategory: MenuItem | null) =>
      dispatch({ type: HeaderActionTypes.SET_SELECTED_CATEGORY, selectedMeganavCategory }),
    [dispatch]
  );

  return { showMegaNav, hideMegaNav, setSelectedMeganavCategory };
};
