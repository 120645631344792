import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkOrderingHelpActionCreator } from "@favourites/state/Favourites.actions";
import { ModalsGroupComponent } from "./ModalsGroup.component";
import {
  scottishLegislationErrorSelectorCached,
  itemQuantityExceededErrorSelectorCached,
  bulkOrderLimitExceededErrorSelectorCached,
  maximumBasketLimitExceededErrorSelectorCached,
} from "../../domain/basket/basket.selectors";
import {
  selectAddOrderToBasketErrors,
  selectShouldOpenGenericErrorModal,
  selectShouldOpenFavouritesSaveErrorModal,
  selectShouldOpenFavouritesDeleteErrorModal,
} from "./Modal.selectors";
import { ModalNames } from "./Modal.types";
import { State } from "../../common/store";

export const ModalsGroupContainer = () => {
  const addOrderToBasketErrors = useSelector(selectAddOrderToBasketErrors);
  const showGenericErrorModal = useSelector(selectShouldOpenGenericErrorModal);
  const scottishLegislationError = useSelector(scottishLegislationErrorSelectorCached);
  const itemQuantityExceededError = useSelector(itemQuantityExceededErrorSelectorCached);
  const showFavouriteSaveErrorModal = useSelector(selectShouldOpenFavouritesSaveErrorModal);
  const bulkOrderLimitExceededError = useSelector(bulkOrderLimitExceededErrorSelectorCached);
  const showFavouriteDeleteErrorModal = useSelector(selectShouldOpenFavouritesDeleteErrorModal);
  const maximumBasketLimitExceededError = useSelector(maximumBasketLimitExceededErrorSelectorCached);
  const showLoadingModal = useSelector((state: State) => state.modal.currentlyVisible === ModalNames.MODAL_LOADING);
  const showBasketAddSuccess = useSelector(
    (state: State) => state.modal.currentlyVisible === ModalNames.MODAL_BASKET_ADD_SUCCESS
  );
  const dispatch = useDispatch();
  const bulkOrderingHelp = () => bulkOrderingHelpActionCreator(dispatch);

  return (
    <ModalsGroupComponent
      bulkOrderingHelp={bulkOrderingHelp}
      showGenericErrorModal={showGenericErrorModal}
      addOrderToBasketErrors={addOrderToBasketErrors}
      scottishLegislationError={scottishLegislationError}
      itemQuantityExceededError={itemQuantityExceededError}
      bulkOrderLimitExceededError={bulkOrderLimitExceededError}
      showFavouriteSaveErrorModal={showFavouriteSaveErrorModal}
      showFavouriteDeleteErrorModal={showFavouriteDeleteErrorModal}
      maximumBasketLimitExceededError={maximumBasketLimitExceededError}
      showLoadingModal={showLoadingModal}
      showBasketAddSuccess={showBasketAddSuccess}
    />
  );
};
