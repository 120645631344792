import { AnyAction } from "redux";
import { SearchIngridAdActionType, SearchIngridAdState } from "./SearchAd.type";

export const searchAdInitialState: SearchIngridAdState = {
  ads: [],
};

export const searchAdReducer = (
  state: SearchIngridAdState = searchAdInitialState,
  action: AnyAction
): SearchIngridAdState => {
  switch (action.type) {
    case SearchIngridAdActionType.SEARCH_AD_SUCCESS:
      return {
        ads: action.ads,
      };
    case SearchIngridAdActionType.SEARCH_AD_REQUEST || SearchIngridAdActionType.SEARCH_AD_FAILURE:
      return {
        ads: [],
      };
    case SearchIngridAdActionType.SEARCH_AD_RESET:
      return searchAdInitialState;
    default:
      return state;
  }
};
