import { Product } from "../../domain/product";
import { useEffect, useState } from "react";
import { pushProductVisitEvent } from "./client";

export function useTaggstarProductVisitEvent(product: Product) {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    if (visited) {
      return;
    }
    pushProductVisitEvent(product);
    setVisited(true);
  }, [visited, product]);
}
