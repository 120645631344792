import { SetCutOffExpiredAction } from "../../components/AmendOrderBanner/AmendOrderBanner.types";
import { OrdersAction, OrdersActionTypes, OrdersState } from "./order.types";
import { DataState } from "../../common/dataState";

export const ordersInitialState: OrdersState = {
  data: {
    orders: [],
  },
  dataState: DataState.UNKNOWN,
};

export const ordersReducer = (
  state = ordersInitialState,
  action: OrdersActionTypes | SetCutOffExpiredAction
): OrdersState => {
  switch (action.type) {
    case OrdersAction.FETCH_ORDERS_PENDING: {
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    }
    case OrdersAction.FETCH_ORDERS_SUCCESS: {
      return {
        ...state,
        dataState: DataState.SUCCESS,
        data: {
          ...state.data,
          orders: action.paginatedOrders.orders,
        },
        controls: action.paginatedOrders.controls,
      };
    }
    case OrdersAction.FETCH_ORDERS_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          orders: ordersInitialState.data.orders,
        },
        dataState: DataState.FAILED,
      };
    }
    case OrdersAction.STOP_AMENDING_ORDER_PENDING: {
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    }
    case OrdersAction.STOP_AMENDING_ORDER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
        },
        dataState: DataState.SUCCESS,
      };
    }
    case OrdersAction.STOP_AMENDING_ORDER_ERROR: {
      return {
        ...state,
        dataState: DataState.FAILED,
      };
    }
    case OrdersAction.START_AMENDING_ORDER_PENDING: {
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    }
    case OrdersAction.START_AMENDING_ORDER_SUCCESS: {
      return {
        ...state,
        dataState: DataState.SUCCESS,
      };
    }
    case OrdersAction.START_AMENDING_ORDER_ERROR: {
      return {
        ...state,
        dataState: DataState.FAILED,
      };
    }
    case OrdersAction.CANCEL_ORDER_SUCCESS: {
      return {
        ...state,
        dataState: DataState.SUCCESS,
        data: {
          ...state.data,
          orders: state.data.orders.filter(order => {
            return order.orderUid !== action.orderUid;
          }),
        },
      };
    }
    default:
      return state;
  }
};
