import { Product } from "../../domain/product/product.types";
import { Action } from "redux";
import { BasketError, ClickSource } from "../../domain/basket/basket.types";
import { FulfilmentState } from "../../common/dataLayer/types";

export interface BasicModalProps {
  title: string;
  description: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => Action | void;
  onSecondaryButtonClick?: () => Action | void;
  onClose?: () => void;
  isOpen?: boolean;
}

export interface FavouritesModalProps {
  title: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: (isChecked: boolean) => Action | void;
  onSecondaryButtonClick?: () => Action | void;
  onClose?: () => void;
  isOpen?: boolean;
}

export interface AlternativeProductsModalProps {
  title: string;
  onSecondaryButtonClick?: () => Action | void;
  onClose?: () => void;
  products: Product[];
  isOpen: boolean;
  clickSource?: ClickSource;
  fulfilmentState?: FulfilmentState;
  isSimilarProductsFetched?: boolean;
}

export interface DiscardChangesModalProps {
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  onClose?: () => void;
  isOpen?: boolean;
  slotDetails: SlotDetails | undefined;
}

export interface EmptyTrolleyModalProps {
  onPrimaryButtonClick: () => void;
  onClose: () => void;
}

export interface BasketMultiErrorModalProps {
  errors: BasketMultiErrorContent[];
}

export interface BasketMultiErrorMessageProps {
  error: BasketMultiErrorContent;
  isLast: boolean;
}

export interface BasketMultiErrorContent {
  detail: string;
  productName: string;
  secondaryButtonText?: string;
  secondaryButtonUrl?: string;
}

export enum ModalNames {
  MODAL_SCOTTISH_LEGISLATION = "scottishLegislation",
  MODAL_BULK_ORDER_LIMIT_EXCEEDED = "bulkOrderLimitExceeded",
  MODAL_ITEM_QUANTITY_EXCEEDED = "itemQuantityExceeded",
  MODAL_MAXIMUM_BASKET_LIMIT_EXCEEDED = "maximumBasketLimitExceeded",
  MODAL_REMOVE_FROM_FAVOURITES = "removeFromFavourites",
  MODAL_REMOVE_FROM_FAVOURITES_ERROR = "removeFromFavouritesError",
  MODAL_SAVE_FAVOURITE_ERROR = "saveFavouriteError",
  MODAL_GENERIC_ERROR = "genericError",
  MODAL_ADD_ORDER_TO_BASKET_ERRORS = "addOrderToBasket",
  MODAL_AMEND_DISCARD_CHANGES = "amendDiscardChanges",
  MODAL_LOADING = "loading",
  MODAL_BASKET_ADD_SUCCESS = "basketAddSuccess",
}

export interface ModalsGroupComponentProps {
  scottishLegislationError: BasketError | undefined;
  bulkOrderLimitExceededError: BasketError | undefined;
  itemQuantityExceededError: BasketError | undefined;
  maximumBasketLimitExceededError: BasketError | undefined;
  addOrderToBasketErrors: BasketMultiErrorContent[] | undefined;
  showFavouriteDeleteErrorModal: boolean;
  showFavouriteSaveErrorModal: boolean;
  showGenericErrorModal: boolean;
  bulkOrderingHelp: () => void;
  showLoadingModal: boolean;
  showBasketAddSuccess: boolean;
}

export type ModalWrapperComponentProps<T extends {}> = {
  isOpen?: boolean;
} & T;

export interface ModalWrapperComponentState {
  isOpenAltered: boolean;
}

export interface ModalState {
  currentlyVisible: string | null;
  meta?: {
    [key: string]: any;
  };
}

export enum ModalActionTypes {
  CURRENTLY_VISIBLE_MODAL = "CURRENTLY_VISIBLE_MODAL",
  CLOSE_CURRENTLY_VISIBLE_MODAL = "CLOSE_CURRENTLY_VISIBLE_MODAL",
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
}

export interface ModalOpenAction {
  type: ModalActionTypes.CURRENTLY_VISIBLE_MODAL;
  name: string;
}

export interface SlotDetails {
  time: string;
  address: string;
  orderType: "click_and_collect" | "delivery";
}
