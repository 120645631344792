import { PromoMechanicIds } from "./types";

export const temporaryPriceReductionMechanicIds = [
  PromoMechanicIds.SO_HALF_PRICE,
  PromoMechanicIds.SO_SAVE_X_VALUE,
  PromoMechanicIds.SO_SAVE_X_PERCENT,
  PromoMechanicIds.SO_FIXED_PRICE,
  PromoMechanicIds.IO_HALF_PRICE,
  PromoMechanicIds.IO_SAVE_X_VALUE,
  PromoMechanicIds.IO_SAVE_X_PERCENT,
  PromoMechanicIds.IO_FIXED_PRICE,
];

export const simplePromotionMechanicIds = [...temporaryPriceReductionMechanicIds, PromoMechanicIds.NECTAR_PROMOTION];

export const complexPromoMechanicIds = [
  PromoMechanicIds.BUY_X_GET_X_POINTS,
  PromoMechanicIds.SPEND_X_GET_X_POINTS,
  PromoMechanicIds.BUY_ONE_GET_ONE_FREE,
  PromoMechanicIds.THREE_FOR_TWO,
  PromoMechanicIds.BUY_X_FOR_Y_SAME_PRICE,
  PromoMechanicIds.BUY_X_FOR_Y_VARIABLE_PRICE,
  PromoMechanicIds.BUY_X_SAVE_Y_VALUE,
  PromoMechanicIds.BUY_ITEM_1_GET_ITEM_2_FREE,
  PromoMechanicIds.BUY_ITEM_1_GET_ITEM_2_FOR_X_VALUE,
  PromoMechanicIds.BUY_X_SAVE_Y_PERCENT,
  PromoMechanicIds.BUY_X_SAVE_Y_PERCENT_ROLLING,
  PromoMechanicIds.SPEND_X_VALUE_SAVE_Y_VALUE,
  PromoMechanicIds.SPEND_X_SAVE_Y_PERCENT,
  PromoMechanicIds.BUY_X_GET_Y_CHEAPEST_FREE,
  PromoMechanicIds.MULTI_DEAL_FOR_X_VALUE,
  PromoMechanicIds.MULTI_DEAL_SAVE_X_VALUE,
  PromoMechanicIds.MULTI_DEAL_GET_X_POINTS,
  PromoMechanicIds.MULTI_DEAL_SAVE_Y_PERCENT,
];
