import { MealDealPromotions } from "../../MealDealBuilder.types";
import { serviceRequest } from "../../../../services/request";
import { Method, Result } from "../../../../common/http/request";
import { Optional } from "../../../../common/types";

const url = "/groceries-api/gol-services/product/v1/product";

export const fetchMealDealOfferData = (
  offerId: string,
  promotionText?: string,
  baseUrl: Optional<string> = url
): Promise<Result<MealDealPromotions>> => {
  const queryParam =
    promotionText && promotionText.trim() !== "" ? `promotionText=${promotionText}` : `promotionId=${offerId}`;

  return serviceRequest({
    method: Method.GET,
    url: `${baseUrl}/complex-promotions?${queryParam}`,
  });
};
