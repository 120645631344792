import { Method, request, Result } from "../common/http/request";
import { Optional } from "../common/types";
import { serviceRequest } from "./request";
import {
  AuthenticationRes,
  CondtionsRes,
  ProfileConditionsItem,
  UpdateProfileRes,
} from "../components/DietaryProfile/DietaryModal/DietaryModal.types";
import { LoginResponse } from "./login.types";
import { getAuthCookieValue } from "../utils/cookie";

export interface Consent {
  mission: string;
  consent: boolean;
}

export interface ProgressiveRegistrationRequest {
  mobile: string;
  postcode: string;
  agreement: boolean;
  username: string;
  password: string;
  consents?: Consent[];
}

export interface ProgressiveRegistrationV2Request {
  access_token: string;
  postcode: string;
  nectar_card_number: string;
  terms_and_conditions_accepted: boolean;
  cpm?: {
    email: string;
    consents: Consent[];
  };
}

export interface MissionsRequest {
  email: string;
  token: string;
}

export interface Mission {
  name: string;
  is_origin: boolean;
}

export interface MissionsResponse {
  missions: Mission[];
}

export type CustomerProfileResponse = {
  user_id: string;
  customer_id: string;
  customer_number: string;
  personalization_id: string;
  identity_id: string;
  is_registered: boolean;
  is_new_customer?: boolean;
  given_name: string;
  family_name?: string;
  has_nectar_associated?: boolean;
  has_nectar_linked?: boolean;
  dietary_profile: string;
  email: string;
  delivery_pass_expiry_date?: string;
  primary_phone: string;
  is_recurring_slot_registered: boolean;
  delivery_pass_id?: string;
  delivery_pass_auto_renewal: boolean;
  postcode: string;
  is_colleague: boolean;
  is_digital_nectar: boolean;
  is_very_important_customer: boolean;
  is_sam_agent: boolean;
  default_substitution_preference: boolean;
};

export type CustomerAddress = {
  postcode: string;
  preferred_delivery_address: boolean;
  address_uid: string;
  building_name: string;
  building_number: string;
  nickname: string;
  first_name: string;
  last_name: string;
  county: string;
  is_primary: boolean;
  title: string;
  street: string;
  town: string;
  is_billing_address: boolean;
  flat_number: string;
  delivery_instructions: string;
  blocked: boolean;
};

export type CustomerAddressResponse = {
  addresses: CustomerAddress[];
};

export type CPMResponse = {
  consents: CPMConsent[];
};

export type CPMConsent = {
  consent: boolean;
  mission: string;
};

export type IdentityCustomerResponse = {
  id: string;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
};

const url = "/groceries-api/gol-services/customer";

export const generateFMUrl = (apiUrl: string) => {
  const FM_PREFIX = (window as any).GOL.config.foodMaestroUrl;
  return `${FM_PREFIX}${apiUrl}`;
};

export const dietaryApi = {
  FM_GETCONDITIONS: `/getconditions`,
  FM_GETPROFILE: `/getprofileconditions`,
  FM_UPDATEPROFILE: `/updateprofileconditions`,
  FM_AUTHENTICATE: `/authenticate`,
  FM_DELETEPROFILE: `/deleteuseraccount`,
  GOL_SECURETOKEN: `${url}/v1/customer/dietary-profile/fm-token`,
  GOL_ONBOARDED: `${url}/v1/customer/dietary-profile/onboarded`,
  FM_CREATEPROFILE: `/createprofile`,
};

export const fetchUserTokenFromThirdParty = (
  tokenUrl: string,
  baseUrl: Optional<string> = url
): Promise<Result<{ token: string }>> => {
  return serviceRequest<{ token: string }>({
    method: Method.POST,
    url: `${baseUrl}/v1/customer/third-party`,
    body: { token_url: tokenUrl },
  });
};

export const fetchCustomerProfile = (baseUrl: Optional<string> = url): Promise<Result<CustomerProfileResponse>> => {
  const hasAuthCookies = !!getAuthCookieValue();
  if (!hasAuthCookies) {
    return Promise.reject({});
  }
  return serviceRequest<CustomerProfileResponse>({ method: Method.GET, url: `${baseUrl}/v1/customer/profile` });
};

export const deleteCustomerAddress = (addressId: string, baseUrl: Optional<string> = url): Promise<Result<void>> => {
  return serviceRequest({
    method: Method.DELETE,
    url: `${baseUrl}/v1/customer/address/${addressId}`,
  });
};

export const fetchCustomerAddresses = (baseUrl: String = url): Promise<Result<CustomerAddressResponse>> => {
  return serviceRequest<CustomerAddressResponse>({ method: Method.GET, url: `${baseUrl}/v1/customer/address` });
};

export const editCustomerAddress = (
  address: CustomerAddress,
  baseUrl: Optional<string> = url
): Promise<Result<void>> => {
  return serviceRequest({
    method: Method.PUT,
    url: `${baseUrl}/v1/customer/address/${address.address_uid}`,
    body: { ...address },
  });
};

export const fetchCPMConsents = (baseUrl: String = url): Promise<Result<CPMResponse>> => {
  return serviceRequest<CPMResponse>({ method: Method.GET, url: `${baseUrl}/v1/customer/consents` });
};

export const updatePostcode = (postcode: string, baseUrl: Optional<string> = url): Promise<Result<void>> =>
  serviceRequest({ method: Method.PUT, url: `${baseUrl}/v1/customer/postcode`, body: { postcode } });

export const emailVerification = (
  email: string,
  postcode: string,
  baseUrl: Optional<string> = url
): Promise<Result<void>> =>
  serviceRequest({ method: Method.POST, url: `${baseUrl}/v1/customer/email-verification`, body: { email, postcode } });

export const updateDietaryProfileStatus = (enabled: boolean, baseUrl: Optional<string> = url): Promise<Result<void>> =>
  serviceRequest({
    method: Method.PUT,
    url: `${baseUrl}/v1/customer/dietary-profile/status`,
    body: { enabled },
  });

export const updateDietaryProfileOnboard = (
  onboarded: boolean,
  baseUrl: Optional<string> = url
): Promise<Result<void>> =>
  serviceRequest({ method: Method.PUT, url: `${baseUrl}/v1/customer/dietary-profile/onboarded`, body: { onboarded } });

export const getFoodMaestroToken = (baseUrl: Optional<string> = url): Promise<Result<string>> =>
  serviceRequest({ method: Method.GET, url: `${baseUrl}/v1/customer/dietary-profile/fm-token` });

export const authenticateFoodMaestro = (Authorization: string): Promise<Result<AuthenticationRes>> =>
  request(Method.POST, generateFMUrl(dietaryApi.FM_AUTHENTICATE), undefined, { Authorization });

export const getFoodMaestroConditions = (Authorization: string): Promise<Result<CondtionsRes>> =>
  request(Method.POST, generateFMUrl(dietaryApi.FM_GETCONDITIONS), undefined, { Authorization });

export const getFoodMaestroProfile = (Authorization: string): Promise<Result<ProfileConditionsItem[]>> =>
  request(Method.POST, generateFMUrl(dietaryApi.FM_GETPROFILE), undefined, { Authorization });

export const updateFoodMaestroProfile = (Authorization: string, body: any): Promise<Result<UpdateProfileRes>> =>
  request(
    Method.POST,
    generateFMUrl(dietaryApi.FM_UPDATEPROFILE),
    body,
    {
      Authorization,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    false
  );

export const deleteFoodMaestroProfile = (Authorization: string) =>
  request(Method.POST, generateFMUrl(dietaryApi.FM_DELETEPROFILE), undefined, { Authorization });

export const callNewProgressiveRegistrationEndpoint = (
  progRegRequest: ProgressiveRegistrationV2Request,
  baseUrl: Optional<string> = url
): Promise<Result<LoginResponse>> =>
  serviceRequest<LoginResponse>({ method: Method.POST, url: `${baseUrl}/v1/customer/profile`, body: progRegRequest });

export const getIdentityProfile = (
  Authorization: string,
  baseUrl: Optional<string> = url
): Promise<Result<IdentityCustomerResponse>> => {
  return request<IdentityCustomerResponse>(Method.GET, `${baseUrl}/v1/customer/identity-profile`, undefined, {
    Authorization: `Bearer ${Authorization}`,
  });
};

export type DeliveryPassOptionsResponse = {
  current_pass_name: string;
  current_pass_length: number;
  current_pass_price: number;
  current_pass_expiry: string;
  token_expiry: string;
  has_saved_preference: boolean;
  saved_preference?: DeliveryPassSavedPreference;
  recommended_pass_sku: string;
  passes: DeliveryPassOption[];
};

type DeliveryPassSavedPreference = {
  opted_for_renewal: boolean;
  chosen_pass?: DeliveryPassOption;
};

type DeliveryPassOption = {
  sku: string;
  type: string;
  name: string;
  price: number;
  discounted_price?: number;
  discount?: number;
  price_info_text: string;
};

export const fetchDeliveryPassOptions = (
  token: string,
  baseUrl: Optional<string> = url
): Promise<Result<DeliveryPassOptionsResponse>> => {
  return serviceRequest<DeliveryPassOptionsResponse>({
    method: Method.GET,
    url: `${baseUrl}/v1/customer/delivery-pass?token=${token}`,
  });
};

type SaveDeliveryPassPreferenceRequest = {
  token: string;
  preference: boolean;
  sku: string;
};

type SaveDeliveryPassPreferenceResponse = {};

export const saveDeliveryPassPreference = (
  saveDeliveryPassPreferenceRequest: SaveDeliveryPassPreferenceRequest,
  baseUrl: Optional<string> = url
): Promise<Result<SaveDeliveryPassPreferenceResponse>> => {
  return serviceRequest<SaveDeliveryPassPreferenceResponse>({
    method: Method.PUT,
    url: `${baseUrl}/v1/customer/delivery-pass`,
    body: saveDeliveryPassPreferenceRequest,
  });
};
