import React from "react";
import { ErrorPage } from "./ErrorPage";
import fruitImage from "../../static/empty-trolley.svg";
import { ReloadButton } from "../ReloadButton/ReloadButton.component";

export const RecoverableErrorPage = () => (
  <ErrorPage
    header="Something went wrong"
    description="Sorry, we're having trouble showing you this page right now. Try refreshing the page to see if that fixes the problem."
    image={<img alt="Fruit" src={fruitImage} />}
    callToAction={<ReloadButton />}
  />
);

RecoverableErrorPage.displayName = "RecoverableErrorPage";
