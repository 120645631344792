import { MutableRefObject, useEffect, useState } from "react";

type HTMLElement = Element | null;

export type IntersectionObserverParams = {
  target: MutableRefObject<HTMLElement>;
  rootRef?: MutableRefObject<HTMLElement>;
  rootMargin?: string;
  threshold?: number[] | number;
};

export const useIntersectionObserver = ({
  rootRef,
  target,
  rootMargin = "0px",
  threshold = 1,
}: IntersectionObserverParams) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    // If we're trying to observe elements in a root element that does not exist
    if (rootRef && rootRef.current === null) return;

    const intersectionObserver = new IntersectionObserver(
      entries => {
        setIsIntersecting(entries[0].isIntersecting);
      },
      { threshold, rootMargin, root: rootRef?.current ?? undefined }
    );

    if (target && target.current) {
      intersectionObserver.observe(target.current);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, [target, rootRef, rootMargin, threshold]);

  return isIntersecting;
};
