import { formatCurrency } from "../../../common/format";
import { State } from "../../../common/store";
import { ProductTileMiniProduct } from "../../ProductTile/productTile.types";
import { createSelector } from "reselect";
import { getBasketPromotionsWithProductPromotionURL } from "../../../domain/basket/basket";
import { Product, ProductUid } from "../../../domain/product/product.types";
import { BasketProduct } from "../../../domain/basket/basket.types";

export const convertToFormatCurrency = (data: number | undefined): string =>
  typeof data !== "undefined" ? formatCurrency(data) : "";

export const selectSubtotal = (data: number | undefined): string => convertToFormatCurrency(data);

export const selectSavings = (data: number | undefined): string => {
  const savings = convertToFormatCurrency(data);
  return savings.length > 0 && data !== 0 ? `Savings -${savings}` : "";
};

export const mapBasketProductsToMiniProductTileProducts = (
  basketProducts: BasketProduct[],
  products: Map<ProductUid, Product>
): ProductTileMiniProduct[] => {
  return basketProducts?.map((basketProduct: BasketProduct): ProductTileMiniProduct => {
    const product = products.get(basketProduct.sku);
    const mappedBasketPromotions = getBasketPromotionsWithProductPromotionURL(basketProduct.promotions, product);
    const showPromotionPrice = basketProduct.promotions.some(promotion => !promotion.promotionMissed);

    return {
      product: {
        ...basketProduct,
        image: product?.image ?? basketProduct.image,
        promotions: mappedBasketPromotions,
      },
      showPromotionPrice,
      serves: product?.occasion?.serves,
    };
  });
};

export const selectMiniTrolleyProducts = createSelector(
  (state: State) => state.basket.data.items.getItems(),
  (state: State) => state.products.data,
  mapBasketProductsToMiniProductTileProducts
);
