import { useEffect } from "react";

/**
 * Custom hook that compensates for the width of the scrollbar on modal renders.
 *
 * This prevents content on the page from shifting when opening modals
 * resulting in a cleaner user experience.
 *
 * @returns {void}
 */
export const useHandleScrollbarWidth = (): void => {
  const getScrollbarWidth = (): number => {
    const scrollbarDiv = document.createElement("div");
    scrollbarDiv.style.visibility = "hidden";
    scrollbarDiv.style.overflow = "scroll";
    document.body.appendChild(scrollbarDiv);

    const width: number = scrollbarDiv.offsetWidth - scrollbarDiv.clientWidth;
    document.body.removeChild(scrollbarDiv);
    return width;
  };

  useEffect(() => {
    const headerWrapper = document.querySelector(".header-wrapper") as HTMLElement;
    const scrollbarWidth = getScrollbarWidth();

    if (scrollbarWidth > 0) {
      document.body.style.marginRight = `${scrollbarWidth}px`;
      if (headerWrapper) {
        headerWrapper.style.marginRight = `${scrollbarWidth}px`;
      }
    }

    return () => {
      if (scrollbarWidth) {
        document.body.removeAttribute("style");
        headerWrapper && headerWrapper.removeAttribute("style");
      }
    };
  }, []);
};
