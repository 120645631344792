import { GOLUI_PREFIX } from "../../../routes";

/**
 * @param url - the URL
 * @returns the formed URL and if it's an external link
 */
export function checkMegaNavUrl(url: string) {
  if (!url || url === "/") {
    return { extern: false, url: "" };
  }

  let extern = false;
  if (url.startsWith("http")) {
    extern = true;
  } else if (!url.startsWith("/")) {
    url = `/${url}`;
  }
  if (!url.startsWith(GOLUI_PREFIX)) {
    extern = true;
  }

  return {
    extern,
    url,
  };
}
