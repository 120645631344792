import { ClickSource } from "../../../../domain/basket/basket.types";
import { AddToCartOrigin } from "../../../dataLayer/types";

export const getOriginFromClickSource = (clickSource?: ClickSource): string | undefined => {
  switch (clickSource) {
    case ClickSource.FREQUENTLY_BOUGHT_TOGETHER:
      return AddToCartOrigin.FREQUENTLY_BOUGHT_TOGETHER;
    case ClickSource.MINI_TROLLEY:
      return AddToCartOrigin.TROLLEY_MINI;
    case ClickSource.NECTAR_ADD_ALL_BONUS_POINTS:
      return AddToCartOrigin.NECTAR_ADD_ALL_BONUS;
    case ClickSource.CHANGES_TO_TROLLEY:
      return AddToCartOrigin.CHANGES_TO_TROLLEY;
    case ClickSource.OUT_OF_STOCK:
      return AddToCartOrigin.OUT_OF_STOCK;
    case ClickSource.VIEW_SIMILAR:
      return AddToCartOrigin.VIEW_SIMILAR;
    default:
      return undefined;
  }
};
