import React from "react";
import { AccessibleSkipTo } from "./AccessibleSkipTo.component";
import { routes, urls } from "../../routes";
import { Account, Locked } from "@jsluna/icons";
import { AuthorizedContainer } from "../Authorized";
import { Route, Switch } from "react-router-dom";
import { BookDeliveryContainer } from "./BookDelivery/BookDelivery.container";
import { MiniTrolleyContainer } from "./MiniTrolley/MiniTrolley.container";
import { HeaderLinkObject, HeaderLinkProps } from "./Header.types";
import clsx from "clsx";
import { Link } from "@sainsburys-tech/fable";
import { useResponsive } from "../ResponsiveProvider";
import { featureFlags } from "../../common/firebase";

export const MultiSearchLink: (props: HeaderLinkProps) => React.ReactElement = ({ customStyle }: HeaderLinkProps) => (
  <Link
    href={HEADER_LINKS["multi-search"].url}
    className={clsx("multi-search", customStyle)}
    data-testid="multi-search-link"
    onClick={(e: React.MouseEvent) => {
      trackEvent("Search a list of items");
    }}
  >
    <span>{HEADER_LINKS["multi-search"].label}</span>
  </Link>
);

export const TopLeftLinks = () => {
  const { isTablet } = useResponsive();
  const links = [HEADER_LINKS.explore, HEADER_LINKS["help-centre"], HEADER_LINKS["store-locator"]];
  return (
    <>
      <AccessibleSkipTo />
      <ul className="top-links">
        {links.map(l => (
          <li key={`top-links-${l.url}`}>
            <Link href={l.url}>{l.tabletLabel && isTablet ? l.tabletLabel : l.label}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export const LogoutLink = () => {
  const isNewGlobalHeader = featureFlags.get("new_global_header");
  const className = isNewGlobalHeader
    ? "top-right-links--logout-new-global-header ln-u-font-weight-bold"
    : "top-right-links--logout";
  return (
    <Link
      onClick={(e: React.MouseEvent) => {
        trackEvent("Log out");
      }}
      href={HEADER_LINKS.logout.url}
      className={className}
      data-testid="logout-link"
    >
      {isNewGlobalHeader && <Locked className="logout-link__icon" alt="" aria-labelledby="logout-link" />}
      {HEADER_LINKS.logout.label}
    </Link>
  );
};

const trackEvent = (label: string) => {
  (window as any).digitalData.track("ev_headernavClick", {
    hit_type: "link",
    data_event_action: "click",
    data_event_category: "header nav",
    data_event_label: label,
  });
};

export const LoginRegisterLink = () => {
  return (
    <Link
      onClick={(e: React.MouseEvent) => {
        trackEvent("Login - Register");
      }}
      href={HEADER_LINKS.login.url}
      className="top-right-links--login ln-u-font-weight-bold"
      data-testid="login-register-link"
    >
      {HEADER_LINKS.login.label}
    </Link>
  );
};

export const MyAccountLink = () => {
  const isNewGlobalHeader = featureFlags.get("new_global_header");
  const className = isNewGlobalHeader ? "top-right-links--myaccount ln-u-font-weight-bold" : "account-link";
  return (
    <Link
      id="account-link"
      className={className}
      onClick={(e: React.MouseEvent) => {
        trackEvent("My account");
      }}
      href={HEADER_LINKS["my-account"].url}
      data-testid="my-account-link"
      aria-label="My account"
    >
      <Account className="account-link__icon" alt="" aria-labelledby="account-link" />
      {isNewGlobalHeader ? HEADER_LINKS["my-account-new-global-header"].label : HEADER_LINKS["my-account"].label}
    </Link>
  );
};

export const TopRightLinks = ({ className }: { className?: string }) => {
  const isInMinimizedHeader = document.querySelector(".header-minimized.visible") !== null;

  return (
    <div className={clsx("top-right-links", className, isInMinimizedHeader && "top-right-links--minimized")}>
      <AuthorizedContainer fallback={<LoginRegisterLink />}>
        <Switch>
          <Route path={routes.MY_ACCOUNT} component={LogoutLink} />
          <Route component={MyAccountLink} />
        </Switch>
        <Route path={routes.ORDER_CONFIRMATION} component={LogoutLink} />
      </AuthorizedContainer>
      <BookDeliveryContainer />
      <MiniTrolleyContainer />
    </div>
  );
};

const HEADER_LINKS: Readonly<HeaderLinkObject> = {
  explore: {
    label: "Explore",
    url: "https://www.sainsburys.co.uk/",
    external: true,
  },

  "help-centre": {
    label: "Help Centre",
    url: "https://help.sainsburys.co.uk/",
    external: true,
  },
  "store-locator": {
    label: "Store Locator",
    url: "https://stores.sainsburys.co.uk/",
    external: true,
  },

  login: {
    label: "Log in / Register",
    url: urls.LOGON_VIEW_IDENTIFIER,
    external: true,
  },
  logout: {
    label: "Log out",
    url: "/shop/gb/preLogOff?langId=44&storeId=10151",
    external: true,
  },
  "my-account": {
    label: "My account",
    url: "/gol-ui/MyAccount",
    external: true,
  },
  "my-account-new-global-header": {
    label: "My Account",
    url: "/gol-ui/MyAccount",
    external: true,
  },
  "multi-search": {
    label: "Search by list",
    url: urls.MULTI_SEARCH,
    external: true,
  },
};
