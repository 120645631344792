import React, { ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import { State, UserState } from "../../common/store";
import { WithChildren } from "../../common/types";
import { RedirectInternal } from "../RedirectInternal";
import { urls } from "../../routes";
import { DataState } from "../../common/dataState";
import { ConditionalRender, ConditionalRenderProps } from "../ConditionalRender";
import { GENERIC_USER_ID } from "../../common/analytics/createDigitalData";

export enum AuthorizationState {
  AUTHORIZED,
  UNAUTHORIZED,
  PENDING,
}

interface AuthorizedProps {
  authorizationState: AuthorizationState;
}

interface AuthorizedContainerProps {
  fallback?: JSX.Element | null;
}

type AuthorizedComponentProps = AuthorizedProps & AuthorizedContainerProps & WithChildren;

export class Authorized extends React.Component<AuthorizedComponentProps> {
  static defaultProps = {
    fallback: <RedirectInternal to={urls.OAUTH_LOGIN} />,
  };

  render() {
    switch (this.props.authorizationState) {
      case AuthorizationState.AUTHORIZED:
        return this.props.children || null;
      case AuthorizationState.PENDING:
        return null;
      case AuthorizationState.UNAUTHORIZED:
        return this.props.fallback;
      default:
        return null;
    }
  }
}

export const getAuthorizationState = (state: UserState): AuthorizationState => {
  switch (state.dataState) {
    case DataState.SUCCESS:
      const userIsAuthorized = state.userDetails ? state.userDetails.isRegistered : false;
      return userIsAuthorized ? AuthorizationState.AUTHORIZED : AuthorizationState.UNAUTHORIZED;
    case DataState.PENDING:
    case DataState.UNKNOWN:
      return AuthorizationState.PENDING;
    case DataState.FAILED:
      return AuthorizationState.UNAUTHORIZED;
    default:
      return AuthorizationState.UNAUTHORIZED;
  }
};

const mapStateToProps = (state: State, ownProps: WithChildren) => ({
  authorizationState: getAuthorizationState(state.user),
  ...ownProps,
});

export interface WithIsAuthorized {
  isAuthorized: boolean;
}

const mapStateToIsAuthorized = (state: State): WithIsAuthorized => {
  const userAuthorizationState = getAuthorizationState(state.user);

  return {
    isAuthorized: userAuthorizationState === AuthorizationState.AUTHORIZED,
  };
};

export function withAuthorizedState(WrappedComponent: ComponentType) {
  return connect(mapStateToIsAuthorized)(WrappedComponent);
}

export const AuthorizedContainer = connect<AuthorizedProps, AuthorizedContainerProps & WithChildren>(mapStateToProps)(
  Authorized
);

export const selectIsRegistered = (state: State) => {
  const { dataState, userDetails } = state.user;
  switch (dataState) {
    case DataState.SUCCESS:
      return userDetails!.isRegistered;
    default:
      return false;
  }
};

export const selectIsRegisteredOrGuestUser = (state: State) => {
  const { dataState, userDetails } = state.user;
  switch (dataState) {
    case DataState.SUCCESS:
      const isRegistered = userDetails!.isRegistered;
      const isGuest = userDetails!.id !== GENERIC_USER_ID;
      return isRegistered || isGuest;
    default:
      return false;
  }
};

interface OwnProps {
  children?: ReactNode;
}

const nonGenericUserMapStateToProps = (state: State, { children }: OwnProps) =>
  ({
    predicate: () => selectIsRegisteredOrGuestUser(state),
    children,
  } as ConditionalRenderProps<boolean>);

export const EnsureNonGenericUser = connect(nonGenericUserMapStateToProps)(ConditionalRender);

export const selectIsAuthorized = (state: State): boolean =>
  getAuthorizationState(state.user) === AuthorizationState.AUTHORIZED;
