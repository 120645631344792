import { State } from "../common/store";
import { ProductsByCategory } from "@favourites/Favourites.types";
import { createSelectorCreator, defaultMemoize } from "reselect";
import { selectSlotStartTime } from "./slot.selectors";
import { shouldShowProduct } from "../domain/product/product";
import { ProductTilePrice } from "../components/ProductTile/productTile.types";
import { ContentPage } from "../services/content";
import {
  AISLE,
  AssociationType,
  Product,
  ProductsState,
  ProductsViews,
  ProductType,
  ProductUid,
} from "../domain/product/product.types";
import { KG } from "../domain/basket/basket.types";
import { isFavouriteIconRoute } from "@favourites/utils/routes";
import isEqual from "lodash.isequal";
import GolTime from "../domain/time";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectIsCategorySort = (state: State): boolean => {
  return Boolean(state.products.controls && state.products.controls.activeSort === AISLE);
};

export const selectProductsCategories =
  (view: ProductsViews): ((products: ProductsState, page: ContentPage) => string | undefined) =>
  (products: ProductsState, page: ContentPage) => {
    if ((page === ContentPage.FAVOURITES || page === ContentPage.SEARCH) && products.views[`${view}`]) {
      const allIds = (products.views[`${view}`] as ProductUid[]).reduce((acc, productUid) => {
        const product = products.data.get(productUid);
        const categories = product ? product.categories : null;
        return categories ? [...acc, ...categories.map((cat: { id: string }) => cat.id)] : acc;
      }, []);
      return [...new Set(allIds)].join(",");
    }
    return undefined;
  };

export const selectHasOptions = (product: Product): boolean => {
  return Boolean(product.multivariants || product.catchweight);
};

export const selectShouldShowCostPerUnit = (productType: string, retailPrice?: ProductTilePrice): boolean => {
  return productType === ProductType.LOOSE || (!!retailPrice && retailPrice.measure === KG);
};

export const selectProducts = (view: ProductsViews): ((state: State) => ProductUid[]) => {
  return state =>
    (state.products.views[`${view}`] as ProductUid[]).filter(productUid => {
      const slotStartTime = selectSlotStartTime(state);
      const product = state.products.data.get(productUid);
      return product && shouldShowProduct(product, slotStartTime);
    });
};

export const createProductsSelector = createDeepEqualSelector(
  [
    (state: State) => selectSlotStartTime(state),
    (state: State) => state.products.data,
    (state: State, view: ProductsViews) => state.products.views[view],
  ],
  (slotStartTime: GolTime | undefined, products: Map<string, Product>, viewProducts: ProductUid[]) => {
    return viewProducts.filter(uid => {
      const product = products.get(uid);
      return product && shouldShowProduct(product, slotStartTime);
    });
  }
);

export const createProductsByCategorySelector = createDeepEqualSelector(
  [(state: State, view: ProductsViews) => createProductsSelector(state, view), (state: State) => state.products.data],
  (productUids: ProductUid[], products: Map<ProductUid, Product>): ProductsByCategory[] => {
    const groups = productUids
      .map(productUid => products.get(productUid))
      .reduce(
        (accumulator: Accumulator, favouriteProduct: Product) => {
          const { productUid, zone, department, associationType } = favouriteProduct;

          if (zone && department) {
            const categoryName = `${zone} - ${department}`;
            addProductToCategory(accumulator, categoryName, productUid);
          }

          // If the product is an associated product it needs to sit next to the parent product.
          // We ignore the associated product category and assign the parent category to preserve ordering.
          // Zone and department is not defined in the response for associated products
          const isAssociatedProduct = !zone && !department && associationType !== AssociationType.NONE;
          if (isAssociatedProduct) {
            const parentCategoryName = accumulator.categories[accumulator.categories.length - 1];
            if (parentCategoryName) {
              addProductToCategory(accumulator, parentCategoryName, productUid);
            }
          }

          return accumulator;
        },
        { categories: [], productsByCategory: {} }
      );
    return groups.categories.map(category => ({
      category,
      products: groups.productsByCategory[`${category}`],
    }));
  }
);

export const isFavourite = (state: State, product?: Product): boolean => {
  const { pathname } = state.router.location;

  if (isFavouriteIconRoute(pathname)) {
    return !!product?.favourite;
  }

  return product ? product.favouriteUid !== "" && product.favouriteUid !== null : false;
};

const addProductToCategory = (accumulator: Accumulator, categoryName: string, productUid: string) => {
  if (!accumulator.productsByCategory[`${categoryName}`]) {
    accumulator.productsByCategory[`${categoryName}`] = [];
    accumulator.categories.push(categoryName);
  }
  accumulator.productsByCategory[`${categoryName}`].push(productUid);
};

type Accumulator = { categories: string[]; productsByCategory: { [p: string]: ProductUid[] } };
