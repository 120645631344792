import React, { ReactElement, useState } from "react";
import { Cancel } from "@jsluna/icons";
import { Tooltip, TooltipMessage } from "@jsluna/labs";
import { LinkButton } from "@jsluna/react";
import clsx from "clsx";

export interface FeatureTourTooltipProps {
  position?:
    | "left"
    | "left-start"
    | "left-end"
    | "right"
    | "right-start"
    | "right-end"
    | "top"
    | "top-start"
    | "top-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end";
  isOpen: boolean;
  header?: ReactElement | String;
  message: ReactElement | String;
  currentStep?: number;
  totalStep?: number;
  showNextCTA?: boolean;
  nextCTAText?: string;
  children?: ReactElement;
  className?: string;
  isLarge?: boolean;
  showLink?: boolean;
  handleNextOnClick?: () => void;
  handleCloseOnClick?: () => void;
}

export const FeatureTourTooltip = React.memo(
  ({
    position = "bottom-start",
    isOpen,
    header,
    message,
    currentStep,
    totalStep,
    showNextCTA = false,
    nextCTAText = "Next",
    children,
    className = "",
    isLarge = false,
    showLink = false,
    handleNextOnClick,
    handleCloseOnClick,
  }: FeatureTourTooltipProps) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(isOpen);

    const stepCount = currentStep && totalStep && currentStep <= totalStep && (
      <div data-testid="fav-tooltip_step" className="fav-tooltip_footer_step">
        {currentStep} of {totalStep}
      </div>
    );

    const nextCTA = showNextCTA && (
      <LinkButton
        tabIndex={-1}
        onClick={handleNextOnClick}
        data-testid="fav-tooltip_next"
        className="fav-tooltip_footer_cta"
      >
        {nextCTAText}
      </LinkButton>
    );

    const Footer = (showNextCTA || (currentStep && totalStep)) && (
      <div className="fav-tooltip_footer" data-testid="fav-tooltip_footer">
        {stepCount}
        {nextCTA}
      </div>
    );

    const Header = header && (
      <b className="fav-tooltip_header" role="heading" aria-level={1} data-testid="fav-tooltip_header">
        {header}
      </b>
    );

    const handleOnClick = () => {
      if (handleCloseOnClick) {
        handleCloseOnClick();
      } else {
        setIsTooltipOpen(false);
      }
    };

    const tooltipMessage = (
      <div className="fav-tooltip_wrapper">
        <div className="fav-tooltip_content-container">
          <div className="fav-tooltip_content">
            {Header}
            <div className="fav-tooltip_message" data-testid="fav-tooltip_message">
              {message}
            </div>
          </div>
          <button tabIndex={-1} className="fav-tooltip_close" onClick={handleOnClick} data-testid="fav-tooltip_close">
            <Cancel />
          </button>
        </div>
        {Footer}
      </div>
    );

    return (
      <Tooltip
        open={isTooltipOpen}
        position={position}
        className={clsx(
          "fav-tooltip",
          `fav-tooltip-${position}`,
          { "fav-tooltip-large": isLarge },
          { "fav-tooltip-showLink": showLink },
          className
        )}
        data-testid="fav-tooltip"
        aria-hidden={true}
        role="tooltip"
      >
        {children}
        <TooltipMessage>{tooltipMessage}</TooltipMessage>
      </Tooltip>
    );
  }
);
