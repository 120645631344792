import React, { useEffect, useState } from "react";
import { Form, ProgressIndicator, ProgressSpinner, ProgressMessage } from "@jsluna/react";
import { Search } from "@jsluna/icons";
import { resetIdCounter, useCombobox } from "downshift";
import { urls } from "../../../routes";
import useSuggestions, { SearchBarActionTypes, Suggestion } from "./useSuggestions";
import { magnoliaTemplateClickHandler } from "../../../common/dataLayer/cmsAnalyticsEvent";
import { sendAnalyticsEvent } from "../../../common/dataLayer/analyticsEvent";
import { useDigitalDataEventTracking } from "../../../common/hooks/useDigitalDataEventTracking";
import {
  digitalDataGenericTrackEvent,
  EXPERIMENTS,
  HIT_TYPES,
  INTERACTION_EVENT_NAMES,
  SearchLocations,
  trackExperiment,
} from "../../../common/analytics";
import { useRestingSearch } from "./hooks/useRestingSearch.hook";
import { MagnoliaRenderer } from "../../../components/Magnolia/MagnoliaRenderer.component";
import { useMegaNavActions } from "../headerState/header.actions";
import { useTrackCMSComponentAnalytics } from "../../../common/hooks/useTrackCMSComponentAnalytics";
import { Skeleton } from "@sainsburys-tech/fable";
import { DataState } from "../../../common/dataState";
import { RestingSearchV2 } from "./hooks/useTrendingSearch.hook";
import { MagnoliaTemplate } from "../../../services/content";

const LoadingMessage = () => {
  return (
    <ProgressIndicator loading preventFocus role="option">
      <ProgressSpinner color="dark" className="ln-c-dropdown-list__item ln-c-dropdown-list__item--align-center" />
      <ProgressMessage>Loading...</ProgressMessage>
    </ProgressIndicator>
  );
};

interface SearchBarComponentProps {
  doSearch: (term: string | null, trending: boolean) => void;
  setLastSeenFavouritesProduct: (product: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  checkoutHeader?: boolean;
  restingSearchPreset?: {
    template: MagnoliaTemplate | null;
    isLoading: boolean;
    magnoliaError: boolean;
    restingSearch: RestingSearchV2;
  };
  searchTerm: string;
}

export const SearchBarComponent = ({
  doSearch,
  onFocus,
  onBlur,
  searchTerm,
  setLastSeenFavouritesProduct,
  restingSearchPreset,
  checkoutHeader,
}: Readonly<SearchBarComponentProps>) => {
  const { loading, error, suggestions, getSuggestedTerms, dispatch } = useSuggestions();

  const {
    template: _template,
    isLoading: _isLoading,
    magnoliaError: _magnoliaError,
    restingSearch: _restingSearch,
  } = useRestingSearch(checkoutHeader || !!restingSearchPreset);

  const { template, isLoading, magnoliaError, restingSearch } = restingSearchPreset || {
    template: _template,
    isLoading: _isLoading,
    magnoliaError: _magnoliaError,
    restingSearch: _restingSearch,
  };

  useEffect(() => {
    if (restingSearch.track_experiment && !checkoutHeader) {
      trackExperiment(EXPERIMENTS.Findability_Test_Group_6, restingSearch.suggested_enabled ? "2" : "1");
    }
  }, [restingSearch, checkoutHeader]);

  useTrackCMSComponentAnalytics(!isLoading && template !== null);

  const loadingIndicator = (
    <div role="progressbar" className="trending-search__magnolia__loading">
      <Skeleton height={200} width={200} />
      <Skeleton height={200} width={200} />
      <Skeleton height={200} width={200} />
    </div>
  );

  const [term, setTerm] = useState(searchTerm);
  const [typedTerm, setTypedTerm] = useState("");

  const { addToDigitalData } = useDigitalDataEventTracking();

  const handleStateChange = (changes: any) => {
    if (changes.hasOwnProperty("selectedItem")) {
      setTerm(changes.selectedItem);
    } else if (changes.hasOwnProperty("inputValue")) {
      setTypedTerm(changes.inputValue);
      setTerm(changes.inputValue);
    }
  };

  const submitLastSeenFavourite = (product: string) => {
    var strippedProductId = product.replace(/^#/, "");
    setLastSeenFavouritesProduct(strippedProductId);
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // temporary event only for the AB testing will be removed when gets to 100%
    const analyticsEvent = {
      eventName: "SearchInteraction",
      eventCategory: "Search",
      eventAction: "Search submitted",
      eventLabel: "SearchBarNew",
      search_term: term,
    };
    sendAnalyticsEvent(analyticsEvent);
    // end of event
    addToDigitalData({ data_search_typedTerm: typedTerm });
    submitLastSeenFavourite(window.location.hash);
    doSearch(term, false);
    dispatch({ type: SearchBarActionTypes.SET_IDLE });
  };

  const hasSuggestions = suggestions && suggestions.length > 0;
  resetIdCounter();

  const { hideMegaNav } = useMegaNavActions();

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    getComboboxProps,
    openMenu,
  } = useCombobox({
    id: "search-bar",
    items: suggestions
      ? suggestions.map(suggestion => suggestion.value)
      : restingSearch?.suggested_enabled
      ? restingSearch.terms.map(trendingSearch => trendingSearch)
      : [],
    onStateChange: handleStateChange,
    initialSelectedItem: term,
    onSelectedItemChange: value => {
      addToDigitalData({ data_search_typedTerm: typedTerm });
      digitalDataGenericTrackEvent(INTERACTION_EVENT_NAMES.SEARCH_INTERACTION, {
        hit_type: HIT_TYPES.LINK,
        data_search_location: SearchLocations.SEARCH_BAR_NEW,
        data_search_term: value.selectedItem,
        event_name: INTERACTION_EVENT_NAMES.SEARCH_INTERACTION,
      });
      doSearch(String(value.inputValue), suggestions ? false : true);
    },
  });

  const trackEvent = () => {
    (window as any).digitalData.track("ev_headernavClick", {
      hit_type: "link",
      data_event_action: "click",
      data_event_category: "header nav",
      data_event_label: "Search button",
    });
  };

  return (
    <Form
      role="search"
      onSubmit={onSubmit}
      className="search-bar legacy-search"
      data-testid="search-bar-form"
      action={urls.SEARCH_RESULTS}
    >
      <div
        {...getComboboxProps()}
        className="ds-flex-1"
        onClick={() => {
          if (!isOpen && restingSearch.magnolia_enabled && restingSearch.suggested_enabled) {
            openMenu();
          }
        }}
      >
        <div role="searchbox" aria-label="Enter Search terms" aria-controls="search-bar-menu">
          <label htmlFor="search-bar-input" {...getLabelProps()}>
            <input
              aria-controls="search-bar-menu"
              aria-owns="search-bar-menu"
              aria-label="Enter search terms"
              name="filters[\keyword]\"
              className="search-bar__field-group"
              id="search-bar-input"
              data-testid="search-bar-input"
              {...getInputProps({
                type: "text",
                onFocus: () => {
                  hideMegaNav();
                  if (!isOpen) {
                    if (restingSearch?.suggested_enabled || restingSearch?.magnolia_enabled) {
                      openMenu();
                    }
                    if (onFocus) {
                      onFocus();
                    }
                  }
                },
                onBlur,
                placeholder: "Search products",
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  e.persist();
                  getSuggestedTerms(e);
                },
              })}
            />
          </label>
        </div>
        <button
          aria-label="Search button"
          data-testid="search-bar-button"
          className="search-bar__button !ds-bg-transparent !ds-top-0"
          type="submit"
          onClick={trackEvent}
        >
          <Search aria-label="Search products" />
        </button>
        <ul
          {...getMenuProps()}
          role="listbox"
          className={`ln-c-dropdown-list ln-c-dropdown-list--overlay ${
            term?.length < 3 && typedTerm.length < 3 ? "resting-search" : ""
          }`}
          aria-label="Search suggestions list"
          id="search-bar-menu"
        >
          {isOpen &&
            term?.length < 3 &&
            typedTerm.length < 3 &&
            (restingSearch?.magnolia_enabled || restingSearch?.suggested_enabled) &&
            !checkoutHeader && (
              <>
                {restingSearch.suggested_enabled && restingSearch.terms.length > 0 && (
                  <div
                    className={`${
                      magnoliaError || !restingSearch.magnolia_enabled
                        ? "trending-search__container trending-search__container__isolated"
                        : "trending-search__container"
                    }`}
                    role="list"
                  >
                    <h4 className="trending-search__header">Suggested Searches</h4>
                    <ul className="trending-search__list" aria-label="Search suggestions list" id="search-bar-menu">
                      {restingSearch?.terms.map((item: string, index: number) => (
                        <li
                          role="option"
                          aria-selected={index === highlightedIndex}
                          className={`trending-search__list__item ln-c-dropdown-list__item ln-c-dropdown-list__item--align-center ${
                            index === highlightedIndex ? "is-hover" : ""
                          }`}
                          {...getItemProps({
                            key: item + index,
                            index,
                            item,
                          })}
                        >
                          {item}
                        </li>
                      ))}
                      {restingSearch.status === DataState.PENDING && (
                        <div data-testid={"resting-skeleton"}>
                          {Array(10)
                            .fill(true)
                            .map((_, i) => (
                              <li
                                className={
                                  "trending-search__list__item ln-c-dropdown-list__item ln-c-dropdown-list__item--align-center"
                                }
                              >
                                <Skeleton height="0.75rem" width="100%" />
                              </li>
                            ))}
                        </div>
                      )}
                    </ul>
                  </div>
                )}

                {restingSearch.magnolia_enabled && !magnoliaError && (
                  <div className="trending-search__magnolia">
                    {isLoading
                      ? loadingIndicator
                      : template && (
                          <MagnoliaRenderer
                            className="trending-search__magnolia__content"
                            template={template}
                            dataTestId="trending-search-magnolia"
                            onClick={magnoliaTemplateClickHandler}
                          />
                        )}
                  </div>
                )}
              </>
            )}

          {isOpen && suggestions && typedTerm.length > 2 && !error && (
            <>
              {loading && <LoadingMessage />}
              {!loading && !hasSuggestions && (
                <li
                  className="ln-c-dropdown-list__item ln-c-dropdown-list__item--align-center"
                  role="option"
                  aria-disabled={true}
                  aria-selected={false}
                >
                  No suggestions found
                </li>
              )}
              {!loading && hasSuggestions
                ? suggestions.map((item: Suggestion, index: number) => (
                    <li
                      role="option"
                      aria-selected={index === highlightedIndex}
                      className={`ln-c-dropdown-list__item ln-c-dropdown-list__item--align-center ${
                        index === highlightedIndex ? "is-hover" : ""
                      }`}
                      {...getItemProps({
                        key: item.value + index,
                        index,
                        item: item.value,
                      })}
                    >
                      {item.value}
                    </li>
                  ))
                : null}
            </>
          )}
        </ul>
      </div>
    </Form>
  );
};
