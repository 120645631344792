export type KrangRecommendationsPage = {
  enabled: boolean;
};

export type KrangRecommendations = {
  pages?: {
    offers?: KrangRecommendationsPage;
    favourites?: KrangRecommendationsPage;
    before_you_go?: KrangRecommendationsPage;
  };
};

export enum OfferPageCarouselsKey {
  OFFERS_GREAT_OFFERS_CAROUSEL = "offersGreatOffersCarousel",
  OFFERS_FAVOURITES_CAROUSEL = "offersFavouritesCarousel",
  OFFERS_CITRUS_CAROUSEL = "offersCitrusCarousel",
  OFFERS_KRANG_CAROUSEL = "offersKrangCarousel",
  OFFERS_YNP_CAROUSEL = "yourNectarPricesCarousel",
  NECTAR_ZONAL_YNP_CAROUSEL = "NectarZonalYNPCarousel",
}
