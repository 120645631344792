import { ProductSource } from "../../../../domain/product/product.types";
import { AddToCartOrigin } from "../../../dataLayer/types";

export const getOriginFromProductSource = (
  productSource?: ProductSource | string,
  carouselKey?: string
): string | undefined => {
  switch (productSource) {
    case ProductSource.CITRUS_SEARCH_ADS_BELOW_GRID:
      return AddToCartOrigin.CITRUS_SEARCH_BELOW_GRID;
    case ProductSource.CITRUS_XSELL_AD_PDP:
      return AddToCartOrigin.CITRUS_XSELL_PDP;
    case ProductSource.KRANG:
      return AddToCartOrigin.KRANG;
    case ProductSource.FREQUENTLY_BOUGHT_TOGETHER:
      return AddToCartOrigin.FREQUENTLY_BOUGHT_TOGETHER;
    case ProductSource.HIERARCHY:
      return carouselKey ? `${carouselKey} Carousel` : AddToCartOrigin.HIERARCHY;
    case ProductSource.EVENTS:
    case ProductSource.FEATURES:
      return carouselKey ? `${carouselKey} Carousel` : "";
    case ProductSource.MEAL_DEAL_BUILDER:
      return AddToCartOrigin.MEAL_DEAL;
    case ProductSource.HOME_PAGE:
      return carouselKey ? `${carouselKey} Carousel` : AddToCartOrigin.HOME_PAGE;
    case ProductSource.BROWSE_ZONE:
      return carouselKey ? `${carouselKey} Carousel` : AddToCartOrigin.ZONE_PAGE;
    case ProductSource.OUT_OF_STOCK:
      return AddToCartOrigin.OUT_OF_STOCK;
    case ProductSource.VIEW_SIMILAR:
      return AddToCartOrigin.VIEW_SIMILAR;
    default:
      return undefined;
  }
};
