import { getChooseShoppingMethodUrl, matchesPath, urls } from "../../routes";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useState } from "react";
import { encodeQueryParams } from "../../common/http/query";
import { startCheckout as servicesStartCheckout } from "../../services/checkout";
import { getCachedSlotReservation } from "../slot/slotReservation";
import { reserveSlotAction } from "../../views/HomeDelivery/slotReservation.actions";
import { useFeatureFlag } from "../../common/firebase/featureFlags.hooks";
import AmericanExpress from "../../static/amex-card.svg";
import Maestro from "../../static/maestro-card.svg";
import Mastercard from "../../static/master-card.svg";
import Visa from "../../static/visa-card.svg";
import VisaElectron from "../../static/visa-electron-card.svg";
import { useCoupon } from "../coupon/coupon";

export const ERROR_CODE_BILLING_ADDRESS_NOT_FOUND = "BILLING_ADDRESS_NOT_FOUND";
export const ERROR_CODE_SLOT_NOT_FOUND = "SLOT_NOT_FOUND";
export const ERROR_CODE_SLOT_EXPIRED = "SLOT_EXPIRED";
export const ERROR_CODE_GENERAL = "GENERAL_ERROR";
export const CHANGES_TO_TROLLEY = "CHANGES_TO_TROLLEY";
export const MULTIPLE_BILLING_ADDRESSES_FOUND = "MULTIPLE_BILLING_ADDRESSES_FOUND";
export const DELIVERY_ADDRESS_NOT_FOUND = "DELIVERY_ADDRESS_NOT_FOUND";
export const INVALID_COUPONS = "INVALID_COUPONS";
export const ORDER_NOT_FOUND = "ORDER_NOT_FOUND";
export const ORDER_STILL_UPDATING = "ORDER_STILL_UPDATING";
export const INVALID_FILTER_PARAM = "INVALID_FILTER_PARAM";
export const INVALID_SLOT_BOOKING_KEY = "INVALID_SLOT_BOOKING_KEY";
export const SLOT_VALIDATION_FAILURE = "SLOT_VALIDATION_FAILURE";
export const SLOT_BOOKING_FAILED = "SLOT_BOOKING_FAILED";
export const ORDER_CANNOT_BE_CHANGED_POST_CUTOFF = "ORDER_CANNOT_BE_CHANGED_POST_CUTOFF";
export const OCCASION_CANNOT_BE_CHANGED_POST_CUTOFF = "OCCASION_CANNOT_BE_CHANGED_POST_CUTOFF";

export interface UseCheckoutProps {
  startCheckout: () => void;
  error?: "general_error" | "slot_error" | "cut_off_error" | "occasion_cut_off_error";
}
const redirectToWCSCheckoutFlow = (param?: string) => {
  let url = urls.CHECKOUT_FLOW;
  if (param) {
    url = `${urls.CHECKOUT_FLOW}${param}`;
  }
  window.location.assign(url);
};

export const redirectToWCSOrderCalculate = () => window.location.assign(urls.WCS_ORDER_CALCULATE);

export const useCheckout = () => {
  const useFBSv1 = useFeatureFlag("use_food_basket_service");
  const useFBSv2 = useFeatureFlag("use_food_basket_service_v2");
  const useFBSv3 = useFeatureFlag("use_food_basket_service_v3");
  const isBYGABPageOrderTestEnabled = useFeatureFlag("byg_ab_test_page_order");
  const startCheckoutEndpointVersion = useFBSv1 && (useFBSv2 || useFBSv3) ? "v2" : "v1";
  const isCheckoutBYGPage = matchesPath(location.pathname, urls.CHECKOUT_BEFORE_YOU_GO);
  const isCheckoutFFPage = matchesPath(location.pathname, urls.CHECKOUT_FORGOTTEN_FAVOURITES);
  const changesToTrolleyGolUi = useFeatureFlag("changes_to_trolley");
  const { fetchCoupons } = useCoupon();
  const [error, setError] = useState<
    "general_error" | "slot_error" | "cut_off_error" | "occasion_cut_off_error" | undefined
  >();
  const dispatch = useDispatch();
  const { reservationType } = getCachedSlotReservation() || {};
  const isClickAndCollect = reservationType === "Collection";

  const redirectToBYG = () => dispatch(push(urls.CHECKOUT_BEFORE_YOU_GO));
  const redirectToFF = () => dispatch(push(urls.CHECKOUT_FORGOTTEN_FAVOURITES));
  const redirectToCheckoutSummary = () => dispatch(push(urls.CHECKOUT_SUMMARY));

  const startCheckout = (isInAmendMode: boolean, basketId: string) => {
    //fetch coupons here to update the basket as soon as we land in Checkout page
    fetchCoupons();
    if (isCheckoutBYGPage) {
      sessionStorage.setItem("checkoutBYGPageSeen", basketId);
      if (isBYGABPageOrderTestEnabled) {
        redirectToCheckoutSummary();
      } else {
        redirectToFF();
      }
    } else if (isCheckoutFFPage) {
      sessionStorage.setItem("checkoutFFPageSeen", basketId);
      if (isBYGABPageOrderTestEnabled) {
        redirectToBYG();
      } else {
        redirectToCheckoutSummary();
      }
    } else {
      servicesStartCheckout(startCheckoutEndpointVersion)
        .then(result => {
          if (!result.isSuccess()) {
            if (
              result.errors.hasCode(ERROR_CODE_BILLING_ADDRESS_NOT_FOUND) ||
              result.errors.hasCode(MULTIPLE_BILLING_ADDRESSES_FOUND) ||
              result.errors.hasCode(DELIVERY_ADDRESS_NOT_FOUND)
            ) {
              redirectToWCSCheckoutFlow("&proceedToWcs=true");
              return;
            }
            if (result.errors.hasCode(ERROR_CODE_SLOT_NOT_FOUND) || result.errors.hasCode(ERROR_CODE_SLOT_EXPIRED)) {
              window.location.assign(getChooseShoppingMethodUrl());
              return;
            }
            if (result.errors.hasCode(CHANGES_TO_TROLLEY)) {
              const params = encodeQueryParams(
                {
                  previousPageName: "MyTrolleyCheckout",
                  clickAndCollect: isClickAndCollect,
                  isOrderAmend: isInAmendMode,
                },
                false
              );

              if (changesToTrolleyGolUi) {
                dispatch(reserveSlotAction());
              } else {
                window.location.assign(`${urls.WCS_BROWSE_PRODUCTS}&${params}`);
              }
              return;
            }
            if (
              result.errors.hasCode(ORDER_NOT_FOUND) ||
              result.errors.hasCode(ORDER_STILL_UPDATING) ||
              result.errors.hasCode(INVALID_FILTER_PARAM) ||
              result.errors.hasCode(ERROR_CODE_GENERAL)
            ) {
              setError("general_error");
              return;
            }
            if (
              result.errors.hasCode(INVALID_SLOT_BOOKING_KEY) ||
              result.errors.hasCode(SLOT_VALIDATION_FAILURE) ||
              result.errors.hasCode(SLOT_BOOKING_FAILED)
            ) {
              setError("slot_error");
              return;
            }
            if (result.errors.hasCode(ORDER_CANNOT_BE_CHANGED_POST_CUTOFF)) {
              setError("cut_off_error");
              return;
            }
            if (result.errors.hasCode(OCCASION_CANNOT_BE_CHANGED_POST_CUTOFF)) {
              setError("occasion_cut_off_error");
              return;
            }
            if (result.errors.hasCode(INVALID_COUPONS)) {
              checkoutStartSuccessNavigation(basketId);
              return;
            }
            throw new Error(result.errors.first().code);

            // TODO: handle rest of errors
          }
          checkoutStartSuccessNavigation(basketId);
        })
        .catch(() => setError("general_error"));
    }
  };

  const checkoutStartSuccessNavigation = (basketId: string) => {
    sessionStorage.setItem("startCheckout", basketId);
    const isCheckoutBYGPageSeen = sessionStorage.getItem("checkoutBYGPageSeen") === basketId;
    const isCheckoutFFPageSeen = sessionStorage.getItem("checkoutFFPageSeen") === basketId;

    if (isBYGABPageOrderTestEnabled) {
      if (!isCheckoutFFPageSeen) {
        redirectToFF();
      }

      if (!isCheckoutBYGPageSeen && isCheckoutFFPageSeen) {
        redirectToBYG();
      }
    } else {
      if (!isCheckoutBYGPageSeen) {
        redirectToBYG();
      }

      if (!isCheckoutFFPageSeen && isCheckoutBYGPageSeen) {
        redirectToFF();
      }
    }

    if (isCheckoutFFPageSeen && isCheckoutBYGPageSeen) {
      redirectToCheckoutSummary();
    }
  };

  return { startCheckout, error };
};

export const getCardNetworkDetails = (cardNetwork: string) => {
  switch (cardNetwork) {
    case "AMERICAN_EXPRESS":
      return { formattedIssuer: "American Express", imgSrc: AmericanExpress, imgAlt: "American Express" };
    case "MAESTRO_DEBIT":
      return { formattedIssuer: "Maestro", imgSrc: Maestro, imgAlt: "Maestro" };
    case "MASTERCARD":
      return { formattedIssuer: "MasterCard", imgSrc: Mastercard, imgAlt: "Mastercard" };
    case "VISA":
      return { formattedIssuer: "Visa", imgSrc: Visa, imgAlt: "Visa" };
    case "VISA_ELECTRON_DEBIT":
      return { formattedIssuer: "Visa Electron", imgSrc: VisaElectron, imgAlt: "Visa Electron" };
    case "VISA_DELTA_DEBIT":
      return { formattedIssuer: "Visa Debit", imgSrc: Visa, imgAlt: "Visa Debit" };
    default:
      return { formattedIssuer: cardNetwork.replace(/_/g, " ") };
  }
};
