export const getClientIp = async () => {
  let ip = "";
  await fetch("https://www.cloudflare.com/cdn-cgi/trace")
    .then(response => {
      return response.text();
    })
    .then(res => {
      ip = res
        .split(/\r?\n/)
        .filter(item => item.includes("ip="))[0]
        .replace("ip=", "");
    })
    .catch(err => {
      return "";
    });
  return ip;
};
