import { combineReducers } from "redux";
import { DataState } from "../../common/dataState";
import { createDataStateReducer } from "../../common/dataState/dataState.reducer";
import { NFActions } from "./ATBModal.action";
import {
  NorthforkState,
  RecipeATBActions,
  RecipeNFProductState,
  RecipeNFRecipeState,
  RECIPE_ATB_RESERVED_COMMON_NAME,
  RECIPE_ATB_RESERVED_SHARED_NAME,
} from "./ATBModal.type";
import { RecipeProductSet } from "./RecipeProductSet";

enum PRODUCT_AREA {
  RECIPE,
  SHARED,
  COMMON,
}

const status = createDataStateReducer({
  [RecipeATBActions.RECIPE_PRODUCT_REQUEST]: DataState.PENDING,
  [RecipeATBActions.RECIPE_PRODUCT_SUCCESS]: DataState.SUCCESS,
  [RecipeATBActions.RECIPE_PRODUCT_FAILED]: DataState.FAILED,
});

const nfRecipesInitialState: RecipeNFProductState["recipe"] = {};
const nfSharedInitialState: RecipeNFProductState["shared"] = null;
const nfCommonInitialState: RecipeNFProductState["common"] = null;

export const initialState: NorthforkState = {
  dataState: DataState.UNKNOWN,
  products: {
    recipe: nfRecipesInitialState,
    shared: nfSharedInitialState,
    common: nfCommonInitialState,
  },
};

const recipeNFReducer = (
  state: RecipeNFProductState = initialState.products,
  action: NFActions
): RecipeNFProductState => {
  switch (action.type) {
    case RecipeATBActions.RECIPE_PRODUCT_SUCCESS:
      const { recipes, common, shared } = action.payload;
      const recipeState: RecipeNFProductState["recipe"] = {};

      for (let idx = 0; idx < recipes.length; idx++) {
        const recipe = recipes[idx];
        const currentServes = action.serves[idx];
        recipeState[recipe.id] = {
          priority: action.priority,
          serves: currentServes,
          ingredients: RecipeProductSet.fromUnknownArray(recipe?.products),
        };
      }

      const commonState = common?.length ? RecipeProductSet.fromUnknownArray(common) : null;
      const sharedState = shared?.length ? RecipeProductSet.fromUnknownArray(shared) : null;

      return {
        recipe: recipeState,
        common: commonState,
        shared: sharedState,
      };

    case RecipeATBActions.RECIPE_PRODUCT_CLEAR:
      return initialState.products;
    case RecipeATBActions.RECIPE_PRODUCT_FAILED:
      const ids = action.ids;
      const priority = action.priority;
      const serves = action.serves;
      const newRecipes = {} as Record<string, RecipeNFRecipeState>;

      for (let i = 0; i < ids.length; i++) {
        newRecipes[ids[i]] = {
          serves: serves[i],
          priority,
          ingredients: new RecipeProductSet([]),
        };
      }
      return {
        recipe: newRecipes,
        common: null,
        shared: null,
      };
    case RecipeATBActions.RECIPE_PRODUCT_ITEM_UPDATE: {
      const area = checkCommonOrSharedIds(action);
      switch (area) {
        case PRODUCT_AREA.SHARED:
          if (!state.shared) return state;
          return {
            ...state,
            shared: state.shared
              .updateItem(action.productId, action.ingredientId, action.selected, action.quantity)
              .clone(),
          };
        case PRODUCT_AREA.COMMON:
          if (!state.common) return state;
          return {
            ...state,
            common: state.common
              .updateItem(action.productId, action.ingredientId, action.selected, action.quantity)
              .clone(),
          };
        default:
          if (!state.recipe[action.id]) return state;
          return {
            ...state,
            [action.id]: {
              ...state[action.id],
              ingredients: state.recipe[action.id].ingredients
                .updateItem(action.productId, action.ingredientId, action.selected, action.quantity)
                .clone(),
            },
          };
      }
    }
    case RecipeATBActions.RECIPE_PRODUCT_ITEM_SWAP: {
      const area = checkCommonOrSharedIds(action);
      switch (area) {
        case PRODUCT_AREA.SHARED:
          if (!state.shared) return state;
          return {
            ...state,
            shared: state.shared.swapItem(action.swapProduct).clone(),
          };
        case PRODUCT_AREA.COMMON:
          if (!state.common) return state;
          return {
            ...state,
            common: state.common.swapItem(action.swapProduct).clone(),
          };
        default:
          // handle recipe
          if (!action.id) return state;
          return {
            ...state,
            recipe: {
              ...state.recipe,
              [action.id]: {
                ...state[action.id],
                ingredients: state.recipe[action.id].ingredients.swapItem(action.swapProduct).clone(),
              },
            },
          };
      }
    }
    case RecipeATBActions.RECIPE_PRODUCT_REQUEST:
    default:
      return state;
  }
};

function checkCommonOrSharedIds(action: NFActions): PRODUCT_AREA {
  switch (action.id) {
    case RECIPE_ATB_RESERVED_COMMON_NAME:
      return PRODUCT_AREA.COMMON;
    case RECIPE_ATB_RESERVED_SHARED_NAME:
      return PRODUCT_AREA.SHARED;
    default:
      return PRODUCT_AREA.RECIPE;
  }
}

export const northforkReducer = combineReducers({
  dataState: status,
  products: recipeNFReducer,
} as Record<keyof NorthforkState, any>);
