import React, { useCallback, useMemo } from "react";
import { Route as ReactRouterDomRoute, RouteProps as ReactRouterRouteProps } from "react-router-dom";
import { featureFlags } from "../../common/firebase";
import { RouteKeys, routes } from "../../routes";
import { NotFoundErrorPage } from "../Error";

type RouteValues = typeof routes[keyof typeof routes];

export type RouteProps = Omit<ReactRouterRouteProps, "path"> & {
  showFallbackUIWhenDisabled?: boolean;
  path?: RouteValues | RouteValues[];
};

/**
 * Please see docs/DISABLE_PAGES.md
 */

export const Route = ({ showFallbackUIWhenDisabled, ...props }: RouteProps) => {
  const disabledRoutes = useMemo(() => featureFlags.get("disabled_routes"), []);

  const getIsRouteDisabled = useCallback(
    (path: RouteValues) => disabledRoutes?.some((route: RouteKeys) => routes[route] === path),
    [disabledRoutes]
  );

  const isOnePathDisabled = useMemo(() => {
    if (props.path) {
      let pathsAsArr = props.path;

      if (!Array.isArray(props.path)) {
        pathsAsArr = [props.path];
      }

      return (pathsAsArr as RouteValues[])?.some((path: RouteValues) => getIsRouteDisabled(path));
    }

    return false;
  }, [getIsRouteDisabled, props.path]);

  if (props.path === undefined) {
    return <ReactRouterDomRoute {...props} />;
  }

  if (isOnePathDisabled) {
    return (
      <ReactRouterDomRoute
        {...props}
        render={() => (showFallbackUIWhenDisabled ? <NotFoundErrorPage /> : null)}
        component={undefined}
        path={props.path}
      />
    );
  }

  return <ReactRouterDomRoute {...props} path={props.path} />;
};
