import { isNotEmpty } from "../../common/forms";
import { MappedMissionsConsentsApiResponseType, missionsConsentsTypes } from "./types";
import { MissionsConsentsApiResponse } from "./golServicesTypes";

export const createMissionsDefault = (): MappedMissionsConsentsApiResponseType => {
  const output = {
    cpmSelectedOption: {
      value: "",
      error: "Please select your communication preference.",
      validator: isNotEmpty,
    },
  };

  const missionsData = Object.keys(missionsConsentsTypes).map(key => ({
    id: missionsConsentsTypes[`${key}`],
    value: false,
  }));

  return Object.assign(output, convertMissionsToCPMFormConfig(missionsData));
};

export const createMissionsFromResponse = (
  missionsConsents: MissionsConsentsApiResponse
): MappedMissionsConsentsApiResponseType | undefined => {
  let missionsData: object[] = [];
  const cpmExists: Array<Boolean> = [];
  let output;

  if (missionsConsents && Array.isArray(missionsConsents.data)) {
    missionsData = missionsConsents.data.map(consent => {
      cpmExists.push(consent.attributes.consent);
      return {
        id: missionsConsentsTypes[consent.id],
        value: consent.attributes.consent,
      };
    });

    if (cpmExists.indexOf(false) > -1) {
      output = {
        cpmSelectedOption: {
          value: "no",
          error: "Please select your communication preference.",
          validator: isNotEmpty,
        },
      };
    }

    return Object.assign({}, output, convertMissionsToCPMFormConfig(missionsData));
  }

  return undefined;
};

const convertMissionsToCPMFormConfig = (missionsData: object[]): MappedMissionsConsentsApiResponseType => {
  return missionsData!.reduce((acc, { id, value }: { id: string; value: boolean }) => {
    acc[`${id}`] = { value };
    return acc;
  }, {});
};
