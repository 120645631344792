import { Method, request } from "../common/http/request";
import { getAuthCookieValue } from "../utils/cookie";
import { updateWcCookies } from "./cookie.jwt";
import { urls } from "../routes";
import isEmpty from "lodash.isempty";
import { featureFlags } from "../common/firebase/featureFlags";
import createUserManager from "../common/oAuth/oAuth.config";
import { getAccessTokenFromStorage } from "../common/oAuth/oAuth.helpers";

type ServiceRequestParams = {
  method: Method;
  url: string;
  body?: object;
  headers?: object;
  timeout?: number;
};

let isBenchmarkModeOn = false;

export const serviceRequest = async <T>({
  method,
  url,
  body = undefined,
  headers = {},
  timeout = 30000,
}: ServiceRequestParams) => {
  const requestStaringAt = Date.now();
  return request<T>(method, url, body, await getHeaders(headers), undefined, timeout).then(result => {
    if (result.isUnauthorised()) {
      if (url.includes("gol-services/login")) {
        // do not attempt to re-auth any calls to login service
        return result;
      }

      if (url.includes("/v1/checkout/payment-cards/process")) {
        window.location.replace(urls.OAUTH_LOGOUT);
        return result;
      }

      // attempt to update WC session
      return updateWcCookies().then(async isUpdated => {
        if (isUpdated) {
          return request<T>(method, url, body, await getHeaders(headers));
        }
        createUserManager().signinSilent();
        createUserManager()
          .signinSilentCallback()
          .then(async () => {
            // TODO we have to update WC cookies here
            return request<T>(method, url, body, await getHeaders(headers));
          })
          .catch(err => {
            window.location.replace(urls.OAUTH_LOGIN);
          });

        return result;
      });
    }

    if (isBenchmarkModeOn) BenchmarkJson(url, requestStaringAt);

    return result;
  });
};

function BenchmarkJson(url: string, startingTimestamp: number) {
  const benchmarkStg = localStorage.getItem("info-container-json");
  const serviceCalls = benchmarkStg ? JSON.parse(benchmarkStg) : { benchmark: [] };

  const timeStamp = Date.now() - startingTimestamp;
  const baseURL = url.split("?");
  const formatterName = baseURL[0].replace(/\/|&|=|\.|\?/gm, "-");
  const serviceCallsUpdated = {
    benchmark: [
      ...serviceCalls["benchmark"],
      {
        currentPath: window.location.pathname,
        actionName: formatterName,
        time: timeStamp,
      },
    ],
  };

  localStorage.setItem("info-container-json", JSON.stringify(serviceCallsUpdated));
}

export function createFeatureFlagsHeader(flags: { [key: string]: any }) {
  const isEmptyObject = (flag: any) => typeof flag === "object" && Object.keys(flag).length === 0;

  return Object.keys(flags)
    .reduce((acc: string[], curr: string): string[] => {
      if (flags[curr] && !isEmptyObject(flags[curr])) {
        acc.push(curr);
      }
      return acc;
    }, [])
    .join(",");
}

async function getHeaders(headers: object) {
  const result: { [key: string]: string } = {
    WCAuthToken: encodeURIComponent(getAuthCookieValue() || ""),
    ...Object.keys(headers).reduce((acc, name) => {
      acc[`${name}`] = headers[`${name}`];

      return acc;
    }, {}),
  };

  const allFlags = featureFlags.getAll();

  if (allFlags) {
    const features = createFeatureFlagsHeader(allFlags);

    if (!isEmpty(features)) {
      result["Enabled-Feature-Flags"] = features;
      isBenchmarkModeOn = features.indexOf("benchmark_test") > -1;
    }
  }

  try {
    const accessToken = await getAccessTokenFromStorage();
    if (accessToken) {
      result.Authorization = `Bearer ${accessToken}`;
    }
  } catch (e) {}

  return result;
}
