import { Dispatch, MiddlewareAPI } from "redux";
import qs from "qs";
import { State } from "../../common/store";
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { routes, urls } from "../../routes";
import {
  fetchProductDetailsNotFoundError,
  fetchProductDetailsThunkBySeoURL,
  fetchProductDetailsThunkByWCSCatEntryId,
  fetchProductDetailsThunkByProductId,
} from "./productDetails.actions";
import { matchPath } from "react-router-dom";
import {
  DietaryProfileUpdateActionTypes,
  UpdateDietaryProfileStatusSuccessActionType,
} from "../../components/DietaryProfile/DietaryProfile.types";
import { AppThunkDispatch, AppThunkAction } from "../../common/types";
import { Basket } from "../../domain/basket/basket.types";

interface RouteParams {
  seoUrl: string;
}

export const productDetailsMiddleware =
  (api: MiddlewareAPI<Dispatch, State>) =>
  (next: Dispatch) =>
  async (action: LocationChangeAction | UpdateDietaryProfileStatusSuccessActionType) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        handleLocationChange(action, api);
        break;
      case DietaryProfileUpdateActionTypes.DIETARY_PROFILE_UPDATE_SUCCESS:
        refresh(action, api);
        break;
      default:
        break;
    }

    next(action);
  };

enum pdpParam {
  PRODUCT_ID = "productId",
  PRODUCT_UID = "productUid",
}

export function handleLocationChange(action: LocationChangeAction, api: MiddlewareAPI<AppThunkDispatch, State>) {
  const match = matchPath<RouteParams>(action.payload.location.pathname, {
    path: routes.PRODUCT_DETAILS,
    exact: true,
  });

  if (!match) {
    return;
  }

  const urlPath = match.params[1];
  const searchParams = action.payload.location.search;
  const productSearchParam = getProductSearchParam(urlPath, searchParams);

  if (productSearchParam) {
    api.dispatch(setActionBySearchParam(productSearchParam, searchParams, api.getState().basket.basketDetails));
  } else if (urlPath) {
    // React router breaks with % encoded in the URL. Here we use window.location as a workaround
    // https://github.com/ReactTraining/react-router/issues/5816
    const path = window.location.pathname
      .replace(`${urls.PRODUCT_DETAILS}/`, "")
      .replace(`${urls.PRODUCT_DETAILS.toLowerCase()}/`, "")
      // React Router is decoding the URL on navigation, but WCS is not, this is an attempt to not double decode.
      .replace(`%-`, "%25-");
    api.dispatch(
      fetchProductDetailsThunkBySeoURL(decodeURIComponent(path), searchParams, api.getState().basket.basketDetails)
    );
  } else {
    api.dispatch(fetchProductDetailsNotFoundError());
  }
}

function refresh(action: UpdateDietaryProfileStatusSuccessActionType, api: MiddlewareAPI<AppThunkDispatch, State>) {
  const url = api.getState().router.location.pathname;

  const match = matchPath<RouteParams>(url, {
    path: routes.PRODUCT_DETAILS,
    exact: true,
  });

  if (!match) {
    return;
  }

  const seoUrl = getSeoUrl(url);
  const searchParams = api.getState().router.location.search;
  const productSearchParam = getProductSearchParam(seoUrl, searchParams);

  if (productSearchParam) {
    api.dispatch(setActionBySearchParam(productSearchParam, searchParams, api.getState().basket.basketDetails));
  } else if (seoUrl) {
    // React router breaks with % encoded in the URL. Here we use window.location as a workaround
    // https://github.com/ReactTraining/react-router/issues/5816

    const path = seoUrl.replace(`${urls.PRODUCT_DETAILS}/`, "").replace(`${urls.PRODUCT_DETAILS.toLowerCase()}/`, "");
    api.dispatch(
      fetchProductDetailsThunkBySeoURL(decodeURIComponent(path), searchParams, api.getState().basket.basketDetails)
    );
  } else {
    api.dispatch(fetchProductDetailsNotFoundError());
  }
}

const setActionBySearchParam = (
  searchParam: { name: string; value: string },
  searchParams: string,
  basket: Basket
): AppThunkAction<void> => {
  return searchParam.name === pdpParam.PRODUCT_ID
    ? fetchProductDetailsThunkByWCSCatEntryId(searchParam.value, searchParams, basket)
    : fetchProductDetailsThunkByProductId(searchParam.value, searchParams, basket);
};

const getProductSearchParam = (url: string, searchParams: string): { name: string; value: string } | null => {
  if (url !== "ProductDisplay") {
    return null;
  }

  const currentQueryParams = qs.parse(searchParams, { ignoreQueryPrefix: true });

  for (const param in pdpParam) {
    if (currentQueryParams[pdpParam[`${param}`]]) {
      return { name: pdpParam[`${param}`], value: currentQueryParams[pdpParam[`${param}`]] };
    }
  }

  return null;
};

function getSeoUrl(url: string): string {
  const productSeoURLPath = 3;

  return url.split("/").splice(productSeoURLPath).join("/");
}
