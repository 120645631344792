import { ContentBannerLocation, ContentPlacement, TemplateArea } from "../../services/contentTypes";
import { DataState } from "../../common/dataState";
import { SearchProducts } from "../SearchResultsFindability/SearchResultsFindability.types";
import { FetchEventProductsArgs, RequestFilters, SearchResponse } from "../../services/product.types";
import { IngridAd } from "../../components/IngridAd/IngridAd.type";
import { UseProductsReturnType } from "../../common/hooks/useProducts";

export enum EventsFeaturesActionType {
  PRODUCTS_REQUEST = "EVENTS_BUILDER_PRODUCT_REQUEST",
  PRODUCTS_SUCCESS = "EVENTS_BUILDER_PRODUCT_SUCCESS",
  CONTENT_SUCCESS = "EVENTS_BUILDER_CONTENT_SUCCESS",
  CMS_DATA_SUCCESS = "EVENTS_BUILDER_CMS_DATA_SUCCESS",
  PRODUCTS_FAILURE = "EVENTS_BUILDER_PRODUCT_FAILURE",
  SEARCH_TERM_SUCCESS = "EVENTS_BUILDER_SEARCH_TERM_SUCCESS",
}

export enum PageType {
  EVENTS = "events",
  FEATURES = "features",
}

export enum PageLink {
  EVENT = "/gol-ui/events",
  FEATURES = "/gol-ui/features",
}

export interface EventsAndFeaturesHookReturn {
  landingURL: string;
  facetSlug: string | null;
  requestFilters: RequestFilters | undefined;
  sort: string;
  page: string;
}

export interface RequestProps {
  template_id: string;
  storeId?: string;
  delivery_date?: string;
  region?: string;
  has_nectar_associated?: boolean;
  is_digital_customer?: boolean;
  has_delivery_pass?: boolean;
  is_very_important_customer?: boolean;
  espotIds: string[];
  placement?: ContentPlacement;
  category_ids?: string[];
  slotId?: ContentBannerLocation;
}

export interface ResponseData {
  aboveGrid?: TemplateArea;
  belowGrid?: TemplateArea;
  seo?: TemplateArea;
  meta?: Meta | null;
  aboveGridSecondary?: TemplateArea;
}

export interface BrowseIngridAdState {
  ads: IngridAd[];
}

export type EventsDataState = ResponseData;

export interface BrowseMagnoliaResponse extends ResponseData, BrowseIngridAdState {}

export interface Meta {
  description: string;
  searchTerm?: string;
  title: string;
  header: Header;
  backgroundColor?: string;
  theme?: string;
  pageHeader: string;
  pageIntroCopy: string;
  canonicalTag: string;
  sponsoredProductsCount?: string;
}

export interface Header {
  theme: string;
  backgroundColor?: string;
  backgroundImage?: BackgroundImage;
  pills?: PillItem[];
}

export interface BackgroundImage {
  url: string;
  altText: string;
}

export interface PillItem {
  url: string;
  searchTerm?: string;
  target: string;
  title: string;
  copy: string;
  isSelected?: boolean;
}

export interface EventsFeaturesState {
  dataState: DataState;
  searchTerm: string;
  cmsData: ResponseData | undefined;
  ads: IngridAd[];
}

export interface EventsFeaturesResponseData {
  meta: {
    description: string;
    searchTerm: string;
    title: string;
  };
  data?: TemplateArea;
}

export interface EventsAndFeaturesListPageProps {
  path: string;
  requestArgs: FetchEventProductsArgs;
}

export interface EventsAndFeaturesPageProps {
  path: string;
  eventId: string;
  isOccasionsRoute: boolean;
}

export const eventsFeaturesInitialState: EventsFeaturesState = {
  dataState: DataState.UNKNOWN,
  searchTerm: "",
  ads: [],
  cmsData: undefined,
};

export type EventsFetchResultData = SearchResponse;

export interface CreateEventsFeaturesBuilderRequestActionType {
  type: EventsFeaturesActionType.PRODUCTS_REQUEST;
  products: SearchProducts;
}

export interface CreateEventsFeaturesBuilderSuccessActionType {
  type: EventsFeaturesActionType.PRODUCTS_SUCCESS;
  products: SearchProducts;
}

export interface CreateEventsFeaturesBuilderDataActionType {
  type: EventsFeaturesActionType.CONTENT_SUCCESS;
  ads: IngridAd[];
}

export interface CreateEventsFeaturesBuilderCMSDataActionType {
  type: EventsFeaturesActionType.CMS_DATA_SUCCESS;
  cmsData: ResponseData;
}

export interface CreateEventsFeaturesBuilderFailureActionType {
  type: EventsFeaturesActionType.PRODUCTS_FAILURE;
}

export interface CreateEventsFeaturesSearchTermActionType {
  type: EventsFeaturesActionType.SEARCH_TERM_SUCCESS;
  searchTerm: string;
}

export interface HeaderPillsNavProps {
  pageType: string;
  pills: PillItem[];
  eventId: string;
  theme?: string;
}

export interface EventFeaturesHeaderPillsProps {
  className?: string;
  pillItems: PillItem[];
  pageType: string;
  eventId: string;
  theme?: string;
}

export interface EventsFeaturesCarouselProps {
  addProductForViewportAnalytics?: UseProductsReturnType["addProductForViewportAnalytics"];
  scrollCallback?: VoidFunction;
  carouselFilters: string[];
  zonePath: string;
  carouselKey: string;
  productSource: string;
  title?: string;
}

export interface EventsFeaturesMetaTagsType {
  filters: any;
  canonicalPath: string;
  seoData: any;
}
