import { State } from "../../common/store";
import { BasketMultiErrorContent, ModalNames, SlotDetails } from "./Modal.types";
import { urls } from "../../routes";
import { ScottishLegislationRestrictionError } from "../../services/basket.types";
import { formatDeliveryDate } from "../../views/MyOrders/myOrders.selectors";
import { SlotReservation, SlotReservationType } from "../../domain/slot/slotReservation.types";

export const selectShouldOpenFavouritesDeleteErrorModal = (state: State): boolean =>
  Boolean(state.modal.meta && state.modal.meta.name === ModalNames.MODAL_REMOVE_FROM_FAVOURITES_ERROR);

export const selectShouldOpenFavouritesSaveErrorModal = (state: State): boolean =>
  Boolean(state.modal.meta && state.modal.meta.name === ModalNames.MODAL_SAVE_FAVOURITE_ERROR);

export const selectShouldOpenGenericErrorModal = (state: State): boolean =>
  Boolean(state.modal.meta && state.modal.meta.name === ModalNames.MODAL_GENERIC_ERROR);

export const selectAddOrderToBasketErrors = (state: State): BasketMultiErrorContent[] | undefined => {
  const errors = state.basket?.errors?.addOrderToBasketErrors;
  if (errors === undefined) {
    return undefined;
  }
  return errors.map(error => {
    let secondaryButtonText, secondaryButtonUrl;
    const infoUrl = error.infoUrl;

    if (infoUrl) {
      secondaryButtonText = "Find out more";
      secondaryButtonUrl = infoUrl;
    }
    if (error.code === ScottishLegislationRestrictionError) {
      secondaryButtonText = "Change slot";
      secondaryButtonUrl = urls.BOOK_DELIVERY_SLOT; // Can this be changed to CYSM?
    }

    return { detail: error.detail, productName: error.productName, secondaryButtonText, secondaryButtonUrl };
  });
};

export const selectOriginalSlotDetails = (originalSlotReservation: SlotReservation | null): SlotDetails | undefined => {
  if (originalSlotReservation) {
    const date = formatDeliveryDate(
      originalSlotReservation.reservationStartTime,
      originalSlotReservation.reservationEndTime,
      true,
      false
    );
    const address = createAddress(originalSlotReservation);
    return {
      time: date.slotDate,
      address,
      orderType: originalSlotReservation?.reservationType === "CLICK_AND_COLLECT" ? "click_and_collect" : "delivery",
    };
  }
};

export const createAddress = (originalSlotReservation: SlotReservation): string => {
  if (originalSlotReservation.reservationType === SlotReservationType.DELIVERY) {
    return `${originalSlotReservation.deliveryAddress?.nickname}, ${originalSlotReservation.deliveryAddress?.postcode}`;
  }

  if (originalSlotReservation.reservationType === SlotReservationType.CLICK_AND_COLLECT) {
    return `${originalSlotReservation.reservationAddressNickname}, ${originalSlotReservation.postcode}`;
  }

  return "This address no longer exists in your address book";
};
