import { RecipesBrowseState } from "../../common/store";
import { ActionTypes, RecipeHierarchyAction } from "./RecipesBrowse.actions";
import { Action } from "redux";
import { DataState } from "../../common/dataState";

export const recipesBrowseInitialState: RecipesBrowseState = {
  dataState: DataState.UNKNOWN,
  hierarchy: [],
};

export default function recipesBrowseReducer(
  state: RecipesBrowseState = recipesBrowseInitialState,
  action: RecipeHierarchyAction | Action
): RecipesBrowseState {
  switch (action.type) {
    case ActionTypes.RECIPES_BROWSE_FETCH_SUCCESS:
      const successResp = action as RecipeHierarchyAction;
      return { ...state, dataState: DataState.SUCCESS, hierarchy: successResp.payload };
    case ActionTypes.RECIPES_BROWSE_FETCH_FAILURE:
      const failureResp = action as RecipeHierarchyAction;
      return { ...state, dataState: DataState.FAILED, hierarchy: failureResp.payload };
    default:
      return state;
  }
}
