import React from "react";
import { Favourites } from "@jsluna/icons";
import { Device, FeatureTourConfig, TourJourneyTypes, TourPageTypes } from "@favourites/FeatureTour.types";

export const tourConfig: FeatureTourConfig = {
  pages: {
    [TourPageTypes.FAVOURITES]: {
      [TourJourneyTypes.NORMAL]: {},
      [TourJourneyTypes.ENHANCED]: {
        [Device.MOBILE]: [
          {
            header: "Add items to your favourites now",
            text: (
              <>
                Use the heart icon <Favourites /> when you’re browsing to save items to your favourites.
              </>
            ),
            position: "bottom-start",
            tileIndex: 0,
            nextCTA: "Next",
          },
          {
            header: "Choose how to view your Favourites",
            text: "Sort your favourites by regularly bought items or by items you’ve manually added to your Favourites.",
            position: "bottom",
            nextCTA: "Got it",
          },
        ],
        [Device.DESKTOP]: [
          {
            header: "You can now add items to your favourites",
            text: (
              <>
                Use the heart icon <Favourites /> when you’re browsing to add or remove any items from your favourites.
              </>
            ),
            position: "top",
            tileIndex: 0,
            nextCTA: "Next",
            isLargeTooltip: true,
          },
          {
            header: "Choose how to view your Favourites",
            text: "Sort by regularly bought items or by items you’ve manually saved to your Favourites.",
            position: "left-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
            scrollToElement: ".product-controls__sort-dropdown",
          },
        ],
      },
    },
    [TourPageTypes.SEARCH_RESULTS_FINDABILITY]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            nextCTA: "Next",
            tileIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.SEARCH_RESULTS_FINDABILITY_WITH_CATEGORY]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            nextCTA: "Next",
            tileIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.OFFERS]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "left-start",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "left-start",
            nextCTA: "Next",
            tileIndex: 1,
            scrollToElement: ".carousel-header__title",
            scrollToElementIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.OFFERS_ZONE_PAGE]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top",
            nextCTA: "Next",
            tileIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.NECTAR_DESTINATION]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "left-start",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "left-start",
            nextCTA: "Next",
            tileIndex: 1,
            scrollToElement: ".carousel-header__title",
            scrollToElementIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.PRODUCT_DETAILS]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "right-start",
            tileIndex: 0,
          },
        ],
      },
    },
    [TourPageTypes.BROWSE]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            nextCTA: "Next",
            tileIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.BROWSE_FEATURED]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            nextCTA: "Next",
            tileIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.BROWSE_ZONE]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "left-start",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "left-start",
            nextCTA: "Next",
            tileIndex: 1,
            scrollToElement: ".fav-tooltip_content-container",
            scrollToElementIndex: 0,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.BROWSE_DEPARTMENT]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            nextCTA: "Next",
            tileIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.BROWSE_AISLE]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            nextCTA: "Next",
            tileIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
    [TourPageTypes.BROWSE_SHELF]: {
      [TourJourneyTypes.NORMAL]: {
        [Device.MOBILE]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "top-start",
            tileIndex: 0,
          },
        ],
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            tileIndex: 1,
          },
        ],
      },
      [TourJourneyTypes.ENHANCED]: {
        [Device.DESKTOP]: [
          {
            text: <b>Try using the heart icon to add items to your Favourites.</b>,
            position: "bottom",
            nextCTA: "Next",
            tileIndex: 1,
          },
          {
            text: <b>You can find your saved items in Favourites, where you can quickly add them to your trolley.</b>,
            position: "bottom-start",
            nextCTA: "Got it",
            isLargeTooltip: true,
          },
        ],
      },
    },
  },
};
