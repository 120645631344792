import React from "react";
import { connect } from "react-redux";
import { NavigationActionTypes, InternalNavigationAction } from "../../common/middleware/navigationMiddleware";

export class RedirectInternalComponent extends React.Component<{
  createInternalNavigation: (to: string) => InternalNavigationAction;
  to: string;
}> {
  componentDidMount() {
    this.props.createInternalNavigation(this.props.to);
  }

  render() {
    return null;
  }
}

const createInternalNavigation = (path: string) => ({
  type: NavigationActionTypes.INTERNAL_NAVIGATION,
  path,
});

export const RedirectInternal = connect(null, {
  createInternalNavigation,
})(RedirectInternalComponent);
