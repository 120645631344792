import { Helmet } from "react-helmet";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ContentWrap } from "./components/ContentWrap";
import { routes } from "./routes";

export const HelmetRouter = () => (
  <ContentWrap>
    <Suspense fallback={null}>
      <Switch>
        <Route exact path={routes.TROLLEY} render={() => <Helmet defaultTitle="Trolley | Sainsbury's" />} />
        <Route render={() => <Helmet defaultTitle="Sainsbury's online Grocery Shopping and Fresh Food Delivery" />} />
      </Switch>
    </Suspense>
  </ContentWrap>
);

HelmetRouter.displayName = "HelmetRouter";
