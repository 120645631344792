import { FavouriteSourceAnalyticsType } from "@favourites/Favourites.types";
import { Nullable, Optional } from "../types";
import { DigitalDataProduct } from "../../views/Checkout/checkout";
import { BasketPromotion } from "../../domain/basket/basket.types";

export type Product = {
  sku: Nullable<string>;
  parentSku: Nullable<string>;
  name: Nullable<string>;
  finalPrice: Nullable<number>;
  priceType: Nullable<string>;
  isSpotlight: Nullable<boolean>;
  isOnOffer: Nullable<boolean>;
  offerPromoId: Nullable<string>;
  offerDateStartTimeStamp: Nullable<string>;
  offerDateEndTimeStamp: Nullable<string>;
  isUnitAvailable: Nullable<boolean>;
  isWeightAvailable: Nullable<boolean>;
  isWeightRangeAvailable: Nullable<boolean>;
  isMultiVariantAvailable: Nullable<boolean>;
  imageURL: Nullable<string>;
  list: Optional<string>;
  position: Nullable<number>;
  isUserFavorite: Nullable<boolean>;
  favouriteSource: Optional<FavouriteSourceAnalyticsType>;
  pfmCategory: PFMCategory | string;
  adId: Nullable<string>;
  adType: Nullable<string>;
  nectar_offer: Nullable<boolean>;
  parent_sku?: string;
  isInStock?: boolean;
  reviewScore?: Nullable<number>;
  reviewsNumber?: Nullable<number>;
  offerType?: Nullable<string>;
  nectarPoints?: Nullable<number>;
};

export type Customer = {
  title: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: Nullable<string>;
  nectarCard: Nullable<string>;
  marketingPreference: Nullable<string>;
};

export interface BasketProduct extends Product {
  quantityType: Nullable<string>;
  quantityValue: Nullable<number>;
  originalUnitPrice?: Nullable<number>;
  unitPrice?: Nullable<number>;
  unitPriceSaving?: Nullable<number>;
  favourite?: Nullable<boolean>;
  fulfilmentState?: Nullable<FulfilmentState>;
  promotions?: Nullable<BasketPromotion[]>;
}

export type Price = {
  finalPrice: number;
  priceType: string;
};

export type Attributes = {
  seasonalProductCount?: number;
  reviewScore?: number;
  reviewsNumber?: number;
};

export type AnalyticsEvent = {
  eventName?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  product?: Product;
  product_id?: string[];
  customer?: Customer;
  slotTime?: string;
  attributes?: Attributes;
  search_term?: string;
  stockout_count?: string;
  non_interaction?: boolean;
  data_transaction_id?: string;
  data_order_id?: string;
  data_product_id?: string[];
  data_product_alternativeId?: string[];
  data_product_quantity?: number[];
  data_product_unitPrice?: number[];
  data_product_price?: number[];
  data_event_name?: string;
  isSeeAlternativesButtonAvailable?: string;
  linkParentHeader?: string;
  linkDestination?: string;
  data_fulfilmentMethod?: string;
  hit_type?: string;
  data_event_action?: string;
  data_event_category?: string;
  data_event_label?: string;
  data_component_classification?: string;
  data_error_type?: string;
  data_error_message?: string;
};

export type ExperimentVariantEvent = {
  name: string;
  variant: string;
};

export type PageDescription = string;
export const FavouritesPageDescription: PageDescription = "favourites as a single list";
export const SeasonalFavouritesDescription: PageDescription = "seasonal favourites as a single list";
export const PreviousOrdersPageDescription: PageDescription = "previous orders";
export const NotApplicablePageDescription: PageDescription = "";
export const SearchPageDescription: PageDescription = "search:search results";
export const BrandPageDescription: PageDescription = "brand results";
export const AddAllToTrolleyDescription: PageDescription = "previous orders: add all to trolley";
export const TrolleyPageDescription: PageDescription = "trolley summary";
export const listerPagePageDescription: PageDescription = "product lister page";
export const BeforeYouGoPageDescription: PageDescription = "before you go upsell products";
export const OffersPageDescription: PageDescription = "offers carousel";
export const OffersZonePageDescription: PageDescription = "offers zone page";
export const NectarPageDescription: PageDescription = "Nectar";
export const CheckoutSummaryPageDescription: PageDescription = "checkout";
export const EventsPageDescription: PageDescription = "event page results";
export const FeaturesPageDescription: PageDescription = "features page results";
export const HomePageDescription: PageDescription = "home page results";
export const MealDealPageDescription: PageDescription = "meal deal builder page";

export enum PFMCategory {
  HIERARCHY = "hierarchy",
  BRANDEDSHELF = "brands",
  MINI_TROLLEY = "mini trolley",
  TROLLEY = "full trolley",
  FAVOURITES = "favourites",
  SEASONAL_FAVOURITES = "seasonal_favourites",
  PREVIOUS_ORDERS = "favourites",
  KRANG = "krang",
  CROSS_SELL = "crossSell",
  APE = "alternatives_favourites",
  APE_OVERRIDE = "alternatives_favourites",
  PRODUCT_DETAILS = "productDetails",
  SEARCH_RESULTS = "search",
  SPOTLIGHT = "spotlight",
  HOOKLOGIC = "hookLogic",
  CITRUS_SEARCH_ADS_BELOW_GRID = "citrus_search_ads_below_grid",
  CITRUS_SEARCH_ADS_IN_GRID = "citrus_search_ads_in_grid",
  CITRUS_XSELL_AD_PDP = "citrus_xsell_ad_pdp",
  CITRUS_XSELL_AD_FAVOURITES = "citrus_xsell_ad_favourites",
  CITRUS_CHECKOUT_UPSELL = "citrus_checkout_upsell",
  CITRUS_OFFERS = "citrus_offers",
  CHECKOUT_UPSELL = "productUpsell",
  CHECKOUT_FAVOURITES = "forgottenFavourites",
  FIRST_FAVOURITE = "first_favourite",
  PRODUCT_LISTER = "productLister",
  ZONE_OFFERS = "zone_offers",
  GREAT_OFFERS = "great_offers",
  CHECKOUT = "checkout_review",
  NECTAR_DESTINATION = "Nectar",
  EVENTS = "Events",
  FEATURES = "Features",
  HOME_PAGE = "Homepage",
  MEAL_DEAL_BUILDER = "Meal deal builder",
  BROWSE_ZONE = "Zone",
  FREQUENTLY_BOUGHT_TOGETHER = "Frequently bought together",
}

export type UnitPriceAndSaving = {
  unitPrice: number;
  originalUnitPrice: number;
  unitPriceSaving: number;
};

export enum QuantityType {
  UNITS = "units",
  WEIGHT = "weight",
  WEIGHTRANGE = "weight range",
}

export type SearchAnalyticsType = {
  onsiteSearchResults: string;
  onsiteSearchTerm: string;
  originalSearchTerm: string;
};

export interface ExistingOrder {
  item: DigitalDataProduct[];
  total: {
    totalQuantity: number;
    transactionCost: number;
  };
}

export type Basket = {
  totalNectarItems: number;
  totalNectarValue: number;
  totalItems: number;
  totalUniqueItems?: number;
  totalValue: number;
  products: Product[];
  orderId: string;
};

export enum AnalyticsEventType {
  ADD_TO_BASKET = "addToBasket",
  BASKET_UPDATED = "BasketUpdated",
  REMOVE_FROM_BASKET = "RemoveFromBasket",
  PRODUCT_CLICKED = "productClick",
  PRODUCT_IMPRESSION = "productImpression",
  AMEND_ORDER_CLICKED = "amendOrderClick",
  SEE_ORDERS_CLICKED = "seeOrdersClick",
  SEARCH_FEEDBACK = "searchFeedback",
  FILTER_CHANGES = "filterChanges",
  SEARCH_SORTBY_CHANGE = "sortBySelection",
  RESERVE_SLOT_MODAL_OPEN = "reserveSlotModalOpen",
  RESERVE_SLOT_CNC_MODAL_OPEN = "reserveSlotCncModalOpen",
}

export enum RecipePageIds {
  RECIPE = "recipe",
  ARTICLE = "article",
  SEARCH_RESULTS = "searchResults",
}

export enum SortByType {
  FAVOURITES_FIRST = "Relevance",
  PRICE_ASC = "Price - Low to High",
  PRICE_DESC = "Price - High to Low",
  NAME_ASC = "Product Name - A to Z",
  NAME_DESC = "Product Name - Z to A",
  TOP_SELLERS = "Top Sellers",
  RATINGS_DESC = "Ratings - High to Low",
}

export enum FavouritesSortByType {
  MANUALLY = "MANUALLY",
  FAVOURITES_RANK = "FAVOURITES_RANK",
  AISLE = "AISLE",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
}

export enum FindabilitySortByType {
  "-relevance" = "Relevance",
  "price" = "Price - Low to High",
  "-price" = "Price - High to Low",
  "-customer rating" = "Ratings - High to Low",
}

export enum BrandedSortByType {
  "-relevance" = "Relevance",
  "price_asc" = "Price - Low to High",
  "price_desc" = "Price - High to Low",
  "rating" = "Ratings - High to Low",
}

export enum OfferType {
  ORIGINAL_PRICE = "original unit price",
  TYPICAL_PRICE_REDUCTION = "typical price reduction",
  NECTAR_PRICE = "nectar price",
  YOUR_NECTAR_PRICE = "your nectar price",
  PROMO_PRICE = "promo price",
}

export enum AddToCartOrigin {
  FAVOURITES_SEASONAL = "Seasonal Favourites",
  CITRUS_SEARCH_BELOW_GRID = "Citrus Search Below Grid",
  CITRUS_XSELL_PDP = "Cross Sell",
  NECTAR_TOP = "Top Nectar Prices Carousel",
  NECTAR_FAVOURITES = "Nectar Favourites Carousel",
  NECTAR_BONUS = "Nectar Bonus Points Carousel",
  NECTAR_ADD_ALL_BONUS = "Nectar Add All Bonus Points",
  NECTAR_ZONAL_YNP_CAROUSEL = "Offers: Nectar Zonal: YNP Carousel",
  OFFERS_FAVOURITES = "Offers Favourites Carousel",
  OFFERS_FINDABILITY = "Findability Carousel",
  OFFERS_TOP = "Top Offers Carousel",
  OFFERS_CITRUS = "Citrus Offers Carousel",
  OFFERS_YNP_CAROUSEL = "Offers: Your Nectar Prices Carousel",
  KRANG = "Recommendations",
  TROLLEY_PAGE = "Trolley Page",
  CHANGES_TO_TROLLEY = "Changes to your trolley",
  TROLLEY_MINI = "Mini Trolley",
  TROLLEY_SIDE_BAR = "Trolley Side Bar",
  SEARCH_REDIRECT = "Search Redirect",
  HIERARCHY = "Hierarchy",
  SIMILAR_PRODUCTS = "Cross Sell:Product Alternatives",
  EVENTS = "Events",
  FEATURES = "Features",
  MEAL_DEAL = "Meal Deal",
  HOME_PAGE = "Homepage",
  ZONE_PAGE = "Browse zone",
  FREQUENTLY_BOUGHT_TOGETHER = "Frequently bought together",
  FREQUENTLY_BOUGHT_TOGETHER_CAROUSEL = "Search Results: Frequently Bought Together Carousel",
  OUT_OF_STOCK = "Out of Stock Alternatives",
  VIEW_SIMILAR = "View Similar",
}

export enum FulfilmentState {
  ALTERNATIVE_PRODUCT = "alternative-product",
  NO_LONGER_ON_OFFER = "no-longer-on-offer",
}
