import React, { ErrorInfo, ReactNode } from "react";
import { UnrecoverableErrorPage } from "../../Error/UnrecoverableErrorPage";
import { ErrorReporter, useReportError as getErrorReporter } from "../../Error/reportErrorHook";

interface UnrecoverableErrorBoundaryProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class UnrecoverableErrorBoundary extends React.Component<UnrecoverableErrorBoundaryProps, State> {
  errorReporter: ErrorReporter;

  constructor(props: UnrecoverableErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.errorReporter = getErrorReporter();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info.componentStack);

    this.errorReporter(error, { shownErrorBoundary: true });
  }

  render() {
    if (this.state.hasError) {
      return <UnrecoverableErrorPage />;
    }

    return this.props.children;
  }
}

export { UnrecoverableErrorBoundary };
