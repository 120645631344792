import { useEffect } from "react";
import { trackCMSComponentAnalytics } from "../../../common/dataLayer/cmsAnalyticsEvent";

/**
 * This hook is used to track CMS component load and intersect events.
 * It adds an observer to track impressions when components come into view.
 * This observer is disconnected and unloaded from the DOM when the component is unmounted.
 *
 * @param condition - Optional condition for if the analytics should be fired.
 */
export const useTrackCMSComponentAnalytics = (condition = true) => {
  useEffect(() => {
    if (condition) {
      const disconnectObserver = trackCMSComponentAnalytics();

      return () => disconnectObserver();
    }
  }, [condition]);
};
