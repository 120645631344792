import { PropsWithChildren, ReactElement } from "react";
import { ExtendedFlags } from "./featureFlags";
import { useFeatureFlag as getFeatureFlag, useFeatureFlagObject as getFeatureFlagObject } from "./featureFlags.hooks";

export interface Props<K extends keyof ExtendedFlags<K> | string> {
  flag: K;
}

const isStringUsingDotNotation = (flag: string): boolean => typeof flag === "string" && flag.split(".").length > 1;

/**
 * If the provided feature flag is truthy, show the provided children.
 * If the provided feature flag is falsy, don't show the provided children.
 * @param {string} flag - The key string for the firebase feature flag, can also accept a dot notated string.
 * @param {ReactElement} children - The UI to show if the feature flag is on.
 *
 * @example
 * <FeatureEnabled flag={"feature"}>
 *   <Component />
 * </FeatureEnabled>
 *
 * @example
 * <FeatureEnabled flag={"feature.mobile.banner"}>
 *   <Component />
 * </FeatureEnabled>
 */
export function FeatureEnabled<K extends keyof ExtendedFlags<K> | "add_to_favourites.banner">({
  flag,
  children,
}: PropsWithChildren<Props<K>>) {
  if (isStringUsingDotNotation(flag)) {
    return Boolean(getFeatureFlagObject(flag as string)) ? (children as ReactElement) : null;
  }

  return Boolean(getFeatureFlag(flag as keyof ExtendedFlags<K>)) ? (children as ReactElement) : null;
}

/**
 * If the provided feature flag is truthy, don't show the provided children.
 * If the provided feature flag is falsy, show the provided children.
 * @param {string} flag - The key string for the firebase feature flag, can also accept a dot notated string.
 * @param {ReactElement} children - The UI to show if the feature flag is off.
 *
 * @example
 * <FeatureDisabled flag={"feature"}>
 *   <Component />
 * </FeatureDisabled>
 *
 * @example
 * <FeatureDisabled flag={"feature.mobile.banner"}>
 *   <Component />
 * </FeatureDisabled>
 */
export function FeatureDisabled<K extends keyof ExtendedFlags<K> | string>({
  flag,
  children,
}: PropsWithChildren<Props<K>>) {
  if (isStringUsingDotNotation(flag)) {
    return Boolean(getFeatureFlagObject(flag as string)) ? null : (children as ReactElement);
  }

  return Boolean(getFeatureFlag(flag as keyof ExtendedFlags<K>)) ? null : (children as ReactElement);
}
