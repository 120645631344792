import { State } from "../../common/store";
import { IngridAd } from "./IngridAd.type";
import { ESPOT_IDS } from "../Citrus/MultiAd/MultiAd.utils";

export const selectSearchAd =
  (placement: string) =>
  (state: State): IngridAd | undefined =>
    state.searchAd.ads.find(ad => ad.placement === placement);

export const selectSearchAdLength =
  () =>
  (state: State): number =>
    state.searchAd.ads.filter(ad => {
      return ESPOT_IDS.Search_in_grid_1.includes(ad.placement);
    }).length;

export const selectBrowseAd =
  (placement: string) =>
  (state: State): IngridAd | undefined =>
    state.browseAd.ads.find(ad => ad.placement === placement);

export const selectBrowseAdLength =
  () =>
  (state: State): number =>
    state.browseAd.ads.filter(ad => {
      return ESPOT_IDS.Shelf_in_grid_1.includes(ad.placement);
    }).length;

export const selectEventAd =
  (placement: string) =>
  (state: State): IngridAd | undefined =>
    state.eventsFeatures.ads.find(ad => ad.placement === placement);

export const selectFavouritesAd =
  (placement: string) =>
  (state: State): IngridAd | undefined =>
    state.favourites.ads.find(ad => ad.placement === placement);

export const selectFavouritesAdLength =
  () =>
  (state: State): number =>
    state.favourites.ads.length;
