import { getQueryParameter } from "../url";
import { LocationState } from "../location/locationReducer";
import { routes } from "../../routes";

export const isSimplifiedView = ({ location }: { location: LocationState }) => {
  const hasSimplifiedParam =
    getQueryParameter(location.current, "simplified") === "1" ||
    getQueryParameter(location.current, "internal_source") === "golapp";

  return hasSimplifiedParam;
};

export const shouldHideFooter = (): boolean => !!window.location.href.match(routes.OAUTH);
