/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { OutlinedButton } from "@jsluna/react";
import { ClickAndCollect, Delivery } from "@jsluna/icons";
import { BookDeliveryComponentProps, BookDeliveryContainerProps } from "./BookDelivery.types";
import { EnsureNonGenericUser } from "../../Authorized";
import { setDataLayerSlotsBooked } from "../../../common/dataLayer/slots";
import { featureFlags } from "../../../common/firebase";
import { useResponsive } from "../../ResponsiveProvider";

export const BookDeliveryResponsiveWrapper = (props: BookDeliveryContainerProps) => {
  const { isTablet, isMobile } = useResponsive();
  return (
    <EnsureNonGenericUser>
      <BookDeliveryComponent isMobile={isMobile} isTablet={isTablet} {...props} />
    </EnsureNonGenericUser>
  );
};

export const BookDeliveryComponent = (props: BookDeliveryComponentProps) => {
  const {
    isMobile,
    isTablet,
    deliveryTime,
    deliveryDate,
    deliveryIsExpired,
    isLoaded,
    isFailed,
    isPending,
    isNoBooking,
    isGreen,
    bookSlot,
    chooseDeliveryMethod,
    isDelivery,
  } = props;

  useEffect(() => {
    const hasValidSlotBooked = !isNoBooking && !deliveryIsExpired;
    setDataLayerSlotsBooked(hasValidSlotBooked);
  }, [isNoBooking, deliveryIsExpired]);

  const isNewGlobalHeader = featureFlags.get("new_global_header");
  const desktopClassName = isNewGlobalHeader ? "book-delivery-new-global-header" : "book-delivery";
  const nonDesktopClassName = isMobile ? "book-delivery--mobile" : isTablet ? "book-delivery--tablet" : "";

  const isInMinimizedHeader = document.querySelector(".header-minimized.visible") !== null;
  const className = `${desktopClassName} ${nonDesktopClassName} ${
    isInMinimizedHeader ? "book-delivery--minimized" : ""
  }`;

  return (
    <div className={className} data-testid="book-delivery">
      {(((deliveryIsExpired || isFailed) && !isPending) || isNoBooking) && (
        <OutlinedButton
          className="book-delivery__button"
          onClick={chooseDeliveryMethod}
          fullWidth={isMobile ?? isTablet}
          data-testid="book-delivery-button"
        >
          Book a slot
        </OutlinedButton>
      )}
      {(isPending || isLoaded) && !deliveryIsExpired && (
        <a onClick={bookSlot}>
          {isLoaded &&
            (isDelivery ? (
              <Delivery
                className={isGreen ? "book-delivery__icon book-delivery__icon--eco" : "book-delivery__icon"}
                data-testid="book-delivery-icon"
              />
            ) : (
              <ClickAndCollect className="book-delivery__icon" data-testid="book-collect-icon" />
            ))}

          {isNewGlobalHeader && (
            <div className="book-delivery-new-global-header__slot" data-testid="book-delivery-slot">
              {isLoaded && (
                <span className="book-delivery__datetime">
                  {deliveryDate}, {deliveryTime}
                </span>
              )}
            </div>
          )}
          {!isNewGlobalHeader && (
            <div className="book-delivery__slot" data-testid="book-delivery-slot">
              {isLoaded && (
                <>
                  <span className="book-delivery__datetime">{deliveryDate}</span>{" "}
                  <span className="book-delivery__datetime">{deliveryTime}</span>
                </>
              )}
            </div>
          )}
        </a>
      )}
    </div>
  );
};

export const LoadingIndicator = () => (
  <span aria-label="loading trolley" className="loading">
    <span>&bull;</span>
    <span>&bull;</span>
    <span>&bull;</span>
  </span>
);
