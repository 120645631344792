import React from "react";
import { BasicModalProps, ModalNames } from "./Modal.types";
import { modalWithWrapper } from "./ModalWrapper.component";
import { BasicModalWithClose } from "./BasicModalWithClose/BasicModalWithClose";

type GenericErrorWithWrapperProps = Pick<BasicModalProps, "title" | "description" | "primaryButtonText">;

const GenericErrorWithWrapper = modalWithWrapper<GenericErrorWithWrapperProps>(
  BasicModalWithClose,
  ModalNames.MODAL_GENERIC_ERROR
);

export const GenericErrorModal = () => (
  <GenericErrorWithWrapper
    title="Something went wrong"
    description="It looks like something went wrong. Please try again."
    primaryButtonText="OK"
  />
);
