import React from "react";
import clsx from "clsx";
import { ArrowDown, ArrowUp } from "@jsluna/icons";

export interface TabletGroceriesMenuLinkProps {
  onClick?: () => void;
  selected?: boolean;
}

export const TabletGroceriesMenuLink: React.StatelessComponent<TabletGroceriesMenuLinkProps> = ({
  onClick,
  selected,
}) => (
  <button
    onClick={onClick}
    data-testid="desktop-nav-item-link"
    className={clsx({
      "nav__menu-link": true,
      "nav__menu-link--selected": selected,
    })}
    aria-label="Groceries"
  >
    Groceries
    {selected ? (
      <ArrowUp className="nav__menu-chevron" alt="" title="Close groceries menu" />
    ) : (
      <ArrowDown className="nav__menu-chevron" alt="" title="Open groceries menu" />
    )}
  </button>
);
