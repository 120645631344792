import { Method } from "../common/http/request";
import { serviceRequest } from "./request";
import {
  NectarAdd,
  NectarVerify,
  NectarOffers,
  NectarCardError,
  LinkNectarCardResponse,
  NectarDetailLoyalty,
} from "./nectar.types";
import { Optional } from "../common/types";
import { AcceptYnpOptInResults, FetchYnpOptInResults } from "../domain/ynp/ynp.types";
import { encodeQueryParams } from "../common/http";

const baseOldNectarUrl = "/groceries-api/gol-services/nectar";
const baseNectarUrl = "/groceries-api/loyalty-services";

export const fetchNectarOffers = (url: Optional<string> = baseOldNectarUrl) =>
  serviceRequest<NectarOffers>({ method: Method.GET, url: `${url}/v1/nectar/offers`, timeout: 10000 });

export const addCardRequest = (nectarNumber: string, url: Optional<string> = baseNectarUrl) => {
  const payload = {
    nectarCardId: nectarNumber,
  };
  return serviceRequest<NectarAdd>({
    url: `${url}/v1/card/add`,
    method: Method.POST,
    body: payload,
  });
};

export const getCardDetailsRequest = (url: Optional<string> = baseNectarUrl) => {
  return serviceRequest<NectarDetailLoyalty>({
    method: Method.GET,
    url: `${url}/v1/card/details`,
  });
};

export const deleteCardRequest = (url: Optional<string> = baseNectarUrl) => {
  return serviceRequest<NectarCardError | null>({
    method: Method.DELETE,
    url: `${url}/v1/card/unlink`,
  });
};

export const linkCardRequest = (url: Optional<string> = baseNectarUrl) => {
  return serviceRequest<LinkNectarCardResponse>({
    method: Method.POST,
    url: `${url}/v1/card/link`,
  });
};

export const verifyLinkCardRequest = (validationCode: string, url: Optional<string> = baseNectarUrl) => {
  const payload = {
    validationCode,
  };
  return serviceRequest<NectarVerify>({
    url: `${url}/v1/card/verify`,
    method: Method.POST,
    body: payload,
  });
};

export const fetchYnpOptIns = (
  params: { storeId: string; slotDate: string },
  url: Optional<string> = baseOldNectarUrl
) => {
  return serviceRequest<FetchYnpOptInResults>({
    url: `${url}/v1/nectar/ynp-opt-ins${encodeQueryParams({
      store_id: params.storeId,
      slot_date: params.slotDate,
    })}`,
    method: Method.GET,
  });
};

export const optInForAllProducts = (
  params: { storeId: string; slotDate: string },
  url: Optional<string> = baseOldNectarUrl
) => {
  return serviceRequest<AcceptYnpOptInResults>({
    url: `${url}/v1/nectar/ynp-opt-ins${encodeQueryParams({
      store_id: params.storeId,
      slot_date: params.slotDate,
    })}`,
    method: Method.PUT,
  });
};
