import React from "react";
import {
  Modal,
  ModalHeading,
  FilledButton,
  TextButton,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from "@jsluna/react";
import { useFocusTrap } from "../../../common/hooks/useFocusTrap";

type ModalProps = {
  title: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  primaryButtonText: string;
  onPrimaryButtonClick: () => void;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
  className?: string;
  handleClose?: () => void;
  children: React.ReactNode;
};

export const BasicModal = ({
  title,
  isOpen,
  setIsOpen,
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  className,
  children,
  handleClose,
}: ModalProps) => (
  <Modal
    alert
    handleClose={handleClose ? handleClose : () => setIsOpen(false)}
    open={isOpen}
    headingId="dialog-modal"
    className={className ? className : ""}
    data-testid="modal"
  >
    <ModalHeading element="h3">{title}</ModalHeading>
    {children}
    <ButtonGroupWrapper>
      <ButtonGroupPrimary>
        <FilledButton onClick={onPrimaryButtonClick} data-testid="modal-primary-button">
          {primaryButtonText}
        </FilledButton>
      </ButtonGroupPrimary>
      <ButtonGroupSecondary>
        <TextButton
          onClick={onSecondaryButtonClick ? onSecondaryButtonClick : () => setIsOpen(false)}
          data-testid="modal-secondary-button"
        >
          {secondaryButtonText ? secondaryButtonText : "Cancel"}
        </TextButton>
      </ButtonGroupSecondary>
    </ButtonGroupWrapper>
  </Modal>
);

export const BasicModalWithFocusTrap = (props: ModalProps) => {
  useFocusTrap(props.isOpen, props.setIsOpen);
  return <BasicModal {...props}>{props.children}</BasicModal>;
};
