import React from "react";
import { useDispatch } from "react-redux";
import { BasicModal } from "./Modal.component";
import { ModalNames, ModalsGroupComponentProps } from "./Modal.types";
import { modalWithWrapper } from "./ModalWrapper.component";
import { clearBasketErrorActionCreator } from "../../domain/basket/basket.actions";
import { BasketErrorKeys } from "../../domain/basket/basket.types";

const BulkQuantityExceededModalWithWrapper = modalWithWrapper(BasicModal, ModalNames.MODAL_BULK_ORDER_LIMIT_EXCEEDED);

type BulkQuantityExceededModalProps = {
  title: string;
  description: string;
  bulkOrderingHelp: ModalsGroupComponentProps["bulkOrderingHelp"];
};

export const BulkQuantityExceededModal = ({ title, description, bulkOrderingHelp }: BulkQuantityExceededModalProps) => {
  const dispatch = useDispatch();

  const clearBulkOrderLimitExceededError = () => {
    dispatch(clearBasketErrorActionCreator(BasketErrorKeys.BULK_ORDER_LIMIT_EXCEEDED));
  };

  return (
    <BulkQuantityExceededModalWithWrapper
      title={title}
      description={description}
      primaryButtonText="OK"
      secondaryButtonText="Find out more"
      onPrimaryButtonClick={clearBulkOrderLimitExceededError}
      onSecondaryButtonClick={bulkOrderingHelp}
      onClose={clearBulkOrderLimitExceededError}
    />
  );
};
