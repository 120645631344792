import { MealDealOfferList, PillItem } from "../../MealDealBuilder.types";

export const convertToPills = (nodes: MealDealOfferList[], selectedListId: number | undefined): PillItem[] => {
  const pills: PillItem[] = [];
  nodes.forEach(node => {
    const title = node.listName.concat(node.promoQuantity > 1 ? ` x${node.promoQuantity}` : "");
    const listId = node.listId;
    pills.push({
      title,
      listId,
      url: "",
      target: "",
      copy: "",
      isSelected: selectedListId ? selectedListId === listId : false,
    });
  });
  // Sorting the pills array by listId in ascending order
  pills.sort((a, b) => a.listId - b.listId);
  return pills;
};
