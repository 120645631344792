import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../common/store";
import { useServiceRequestCallback } from "../../../services/hooks";
import { DataState } from "../../../common/dataState";
import { getCachedSlotReservation } from "../../../domain/slot/slotReservation";
import {
  selectHasNectarAssociated,
  selectIsDigitalCustomer,
  selectIsDeliveryPassCustomer,
  selectIsGuestCustomer,
  selectIsVeryImportantCustomer,
  selectUserDataStatus,
} from "../../../common/nectar";
import { fetchMeganavContent } from "../../../services/megaNav";
import { MeganavMagnoliaResponse } from "../../../services/megaNav.types";
import { convertMegaNavToMenuItems, MegaNavData, MenuItem } from "../Header.types";
import { urls } from "../../../routes";
import { DEFAULT_MENU_ITEM_TITLE } from "../menuItemsSelectors";
import { isDataStateSettled } from "../../../utils/isDataStateSettled/isDataStateSettled";
import { featureFlags } from "../../../common/firebase";

// Initial State with Default Values
const magnoliaDataInitialState: MeganavMagnoliaResponse = {
  ads: [],
};

export const useMeganavCMSDataFetch = (contentId: string, findabilityID?: string) => {
  const [banners, fetchMeganavData] = useServiceRequestCallback(fetchMeganavContent);
  const [magnoliaData, setMagnoliaData] = useState<MeganavMagnoliaResponse>(magnoliaDataInitialState);
  const dispatch = useDispatch();
  const userDataStatus = useSelector(selectUserDataStatus);
  const isGuestCustomer = useSelector(selectIsGuestCustomer);
  const hasNectar = useSelector(selectHasNectarAssociated);
  const isDigitalCustomer = useSelector(selectIsDigitalCustomer);
  const hasDeliveryPass = useSelector(selectIsDeliveryPassCustomer);
  const isVeryImportantCustomer = useSelector(selectIsVeryImportantCustomer);
  const slotReservation = useSelector((state: State) => state.slotReservation);
  const slotDetailsLoaded = slotReservation.dataState !== "PENDING";

  /** Resets the data whenever we switch between views */
  useEffect(() => {
    setMagnoliaData(magnoliaDataInitialState);
  }, [contentId, findabilityID]);

  /** Re-fetches the data whenever it's relevant */
  useEffect(() => {
    if (isDataStateSettled(userDataStatus) && slotDetailsLoaded) {
      const { storeIdentifier, slotDate, region } = getCachedSlotReservation() ?? {};

      fetchMeganavData({
        template_id: "meganav",
        store: storeIdentifier,
        delivery_date: slotDate,
        contentId,
        region,
        findabilityID,
        ...(findabilityID && {
          placement: "zone",
          slotId: "Zone_1",
          maxNumberOfAds: 3,
        }),
        ...(!isGuestCustomer && {
          has_nectar_associated: hasNectar,
          is_digital_customer: isDigitalCustomer,
          has_delivery_pass: hasDeliveryPass,
          is_very_important_customer: isVeryImportantCustomer,
        }),
      });
    }
  }, [
    contentId,
    userDataStatus,
    slotDetailsLoaded,
    fetchMeganavData,
    dispatch,
    isGuestCustomer,
    hasNectar,
    isDigitalCustomer,
    hasDeliveryPass,
    isVeryImportantCustomer,
    findabilityID,
  ]);

  /** Separate effect to handle banners. Status and other dependencies */
  useEffect(() => {
    if (banners.status === DataState.SUCCESS && banners.data) {
      if (banners.data?.seasonal || banners.data?.ads) {
        setMagnoliaData({
          ads: banners.data.ads,
          seasonal: banners.data?.seasonal,
        });
      }
    }
  }, [banners.status, banners.data]);
  return magnoliaData;
};

/**
 * Is responsible for injecting the meganav vocab into the existing menu items, as well as
 * occasions and seasonal inspiration items.
 */
export function useMegaNavItems(navItems: MenuItem[], vocab: MegaNavData | null): MenuItem[] {
  const isOccasionsEnabled = featureFlags.get("occasions");
  return useMemo(() => {
    const newNavItems = [...navItems];
    /** Control the display of the occasions object in the new meganav */
    const occasionsIdx = navItems.findIndex(e => e.title === DEFAULT_MENU_ITEM_TITLE.OCCASIONS);
    if (occasionsIdx === -1) {
      // There is no occasions entry included by default, push one
      newNavItems.push({
        id: "occasions",
        title: "Occasions",
        url: urls.OCCASIONS,
        external: true,
        items: [],
      });
    } else if (isOccasionsEnabled) {
      // Occasions are allowed and the user is valid
      newNavItems.splice(occasionsIdx, 1, { ...navItems[occasionsIdx], url: urls.OCCASIONS });
      // Do not show the occasions home L1 link in the new meganav
      newNavItems.splice(occasionsIdx, 1, {
        ...navItems[occasionsIdx],
        items: navItems[occasionsIdx]?.items?.filter(e => e.title !== "Occasions Home"),
      });
    }
    /** Inject the megaNav data into the groceries nav item */
    if (!vocab) return newNavItems;
    const megaNavMapped = convertMegaNavToMenuItems(vocab);
    const groceriesItemIdx = newNavItems.findIndex(e => e.title === DEFAULT_MENU_ITEM_TITLE.GROCERIES);
    if (groceriesItemIdx === -1) return newNavItems;
    newNavItems.splice(groceriesItemIdx, 1, {
      ...newNavItems[groceriesItemIdx],
      items: megaNavMapped,
    });
    return newNavItems;
  }, [navItems, vocab, isOccasionsEnabled]);
}
