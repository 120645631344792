import React from "react";
import { BasicModalProps, ModalNames } from "./Modal.types";
import { modalWithWrapper } from "./ModalWrapper.component";
import { useHandleScrollbarWidth } from "../../common/hooks/useHandleScrollbarWidth";
import { BasicModalWithClose } from "./BasicModalWithClose/BasicModalWithClose";

type FavouriteDeleteErrorWithWrapperProps = Pick<BasicModalProps, "title" | "description" | "primaryButtonText">;

const FavouriteDeleteErrorWithWrapper = modalWithWrapper<FavouriteDeleteErrorWithWrapperProps>(
  BasicModalWithClose,
  ModalNames.MODAL_REMOVE_FROM_FAVOURITES_ERROR
);

const FavouriteDeleteErrorWithHook: any = (props: FavouriteDeleteErrorWithWrapperProps) => {
  useHandleScrollbarWidth();
  return <FavouriteDeleteErrorWithWrapper {...props} />;
};

export const FavouriteDeleteErrorModal = () => (
  <FavouriteDeleteErrorWithHook
    title="Remove from favourites"
    description="There has been an error deleting favourite."
    primaryButtonText="OK"
  />
);
