import { PromotionTypes } from "../../../common/types";
import { OrderPromiseStatus, OrderPromiseStatusType, ProductFeature, ProductHeader } from "../../../domain/product";
import { ProductNectarOffer } from "../productTile.types";

export type GenerateProductTitleAriaLabelProps = {
  nameFormatted: string;
  productHeader?: ProductHeader;
  hasProductCTA: boolean;
  promoType?: PromotionTypes;
  nectarOffer?: ProductNectarOffer;
  currentOrderPromiseStatus?: OrderPromiseStatus;
};

export function generateProductTitleAriaLabel({
  nameFormatted,
  productHeader,
  hasProductCTA,
  promoType,
  nectarOffer,
  currentOrderPromiseStatus,
}: GenerateProductTitleAriaLabelProps) {
  let prefix = "";
  let suffix = "";

  if (promoType === PromotionTypes.YOUR_NECTAR_PRICE) {
    prefix += "Your Nectar Price, ";
  } else if (promoType === PromotionTypes.NECTAR) {
    prefix += "Nectar Price, ";
  } else if (promoType === PromotionTypes.TPR) {
    prefix += "Offer, ";
  } else if (promoType === PromotionTypes.NECTAR_POINTS) {
    prefix += "Bonus Points, ";
  }

  if (currentOrderPromiseStatus?.label) {
    prefix += "Occasions, ";
  }

  if (
    productHeader &&
    !(hasProductCTA && productHeader.type === ProductFeature.FEATURED) &&
    productHeader.text !== "Nectar Price"
  ) {
    prefix += `${productHeader.text}, `;
  }

  if (nectarOffer) {
    prefix += `with ${nectarOffer.points.replace(/[^0-9]/g, "")} bonus points, `;
  }

  if (currentOrderPromiseStatus?.type === OrderPromiseStatusType.NOT_FOR_THIS_ORDER) {
    suffix += ", not available for this order";
  } else if (currentOrderPromiseStatus?.type === OrderPromiseStatusType.CHANGE_SLOT) {
    suffix += ", change slot to add";
  } else if (currentOrderPromiseStatus?.type === OrderPromiseStatusType.BEING_PREPARED) {
    suffix += ", being prepared for this order";
  }

  return `${prefix}${nameFormatted}${suffix}`;
}
