import { tourConfig } from "@favourites/config/tour.config";
import { Device, FeatureTourConfig, Step, TourJourneyTypes } from "@favourites/FeatureTour.types";
import { disabledTourRoutes, favouritesRoutes as routes } from "@favourites/utils/routes";
import { matchPath } from "react-router";
import { featureFlags } from "../../../../common/firebase/featureFlags";
import { getState } from "../../../../components/ResponsiveProvider";

/**
 * FeatureTour is a singleton used to manage the state of the feature tour used to support
 * the add to favourites implementation.
 *
 * The persistence of user interaction with the tour is controlled using session and local storage values
 * which are initialised on app startup and then specific data for a particular step is retrieved
 * using the `get` method.
 *
 * @example
 * import { featureTour } from "./featureTour";
 * const { totalSteps, page } = featureTour.get({ step: 1 });
 */
class FeatureTour {
  constructor({
    sessionKey,
    localStorageKey,
    featureFlag,
  }: {
    sessionKey: string;
    localStorageKey: string;
    featureFlag: string;
  }) {
    this.sessionKey = sessionKey;
    this.localStorageKey = localStorageKey;
    this.featureFlag = featureFlag;
  }

  private config: FeatureTourConfig = tourConfig;
  private sessionKey: string;
  private localStorageKey: string;
  private featureFlag: string;

  /**
   * @description initialise the session and local storage values for the feature tour.
   */
  public initialise(): void {
    const localStorageExists = localStorage.getItem(this.localStorageKey);
    const setDefaultSession = () => this.setSession({ enabled: true, type: TourJourneyTypes.NORMAL });

    if (!this.isFeatureEnabled()) return;

    setDefaultSession();

    if (!localStorageExists) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(true));
    }
  }

  /**
   * @description return whether the tour should be enabled
   * for the current page.
   */
  public isEnabled(): boolean {
    return this.isTourPage() && this.isSessionEnabled() && this.isFeatureEnabled() && !this.hasUserClickedHeart();
  }

  /**
   * @description returns all the information necessary to render the tour tooltip
   * for a particular page and particular step on that page.
   *
   * @example
   * const { page, totalSteps } = featureTour.get({ step: 1 });
   */
  public get({ step }: { step: number }): { page?: Step; totalSteps?: number } {
    const name = this.getPageName();
    const journey = this.getJourneyType();
    const { isMobile } = getState();

    const device: Device = isMobile ? Device.MOBILE : Device.DESKTOP;

    let page;
    let totalSteps;

    if (name && journey && this.config.pages[name]) {
      const pageJourney = this.config.pages[name][journey];

      if (pageJourney && device in pageJourney) {
        page = pageJourney[device][step - 1]; // the step counter starts at 1, not 0
        totalSteps = journey === TourJourneyTypes.NORMAL ? undefined : pageJourney[device].length; // if the journey is normal, we don't want to show the total steps
      }
    }

    return {
      page,
      totalSteps,
    };
  }

  /**
   * @description retrieve the session information.
   *
   * @example
   * const session = featureTour.getSession();
   */
  public getSession(): {
    enabled: boolean;
    type: TourJourneyTypes.NORMAL | TourJourneyTypes.ENHANCED;
  } {
    return JSON.parse(sessionStorage.getItem(this.sessionKey) ?? "{}");
  }

  /**
   * @description sets the journey type and enables you to temporarily disable the tour
   * for the duration of the session.
   *
   * @example
   * featureTour.setSession({ enabled: true, type: TourJourneyTypes.ENHANCED });
   */
  public setSession({
    enabled = this.getSession().enabled,
    type = this.getSession().type,
  }: {
    enabled?: boolean;
    type?: TourJourneyTypes.NORMAL | TourJourneyTypes.ENHANCED;
  }): void {
    sessionStorage.setItem(
      this.sessionKey,
      JSON.stringify({
        enabled,
        type,
      })
    );
  }

  /**
   * @description helper to display the current step out of total step count.
   *
   * @example
   * return featureTour.stringifySteps({ step: 1, totalSteps: 3 }); // returns "1 of 3"
   */
  public stringifySteps({ step, totalSteps }: { step: number; totalSteps?: number }): string {
    return `${step} of ${totalSteps ?? step}`;
  }

  private getJourneyType(): TourJourneyTypes | undefined {
    const session = sessionStorage.getItem(this.sessionKey);
    const { type } = JSON.parse(session || "{}");

    return type;
  }

  private getPageName(): string | undefined {
    const route = Object.keys(routes).find(key => routes[key] === this.getPath());

    return route?.toLowerCase();
  }

  private getTourRoutes(): string[] {
    return Object.entries(routes)
      .map(([_, route]) => {
        return route;
      })
      .filter(route => {
        return !Object.values(disabledTourRoutes).includes(route);
      });
  }

  private getPath(): string | undefined {
    return matchPath(window.location.pathname, {
      path: this.getTourRoutes(),
      exact: true,
    })?.path;
  }

  private isTourPage(): boolean {
    return Boolean(Object.keys(routes).find(key => routes[key] === this.getPath()));
  }

  private isSessionEnabled(): boolean {
    const { enabled } = JSON.parse(sessionStorage.getItem(this.sessionKey) || "{}");

    return enabled;
  }

  private isFeatureEnabled(): boolean {
    return featureFlags.find(this.featureFlag);
  }

  private hasUserClickedHeart(): boolean {
    return localStorage.getItem(this.localStorageKey) === JSON.stringify(false);
  }
}

export const featureTour = new FeatureTour({
  sessionKey: "feature_tour",
  localStorageKey: "feature_tour",
  featureFlag: "add_to_favourites.feature_tour",
});
