import { CitrusBanner } from "../components/Citrus/BannerAd/BannerAd.types";

export enum ContentPage {
  SEARCH = "search",
  BROWSE = "browse",
  EVENT = "event",
  FAVOURITES = "favourites",
  BRANDSHELF = "BrandPage",
}

export enum ContentPlacement {
  OFFERS = "offers",
  SEARCH = "search-below-grid",
  FAVOURITES = "broad-display",
  CATEGORY_ONLY = "category-only",
  IN_GRID_SEARCH = "search-only",
  HOME_PAGE = "homepage-sainsburys-only",
}

export enum ContentBannerLocation {
  TOP = "top_1",
  TOP_1A = "top_1a",
  TOP_1B = "top_1b",
  TOP_1C = "top_1c",
  IN_GRID = "in_grid_1",
  BOTTOM_1A = "bottom_1a",
  BOTTOM_1B = "bottom_1b",
  BOTTOM_1C = "bottom_1c",
  BOTTOM_2 = "bottom_2",
  BOTTOM_3 = "bottom_3",
  SEARCH_BOTTOM_1 = "S_below_grid_1",
  FAVOURITES_BOTTOM_1 = "Favourites_bottom_1",
  OFFERS_ABOVE_GRID_1 = "Offers_above_grid_1",
  OFFERS_BELOW_GRID_1 = "Offers_below_grid_1",
  FAVOURITES_IN_GRID_1 = "Favourites_in_grid_1",
  SHELF_IN_GRID_1 = "Shelf_in_grid_1",
  EVENT_IN_GRID_1 = "Event_in_grid_1",
  SEARCH_IN_GRID_1 = "Search_in_grid_1",
  HOME_PAGE = "Homepage_1",
}

export interface TemplateComponent {
  type: string;
  filters?: string[];
  heading?: string;
  section?: Section[];
  [otherOptions: string]: unknown;
}

export interface Section {
  heading: string;
  items: Item[];
  link: Link;
}

export interface Item {
  copy?: string;
  iconType?: string;
  link?: Link;
}

export interface Link {
  title?: string;
  url?: string;
}

export interface TemplateAreas {
  aboveGrid?: TemplateArea;
  seo?: TemplateArea;
}

export interface TemplateArea {
  attributes: TemplateComponent[] | CitrusBanner[];
  desc?: string;
  id?: string;
  type?: string;
  imageStyle?: string;
  isVertical?: boolean;
  mobileLayout?: string;
}

export interface MagnoliaTemplate {
  meta?: {
    [key: string]: any;
  };
  data: TemplateArea[];
}
