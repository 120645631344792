import { Result } from "../../common/http/request";
import { handleUnauthorized } from "../../common/responseErrorHandler";
import { fetchNectarOffers } from "../../services/nectar";
import { AppThunkDispatch } from "../../common/types";
import {
  FetchNectarOffersErrorAction,
  FetchNectarOffersPendingAction,
  FetchNectarOffersSuccessAction,
  NectarActions,
  NectarOffers,
} from "./nectar.types";
import { NectarOffer, NectarOffers as ServicesNectarOffers } from "../../services/nectar.types";

export const fetchNectarOffersPendingActionCreator = (): FetchNectarOffersPendingAction => ({
  type: NectarActions.FETCH_NECTAR_OFFERS_PENDING,
});

export const fetchNectarOffersSuccessActionCreator = (nectarOffers: NectarOffers): FetchNectarOffersSuccessAction => ({
  type: NectarActions.FETCH_NECTAR_OFFERS_SUCCESS,
  nectarOffers,
});

export const fetchNectarOffersErrorActionCreator = (): FetchNectarOffersErrorAction => ({
  type: NectarActions.FETCH_NECTAR_OFFERS_ERROR,
});

export const fetchNectarOffersAction = () => async (dispatch: AppThunkDispatch) => {
  dispatch(fetchNectarOffersPendingActionCreator());

  const result: Result<ServicesNectarOffers> = await fetchNectarOffers();

  dispatch(handleUnauthorized(result));

  if (result.isSuccess()) {
    dispatch(fetchNectarOffersSuccessActionCreator(mapNectarOffers(result.data)));
  } else {
    dispatch(fetchNectarOffersErrorActionCreator());
  }
};

export const mapNectarOffers = (nectarOffers: ServicesNectarOffers): NectarOffers => {
  return {
    accountStatus: nectarOffers.account_status,
    offers: nectarOffers.offers,
  };
};

export const filterNectarOffers = (nectarOffers: NectarOffer[], reservationStartTime: string) => {
  if (reservationStartTime)
    return nectarOffers.filter(offer => new Date(offer.expires) >= new Date(reservationStartTime));

  return nectarOffers;
};
