import {
  AISLE,
  AssociationType,
  PaginatedProducts,
  Product,
  ProductHeader,
  ProductSource,
  ProductType,
} from "./product.types";
import { ProductPage, ProductTileProduct } from "../../components/ProductTile/productTile.types";
import { detailsHtml } from "../../services/product.fixtures";
import { PromoMechanicIds } from "../../common/types";
import { FavouriteType } from "@favourites/Favourites.types";
import { PromoTypes } from "../../services/product.types";

export const alternativeProduct: Product = {
  productUid: "7853707",
  favouriteUid: null,
  favourite: false,
  favouriteSource: FavouriteType.MANUALLY_ADDED,
  productType: ProductType.BASIC,
  isSupplyChainOrderable: false,
  name: "Sainsbury's Alternative",
  eans: ["0000000278645"],
  retailPrice: {
    price: 6.5,
    measure: "ea",
  },
  unitPrice: {
    price: 0.24,
    measure: "sht",
    measureAmount: 100,
  },
  image: "https://assets.js-golazo.co.uk/default-image.jpg",
  assets: {
    images: [],
    video: [],
  },
  imageThumbnail: null,
  imageThumbnailSmall: null,
  fullUrl: "https://www.golui.gol.js-devops.co.uk/shop/gb/groceries/product/details/batman-cake-913g",
  pdpDeepLink: "",
  available: true,
  isSpotlight: false,
  isIntolerant: false,
  isMHRA: false,
  attributes: {
    brand: ["Sainsbury's"],
  },
  displayIcons: [],
  productCTA: {
    type: "REPLACEMENT",
    text: "Add this instead",
  } as ProductHeader,
  productHeader: {
    type: "REPLACEMENT",
    text: "Try this instead",
  } as ProductHeader,
  labels: [],
  badges: [],
  promotions: [],
  associationType: AssociationType.APE,
  position: 1,
  source: ProductSource.FAVOURITE,
  reviews: {
    isEnabled: true,
    productUid: "7853707",
    averageRating: 2.3191,
    numberOfReviews: 47,
  },
  breadcrumbs: [],
  importantInformation: [],
  description: [],
  attachments: [],
  categories: [
    {
      id: "13270",
    },
  ],
};

const alternativeProductv2: Product = {
  productUid: "7853707",
  favouriteUid: "52659",
  favourite: true,
  favouriteSource: FavouriteType.MANUALLY_ADDED,
  productType: ProductType.BASIC,
  isSupplyChainOrderable: false,
  name: "Sainsbury's Alternative",
  zone: "zone name",
  eans: ["12345"],
  expires: undefined,
  retailPrice: {
    price: 6.5,
    measure: "ea",
  },
  unitPrice: {
    price: 0.24,
    measure: "sht",
    measureAmount: 100,
  },
  image: "https://assets.js-golazo.co.uk/default-image.jpg",
  assets: {
    images: [
      {
        id: "id",
        sizes: [
          {
            height: 480,
            url: "https://assets.js-golazo.co.uk/default-image.jpg",
            width: 480,
          },
        ],
      },
    ],
    video: [
      {
        url: "https://www.youtube.com/embed/h_D3VFfhvs4",
      },
    ],
  },
  associationParentProductUid: "7553917",
  imageThumbnail:
    "//www.golui.gol.js-devops.co.uk/wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/productImages/63/5019485112363/5019485112363_M.jpeg",
  imageThumbnailSmall:
    "//www.golui.gol.js-devops.co.uk/wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/productImages/63/5019485112363/5019485112363_S.jpeg",
  fullUrl: "https://www.golui.gol.js-devops.co.uk/shop/gb/groceries/product/details/batman-cake-913g",
  pdpDeepLink: "",
  available: true,
  isSpotlight: false,
  isIntolerant: false,
  isMHRA: false,
  attributes: {
    brand: ["Sainsbury's"],
  },
  displayIcons: ["Frozen", "Vegan"],
  labels: [
    {
      altText: "frozen",
      color: "#000000",
      labelUid: "FROZEN",
      link: "/shop/gb/groceries/frozen",
      linkOpensInNewWindow: false,
      text: "Frozen",
    },
  ],
  badges: [
    {
      altText: "frozen",
      badgeUid: "PriceLockdown",
      color: "#cb0005",
      iconUrl: "https://assets.js-golazo.co.uk/default-image.jpg",
      link: "https://www.sainsburys.co.uk/shop/gb/groceries/great-offers",
      linkOpensInNewWindow: false,
      text: "Price Lockdown",
    },
  ],
  promotions: [],
  associationType: AssociationType.APE_OVERRIDE,
  position: 1,
  source: ProductSource.PRODUCT,
  reviews: {
    isEnabled: true,
    productUid: "7853707",
    averageRating: 2.3191,
    numberOfReviews: 47,
  },
  breadcrumbs: [
    {
      label: "crumb label",
      url: "gb/newproduct",
    },
  ],
  catchweight: [
    {
      range: "1.37kg-1.78kg",
      retailPrice: {
        measure: "kg",
        price: 3,
      },
      unitPrice: {
        measure: "kg",
        measureAmount: 1,
        price: 6.64,
      },
    },
  ],
  importantInformation: ["some important information"],
  department: "department name",
  description: ["Product Description Line 1", "Product Description Line 2"],
  detailsHtml,
  attachments: [
    {
      fileDescription: "health and hygiene",
      fileName: "hygiene doc",
      filePath: "example/health.pdf",
      mimeType: "application/pdf",
    },
  ],
  categories: [
    {
      id: "1234",
    },
  ],
};

export const aDomainProduct = (override?: { [key in keyof Partial<Product>]: any }): Product => ({
  position: 1,
  detailsHtml,
  associationParentProductUid: undefined,
  expires: undefined,
  eans: ["12345"],
  source: ProductSource.PRODUCT,
  productUid: "7553917",
  favouriteUid: "52659",
  favourite: true,
  favouriteSource: FavouriteType.MANUALLY_ADDED,
  zone: "zone name",
  department: "department name",
  productType: ProductType.BASIC,
  isSupplyChainOrderable: false,
  name: "name",
  attributes: {
    brand: ["Sainsbury's"],
  },
  categories: [
    {
      id: "1234",
    },
  ],
  retailPrice: {
    price: 8.76,
    measure: "unit",
  },
  unitPrice: {
    price: 8.76,
    measure: "ea",
    measureAmount: 1,
  },
  image: "https://assets.js-golazo.co.uk/default-image.jpg",
  imageThumbnail:
    "//www.golui.gol.js-devops.co.uk/wcsstore/ExtendedSitesCatalogAssetStore/" +
    "images/catalog/productImages/63/5019485112363/5019485112363_M.jpeg",
  imageThumbnailSmall:
    "//www.golui.gol.js-devops.co.uk/wcsstore/" +
    "ExtendedSitesCatalogAssetStore/images/catalog/productImages/63/5019485112363/5019485112363_S.jpeg",
  fullUrl: "https://www.golui.gol.js-devops.co.uk/shop/gb/groceries/product/details/batman-cake-913g",
  pdpDeepLink: "/shop/ProductDisplay?storeId=10151&langId=44&productId=66358",
  available: true,
  isSpotlight: false,
  isIntolerant: false,
  isMHRA: false,
  displayIcons: ["Frozen", "Vegan"],
  labels: [
    {
      labelUid: "FROZEN",
      text: "Frozen",
      color: "#000000",
      link: "/shop/gb/groceries/frozen",
      linkOpensInNewWindow: false,
      altText: "frozen",
    },
  ],
  badges: [
    {
      badgeUid: "PriceLockdown",
      text: "Price Lockdown",
      color: "#cb0005",
      iconUrl: "https://assets.js-golazo.co.uk/default-image.jpg",
      link: "https://www.sainsburys.co.uk/shop/gb/groceries/great-offers",
      linkOpensInNewWindow: false,
      altText: "frozen",
    },
  ],
  description: ["Product Description Line 1", "Product Description Line 2"],
  importantInformation: ["some important information"],
  multivariants: [
    {
      productUid: "7553918",
      displayName: "Robin Cake 913g",
      retailPrice: {
        price: 0.49,
        measure: "unit",
      },
      unitPrice: {
        price: 0.49,
        measure: "unit",
        measureAmount: 1,
      },
      promotions: [
        {
          promotionUid: "10005002",
          url: "/shop/PromotionDisplayView?langId=44&storeId=10151&promotionId=10005002",
          icon: "//www.golui.gol.js-devops.co.uk/wcsstore/images/SO_One_Third_S_Icon.gif",
          strapLine: "Save 1/3:  was £1.29 now 86p",
          startDate: "2013-03-21T00:00:00Z",
          endDate: "2023-12-14T00:00:00Z",
          promoMechanicId: "4",
          isNectar: false,
          promoType: PromoTypes.BOGOF,
        },
      ],
    },
  ],
  catchweight: [
    {
      retailPrice: {
        price: 3.0,
        measure: "kg",
      },
      unitPrice: {
        price: 6.64,
        measure: "kg",
        measureAmount: 1,
      },
      range: "1.37kg-1.78kg",
    },
  ],
  assets: {
    images: [
      {
        sizes: [
          {
            url: "https://assets.js-golazo.co.uk/default-image.jpg",
            height: 480,
            width: 480,
          },
        ],
        id: "id",
      },
    ],
    video: [
      {
        url: "https://www.youtube.com/embed/h_D3VFfhvs4",
      },
    ],
  },
  promotions: [
    {
      promotionUid: "10005002",
      url: "/shop/PromotionDisplayView?langId=44&storeId=10151&promotionId=10005002",
      icon: "//www.golui.gol. js-devops.co.uk/wcsstore/images/SO_One_Third_S_Icon.gif",
      strapLine: "Save 1/3:  was £1.29 now 86p",
      startDate: "2013-03-21T00:00:00Z",
      endDate: "2023-12-14T00:00:00Z",
      promoMechanicId: "4",
      promoType: PromoTypes.BOGOF,
      isNectar: false,
    },
  ],
  // Association type to be removed
  associationType: AssociationType.NONE,
  reviews: {
    isEnabled: true,
    productUid: "7553917",
    averageRating: 0,
    numberOfReviews: 500,
  },
  breadcrumbs: [
    {
      label: "crumb label",
      url: "gb/newproduct",
    },
  ],
  attachments: [
    {
      fileName: "hygiene doc",
      fileDescription: "health and hygiene",
      filePath: "example/health.pdf",
      mimeType: "application/pdf",
    },
  ],
  ...(override || {}),
});

export const aDomainProductWithHeader = (productHeader: ProductHeader): Product => ({
  ...aDomainProduct(),
  productHeader,
});

export const aDomainProductWithCTA = (productCTA: ProductHeader): Product => ({
  ...aDomainProduct(),
  productCTA,
});

export const aDomainProductWithAlternatives = (productCTA: ProductHeader): Product => ({
  ...aDomainProduct(),
  alternatives: [alternativeProduct, alternativeProduct, alternativeProduct],
  available: false,
  productCTA,
});

export const aDomainProductWithAlternative = (productCTA: ProductHeader | undefined): Product => ({
  ...aDomainProduct(),
  alternatives: [alternativeProductv2],
  available: false,
  productCTA,
});

export const aDomainProductWithControls: PaginatedProducts = {
  products: [aDomainProduct()],
  controls: {
    sortOptions: [{ value: AISLE, display: "Category" }],
    activeSort: AISLE,
    filters: [],
    pageNumber: 1,
    pageLast: 1,
    pageSizeOptions: [30, 60, 90],
    pageSizeActive: 30,
    recordCount: 30,
  },
};

export const aDomainProductWithAssociationAndControls: PaginatedProducts = {
  products: [aDomainProductWithAlternative(undefined)],
  controls: {
    sortOptions: [{ value: AISLE, display: "Category" }],
    activeSort: AISLE,
    filters: [],
    pageNumber: 1,
    pageLast: 1,
    pageSizeOptions: [30, 60, 90],
    pageSizeActive: 30,
    recordCount: 30,
  },
};

export const mockCanonicalUrl = "https://www.sainsburys.co.uk/gol-ui/product/test-product";

export const aProductTileProduct = (): ProductTileProduct => {
  const product = aDomainProduct();

  return {
    product,
    selectedUnitOfMeasure: "ea",
    isScrolledIntoView: false,
    productOptions: undefined,
    productPage: ProductPage.PRODUCT_TILE,
    promotions: product.promotions,
    retailPrice: {
      price: "£8.76",
      measure: "unit",
    },
    unitPrice: {
      price: "£8.76",
      measure: "ea",
    },

    canonicalUrl: mockCanonicalUrl,
  };
};

export const aDomainProductWithControlsAndFilters: PaginatedProducts = {
  products: [aDomainProduct()],
  controls: {
    sortOptions: [{ value: AISLE, display: "Category" }],
    activeSort: AISLE,
    filters: [
      {
        key: "nav_Filter-Your-Results",
        label: "Filter Your Results",
        type: "MULTI_SELECT",
        values: [
          {
            label: "Offers & Nectar Prices",
            value: "Offers",
            count: 10,
            selected: false,
            enabled: true,
          },
          {
            label: "New",
            value: "New",
            count: 10,
            selected: false,
            enabled: false,
          },
        ],
      },
      {
        key: "nav_Brand",
        label: "Brand",
        type: "MULTI_SELECT",
        values: [
          {
            label: "Andrex",
            value: "Andrex",
            count: 10,
            selected: false,
            enabled: true,
          },
          {
            label: "Anchor",
            value: "Anchor",
            selected: false,
            count: 10,
            enabled: true,
          },
          {
            label: "La Rochelle",
            value: "La-Rochelle",
            selected: false,
            count: 10,
            enabled: true,
          },
          {
            label: "Smirnoff",
            value: "Smirnoff",
            count: 10,
            selected: false,
            enabled: true,
          },
          {
            label: "Munchy Seeds",
            value: "Munchy-Seeds",
            count: 10,
            selected: false,
            enabled: true,
          },
          {
            label: "Babybel",
            value: "Babybel",
            count: 10,
            selected: false,
            enabled: true,
          },
          {
            label: "Glaceau Smartwater",
            value: "Glaceau-Smartwater",
            count: 10,
            selected: false,
            enabled: true,
          },
        ],
      },
    ],
    pageNumber: 1,
    pageLast: 1,
    pageSizeOptions: [30, 60, 90],
    pageSizeActive: 30,
    recordCount: 30,
  },
};

export const aDomainNectarPromotion = {
  promotionUid: "10005002",
  url: "/shop/PromotionDisplayView?langId=44&storeId=10151&promotionId=10005002",
  icon: "//www.golui.gol.js-devops.co.uk/wcsstore/images/SO_One_Third_S_Icon.gif",
  strapLine: "Save 1/3:  was £1.29 now 86p",
  startDate: "2013-03-21T00:00:00Z",
  endDate: "2023-12-14T00:00:00Z",
  promoMechanicId: PromoMechanicIds.NECTAR_PROMOTION,
};
