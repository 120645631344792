import { Recipe } from "../Recipes/Recipe.types";
import { WithDataState } from "../../common/dataState";

export interface YourRecipesFavouritesResponse {
  recipes: YourRecipesFavourite[];
  totalCount: number;
}
export type YourRecipesFavourite = Recipe;

export interface YourRecipesFavouritesState extends WithDataState {
  favourites: YourRecipesFavouritesResponse;
}

export enum YourRecipesFavouritesActions {
  YOUR_RECIPES_FAVOURITES_REQUEST = "YOUR_RECIPES_FAVOURITES_REQUEST",
  YOUR_RECIPES_FAVOURITES_SUCCESS = "YOUR_RECIPES_FAVOURITES_SUCCESS",
  YOUR_RECIPES_FAVOURITES_FAILED = "YOUR_RECIPES_FAVOURITES_FAILED",
  YOUR_RECIPES_FAVOURITE_DELETED_REQUEST = "YOUR_RECIPES_FAVOURITE_DELETED_REQUEST",
  YOUR_RECIPES_FAVOURITE_DELETED_SUCCESS = "YOUR_RECIPES_FAVOURITE_DELETED_SUCCESS",
  YOUR_RECIPES_FAVOURITE_DELETED_FAILED = "YOUR_RECIPES_FAVOURITE_DELETED_FAILED",
  REMOVE_DELETED_RECIPE_FROM_STATE = "REMOVE_DELETED_RECIPE_FROM_STATE",
}
