import { getCachedSlotReservation } from "../slot/slotReservation";
import moment from "moment";
import { SlotReservationType as SlotReservationTypeResponse } from "../../services/slot.types";

/**
 * Use the cached slot reservation
 * If there is no cached slot reservation, use tomorrow
 */
export const getPickTime = () => {
  const cachedSlotReservation = getCachedSlotReservation();
  if (cachedSlotReservation?.slotDate) {
    return moment.utc(cachedSlotReservation.slotDate).format();
  } else {
    const defaultPickTime = moment.utc().add(1, "day").format();
    return defaultPickTime;
  }
};

/**
 * Use the cached slot reservation
 * If there is no cached slot reservation, use the default store number
 */
export const getStoreNumber = () => {
  const cachedSlotReservation = getCachedSlotReservation();
  return cachedSlotReservation?.storeIdentifier || "2168";
};

/**
 * Use the cached slot reservation
 * If there is no cached slot reservation, use tomorrow and the default store number
 */
export const getPickTimeAndStoreNum = () => {
  const pickTime = getPickTime();
  const storeNum = getStoreNumber();
  return { pickTime, storeNum };
};

export const getSlotBooked = () => {
  const cachedSlotReservation = getCachedSlotReservation();
  return !!(
    cachedSlotReservation?.reservationType &&
    cachedSlotReservation?.reservationType !== SlotReservationTypeResponse.NONE
  );
};
