import { FavouriteType } from "@favourites/Favourites.types";
import {
  ProductMessage,
  ProductServiceError,
  PromotionResponse,
  SuggestedSearchTerm,
} from "../../services/product.types";

export interface PaginatedProducts {
  products: Product[];
  relevancyProducts?: RelevancySplit;
  controls: ProductControls;
}

export interface RelevancySplit {
  relevant: Product[];
  related: Product[];
}

export interface ProductsWithAds {
  products: Product[];
  ads?: ProductAds;
}

export interface ProductAds {
  sponsoredProducts: SponsoredProducts;
}

export interface SponsoredProducts {
  productAds: ProductAd[];
}

export interface ProductAd {
  product: Product;
  id: string;
  type: string;
}

export function getProductAdEans(productAds: ProductAd[]): string[] {
  return productAds.reduce((acc, curr) => acc.concat(curr.product.eans), [] as string[]);
}

export interface Product {
  productUid: string;
  favouriteUid: string | null;
  favourite: boolean;
  favouriteSource?: FavouriteType;
  zone?: string;
  department?: string;
  productType: ProductType;
  isSupplyChainOrderable: boolean;
  attributes: {
    brand: string[];
  };
  name: string;
  image: string | null;
  eans: string[];
  imageThumbnail: string | null;
  imageThumbnailSmall: string | null;
  assets: Assets;
  fullUrl?: string;
  pdpDeepLink: string;
  retailPrice?: RetailPrice;
  unitPrice?: UnitPrice;
  averageWeight?: AverageWeight;
  nectarPrice?: NectarPrice;
  yourNectarPrice?: YourNectarPrice;
  available: boolean;
  alternatives?: Product[] | undefined;
  isSpotlight: boolean;
  isIntolerant: boolean;
  isMHRA: boolean;
  occasion?: Occasion;
  promise?: ProductOrderPromise;
  multivariants?: MultiVariant[];
  promotions?: Promotion[];
  associationType: AssociationType;
  associationParentProductUid?: string;
  expires?: string;
  catchweight?: Catchweight[];
  displayIcons: string[];
  labels: Label[];
  badges: Badge[];
  position: number;
  source: ProductSource | string;
  reviews: Reviews;
  breadcrumbs: Breadcrumb[];
  detailsHtml?: string;
  description: string[];
  importantInformation: string[];
  attachments: Attachment[];
  productCTA?: ProductHeader;
  productHeader?: ProductHeader;
  categories: ProductCategory[];
  messages?: ProductMessage[];
  errors?: Error;
  relevancyRank?: number;
  list?: string;
  notForEU?: boolean;
}

export function getProductEANs(products: Product[] = []): string[] {
  return products.reduce((acc, curr) => acc.concat(curr.eans), [] as string[]);
}

export interface Occasion {
  serves: string;
}

export interface ProductOrderPromise {
  status: OrderPromiseStatus;
  earliestPromiseDate?: string;
  lastAmendmentDate?: string;
}

export type OrderPromiseStatus = {
  label: string;
  type: OrderPromiseStatusType;
};

export enum OrderPromiseStatusType {
  AVAILABLE_TO_ADD = "AVAILABLE_TO_ADD",
  CHANGE_SLOT = "CHANGE_SLOT",
  NOT_FOR_THIS_ORDER = "NOT_FOR_THIS_ORDER",
  TIME_TO_CUTOFF = "TIME_TO_CUTOFF",
  TIME_TO_CUTOFF_CLOSE = "TIME_TO_CUTOFF_CLOSE",
  BEING_PREPARED = "BEING_PREPARED",
}

export interface ProductCategory {
  id: string;
}

export interface ProductCategoryLink {
  url: string;
  name: string;
}

export interface ProductHeader {
  type: ProductFeature;
  text: string;
}

export enum ProductFeature {
  SPONSORED = "SPONSORED",
  REPLACEMENT = "REPLACEMENT",
  FEATURED = "FEATURED",
  NECTAR = "NECTAR",
}

export interface Breadcrumb {
  label: string;
  url: string;
}

export interface Label {
  labelUid: string;
  text: string;
  color: string;
  link?: string;
  linkOpensInNewWindow: boolean;
  altText: string;
}

export interface Badge {
  badgeUid: string;
  text: string;
  color: string;
  iconUrl?: string;
  link?: string;
  linkOpensInNewWindow: boolean;
  altText: string;
}

export interface ProductReviewsProps {
  fullUrl?: string;
  product: Product;
  reviews: Reviews;
}

export interface ProductControls {
  sortOptions: SortOptions[];
  activeSort: SortOption;
  pageSizeOptions: number[];
  pageSizeActive: number;
  filters?: FilterProduct[];
  pageLast: number;
  pageNumber: number;
  recordCount: number;
}

export interface FilterProduct {
  key: string;
  label: string;
  type: string;
  unwrapped?: boolean;
  values: FilterProductValue[];
}

export enum FilterProductType {
  MULTI_SELECT = "MULTI_SELECT",
  SINGLE_SELECT = "SINGLE_SELECT",
}

export interface FilterProductValue {
  id?: string;
  label: string;
  count?: number;
  value: string;
  selected: boolean;
  enabled: boolean;
  disabled?: boolean;
}

export type SortOption = string;
export const AISLE: SortOption = "AISLE";
export const PRICE_ASC: SortOption = "PRICE_ASC";
export const FAVOURITES_FIRST: SortOption = "FAVOURITES_FIRST";

export interface SortOptions {
  display: string;
  value: SortOption;
}

export type ProductUid = Product["productUid"];

export interface RetailPrice {
  price: number;
  measure: string;
  wasPrice?: number;
}

export interface Attachment {
  filePath: string;
  fileName: string;
  fileDescription: string;
  mimeType: string;
}

export interface Reviews {
  isEnabled: boolean;
  productUid: string;
  numberOfReviews: number;
  averageRating: number;
}

export interface UnitPrice {
  price: number;
  measure: string;
  measureAmount: number;
}

export interface AverageWeight {
  amount: number;
  measure: string;
}

export interface NectarPrice {
  retailPrice: number;
  measure: string;
  unitPrice: number;
  url: string;
}

export interface YourNectarPrice {
  withoutNectarPrice: number;
  startTime: string;
  expiryTime: string;
}

export interface NectarPriceFormatted {
  retailPrice: string;
  measure: string;
  unitPrice: string;
}

export interface ProductsPagination {
  pageActive: number;
  pageLast: number;
  pageSize: number;
  pageSizeOptions: number[];
}

export type ProductListControlsQueryParams = {
  pageNumber?: number;
  pageSize?: number;
  filters?: Filter[];
  sort?: string;
};

export interface Filter {
  key: string;
  value: string;
  enabled: boolean;
}

export interface Promotion {
  strapLine: string;
  icon?: string;
  startDate?: string;
  endDate?: string;
  url: string;
  promotionUid: string;
  promotionMissed?: boolean;
  originalPrice?: number;
  isNectarPrice?: boolean;
  promoMechanicId?: string;
  type?: string;
  promoType?: PromotionResponse["promo_type"];
  promoGroup?: PromotionResponse["promo_group"];
  isNectar?: PromotionResponse["is_nectar"];
  listName?: string; // AD_x
  listOrder?: string;
  listId?: string;
}

export enum ProductType {
  CATCHWEIGHT = "CATCHWEIGHT",
  MULTIVARIANT = "MULTIVARIANT",
  LOOSE = "LOOSE",
  BASIC = "BASIC",
}

export enum AssociationType {
  UPSELL = "UPSELL",
  CROSSELL = "CROSSELL",
  APE = "APE",
  APE_OVERRIDE = "APE_OVERRIDE",
  KRANG = "KRANG",
  CITRUS_CROSSELL = "CITRUS_CROSSELL",
  NONE = "NONE",
}

export interface MultiVariant {
  productUid: string;
  displayName: string;
  promotions?: Promotion[];
  retailPrice: RetailPrice;
  unitPrice: UnitPrice;
}

export interface Catchweight {
  range: string;
  retailPrice: RetailPrice;
  unitPrice: UnitPrice;
}

export enum ProductSource {
  HIERARCHY = "HIERARCHY",
  PRODUCT = "PRODUCT",
  PRODUCT_DETAILS = "PRODUCT_DETAILS",
  FAVOURITE = "FAVOURITE",
  SEASONAL_FAVOURITE = "SEASONAL_FAVOURITE",
  RECOMMENDATION = "RECOMMENDATION",
  FIRST_FAVOURITES = "FIRST_FAVOURITES",
  SEARCH_RESULTS = "SEARCH_RESULTS", // TODO might be incorrect
  PREVIOUS_ORDERS = "PREVIOUS_ORDERS",
  CROSS_SELL = "CROSS_SELL",
  CITRUS_SEARCH_ADS_BELOW_GRID = "CITRUS_SEARCH_ADS_BELOW_GRID",
  CITRUS_SEARCH_ADS_IN_GRID = "CITRUS_SEARCH_ADS_IN_GRID",
  CITRUS_XSELL_AD_PDP = "CITRUS_XSELL_AD_PDP",
  CITRUS_XSELL_AD_FAVOURITES = "CITRUS_XSELL_AD_FAVOURITES",
  CITRUS_OFFERS = "CITRUS_OFFERS",
  TROLLEY = "TROLLEY",
  PRODUCT_LISTER = "PRODUCT_LISTER",
  CHECKOUT_UPSELL = "CHECKOUT_UPSELL",
  CITRUS_CHECKOUT_UPSELL = "CITRUS_CHECKOUT_UPSELL",
  CHECKOUT_FAVOURITES = "CHECKOUT_FAVOURITES",
  NECTAR_DESTINATION = "NECTAR_DESTINATION",
  ZONE_OFFERS = "ZONE_OFFERS",
  BRANDSHELF = "BRANDSHELF",
  CHECKOUT = "CHECKOUT",
  KRANG = "KRANG",
  MEAL_DEAL_BUILDER = "MEAL_DEAL_BUILDER",
  LISTER_BUILDER = "LISTER_BUILDER",
  EVENTS = "EVENTS",
  FEATURES = "FEATURES",
  BROWSE_ZONE = "ZONE",
  HOME_PAGE = "HOME_PAGE",
  PROMO_LISTER = "PROMO_LISTER",
  FREQUENTLY_BOUGHT_TOGETHER = "FREQUENTLY_BOUGHT_TOGETHER",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  YOUR_NECTAR_PRICES_OPT_IN = "YOUR_NECTAR_PRICES_OPT_IN",
  VIEW_SIMILAR = "VIEW_SIMILAR",
}

export enum ProductsViews {
  BASKET = "basket",
  FAVOURITES = "favourites",
  FAVOURITES_ADS = "favourites_ads",
  FAVOURITES_ALTERNATIVES = "favourites_alternatives",
  SEASONAL_FAVOURITES = "seasonal_favourites",
  RECOMMENDATIONS = "recommendations",
  FIRST_FAVOURITES = "first_favourites",
  SEARCH_RESULTS = "search_results",
  SEARCH_RESULTS_RELEVANT = "search_results_relevant",
  SEARCH_RESULTS_RELATED = "search_results_related",
  SEARCH_RESULTS_ADS_BELOW_GRID = "search_results_ads_below_grid",
  PREVIOUS_ORDERS = "previous_orders",
  PRODUCT_DETAILS = "product_details",
  PRODUCT_DETAILS_SPONSORED_PRODUCT = "product_details_sponsored_product",
  TROLLEY = "trolley",
  PRODUCT_LISTER = "product_lister",
  NECTAR_OFFERS = "nectar_offers",
  OFFERS = "offers",
  CHECKOUT = "checkout",
  NECTAR_DESTINATION_PAGE = "nectar_destination_page",
  NDP_NECTAR_OFFERS_CAROUSEL = "ndp_nectar_offers_carousel",
  NDP_NECTAR_FAVOURITES_CAROUSEL = "ndp_nectar_favourites_carousel",
  NDP_BONUS_POINTS_CAROUSEL = "ndp_bonus_points_carousel",
  MEAL_DEAL_BUILDER = "meal_deal_builder",
  LISTER_BUILDER = "lister_builder",
  EVENTS_BUILDER = "events_builder",
  FEATURES = "features",
}

export interface ProductsState {
  data: Map<ProductUid, Product>;
  views: {
    [ProductsViews.FAVOURITES]: ProductUid[];
    [ProductsViews.FAVOURITES_ADS]: ProductUid[];
    [ProductsViews.FAVOURITES_ALTERNATIVES]: ProductUid[];
    [ProductsViews.SEASONAL_FAVOURITES]: ProductUid[];
    [ProductsViews.RECOMMENDATIONS]: ProductUid[];
    [ProductsViews.FIRST_FAVOURITES]: ProductUid[];
    [ProductsViews.PREVIOUS_ORDERS]: ProductUid[];
    [ProductsViews.SEARCH_RESULTS]: ProductUid[];
    [ProductsViews.SEARCH_RESULTS_RELEVANT]: ProductUid[];
    [ProductsViews.SEARCH_RESULTS_RELATED]: ProductUid[];
    [ProductsViews.SEARCH_RESULTS_ADS_BELOW_GRID]: ProductUid[];
    [ProductsViews.PRODUCT_DETAILS]: ProductUid[];
    [ProductsViews.PRODUCT_DETAILS_SPONSORED_PRODUCT]: ProductUid[];
    [ProductsViews.PRODUCT_LISTER]: ProductUid[];
    [ProductsViews.MEAL_DEAL_BUILDER]: ProductUid[];
    [ProductsViews.LISTER_BUILDER]: ProductUid[];
    [ProductsViews.EVENTS_BUILDER]: ProductUid[];
    [ProductsViews.NECTAR_DESTINATION_PAGE]: {
      [ProductsViews.NDP_NECTAR_OFFERS_CAROUSEL]: ProductUid[];
      [ProductsViews.NDP_NECTAR_FAVOURITES_CAROUSEL]: ProductUid[];
      [ProductsViews.NDP_BONUS_POINTS_CAROUSEL]: ProductUid[];
    };
  };
  controls?: ProductControls;
  suggestedTerm?: SuggestedSearchTerm[] | undefined;
  errors?: ProductServiceError[];
}

export interface Assets {
  images: Image[];
  video: Video[];
}

export interface Image {
  sizes: ImageSize[];
  id: string;
  caption?: string;
}

export interface ImageSize {
  url: string;
  width: number;
  height: number;
}

export interface Video {
  url: string;
}

export interface Favourite {
  favouriteId: string;
  sourceType: string;
}
