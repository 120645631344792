import { Action, AnyAction } from "redux";
import { ModalActionTypes, ModalNames, ModalOpenAction } from "./Modal.types";

export const createOpenModalAction = (meta?: object): AnyAction => ({
  type: ModalActionTypes.OPEN_MODAL,
  meta,
});

export const createOpenGenericModalAction = (): AnyAction => ({
  type: ModalActionTypes.OPEN_MODAL,
  meta: { name: ModalNames.MODAL_GENERIC_ERROR },
});

export const createCloseModalAction = (): Action => ({
  type: ModalActionTypes.CLOSE_MODAL,
});

export const createCurrentlyVisibleModalAction = (name: string): ModalOpenAction => ({
  type: ModalActionTypes.CURRENTLY_VISIBLE_MODAL,
  name,
});

export const createCloseCurrentlyVisibleModalAction = (): Action => ({
  type: ModalActionTypes.CLOSE_CURRENTLY_VISIBLE_MODAL,
});
