import { ProductType } from "../services/product.types";
import { currencyFormatter } from "../components/ProductTile/ProductTile.selectors";
import { ProductOption, ProductTileProduct, ProductTilePromotion } from "../components/ProductTile/productTile.types";
import { simplePromotionMechanicIds } from "../common/constants";
import { PromoMechanicIds } from "../common/types";
import { YourNectarPrice } from "../domain/product";

export type GetOriginalPriceParams = {
  promotions: ProductTilePromotion[] | undefined;
  productType: ProductType;
  productTileProduct: ProductTileProduct;
  selectedDropdownOption: ProductOption;
  yourNectarPrice: YourNectarPrice | undefined;
};

export const getOriginalPrice = ({
  promotions,
  productType,
  productTileProduct,
  selectedDropdownOption,
  yourNectarPrice,
}: GetOriginalPriceParams) => {
  if (yourNectarPrice) return currencyFormatter(yourNectarPrice.withoutNectarPrice);
  if (!promotions) return undefined;

  const unformattedOriginalPrice = promotions.find(
    ({ promoMechanicId }) => promoMechanicId && simplePromotionMechanicIds.includes(promoMechanicId as PromoMechanicIds)
  )?.originalPrice;

  if (unformattedOriginalPrice === 0) return productTileProduct.retailPrice?.price;

  if (!unformattedOriginalPrice) return undefined;

  const originalPrice = currencyFormatter(unformattedOriginalPrice);

  if (originalPrice && productType === ProductType.CATCHWEIGHT) {
    const wasPrice = selectedDropdownOption.retailPrice?.wasPrice;

    // We already call currencyFormatter on the dropdown option retail price values, so no need to do so here.
    return wasPrice ? wasPrice : selectedDropdownOption.retailPrice?.price;
  }

  return originalPrice;
};
