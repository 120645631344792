import moment from "moment";
import { SlotReservation } from "../domain/slot/slotReservation.types";

export const formatReservedUntil = (slotReservation: SlotReservation): string => {
  const { reservationUntil } = slotReservation;
  if (reservationUntil) {
    return `${moment(reservationUntil).tz("Europe/London").calendar({
      sameDay: "HH:mma [today]",
      nextDay: "HH:mma [tomorrow]",
      sameElse: "HH:mma [on] DD/MMMM/YYYY",
    })}`;
  }
  return "";
};
