import { urls } from "../../../routes";

export const ADD_NECTAR_LINK = urls.MY_NECTAR_CARD;
export const useNectarSignUpLinks = () => {
  const addNectarLink = urls.MY_NECTAR_CARD;
  const loginRedirectLink = `${urls.OAUTH_LOGIN}?redirectUri=${encodeURIComponent(
    `${window.location.origin}${addNectarLink}`
  )}`;

  return { addNectarLink, loginRedirectLink };
};
