import { Action } from "redux";
import {
  ReserveSlotFailureAction,
  ReserveSlotRequestAction,
  ReserveSlotState,
  ReserveSlotSuccessAction,
  SlotReservationActions,
} from "./slotReservation.types";
import { CloseCurrentlyVisibleModalAction, ModalActionTypes } from "./Modal/Modal.types";
import { DataState } from "../../common/dataState";
import { ResponseErrorActionType } from "../../common/types";

export const bookReserveSlotInitialState: ReserveSlotState = {
  dataState: DataState.UNKNOWN,
};

type ActionTypes = ReserveSlotRequestAction | ReserveSlotSuccessAction | ReserveSlotFailureAction | Action;

export const bookSlotReservationReducer = (
  state: ReserveSlotState = bookReserveSlotInitialState,
  action: ActionTypes
): ReserveSlotState => {
  switch (action.type) {
    case SlotReservationActions.RESERVE_SLOT_REQUEST: {
      return {
        ...state,
        dataState: DataState.PENDING,
      };
    }

    case SlotReservationActions.RESERVE_SLOT_SUCCESS: {
      return {
        ...state,
        dataState: DataState.SUCCESS,
      };
    }

    case SlotReservationActions.RESERVE_SLOT_FAILURE: {
      const { message } = action as ReserveSlotFailureAction;
      return {
        ...state,
        dataState: DataState.FAILED,
        failureMessage: message,
      };
    }

    case ModalActionTypes.CLOSE_CURRENTLY_VISIBLE_MODAL: {
      const { name } = action as CloseCurrentlyVisibleModalAction;
      if (name === "reserveSlot") {
        return {
          ...state,
          dataState: DataState.UNKNOWN,
        };
      }
      return {
        ...state,
      };
    }

    case ResponseErrorActionType.HTTP_UNAUTHORIZED_ERROR:
      return bookReserveSlotInitialState;

    default:
      return state;
  }
};
