import { Action } from "redux";

export interface LocationState {
  current: string;
  referrer: string;
}

export function locationReducer(
  state: LocationState | undefined = {
    current: "",
    referrer: "",
  },
  action: Action
): LocationState {
  return state;
}
