import { SearchAnalyticsType } from "../../common/dataLayer/types";
import { Dispatch } from "redux";
import { ContentBannerLocation, ContentPage } from "../../services/content";
import { InterleavedBannerAd } from "../../components/Citrus/BannerAd/BannerAd.types";
import { InterleavedEspot } from "../../components/Espots/Espot.types";
import { mapControls, mapProduct, mapProducts } from "./../../domain/product/product";
import {
  FetchResultsParams,
  searchFilterKeys,
  SearchProducts,
  SearchResultsArgs,
} from "./SearchResultsFindability.types";
import {
  Controls,
  ProductAd,
  ResponseFilter,
  ResponseFilterValue,
  SearchResponse,
  Sort,
  SuggestedSearchTerm,
} from "../../services/product.types";
import { AdMetaData, createAdImpressionAction, createAdsTrackingDataAction } from "../../common/ads";
import {
  AssociationType,
  FilterProduct,
  FilterProductType,
  FilterProductValue,
  Product,
  ProductSource,
  RelevancySplit,
} from "../../domain/product/product.types";
import { AdobeDigitalDataProperties } from "../../common/analytics/types";
import { isBrowseRoute } from "../Browse/Browse.utils";
import { isEventsRoute, isFeaturesRoute } from "../EventsFeatures/EventsFeatures.utils";

export const setFindabilityBannerAds = (sequence: number): InterleavedBannerAd[] => {
  return [
    {
      location: ContentBannerLocation.IN_GRID,
      page: ContentPage.SEARCH,
      categoriesPosition: { insertSequence: sequence, rowIndex: 0 },
      insertSequence: sequence,
    },
  ];
};

export const setFindabilityEspots = (sequence: number, searchTerm: string): InterleavedEspot[] => {
  return [
    {
      available: true,
      categoriesPosition: { rowIndex: 0, insertSequence: sequence },
      espotParams: { name: "NZ_Search_Responsive_Grid_1_By_SearchTerm", search_term: searchTerm },
      insertSequence: sequence,
    },
  ];
};

export const createSearchResultsParams = ({
  term,
  category,
  slot,
  sort,
  pageNumber,
  brand,
  dietary,
  other,
  offer,
  promotion,
  isSlotLocked,
  salesWindowVariant,
}: FetchResultsParams): SearchResultsArgs => {
  const params: SearchResultsArgs = { term, sort, pageNumber, salesWindowVariant };

  // TODO: improve the way we are adding the values into params
  if (category) {
    params.filters = { cat_Category: category };
  }

  if (brand) {
    params.filters = { ...params.filters, [searchFilterKeys.BRAND]: brand };
  }

  if (dietary) {
    params.filters = { ...params.filters, [searchFilterKeys.DIETARY]: dietary };
  }

  if (other) {
    params.filters = { ...params.filters, [searchFilterKeys.OTHER]: other };
  }

  if (offer) {
    params.filters = { ...params.filters, [searchFilterKeys.OFFER]: offer };
  }

  if (promotion) {
    params.filters = { ...params.filters, [searchFilterKeys.PROMOTION]: promotion };
  }

  params.isSlotLocked = isSlotLocked;

  if (slot) {
    params.storeIdentifier = slot.storeIdentifier;
    params.slotDate = slot.slotDate;
    params.slotDateTime = slot.slotDateTime;
    params.slotType = slot.reservationType;
    params.flexiStores = slot.flexiStores;
    params.region = slot.region;
  }
  return params;
};

export const mapSearchProducts = (
  source: ProductSource,
  servicesProducts: SearchResponse,
  dispatch: Dispatch
): SearchProducts => {
  const { products, relevancyProducts } = mergeAllProducts(source, servicesProducts, dispatch);
  return {
    products,
    relevancyProducts,
    controls: mapControls(servicesProducts.controls),
    errors: servicesProducts.errors,
    suggestedTerm: servicesProducts.suggested_search_terms,
  };
};

export const isCategoryMatched = (servicesProducts: SearchResponse): boolean => {
  // Check if there is a category Match
  const categories =
    servicesProducts.controls && servicesProducts.controls.cat_filters ? servicesProducts.controls.cat_filters : [];
  let currentCategories = categories[0];
  let isCatMatch = false;
  while (currentCategories && currentCategories.children && !isCatMatch) {
    isCatMatch = currentCategories.children[0].selected ? currentCategories.children[0].selected : false;
    currentCategories = currentCategories.children[0];
  }
  return isCatMatch;
};

export const prepareSpotlightPositionForEvents = (len: number): number[] => {
  const sponsoredPositions = [3, 4, 5, 6, 11, 12, 13, 14];
  let nextPosition = 18;

  while (sponsoredPositions.length < len) {
    sponsoredPositions.push(nextPosition);
    nextPosition += 4;
  }
  return sponsoredPositions;
};

export function mergeAllProducts(
  source: ProductSource,
  serviceResponse: SearchResponse,
  dispatch: Dispatch
): { products: Product[]; relevancyProducts: RelevancySplit } {
  let products = mapProducts(source, serviceResponse.products);

  const AddSpotlightProducts = () => {
    // citrus products in the specific position passed
    if (serviceResponse.ads && serviceResponse.ads.sponsored_products) {
      const position =
        (isBrowseRoute(window.location.pathname) ||
          isEventsRoute(window.location.pathname) ||
          isFeaturesRoute(window.location.pathname)) &&
        serviceResponse.ads.sponsored_products.spotlight_product_ads.length > 3
          ? prepareSpotlightPositionForEvents(serviceResponse.ads.sponsored_products.spotlight_product_ads.length)
          : undefined;
      products = mapSpotlightProducts(
        ProductSource.CITRUS_SEARCH_ADS_IN_GRID,
        dispatch,
        products,
        serviceResponse.ads.sponsored_products.spotlight_product_ads,
        position
      );
    }
  };

  const addSponsoredProducts = () => {
    // featured product at the beginning of the row
    if (serviceResponse.spotlight_products) {
      products = [...mapProducts(source, serviceResponse.spotlight_products), ...products];
    }
  };

  addSponsoredProducts();
  AddSpotlightProducts();

  return {
    products,
    relevancyProducts: {
      relevant: products.filter(prod => prod.relevancyRank === 1),
      related: products.filter(prod => prod.relevancyRank === 2),
    },
  };
}

export function mapSpotlightProducts(
  source: ProductSource,
  dispatch: Dispatch,
  products: Product[],
  spotlightProducts: ProductAd[] = [],
  positions: number[] = [1, 2, 3, 6, 9]
): Product[] {
  let positionsFilled = 0;
  let allProducts = products;

  positions.forEach((position, i) => {
    if (positionsFilled >= spotlightProducts.length) {
      return;
    }
    const ad = spotlightProducts[positionsFilled];
    if (ad.ad_id) {
      dispatch(createAdImpressionAction(ad.ad_id));
      const adMeta: AdMetaData[] = [{ id: ad.ad_id, type: ad.type, productUid: ad.product.product_uid }];
      dispatch(createAdsTrackingDataAction(adMeta));
    }

    const product = mapProduct(ad.product, 0, source, AssociationType.NONE);
    allProducts = [...allProducts.slice(0, position - 1), product, ...allProducts.slice(position - 1)];

    positionsFilled++;
  });

  return allProducts;
}
export interface FindabilityFilterProduct {
  [key: string]: FilterProduct;
}

export interface FilterProductWithSelectedCount {
  filters: FindabilityFilterProduct;
  selectedCount: number;
}

export const replacementLabels: { [key: string]: string } = {
  "nav_Filter-Your-Results": "Options",
  diet_Dietary_and_Lifestyle_Options: "Dietary",
  subcat_SubCategory: "Subcategory",
  nav_Brand: "Brand",
  cat_Category: "Category",
  other: "Other",
};

export const mapSearchFilters = (originalFilters: FilterProduct[]): FilterProductWithSelectedCount => {
  const selectedCount = getFiltersSelectedCount(originalFilters);
  const isNotAisleOrShelf = (filter: FilterProduct) => filter.key !== "aisle_Aisle" && filter.key !== "shelf_Shelf";
  const isNotMultiSelectWithEnabledOptions = (filter: FilterProduct) =>
    filter.type !== FilterProductType.MULTI_SELECT || filter.values.every(value => value.enabled);

  const modifiedFilters = originalFilters.filter(isNotAisleOrShelf).filter(isNotMultiSelectWithEnabledOptions);

  const filters: FindabilityFilterProduct = modifiedFilters.reduce((obj, item) => {
    const key = (replacementLabels[item.key] || item.key).toLowerCase();
    return {
      ...obj,
      [key]: {
        ...item,
        label: replacementLabels[item.key] || item.label,
      },
    };
  }, {});

  const offerFilter: FilterProductValue[] =
    filters.options && filters.options.values
      ? filters.options.values.filter((item: FilterProductValue) => item.id === "Offers")
      : [];

  return offerFilter.length > 0
    ? { filters: { ...filters, offers: { ...filters.options, unwrapped: true, values: offerFilter } }, selectedCount }
    : { filters, selectedCount };
};

export const getFiltersSelectedCount = (filters: FilterProduct[]): number => {
  let sum = 0;

  if (Array.isArray(filters) && filters.length) {
    filters.forEach(e => {
      e.values.forEach(el => {
        if (el.selected) {
          sum += 1;
        }
      });
    });
  }

  return sum;
};

export const mapFindabilitySearchForAnalytics = (
  searchTerm: string,
  count: number,
  suggestedSearchTerms?: SuggestedSearchTerm[]
): SearchAnalyticsType => {
  return {
    originalSearchTerm: searchTerm,
    onsiteSearchResults: count.toString(),
    onsiteSearchTerm:
      suggestedSearchTerms && suggestedSearchTerms[0].type === "AUTOCORRECT"
        ? suggestedSearchTerms[0].term
        : searchTerm,
  };
};

export const getPromotionalTileCounts = (productData: SearchResponse) => {
  const sponsored =
    productData && productData.ads && productData.ads.sponsored_products
      ? productData.ads.sponsored_products.spotlight_product_ads.length
      : 0;
  const featured = productData && productData.spotlight_products ? productData.spotlight_products.length : 0;

  return `Featured~${featured} Sponsored~${sponsored}`;
};

export const getCategoryHierarchyPayload = (term: string, data: SearchResponse) => {
  if (data.controls.cat_filters && data.controls.cat_filters.length > 0 && data.controls.cat_filters[0].children) {
    let catFilter = data.controls.cat_filters[0].children;
    let payload = "";
    while (!catFilter[0].selected && catFilter[0].children) {
      if (catFilter[0].name && catFilter[0].name.toLowerCase() !== term.toLowerCase()) {
        payload += `${catFilter[0].name}:`;
      }
      catFilter = catFilter[0].children;
    }
    payload = payload + catFilter[0].name;
    return payload;
  }
  return undefined;
};

const getLabel = (label: string): string => {
  let parsedLabel = label.split(" ")[0].toLowerCase();
  if (parsedLabel === "filter") {
    parsedLabel = "";
  }
  return parsedLabel;
};

const getValues = (values: ResponseFilterValue[]): string => {
  return values
    .filter(val => val.selected)
    .map(val => val.value)
    .join("|");
};

const parseFilters = (filters: ResponseFilter[], isCategory: boolean = false): string => {
  if (!filters || filters.length === 0) {
    return "";
  }

  const activeFilters = filters.filter(item => {
    if (isCategory) {
      return item.label === "Category" || item.label === "SubCategory";
    } else {
      return item.label !== "Category" && item.label !== "SubCategory";
    }
  });

  const filterString = activeFilters
    .map(filter => {
      const label = getLabel(filter.label);
      const values = getValues(filter.values);

      if (values) {
        if (!label) {
          return values;
        } else {
          return `${label}:${values}`;
        }
      } else {
        return null;
      }
    })
    .filter((val: any) => val)
    .join(",")
    .toLowerCase();

  return filterString;
};

const parseSort = (sort: Sort): string => {
  let display = "";
  const active = sort.active;
  const activeOption = sort.options.filter((option: any) => option.value === active);

  if (activeOption && activeOption[0] && activeOption[0].display) {
    display = activeOption[0].display.replace(" - ", ":").toLowerCase();
  }

  return display;
};

const getTypedTerm = (searchTerm: string, redirectTerm?: string | null, isMultiSearch?: boolean): string => {
  if (redirectTerm) {
    return redirectTerm;
  }

  if (isMultiSearch) {
    return searchTerm;
  }

  return (window as any).digitalData.data_search_typedTerm;
};

export const createSearchDigitalProperties = (
  searchTerm: string,
  correctedTerm: string | undefined,
  controls: Controls,
  redirectTerm?: string | null,
  isBackNavigation?: boolean,
  isMultiSearch?: boolean,
  isTrending?: boolean,
  isTrendingOrganic?: boolean
): AdobeDigitalDataProperties => {
  const { page, filters, returned_record_count, total_record_count, sort } = controls;
  const activeFilters = parseFilters(filters);
  const activeSort = parseSort(sort);
  const categories = parseFilters(filters, true);
  const typedTerm = getTypedTerm(searchTerm, redirectTerm, isMultiSearch);
  const isCorrected = correctedTerm !== undefined && correctedTerm.length > 0;
  const isSuggested = !isCorrected && typedTerm !== undefined && searchTerm !== typedTerm && !isBackNavigation;
  const isRedirected = redirectTerm !== undefined && redirectTerm !== null;
  let dataMethod = "manual";

  if (isSuggested) {
    dataMethod = "suggested";
  }

  if (isCorrected) {
    dataMethod = "corrected";
  }

  if (isRedirected) {
    dataMethod = "search redirect";
  }

  if (isMultiSearch) {
    dataMethod = "multi search";
  }

  if (isTrending) {
    dataMethod = isTrendingOrganic ? "trending: organic" : "trending";
  }

  return {
    data_search_term: isCorrected ? correctedTerm : searchTerm,
    data_search_method: dataMethod,
    data_page_number: page.active,
    data_productList_categories: categories,
    data_productList_filters: activeFilters,
    data_productList_numberOfProducts: total_record_count,
    data_productList_numberOfProductsShown: returned_record_count,
    data_siteSection: "search results",
    data_sortBy: activeSort,
    data_page_type: "search",
    data_search_typedTerm: isBackNavigation ? searchTerm : typedTerm || searchTerm,
  };
};

export const fetchSearchIngridAdPositions = (screenWidth: number, ingridAdsLength: number) => {
  if (screenWidth < 960 && screenWidth >= 720) {
    return [2, 6];
  }

  if (screenWidth < 1400 && screenWidth >= 960) {
    return [1, ingridAdsLength > 1 ? 5 : 7];
  }

  return [2, ingridAdsLength > 1 ? 7 : 8];
};

export const fetchSearchIngridAdPositionsOptimised = (screenWidth: number, ingridAdsLength: number) => {
  if (screenWidth < 960 && screenWidth >= 720) {
    return [1, 3];
  }

  if (screenWidth < 1400 && screenWidth >= 960) {
    return [2, ingridAdsLength > 1 ? 7 : 10];
  }

  return [3, ingridAdsLength > 1 ? 9 : 13];
};
