import { useCallback } from "react";

export const useDigitalDataEventTracking = () => {
  // Step 1 : when called, push data to digital data
  const addToDigitalData = useCallback((data: object) => {
    (window as any).digitalData = {
      ...(window as any).digitalData,
      ...data,
    };
  }, []);

  return {
    addToDigitalData,
  };
};
