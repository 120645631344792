import { Action, Dispatch } from "redux";
import {
  mapAssociationType,
  mapPaginatedProducts,
  mapProduct,
  mapProducts as mapFirstFavouritesProducts,
  removeUnavailableProducts,
  removeUnwantedProducts,
} from "../../../domain/product/product";
import { State } from "../../../common/store";
import { clearBasketErrorActionCreator } from "../../../domain/basket/basket.actions";
import { externalUrls, urls } from "../../../routes";
import { createExternalNavigation, createExternalNavigationWindow } from "../../../components/RedirectExternal";
import { handleUnauthorized } from "../../../common/responseErrorHandler";
import {
  DeleteFavouritesSuccessAction,
  FavouriteDisableFirstInteractionType,
  FavouriteDisableModalActionCreatorType,
  FavouriteDisableModalFirstInteractionAction,
  FavouritesActionTypes,
  FavouritesDataPendingAction,
  FavouritesSeasonalDataPendingAction,
  FavouritesLastSeenProductAction,
  FavouritesOffersOnlyActionType,
  FavouritesSort,
  FavouritesStartShoppingActionType,
  FetchFavouritesSuccessAction,
  FetchFavouritesSuccessWithErrorsAction,
  FetchSeasonalFavouritesSuccessAction,
  FetchSeasonalFavouritesSuccessWithErrorsAction,
  fetchRecommendationsFailureAction,
  fetchRecommendationsPendingAction,
  fetchFirstFavouritesSuccessAction,
  fetchFavouritesSuccessWithErrorsFallbackAction,
  SuccessfulSaveFavouriteAction,
  ErrorSaveFavouriteAction,
  FavouritesErrorActionTypes,
  ClearFavouritesErrorsAction,
  SaveFavouriteProductErrorAction,
  DeleteFavouriteProductErrorAction,
  FavouritesDeleteFromProductsAction,
  FavouritesDeleteAction,
  PendingSaveFavouriteAction,
  FavouritesAction,
  TrackAddFavouriteClickAction,
  TrackRemoveFavouriteClickAction,
  FavouriteSourceForAnalyticsTypes,
  TrackSortFavouritesAction,
  TrackFeatureTourCloseAction,
  TrackFeatureTourStartAction,
  TrackFeatureTourNextAction,
} from "@favourites/Favourites.types";
import { favouritesChangedProductControlsActionCreator } from "../../../components/ProductControls/productControls.actions";
import { createOpenModalAction } from "../../../components/Modal/modal.actions";
import { FavouritesPageViewAction, NoFavouritesPageViewAction } from "../../../common/analytics/analytics";
import { AdobeDigitalDataProperties, AnalyticsActionTypes } from "../../../common/analytics/types";
import { ModalNames } from "../../../components/Modal/Modal.types";
import { Result } from "../../../common/http/request";
import { serviceWorkerClient } from "../../../common/serviceWorkerClient";
import { AdMetaData, createAdImpressionAction, createAdsTrackingDataAction } from "../../../common/ads";
import { AppThunkAction, AppThunkDispatch } from "../../../common/types";
import { fetchDietaryWarnings } from "../../../components/DietaryProfile/DietaryWarning/dietaryWarning.actions";
import {
  AlternativeProduct,
  FavouritesResponse,
  Product as ServicesProduct,
  ProductAd,
  ProductAdType,
  ProductQueryParameters,
  ProductServiceError,
  Sort,
  SortOption,
} from "../../../services/product.types";
import { deleteFavourites, fetchFavourites, fetchFirstFavourites, saveFavourite } from "../../../services/product";
import {
  getProductEANs,
  PaginatedProducts,
  Product,
  ProductHeader,
  ProductSource,
  ProductsViews,
} from "../../../domain/product/product.types";
import { getCachedSlotReservation } from "../../../domain/slot/slotReservation";
import { BasketErrorKeys } from "../../../domain/basket/basket.types";
import { useFeatureFlag } from "../../../common/firebase/featureFlags.hooks";
import { selectHasNectarAssociated } from "../../../common/nectar";
import { selectIsAuthorized } from "../../../components/Authorized";
import { createInternalNavigationAction } from "../../../common/middleware/navigationMiddleware";
import { CtaTypes, TourJourneyTypes } from "../FeatureTour.types";
import { AdobePageName, AdobePageType, generateAdobeAnalyticsPageProperties } from "../../../utils";
import { mapFavouriteSource } from "../../../common/dataLayer/basket";
import { FavouritesSortByType } from "../../../common/dataLayer/types";
import { selectIsSeasonalFavourite } from "@favourites/state/Favourites.selectors";
import { sendFavouritesDeleteEvent, sendFavouritesSeasonalDeleteEvent } from "../../../common/dataLayer/favourites";
import { IngridAd } from "../../../components/IngridAd/IngridAd.type";

export const clearProductCoordMapAction = (): FavouritesAction => ({
  type: FavouritesActionTypes.CLEAR_PRODUCT_COORD_MAP,
});

export const setLastSeenFavourite = (product: string): FavouritesLastSeenProductAction => ({
  type: FavouritesActionTypes.SET_LAST_SEEN_FAVOURITE,
  product,
});

export const resetTourStepAction = (): FavouritesAction => ({
  type: FavouritesActionTypes.RESET_TOUR_STEP,
});

export const increaseTourStepAction = (): FavouritesAction => ({
  type: FavouritesActionTypes.INCREASE_TOUR_STEP,
});

export const hideTourAction = (): FavouritesAction => ({
  type: FavouritesActionTypes.HIDE_FEATURE_TOUR,
});

export const showTourAction = (): FavouritesAction => ({
  type: FavouritesActionTypes.SHOW_FEATURE_TOUR,
});

export const removeLastSeenFavouriteAction = (): FavouritesAction => ({
  type: FavouritesActionTypes.REMOVE_LAST_SEEN_FAVOURITE,
});

export const setFavouritesIdleAction = (): FavouritesAction => ({
  type: FavouritesActionTypes.SET_FAVOURITES_IDLE,
});

export const favouritesFetchingAction = (
  controls: FavouritesDataPendingAction["controls"]
): FavouritesDataPendingAction => ({
  type: FavouritesActionTypes.FETCH_FAVOURITES_PENDING,
  controls,
});

export const noFavouritesStartShoppingAction = (): FavouritesStartShoppingActionType => ({
  type: FavouritesActionTypes.START_SHOPPING_ANALYTICS_EVENT,
});

export const favouritesCheckOffersOnlyAction = (isOffersOnly: boolean): FavouritesOffersOnlyActionType => {
  return {
    type: FavouritesActionTypes.CHECK_OFFERS_ONLY,
    isOffersOnly,
  };
};

export const favouritesErrorAction = (error: string) => {
  return {
    type: FavouritesActionTypes.FETCH_FAVOURITES_ERROR,
    message: error,
  };
};

export const favouritesDeleteErrorAction = (error: string) => {
  return {
    type: FavouritesActionTypes.DELETE_FAVOURITES_ERROR,
    message: error,
  };
};

export const favouritesDeleteSelectedItemAction = (location: string, favouriteProductUid: string) => {
  return {
    type: FavouritesActionTypes.UPDATE_DELETE_FAVOURITES_SELECTED_ITEM,
    favouritesDeleteSelectedItem: location,
    favouriteProductUid,
  };
};

export const favouritesSuccessAction = (products: PaginatedProducts): FetchFavouritesSuccessAction => ({
  type: FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS,
  products,
});

export const favouritesSuccessWithErrorsAction = (
  errors: ProductServiceError[]
): FetchFavouritesSuccessWithErrorsAction => ({
  type: FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS_WITH_ERRORS,
  errors,
});

export const seasonalFavouritesFetchingAction = (): FavouritesSeasonalDataPendingAction => ({
  type: FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_PENDING,
});

export const seasonalFavouritesSuccessAction = (products: PaginatedProducts): FetchSeasonalFavouritesSuccessAction => ({
  type: FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_SUCCESS,
  products,
});

export const seasonalFavouritesSuccessWithErrorsAction = (
  errors: ProductServiceError[]
): FetchSeasonalFavouritesSuccessWithErrorsAction => ({
  type: FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_SUCCESS_WITH_ERRORS,
  errors,
});

export const seasonalFavouritesErrorAction = (error: string) => {
  return {
    type: FavouritesActionTypes.FETCH_SEASONAL_FAVOURITES_ERROR,
    message: error,
  };
};

export const disableFavouritesModalAction: FavouriteDisableModalActionCreatorType = () => ({
  type: FavouritesActionTypes.DISABLE_FAVOURITES_MODAL,
  isFavouritesModalDisabled: true,
});

export const favouritesPageViewAction = (adobeProperties: AdobeDigitalDataProperties): FavouritesPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "favouritesSingleList",
    template: "favouriteslist",
    section: "favouritesSingleList",
    newTemplate: "favouriteslist",
  },
  adobeProperties,
});

export const noFavouritesPageViewAction = (): NoFavouritesPageViewAction => ({
  type: AnalyticsActionTypes.PAGE_VIEW,
  page: {
    name: "firstFavouritesSingleList",
    template: "firstFavouriteslist",
    section: "firstFavouriteslist",
    newTemplate: "firstFavouritesSingleList",
  },
});

export const fetchFirstFavouritesPendingAction = (): fetchRecommendationsPendingAction => ({
  type: FavouritesActionTypes.FETCH_FIRST_FAVOURITES_PENDING,
});

export const fetchFirstFavouritesSuccess = (
  products: Object,
  serviceProducts: ServicesProduct[]
): fetchFirstFavouritesSuccessAction => ({
  type: FavouritesActionTypes.FETCH_FIRST_FAVOURITES_SUCCESS,
  products,
  serviceProducts,
});

export const fetchFavouritesSuccessWithErrorsFallback = (
  products: Object,
  serviceProducts: ServicesProduct[],
  message: string
): fetchFavouritesSuccessWithErrorsFallbackAction => ({
  type: FavouritesActionTypes.FETCH_FAVOURITES_SUCCESS_WITH_ERRORS_FALLBACK,
  products,
  serviceProducts,
  message,
});

export const fetchFirstFavouritesFailureAction = (): fetchRecommendationsFailureAction => ({
  type: FavouritesActionTypes.FETCH_FIRST_FAVOURITES_FAILURE,
});

export const updateFirstFavouritesRecommendationsSuccess = () => ({
  type: FavouritesActionTypes.UPDATE_RECOMMENDATIONS_SUCCESS,
});

export const updateFirstFavouritesRecommendations = (products: Object) => ({
  type: FavouritesActionTypes.UPDATE_RECOMMENDATIONS,
  products,
});

export const fetchFirstFavouritesThunk =
  (): AppThunkAction =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(fetchFirstFavouritesPendingAction());

    const { storeIdentifier, slotDate, slotDateTime } = getCachedSlotReservation() || {};
    const result = await fetchFirstFavourites({
      store_identifier: storeIdentifier,
      slot_date: slotDate,
      slot_datetime: slotDateTime,
    });

    dispatch(handleUnauthorized(result));

    if (result.isSuccess()) {
      dispatch(noFavouritesPageViewAction());

      const { data: { products = [] } = {} } = result;

      const splicedProducts = filterRecommendationProducts(ProductSource.FIRST_FAVOURITES, products);
      dispatch(fetchFirstFavouritesSuccess(splicedProducts, products));
    } else {
      dispatch(fetchFirstFavouritesFailureAction());
    }
  };

export const favouritesErrorThunk =
  (message: string): AppThunkAction =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(fetchFirstFavouritesPendingAction());

    const { storeIdentifier, slotDate, slotDateTime } = getCachedSlotReservation() || {};
    const result = await fetchFirstFavourites({
      store_identifier: storeIdentifier,
      slot_date: slotDate,
      slot_datetime: slotDateTime,
    });

    dispatch(handleUnauthorized(result));

    if (result.isSuccess()) {
      dispatch(noFavouritesPageViewAction());

      const { data: { products = [] } = {} } = result;

      const splicedProducts = filterRecommendationProducts(ProductSource.FIRST_FAVOURITES, products);

      dispatch(fetchFavouritesSuccessWithErrorsFallback(splicedProducts, products, message));
    } else {
      dispatch(favouritesErrorAction(message));
    }
  };

export const fetchSeasonalFavouritesThunk =
  (queryParameters: ProductQueryParameters): AppThunkAction =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    const { storeIdentifier, slotDate, slotDateTime, flexiStores, reservationType } = getCachedSlotReservation() || {};
    const result = await fetchFavourites(
      {
        storeIdentifier,
        slotDate,
        slotDateTime,
        flexiStores,
        pageSize: 20,
        slotType: reservationType,
        isSlotLocked: Boolean(queryParameters.isSlotLocked),
        compareSeasonal: true,
      },
      "seasonal",
      useFeatureFlag("get_favourites_from_v2")
    );

    dispatch(handleUnauthorized(result));

    if (result.isSuccess()) {
      const products = mapProducts(dispatch, ProductSource.SEASONAL_FAVOURITE, result.data);
      dispatch(seasonalFavouritesSuccessAction(products));
      await dispatch(fetchDietaryWarnings(getProductEANs(products.products)));

      if (result.data.errors) {
        dispatch(seasonalFavouritesSuccessWithErrorsAction(result.data.errors));
      }
    } else {
      dispatch(seasonalFavouritesErrorAction(result.errors.first().detail));
    }
  };

/** @deprecated to be removed after add to favourites release and replaced by `newFetchFavouritesThunk` */
export const fetchFavouritesThunk =
  (queryParameters: ProductQueryParameters): AppThunkAction =>
  async (dispatch: AppThunkDispatch, getState: () => State): Promise<void> => {
    const state = getState();
    dispatch(
      favouritesFetchingAction({
        sort: queryParameters.sort,
        filters: queryParameters.filters,
        pageSize: Number(queryParameters.pageSize) || undefined,
        pageNumber: Number(queryParameters.pageNumber) || undefined,
      })
    );

    const { storeIdentifier, slotDate, slotDateTime, flexiStores, reservationType } = getCachedSlotReservation() || {};
    const isModalEnable = useFeatureFlag("alternatives_modal");
    // feature flag enabled and nectar not associated
    const isNewEndpoint =
      useFeatureFlag("get_wcs_favourites_from_v2") && !selectHasNectarAssociated(state) ? true : false;

    const result = await fetchFavourites(
      {
        sort: queryParameters.sort,
        filters: queryParameters.filters,
        pageSize: Number(queryParameters.pageSize),
        pageNumber: Number(queryParameters.pageNumber),
        includes: ["ASSOCIATIONS"],
        storeIdentifier,
        slotDate,
        slotDateTime,
        slotType: reservationType,
        isSlotLocked: Boolean(queryParameters.isSlotLocked),
        flexiStores,
      },
      "default",
      isNewEndpoint
    );

    dispatch(handleUnauthorized(result));

    if (result.isSuccess()) {
      if (result.data.products.length !== 0) {
        const { data_page_name, data_siteSection, data_shoppingMode } = generateAdobeAnalyticsPageProperties({
          basket: state.basket.basketDetails,
          pageName: AdobePageName.FAVOURITES,
          pageType: AdobePageType.GROCERIES,
        });

        dispatch(favouritesPageViewAction({ data_page_name, data_siteSection, data_shoppingMode }));
      }

      const products = mapProducts(dispatch, ProductSource.FAVOURITE, result.data, isModalEnable);
      dispatch(favouritesSuccessAction(products));
      await dispatch(fetchDietaryWarnings(getProductEANs(products.products)));

      if (result.data.errors) {
        dispatch(favouritesSuccessWithErrorsAction(result.data.errors));
      }

      const isOffersPresent = checkOffersInResponse(result.data.products);

      if (isOffersPresent) {
        dispatch(favouritesCheckOffersOnlyAction(true));
      }

      if (result.data.products.length === 0) {
        dispatch(fetchFirstFavouritesThunk());
      }
    } else {
      dispatch(favouritesErrorThunk(result.errors.first().detail));
    }

    if (localStorage.hasOwnProperty("disable_favourites_modal")) {
      dispatch(disableFavouritesModalAction());
    }

    if (localStorage.hasOwnProperty("is_favourites_modal_first_interaction")) {
      dispatch(disableFavouritesFirstInteraction());
    }
  };

export const newFetchFavouritesThunk =
  (queryParameters: ProductQueryParameters): AppThunkAction =>
  async (dispatch: AppThunkDispatch, getState: () => State): Promise<void> => {
    const state = getState();
    dispatch(
      favouritesFetchingAction({
        sort: queryParameters.sort,
        filters: queryParameters.filters,
        pageSize: Number(queryParameters.pageSize) || undefined,
        pageNumber: Number(queryParameters.pageNumber) || undefined,
      })
    );

    const { storeIdentifier, slotDate, slotDateTime, flexiStores, reservationType } = getCachedSlotReservation() || {};
    const isModalEnable = useFeatureFlag("alternatives_modal");
    // feature flag enabled and nectar not associated
    const isNewEndpoint =
      useFeatureFlag("get_wcs_favourites_from_v2") && !selectHasNectarAssociated(state) ? true : false;

    let result = await fetchFavourites(
      {
        sort: queryParameters.sort,
        filters: queryParameters.filters,
        pageSize: Number(queryParameters.pageSize),
        pageNumber: Number(queryParameters.pageNumber),
        includes: ["ASSOCIATIONS"],
        storeIdentifier,
        slotDate,
        slotDateTime,
        slotType: reservationType,
        isSlotLocked: Boolean(queryParameters.isSlotLocked),
        flexiStores,
      },
      "default",
      isNewEndpoint
    );

    dispatch(handleUnauthorized(result));

    if (result.isSuccess()) {
      const userHasFavourites = result.data.controls.total_record_count > 0;
      const favouritesOnActivePage = result.data.products.length > 0;

      if (userHasFavourites && !favouritesOnActivePage) {
        result = await retryFetchFavourites(
          dispatch,
          queryParameters,
          result.data.controls.total_record_count,
          storeIdentifier,
          slotDate,
          slotDateTime,
          reservationType,
          flexiStores,
          isNewEndpoint
        );
      }

      if (favouritesOnActivePage) {
        const { data_page_name, data_siteSection, data_shoppingMode } = generateAdobeAnalyticsPageProperties({
          basket: state.basket.basketDetails,
          pageName: AdobePageName.FAVOURITES,
          pageType: AdobePageType.GROCERIES,
        });
        dispatch(favouritesPageViewAction({ data_page_name, data_siteSection, data_shoppingMode }));
      }

      const products = mapProducts(dispatch, ProductSource.FAVOURITE, result.data, isModalEnable);
      dispatch(favouritesSuccessAction(products));
      await dispatch(fetchDietaryWarnings(getProductEANs(products.products)));

      if (result.data.errors) {
        dispatch(favouritesSuccessWithErrorsAction(result.data.errors));
      }

      const isOffersPresent = checkOffersInResponse(result.data.products);

      if (isOffersPresent) {
        dispatch(favouritesCheckOffersOnlyAction(true));
      }

      if (!userHasFavourites) {
        dispatch(fetchFirstFavouritesThunk());
      }
    } else {
      dispatch(favouritesErrorThunk(result.errors.first().detail));
    }

    if (localStorage.hasOwnProperty("disable_favourites_modal")) {
      dispatch(disableFavouritesModalAction());
    }

    if (localStorage.hasOwnProperty("is_favourites_modal_first_interaction")) {
      dispatch(disableFavouritesFirstInteraction());
    }
  };

function mapProducts(
  dispatch: Dispatch,
  source: ProductSource,
  response: FavouritesResponse,
  isModalEnable?: boolean
): PaginatedProducts {
  const paginatedProducts = mapPaginatedProducts(source, response);

  if (response.alternatives) {
    response.alternatives.forEach((alternative: AlternativeProduct) => {
      paginatedProducts.products = isModalEnable
        ? associateAlternatives(paginatedProducts.products, alternative, ProductSource.FAVOURITE, alternative.type)
        : insertAfterParent(paginatedProducts.products, alternative, ProductSource.FAVOURITE, alternative.type);
    });
  }

  if (response.ads) {
    response.ads.sponsored_products.forEach((ad: ProductAd) => {
      if (ad.type === ProductAdType.XSELL) {
        paginatedProducts.products = insertAfterParent(
          paginatedProducts.products,
          ad,
          ProductSource.CROSS_SELL,
          ad.type
        );
      }

      if (ad.type === ProductAdType.CITRUS && ad.ad_id) {
        paginatedProducts.products = insertAfterParent(
          paginatedProducts.products,
          ad,
          ProductSource.CITRUS_XSELL_AD_FAVOURITES,
          ad.type
        );
        dispatch(createAdImpressionAction(ad.ad_id));

        const AdData: AdMetaData[] = [{ id: ad.ad_id, type: ad.type, productUid: ad.product.product_uid }];
        dispatch(createAdsTrackingDataAction(AdData));
      }
    });
  }

  paginatedProducts.products = filterUnavailableProducts(paginatedProducts.products);
  paginatedProducts.products = updatePositionValue(paginatedProducts.products);

  return paginatedProducts;
}

const filterUnavailableProducts = (products: Product[]) => {
  return products.filter((product: Product) => {
    const hasRetailPrice: boolean = (product.retailPrice?.price ?? 0) > 0;
    const hasCatchWeightPrice: boolean = (product.catchweight ?? []).length > 0;
    const hasMultiVariantPrice: boolean = (product.multivariants ?? []).length > 0;
    const hasAlternative: boolean = (product.alternatives ?? []).length > 0;

    return hasRetailPrice || hasCatchWeightPrice || hasMultiVariantPrice || hasAlternative;
  });
};

const associateAlternatives = (
  products: Product[],
  child: ProductAd | AlternativeProduct,
  source: ProductSource,
  associationType: string
): Product[] => {
  const parentIndex = products.findIndex(product => product.productUid === child.parent_product_uid);
  if (parentIndex === -1) {
    return products;
  }

  const childProduct = mapProduct(child.product, 0, source, mapAssociationType(associationType));
  childProduct.associationParentProductUid = products[Number(parentIndex)].productUid;
  products[Number(parentIndex)].productCTA = { type: "REPLACEMENT", text: "See alternatives" } as ProductHeader;

  if (products[Number(parentIndex)].alternatives == null) products[Number(parentIndex)].alternatives = [];

  products[Number(parentIndex)].alternatives!.push(childProduct);
  return products;
};

const insertAfterParent = (
  products: Product[],
  child: ProductAd | AlternativeProduct,
  source: ProductSource,
  associationType: string
): Product[] => {
  //is there a product in the list that already is an alternative
  if (products.findIndex(product => product.associationParentProductUid === child.parent_product_uid) !== -1) {
    return products;
  }

  const parentIndex = products.findIndex(product => product.productUid === child.parent_product_uid);
  if (parentIndex === -1) {
    return products;
  }

  const childProduct = mapProduct(child.product, 0, source, mapAssociationType(associationType));
  childProduct.associationParentProductUid = products[Number(parentIndex)].productUid;
  childProduct.source = source;
  products[Number(parentIndex)].productCTA = childProduct.productCTA;

  products.splice(parentIndex + 1, 0, childProduct);
  return products;
};

const updatePositionValue = (products: Product[]): Product[] => {
  let count = 0;
  return products.map((product: Product) => {
    product.position = ++count;
    return product;
  });
};

/** @deprecated to be removed after add to favourites release and replaced by `favouriteDeleteSuccessAction()` */
export const favouritesDeleteSuccessAction = (): DeleteFavouritesSuccessAction => ({
  type: FavouritesActionTypes.DELETE_FAVOURITES_SUCCESS,
});

/** @deprecated to be removed after add to favourites release */
export const favouritesDeleteFromProductsAction = (productUid: string): FavouritesDeleteFromProductsAction => ({
  type: FavouritesActionTypes.DELETE_FAVOURITES_FROM_PRODUCTS,
  productUid,
});

export const disableFavouritesFirstInteraction: FavouriteDisableFirstInteractionType = () => ({
  type: FavouritesActionTypes.DISABLE_FAVOURITES_FIRST_INTERACTION,
  isFavouritesModalFirstInteraction: false,
});

export const deleteFavouritesFirstInteractionAction = (): FavouriteDisableModalFirstInteractionAction => ({
  type: FavouritesActionTypes.DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_ENABLED,
  isFavouritesModalFirstInteraction: false,
});

export const deleteFavouritesSecondInteractionAction = (): FavouriteDisableModalFirstInteractionAction => ({
  type: FavouritesActionTypes.DELETE_FAVOURITES_FIRST_INTERACTION_MODAL_DISABLED,
  isFavouritesModalFirstInteraction: false,
});

/** @deprecated to be removed after add to favourites release */
export const favouritesDeleteAction = (favouriteProductUid: string): FavouritesDeleteAction => ({
  type: FavouritesActionTypes.DELETE_FAVOURITES_PENDING,
  favouriteProductUid,
});

/** @deprecated to be removed after add to favourites release */
export const pendingSaveFavouriteAction = (productUid: string): PendingSaveFavouriteAction => ({
  type: FavouritesActionTypes.PENDING_SAVE_FAVOURITE,
  productUid,
});

export const successfulSaveFavouriteAction = (): SuccessfulSaveFavouriteAction => ({
  type: FavouritesActionTypes.SUCCESSFUL_SAVE_FAVOURITE,
});

export const errorSaveFavouriteAction = (error: string): ErrorSaveFavouriteAction => {
  return {
    type: FavouritesActionTypes.ERROR_SAVE_FAVOURITE,
    message: error,
  };
};

export const deleteFavouriteProductErrorAction = (favouriteProductUid: string): DeleteFavouriteProductErrorAction => ({
  type: FavouritesActionTypes.FAVOURITE_PRODUCT_ERROR,
  favouriteProductUid,
  status: FavouritesErrorActionTypes.DELETE,
});

export const saveFavouriteProductErrorAction = (favouriteProductUid: string): SaveFavouriteProductErrorAction => ({
  type: FavouritesActionTypes.FAVOURITE_PRODUCT_ERROR,
  favouriteProductUid,
  status: FavouritesErrorActionTypes.SAVE,
});

export const clearFavouritesErrorsAction = (favouriteProductUid: string): ClearFavouritesErrorsAction => ({
  type: FavouritesActionTypes.CLEAR_FAVOURITE_PRODUCT_ERROR,
  favouriteProductUid,
});

export const trackAddFavouriteClick = (product: Product): TrackAddFavouriteClickAction => ({
  type: FavouritesActionTypes.ADD_FAVOURITE_ADOBE_ANALYTICS_REQUEST,
  data_product_id: [product.productUid],
  data_product_favouriteUnitPrice: [product.retailPrice?.price],
  data_product_name: [product.name],
  data_event_category: "Favourites",
  data_event_action: "click",
  data_event_label: "Add favourite",
});

const mapSortBy = {
  [FavouritesSortByType.AISLE]: "Category",
  [FavouritesSortByType.PRICE_ASC]: "Price - Low to High",
  [FavouritesSortByType.PRICE_DESC]: "Price - High to Low",
  [FavouritesSortByType.MANUALLY]: "Saved Items",
  [FavouritesSortByType.FAVOURITES_RANK]: "Regularly bought",
};

const parseSortBy = (label: string) => {
  return mapSortBy[label]?.replace(" - ", ":").toLowerCase();
};

export const trackSortFavourites = (sort: string): TrackSortFavouritesAction => ({
  type: FavouritesActionTypes.SORT_FAVOURITES_ADOBE_ANALYTICS_REQUEST,
  hit_type: "view",
  data_sortBy: parseSortBy(sort),
  data_event_category: "Sort by selection",
  data_event_action: "Sort by interaction",
  data_event_label: mapSortBy[sort],
});

export const saveFavouriteAction =
  (product: Product): AppThunkAction =>
  async (dispatch: Dispatch, getState: () => State) => {
    const { productUid } = product;

    const isUserLoggedIn = selectIsAuthorized(getState());
    const isAddToFavouritesEnabled = useFeatureFlag("add_to_favourites");

    if (!isUserLoggedIn && isAddToFavouritesEnabled) {
      dispatch(createInternalNavigationAction(urls.OAUTH_LOGIN));
      return;
    }

    const response = await saveFavourite(productUid);

    if (response.isSuccess()) {
      dispatch(trackAddFavouriteClick(product));
    } else {
      dispatch(
        createOpenModalAction({
          name: ModalNames.MODAL_SAVE_FAVOURITE_ERROR,
        })
      );
      dispatch(saveFavouriteProductErrorAction(productUid));
    }
  };

export const trackRemoveFavouriteClick = (product: Product): TrackRemoveFavouriteClickAction => {
  const favouriteSource =
    (product.favouriteSource && mapFavouriteSource[product.favouriteSource]) || FavouriteSourceForAnalyticsTypes.NONE;

  return {
    type: FavouritesActionTypes.REMOVE_FAVOURITE_ADOBE_ANALYTICS_REQUEST,
    data_product_id: [product.productUid],
    data_product_favouriteUnitPrice: [product.retailPrice?.price],
    data_product_name: [product.name],
    data_product_favouriteSource: [favouriteSource],
    data_event_category: "Favourites",
    data_event_action: "click",
    data_event_label: "Remove favourite",
  };
};

export const removeFavouriteAction =
  (product: Product, isChecked?: boolean): AppThunkAction =>
  async (dispatch: Dispatch, getState: () => State) => {
    if (isChecked) {
      localStorage.setItem("disable_favourites_modal", "true");
      dispatch(disableFavouritesModalAction());
    }

    const state = getState();

    const {
      favourites: { productCoordMap, isFavouritesModalFirstInteraction },
    } = getState();

    if (isChecked || isFavouritesModalFirstInteraction) {
      dispatch(deleteFavouritesFirstInteractionAction());
      localStorage.setItem("is_favourites_modal_first_interaction", "false");
    }

    if (productCoordMap) {
      productCoordMap.clear();
    }

    await serviceWorkerClient.clearCache();

    const isUserLoggedIn = selectIsAuthorized(getState());
    const isAddToFavouritesEnabled = useFeatureFlag("add_to_favourites");

    if (!isUserLoggedIn && isAddToFavouritesEnabled) {
      dispatch(createInternalNavigationAction(urls.OAUTH_LOGIN));

      return;
    }

    await serviceWorkerClient.clearCache();

    const result = await deleteFavourites(product.productUid);

    if (getState().favourites.isFavouritesModalFirstInteraction) {
      dispatch(deleteFavouritesSecondInteractionAction());
      localStorage.setItem("is_favourites_modal_first_interaction", "false");
    }

    favouriteResponseAction(dispatch, result, product, state);
  };

/** @deprecated to be removed after add to favourites release and replaced by `removeFavouriteAction()` */
export const createFavouriteDeleteAction =
  (isChecked: boolean, product: Product, isFavouritesRoute: boolean): AppThunkAction =>
  async (dispatch: Dispatch, getState: () => State) => {
    if (isChecked) {
      localStorage.setItem("disable_favourites_modal", "true");
      dispatch(disableFavouritesModalAction());
    }

    const {
      products: { views },
      favourites: { favouritesDeleteSelectedItem, favouriteProductUid, isFavouritesModalFirstInteraction },
    } = getState();

    const { products: { views: { favourites = [] } = {} } = {} } = getState();

    const finalPageAndLastProduct = views[ProductsViews.FAVOURITES].length === 1;

    if (isChecked || isFavouritesModalFirstInteraction) {
      dispatch(deleteFavouritesFirstInteractionAction());
      localStorage.setItem("is_favourites_modal_first_interaction", "false");
    }

    const isUserLoggedIn = selectIsAuthorized(getState());
    const isAddToFavouritesEnabled = useFeatureFlag("add_to_favourites");

    if (!isUserLoggedIn && isAddToFavouritesEnabled) {
      dispatch(createInternalNavigationAction(urls.OAUTH_LOGIN));

      return;
    }

    dispatch(favouritesDeleteAction(product.productUid));

    await serviceWorkerClient.clearCache();

    const result = await deleteFavourites(product.productUid);

    deleteFavouriteActionHelper(
      favourites,
      dispatch,
      result,
      finalPageAndLastProduct,
      favouritesDeleteSelectedItem,
      favouriteProductUid,
      isFavouritesRoute,
      product.productUid
    );
  };

/** @deprecated to be removed after add to favourites release and replaced by `removeFavouriteAction()` */
export const oneClickFavouriteDeleteAction =
  (product: Product, location: string, isFavouritesRoute: boolean): AppThunkAction =>
  async (dispatch: Dispatch, getState: () => State) => {
    const {
      products: { views },
    } = getState();

    const { products: { views: { favourites = [] } = {} } = {} } = getState();

    const {
      favourites: { productCoordMap },
    } = getState();

    productCoordMap.clear();

    const finalPageAndLastProduct = views[ProductsViews.FAVOURITES].length === 1;

    await serviceWorkerClient.clearCache();

    const isUserLoggedIn = selectIsAuthorized(getState());
    const isAddToFavouritesEnabled = useFeatureFlag("add_to_favourites");

    if (!isUserLoggedIn && isAddToFavouritesEnabled) {
      dispatch(createInternalNavigationAction(urls.OAUTH_LOGIN));
      return;
    }

    dispatch(favouritesDeleteAction(product.productUid));

    const result = await deleteFavourites(product.productUid);

    if (getState().favourites.isFavouritesModalFirstInteraction) {
      dispatch(deleteFavouritesSecondInteractionAction());
      localStorage.setItem("is_favourites_modal_first_interaction", "false");
    }

    deleteFavouriteActionHelper(
      favourites,
      dispatch,
      result,
      finalPageAndLastProduct,
      location,
      product.productUid,
      isFavouritesRoute,
      product.productUid
    );
  };

/** @deprecated to be removed after add to favourites release and replaced by `favouriteResponseAction()` */
const deleteFavouriteActionHelper = (
  favourites: string[],
  dispatch: AppThunkDispatch,
  result: Result<any>,
  finalPageAndLastProduct: boolean,
  location: string,
  favouriteProductUid: string,
  isFavouritesRoute: boolean,
  uid: string
) => {
  dispatch(handleUnauthorized(result));

  if (result.isSuccess()) {
    serviceWorkerClient.clearCache();
    dispatch(favouritesDeleteFromProductsAction(favouriteProductUid));
    dispatch(favouritesDeleteSuccessAction());
  } else {
    dispatch(favouritesDeleteErrorAction(result.errors.first().detail));
    dispatch(
      createOpenModalAction({
        name: ModalNames.MODAL_REMOVE_FROM_FAVOURITES_ERROR,
      })
    );
    dispatch(deleteFavouriteProductErrorAction(uid));
  }

  if (isFavouritesRoute) {
    const adjacentFavourite = getAdjacentFavourite(favourites, location, favouriteProductUid);

    dispatch(
      favouritesChangedProductControlsActionCreator(
        {
          pageNumber: finalPageAndLastProduct ? 1 : undefined,
        },
        adjacentFavourite
      )
    );
  }
};

const favouriteResponseAction = (dispatch: AppThunkDispatch, result: Result<any>, product: Product, state: State) => {
  dispatch(handleUnauthorized(result));

  if (result.isSuccess()) {
    serviceWorkerClient.clearCache();
    dispatch(trackRemoveFavouriteClick(product));

    if (selectIsSeasonalFavourite(state, product.productUid)) {
      sendFavouritesSeasonalDeleteEvent();
    }
    sendFavouritesDeleteEvent();
  } else {
    dispatch(
      createOpenModalAction({
        name: ModalNames.MODAL_REMOVE_FROM_FAVOURITES_ERROR,
      })
    );
    dispatch(deleteFavouriteProductErrorAction(product.productUid));
  }
};

export const removeUnwantedRecommendationProduct = () => async (dispatch: Dispatch, getState: () => State) => {
  const state = getState();

  const {
    favourites: { serviceProducts },
  } = state;

  const products = filterRecommendationProducts(ProductSource.FIRST_FAVOURITES, serviceProducts);

  dispatch(updateFirstFavouritesRecommendations(products));

  dispatch(updateFirstFavouritesRecommendationsSuccess());
};

const filterRecommendationProducts = (source: ProductSource, serviceProducts: ServicesProduct[]): Product[] => {
  const mappedProducts = removeUnavailableProducts(
    removeUnwantedProducts(mapFirstFavouritesProducts(source, serviceProducts))
  );

  return mappedProducts.slice(0, 18);
};

/** @deprecated to be removed after add to favourites release */
export const getAdjacentFavourite = (favourites: string[], location: string, favouriteProductUid: string) => {
  if (typeof location === "undefined") {
    return ``;
  }

  location = location.split("#").join("");

  if (favourites.length <= 1 || favourites.indexOf(location) === -1) {
    return ``;
  }

  if (location === favouriteProductUid) {
    let arrayIndex = favourites.findIndex(index => index === location);
    arrayIndex = arrayIndex === favourites.length - 1 ? arrayIndex - 1 : arrayIndex + 1;

    return `#${favourites[`${arrayIndex}`]}`;
  }

  const arrayIndex = favourites.findIndex(index => index === location);

  return `#${favourites[`${arrayIndex}`]}`;
};

export const mapSort = (sort: Sort): FavouritesSort => {
  const sortOptions = sort.options.reduce((result: SortOption[], sortItem: SortOption) => {
    result.push(sortItem);
    return result;
  }, []);
  return { activeSort: sort.active, sortOptions };
};

export const changeDeliverySlotActionCreator = (dispatch: Dispatch<Action>) => {
  dispatch(clearBasketErrorActionCreator(BasketErrorKeys.SCOTTISH_LEGISLATION));
  dispatch(createExternalNavigation(urls.BOOK_DELIVERY_SLOT));
};

export const bulkOrderingHelpActionCreator = (dispatch: Dispatch<Action>) => {
  dispatch(clearBasketErrorActionCreator(BasketErrorKeys.BULK_ORDER_LIMIT_EXCEEDED));
  dispatch(createExternalNavigationWindow(externalUrls.BULK_ORDERING_HELP));
};

export const checkOffersInResponse = (products: ServicesProduct[]): boolean => {
  return products.some(({ promotions = [] }: ServicesProduct) => promotions.length !== 0);
};

export const findLastPopulatedPage = (totalRecords: number, pageSize: number): number => {
  const totalPopulatedPages = Math.ceil(totalRecords / pageSize);
  const lastPage = totalPopulatedPages === 0 ? 0 : totalPopulatedPages;

  return lastPage;
};

const retryFetchFavourites = (
  dispatch: Dispatch,
  queryParameters: ProductQueryParameters,
  totalRecords: number,
  storeIdentifier?: string,
  slotDate?: string,
  slotDateTime?: string,
  slotType?: string,
  flexiStores?: string[],
  isNewEndpoint?: boolean
): Promise<Result<FavouritesResponse>> => {
  dispatch(
    favouritesFetchingAction({
      sort: queryParameters.sort,
      filters: queryParameters.filters,
      pageSize: Number(queryParameters.pageSize) || undefined,
      pageNumber: findLastPopulatedPage(totalRecords, Number(queryParameters.pageSize)),
    })
  );

  const result = fetchFavourites(
    {
      sort: queryParameters.sort,
      filters: queryParameters.filters,
      pageSize: Number(queryParameters.pageSize),
      pageNumber: findLastPopulatedPage(totalRecords, Number(queryParameters.pageSize)),
      includes: ["ASSOCIATIONS"],
      storeIdentifier,
      slotDate,
      slotDateTime,
      flexiStores,
    },
    "default",
    isNewEndpoint
  );

  return result;
};

export const trackFeatureTourStartAction = (journey: TourJourneyTypes): TrackFeatureTourStartAction => ({
  type: FavouritesActionTypes.START_FEATURE_TOUR,
  data_event_category: "Favourites - Feature tour",
  data_event_action: "click",
  data_event_label: "Find out how",
  data_journey_type: journey,
});

export const trackFeatureTourCloseAction = (journey: TourJourneyTypes, steps: string): TrackFeatureTourCloseAction => ({
  type: FavouritesActionTypes.CLOSE_FEATURE_TOUR,
  data_event_category: "Favourites - Feature tour",
  data_event_action: "click",
  data_event_label: "Close",
  data_tooltip_step: steps,
  data_journey_type: journey,
});

export const trackFeatureTourNextAction = (
  journey: TourJourneyTypes,
  steps: string,
  label?: CtaTypes
): TrackFeatureTourNextAction => ({
  type: FavouritesActionTypes.NEXT_STEP_FEATURE_TOUR,
  data_event_category: "Favourites - Feature tour",
  data_event_action: "click",
  data_event_label: label,
  data_tooltip_step: steps,
  data_journey_type: journey,
});

export const fetchMagnoliaSuccessfulAction = (ads: IngridAd[]) => ({
  type: FavouritesActionTypes.FETCH_MAGNOLIA_SUCCESS,
  ads,
});
