import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../common/store";
import { SearchBarComponent } from "./SearchBar.component";
import { createSearchAction } from "./search.actions";
import { selectSearchTerm } from "../../../views/SearchResults/search.selectors";
import { setLastSeenFavourite } from "@favourites/state/Favourites.actions";
import { MagnoliaTemplate } from "../../../services/content";
import { RestingSearchV2 } from "./hooks/useTrendingSearch.hook";

interface SearchBarContainerProps {
  onFocus?: () => void;
  onBlur?: () => void;
  checkoutHeader?: boolean;
  restingSearchPreset?: {
    template: MagnoliaTemplate | null;
    isLoading: boolean;
    magnoliaError: boolean;
    restingSearch: RestingSearchV2;
  };
}

export function SearchBarContainer({
  onFocus,
  onBlur,
  checkoutHeader,
  restingSearchPreset,
}: Readonly<SearchBarContainerProps>) {
  const dispatch = useDispatch();
  const searchTerm = useSelector((state: State) => selectSearchTerm(state));

  const setLastSeenFavouritesProduct = (product: string) => dispatch(setLastSeenFavourite(product));
  const doSearch = (term: string | null, trending: boolean) => {
    if (term) {
      dispatch(createSearchAction(term.trim(), trending));
    }
  };

  const SearchBarComponentProps = {
    doSearch,
    setLastSeenFavouritesProduct,
    searchTerm,
    onFocus,
    onBlur,
    checkoutHeader,
    restingSearchPreset,
  };

  return <SearchBarComponent {...SearchBarComponentProps} />;
}
