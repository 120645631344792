import React from "react";
import { Modal, Button, ButtonGroup, LoadingIndicator } from "@sainsburys-tech/fable";

export default function ModalLoading() {
  return (
    <Modal
      isOpen
      hasClickawayClose={false}
      data-testid="global-modal-loading"
      renderFooter={
        <ButtonGroup>
          <Button variant="primary" disabled={true}>
            Ok
          </Button>
        </ButtonGroup>
      }
    >
      <LoadingIndicator label="Please wait while we fill your trolley for you..." size="sm" variant="primary" />
    </Modal>
  );
}
