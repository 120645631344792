import { LinkFlag } from "./useMenuPromotionalItem";

export interface HeaderProps {
  navItems: MenuItem[];
  promotionalLink: LinkFlag | boolean;
  isFoodToOrderEnabled: boolean;
  isLoggedIn: boolean;
  megaNavData: MegaNavData | null;
}

export interface MenuItem {
  title: string;
  url: string;
  items?: MenuItem[];
  authorizedUrl?: string;
  newTab?: boolean;
  id: string;
  external?: boolean;
}

export type HeaderLink =
  | "explore"
  | "help-centre"
  | "store-locator"
  | "logout"
  | "login"
  | "my-account"
  | "my-account-new-global-header"
  | "multi-search";

export type HeaderLinkObject = {
  [name in HeaderLink]: {
    label: string;
    tabletLabel?: string;
    url: string;
    external: boolean;
  };
};

export interface HeaderLinkProps {
  customStyle?: string;
}

export function isQualifiedMenuItem(item: any): item is MenuItem {
  return (
    typeof item.title === "string" &&
    item.title.length > 0 &&
    typeof item.id === "string" &&
    item.id.length > 0 &&
    typeof item.url === "string" &&
    item.url.length > 0
  );
}

// WCSBreadcrumb associates a GOL-UI URL path with its WCS info details.
export interface WCSBreadcrumbs {
  [path: string]: {
    url: string;
    label: string;
  };
}

export interface MegaNavCategory {
  n: string; // name
  s: string; // url
  c?: MegaNavCategory[]; // children
}

export interface MegaNavData {
  catalog_id: string;
  category_hierarchy: MegaNavCategory[];
}

export interface MegaNavProps {
  data: MegaNavData;
}

export function convertToMenuItem(megaNavItem: MegaNavCategory): MenuItem {
  return {
    title: megaNavItem.n,
    url: megaNavItem.s,
    id: megaNavItem.s + megaNavItem.n,
    items: megaNavItem.c?.map(convertToMenuItem),
    external: true,
  };
}
export function convertMegaNavToMenuItems(megaNavData: MegaNavData): MenuItem[] {
  return megaNavData.category_hierarchy.map(convertToMenuItem);
}
