import React from "react";
import { ErrorPage } from "./ErrorPage";
import fruitImage from "../../static/empty-trolley.svg";
import { urls } from "../../routes";
import { FilledButton } from "@jsluna/button";

export const UnrecoverableErrorPage = () => (
  <ErrorPage
    header="Something went wrong"
    description="Sorry, we're having trouble showing you this page right now. Please try again later."
    image={<img alt="Fruit" src={fruitImage} />}
    callToAction={
      <FilledButton className="error-page__button--primary" onClick={() => window.location.assign(urls.GROCERIES_URL)}>
        Go to homepage
      </FilledButton>
    }
  />
);
UnrecoverableErrorPage.displayName = "UnrecoverableErrorPage";
