/**
 * Removes any starting and ending slashes from a slug (url path)
 * @param slug the string to remove the slashes form
 * @returns The modified string.
 */

export const trimSlashes = (slug: string) => {
  if (slug.startsWith("/")) {
    slug = slug.slice(1);
  }
  if (slug.endsWith("/")) {
    slug = slug.slice(0, -1);
  }
  return slug;
};
