import { State } from "../../../common/store";
import { useDispatch, useSelector } from "react-redux";
import {
  hideTourAction,
  increaseTourStepAction,
  showTourAction,
  trackFeatureTourCloseAction,
  trackFeatureTourNextAction,
  trackFeatureTourStartAction,
} from "@favourites/state/Favourites.actions";
import { useEffect, ReactElement, useRef, useCallback } from "react";
import { featureTour } from "@favourites/components/FeatureTour/featureTour";
import { CtaTypes, Step, TourJourneyTypes } from "@favourites/FeatureTour.types";
import { useHeaderActions } from "../../../components/Header/headerState/header.actions";

interface FavouriteFeatureTour {
  show: boolean;
  step: number;
  totalSteps?: number;
  page: {
    header?: string | ReactElement;
    nextCTA?: CtaTypes;
    position?: Step["position"];
    text: string | ReactElement;
    tileIndex?: number;
    isLargeTooltip?: boolean;
  };
  handleNextTourOnClick: () => void;
  handleCloseTourOnClick: () => void;
  handleEnableTourOnClick: () => void;
  handleDisableTourOnClick: () => void;
}

export const useFeatureTour = (): FavouriteFeatureTour => {
  const dispatch = useDispatch();

  const step: number = useSelector((state: State) => state.favourites.tour.step);
  const show: boolean = useSelector((state: State) => state.favourites.tour.show);

  const isEnabled: boolean = featureTour.isEnabled();
  const { totalSteps, page } = featureTour.get({ step });

  const { showHeader } = useHeaderActions();
  const firstRender = useRef(true);
  const isReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

  useEffect((): void => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    scrollTo();
    // TO-DO: refactor when useEffectEvent is stable see https://react.dev/reference/react/experimental_useEffectEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, show]);

  const scrollTo = useCallback(() => {
    const { page } = featureTour.get({ step });

    if (isEnabled && page?.scrollToElement) {
      const scrollToElement = page.scrollToElement
        ? (document.querySelectorAll(page.scrollToElement)[page.scrollToElementIndex || 0] as HTMLElement)
        : null;
      if (scrollToElement) {
        scrollToElement.scrollIntoView({ behavior: isReducedMotion ? "auto" : "smooth", block: "center" });
      }
    }
  }, [isReducedMotion, isEnabled, step]);

  const handleCloseTourOnClick = useCallback(
    (hasCta?: CtaTypes): void => {
      featureTour.setSession({ enabled: false });
      const session = featureTour.getSession();

      if (hasCta) {
        dispatch(
          trackFeatureTourNextAction(session.type, featureTour.stringifySteps({ step, totalSteps }), page?.nextCTA)
        );
        dispatch(hideTourAction());
      } else {
        dispatch(trackFeatureTourCloseAction(session.type, featureTour.stringifySteps({ step, totalSteps })));
        dispatch(hideTourAction());
      }
    },
    [dispatch, page, step, totalSteps]
  );

  const handleNextTourOnClick = useCallback((): void => {
    const session = featureTour.getSession();

    const isLastStep = step >= (totalSteps ?? step);

    if (isLastStep) {
      handleCloseTourOnClick(page?.nextCTA);
    } else {
      dispatch(
        trackFeatureTourNextAction(session.type, featureTour.stringifySteps({ step, totalSteps }), page?.nextCTA)
      );
      dispatch(increaseTourStepAction());
    }
  }, [dispatch, step, totalSteps, page?.nextCTA, handleCloseTourOnClick]);

  const handleDisableTourOnClick = (): void => {
    localStorage.setItem("feature_tour", "false");

    dispatch(hideTourAction());
  };

  const handleEnableTourOnClick = useCallback((): void => {
    featureTour.setSession({ enabled: true, type: TourJourneyTypes.ENHANCED });

    localStorage.setItem("feature_tour", "true");

    dispatch(trackFeatureTourStartAction(TourJourneyTypes.ENHANCED));

    if (show) {
      dispatch(hideTourAction());
    }

    dispatch(showTourAction());
    scrollTo();
    showHeader();
  }, [dispatch, scrollTo, showHeader, show]);

  return {
    step,
    show: show && isEnabled,
    totalSteps,
    page: {
      header: page?.header,
      nextCTA: page?.nextCTA,
      position: page?.position,
      text: page?.text ?? "",
      tileIndex: page?.tileIndex,
      isLargeTooltip: page?.isLargeTooltip,
    },
    handleNextTourOnClick,
    handleCloseTourOnClick,
    handleEnableTourOnClick,
    handleDisableTourOnClick,
  };
};

export default useFeatureTour;
